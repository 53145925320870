import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class PersonnelSalaryAccrual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            list: [],
            date: false,
            description: "Maaş Tahakkuku"
        };
    }

    rowChangeUpdate = (val, obj) => {
        const updatedList = [...this.state.list];
        updatedList[obj].salary = val;
        this.setState({ list: updatedList });
    };
    dateUpdate = (date) => {
        this.setState({ date: date });
    };

    descriptionUpdate = (description) => {
        this.setState({ description: description });
    };
    list() {
        Api.get("/employee/showAllActive", (data, errors) => {
            if (data.employees) {
                this.setState({list: data.employees});
            }
        });
    }

    update(){
        if(this.state.date===false){
            toast.error("Lütfen Tarih Seçin", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: true
            });
        }else{
            Api.post("/employee/addSalaryAccrual", {list:this.state.list,date:this.state.date,description:this.state.description}, (data, errors) => {
                if (data) {
                    //this.list();
                    toast.success("Başarıyla Güncellendi", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: true
                    });
                    this.props.router.push("/dashboard/employee");
                }
            });
        }

    }


    componentWillMount() {
        this.list();
    }


    render() {
        return (
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            Toplu Maaş Tahakkuku Ekle
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <div><ToastContainer  limit={1} />
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th style={{width:"20%"}} className="fcolumn">Adı Soyadı</th>
                                <th style={{width:"20%"}} className="fcolumn">TC Kimlik No</th>
                                <th style={{width:"20%"}} className="fcolumn">Tahakkuk Tutarı</th>
                            </tr>
                            </thead>
                        </table>
                        {
                            this.state.list.map((item, index) => {
                                return <table className="table table-striped table-bordered table-hover">
                                    <tbody>
                                    <tr key={index}>
                                        <td style={{width:"20%"}}>
                                            {item.firstname+" "+item.lastname}
                                        </td>
                                        <td style={{width:"20%"}}>{item.identity_number}</td>
                                        <td style={{width:"20%"}}>
                                            <input
                                                value={this.state.list[index].salary}
                                                className="form-control" type="number" min="1"
                                                onChange={(e) => this.rowChangeUpdate(e.target.value, index)}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>;
                            })
                        }
                        <div style={{"marginTop":"20px","marginBottom":"20px","width":"900px","float":"right"}}>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    Açıklama:
                                    <input
                                        type={"text"}
                                        value={this.state.description}
                                        className="form-control"
                                        onChange={(e) => this.descriptionUpdate(e.target.value)}
                                    />
                                </div>
                                <div className={"col-md-4"}>
                                    Tarih Seçin:
                                    <input
                                        type={"date"}
                                        value={this.state.date}
                                        className="form-control"
                                        onChange={(e) => this.dateUpdate(e.target.value)}
                                    />
                                </div>
                                <div className={"col-md-4"}>
                                    <br />
                                    <button className="btn blue btn-sm" onClick={this.update.bind(this)}>
                                        <i className="fa fa-plus"/>&nbsp; Güncelle
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{"clear":"both"}}></div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties,
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(PersonnelSalaryAccrual);