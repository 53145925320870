import React, {Component} from 'react'
import EkoFormInputText from './form/EkoFormInputText'
import EkoFormSelect from './form/EkoFormSelect'
import EkoFormSwitch from './form/EkoFormSwitch'
import EkoFormButton from './form/EkoFormButton'
import EkoFormDate from './form/EkoFormDate'
import EkoFormCheckbox from './form/EkoFormCheckbox'
import EkoFormAutoComplete from './form/EkoFormAutoComplete'
import EkoFormAutosuggest from './form/EkoFormAutosuggest'
import EkoFormRadioButton from './form/EkoFormRadioButton'
import EkoFormTooltip from './EkoFormTooltip';
import PropTypes from 'prop-types';

class EkoForm extends Component {

    onSubmit(e) {
        e.preventDefault();
    }


    render() {
        let form = 'Bu işlem için yetkiniz yok'
        if (!this.props.noPermit) {

            form = <EkoFormTooltip hortizonal={true} formId={this.props.formId} onSubmit={this.onSubmit.bind(this)}>
                {this.props.children}
            </EkoFormTooltip>;
        }
        return (
            <div className="form-body">
                {form}
            </div>

        )
    }
}

EkoForm.propTypes = {
    hortizonal: PropTypes.bool,
    formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


//NOTE @bocek, hepsini tek classdan export edelim beraber kullnialacaklar nasilsa
export {
    EkoForm,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormButton,
    EkoFormDate,
    EkoFormCheckbox,
    EkoFormAutoComplete,
    EkoFormRadioButton,
    EkoFormAutosuggest
}
