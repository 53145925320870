import React, {Component} from 'react'

import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import { CSS } from "../../css/tab_datatable";
import AddAsset from './AddAsset'
import {toastr} from 'react-redux-toastr'
import HelperContainer from '../../util/HelperContainer'
import Util from '../../util/Util';
import Api from '../../util/Api'
import moment from 'moment'

import {FETCH_ASSETS} from '../../actions/types'
import Style from "style-it";

class Assets extends Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showInsertModal: false,
            errors: {},
            validationFields: [],
            assets: [],
            tableTitle:"",
        }

    }


    fetchAssets() {
        //this.props.fetchData("/fixedAsset/list", FETCH_ASSETS);
        var url ="";
        if(this.props.params.main_id === undefined){
            url = "/fixedAsset/mainList";
        }else{
            url = "/fixedAsset/subFixedAsset/"+this.props.params.main_id;
        }
        Api.get(url, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response;
                this.setState({assets:getData });
            }
        });
        if(this.props.params.main_id !== undefined){
            Api.get("/fixedAsset/"+this.props.params.main_id, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.setState({tableTitle:response.fixedAsset.asset_name });
                }
            });
        }
    }

    componentWillMount() {
        this.fetchAssets();
    }
    fixedAssetAccountTransaction(selectedRow) {
        window.location = "/dashboard/assets/" + selectedRow.id;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.assets) {
            this.setState({assets: nextProps.assets});
        }
    }

    fixedAssetDepreciationStop(selectedRow) {
        var self = this;

        toastr.confirm(`Emin misiniz?`, {
            onOk: () => {
                Api.get('/fixedAsset/stopDepreciation/' + selectedRow.id,
                    function (response) {
                        toastr.success('Başarılı', 'Başarıyla Uygulandı');
                    })
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });


    }

    openUpdate(selectedRow) {
        var self = this;
        let formData = {}
        Api.get('/fixedAsset/' + selectedRow.id,
            function (response) {
                formData = {}
                if (response.fixedAsset) {
                    formData.asset = HelperContainer.filterObject(response.fixedAsset);
                    try {
                        formData.asset.purchase_price = Util.convertDecimalToMoney(formData.asset.purchase_price)
                        formData.asset.vat_amount = Util.convertDecimalToMoney(formData.asset.vat_amount)
                        formData.asset.otv_amount = Util.convertDecimalToMoney(formData.asset.otv_amount)
                        formData.asset.accumulated_depreciation = Util.convertDecimalToMoney(formData.asset.accumulated_depreciation)
                        formData.asset.depreciation_term = formData.asset.depreciation_term || formData.asset.depreciation_term === 0 ?
                            formData.asset.depreciation_term.toString() : "";
                        formData.asset.purchase_date = Util.convertFromDatabaseDate(formData.asset.purchase_date);

                    }
                    catch (ex) {
                        console.log(ex);
                    }
                    self.setEndDate(formData);
                }
            })
    }

    setEndDate(formData) {
        const self = this;
        if (formData.asset.depreciation_term && formData.asset.purchase_date) {
            let date = Util.convertToDatabaseDate(formData.asset.purchase_date);
            let term = formData.asset.depreciation_term
            Api.get('/calculation/FaDepreciationEndDate?date=' + date + '&year=' + term, (response) => {
                if (response.status === 'ok') {
                    let depreciationEndDate = response.response.date ? moment(response.response.date, "YYYY-MM-DD").format("DD-MM-YYYY") : "";
                    formData.asset = Object.assign({}, formData.asset, {
                        end_date: depreciationEndDate
                    })
                }
                self.setState({
                    showInsertModal: true,
                    errors: {},
                    formData: formData,
                });
            });
        }
    }

    onDelete(selectedRow) {
        const self = this;
        Api.delete("/fixedAsset/" + selectedRow.id, function (response, err) {
            if (response) {
                self.fetchAssets();
                toastr.success('Başarılı', 'Başarıyla Silindi');
            }
            else toastr.error('Hata', err.message);
        })
    }

    openInsert(menuItem) {
        if (menuItem === 1) {
            this.props.router.push("/dashboard/purchases/purchaseInvoice/add/toFixedAssetInvoice");
        }else if (menuItem === 2) {
            this.props.router.push("/dashboard/assets");
        } else if (menuItem === 3) {
            this.props.router.push("/dashboard/salesInvoice/add/toFixedAsset");
        } else if (menuItem === 4) {
            this.setState({showInsertModal: true, formData: false})
        }

    }

    onSave(formData, formFields) {
        this.fetchAssets();
        this.setState({showInsertModal: false, formData: false});
    }
    get items() {
        var list = [];
        list =  [
            {
                id: 1,
                link: '/dashboard/assets',
                title: 'Demirbaşlar'
            },
            {
                id: 2,
                link: '/dashboard/fixedAsset',
                title: 'Demirbaş Alış Faturaları'
            },
            {
                id: 4,
                link: '/dashboard/fixedAssetSalesInvoice',
                title: 'Demirbaş Satış Faturaları'
            },
            {
                id: 3,
                link: '/dashboard/depreciation',
                title: 'Amortismanlar'
            }
        ];
        return list;
    }
    render() {
        const insertMenu = [
            {id: 4, name: 'Demirbaş Ekle'},
            {id: 1, name: 'Demirbaş Alış Faturası Ekle'},
            {id: 3, name: 'Demirbaş Satış Faturası Ekle'}
        ];
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                <AddAsset
                    showModal={this.state.showInsertModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showInsertModal: false})
                    }}
                    errors={this.state.errors}
                />
                {Style.it(
                    CSS,
                    <div>{
                        this.props.params.main_id === undefined ?
                            <EkoDataTable
                                tabMenu={this.items}
                                selectedTab={1}
                                insertMenu={insertMenu}
                                openInsert={this.openInsert.bind(this)}
                                openUpdate={this.openUpdate.bind(this)}
                                onDelete={this.onDelete.bind(this)}
                                data={this.state.assets}
                                fixedAssetAccountTransaction={this.fixedAssetAccountTransaction.bind(this)}
                                fixedAssetDepreciationStop={this.fixedAssetDepreciationStop.bind(this)}
                                name={"fixedAsset"}>
                            </EkoDataTable> :
                            <EkoDataTable
                                title={this.state.tableTitle}
                                data={this.state.assets}
                                openUpdate={this.openUpdate.bind(this)}
                                onDelete={this.onDelete.bind(this)}
                                fixedAssetAccountTransaction={false}
                                addButtonShow={false}
                                name={"fixedAsset"}>
                            </EkoDataTable>
                    }</div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        assets: state.data.assets,
    };
}

export default connect(mapStateToProps, actions)(Assets);
