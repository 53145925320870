import React, {Component} from "react";
import EkoModal from "../../components/elements/EkoModal";
import {toastr} from "react-redux-toastr";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import styled from "styled-components";
import {EkoFormSelect} from "../elements/EkoForm";
class SalesShipmentSendModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            showModal: false,
            saving: false,
            sendBtnDisabledStatus: false,
            invoiceId: 0,
            errors: {},
            formFields: {},
            selectedReceiver:null,
        };
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData;
        let errors = nextProps.errors || {};
        this.setState({
            formData: formData,
            errors: errors,
            showModal: nextProps.showModal
        });
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    sendRequirementCheck(){
        if(this.state.formData!==0){
            Api.get("/salesShipment/sendRequirementCheck/" + this.state.formData, (data, errors) => {
                if(data){
                    if(data.status==='error'){
                        toastr.error(data.message);
                    }else{
                        this.onSend();
                    }
                }
            });
        }
    }

    onSend() {
        var selectedRow = this.state.formData;
        const self = this;
        self.setState({ eInvoiceLoading: true,sendBtnDisabledStatus:true });
        var findReceiver = this.props.receiver.find((x) => x.identifier === this.state.selectedReceiver);
        if(findReceiver){
            let integrator = JSON.parse(localStorage.getItem("integrator"));
            var url = "";
            if (integrator === 1) {
                url = "/sendSalesShipment";
                //@oguz sovos gonderimi
            } else if (integrator === 3) {
                url = "/nesv2/sendSalesShipment";
                //@oguz nesv2 gönderimi
            }
            Api.post(
                url,
                {
                    sales_shipment_id: selectedRow,
                    receiver: findReceiver
                },
                (response, err) => {
                    if (response) {
                        if(response.status=='fail'){
                            toastr.error(response.message);
                        }else{
                            toastr.success("Başarılı");
                        }
                        self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                        this.onCancel();
                    } else {
                        toastr.error("Hata", "Sistemsel Bir Hata Oluştu.");
                        self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                        err =
                            err === "Unauthorized"
                                ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                                : err;
                        toastr.error("Hata", err.message);
                    }
                }
            );
        }else{
            toastr.error("Hata", "Gönderici adresi seçmelisiniz.");
            self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
        }
    }

    onFieldChange = (field, value) => {
        if (field === "identifier") {
            this.setState({
                selectedReceiver: value,
            });
        }

    };
    render() {
        const Wrap = styled.div`
          .hidden{display:none !important}
          .showDiv{display:block !important}`;
        return (
            <EkoModal
                showModal={this.state.showModal}
                title={"Sevk İrsaliyesi Gönder"}
                onHide={() => {
                    this.onCancel();
                }}
            >
                <div className={"row showDiv"}>
                    <div className={"col-md-12 text-center"}>
                        Sevk İrsaliyesini Göndermek Üzeresiniz.<br/>Bu İşlemi Yapmak İstediğinize Eminmisiniz?
                        <div style={{"height":"60px"}}></div>
                    </div>
                    <div className="col-md-12">
                            <EkoFormSelect
                                label="Adres *"
                                optionId="identifier"
                                optionValue="identifier"
                                labelMd={4}
                                colMd={7}
                                value={this.state.selectedReceiver}
                                options={this.props.receiver}
                                onChange={this.onFieldChange}
                                id="identifier"
                            />
                    </div>
                    <div className={"col-md-12"} style={{"marginTop":"50px"}}>
                        <div className="col-md-12 text-center">
                            <button disabled={this.state.sendBtnDisabledStatus} className="btn btn-default blue btn-sm" onClick={this.sendRequirementCheck.bind(this)}>
                                Gönder
                            </button>
                            <button className="btn btn-default btn-sm" onClick={this.onCancel.bind(this)}>
                                İptal
                            </button>
                        </div>
                    </div>
                </div>
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices
    };
}

export default connect(mapStateToProps, actions)(SalesShipmentSendModal);
