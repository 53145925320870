import React, {Component} from 'react'
import Layout from '../layouts/Dashboard'
import {connect} from 'react-redux'
import * as actions from '../../actions'

const NotFound = props => {
    return (
        <Layout>
            <h3>Aradığınız sayfa yapım aşamasında</h3>
        </Layout>
    )
};


function mapStateToProps(state) {
    return {
        pageLoading: state.page.pageLoading,
        pageTitle: state.page.title  //NOTE @bocek sayfada pageTitle lazim olur diye koydum
        //simdilik birseye yaramiyor
    };
}

export default connect(mapStateToProps, actions)(NotFound);
