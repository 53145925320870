import React, { Component } from "react";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { FETCH_WAREHOUSE_TRANSFERS } from "../../actions/types";
import Api from "../../util/Api";
import {
  EkoFormButton,
  EkoFormInputText,
  EkoFormSelect,
} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";
import { toastr } from "react-redux-toastr";

class Warehouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      warehouseList: [],
      loading: true,
      openModal: false,
      formData: [],
    };
  }

  setMain(warehouse) {
    Api.put("/warehouses/setMain", warehouse, function (res, err) {
      toastr.success("Başarılı!", "Ana Depo Başarıyla Değiştirildi");
    });
  }

  fetchAllWarehouses(){
    this.props.fetchData("/warehouses/showAll", FETCH_WAREHOUSE_TRANSFERS);
    Api.get("/warehouses/showAll", (res, err) => {
      this.setState({
        loading: false,
      });
      if (!err) {
        this.setState({
          warehouseList: res.items,
        });
      }
    });
  }

  componentWillMount() {
    this.setState({
      cities: JSON.parse(localStorage.getItem("fetch_cities")).data,
      counties: JSON.parse(localStorage.getItem("fetch_counties")).data,
    });

    this.fetchAllWarehouses();
  }
  openInsert() {}
  setCounties(city) {
    this.setState({
      cityCounties: this.state.counties.filter((cts) => city === cts.city_id),
    });
  }
  openUpdate(row) {
    this.setCounties(parseInt(row.city_id));
    this.setState({
      formData: {
        id: row.id,
        name: row.name,
        city_id: parseInt(row.city_id),
        county_id: parseInt(row.county_id),
        address: row.address,
      },
    });
    this.openModal();
  }
  onDelete({ id }) {
    Api.post("/warehouses/destroy", { ware_house_id: id }, (res, err) => {
      if (res.status === "fail") {
        toastr.error("Hata!", res.message);
      } else {
        toastr.success("Başarılı!", res.message);
        let warehouseList = [...this.state.warehouseList];

        warehouseList.map((warehouse, index) => {
          if (parseInt(warehouse.id) === parseInt(id)) {
            delete warehouseList[index];
          }
        });

        this.setState({
          warehouseList: warehouseList.filter(
              (warehouse) => warehouse.id !== id
          ),
        });
      }
    });
  }
  openModal = () => {
    this.setState({ openModal: true });
  };
  saveModal = () => {
    //set default method and endpoint
    let method = "post",
        endpoint = "/warehouses";

    //if there is id in formdata, change the method and endpoint
    if (this.state.formData.id) {
      method = "put";
      endpoint += "/update";
    }

    //send save request
    Api[method](endpoint, this.state.formData, (res, err) => {
      if(res.status === "ok"){
        toastr.success('Başarılı', res.message)
        this.fetchAllWarehouses();
        this.setState({formData: []})
        this.closeModal();
      }else{
        this.setState({errors: err})
        toastr.error('Hata!', "Lütfen formu kontrol ediniz.")
      }
    });

  };
  closeModal() {
    this.setState({ openModal: false });
  }
  onChange(field, value) {
    if (field === "city_id") {
      this.setCounties(value);
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value,
      },
    });
  }
  accountTransaction(selectedRow) {
    window.location.replace("/dashboard/transaction/warehouse/" + selectedRow.id);
  }
  render() {
    const inputProps = {
      formData: this.state.formData,
      errors: this.state.errors
    };


    const insertMenu =
        this.props.viewOptions && this.props.viewOptions.warehouses
            ? this.props.viewOptions.transferWarehouses.addRecordMenu
            : false;

    return (
        <div>
          <EkoDataTable
              insertMenu={insertMenu}
              name={"warehouses"}
              onSelectedRowChange={this.onSelectedRowChange}
              openInsert={this.openModal.bind(this)}
              openUpdate={this.openUpdate.bind(this)}
              setMain={this.setMain.bind(this)}
              accountTransaction={this.accountTransaction.bind(this)}
              onDelete={this.onDelete.bind(this)}
              data={this.state.warehouseList}
              loading={this.state.loading}
              showDelete
              title={"Depolar"}
          />
          <EkoModal
              showModal={this.state.openModal}
              onHide={this.closeModal.bind(this)}
              title={"Yeni Depo"}
          >
            <div className={"row"}>
              <label>Depo Adı</label>
              <EkoFormInputText
                  {...inputProps}
                  onChange={this.onChange.bind(this)}
                  colMd={12}
                  placeHolder="Depo Adı"
                  type="text"
                  id="name"
                  style={{
                    display: "block",
                    borderColor: "#ccc",
                    padding: ".5em",
                    borderRadius: "0 0 3px 3px",
                    fontSize: "small",
                  }}
              />
              <div className="row">
                <div className="col-md-6"><EkoFormSelect
                    {...inputProps}
                    onChange={this.onChange.bind(this)}
                    searchable={true}
                    id="city_id" //formData.address.city_id
                    isVertical={true}
                    optionValue="name"
                    defaultText="Seçiniz"
                    options={this.state.cities}
                    label="İl"
                /></div>
                <div className="col-md-6"><EkoFormSelect
                    {...inputProps}
                    onChange={this.onChange.bind(this)}
                    searchable={true}
                    id="county_id" //formData.address.city_id
                    isVertical={true}
                    optionValue="name"
                    defaultText="Seçiniz"
                    options={this.state.cityCounties}
                    label="İlçe"
                /></div>
              </div>
              <div className={"row"}>
                <EkoFormInputText
                    labelMd={2}
                    colMd={10}
                    {...inputProps}
                    onChange={this.onChange.bind(this)}
                    label="Adres" //formData.address.address
                    id="address"
                    type="textarea"
                    childrenShow
                ></EkoFormInputText>
              </div>

              <div style={{ height: "30px" }}></div>
              <div style={{ "text-align": "right" }}>
                <EkoFormButton
                    label={"Kaydet"}
                    faClass="fa fa-plus"
                    buttonsType={"default"}
                    className="btn blue"
                    onClick={this.saveModal}
                />
                <EkoFormButton
                    label={"İptal"}
                    buttonsType={"default"}
                    className="btn white"
                    onClick={this.closeModal.bind(this)}
                />
              </div>
            </div>
          </EkoModal>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data.warehouses ? state.data.warehouses.warehouses : undefined,
    viewOptions: state.data.viewOptions,
  };
};

export default connect(mapStateToProps, actions)(Warehouses);
