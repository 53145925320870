import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import AddCompanyUserRole from './AddCompanyUserRole'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'

import Util from "../../util/Util";
import Api from '../../util/Api'
//import EkoToastrError from '../../components/elements/EkoToastrError'
import {FETCH_INVITATIONS} from '../../actions/types'
import {EkoFormButton} from "../../components/elements/EkoForm";
import AccountLockModal from "../../components/forms/AccountLockModal";

class CompanyUserRole extends Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            lockShow: false,
            user: {},
            lock_before: null,
            validationFields: [],
            invitations: [] //table Item
        }

    }


    fetchInvitations() {
        this.props.fetchData("/invitation/list", FETCH_INVITATIONS);
    }

    componentWillMount() {
        this.fetchInvitations();

        this.setState({
            user: JSON.parse(localStorage.getItem('user'))
        })

        Api.get('/company/lock-before', (res) => {
            const lockBefore = res.lock_before;

            this.setState({
                lock_before: lockBefore
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invitations) {
            this.setState({invitations: nextProps.invitations});
        }

    }


    openUpdate(selectedRow) {
        var self = this;
        const id = selectedRow.id;
        Api.get('/invitation/' + id,
            function (response) {
                console.log(response);
                let formData = {}
                formData.companyUserRole = HelperContainer.filterObject(response.invitation);
                self.setState({
                    showInsertModal: true,
                    errors: {},
                    formData: formData
                });
            })


    }

    onDelete(selectedRow) {
        const self = this;
        Api.delete("/invitation/" + selectedRow.id, function (response, err) {
            if (response) {
                self.fetchInvitations();
                toastr.success('Başarılı', 'Başarıyla Silindi');
            }
            else toastr.error('Hata', err.message);
        })
        /*  let newData = Sugar.Array.filter(this.props.companyUserRoless,function(object){
              return (object.id!==selectedRow.id);
          });
          this.props.updateFetchedData(FETCH_SUPPLIERS,{companyUserRoless:newData});*/
    }


    onSave(formData, formFields) {
        this.fetchInvitations();
        this.setState({showInsertModal: false, formData: false});
    }

    toggleAccountLock = () => {
        this.setState((prevState) => ({
            lockShow: !prevState.lockShow
        }))
    }


    saveLockBefore = (lockBefore) => this.setState({
        lock_before: lockBefore
    })

    render() {
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>


                {this.state.user && this.state.user.is_owner ?
                    <AccountLockModal saveLockBefore={this.saveLockBefore} lockBefore={this.state.lock_before}
                                      showModal={this.state.lockShow} toggle={this.toggleAccountLock}/> : null}


                <AddCompanyUserRole
                    showModal={this.state.showInsertModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showInsertModal: false})
                    }}
                    errors={this.state.errors}
                />

                {this.state.user && this.state.user.is_owner ?
                    <div className="pull-right" style={{
                        marginTop: 10,
                        marginRight: 10
                    }}>
                        <EkoFormButton disabled={this.state.lock_before === null}
                                       isLoading={this.state.lock_before === null} onClick={this.toggleAccountLock}
                                       className={'btn btn-default'}
                                       label={this.state.lock_before ? 'Hesap Kilitli' : 'Hesabı Kitle'}/>
                        {this.state.lock_before ? <h5>
                            <small>{moment(this.state.lock_before).format('DD/MM/YYYY')} tarihinden öncesi
                                kilitli.</small>
                        </h5> : null}

                    </div> : null
                }

                <EkoDataTable
                    openInsert={() => {
                        this.setState({showInsertModal: true, formData: false})
                    }}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.props.invitations}
                    name={"invitation"}>


                </EkoDataTable>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        invitations: state.data.invitations

    };
}

export default connect(mapStateToProps, actions)(CompanyUserRole);
