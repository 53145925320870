import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect,
} from "../../components/elements/EkoForm";
// import AutoComplete from '../../components/elements/AutoComplete';
import Util from "../../util/Util";
// import { Alert } from 'reactstrap';
// import MoneyText from '../../components/elements/MoneyText';
import * as actions from "../../actions";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import { connect } from "react-redux";
import { FETCH_SUPPLIERS } from "../../actions/types";
import EkoFormButtonGroup from "../../components/elements/form/EkoFormButtonGroup";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import { parseSelfEmploymentData } from "../../services/SelfEmploymentDataService";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      formFields: {},
      saving: false,
      successDisabled: false,
      spinner: false,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:12,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
  }

  defaultFormData() {
    return {
      insurancePolicy: {},
    };
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.setState({ formData: formData, errors: errors });
    this.getNewTagModalList();
  }

  componentDidMount() {
    this.props.fetchData("/supplier/list", FETCH_SUPPLIERS);
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    this.setState({ formData: formData, errors: {} });
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave(refresh, isChecked) {
    console.log('onSave');
    const redirectToBase = arguments[0];
    const self = this;
    self.setState({ saving: true });

    for (var i in this.props.suppliers) {
      if (
        this.props.suppliers[i].id ===
        this.state.formData.insurancePolicy.supplier_id
      ) {
        if (this.props.suppliers[i].currency !== "TRY") {
          toastr.error("Döviz tipi TRY dışında tedarikçi seçemezsiniz.");
          return;
        }
      }
    }

    let amount = Util.convertMoneyToDecimal(
      this.state.formData.insurancePolicy.amount || "0,00"
    );
    let start_date = Util.convertToDatabaseDate(
      this.state.formData.insurancePolicy.start_date
    );
    let end_date = Util.convertToDatabaseDate(
      this.state.formData.insurancePolicy.end_date
    );

    let insurancePolicy = Object.assign(
      {},
      self.state.formData.insurancePolicy,
      {
        newTagRows:this.state.newTagRows,
        amount: amount,
        start_date: start_date,
        end_date: end_date,
      }
    );

    if (!this.isPermitted()) {
      const selfEmploymentDatas = parseSelfEmploymentData();

      insurancePolicy.supplier_id =
        selfEmploymentDatas.self_employment.default_supplier;
    }

    let errors = self.state.errors;

    let func = "post";
    let url = "/insurancePolicy";

    if (insurancePolicy.id) {
      func = "put"; //NOTE edit işlemi
      url = "/insurancePolicy/" + insurancePolicy.id;
    }
    if (
      this.props.addDocument?.uri &&
      insurancePolicy.document_url !== this.props.addDocument?.uri
    ) {
      insurancePolicy.document_url = this.props.addDocument?.uri;
    }
    insurancePolicy.is_checked = isChecked === true;

    Api[func](url, insurancePolicy, function (response, postErrors) {
      if (postErrors) {
        errors.insurancePolicy = postErrors;
        if (postErrors.message) {
          toastr.error(postErrors.message);
        }

        self.setState({ saving: false, errors: errors });
      } else {
        if (insurancePolicy.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");
          self.setState({ saving: false, errors: {} });
          if (isChecked === true) {
            Api.get(
              `/salesInvoice/next/${self.state.formData?.insurancePolicy?.id}`,
              function (res, error) {
                if (res.item) {
                  const path = window.location.pathname;
                  const ex = path.split("/");
                  ex.pop();
                  ex.push(res.item.id);
                  self.setState({
                    saving: false,
                    errors: {},
                    formData: self.defaultFormData(),
                  });
                  window.location.href = ex.join('/');
                } else {
                  self.props.router.push(
                    "/dashboard/purchases/insurancePolicy"
                  );
                }
              }
            );
          } else {
            if (redirectToBase && redirectToBase === true) {
              self.props.redirectToBase();
            } else {
              self.props.router.push(
                "/dashboard/purchases/insurancePolicy/add"
              );
            }
          }
        } else {
          toastr.success("Başarılı", "Başarıyla Kaydedildi");
          if (redirectToBase && redirectToBase === true) {
            self.props.redirectToBase();
          } else {
            self.setState({
              saving: false,
              errors: {},
              formData: self.defaultFormData(),
            });
          }
        }

        // self.props.fetchData("/insurancePolicy/autoComplete",FETCH_OTHER_RECEIPT_AUTOCOMPLETE);
        //self.props.onSave();
      }
    });
  }

  cancelOperation() {
    this.props.router.push("/dashboard/purchases/insurancePolicy");
  }

  isPermitted = () => {
    return !CompanyTypePermission.isSelfEmployment();
  };
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props && this.props.editId) {
      Api.get("/company/newTagRecordDocumentList/12/" + this.props.editId, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const insurancePolicyProps = {
      onChange: this.setFieldChange.bind(this, "insurancePolicy"),
      onMount: this.setFieldMount.bind(this, "insurancePolicy"),
      formData: this.state.formData.insurancePolicy,
      errors: this.state.errors.insurancePolicy,
    };
    const policyTypes = this.props.viewOptions
      ? this.props.viewOptions.insurance_policies.insurance_policy_type
      : [];
    const isPermitted = this.isPermitted();
    const updatedAt = this.state?.formData?.insurancePolicy?.is_checked
      ? this.state?.formData?.insurancePolicy?.updated_at
      : null;
    const colClassName = isPermitted ? "col-md-3" : "col-md-4";
    const isSuper = this.props.companies.filter(c => c.company_type === 9).length > 0;
    return (
        <div>
          <div className="portlet-body">
            <EkoForm formId={"add_policy"}>
              <div className="row">
                {this.isPermitted() ? (
                    <div className={colClassName}>
                      <div className="form-group">
                        <EkoFormSelect
                            {...insurancePolicyProps}
                            searchable={true}
                            id="supplier_id"
                            clearable={true}
                            optionValue="name"
                            defaultText="Seçiniz"
                            options={this.props.suppliers}
                            label="Kimden"
                            isVertical
                        />
                      </div>
                    </div>
                ) : null}

                <div className={colClassName}>
                  <div className="form-group">
                    <EkoFormDate
                        {...insurancePolicyProps}
                        id="start_date"
                        dateFormat="DD-MM-YYYY"
                        clearable={false}
                        label="Poliçe Başlangıç"
                        isVertical
                    />
                  </div>
                </div>

                <div className={colClassName}>
                  <div className="form-group">
                    <EkoFormDate
                        {...insurancePolicyProps}
                        id="end_date"
                        dateFormat="DD-MM-YYYY"
                        clearable={false}
                        label="Poliçe Bitiş"
                        isVertical
                    />
                  </div>
                </div>

                <div className={colClassName}>
                  <div className="form-group">
                    <EkoFormInputText
                        {...insurancePolicyProps}
                        id="amount"
                        label="Ödenecek Tutar"
                        isVertical
                        type="money"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <EkoFormInputText
                        {...insurancePolicyProps}
                        label="Açıklama"
                        id="description"
                        isVertical
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <EkoFormInputText
                        {...insurancePolicyProps}
                        label="Poliçe No"
                        id="policyno"
                        isVertical
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <EkoFormSelect
                        {...insurancePolicyProps}
                        searchable={true}
                        id="insurance_policy_type"
                        clearable={true}
                        optionValue="name"
                        defaultText="Seçiniz"
                        options={policyTypes}
                        label="PoliçeTipi"
                        isVertical
                    />
                  </div>
                </div>
              </div>
            </EkoForm>
          </div>
          <div className="form-actions">
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                className="buttonProccess"
            >
              {isSuper ? (
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                  >
                    <EkoFormButton
                        label={"Kontrol Edildi"}
                        faClass="fa fa-check"
                        buttonsType={this.props.buttonsType || "default"}
                        className="btn yellow mt-ladda-btn ladda-button"
                        isLoading={this.props.saving}
                        disabled={!this.props?.editId}
                        onClick={this.onSave.bind(this, false, true)}
                    />{" "}
                    <span style={{fontWeight: "bold"}}>
                  {updatedAt ? Util.convertToDatabaseDateTime(updatedAt) : null}
                </span>
                  </div>
              ) : null}
              <EkoFormButtonGroup
                  label={this.props.buttonSaveLabel || "Kaydet"}
                  faClass="fa fa-plus"
                  className="btn blue mt-ladda-btn ladda-button"
                  isLoading={this.state.saving}
                  buttonsType={"default"}
                  onClick={this.onSave.bind(this, true)}
                  buttons={[
                    {
                      onClick: this.onSave.bind(this, false),
                      labelText: "Kaydet ve Yeni Ekle",
                    },
                  ]}
                  isCheckButton={true}
              />
              {/* /btn-group */}
              <button
                  style={{marginLeft: "10px", height: 34.2}}
                  className="btn dark btn-outline"
                  onClick={this.cancelOperation.bind(this)}
              >
                İptal
              </button>
            </div>
          </div>
          <hr/>
          <a onClick={this.openNewTagModal} style={{top: -10, position: "relative"}}>Etiket Ekle</a>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliers: state.data.suppliers,
    viewOptions: state.data.viewOptions,
    addDocument: state.page.addDocument,
    companies: state.data.companies || []
  };
}

export default connect(mapStateToProps, actions)(AddPolicy);
