import React, { Component } from "react";
import EkoModal from "../elements/EkoModal";
import {
  EkoFormButton,
  EkoFormRadioButton,
  EkoFormSelect
} from "../elements/EkoForm";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "../elements/EkoSpinner";
import { withRouter } from "react-router";

class GetInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { getIn: { is_detail: 0 } },
      saving: false,
      errors: {},
      formFields: {},
      title: "Fatura Tipi Seçiniz",
      reply: null,
      radioButtons: [
        {
          label: "Özet",
          id: "is_detail",
          radio_id: "no",
          value: 0,
          checked: true
        },
        {
          label: "Detaylı",
          id: "is_detail",
          radio_id: "yes",
          value: 1
        }
      ]
    };
  }

  setFieldChange(type, field, value) {
    let formData = { ...this.state.formData[type] };

    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    if (field === "template_id") {
      let selected = this.props.templates.filter(item => item.id === value);
      let properties = JSON.parse(selected[0].properties);
      formData.is_note = false;
      properties.canvasElements.forEach(item => {
        if (item.variableName === "specialNote") formData.is_note = true;
      });
    }

    let bigForm = { ...this.state.formData, [type]: { ...formData } };

    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  closeModal() {
    this.setState({
      saving: false,
      errors: {},
      formData: { getIn: {} },
      title: "Fatura Tipi Seçiniz"
    });
    this.props.closeModal();
  }

  insertUser() {
    const self = this;
    let type = this.state.formData.getIn.document_type;
    let uuid = this.props.uuid;
    self.setState({ saving: true, reply: true });
    Api.post(
      "/eInvoice/getIn",
      { type: type, reply: true, uuid: uuid },
      (response, error) => {
        if (response) {
          self.redirectInsert();
        } else
          toastr.error(
            "Hata",
            "Beklenmedik bir hata ile karşılaşıldı lütfen daha sonra tekrar deneyiniz."
          );
        self.setState({ saving: false, reply: false });
      }
    );
  }

  redirectInsert = () => {
    let type = this.state.formData.getIn.document_type;
    let uuid = this.props.uuid;
    if (type === 2 && this.state.formData.getIn.is_detail === 0)
      this.props.router.push(
        "/dashboard/purchases/purchaseInvoice/insert/" +
          type +
          "/" +
          uuid +
          "/summary"
      );
    else
      this.props.router.push(
        "/dashboard/purchases/purchaseInvoice/insert/" + type + "/" + uuid
      );
  };

  checkCustomer() {
    const self = this;
    let type = this.state.formData.getIn.document_type;
    let uuid = this.props.uuid;
    const customerOptions = {
      okText: "Evet",
      cancelText: "Hayır",
      onOk: () => self.insertUser(),
      onCancel: () => self.redirectInsert()
    };
    let customerText =
      type === 3
        ? "Bu müşteri kayıtlı değil kayıt etmek ister misiniz ?"
        : "Bu tedarikçi kayıtlı değil kayıt etmek ister misiniz ?";
    self.setState({ saving: true });
    Api.post(
      "/eInvoice/getIn",
      { type: type, isThere: true, uuid: uuid },
      (response, error) => {
        if (response) {
         if (response.isThere) {
            self.redirectInsert();
          } else {
           if (response.status === 'warning' ){
             let redirectPages = {
               1 : "/dashboard/purchases/purchaseInvoice/add/productServiceInvoice",
               2 : "/dashboard/purchases/purchaseInvoice/add/expenseInvoice",
               3 : "/dashboard/purchases/purchaseInvoice/add/customerReturnInvoice",
               4 : "/dashboard/purchases/purchaseInvoice/add/toFixedAssetInvoice"
             }
             toastr.confirm(
                 'Alınan faturanın xml formatı geçerli bir format değildir. Bu nedenle otomatik doldurma işlemini yerine getiremiyoruz',
                 {
                   okText: "Anladım",
                   cancelText: "Vazgeç",
                   onOk: () => self.props.router.push(redirectPages[this.state.formData.getIn.document_type]),
                   onCancel: () => this.closeModal()
                 }
             );

           }else{
             toastr.confirm(customerText, customerOptions);
           }
          }
        } else
          toastr.error(
            "Hata",
            "Beklenmedik bir hata ile karşılaşıldı lütfen daha sonra tekarar deneyiniz."
          );
        self.setState({ saving: false });
      }
    );
  }

  render() {
    const getInProps = {
      onChange: this.setFieldChange.bind(this, "getIn"),
      onMount: this.setFieldMount.bind(this, "getIn"),
      formData: this.state.formData.getIn,
      errors: this.state.errors.getIn
    };
    const getIn = this.state.formData.getIn;
    var user_json = JSON.parse(localStorage.getItem("user"));
    if(user_json.company_type == 9 || user_json.company_type == 6){
      var types = [
        { id: 2, name: "Gider Faturası" }
      ];
    }else{
      var types = [
        { id: 1, name: "Tedarikçiden Mal/Hizmet Faturası" },
        { id: 2, name: "Gider Faturası" },
        { id: 3, name: "Müşteriden Fatura (İade, Kur, Fiyat Farkı)" },
        { id: 4, name: "Demirbaş Faturası" },
      ];
    }
    let popover = (
      <Popover
        className={"tooltip"}
        bsClass="popoverClass"
        id="popover-positioned-left"
        title={""}
      >
        <span
          dangerouslySetInnerHTML={{
            __html:
              "<br/>Özet seçilmesi durumunda, Fatura tutarı sadece tek satır olarak aktarılacaktır.<br/><br/>" +
              "Detay seçilmesi durumunda ise Faturadaki ürün kalemleri tek tek aktarılacaktır.<br/>" +
              "Fakat Telekomünikasyon, Internet, Su, Elektrik faturaları gibi KDV dışında ÖİV vs.... gibi başka vergi tiplerini barındıran faturalarda her tedarikçinin efatura formatı farklı olabildiğinden dolayı  fatura dip toplamı tutturmakta sıkıntı yaşanabilmektedir.<br/><br/>"
          }}
        />
      </Popover>
    );
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={this.state.title}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <div className="row">
          {this.state.reply ? (
            <div className={"col-md-push-1 col-md-10"}>
              <p>
                Lütfen bekleyiniz{" "}
                {getIn.document_type === 3 ? "müşteri" : "tedarikçi"}{" "}
                kaydediliyor
              </p>
              <hr />
              <EkoSpinner />
            </div>
          ) : (
            <div className={"col-md-push-1 col-md-10"}>
              <p>Faturayı içeri almak için fatura tipini seçip devam ediniz.</p>
              <hr />
              <div className="col-md-12">
                <EkoFormSelect
                  {...getInProps}
                  label="Fatura Tipi"
                  labelMd={3}
                  colMd={9}
                  optionId={"id"}
                  options={types}
                  id="document_type"
                />
              </div>
              {this.state.formData.getIn.document_type === 2 ? (
                <div
                  style={{ textAlign: "center" }}
                  className="col-md-push-2 col-md-10"
                >
                  <br />
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement={"left"}
                    overlay={popover}
                  >
                    <button className={"popover-button"}>
                      <i className="fa fa-question-circle popovers popover-fa" />
                    </button>
                  </OverlayTrigger>
                  <EkoFormRadioButton
                    {...getInProps}
                    id="radioButtons"
                    buttons={this.state.radioButtons}
                    onFalse={this.onFalse}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <Modal.Footer>
          <br />
          <EkoFormButton
            label={"Kayıtlara İşle"}
            faClass="fa fa-download"
            isLoading={this.state.saving}
            disabled={!getIn.document_type}
            onClick={this.checkCustomer.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            disabled={this.state.saving}
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </Modal.Footer>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(withRouter(GetInModal));
