import Money from "./helper/Money";

class UtilHelper {

    constructor(){
        this.money = new Money();
    }

}


export default new UtilHelper();