/**
 * Created by mehmetyurtar on 31/08/2017.
 */


export const config = {
    parentElements: [
        {
            "label": "Musteri Bilgileri",
            "name": "customer-info",
            "isOpen": false
        },
        {
            "label": "Fatura Bilgileri",
            "name": "invoice-info",
            "isOpen": false
        },
        {
            "label": "Hizmet / Urun Bilgileri",
            "name": "service-info",
            "isOpen": false,
            "isFixedTable": true
        },
        {
            "label": "Toplam Miktar Bilgileri",
            "name": "total-info",
            "isOpen": true
        },
        {
            "label": "Ozel Notlar",
            "name": "other-notes",
            "isOpen": false
        }
    ],
    mainElements: [
        {
            "label": "Müşteri Ünvanı",
            "variableName": "company_name",
            "icon": "user",
            "parentName": "customer-info",
            "placeHolder": "Müşteri Ünvanı"
        },
        {
            "label": "Müşteri Adresi",
            "variableName": "address",
            "icon": "map-marker",
            "parentName": "customer-info",
            "placeHolder": "Müşteri Adresi bir mah. no:1 daire:1 ilçe,il"
        },
        {
            "label": "Müşteri Email",
            "variableName": "email",
            "icon": "envelope",
            "parentName": "customer-info",
            "placeHolder": "ornek@ekohesap.com"
        },
        {
            "label": "Müşteri Telefon",
            "variableName": "phone",
            "icon": "phone",
            "parentName": "customer-info",
            "placeHolder": "05555555555"
        },
        {
            "label": "Müşteri Bakiye",
            "variableName": "balance",
            "icon": "balance-scale",
            "parentName": "customer-info",
            "placeHolder": "1,000"
        },
        {
            "label": "Vergi Dairesi",
            "variableName": "tax_office_name",
            "icon": "gavel",
            "parentName": "customer-info",
            "placeHolder": "Vergi Dairesi"
        },
        {
            "label": "Vergi Numarası",
            "variableName": "tax_identity_number",
            "icon": "gavel",
            "parentName": "customer-info",
            "placeHolder": "123456789",
            "hidden": false
        },
        {
            "label": "Vergi Bilgileri",
            "variableName": "name_and_identity",
            "icon": "gavel",
            "parentName": "customer-info",
            "placeHolder": "Vergi Dairesi 1234567890"
        },
        {
            "label": "Fatura Numarası",
            "variableName": "legal",
            "icon": "file-alt",
            "parentName": "invoice-info",
            "placeHolder": "S123456"
        },
        {
            "label": "Fiili Sevk Tarihi",
            "variableName": "shipping_date",
            "icon": "truck",
            "parentName": "invoice-info",
            "placeHolder": "01-01-2017"
        },
        {
            "label": "Fiili Sevk Saati",
            "variableName": "shipping_hour",
            "icon": "truck",
            "parentName": "invoice-info",
            "placeHolder": "13:30"
        },
        {
            "label": "Ödeme Tarihi",
            "variableName": "due_date",
            "icon": "calendar",
            "parentName": "invoice-info",
            "placeHolder": "01-01-2017"
        },
        {
            "label": "Düzenlenme Tarihi",
            "variableName": "date",
            "icon": "file-alt",
            "parentName": "invoice-info",
            "placeHolder": "01-01-2017"
        },
        {
            "label": "Kur",
            "variableName": "currency_rate",
            "icon": "usd",
            "parentName": "invoice-info",
            "placeHolder": "5,80"
        },
        {
            "label": "İrsaliye Tarihi",
            "variableName": "irsaliye_date",
            "icon": "calendar",
            "parentName": "invoice-info",
            "placeHolder": "01-01-2017"
        },
        {
            "label": "İrsaliye Numarası",
            "variableName": "irsaliye_no",
            "icon": "sort-numeric-desc",
            "parentName": "invoice-info",
            "placeHolder": "12345"
        },
        {
            "label": "Hizmet / Urun Kalemleri",
            "variableName": "hizmet1",
            "icon": "bars",
            "parentName": "service-info",
            "placeHolder": "123456789",
            "isFixedTable": true
        },
        {
            "label": "Tutar",
            "variableName": "price_amount",
            "icon": "plus",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "İskonto/Toplam İndirim",
            "variableName": "discount_amount",
            "icon": "minus",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Toplam/Fatura Matrahı",
            "variableName": "net_amount",
            "icon": "info",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Toplam Kdv",
            "variableName": "vat_amount",
            "icon": "gavel",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Kdv Detay",
            "variableName": "kdv_detail",
            "icon": "gavel",
            "parentName": "total-info",
            "placeHolder": "%18  35 \n %8  35"
        },
        {
            "label": "Genel Toplam",
            "variableName": "total_amount",
            "icon": "plus",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "KDV Tevkifatı",
            "variableName": "vat_reduction_amount",
            "icon": "gavel",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Stopaj",
            "variableName": "stoppage_amount",
            "icon": "gavel",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Ödenecek Tutar",
            "variableName": "receivable_amount",
            "icon": "minus",
            "parentName": "total-info",
            "placeHolder": "100,000"
        },
        {
            "label": "Yazı ile Toplam",
            "variableName": "write_total",
            "icon": "pencil",
            "parentName": "total-info",
            "placeHolder": "Yüz bin"
        },
        {
            "label": "Metin Alani",
            "variableName": "freeText",
            "icon": "text-width",
            "parentName": "other-notes",
            "placeHolder": "Örnek Metin",
            "isFreeText": true
        },
        {
            "label": "Yazdırmaya Ozel Not",
            "variableName": "specialNote",
            "icon": "edit",
            "parentName": "other-notes",
            "placeHolder": "Ozel Notlarınızı bu alana yazdırabilirsiniz."
        }
    ],
    tableFields: [
        {
            "variableName": "item_name",
            "label": "Hizmet/Urun",
            "labelOnTable": "Hizmet veya urun tanimi",
            "descOnTable": "Hizmet veya urun aciklamasi",
            "checked": true,
            "width": 300,
            "order": 0
        },
        {
            "variableName": "item_code",
            "label": "Kod",
            "checked": true,
            "labelOnTable": "#",
            "width": 96,
            "order": 1
        },
        {
            "variableName": "description",
            "label": "Aciklama",
            "labelOnTable": "Hizmet veya urun aciklamasi",
            "checked": true,
            "width": 150,
            "order": 2
        },
        {
            "variableName": "quantity",
            "label": "Miktar",
            "labelOnTable": "1",
            "checked": true,
            "width": 58,
            "order": 3
        },
        {
            "variableName": "unit",
            "label": "Birim",
            "labelOnTable": "birim",
            "checked": false,
            "width": 100,
            "order": 4
        },
        {
            "variableName": "unit_price",
            "label": "Birim Fiyat",
            "labelOnTable": "1.000,00",
            "checked": false,
            "width": 87,
            "order": 5
        },
        {
            "variableName": "net_amount",
            "label": "Tutar",
            "labelOnTable": "1.000,00",
            "checked": false,
            "width": 87,
            "order": 5
        },
        {
            "variableName": "discount_amount",
            "label": "Indirim",
            "labelOnTable": "0,00",
            "checked": false,
            "width": 100,
            "order": 6
        },
        {
            "variableName": "vat_amount",
            "label": "KDV Miktar",
            "labelOnTable": "200",
            "checked": false,
            "width": 100,
            "order": 7
        },
        {
            "variableName": "vat_amount_percent",
            "label": "KDV Yüzde",
            "labelOnTable": "%18",
            "checked": false,
            "width": 100,
            "order": 7
        },
        {
            "variableName": "total_amount",
            "label": "Toplam",
            "labelOnTable": "1.000,00",
            "checked": false,
            "width": 100,
            "order": 8
        }
    ],
    canvasElements: [
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "width": 164,
            "height": 20,
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "hizmet1",
            "label": "Hizmet / Urun Kalemleri",
            "placeHolder": "123456789",
            "positionX": 18,
            "positionY": 206,
            "parentName": "service-info",
            "isFixedTable": true
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "width": 162,
            "height": 20,
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "company_name",
            "label": "Müşteri Ünvanı",
            "placeHolder": "Test musteri",
            "positionX": 18,
            "positionY": 100,
            "parentName": "customer-info"
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "width": 162,
            "height": 45,
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "address",
            "label": "Müşteri Adresi",
            "placeHolder": "Müşteri Adresi bir mah. no:1 daire:1 ilçe,il",
            "positionX": 18,
            "positionY": 122,
            "parentName": "customer-info"
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "width": 160,
            "height": 20,
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "name_and_identity",
            "label": "Vergi Bilgileri",
            "placeHolder": "Vergi Dairesi 1234567890",
            "positionX": 18,
            "positionY": 170,
            "parentName": "customer-info"
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "width": 100,
            "height": 20,
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "date",
            "label": "Düzenlenme Tarihi",
            "placeHolder": "01-01-2017",
            "positionX": 427,
            "positionY": 49,
            "parentName": "invoice-info"
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "price_amount",
            "label": "Tutar",
            "placeHolder": "100,000",
            "positionX": 408,
            "positionY": 616,
            "parentName": "total-info",
            "width": 100,
            "height": 20
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "discount_amount",
            "label": "İskonto/Toplam İndirim",
            "placeHolder": "100,000",
            "positionX": 408,
            "positionY": 648,
            "parentName": "total-info",
            "width": 100,
            "height": 20
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "vat_amount",
            "label": "Toplam Kdv",
            "placeHolder": "100,000",
            "positionX": 409,
            "positionY": 677,
            "parentName": "total-info",
            "width": 100,
            "height": 20
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "total_amount",
            "label": "Genel Toplam",
            "placeHolder": "100,000",
            "positionX": 409,
            "positionY": 707,
            "parentName": "total-info",
            "width": 100,
            "height": 20
        },
        {
            "fontSize": "12",
            "isBold": false,
            "isItalic": false,
            "textAlign": "left",
            "fontFamily": "'PT Sans', sans-serif",
            "fontWeight": "normal",
            "fontStyle": "normal",
            "variableName": "write_total",
            "label": "Yazı ile Toplam",
            "placeHolder": "Yüz bin",
            "positionX": 18,
            "positionY": 616,
            "parentName": "total-info",
            "width": 100,
            "height": 20
        }
    ],
    selectedEl: {
        "variableName": "write_total",
        "label": "Yazı ile Toplam",
        "placeHolder": "Yüz bin",
        "positionX": 18,
        "positionY": 616,
        "parentName": "total-info",
        "width": 100,
        "height": 20
    },
    paperWidth: 595,
    paperHeight: 842,
    paperOrientation: "v",
    paperBackground: "",
    useDescriptionWithProduct: false,
    componentDefaults: {
        "fontSize":   "12",
        "isBold": false,
        "isItalic": false,
        "textAlign": "left",
        "width": 100,
        "height": 20,
        "fontFamily": "'PT Sans', sans-serif",
        "fontWeight": "normal",
        "fontStyle": "normal",
    },
    isElementSelected: true,
    customPaperSize: false,
    isPrinting: false,
    mainElversion: 0,
    templateName: "Yeni Şablon"
};

export default config;