import React, { useEffect, useRef, useState } from "react";
import { EkoFormButton } from "../elements/EkoForm";
import Resizer from "react-image-file-resizer";
import EkoImageUpload from "../elements/form/EkoImageUpload";
import { connect, useDispatch, useSelector } from "react-redux";
import { setAddDocument } from "../../actions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Document(props) {
    const { addDocument, setAddDocument } = props;
    const [size, setSize] = useState({width: null, height: null});
    const imgRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [thePosition, setPosition] = useState("positionDefault");

    useEffect(() => {
        if(addDocument.uri){
            setSize({width: null, height: null});
        }
    },[addDocument.uri])

    useEffect(() => {
        return () => {
            setAddDocument({ uri: null, type: null });
        };
    }, [setAddDocument]);

    const handleExpandClick = () => {
        setIsExpanded(true);
    };

    const handleCollapseClick = () => {
        setIsExpanded(false);
    };
    const handlePositionChange = () => {
        // positions: default, top, left - Change the position on each click
        if(thePosition === "positionDefault"){
            setPosition("positionTop");
            window.scrollTo(0, 0);
        } else if(thePosition === "positionTop"){
            setPosition("positionLeft");
        } else if(thePosition === "positionLeft"){
            setPosition("positionDefault");
        } else {
            setPosition("positionDefault");
        }
    };

    return (
        <div
            className={thePosition + " portlet light" + (isExpanded ? " expanded" : "")}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "stretch",
                // paddingBottom: 100,
                width: 600,
                marginLeft: 10,
            }}
        >
            {addDocument.type === "application/pdf" || addDocument.type === "pdf" ? (
                <iframe
                    id="iframe1"
                    style={{ height: "100%", width: "100%" }}
                    src={`${addDocument.uri}#page=1&zoom=65`}
                ></iframe>
            ) : (
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={0}
                    initialPositionY={0}
                    width="100%"
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <div className="tools">
                                <button onClick={() => zoomIn()}>+</button>
                                <button onClick={() => zoomOut()}>-</button>
                                <button onClick={() => resetTransform()}>x</button>
                            </div>
                            <TransformComponent>
                                <img ref={imgRef} style={{ width: "100%", minHeight: 650 }} src={addDocument.uri} />
                            </TransformComponent>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            )}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 10 }} id="positionToolbar">
                <button id={"expand-button"} onClick={handleExpandClick} className={thePosition === "positionDefault" ? "btn-disabled" : ""}><i className={thePosition === "positionLeft" ? "fa fa-arrow-right" : "fa fa-arrow-down"}></i></button>
                <button id={"non-expand-button"} onClick={handleCollapseClick} className={thePosition === "positionDefault" ? "btn-disabled" : ""}><i className={thePosition === "positionLeft" ? "fa fa-arrow-left" : "fa fa-arrow-up"}></i></button>
                <button id={"change-position"} onClick={handlePositionChange}><i className="fa fa-undo"></i></button>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        addDocument: state.page.addDocument,
    };
}

export default connect(mapStateToProps, { setAddDocument })(Document);
