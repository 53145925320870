import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";
import Api from '../../util/Api'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";


class WageSheetDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            records: [],
            formData: {},
            page_loading: true,
            wageDetails: {}
        }

    }
    componentWillMount() {
        this.fetchRecordedData();
    }
    componentDidMount() {
        
    }
    fetchRecordedData() {
        var self = this;
        this.setState({page_loading: true, showInsertModal: false, formData: false, records: []});
        Api.get("/company/getCompanyWageDetails/"+self.props.params.id, (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({records: getData, page_loading: false, wageDetails: response.wage });
        });
    }
    deleteRecord(record) {
        var self = this;
        var itemId = record.id;
        var employee_id = record.employee_id;
        toastr.confirm('Kayıt ile beraber ilgili hareketler de silinecektir. Onaylıyor musunuz?',
            {
                onOk: () => {
                    Api.put("/company/deleteCompanyWageSheetItem", {id: itemId, employee_id: employee_id}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == "1") {
                                toastr.success("Başarılı", "Kayıt silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                //self.fetchRecordedData();
                                document.getElementById('row_' + itemId).remove();
                            }else{
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }

    render() {
        return (<>
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase">
                            YÜKLENEN BORDRO DETAYLARI ({this.state.wageDetails.month < 9 ? '0'+this.state.wageDetails.month : this.state.wageDetails.month}/{this.state.wageDetails.year})
                        </span>
                    </div>
                    <div style={{float: 'right'}}>
                        <button onClick={() => { this.props.router.push('/dashboard/employee') } } className='btn btn-sm blue'>Yüklenen Bordrolar Listesi</button>
                    </div>
                </div>
                <div className="portlet-body">
                    {this.state.page_loading == true ? <EkoSpinner /> : 
                        <>

                            <table className='table table-striped'>
                                <thead>
                                <tr>
                                    <th>Adı Soyadı</th>
                                    <th>TC Kimlik</th>
                                    <th>Çalışan Kaydı</th>
                                    <th>Yüklenen Bordro</th>
                                    <th>İşlem</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.records.map((record) =>
                                        <tr key={record.id} id={'row_' + record.id}>
                                            <td>{record.name_surname}</td>
                                            <td>{record.tc_number}</td>
                                            <td>{record.employee_is_new == 1 ? 'Yeni eklendi' : 'Mevcut'}</td>
                                            <td><button onClick={() => { this.props.router.push('/dashboard/wagesheet-details/'+record.id) } } className='btn btn-sm blue'><i className='fa fa-eye'></i></button></td>
                                            <td>
                                                <button type='button' className='btn btn-sm btn-danger' onClick={this.deleteRecord.bind(this, record)}><i className='fa fa-trash'></i></button>
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    this.state.records.length == 0 ? <><tr key="0"><td colSpan={5} style={{textAlign: "center"}}>Kayıt bulunamadı</td></tr></> : ""
                                }
                                </tbody>
                            </table>

                        </>
                    }
                </div>
            </div>
            
        </>)
    }
}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(WageSheetDetails);
