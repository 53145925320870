import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import EkoInputTabRow from "./EkoInputTabRow";
import { array_fill } from "../../services/ArrayHelper";

const EkoInputTab = props => {
  let columnLength = 12;
  let columnCount = 1;
  let rows = [];

  if (!Array.isArray(props.rows)) {
    rows = props.rows.columns;
    columnCount = rows.length;
    columnLength = 12 / columnCount;
  } else {
    rows = [props.rows];
  }

  let columns = Object.values(array_fill(0, columnCount, columnLength));

  return (
    <Col md={12}>
      {columns.map((t, i) => {
        let row = rows[i];

        return (
          <Col key={i} md={props.md || columnLength}>
            {row.map((input, index) => (
              <EkoInputTabRow
                inputProps={props.inputProps}
                row={input}
                key={index}
              />
            ))}
          </Col>
        );
      })}
    </Col>
  );
};

EkoInputTab.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  inputProps: PropTypes.object.isRequired,
  md: PropTypes.number
};

export default EkoInputTab;
