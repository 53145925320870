import React, {Component} from 'react'

import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import { CSS } from "../../css/tab_datatable";
import AddAsset from './AddDepreciation'
import {toastr} from 'react-redux-toastr'
import HelperContainer from '../../util/HelperContainer'
import Util from '../../util/Util';
import Api from '../../util/Api'
import moment from 'moment'

import Style from "style-it";

class Depreciation extends Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showInsertModal: false,
            errors: {},
            validationFields: [],
            assets: [],
            tableTitle:"",
        }

    }


    fetchAssets() {
        var url = "/fixedAsset/depreciationList";
        Api.get(url, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response;
                this.setState({assets:getData });
            }
        });
    }

    componentWillMount() {
        this.fetchAssets();
    }
    fixedAssetAccountTransaction(selectedRow) {
        window.location = "/dashboard/assets/" + selectedRow.id;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.assets) {
            this.setState({assets: nextProps.assets});
        }
    }

    openUpdate(selectedRow) {
        var self = this;
        let formData = {}
        Api.get('/fixedAsset/' + selectedRow.id,
            function (response) {
                formData = {}
                if (response.fixedAsset) {
                    formData.asset = HelperContainer.filterObject(response.fixedAsset);
                    try {
                        formData.asset.purchase_price = Util.convertDecimalToMoney(formData.asset.purchase_price)
                        formData.asset.vat_amount = Util.convertDecimalToMoney(formData.asset.vat_amount)
                        formData.asset.otv_amount = Util.convertDecimalToMoney(formData.asset.otv_amount)
                        formData.asset.accumulated_depreciation = Util.convertDecimalToMoney(formData.asset.accumulated_depreciation)
                        formData.asset.depreciation_term = formData.asset.depreciation_term || formData.asset.depreciation_term === 0 ?
                            formData.asset.depreciation_term.toString() : "";
                        formData.asset.purchase_date = Util.convertFromDatabaseDate(formData.asset.purchase_date);

                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            })
    }

    onDelete(selectedRow) {
        const self = this;
        Api.delete("/fixedAsset/" + selectedRow.id, function (response, err) {
            if (response) {
                self.fetchAssets();
                toastr.success('Başarılı', 'Başarıyla Silindi');
            }
            else toastr.error('Hata', err.message);
        })
    }

    openInsert(menuItem) {
        if (menuItem === 1) {
            this.props.router.push("/dashboard/fixedAsset/add");
        }else if (menuItem === 2) {
            this.setState({showInsertModal: true, formData: false})
        } else if (menuItem === 3) {
            this.props.router.push("/dashboard/fixedAsset/add");
        }

    }

    onSave(formData, formFields) {
        this.fetchAssets();
        this.setState({showInsertModal: false, formData: false});
    }
    get items() {
        var list = [];
        list =  [
            {
                id: 1,
                link: '/dashboard/assets',
                title: 'Demirbaşlar'
            },
            {
                id: 2,
                link: '/dashboard/fixedAsset',
                title: 'Demirbaş Alış Faturaları'
            },
            {
                id: 4,
                link: '/dashboard/fixedAssetSalesInvoice',
                title: 'Demirbaş Satış Faturaları'
            },
            {
                id: 3,
                link: '/dashboard/depreciation',
                title: 'Amortismanlar'
            }
        ];
        return list;
    }
    render() {
        const insertMenu = [
            {id: 2, name: 'Amortisman Ekle'},
            {id: 1, name: 'Demirbaş Alış Faturası Ekle'},
            {id: 3, name: 'Demirbaş Satış Faturası Ekle'},
        ];
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <AddAsset
                    showModal={this.state.showInsertModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showInsertModal: false})
                    }}
                    errors={this.state.errors}
                />
                {Style.it(
                    CSS,
                    <div>{
                        <EkoDataTable
                            tabMenu={this.items}
                            selectedTab={3}
                            data={this.state.assets}
                            addDeleteShow={false}
                            addUpdateShow={false}
                            addButtonShow={false}
                            fixedAssetAccountTransaction={this.fixedAssetAccountTransaction.bind(this)}
                            name={"depreciation"}>
                        </EkoDataTable>
                    }</div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        assets: state.data.assets,
    };
}

export default connect(mapStateToProps, actions)(Depreciation);
