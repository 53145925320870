import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";

import Api from "../../util/Api";

class WarehouseProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            products: [] //table Item
        };
    }

    fetchCollections(id) {

        let endpoint;
        if(id === undefined){
            endpoint = '/warehouses/products';
        }
        else{
            endpoint = '/warehouses/products/' + id;
        }


        Api.get(endpoint, (res, err) => {
            this.setState({
                products: res.products
            })
        })
    }

    componentWillMount() {
        const id = this.props.params && this.props.params.id;
        console.log("hello",id)
        if(id !== undefined && id !== "0" && id !== 0){
            this.fetchCollections(id);
        }else{
            this.fetchCollections();

        }
    }


    render() {
        return (
            <div>
                <EkoDataTable
                    data={this.state.products}
                    name={"warehouse_products"}
                    showDelete={false}
                    title={"Depodaki Ürünler"}
                    addButtonShow={false}
                    headers={[
                        {
                            id: 1,
                            header_name: "Ürün Adı",
                            column_name: "item_name",
                            status: 1
                        },
                        {
                            id: 2,
                            header_name: "Ürün Kodu",
                            column_name: "item_code",
                            status: 1
                        },
                        {
                            id: 3,
                            header_name: "Adet",
                            column_name: "quantity",
                            status: 1
                        }
                    ]}
                ></EkoDataTable>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps, actions)(WarehouseProducts);
