import React, {Component} from 'react';
import EkoDataTable from "../../components/elements/EkoDataTable";
import Style from "style-it";
import {CSS} from "../../css/tab_datatable";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import SalesShipmentSendModal from "../../components/forms/SalesShipmentSendModal";
import EkoModal from "../../components/elements/EkoModal";
import Util from "../../util/Util";
class SalesShipmentList extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showInsertModal: false,
            selectedRow: {},
            data: [],
            customer_list: [],
            tableLoading: false,
            showSendModal: false,
            showSimilarModal: false,
            transformInvoiceAllModal: false,
            SendButtonDisableController:false,
            checkBox: [],
            selectedSendId: 0,
            selectedSimilarId: 0,
            receiver:[{ identifier: "" }],
            partialTransformInvoiceRow: [],
            partialTransformInvoiceModal:false,
            sortConfig: { key: '', direction: '' },
            searchQuery: '',
        }
    }

    componentWillMount() {
        this.fetchData();
        this.fetchCustomers();
    }

    fetchData = () => {
        this.setState({
            tableLoading: true
        })
        Api.get('/salesShipment/list', (res, err) => {
            if (!err) {
                this.setState({
                    data: res.items,
                    tableLoading: false
                })
            }
        })
    }

    fetchCustomers = () => {
        Api.get("/customer/list",
            (res, err) => {
                if (res.status === "ok") {
                    this.setState({
                        customer_list: res.customers
                    });
                }
            }
        );
    };

    onSelectedRowChange = (row) => {
        this.setState({
            selectedRow: row
        })
    }

    // openInsert = () => {
    //     this.props.router.push('/dashboard/salesInvoice/salesShipment/add');
    // }

    openUpdate = () => {
        this.props.router.push('/dashboard/salesInvoice/salesShipment/edit/' + this.state.selectedRow.id);
    }

    onDelete = () => {
        const {id} = this.state.selectedRow;
        Api.delete('/salesShipment/' + id, (response, errors) => {
            if (response && response.status === 'ok') {
                toastr.success('Başarılı', response.message)
                this.fetchData()
            }
            if (errors) {
                toastr.error('HATA', response.message)
            }
        })
    }

    openInsert(menuItem) {
        //localStorage.setItem("new_collection_from_who_type", menuItem);

        if (menuItem === 1) {
            this.props.router.push("/dashboard/salesInvoice/add/toCustomer");
        } else if (menuItem === 2) {
            this.props.router.push("/dashboard/salesInvoice/add/toSupplier");
        } else if (menuItem === 3) {
            this.props.router.push("/dashboard/salesInvoice/zreports/add");
        } else if (menuItem === 4) {
            this.props.router.push("/dashboard/salesInvoice/proforma/add");
        }else if (menuItem === 5) {
            this.props.router.push('/dashboard/salesInvoice/otherSales/add')
        }else if (menuItem === 6) {
            this.props.router.push('/dashboard/salesInvoice/soleprofv2/add')
        } else if (menuItem === 7) {
            this.props.router.push('/dashboard/salesInvoice/salesShipment/add')
        }
    }

    get items() {
        var user_json = JSON.parse(localStorage.getItem("user"));
        if(user_json.company_type == 9 || user_json.company_type == 6){
            window.location.replace("/dashboard/salesInvoice/soleprofv2s");
        }
        if(user_json.company_type == 9 || user_json.company_type == 6){
            return [
                {
                    id: 5,
                    link: "/dashboard/salesInvoice/soleprofv2s",
                    title: "Serbest Meslek Makbuzu"
                },
                {
                    id: 2,
                    link: "/dashboard/salesInvoice/zreports",
                    title: "Z Raporları"
                },
                {
                    id: 3,
                    link: '/dashboard/salesInvoice/proformas',
                    title: 'Teklifler/Siparişler'
                },
                {
                    id: 4,
                    link: "/dashboard/salesInvoice/otherSales",
                    title: "Diğer Gelirler"
                }
            ];
        }else{
            return [
                {
                    id: 1,
                    link: '/dashboard/salesInvoice',
                    title: 'Satışlar'
                },

                {
                    id: 2,
                    link: '/dashboard/salesInvoice/zreports',
                    title: 'Z Raporları'
                },
                {
                    id: 3,
                    link: '/dashboard/salesInvoice/proformas',
                    title: 'Teklifler/Siparişler'
                },
                {
                    id: 4,
                    link: "/dashboard/salesInvoice/otherSales",
                    title: "Diğer Gelirler"
                },
                {
                    id: 7,
                    link: "/dashboard/salesInvoice/salesShipment",
                    title: "Sevk İrsaliyesi"
                },
            ]
        }
    }
    partialTransformInvoice(selectedRow) {
        Api.get(
            '/salesShipment/show/'+selectedRow.id,
            (response, err) => {
                if (response && response.status === 'ok') {
                    this.setState({partialTransformInvoiceRow: response.lines,checkBox:[],SendButtonDisableController:false,partialTransformInvoiceModal:true})
                }
                if (err) {
                    toastr.error('HATA', response.message)
                    this.setState({partialTransformInvoiceRow: [],checkBox:[],SendButtonDisableController:false,partialTransformInvoiceModal:false})
                }
            }
        );
    }
    transformInvoice(selectedRow) {
        this.props.router.push("/dashboard/salesInvoice/add/toCustomer?shipment=" + selectedRow.id);
      }
    transformInvoiceAll() {
        this.setState({transformInvoiceAllModal: true})
    }

    transformInvoiceAllSend() {
        if(this.state.checkBox.length>0){
            const customerIds = new Set();

            this.state.data.forEach((item, index) => {
                if (this.state.checkBox.some(element => element.id === item.id)) {
                    customerIds.add(item.customer_id);
                }
            });
            if (customerIds.size === 1) {
                this.props.router.push("/dashboard/salesInvoice/add/toCustomer?shipment=" + this.state.checkBox.map((item)=>item.id).join(","));
            } else {
                toastr.error('HATA', 'Seçtiğiniz irsaliyelerin müşterileri aynı olmalıdır.');
                return;
            }
            //
            /*
            @oguz aşağıdaki kod yanlış anlaşıldığı için iptal edildi. Gerekli görülürse kullanılabilir. İşlevi seçilen irsaliyeleri faturaya dönüştürmekti.
            Api.post(
                `/salesShipment/transformInvoiceAll`,
                {sales_shipment_ids: this.state.checkBox},
                (response, err) => {
                    if (response && response.status === 'ok') {
                        toastr.success('Başarılı', response.message)
                        this.setState({transformInvoiceAllModal: false,checkBox:[],SendButtonDisableController:false})
                        this.fetchData()
                    }
                    if (err) {
                        toastr.error('HATA', response.message)
                        this.setState({transformInvoiceAllModal: false,checkBox:[],SendButtonDisableController:false})
                    }
                }
            );*/
        }
    }
    transformInvoiceAllcloseModal() {
        this.setState({transformInvoiceAllModal: false,checkBox:[],SendButtonDisableController:true})
    }
    partialTransformInvoiceCloseModal() {
        this.setState({partialTransformInvoiceModal:false,partialTransformInvoiceRow: [],checkBox:[],SendButtonDisableController:true})
    }

    partialTransformInvoiceAllSend() {
        if(this.state.checkBox.length>0){
            this.props.router.push("/dashboard/salesInvoice/add/toCustomer?shipmentLine=" + this.state.checkBox.map((item)=>item.id).join(","));
        }
    }

    checkboxChange = (invoiceId) => {
        invoiceId = parseInt(invoiceId);
        const checkBox = this.state.checkBox.slice(); // Create a copy of the checkBox array
        const index = checkBox.findIndex(element => element.id === invoiceId);

        if (index === -1) {
            // If the item is not found, add it to the array
            checkBox.push({ id: invoiceId });
        } else {
            // If the item is found, remove it from the array
            checkBox.splice(index, 1);
        }

        this.setState({ checkBox }); // Update the state with the new checkBox array
    }
    similarSalesShipment(state){
        this.setState({showSimilarModal:true,selectedSimilarId:state.selectedRow.id});
    }
    sendSimilarSalesShipment(){
        var self = this;
        var customerIds = this.state.checkBox.map((item)=>item.id).join(",");
        Api.get('/salesShipment/similar/'+customerIds+"/"+self.state.selectedSimilarId, (res, err) => {
            if (!err) {
                toastr.success('Başarılı', res.message)
                this.fetchData();
            }else{
                toastr.error('HATA', "Bir hata oluştu.");
            }
        })
        this.showSimilarModalcloseModal();
    }
    showSimilarModalcloseModal() {
        this.setState({showSimilarModal: false,checkBox:[],selectedSimilarId:0})
    }
    salesShipmentSendButton(state) {
        const self = this;
        let integrator = JSON.parse(localStorage.getItem("integrator"));
        if (integrator === 1) {
            //@oguz sovos gonderimi
            Api.post(
                `/nesv2/salesShipmentReceiverList`,
                {sales_shipment_id: state.selectedRow.id},
                (response, err) => {
                    this.setState({showSendModal:true,selectedSendId:state.selectedRow.id,receiver:response});
                }
            );
        } else if (integrator === 2) {
            toastr.error('HATA', "Entegratörünüz ile E-İrsaliye Gönderimi Yapamazsınız.")
        }
        else if (integrator === 3) {
            //@oguz nesv2 gönderimi
                    Api.post(
                        `/nesv2/salesShipmentReceiverList`,
                        {sales_shipment_id: state.selectedRow.id},
                        (response, err) => {
                            this.setState({showSendModal:true,selectedSendId:state.selectedRow.id,receiver:response});
                        }
                    );
        }
    }
    handleSort = (key) => {
        this.setState(prevState => {
            let direction = 'ascending';
            if (prevState.sortConfig.key === key && prevState.sortConfig.direction === 'ascending') {
                direction = 'descending';
            }
            return { sortConfig: { key, direction } };
        });
    };

    getSortedData = () => {
        const { data, sortConfig } = this.state;
        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    };

    getFilteredData = () => {
        const { searchQuery } = this.state;
        const sortedData = this.getSortedData();
        return sortedData.filter(
            item =>
                item.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.document_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    render() {
        const insertMenux = this.props.viewOptions
            ? this.props.viewOptions.sales.addRecordMenu
            : false;
        if(insertMenux!=false){
            var user_json = JSON.parse(localStorage.getItem("user"));
            if(user_json.company_type == 9 || user_json.company_type == 6){
                var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 2);
                insertMenu.unshift({id: 6, name: 'Serbest Meslek Makbuzu'});
            }else{
                insertMenu = insertMenux;
            }
        }
        const filteredData = this.getFilteredData();
        const { checkBox } = this.state;

        return (
            <>
                {Style.it(
                    CSS,
                    <div>
                        <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                           onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri
                            Dön</a>

                        <EkoDataTable
                            tabMenu={this.items}
                            selectedTab={7}
                            loading={this.state.tableLoading}
                            name={"salesShipment"}
                            selectRow={true}
                            partialTransformInvoice={this.partialTransformInvoice.bind(this)}
                            transformInvoice={this.transformInvoice.bind(this)}
                            transformInvoiceAll={this.transformInvoiceAll.bind(this)}
                            salesShipmentSendButton={this.salesShipmentSendButton.bind(this)}
                            similarSalesShipment={this.similarSalesShipment.bind(this)}
                            insertMenu={insertMenu}
                            onSelectedRowChange={this.onSelectedRowChange}
                            openInsert={this.openInsert.bind(this)}
                            openUpdate={() => this.openUpdate()}
                            onDelete={() => this.onDelete()}
                            fetchData={() => this.fetchData()}
                            data={this.state.data}
                            addUpdateShow={false}
                            showDelete
                            title={"Sevk İrsaliyesi"}
                        /><SalesShipmentSendModal
                        activeTab={"address"}
                        showModal={this.state.showSendModal}
                        formData={this.state.selectedSendId}
                        receiver={this.state.receiver}
                        onCancel={() => {
                            this.setState({
                                showSendModal: false
                            });
                        }}
                        errors={this.state.errors}
                    />
                        <EkoModal
                            bsSize="xlg"
                            hideSelectButtons
                            showModal={this.state.transformInvoiceAllModal}
                            onHide={this.transformInvoiceAllcloseModal.bind(this)}
                        >
                            <div style={{padding: '20px'}}>
                                <h4 style={{paddingBottom: '10px'}}>Faturaya dönüştürmek istediğiniz irsaliyeleri
                                    seçiniz.</h4>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Arama..."
                                        value={this.state.searchQuery}
                                        onChange={(e) => this.setState({searchQuery: e.target.value})}
                                        style={{marginBottom: '10px', padding: '5px', width: '100%'}}
                                    />
                                    <table className="table table-striped not-table-layout">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th onClick={() => this.handleSort('date')}>Tarih</th>
                                            <th onClick={() => this.handleSort('document_no')}>Doküman Numarası</th>
                                            <th onClick={() => this.handleSort('customer_name')}>Müşteri Adı</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {filteredData.map((item, index) => (
                                            item.sales_invoice_id === 0 && (
                                                <tr key={index}>
                                                    <td style={{width: 15}}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkBox.some(element => element.id === item.id)}
                                                            onChange={() => this.checkboxChange(item.id)}
                                                        />
                                                    </td>
                                                    <td>{item.date}</td>
                                                    <td>{item.document_no}</td>
                                                    <td>{item.customer_name}</td>
                                                </tr>
                                            )
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-right" style={{marginTop: '10px'}}>
                                    <button
                                        disabled={false} // Replace with your condition
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.transformInvoiceAllSend();
                                        }}
                                    >
                                        Faturaya Dönüştür
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            this.transformInvoiceAllcloseModal();
                                        }}
                                    >
                                        İptal Et
                                    </button>
                                </div>
                            </div>
                        </EkoModal>
                        <EkoModal
                            bsSize="xlg"
                            hideSelectButtons
                            showModal={this.state.partialTransformInvoiceModal}
                            onHide={this.partialTransformInvoiceCloseModal.bind(this)}
                        >
                            <div style={{"padding": "20px"}}>
                                <h4 style={{"paddingBottom": "10px"}}>Faturaya dönüştürmek istediğiniz irsaliye
                                    satırlarını seçiniz.</h4>
                                <div>
                                    <table className="table table-striped not-table-layout">

                                        <thead>
                                        <th>#</th>
                                        <th>Ürün Adı</th>
                                        <th>Açıklama</th>
                                        <th>Miktar</th>
                                        <th>Birim Fiyat</th>
                                        <th>Birim</th>
                                        <th>Fiyat</th>
                                        <th>#</th>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.partialTransformInvoiceRow.map((item, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td style={{width: 15}}>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.checkBox.some(element => element.id === item.id)}
                                                                onChange={() => {
                                                                    this.checkboxChange(item.id);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{item.item_name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.unit_price_fc}</td>
                                                        <td>{item.unit_name}</td>
                                                        <td>{item.amount_fc}</td>
                                                        <td>{item.transform_invoice_line===0?"Faturaya Eklenmedi":"Faturaya Eklendi"}</td>
                                                    </tr>
                                                );
                                            })
                                        }

                                        </tbody>
                                    </table>

                                </div>
                                <div className="text-right" style={{"marginTop": "10px"}}>
                                    <button
                                        disabled={this.state.SendButtonDisableController}
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.partialTransformInvoiceAllSend();
                                        }}
                                    >
                                        Faturaya Dönüştür
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            this.partialTransformInvoiceCloseModal();
                                        }}
                                    >
                                        İptal Et
                                    </button>
                                </div>
                            </div>
                        </EkoModal>
                        <EkoModal
                            bsSize="xlg"
                            hideSelectButtons
                            showModal={this.state.showSimilarModal}
                            onHide={this.showSimilarModalcloseModal.bind(this)}
                        >
                            <div style={{"padding": "20px"}}>
                                <h4 style={{"paddingBottom": "10px"}}>İrsaliye oluşturmak istediğiniz müşterilerinizi
                                    seçiniz.</h4>
                                <p style={{"paddingBottom": "10px"}}>Tabloda seçtiğiniz irsaliye, aşağıda seçilen
                                    müşteriler için oluşturulacaktır.</p>
                                <div>
                                    <table className="table table-striped not-table-layout">

                                        <thead>
                                        <th>#</th>
                                        <th>Müşteri Adı Soyadı</th>
                                        </thead>
                                        <tbody>
                                        {this.state.customer_list && this.state.customer_list.map((customer, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td style={{width: 15}}>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.checkBox.some(element => element.id === customer.id)}
                                                            onChange={() => {
                                                                this.checkboxChange(customer.id);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{customer.fullname}</td>
                                                </tr>
                                            );
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-right" style={{"marginTop": "10px"}}>
                                    <button
                                        disabled={this.state.SendButtonDisableController}
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.sendSimilarSalesShipment();
                                        }}
                                    >
                                        Kaydet
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            this.showSimilarModalcloseModal();
                                        }}
                                    >
                                        İptal Et
                                    </button>
                                </div>
                            </div>
                        </EkoModal>
                    </div>
                )}

            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(SalesShipmentList);
