import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import AddSGKCompanyBranch from './AddSGKCompanyBranch'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";

import Api from '../../util/Api'
//import EkoToastrError from '../../components/elements/EkoToastrError'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";

class SGKCompanyBranches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            records: [],
            formData: {},
            page_loading: true,
            isyeriTurleri: [{"id": "merkez", "value": "MERKEZ"}, {"id": "mukellefiyyetsiz", "value": "MÜKELLEFİYYETSİZ ŞUBE"}],
            kayitDurumlari: [{"id": "1", "value": "AKTİF"}, {"id": "0", "value": "PASİF"}],
        }

    }
    componentWillMount() {
        this.fetchRecordedData();
    }
    componentDidMount() {
        
    }
    fetchRecordedData() {
        this.setState({page_loading: true, showInsertModal: false, formData: false, records: []});
        Api.get("/company/getCompanySgkBranches", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({records: getData, page_loading: false });
        });
    }

    onSave() {
        this.fetchRecordedData();
    }
    openPopup() {
        this.setState({showInsertModal: true});
    }
    deleteRecord(itemId) {
        var self = this;
        toastr.confirm('Kayıt silinecektir. Onaylıyor musunuz?', 
            {
                onOk: () => {
                    Api.put("/company/deleteCompanySgkBranch", {id: itemId}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == "1") {
                                toastr.success("Başarılı", "Kayıt silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                self.fetchRecordedData();
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    editRecord(itemId) {
        var self = this;
        Api.get("/company/getCompanySgkBrancDetails/"+itemId, (response, errors) => {
            if (errors) {
                return;
            }
            console.log(response.data);
            this.setState({formData: response.data, showInsertModal: true});
        });
    }

    findIsyeriTuru(findme) {
        if (findme != null) {
            let data = this.state.isyeriTurleri;
            let buldum = data.find((element) => {
                return element.id === findme;
            })
            if (buldum.value){
                return buldum.value;
            }
        }else{
            return "-";
        }
    }

    render() {
        return (<>
            <div>
                {this.state.page_loading == true ? <EkoSpinner /> : 
                    <>
                    
                    <AddSGKCompanyBranch
                    showModal={this.state.showInsertModal}
                    editData={this.state.formData}
                    onSave={() => {
                        this.setState({ showInsertModal: false })
                        this.fetchRecordedData()
                    } }
                    onCancel={() => {
                        this.setState({ showInsertModal: false })
                        this.fetchRecordedData()
                    } }
                    errors={this.state.errors} />

                    
                    <div style={{textAlign: "right"}} onClick={this.openPopup.bind(this)}>
                        <button type='button' className='btn btn-default blue btn-sm'><i className='fa fa-plus'></i>Yeni Ekle</button>
                    </div>
                    

                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>İşyeri/Şube Türü</th>
                                <th>İşyeri/Şube Adı</th>
                                <th>İşyeri Sicil Numarası</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.records.map((record) =>
                                    <tr>
                                        <td>{this.findIsyeriTuru(record.isyeri_turu)}</td>
                                        <td>{record.isyeri_adi}</td>
                                        <td>{record.sicil_sektor_kodu}-{record.sicil_isyeri_kodu}-{record.sicil_yeni_unite}-{record.sicil_eski_unite}-{record.sicil_isyeri}-{record.sicil_il_kodu}-{record.sicil_ilce_kodu}-{record.sicil_kontrol_no}-{record.sicil_araci_no}</td>
                                        <td>
                                            <button type='button' className='btn btn-sm btn-warning' onClick={this.editRecord.bind(this, record.id)}><i className='fa fa-edit'></i></button>
                                            <button type='button' className='btn btn-sm btn-danger' onClick={this.deleteRecord.bind(this, record.id)}><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                this.state.records.length == 0 ? <><tr><td colSpan={4} style={{textAlign: "center"}}>Kayıt bulunamadı</td></tr></> : ""
                            }
                        </tbody>
                    </table>
                    
                    </>
                }
            </div>
            
        </>)
    }
}

function mapStateToProps(state) {
    return {
        records: state.data.records

    };
}

export default connect(mapStateToProps, actions)(SGKCompanyBranches);
