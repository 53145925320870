import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { FETCH_PAYMENTS } from "../../actions/types";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import Style from "style-it";
import { CSS } from "../../css/tab_datatable";
import {Link} from "react-router";
import OutgoingStatementsModal from "./OutgoingStatementsModal";
import Util from "../../util/Util";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      payments: [],
      showStatementModal: false // cb 03/05/2023
    };
  }

  openReceipt(selectedRow) {
    this.printShow(selectedRow);
  }

  printShow = row => {
    window.open(
        process.env.REACT_APP_API_URL +
        "/payment/print/" +
        row.id +
        "?token=" +
        localStorage.getItem("token"),
        "_blank"
    );
  };

  fetchPayments() {
    this.props.fetchData("/payment/list", FETCH_PAYMENTS);
  }

  componentWillMount() {
    localStorage.removeItem("new_customer_id_take_collection");
    localStorage.removeItem("new_payment_amount_fc");
    localStorage.removeItem("new_collection_amount_fc");
    localStorage.removeItem("selected_list_sales_invoice_id");
    localStorage.removeItem("selected_list_purchases_invoice_id");
    localStorage.removeItem("selected_list_receivable_amount_fc");
    localStorage.removeItem("selected_list_payable_amount_fc");
    localStorage.removeItem("new_collection_from_who_type", 1);
    this.fetchPayments();
  }

  openUpdate(selectedRow) {
    this.props.router.push("/dashboard/payments/edit/" + selectedRow.id);
  }

  openInsert(menuItem) {
    localStorage.setItem("new_payment_from_who_type", menuItem);
    this.props.router.push("/dashboard/payments/add");
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/payment/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchPayments();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  get items() {
    return [
      {
        id: 1,
        link: "/dashboard/payments",
        title: "Ödediklerim"
      },

      {
        id: 2,
        link: "/dashboard/payments/outstanding",
        title: "Borçlarım"
      }
    ];
  }
  /* cb 03/05/2023 */
  sideLink() {
    return (
        <Link className="btn btn-default blue" onClick={this.openOutgoingStatements.bind(this)}><i className="fa fa-share"></i> Banka Hesap Hareketleri (Çıkan Para)</Link>
    );
  }
  openOutgoingStatements() {
    let self = this;
    self.setState({showStatementModal: true});
  }
  /**/
  render() {
    const insertMenu = this.props.viewOptions
        ? this.props.viewOptions.payments.type
        : false;
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          {Style.it(
              CSS,
              <div>
                <OutgoingStatementsModal
                    showModal={this.state.showStatementModal}
                    formData={{"token": localStorage.getItem("token")}}
                    onCancel={() => {
                      this.setState({showStatementModal: false});
                    }}
                    errors={this.state.errors}
                />
                <EkoDataTable
                    tabMenu={this.items}
                    selectedTab={1}
                    openInsert={this.openInsert.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    openReceipt={this.openReceipt.bind(this)}
                    data={this.props.payments}
                    insertMenu={insertMenu}
                    name={"payment"}
                    sideLink={this.sideLink()}
                ></EkoDataTable>
              </div>
          )}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payments: state.data.payments,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(Payments);
