import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class BulkBalanceUpdateCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            list: [],
            date: false,
            searchTerm: ''
        };
    }
    handleSearchTermChange = (e) => {
        this.setState({ searchTerm: e.target.value });
    };
    rowChangeUpdate = (val, obj) => {
        const index = this.state.list.findIndex(item => item.id === obj);
        if(val>=0){
            const updatedList = [...this.state.list];
            updatedList[index].balance_record = val;
            this.setState({ list: updatedList });
        }else{
            toast.error("Sadece + bakiye girebilirsiniz. Örn: 5000", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: true
            });
        }
    };
    dateUpdate = (date) => {
        this.setState({ date: date });
    };
    list() {
        Api.get("/customer/list", (data, errors) => {
            if (data) {
                const updatedList = data.customers.map(item => ({
                    ...item,
                    balance_record: ''
                }));
                this.setState({ list: updatedList }, () => {

                });
            }
        });
    }

    update(){
        if(this.state.date===false){
            toast.error("Lütfen Tarih Seçin", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: true
            });
        }else{
            Api.post("/customer/bulkBalanceUpdate", {list:this.state.list,date:this.state.date}, (data, errors) => {
                if (data) {
                    this.list();
                    toast.success("Başarıyla Güncellendi", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: true
                    });
                }
            });
        }

    }
    resetInput() {
        const updatedList = this.state.list.map(item => ({
            ...item,
            balance: "0.00"
        }));

        this.setState({
            list: updatedList
        });
    }


    componentWillMount() {
        this.list();
    }


    render() {
        const filteredList = this.state.list.filter(item =>
            item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
        );
        return (
            <div className="portlet light">
                <div>
                    <input
                        type="text"
                        placeholder="Ürün Adı Ara"
                        value={this.state.searchTerm}
                        onChange={this.handleSearchTermChange}
                        className="form-control"
                    />
                </div>
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            Toplu Bakiye Güncelleme (Müşteri)
              {/*<button style={{"marginLeft": "10px"}} className="btn blue btn-xs" onClick={this.resetInput.bind(this)}><i className="fa fa-plus"/>&nbsp; Bütün Bakiyeleri Sıfırla</button>*/}
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <div><ToastContainer limit={1}/>
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th style={{width: "20%"}} className="fcolumn">Müşteri Adı</th>
                                <th style={{width: "20%"}} className="fcolumn">Mevcut Bakiye</th>
                                <th style={{width: "20%"}} className="fcolumn">Yeni Bakiye Sadece + bakiye
                                    girebilirsiniz. Örn: 5000
                                </th>
                            </tr>
                            </thead>
                        </table>
                        {filteredList.map((item) => {
                            return (
                                <table key={item.id} className="table table-striped table-bordered table-hover">
                                    <tbody>
                                    <tr>
                                        <td style={{width: "20%"}}>
                                            {item.fullname}
                                        </td>
                                        <td style={{width: "20%"}}>{item.balance}</td>
                                        <td style={{width: "20%"}}>
                                            <input
                                                onWheel={() => document.activeElement.blur()}
                                                value={this.state.list.find(el => el.id === item.id).balance_record}
                                                className="form-control" type="number" min="1" max="100"
                                                onChange={(e) => this.rowChangeUpdate(e.target.value, item.id)}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )
                        })
                        }
                        <div style={{"marginTop": "20px", "marginBottom": "20px", "width": "400px", "float": "right"}}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    Tarih Seçin:
                                    <input
                                        type={"date"}
                                        value={this.state.date}
                                        className="form-control"
                                        onChange={(e) => this.dateUpdate(e.target.value)}
                                    />
                                    <small>
                                        Bakiye düzeltme kayıtları, yukarıdaki tarih ile müşteri hareketlerinize
                                        atılacaktır.
                                    </small>
                                </div>
                                <div className={"col-md-6"}>
                                    <br/>
                                    <button className="btn blue btn-sm" onClick={this.update.bind(this)}>
                                        <i className="fa fa-plus"/>&nbsp; Güncelle
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{"clear": "both"}}></div>
                    </div>
                </div>
                <br/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties,
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(BulkBalanceUpdateCustomer);