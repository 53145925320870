import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormCheckbox,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormSwitch,
  EkoFormDate
} from "../../components/elements/EkoForm";
import { Modal, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import ReactDOM from 'react-dom'

import "./declarations.css";

class DeclarationsIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData || this.defaultFormData(),
      showModal: false,
      errors: {},
      formFields: {},
      saving: false,
      iFrameHeight: '800',
      allParamsOk: false
    };
    //  this.onCancel = this.onCancel.bind(this);
  }

  defaultFormData() {
    return {
        declaration: {
          type_id: 0,
          vat_period: "",
          type_cycle: 0,
          token: ""
        }
      };
  }
  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    let errors = nextProps.errors || {};

    let type_id = this.state.formData.declaration.type_id;
    let vat_period = this.state.formData.declaration.vat_period;
    let type_cycle = this.state.formData.declaration.type_cycle;
    let checkAllData = false;
    if (type_id > 0 && vat_period != "" && type_cycle > 0) {
        checkAllData = true;
    }
    this.setState({
      formData: formData,
      errors: errors,
      showModal: nextProps.showModal,
      allParamsOk: checkAllData
    });
  }
  render() {
    let serviceType = process.env.SERVICE_TYPE;
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);

    var share_holder_extra_query = "";
    if (this.state.formData.declaration.shid){
      share_holder_extra_query = "&shid="+this.state.formData.declaration.shid;
    }
    return (
        <EkoModal
          showModal={this.state.showModal}
          title="Beyanname İşlemleri"
          bsSize="xlg" // xlg
          onHide={() => {
            this.setState({
              showModal: false,
              formData: this.defaultFormData(),
              errors: {}
            });
            //this.onCancel();
          }}
          spinner={this.state.saving}
        >
        
            <iframe 
                style={{overflow: 'visible'}}
                ref="iframe"
                src={apiUrl + "panel/declaration-new.php?tid="+this.state.formData.declaration.type_id+"&cycle="+this.state.formData.declaration.type_cycle+"&period="+this.state.formData.declaration.vat_period+"&t="+ this.state.formData.declaration.token+share_holder_extra_query}
                scrolling="auto" 
                frameBorder="0" 
                width="100%" 
                height={this.state.iFrameHeight} 
            />
           
        </EkoModal>
      );
  }
  
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(DeclarationsIframe);
