import React, {useEffect, useState} from "react";
import Api from "../../util/Api";
import {EkoFormButton} from "../../components/elements/EkoForm";
import {withRouter} from "react-router";
import {toastr} from "react-redux-toastr";
import Resizer from "react-image-file-resizer";

const OfferTemplate = (props) => {
    const [URI, setURI] = useState("");
    const [signatureURI, signatureSetURI] = useState("");
    const [loading, setLoading] = useState(false);

    //console.log(URI, "uri");
    useEffect(() => {
        Api.get("/company", (response, err) => {
            //console.log("offer", response);
            if (response) setURI(response.company.logo);
            if (response) signatureSetURI(response.company.proforma_signature);
            else console.log(err);
        });
    }, []);

    const fileUploadHandler = () => {
        setLoading(true);
        const fd = new FormData();

        fd.append("companyLogo", URI);

        Api.post("/company/upload-logo", fd, (response, error) => {
            if (response && response.status === "ok") {
                    setLoading(false);
                    toastr.success(response.message);
            } else {
                setLoading(false);
                if (error.status === "fail") {
                    if (error.companyLogo) {
                        toastr.error(error.companyLogo[0])
                    }

                    if (error.message) {
                        toastr.error(error.message);
                    }
                }
            }
        });
    };

    const fileUploadSignatureHandler = () => {
        setLoading(true);
        const fd = new FormData();

        fd.append("ProformaSignature", signatureURI);

        Api.post("/company/uploadProformaSignature", fd, (response, error) => {
            if (response && response.status === "ok") {
                setLoading(false);
                toastr.success(response.message);
            } else {
                setLoading(false);
                if (error.status === "fail") {
                    if (error.ProformaSignature) {
                        toastr.error(error.ProformaSignature[0])
                    }

                    if (error.message) {
                        toastr.error(error.message);
                    }
                }
            }
        });
    };

    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 50,
            }}
        >
            <div className="alert alert-info">
                <h3>Bilgilendirme </h3>
                <p>
                    Bu alanda yüklediğiniz logonuz <b>Satışlar</b> sayfasındaki teklifleri
                    kapsamaktadır.
                    <br/>
                    Teklifinizi oluşturduktan sonra ilgili teklifin üzerine tıklayıp{" "}
                    <b>
                        <u>tablo üzerindeki İşlemler açılır menüsünden </u>
                    </b>
                    göster işlevine tıkladığınızda, logonuzu teklifiniz üzerinde
                    görüntüleyebilirsiniz.
                </p>
            </div>
            {URI !== null && URI && URI.size > 256000 && (
                <div className="alert alert-danger" role="alert">
                    Seçili resimin boyutu fazla büyük
                </div>
            )}
            <div className="row">
                <div className="col-md-6" style={{"textAlign":"center"}}>
                    <p>Teklif Logo Görseli</p>
                    <div className="row">
                        <div className="col-md-12">
                            {URI ? (
                                <img
                                    alt="logo"
                                    style={{
                                        padding: 8,
                                        border: "3px solid #9c9a9a",
                                        borderRadius: 20,
                                    }}
                                    src={
                                        URI instanceof File || URI instanceof Blob
                                            ? URL.createObjectURL(URI)
                                            : URI
                                    }
                                    width="180"
                                    height="180"
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="180"
                                    height="180"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-5v-1h5v1zm-18 0l4-5.96 2.48 1.96 2.52-4 1.853 2.964c-1.271 1.303-1.977 3.089-1.827 5.036h-9.026zm10.82 4h-14.82v-18h22v7.501c-.623-.261-1.297-.422-2-.476v-5.025h-18v14h11.502c.312.749.765 1.424 1.318 2zm-9.32-11c-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className="clearfix"/>
                    <br/>
                    <div>
                        {!URI ? (
                            <>
                                <label className="btn btn-default btn-file" htmlFor="companyLogo">
                                    <i className="far fa-file-image"></i> Resim Seç
                                </label>
                                <input
                                    type="file"
                                    id="companyLogo"
                                    onChange={(e) => {
                                        Resizer.imageFileResizer(
                                            e.target.files[0],
                                            480,
                                            240,
                                            "PNG",
                                            80,
                                            0,
                                            (uri) => {
                                                setURI(uri);
                                            },
                                            "blob"
                                        );
                                    }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    style={{display: "none"}}
                                />
                            </>
                        ) : (
                            <EkoFormButton
                                className="btn btn-danger"
                                label={"Resmi Kaldır"}
                                faClass="fas fa-trash"
                                onClick={(e) => setURI(null)}
                                style={{marginLeft: 10}}
                            />
                        )}

                        {
                            <EkoFormButton
                                label={"Kaydet"}
                                faClass="fa fa-upload"
                                isLoading={loading}
                                onClick={fileUploadHandler}
                                disabled={URI !== null && URI.size > 256000}
                                style={{marginLeft: 10}}
                                loading={loading}
                            />
                        }
                    </div>
                </div>
                <div className="col-md-6" style={{"textAlign":"center"}}>
                    <p>Teklif İmza Görseli</p>
                    <div className="row">
                        <div className="col-md-12">
                            {signatureURI ? (
                                <img
                                    alt="logo"
                                    style={{
                                        padding: 8,
                                        border: "3px solid #9c9a9a",
                                        borderRadius: 20,
                                    }}
                                    src={
                                        signatureURI instanceof File || signatureURI instanceof Blob
                                            ? URL.createObjectURL(signatureURI)
                                            : signatureURI
                                    }
                                    width="180"
                                    height="180"
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="180"
                                    height="180"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-5v-1h5v1zm-18 0l4-5.96 2.48 1.96 2.52-4 1.853 2.964c-1.271 1.303-1.977 3.089-1.827 5.036h-9.026zm10.82 4h-14.82v-18h22v7.501c-.623-.261-1.297-.422-2-.476v-5.025h-18v14h11.502c.312.749.765 1.424 1.318 2zm-9.32-11c-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5z"/>
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className="clearfix"/>
                    <br/>
                    <div>
                        {!signatureURI ? (
                            <>
                                <label className="btn btn-default btn-file" htmlFor="ProformaSignature">
                                    <i className="far fa-file-image"></i> Resim Seç
                                </label>
                                <input
                                    type="file"
                                    id="ProformaSignature"
                                    onChange={(e) => {
                                        Resizer.imageFileResizer(
                                            e.target.files[0],
                                            480,
                                            240,
                                            "PNG",
                                            80,
                                            0,
                                            (uri) => {
                                                signatureSetURI(uri);
                                            },
                                            "blob"
                                        );
                                    }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    style={{display: "none"}}
                                />
                            </>
                        ) : (
                            <EkoFormButton
                                className="btn btn-danger"
                                label={"Resmi Kaldır"}
                                faClass="fas fa-trash"
                                onClick={(e) => signatureSetURI(null)}
                                style={{marginLeft: 10}}
                            />
                        )}

                        {
                            <EkoFormButton
                                label={"Kaydet"}
                                faClass="fa fa-upload"
                                isLoading={loading}
                                onClick={fileUploadSignatureHandler}
                                disabled={signatureURI !== null && signatureURI.size > 256000}
                                style={{marginLeft: 10}}
                                loading={loading}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(OfferTemplate);
