import React, { PureComponent } from "react";
import EkoDataTable from "./EkoDataTable";

type Props = {
  fetchData: Function,
  pageCount: number,
  currentPage: number,
  totalCount: number
};

class PaginatedDataTable extends PureComponent<Props> {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sizePerPage: 10,
      loading: false,
      sortName: undefined,
      sortOrder: undefined,
      minDate: null,
      maxDate: null,
      dateColumn: null,
      data: null,
      search: null,
      invoiceStatus: null,
    };
  }

  fetchData = () => {
    this.props.fetchData(
      this.state.sizePerPage,
      this.state.page,
      [this.state.sortName, this.state.sortOrder],
      [this.state.minDate, this.state.maxDate, this.state.dateColumn],
      this.state.search,
      this.state.invoiceStatus,
    );
  };

  sizePerPageListChange = sizePerPage => {
    this.setState(
      {
        sizePerPage: sizePerPage
      },
      () => this.fetchData()
    );
  };

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        page,
        sizePerPage
      },
      () => this.fetchData()
    );
  };

  onSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        sortName,
        sortOrder,
        page: 0
      },
      () => this.fetchData()
    );
  };

  onDateChange = (minDate, maxDate, dateColumn) => {
    this.setState(
      {
        minDate: minDate,
        maxDate: maxDate,
        dateColumn: dateColumn,
        page: 0
      },
      () => this.fetchData()
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.state.page) {
      this.setState({
        page: nextProps.currentPage
      });
    }
  }
  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  }
  delayedFunction = this.debounce((searchText) => {
    this.setState(
        {
          search: searchText || ""
        },
        () => this.fetchData()
    );
  }, 300);
  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    this.delayedFunction(searchText)
    const text = searchText.trim();
      return;

    let searchTextArray = [];
    if (multiColumnSearch) {
      searchTextArray = text.split(" ");
    } else {
      searchTextArray.push(text);
    }

    const data = this.props.data.filter(product => {
      const keys = Object.keys(product);
      let valid = false;
      for (let i = 0, keysLength = keys.length; i < keysLength; i++) {
        const key = keys[i];
        if (colInfos[key] && product[key]) {
          const {
            format,
            filterFormatted,
            formatExtraData,
            searchable,
            hidden
          } = colInfos[key];
          let targetVal = product[key];
          if (!hidden && searchable) {
            if (filterFormatted && format) {
              targetVal = format(targetVal, product, formatExtraData);
            }
            for (
              let j = 0, textLength = searchTextArray.length;
              j < textLength;
              j++
            ) {
              const filterVal = searchTextArray[j].toLowerCase();
              if (
                targetVal
                  .toString()
                  .toLowerCase()
                  .indexOf(filterVal) !== -1
              ) {
                valid = true;
                break;
              }
            }
          }
        }
      }
      return valid;
    });
    this.setState({
      data: data
    });
  };
  onInvoiceStatusChange = (val) => {
    this.setState(
        {
          invoiceStatus: val,
          page: 0
        },
        () => this.fetchData()
    );
  }
  render() {
    let options = {
      ...(this.props.options || {}),
      onPageChange: this.onPageChange,
      onSizePerPageList: this.sizePerPageListChange,
      page: this.state.page,
      onSortChange: this.onSortChange,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      sizePerPage: this.state.sizePerPage,
      pageStartIndex: 1,
      paginationSize: 5,
      onSearchChange: this.onSearchChange
    };

    let props = {
      ...this.props,
      options: options,
      data:
        this.state.page === this.props.currentPage
          ? this.state.data || this.props.data
          : null,
      customPagination: true,
      onDateDataChange: this.onDateChange,
      onInvoiceStatusChange: this.onInvoiceStatusChange
    };

    const totalSize = this.state.data
      ? this.state.data.length
      : this.props.totalCount
      ? this.props.totalCount
      : this.props.pageCount * this.state.sizePerPage;
    let tableProps = {
      remote: true,
      fetchInfo: { dataTotalSize: totalSize }
    };


    return this.props && this.props.totalCount > 1000 ? (
      <EkoDataTable {...props} tableProps={tableProps} />
    ) : (
        <EkoDataTable {...props} tableProps={tableProps} />
    );
  }
}

export default PaginatedDataTable;
