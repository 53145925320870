import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import DBSPurchasesPInvoice from "./pInvoice";
import DBSPurchasesRInvoice from "./pReceipt";
import DBSPurchasesORInvoice from "./pOtherReceipt";
import DBSPurchasesPExpenseInvoice from "./pExpenseInvoice";
import Util from "../../util/Util";
const DBSPurchases = () => {
    return (
        <div>
            <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
               onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject uppercase">
            DBS Giderler Listesi
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <Tabs defaultActiveKey="faturalar" id="uncontrolled-tab-example">
                        <Tab eventKey="faturalar" title="Mal / Hizmet">
                            <DBSPurchasesPInvoice/>
                        </Tab>
                        <Tab eventKey="gider_faturalari" title="Gider Faturaları">
                            <DBSPurchasesPExpenseInvoice/>
                        </Tab>
                        <Tab eventKey="fisler" title="Fişler">
                            <DBSPurchasesRInvoice/>
                        </Tab>
                        <Tab eventKey="diger_alislar" title="Diğer Giderler">
                            <DBSPurchasesORInvoice/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
export default DBSPurchases;