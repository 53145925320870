import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import Api from '../../util/Api'
import EkoModal from "../../components/elements/EkoModal";

class IVDTaxPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            errors: {},
            user: {},
            data: [], //table Item
            openModal: false,
            iframeUrl: "",
        }
    }
    fetch() {
        Api.get("/EArchiveInteraktifVD/IVDTaxPayment", (response, err) => {
            this.setState({data:response.list});
        });
    }
    componentWillMount() {
        this.fetch();
    }
    createCustomRowButtons(state) {
        return state.isSelected && state.selectedRow.type=='spos' ? (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={this.view.bind(this, state.selectedRow)}
            >
                <i className="fa fa-arrow-circle-o-right" /> PDF İndir
            </button>
        ) : (
            ""
        );
    }
    view = (row) => {
        Api.get("/EArchiveInteraktifVD/IVDTaxPaymentDocument/"+row.id, (response, err) => {
            window.open(response);
        });
    };
    closeModal() {
        this.setState({openModal: false});
    }
    render() {
        return (
            <div>
                <EkoDataTable
                    hideSelectButtons
                    createCustomRowButtons={this.createCustomRowButtons.bind(this)}
                    addButtonShow={false}
                    data={this.state.data}
                    name={"ivd_tax_payment"}>
                </EkoDataTable>
                <EkoModal
                    showModal={this.state.openModal}
                    onHide={this.closeModal.bind(this)}
                >
                    <div>
                        <iframe style={{"width":"100%","height":"500px","border":"0px"}} src={this.state.iframeUrl}></iframe>
                    </div>
                </EkoModal>
            </div>
        )
    }
}
function mapStateToProps(state) {

}
export default connect(mapStateToProps, actions)(IVDTaxPayment);