import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import DBSSalesSInvoice from "./sSales";
import DBSSalesSoleprofInvoice from "./sSoleprof";
import DBSSalesZInvoice from "./zSales";
import DBSSalesORInvoice from "./sOtherReceipt";
import Util from "../../util/Util";
const DBSSales = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <div>
            <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
               onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject uppercase">
            DBS Gelirler Listesi
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <Tabs defaultActiveKey={user.company_type == 9 || user.company_type == 6 ? "soleprof" : "satislar"}
                          id="uncontrolled-tab-example">
                        {
                            user.company_type == 9 || user.company_type == 6 ?
                                <Tab eventKey="soleprof" title="e-SMM Makbuzları">
                                    <DBSSalesSoleprofInvoice/>
                                </Tab>
                                :
                                <Tab eventKey="satislar" title="Satışlar">
                                    <DBSSalesSInvoice/>
                                </Tab>
                        }
                        <Tab eventKey="zrapor" title="Z Raporları">
                            <DBSSalesZInvoice/>
                        </Tab>
                        <Tab eventKey="diger_satislar" title="Diğer Satışlar">
                            <DBSSalesORInvoice/>
                        </Tab>
                    </Tabs>
                </div>
                <br/>
            </div>
        </div>
    );
};

export default DBSSales;
