import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import moment from "moment";
import EkoFormDate from "../../components/elements/form/EkoFormDate";

class UpdateCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {}
        }
        this.setState({expiry_date:null,companyId:null,plan_id:1})
    }
    defaultFormData() {
        return {
            supplier: {
                currency: "TRY",
                opening_date: moment().format("YYYY-MM-DD")
            },
            contact: {},
            address: {}
        };
    }
    static getDefaultForm() {
        return {
            supplier: {
                currency: "TRY"
            },
            contact: {},
            address: {}
        };
    }
    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {};

        this.setState({ formData: formData, errors: errors });
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        let errors = nextProps.errors || {};
        this.setState({
            formData: formData,
            errors: errors,
            showModal: nextProps.showModal,

        });

    }



    onDateChange(dateField, value, formattedValue) {
        this.setState({
            expiry_date:value
        })
        if (this.props.addTime){
            let date = formattedValue
                ? moment(formattedValue, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm")
                : "";
            this.props.onChange(dateField, date);
        }else{
            let date = formattedValue
                ? moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD")
                : "";
            this.props.onChange(dateField, date);
        }

    }
    updateModal(){
        let val = Object.assign({},  {
            expiry_date: this.state.expiry_date,
            plan_id: this.state.plan_id,
            id: this.state.formData.companies.id,
        });
        let url = "/company/expiryDateUpdate";
        let func = "post";
        Api[func](url, val, function (response, postErrors) {
            toastr.success('Başarılı', 'Başarıyla Güncellendi');
        });
    }
    loginAttempts(){
        let val = Object.assign({},  {
            companyId: this.state.formData.companies.id,
        });
        let url = "/company/loginAttemptsReset";
        let func = "post";
        Api[func](url, val, function (response, postErrors) {
            toastr.success('Başarılı', 'Başarıyla Güncellendi');
        });
    }

    addSeriesCompany(){
        let val = Object.assign({},  {
            companyId: this.state.formData.companies.id,
        });
        let url = "/company/addSeriesCompany";
        let func = "post";
        Api[func](url, val, function (response, postErrors) {
            if(response.status === 'ok'){
                toastr.success('Başarılı', response.message);
            }else{
                toastr.error('Hata', response.message);
            }
        });
    }

    selectBoxChange(e){
        this.setState({plan_id:e.target.value});
        console.log(this.state.plan_id);
    }
    closeModal() {
        window.location.reload(false);
        this.setState({showModal: false,saving: false,formData:{},expiry_date:null,plan_id:1});
        this.props.closeModal(false);
    }
    render() {
        var data = this.state.formData.companies;
        if(!data){
            data={
                firstname:""
            }
        }
        return (
            <EkoModal
                showModal={this.props.showModal}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.savingModal}
                dialogClassName="transaction-modal"
                title={"Şirket Detayı"}
            >
                <table className='table'>
                    <tr>
                        <td>Adı</td>
                        <td><b>{data.firstname}</b></td>
                        <td>Soyadı</td>
                        <td><b>{data.lastname}</b></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><b>{data.email}</b></td>
                        <td>Bitiş Tarihi</td>
                        <td><b>{data.expiry_date}</b></td>
                    </tr>
                </table>
                <hr />
                <EkoForm>
                    <div className='row'>
                        <div className='col-md-12'>
                            <button onClick={this.loginAttempts.bind(this)} className="btn btn-success">GİRİŞ ENGELİNİ KALDIR</button>
                            <button onClick={this.addSeriesCompany.bind(this)} className="btn btn-warning">SERİ TANIMLA</button>
                        </div>
                        <div className='col-md-6'>

                            <EkoFormDate
                                value={this.state.expiry_date}
                                onChange={this.onDateChange.bind(this)}
                                id="expiry_date"
                                dateFormat="DD-MM-YYYY"
                                colMd={12}
                                clearable={true}
                                label="Bitiş Tarihi"/>
                        </div>
                        <div className="col-md-6">
                            <label className="col-md-5 control-label">Plan Seçim:</label>
                            <select onChange={this.selectBoxChange.bind(this)} defaultValue={data.plan_id} className="form-control">
                                <option value="1">SM Fremium | Paket ID: 1</option>
                                <option value="2">SM Premium | Paket ID: 2</option>
                                <option value="3">Başlangıç | Paket ID: 3</option>
                                <option value="4">Profesyonel | Paket ID: 4</option>
                                <option value="5">Fremium | Paket ID: 5</option>
                                <option value="6">Mobil Premium | Paket ID: 6</option>
                            </select>
                        </div>
                        <div className="col-md-12">
                            <button onClick={this.updateModal.bind(this)} className="btn btn-danger">GÜNCELLE</button>
                        </div>



                    </div>
                </EkoForm>
            </EkoModal>
        )
    }

}

function mapStateToProps(state) {
    return {
        companies: state.data.companies
    };
}

export default connect(mapStateToProps, actions)(UpdateCompany);
