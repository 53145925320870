import React, { Component } from "react";
import EkoModal from "../elements/EkoModal";

class ForibaModal extends Component {
  constructor(props) {
    super(props);
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    return (
      <EkoModal
        showModal={this.props.showModal}
        onHide={this.closeModal.bind(this)}
        title={`${this.props.integrator} Bilgilendirme`}
      >
        <div className="row">
          {this.props.integrator} ile entegrasyon hakkında detaylı bilgi almak
          ve başvurucu
          <br />
          sürecini başlatmak için destek@ekohesap.com adresine email atınız.
        </div>
      </EkoModal>
    );
  }
}

export default ForibaModal;
