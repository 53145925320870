import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import { FETCH_EMPLOYEE_SALARIES } from "../../actions/types";

class EmployeeSalarys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showUpdateModal: false,
      showInsertModal: false,
      errors: {},
      validationFields: [],
      employeeSalaries: [] //table Item
    };
  }

  fetchEmployeeSalarys() {
    this.props.fetchData("/employeeSalary/list", FETCH_EMPLOYEE_SALARIES);
  }

  componentWillMount() {
    this.fetchEmployeeSalarys();
  }

  mapSalaries(salaries) {
    return salaries;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeSalaries) {
      this.setState({
        employeeSalaries: this.mapSalaries(nextProps.employeeSalaries)
      });
    }
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/employeeSalary/" + selectedRow.id, function(response, err) {
      if (response && response.status) {
        if (response.status === "ok") {
          self.fetchEmployeeSalarys();
          toastr.success("Başarılı", "Başarıyla Silindi");
        } else {
          toastr.error("Hata", response.message, {
            timeOut: 0
          });
        }
      } else
        toastr.error("Hata", response.message, {
          timeOut: 0
        });
    });
    /*  let newData = Sugar.Array.filter(this.props.employeeSalaries,function(object){
              return (object.id!==selectedRow.id);
          });
          this.props.updateFetchedData(FETCH_SUPPLIERS,{employeeSalaries:newData});*/
  }

  createCustomButtons = state => {
    return state.isSelected ? (
      <button
        className="btn blue tablebuttons btn-sm"
        onClick={() => {
          this.props.router.push(
            "/dashboard/employee/employeeSalary/preview/" +
              state.selectedRow.id +
              "/" +
              state.selectedRow.employee_id
          );
        }}
      >
        <span>
          <i className="fa fa-eye" /> Önizle
        </span>
      </button>
    ) : null;
    //
  };

  render() {
    return (
      <div>
        <EkoDataTable
          createCustomRowButtons={this.createCustomButtons}
          addButtonShow={false}
          addUpdateShow={false}
          onDelete={this.onDelete.bind(this)}
          data={this.state.employeeSalaries}
          name={"employeeSalary"}
        ></EkoDataTable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeSalaries: state.data.employeeSalaries
  };
}

export default connect(mapStateToProps, actions)(EmployeeSalarys);
