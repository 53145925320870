import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'

class AddShareHolder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.editData || this.defaultFormData(),
            showModal: false,
            saving: false,
            errors: {},
            formFields: {}
        }
    }

    defaultFormData() {
        return {
            
        }
    }
    componentWillMount() {
        let formData = this.props.editData || this.defaultFormData();
        let errors = {}
        this.setState({formData: formData, errors: errors});
    }
    componentWillReceiveProps(nextProps) {
        let formData = nextProps.editData || this.defaultFormData();
        this.setState({formData: formData, showModal: nextProps.showModal});
    }
    onSave() {
        var self = this;
        let formData = this.state.formData;
        self.setState({saving: true});

        
        if (formData.share != "" && formData.tax_office_code != "" && formData.name != "" && formData.surname != "" && formData.address != "" && formData.email != "" && formData.phone != "" && (formData.tc_number != "" || formData.tax_number != "")){
            Api.put("/company/insertCompanyShareHolder", {formData}, function (responses, postErrors) {
                if (postErrors) {
                    if (postErrors.message) {
                        self.setState({saving: false});
                        toastr.error("Başarısız", "Kayıt girişi işlemi sırasında hata oluştu.");
                    }
                }else{
                    if (responses.actionResult == "1") {
                        toastr.success("Başarılı", "Kayıt eklendi. Lütfen bekleyiniz, liste yenileniyor.");
                        self.setState({saving: false, showModal: false});
                        if (self.props.onSave) {
                            self.props.onSave();
                        }
                    }
                }
            })
        }else{
            toastr.error("Başarısız", "Lütfen tüm alanları doldurunuz.");
            self.setState({saving: false});
        }

    }
    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    setFieldChange(type, field, value) {
        let formData = this.state.formData;
        let errors = this.state.errors || {};
        formData[field] = value;
        let bigForm = this.state.formData;
        this.setState({ formData: bigForm, errors: errors });
    }
    
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
          formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }

    taxOfficeAddNewColumn() {
        let taxOffices = this.props.taxOffices || [];
        taxOffices.map(function(item) {
          if (item && item.city) {
            item.value = item.name + " - " + item.city.name;
          }
    
          return item;
        });
        return taxOffices;
    }
    

    render() {

        const shareHolderProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }

        let taxOffices = this.taxOfficeAddNewColumn();

        return <EkoModal
            showModal={this.state.showModal}
            title={this.state.formData.id ? "Güncelle" : "Yeni Ekle"}
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            onSave={() => {
                this.props.onSave();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_company_share_holder"}>

                <div className="row">
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Adı"
                                              id="name"
                                              type="text"
                                              isVertical={true}
                            />
                    </div>
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Soyadı"
                                              id="surname"
                                              isVertical={true}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="TC Kimlik"
                                              id="tc_number"
                                              mask="99999999999"
                                            maskChar=""
                                            type="mask"
                                              isVertical={true}
                            />
                    </div>
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Vergi Kimlik Numarası"
                                              id="tax_number"
                                              mask="9999999999"
                                            maskChar=""
                                            type="mask"
                                              isVertical={true}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <EkoFormSelect
                            {...shareHolderProps}
                            id="tax_office_code"
                            searchable={true}
                            optionId={"code"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={taxOffices}
                            label="Vergi Dairesi Adı"
                        />
                            
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Adres"
                                              id="address"
                                              isVertical={true}
                            />
                    </div>
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="E-mail"
                                              id="email"
                                              isVertical={true}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Telefon"
                                              id="phone"
                                              type="mask"
                                              mask="(999) 999-99-99"
                                              isVertical={true}
                            />
                    </div>
                    <div className="col-md-6">
                            <EkoFormInputText {...shareHolderProps}
                                              label="Pay Oranı (%)"
                                              id="share"
                                              type="percent"
                                              isVertical={true}
                            />
                    </div>
                </div>


                <Modal.Footer>
                    <div className="row buttonProccess2">
                        <EkoFormButton label={this.state.formData.id ? "Güncelle" : "Yeni Ekle"}
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>

            </EkoForm></EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
        taxOffices: state.data.taxOffices,
    };
}

export default connect(mapStateToProps, actions)(AddShareHolder);
