import React, { PureComponent } from "react";
import { Link } from "react-router";
import enhanceWithClickOutside from "react-click-outside";
import PermittedHeader from "../../services/PermittedHeader";
import CompanyMenu from "./CompanyMenu";
import PropTypes from "prop-types";
import HocPermission from "./HocPermission";
import EkoModal from "../elements/EkoModal";

class TopNavigationMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      openMyCompaniesModal: false,
      iFrameHeight: '800',
      reloadMainWindow: false
    };
  }

  isPermitted(pageId, html) {
    return PermittedHeader.isPermitted(pageId, html);
  }

  handleClickOutside() {
    this.setState({ openMenu: false });
  }

  openClose() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  close() {
    this.setState({ openMenu: false });
  }

  render() {
    let menuClassName = "dropdown dropdown-user dropdown-dark ";
    const user = JSON.parse(localStorage.getItem("user"));
    var is_admin_user=0;
    if(user.is_admin_user !==undefined){
      is_admin_user=user.is_admin_user;
    }

    let email = "";
    if (user) {
      if (user.firstname === null && user.lastname === null) {
        email = user.email;
      } else {
        email = `${user.firstname} ${user.lastname}`;
      }
    }

    if (this.state.openMenu) {
      menuClassName += "open";
    }
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    var time = new Date();

    if (this.state.reloadMainWindow === true) {
      window.location.reload();
    }
    return (
        <>
          <EkoModal
              showModal={this.state.openMyCompaniesModal}
              title="Şirketlerim"
              bsSize="xlg"
              onCancel={() => {
                this.setState({
                  openMyCompaniesModal: false,
                  reloadMainWindow: !this.state.reloadMainWindow
                });
              }}
              onHide={() => {
                this.setState({
                  openMyCompaniesModal: false,
                  reloadMainWindow: !this.state.reloadMainWindow
                });
              }}
              spinner={true}
          >
            <iframe
                style={{overflow: 'visible'}}
                ref="iframe"
                src={apiUrl + "panel/my-companies.php?&t=" + localStorage.getItem('token')}
                scrolling="auto"
                frameBorder="0"
                width="100%"
                height={this.state.iFrameHeight}
            />
          </EkoModal>

          <div className="top-menu">
            <CompanyMenu path={this.props.path} />

            {email ? (
                this.props.subscriberExpiredMenu ? (
                    <ul className="nav navbar-nav pull-right">
                      <li className={menuClassName}>
                        <a
                            onClick={this.openClose.bind(this)}
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                            data-close-others="true"
                            aria-expanded="true"
                        >
                  <span className="username username-hide-mobile">
                    {email}
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  </span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-default">
                          {parseInt(user.plan_id, 10) !== 0 ? (
                              <li>
                                <Link to="/dashboard">
                                  <i className="fa fa-step-backward" />
                                  Uygulamaya Dön
                                </Link>
                              </li>
                          ) : (
                              ""
                          )}
                          {user.is_owner ? (
                              <li>
                                <Link to="/subscriber">
                                  <i className="icon-envelope" />
                                  Abone Ol
                                </Link>
                              </li>
                          ) : (
                              ""
                          )}
                          <li>
                            <a href="#" onClick={this.props.logout}>
                              <i className="icon-logout" />
                              Çıkış Yap
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                ) : (
                    <ul className="nav navbar-nav pull-right">
                      <li className={menuClassName}>
                        <a
                            onClick={this.openClose.bind(this)}
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                            data-close-others="true"
                            aria-expanded="true"
                        >
                  <span className="username username-hide-mobile">
                    {email}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-default">
                          {is_admin_user==1 ? (
                              <HocPermission pkey="COMPANY_INFO">
                                <li>
                                  <Link
                                      activeClassName={"menu-active"}
                                      to="/dashboard/user-list"
                                      onClick={this.close.bind(this)}
                                  >
                                    <i className="icon-list" />
                                    Kullanıcı Yönetimi
                                  </Link>
                                </li>
                              </HocPermission>
                          ) : (
                              ""
                          )}
                          <HocPermission pkey="COMPANY_INFO">
                            <li>
                              <Link
                                  activeClassName={"menu-active"}
                                  to="/dashboard/companySettings"
                                  onClick={this.close.bind(this)}
                              >
                                <i className="icon-briefcase" />
                                Şirket Bilgileri
                              </Link>
                            </li>
                          </HocPermission>

                          {/* {this.isPermitted("4", <li>
                                            <Link activeClassName={'menu-active'} to="/dashboard/companyOtherInfo" onClick={this.close.bind(this)}><i
                                                className="icon-user"/> Şirket Bilgileri</Link>
                                        </li>)} */}

                          <HocPermission pkey="USER_LIST">
                            <li>
                              <Link
                                  activeClassName={"menu-active"}
                                  to="/dashboard/companyUserRole"
                                  onClick={this.close.bind(this)}
                              >
                                <i className="icon-calendar" />
                                Kullanıcı Ekle
                              </Link>
                            </li>
                          </HocPermission>

                          <li>
                            <Link
                                activeClassName={"menu-active"}
                                to="/dashboard/integrations"
                                onClick={this.close.bind(this)}
                            >
                              <i className="far fa-file-alt" />
                              Entegrasyonlar
                            </Link>
                          </li>

                          {user.is_owner ? (
                              <li>
                                <Link activeClassName={"menu-active"} to="/subscriber">
                                  <i className="icon-envelope" />
                                  Abone Ol
                                </Link>
                              </li>
                          ) : (
                              ""
                          )}
                          <li className="divider" />
                          <li>
                            <Link
                                activeClassName={"menu-active"}
                                to="/dashboard/templates"
                                onClick={this.close.bind(this)}
                            >
                              <i className="far fa-file-alt" />
                              Yazdırma Şablonları
                            </Link>
                          </li>
                          {user.company_type == "9" ? (
                              <li>
                                <Link onClick={() => {
                                  this.setState({
                                    openMyCompaniesModal: true
                                  });
                                }}
                                >
                                  <i className="fa fa-building" />
                                  Şirketlerim
                                </Link>
                              </li>
                          ) : (
                              ""
                          )}
                          <li>
                            <Link
                                activeClassName={"menu-active"}
                                to="/dashboard/accountingSettings"
                                onClick={this.close.bind(this)}
                            >
                              <i className="icon-settings" />
                              Muhasebe Ayarları
                            </Link>
                          </li>
                          <li>
                            <Link
                                activeClassName={"menu-active"}
                                to="/dashboard/userInfo"
                                onClick={this.close.bind(this)}
                            >
                              <i className="icon-key" />
                              Profilim
                            </Link>
                          </li>
                          <li>
                            <a href="#" onClick={this.props.logout}>
                              <i className="icon-logout" />
                              Çıkış Yap
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                )
            ) : (
                ""
            )}
          </div>
        </>

    );
  }
}

TopNavigationMenu.propTypes = {
  path: PropTypes.string.isRequired
};

export default enhanceWithClickOutside(TopNavigationMenu);
