import React, {Component} from "react";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import {toastr} from "react-redux-toastr";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import moment from "moment";
import styled from "styled-components";

class EArchiveSendModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData || this.defaultFormData(),
            showModal: false,
            detailIsOpened: false,
            counties: [],
            saving: false,
            savingVkn: false,
            sendBtnDisabledStatus: true,
            sendBtnDisabledStatus1: false,
            smsErrorDiv: 0,
            screenPosition: 0,
            smsPasswordTxt: "",
            smsOperationId: 0,
            invoiceId: 0,
            customerId: 0,
            faturaTarihi:"",
            ettn:0,
            finalInvoiceLink:"",
            finalPhoneNumber:"",
            invoiceNote:"",
            errors: {},
            formFields: {},
            time: {},
            seconds: 120
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
    }


    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        if (seconds == 0) {
            this.setState({sendBtnDisabledStatus: true});
            clearInterval(this.timer);
        }
    }

    defaultFormData() {
        return {
            customer: {
                delivery_same_as_invoice_address: true,
                currency: "TRY",
                opening_date: moment().format("YYYY-MM-DD")
            },
            contact: {},
            address: {},
            deliveryAddress: {}
        };
    }

    static getDefaultForm() {
        return {
            customer: {delivery_same_as_invoice_address: true},
            contact: {},
            address: {},
            deliveryAddress: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.getCompanyGovermentInformation();
        let formData = nextProps.formData || this.defaultFormData();
        let errors = nextProps.errors || {};
        this.setState({
            formData: formData,
            errors: errors,
            showModal: nextProps.showModal
        });
        this.decideTab();
    }

    decideTab() {
        let activeTab = "address";
        let detailIsOpened = this.state.detailIsOpened;

        if (this.props.activeTab && Sugar.Object.isEmpty(this.state.errors)) {
            activeTab = this.props.activeTab;
            detailIsOpened = true;
        } else {
            if (!Sugar.Object.isEmpty(this.state.errors.address)) {
                activeTab = "address";
                detailIsOpened = true;
            } else if (!Sugar.Object.isEmpty(this.state.errors.deliveryAddress)) {
                activeTab = "deliveryAddress";
                detailIsOpened = true;
            } else if (!Sugar.Object.isEmpty(this.state.errors.contact)) {
                activeTab = "contact";
                detailIsOpened = true;
            }
        }
        this.setState({activeTab: activeTab, detailIsOpened: detailIsOpened});
    }

    toggleDetail(state) {
        this.setState({detailIsOpened: state});
    }

    setFieldChange(type, field, value) {
        console.log(type, field, value, "TFV");
        let formData = this.state.formData[type];
        if (field === "city_id") {
            if (formData[field] !== value) {
                formData["county_id"] = null;
            }
        }
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = {
            ...this.state.formData,
            [type]: {
                ...formData
            }
        };

        if (field === "tax_identity_number") {
            let customer_tax_identity_number = this.state.formData.customer
                .tax_identity_number;

            let tax_identity_number_length = customer_tax_identity_number.length;

            if (tax_identity_number_length >= 10) {
                Api.get("/check-customer/" + customer_tax_identity_number, function (
                    response
                ) {
                    if (response.status === "fail") {
                        toastr.warning(response.message);
                    }
                });
            }
        }

        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    getCompanyGovermentInformation() {
        Api.get("/company/goverment-information", (response, err) => {
            if (response.data.EArsivUsername && response.data.EArsivPassword) {
                console.log("bilgiler geliyor");
            } else {
                this.setState({screenPosition: 2});
            }
        });
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onSend() {
        this.setState({sendBtnDisabledStatus1: true});
        Api.get("/EArchive/send/" + this.state.formData+"?invoiceNote="+this.state.invoiceNote, (response, err) => {
            if (response) {
                if (response.status == 'ok') {
                    this.startTimer();
                    this.setState({
                        sendBtnDisabledStatus: false,
                        time: {}, screenPosition: 1,
                        seconds: 120,
                        smsOperationId:response.smsOperationId,
                        invoiceId:response.invoiceId,
                        customerId:response.customerId,
                        ettn:response.ettn,
                        faturaTarihi:response.faturaTarihi,
                    });
                } else {
                    if (response.errorMessage) {
                        var errormessage = response.errorMessage;
                    } else {
                        var errormessage = response;
                    }
                    toastr.error("Hata", errormessage);
                }
            } else {
                toastr.error("Hata", "Sistemsel Bir Hata Oluştu. GIB'e Ulaşılamadı. Kod:EAR1");
            }
        });

    }

    onSendSMS() {
        if (this.state.smsPasswordTxt != '') {
            var params = {
                invoice_id: this.state.formData,
                smsPasswordTxt: this.state.smsPasswordTxt,
                smsOperationId: this.state.smsOperationId,
                invoiceId: this.state.invoiceId,
                customerId: this.state.customerId,
                ettn:this.state.ettn,
                faturaTarihi:this.state.faturaTarihi
            };
            Api.post("/EArchive/sendSMSPassword", params, (response, err) => {
                if (response) {
                    if (response.status == 'ok') {
                        this.setState({
                            screenPosition: 3,
                            finalInvoiceLink:response.finalInvoiceLink,
                            finalPhoneNumber:response.phoneNumber
                        });
                    } else if (response.status == 'smsError') {
                        this.setState({smsErrorDiv: 1});
                    } else {
                        toastr.error("Hata", "Sistemsel Bir Hata Oluştu. GIB'e Ulaşılamadı. Kod:EAR2");
                    }
                } else {
                    toastr.error("Hata", "Sistemsel Bir Hata Oluştu. GIB'e Ulaşılamadı. Kod:EAR1");
                }
            });
        }
    }

    render() {
        const Wrap = styled.div`
          .hidden{display:none !important}
          .showDiv{display:block !important}`;
        return (
            <EkoModal
                showModal={this.state.showModal}
                title={"E Arşiv Fatura Gönder"}
                onHide={() => {
                    this.setState({
                        showModal: false,
                        formData: this.defaultFormData(),
                        screenPosition: 0,
                        smsErrorDiv: 0,
                        smsPasswordTxt: "",
                        invoiceNote:"",
                        time: {},
                        errors: {}
                    });
                    this.onCancel();
                }}
            >
                <div className={this.state.screenPosition == 0 ? "row showDiv" : "row hidden"}>
                    <div className={"col-md-12 text-center"}>
                        E-Arşiv Faturayı Göndermek Üzeresiniz.<br/>Bu İşlemi Yapmak İstediğinize Eminmisiniz?
                        <br/>
                        <br/>
                    </div>
                    <div className={"col-md-12"}>
                        <div className="col-md-12 text-center">
                            <textarea onChange={evt => this.setState({invoiceNote: evt.target.value})}
                                      name="note"
                                      style={{
                                          height: 90
                                      }}
                                      value={this.state.invoiceNote}
                                      placeholder={'Fatura Notu Girin'}
                                      className={'form-control adress-area address-editable'}>
                                    </textarea>
                            <button disabled={this.state.sendBtnDisabledStatus1} className="btn btn-default blue btn-sm" onClick={this.onSend.bind(this)}>
                                Gönder
                            </button>
                            <button className="btn btn-default btn-sm" onClick={this.onCancel.bind(this)}>
                                İptal
                            </button>
                        </div>
                    </div>
                </div>
                <div className={this.state.screenPosition == 1 ? "row showDiv" : "row hidden"}>
                    <div className={"col-md-12 text-center"}>
                        <div>
                            {this.state.time.m} Dakika, {this.state.time.s} Saniye
                            <br/>
                            İçinde Cep Telefonunuza Gelen Şifreyi Giriniz.
                            <br/>
                            <div
                                className={this.state.smsErrorDiv == 1 ? "showDiv alert alert-danger" : "hidden alert alert-danger"}
                                role="alert">
                                SMS Şifresini Yanlış Girdiniz.
                            </div>
                            <br/>
                            <input style={{width: "50%", margin:"0px auto"}} onChange={evt => this.setState({smsPasswordTxt: evt.target.value})}
                                   value={this.state.smsPasswordTxt} className={"form-control"} type={"text"}
                                   placeholder={"SMS Şifresini Girin"}/>
                            <br/><br/>
                            <button disabled={this.state.sendBtnDisabledStatus} className="btn btn-default blue btn-sm" onClick={this.onSendSMS.bind(this)}>
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
                <div className={this.state.screenPosition == 2 ? "row showDiv" : "row hidden"}>
                    <div className={"alert alert-danger"} role="alert">
                        Kurum Giriş Bilgilerinizi Girmemişsiniz.<br/>
                        <a href={"/dashboard/companySettings"}>Şirket Bilgileri Sayfası</a> Kurum Giriş Bilgileri
                        Sekmesindeki<br/>'E-Arşiv Portal Bilgileri'ni Doldurunuz.
                    </div>
                </div>
                <div className={this.state.screenPosition == 3 ? "row showDiv" : "row hidden"}>
                    <div className={"col-md-12 text-center"}>
                        <img src={"https://i0.wp.com/ekohesap.com/wp-content/uploads/2022/03/earchive-send-success.gif"} style={{width: "35%"}} />
                        <br/>
                        <h3>Faturanız e-Arşiv Portal'a başarıyla iletildi. <br/>SMS ve e-Posta müşterinize gönderildi.</h3>
                        <br/>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <input value={this.state.finalInvoiceLink} className={"form-control"} type={"text"}/>
                        </div>
                        <div className={"col-md-4"}>
                            <button className="btn btn-default blue btn-sm" onClick={() => {navigator.clipboard.writeText(this.state.finalInvoiceLink)}}>
                                Kopyala
                            </button>
                            <a target={"_blank"} className="btn success green  btn-sm" href={"https://api.whatsapp.com/send?phone="+this.state.finalPhoneNumber+"&text=Fatura Linki: "+this.state.finalInvoiceLink}>
                                <i style={{color:"white",fontSize:"16px"}} className={"fab fa-whatsapp"}></i>
                            </a>
                        </div>
                    </div>
                </div>
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices
    };
}

export default connect(mapStateToProps, actions)(EArchiveSendModal);
