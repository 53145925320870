export const CSS = `tbody tr td{
padding:0!important
}
.form-control3:focus {
    border: 1px solid #3598dc;
    outline: 0;
	    cursor: initial;
}

.form-control3 {
border: 0;
    height: 33px;
    background: transparent;
    margin: -13px;
    padding: 10px;
    width: calc(100% + 17px);
    border-right: 0px;
    cursor: default;
}
thead tr th {
    font-size: 13px!important;    color: #999;
}
thead {
    background: #fcfcfc;
}
.ticon{
	    width: 3%;
    text-align: center;    color: #828282;
}
.ticon2{
    text-align: center;    color: #828282;
}
thead tr th {
    border: 0px!important;
}
th.center{
text-align:center}
.fcolumn{
text-indent:4px;}
.propextra button{
	    border: 0px!important;
    box-shadow: none!important;
    padding: 8px 8px 9px!important;
}

.tplus{
    width: 30%;
    text-align: right;
    margin: auto 0 auto auto;}
.tplus tr{

    line-height: 2;

}
.center th.fcolumn{
    text-align: center;
	    line-height: 2.7;
}
.heighte{
	    margin-bottom: 5px;
		line-height: 28px;
}
.tinvoice thead tr th {
    text-align: center;
}
.tinvoicend {
    font-size: 16px!important;
    margin: -8px;
    color: #818285;
    position: absolute!important;
    background: #fff;
    border: 1px solid;
    padding: 6px 16px!important;
}
.alldiv {
    background: #f9f9f9;
    position: relative;
    display: flex;
    margin: -12px -20px 21px;
    padding: 5px 20px 5px 20px;
}
.heighte.row:first-child {
    margin-top: 14px;
}
.table td, .table th {
    font-size: 12px;
}
.tinvoice{
	table-layout: auto !important;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
@media print {
  body * {
    visibility: hidden;
  }
  table{width:90%}
 .tinvoice, .tinvoice * {
    visibility: visible;
  }
  .tinvoice {
    position: absolute;
    left: 20px;
	top:10px;
	right:50px;
	width:80%;
  }
  .table>tbody>tr>th {
    padding: 4px;
}
.table-bordered{
border:1px solid #000}
}
`;