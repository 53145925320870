import React from "react";
import {
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import Util from "../../util/Util";
import HelperContainer from "../../util/HelperContainer";
import AddCustomer from "../Customer/AddCustomer";
import AddSupplier from "../Supplier/AddSupplier";
import moment from "moment";
import AddDocumentButton from "../../components/forms/AddDocumentButton";

export default class AddSoleprofv2InvoiceHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerActiveTab: "address",
      showCustomer: false,
      showSupplier: false,
      formDataCustomer: AddCustomer.getDefaultForm(),
      formDataSupplier: AddSupplier.getDefaultForm(),
      fromWho: false,
      fromWhoId: 0,
      fromWhoAddress: false,
      fromWhoAddress2: false,
      fields: [
        {
          title: "Kime",
          type: "select",
          id: "customer_id",
          defaultText: "SEÇİNİZ",
          optionValue: "name",
          data: [],
          value: this.props.fromWhoId || 0,

          onAddNewItemClick: this.onAddNewFromWhoClick.bind(this),
          optionId: "id",
          searchable: true,
          isVertical: true
        },
        {
          title: "Döviz Tipi",
          type: "select",
          id: "currency",
          defaultText: "SEÇİNİZ",
          optionValue: "code",
          data: [],
          disabled: false,
          optionId: "code",
          isVertical: true
        },
        {
          title: "Kur",
          type: "money",
          id: "currency_rate",
          isVertical: true,
          precision: 4
        },
        {
          title: "Belge No",
          type: "text",
          id: "document_number",
          placeHolder: "Belge No"
        },
        { title: "Fatura Tarihi", type: "date", id: "date" },
        { title: "Vade Tarihi", type: "date", id: "due_date" }
      ]
    };
  }

  componentWillMount() {
    let fields = this.state.fields;
    fields[0].id = "customer_id";
    this.setState({ fields: fields });

    if (this.props.fromWhoId > 0 && !this.props.formData.customer_name) {
      this.setCustomer(this.props.fromWhoId);
    }
  }

  componentWillReceiveProps(nextProps) {
    let fields = this.state.fields;

    if (nextProps.fromWhoId && this.props.fromWhoId !== nextProps.fromWhoId) {
      this.setCustomer(nextProps.fromWhoId);
    }
    if (nextProps.fromWhos) {
      fields[0].data = nextProps.fromWhos;
    }

    if (nextProps.currencies) {
      fields[1].data = nextProps.currencies;
    }

    if (nextProps.formData.currency && nextProps.formData.currency === "TRY") {
      fields[2].disabled = true;
    }

    if (!nextProps.formData.currency) {
      fields[2].disabled = true;
      this.onChange("currency", "TRY");
    }

    this.setState({ fields: fields });
  }

  onChange(field, value) {
    if (this.props.onChange) {
      if (field === "customer_name") {
        this.setState({ fromWhoId: 0 });
        this.props.onChange("invoice", "customer_id", null);
      }

      this.props.onChange("invoice", field, value);
    }

    if (field === "currency" || field === "date")
      this.calculateCurrencyRate(field, value);

    if (field === "currency") {
      let fields = this.state.fields;

      if (value === "TRY") {
        fields[2].disabled = true;
      } else fields[2].disabled = false;
      this.setState({ fields: fields });
    }

    if (field === "date") {
      this.setState({ formData: { date: value } });
    }

    if (field === "customer_id") {
      //this.fetchAddresses(value);
      this.setState({ fromWhoId: value });
      this.setCustomer(value);
    }

    if (field === "supplier_id") {
      //this.fetchAddresses(value);
      this.setState({ fromWhoId: value });
      this.setSupplier(value);
    }
  }

  onAddNewFromWhoClick() {
    this.setState({
      showCustomer: true,
      formDataCustomer: AddCustomer.getDefaultForm()
    });
  }


  calculateCurrencyRate(field, value) {
    const self = this;
    let currency = this.props.formData.currency;
    let date = this.props.formData.date;
    if (field === "currency") {
      currency = value;
    }
    if (field === "date") date = value;
    if (currency) {
      Api.getCurrencyRate(currency, moment(date).format("YYYY-MM-DD"), function(
        rate,
        err
      ) {
        if (rate) {
          self.onChange("currency_rate", Util.convertDecimalToString(rate, 4));
        }
      });
    }
  }

  setCustomer(id) {
    var self = this;

    Api.paralel(
      [
        "/customer/" + id,
        "/customerContact/showAll/" + id,
        "/customerAddress/showAll/" + id
      ],
      function(responses) {
        let formData = {};
        formData.customer = HelperContainer.filterObject(responses[0].customer);


        if (formData.customer.currency !== "TRY") {
          const fields = self.state.fields;

          fields[1].disabled = true;

          fields[2].disabled = false;

          self.onChange("currency", formData.customer.currency, true);
          self.onChange("currency_rate", 1);
          self.calculateCurrencyRate("currency", formData.customer.currency);
          self.setState({fields});
        } else {
          const fields = self.state.fields;
          fields[1].disabled = false;
          self.setState({fields}, () => {
            self.onChange("currency", formData.customer.currency, true);
            self.onChange("currency_rate", 1);
          });

        }



        if (
          responses[1].customerContacts &&
          responses[1].customerContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].customerContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].customerAddresses &&
          responses[2].customerAddresses.length > 0
        ) {
          const address = responses[2].customerAddresses.filter(function(a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
          if (!formData.customer.delivery_same_as_invoice_address) {
            const addressDelivery = responses[2].customerAddresses.filter(
              function(a) {
                return a.address_type === 1;
              }
            );
            formData.deliveryAddress = HelperContainer.filterObject(
              addressDelivery[0]
            );
          } else formData.deliveryAddress = formData.address;
        } else {
          formData.deliveryAddress = {};
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address) {
          formData.address.is_abroad = !formData.address.city;
          if (formData.address.city)
            fromWhoAddress =
              formData.address.address +
              "\n" +
              formData.address.county.name +
              "\n" +
              formData.address.city.name;
          else fromWhoAddress = formData.address.address;
        } else fromWhoAddress = false;

        let fromWhoAddress2 = self.state.fromWhoAddress2;
        if (formData.deliveryAddress && formData.deliveryAddress.address) {
          formData.deliveryAddress.is_abroad = !formData.deliveryAddress.city;
          if (formData.deliveryAddress.city)
            fromWhoAddress2 =
              formData.deliveryAddress.address +
              "\n" +
              formData.deliveryAddress.county.name +
              "\n" +
              formData.deliveryAddress.city.name;
          else fromWhoAddress2 = formData.deliveryAddress.address;
        } else fromWhoAddress2 = false;

        self.setState({
          formDataCustomer: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: fromWhoAddress2
        });

        self.onChange("address", fromWhoAddress);
        self.onChange("address2", fromWhoAddress2);
      }
    );
  }

  setSupplier(id) {
    var self = this;

    Api.paralel(
      [
        "/supplier/" + id,
        "/supplierContact/showAll/" + id,
        "/supplierAddress/showAll/" + id
      ],
      function(responses) {
        console.log(responses);
        let formData = {};
        formData.supplier = HelperContainer.filterObject(responses[0].supplier);

        if (
          responses[1].supplierContacts &&
          responses[1].supplierContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].supplierContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].supplierAddresses &&
          responses[2].supplierAddresses.length > 0
        ) {
          const address = responses[2].supplierAddresses.filter(function(a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
        } else {
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address) {
          formData.address.is_abroad = !formData.address.city;
          if (formData.address.city)
            fromWhoAddress =
              formData.address.address +
              "\n" +
              formData.address.county.name +
              "\n" +
              formData.address.city.name;
          else fromWhoAddress = formData.address.address;
        } else fromWhoAddress = false;

        self.setState({
          formDataSupplier: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: false
        });

        self.onChange("address", fromWhoAddress);
      }
    );
  }

  onMount() {}

  getInputByType(field) {
    const inputProps = {
      onChange: this.onChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.props.formData,
      errors: this.props.errors
    };
    let rElement = null;
    switch (field.type) {
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={false}
            type="text"
            labelMd={4}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="number"
            labelMd={4}
            precision={field.precision}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "money":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            precision={field.precision}
            type="money"
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "mask":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            maskChar={field.maskChar}
            mask={field.mask}
            precision={field.precision}
            isVertical={field.isVertical}
            labelMd={field.labelMd || 4}
            colMd={field.colMd || 7}
            label={field.title}
            type="mask"
            placeHolder={field.placeHolder}
            tooltip={true}
          />
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            clearable={true}
            labelMd={4}
            colMd={7}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "textarea":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="textarea"
            className={field.className || false}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "select":
        rElement = (
          <EkoFormSelect
            {...inputProps}
            onAddNewItemClick={field.onAddNewItemClick}
            onSearchChange={value => this.onChange("customer_name", value)}
            searchable={field.searchable}
            id={field.id}
            isVertical={true}
            disabled={field.disabled !== undefined ? field.disabled : false}
            defaultText={this.props.formData.customer_name || field.defaultText}
            optionId={field.optionId}
            options={field.data}
            optionValue={field.optionValue}
            label={field.title}
          />
        );
        break;
      default:
    }
    return rElement;
  }

  onCustomerSave(id) {
    this.setState({ showCustomer: false });
    this.setCustomer(this.state.fromWhoId);
    if (this.props.onRefresh) this.props.onRefresh();
    this.onChange("customer_id", id);
  }

  render() {
    const fields = this.state.fields;

    let addressError = "";
    let addressError2 = "";
    if (this.props.errors.description)
      addressError = this.props.errors.description;
    if (this.props.errors.note) addressError2 = this.props.errors.note;

    return (
      <div className="portlet light">
         <div className="portlet-title" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {this.props.title}
            </span>
            </div>
            <div style={{ justifyContent: 'flex-end',display: 'flex',flex: 'auto' }}>
                <AddDocumentButton style={{ margin: 0,transform: 'translateY(-25%)' }} />
            </div>
          </div>
        <div className="portlet-body">
          <div className="alldiv">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  {this.getInputByType(fields[0])}
                </div>
                <div className="col-md-6">{this.getInputByType(fields[1])}</div>
                <div className="col-md-6">{this.getInputByType(fields[2])}</div>
              </div>
            </div>
            <div className="col-md-5">


            </div>
            <div className="col-md-4">
              <div className="heighte row">
                {this.getInputByType(fields[3])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[4])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[5])}
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div style={{ marginTop: 30 }}>{this.props.children}</div>
        </div>
        <AddCustomer
          activeTab={this.state.customerActiveTab}
          showModal={this.state.showCustomer}
          formData={this.state.formDataCustomer}
          onSave={this.onCustomerSave.bind(this)}
          onCancel={() => {
            this.setState({ showCustomer: false });
          }}
          errors={{}}
        />
      </div>
    );
  }
}
