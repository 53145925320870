import React, { Component } from "react";
import { connect } from "react-redux";

export default function(ComposedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      if (!this.props.authenticated) {
        this.redirectToLogin();
      }
    }

    redirectToLogin = () => {
      const path = this.props.location.pathname;
      let addPath = "";

      if (path.includes("dashboard")) {
        addPath = path;
      }

      if (addPath !== "" && this.props.loggedOut !== true) {
        window.location.href =
          window.location.origin + "/login?redirect=" + addPath;
      } else {
        window.location.href = window.location.origin + "/login";
      }
    };

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.redirectToLogin();
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.auth.authenticated,
      loggedOut: state.auth.loggedOut
    };
  }

  return connect(mapStateToProps)(Authentication);
}
