import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormDate, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import Util from '../../util/Util';
import {FETCH_CUSTOMERS} from '../../actions/types'

class AddProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {}
        }
    }

    defaultFormData() {
        return {
            project: {}
        }
    }

    componentDidMount() {
        this.props.fetchData("/customer/list", FETCH_CUSTOMERS);
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}

        this.setState({formData: formData, errors: errors});
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        this.setState({formData: formData, errors: {}, showModal: nextProps.showModal});
    }

//NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada project.firstname)
// type=> address, field=>city_id gibi
    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        console.log(formData);
        this.setState({formData: bigForm, errors: errors});
    }


    //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
    //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
    //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    onSave() {
        var self = this;
        let formData = this.state.formData;

        if (typeof formData.project.customer_id === "undefined") {
            formData.project.customer_id = null
        }

        console.log("save called");
        self.setState({saving: true});
        let validationRules = [];
        let postRules = [];

        HelperContainer.validateContainerData(self.props, validationRules, formData, self.state.errors, function (validated, errors, validationFields) {
            if (validated) {
                console.log("validated response");
                let func = "post";
                let url = "/project";
                if (formData.project.id) {
                    func = "put";
                    url = "/project/" + formData.project.id;
                }
                formData.project.start_date = Util.convertToDatabaseDate(formData.project.start_date);
                formData.project.end_date = Util.convertToDatabaseDate(formData.project.end_date);
                formData.project.unallocated_sga_rate = formData.project.unallocated_sga_rate && formData.project.unallocated_sga_rate.replace('%', '')
                formData.project.unallocated_sga_rate = formData.project.unallocated_sga_rate && Util.convertMoneyToDecimal(formData.project.unallocated_sga_rate || "0,00")
                Api[func](url, formData.project, function (response, postErrors) {
                    if (postErrors) {
                        errors.project = postErrors;
                        self.setState({saving: false, errors: errors});
                    } else {
                        HelperContainer.postContainerData(self.props, validationFields, postRules, formData, self.state.errors, function (validated, postErrors) {
                            if (postErrors.hasOwnProperty("sysError")) {
                                toastr.error('Hata', 'Genel bir hata oluştu, lütfen daha sonra deneyiniz');
                            } else {
                                self.setState({saving: false, errors: postErrors, formData: self.defaultFormData()});
                                toastr.success('Başarılı', 'Başarıyla Kaydedildi');
                                self.props.onSave();
                            }
                        })
                    }
                })
            } else {
                if (errors.hasOwnProperty("sysError")) {
                    toastr.error('Hata', 'Genel bir hata oluştu, lütfen daha sonra deneyiniz');
                } else {
                    self.setState({saving: false, errors: errors});
                }
                //toastr.error("Lütfen Kontrol Ediniz","",{component:<EkoToastrError errors={errors}></EkoToastrError>});
                //  self.setState({saving:false,errors:errors});
            }

        });

    }

    onCancel() {
        console.log("cancel called");
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    newItem(val) {
        console.log(val)
    }

    render() {
        const projectProps = {
            onChange: this.setFieldChange.bind(this, "project"),
            onMount: this.setFieldMount.bind(this, "project"),
            formData: this.state.formData.project,
            errors: this.state.errors.project
        }


        return <EkoModal
            showModal={this.state.showModal}
            title="Proje Ekle"
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_project_modal"}>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...projectProps} //formData.project.firrsname =
                                              label="Projenin Adı"
                                              id="name" //errors.project.firstname = bla bla hatasi
                                              isVertical/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormSelect {...projectProps}
                                           searchable={true}
                                           id="customer_id"
                                           clearable={true}
                                           optionValue="name"
                                           defaultText="Seçiniz"
                                           options={this.props.customers}
                                           label="Müşteri"
                                           isVertical
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <EkoFormDate
                                {...projectProps}
                                id="start_date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                label="Başlangıç Tarihi"
                                isVertical
                            />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <EkoFormDate
                                {...projectProps}
                                id="end_date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                label="Bitiş Tarihi"
                                isVertical
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...projectProps}
                                              label="Firma Genel Giderlerindeki Payı"
                                              id="unallocated_sga_rate"
                                              type="percent"
                                              isVertical
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...projectProps}
                                              label="Proje Açıklaması"
                                              id="description"
                                              type="textarea"
                                              isVertical
                            />
                        </div>
                    </div>
                </div>

                <Modal.Footer>
                    <div className="row buttonProccess2">
                        <EkoFormButton label="Kaydet"
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>

            </EkoForm></EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        customers: state.data.customers,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddProject);
