import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddSalesInvoice from "./AddSalesInvoice";
import Api from "../../util/Api";
import Util from "../../util/Util";
import Style from "style-it";
import { VCSS } from "../../css/voucher.js";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { FETCH_CUSTOMERS_ALL, FETCH_SUPPLIERS_ALL } from "../../actions/types";
import UtilHelper from "../../util/UtilHelper";
import {toastr} from "react-redux-toastr";

class SalesInvoiceAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      calculate: false,
      errors: {},
      documentType: false,
      fromWhos: []
    };
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        item_id: 0,
        asset_id: 0,
        item_code: "",
        istisna_kodu: 0,
        description: "",
        quantity: 1,
        unit_price_fc: 0.0,
        discount_rate: 0,
        unit_id: 0,
        discount_amount_fc: 0,
        net_amount_fc: 0,
        vat_rate_type_id: 0,
        vat_amount_fc: 0,
        warehouse_id: 0,
        tags: []
      }
    );
  }

  defaultFormData() {
    return {
      invoice: {},
      rows: [this.defaultRowData()],
      footer: {}
    };
  }

  defaultErrors() {
    return {
      rows: [
        {
          item_id: false,
          asset_id: false,
          description: false,
          quantity: false,
          unit_price_fc: false,
          discount_rate: false,
          unit_id: false,
          discount_amount_fc: false,
          net_amount_fc: false,
          vat_rate_type_id: false,
          vat_amount_fc: false,
          otherTaxes: false
        }
      ],
      invoice: {},
      footer: {}
    };
  }

  fetchFromWho() {

    
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
  }

  setFromWhos(nextProps) {
    switch (this.state.documentType) {
      case 1:
        if (nextProps.customersAll) {
          const fromWhos = nextProps.customersAll.filter(function(customer) {
            return customer.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      case 4:
        if (nextProps.customersAll) {
          const fromWhos = nextProps.customersAll.filter(function(customer) {
            return customer.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      case 2:
        if (nextProps.suppliersAll) {
          const fromWhos = nextProps.suppliersAll.filter(function(supplier) {
            return supplier.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      default:
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customersAll && nextProps.suppliersAll)
      this.setFromWhos(nextProps);
  }

  prepareInvoiceLineForEdit(line, otherTaxes, isCopied) {
    //console.log('line',line);

    let row = {};

    if (!isCopied) row.id = line.id;
    row.sales_shipment_line_id = line.sales_shipment_line_id??null;
    row.proforma_line_id = line.proforma_line_id??null;
    row.sales_invoice_id = line.sales_invoice_id;
    row.item_id = line.item_id;
    row.asset_id = line.asset_id;
    row.item_code = line.item_code;
    row.istisna_kodu = line.istisna_kodu;
    row.description = line.description;
    row.quantity = Util.convertDecimalToMoney6x(line.quantity);
    row.unit_id = line.unit_id;
    row.warehouse_id = line.warehouse_id;
    row.vat_rate_type_id = line.vat_rate_type_id;
    row.discount_amount_fc = Util.convertDecimalToMoney(
      line.discount_amount_fc
    );
    row.discount_rate = Util.convertNumberToPercent(line.discount_rate);
    row.unit_price_fc = UtilHelper.money.convertDecimalToMoney8(
      line.unit_price_fc
    );
    row.net_amount_fc = Util.convertDecimalToMoney(line.net_amount_fc);

    row.with_tax_amount_fc = UtilHelper.money.convertDecimalToMoney8(
      parseFloat(line.net_amount_fc) + parseFloat(line.vat_amount_fc)
    );

    row.vat_amount_fc = Util.convertDecimalToMoney(line.vat_amount_fc);

    console.log(typeof row.vat_amount_fc);
    row.otherTaxes = false;
    if (otherTaxes && otherTaxes.length > 0) {
      row.otherTaxes = [];
      otherTaxes.forEach(function(otherTax) {
        let tax = {};
        if (!isCopied) {
          tax.id = otherTax.id;
          tax.sales_invoice_id = otherTax.sales_invoice_id;
          tax.sales_invoice_line_id = otherTax.sales_invoice_line_id;
        }
        tax.tax_type_code = otherTax.tax_type_code;
        tax.tax_type_id = otherTax.tax_type_id;
        tax.vat_reduction_type_code = otherTax.vat_reduction_type_code;
        tax.tax_amount_fc = Util.convertDecimalToMoney(otherTax.tax_amount_fc);
        tax.tax_rate = Util.convertNumberToPercent(otherTax.tax_rate);
        row.otherTaxes.push(tax);
      });
    }

    row.exportedLines = false;

    Api.get("/salesInvoiceLine/exported/"+line.id, (response, err) => {
      if(response){
        row.exportedLines = response;
      }
    });
    return row;
  }

  prepareInvoiceForEdit(invoice, isCopied) {
    let ret = {};

    console.log("inv", invoice);

    if (!isCopied) {
      ret.id = invoice.id;
      ret.legal_no = invoice.legal_no;
      ret.legal_serial = invoice.legal_serial;
      ret.date = Util.convertFromDatabaseDate(invoice.date);
      ret.due_date = Util.convertFromDatabaseDate(invoice.due_date);
      ret.shipping_date = Util.convertFromDatabaseDate(invoice.shipping_date);
      ret.currency_rate = Util.convertDecimalToMoneyNew(
        invoice.currency_rate,
        4
      );
    }
    ret.currency_rate = Util.convertDecimalToMoneyNew(invoice.currency_rate, 4);
    ret.customer_id = invoice.customer_id
      ? parseInt(invoice.customer_id, 10)
      : null;
    ret.supplier_id = invoice.supplier_id
      ? parseInt(invoice.supplier_id, 10)
      : null;
    ret.currency = invoice.currency;
    ret.irsaliye_rows = invoice.irsaliye_rows;
    ret.sgk_file_no = invoice.sgk_file_no;
    ret.sgk_period = invoice.sgk_period;
    if(invoice.is_exported===0){
      ret.is_exported = true;
      ret.is_exported2 = true;
    }else if(invoice.is_exported===1){
      ret.is_exported = false;
      ret.is_exported2 = true;
    }else if(invoice.is_exported===2){
      ret.is_exported = true;
      ret.is_exported2 = false;
    }
    ret.document_type = invoice.document_type;
    ret.description = invoice.description;
    ret.tags = invoice.tags;
    ret.warehouse_id = invoice.warehouse_id;
    ret.stock_status = invoice.stock_status;
    ret.relatedTags = invoice.relatedTags;
    ret.supplierData = invoice.supplierData || {};
    ret.customerData = invoice.customerData || {};
    ret.customerData = invoice.customerData || {};
    ret.updated_at = invoice.updated_at || null;
    ret.is_checked = invoice.is_checked || false;
    
    return ret;
  }

  /**
   *
   * @param proforma
   */
  prepareProforma = proforma => {
    let ret = {};

    //ret.date = Util.convertFromDatabaseDate(proforma.proforma_date)
    //ret.due_date = Util.convertFromDatabaseDate(proforma.due_date)
    ret.currency_rate = Util.convertDecimalToString(proforma.currency_rate, 4);
    ret.currency_rate_proforma = Util.convertDecimalToString(proforma.currency_rate, 4);
    ret.currency = proforma.currency;
    ret.customer_id = proforma.customer_id
      ? parseInt(proforma.customer_id, 10)
      : null;

    ret.description = proforma.description;

    return ret;
  };
  prepareSalesReturn = item => {
    let ret = {};
    ret.currency_rate = Util.convertDecimalToString(item.currency_rate, 4);
    ret.currency = item.currency;
    ret.supplier_id = item.supplier_id
        ? parseInt(item.supplier_id, 10)
        : null;

    ret.description = item.description;

    return ret;
  };
  fetchProformaAndPrepareData = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
      ["/proforma/" + id, "/proformaLine/showAll/" + id],
      (responses, error) => {
        if (responses[0].proforma) {
          formData.invoice = this.prepareProforma(responses[0].proforma);
          formData.proformaId = id;
        }

        if (responses[1].proformaLines) {
          const lines = responses[1].proformaLines;
          var taxApis = [];

          lines.forEach(function(line) {
            taxApis.push("/proformaTaxLine/showAll/" + line.id);
            line.proforma_line_id = line.id;
          });

          formData.rows = lines.map(line => {
            return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
          });
        }

        this.setState({
          formData: formData,
          documentType: 1
        });
      }
    );
  };
    fetchProformaAndPrepareDataMulti = id => {
    const formData = { ...this.state.formData };
    var self = this;
      Api.get("/proforma/merge/" + id,  (response, error) => {
        if (response.lines) {
          formData.invoice = this.prepareProforma(response.proforma);
          formData.proformaId = response.proforma.id;
          const lines = response.lines;
          var taxApis = [];

          lines.forEach(function(line) {
            taxApis.push("/proformaTaxLine/showAll/" + line.id);
            line.proforma_line_id = line.id;
          });

          formData.rows = lines.map(line => {
            return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
          });
          this.setState({
            formData: formData,
            documentType: 1,
          });
        }
      });
  };

  fetchProformaAndPrepareDataToLine = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
      ["/proformaLine/merge/" + id],
      (responses, error) => {
        if (responses[0].lines) {
          formData.invoice = this.prepareProforma(responses[0].proforma);
          formData.proformaId = responses[0].proforma.id;
          const lines = responses[0].lines;
          var taxApis = [];

          lines.forEach(function(line) {
            taxApis.push("/proformaTaxLine/showAll/" + line.id);
            line.proforma_line_id = line.id;
          });

          formData.rows = lines.map(line => {
            return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
          });
        }

        this.setState({
          formData: formData,
          documentType: 1
        });
      }
    );
  };

  fetchCollectionToInvoice = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
      ["/collection/collectionToInvoice/" + id],
      (responses, error) => {
        formData.invoice = this.prepareProforma(responses[0].collection);
        const lines = responses[0].lines;
        formData.rows = lines.map(line => {
          return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
        });
        this.setState({
          formData: formData,
          documentType: 1
        });
      }
    );
  };
  fetchSalesReturnAndPrepareData = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
        ["/purchaseInvoice/" + id, "/purchaseInvoiceLine/showAll/" + id],
        (responses, error) => {
          if (responses[0].purchaseInvoice) {
            formData.invoice = this.prepareSalesReturn(responses[0].purchaseInvoice);
          }

          if (responses[1].purchaseInvoiceLines) {
            const lines = responses[1].purchaseInvoiceLines;
            var taxApis = [];

            lines.forEach(function(line) {
              taxApis.push("/purchaseInvoiceTaxLine/showAll/" + line.id);
            });

            formData.rows = lines.map(line => {
              return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
            });
          }

          this.setState({
            formData: formData,
            documentType: 2
          });
        }
    );
  };
  fetchPurchaseReturnAndPrepareData = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
        ["/purchaseInvoice/" + id, "/purchaseInvoiceLine/showAll/" + id],
        (responses, error) => {
          if (responses[0].purchaseInvoice) {
            formData.invoice = this.prepareSalesReturn(responses[0].purchaseInvoice);
          }

          if (responses[1].purchaseInvoiceLines) {
            const lines = responses[1].purchaseInvoiceLines;
            var taxApis = [];

            lines.forEach(function(line) {
              taxApis.push("/purchaseInvoiceTaxLine/showAll/" + line.id);
            });

            formData.rows = lines.map(line => {
              return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
            });
          }

          this.setState({
            formData: formData,
            documentType: 1
          });
        }
    );
  };
  prepareSalesShipment = (salesShipment) => {
    let ret = {};
    ret.currency_rate = Util.convertDecimalToString(
        salesShipment.currency_rate,
        4
    );
    ret.irsaliye_rows = salesShipment.irsaliye_rows;
    ret.currency = salesShipment.currency;
    ret.stock_status = 1;
    ret.warehouse_id = salesShipment.warehouse_id;
    ret.customer_id = salesShipment.customer_id
        ? parseInt(salesShipment.customer_id, 10)
        : null;
    ret.description = salesShipment.description;
    return ret;
  };
  fetchSalesShipment = (id) => {
    const formData = { ...this.state.formData };
    Api.get("/salesShipment/salesShipmentMerge/" + id,  (response, error) => {
      if (response.lines) {
        formData.shipmentId = id;
        response.salesShipment.irsaliye_rows = response.irsaliye_rows;
        //response.salesShipment.irsaliye_rows = [{ irsaliye_no: response.salesShipment.document_no, irsaliye_date: Util.convertFromDatabaseDate(response.salesShipment.date) }];
        formData.invoice = this.prepareSalesShipment(response.salesShipment);
        const lines = response.lines;
        formData.rows = lines.map((line) => {
          line.sales_shipment_line_id = line.id;
          line.vat_rate_type_id = 1;
          line.discount_amount_fc = 0;
          line.discount_rate = 0;
          line.quantity = Util.convertMoneyToDecimal(line.quantity || '0,00');
          line.net_amount_fc = (line.amount_fc || '0,00');
          line.net_amount =  (line.amount || '0,00');
          line.unit_price =  Util.convertMoneyToDecimal(line.unit_price || '0,00');
          line.unit_price_fc =  Util.convertMoneyToDecimal(line.unit_price_fc || '0,00');
          line.vat_amount_fc = Util.convertMoneyToDecimal(line.vat_amount_fc || '0,00');
          line.vat_rate_type_id = line.item_vat_rate_type_id;
          return this.prepareInvoiceLineForEdit(line, [], true);
        });
        this.setState({
          formData: formData,
          documentType: 1,
        });
      }
    });
  };
  fetchSalesShipmentToLine = (id) => {
    const formData = { ...this.state.formData };
    Api.get("/salesShipment/salesShipmentMergeToLine/" + id,  (response, error) => {
      if (response.lines) {
        formData.shipmentId = id;
        response.salesShipment.irsaliye_rows = response.irsaliye_rows;
        //response.salesShipment.irsaliye_rows = [{ irsaliye_no: response.salesShipment.document_no, irsaliye_date: Util.convertFromDatabaseDate(response.salesShipment.date) }];
        formData.invoice = this.prepareSalesShipment(response.salesShipment);
        const lines = response.lines;
        formData.rows = lines.map((line) => {
          line.sales_shipment_line_id = line.id;
          line.vat_rate_type_id = 1;
          line.discount_amount_fc = 0;
          line.discount_rate = 0;
          line.quantity = Util.convertMoneyToDecimal(line.quantity || '0,00');
          line.net_amount_fc = (line.amount_fc || '0,00');
          line.net_amount =  (line.amount || '0,00');
          line.unit_price =  Util.convertMoneyToDecimal(line.unit_price || '0,00');
          line.unit_price_fc =  Util.convertMoneyToDecimal(line.unit_price_fc || '0,00');
          line.vat_amount_fc = Util.convertMoneyToDecimal(line.vat_amount_fc || '0,00');
          line.vat_rate_type_id = line.item_vat_rate_type_id;
          return this.prepareInvoiceLineForEdit(line, [], true);
        });
        this.setState({
          formData: formData,
          documentType: 1,
        });
      }
    });
  };
  componentWillMount() {
    var self = this;

    const {
      router: {
        location: { query }
      }
    } = this.props;

    if (query.proforma) {
      if (query.proforma && query.proforma.includes(',')) {
        this.fetchProformaAndPrepareDataMulti(query.proforma);
      } else {
        this.fetchProformaAndPrepareData(query.proforma);
      }
    }
    if (query.proformaLine) {
      this.fetchProformaAndPrepareDataToLine(query.proformaLine);
    }
    if (query.collection_id) {
      this.fetchCollectionToInvoice(query.collection_id);
    }
    if (query.sales_return) {
      this.fetchSalesReturnAndPrepareData(query.sales_return);
    }
    if (query.purchase_return) {
      this.fetchPurchaseReturnAndPrepareData(query.purchase_return);
    }
    if (query.shipment) {
      this.fetchSalesShipment(query.shipment);
    }
    if (query.shipmentLine) {
      this.fetchSalesShipmentToLine(query.shipmentLine);
    }
    if (this.props.params && this.props.params.id) {
      const invoiceId = this.props.params.id;
      let formData = this.state.formData;
      Api.paralel(
        [
          "/salesInvoice/" + invoiceId,
          "/salesInvoiceLine/showAll/" + invoiceId,
          "/salesInvoice/RelatedTags/" + invoiceId
        ],
        (responses, error) => {
          responses[0].salesInvoice.tags = responses[2];
          responses[0].salesInvoice.relatedTags = responses[2].map(el => el.id);
          const docUrl = responses[0].salesInvoice?.document_url;
          formData.invoice = self.prepareInvoiceForEdit(
            responses[0].salesInvoice
          );

          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }

          let documentType = formData.invoice.document_type;
          let taxApis = [];
          let lines = [];
          if (
            responses[1].salesInvoiceLines &&
            responses[1].salesInvoiceLines.length > 0
          ) {
            lines = responses[1].salesInvoiceLines;

            lines.forEach(function(line) {
              taxApis.push("/salesInvoiceTaxLine/showAll/" + line.id);
            });

            if (taxApis.length > 0) {
              Api.serialGet(taxApis, function(taxResponses, taxErrors) {
                formData.rows = lines.map(function(line, index) {
                  return self.prepareInvoiceLineForEdit(
                    line,
                    taxResponses[index].salesInvoiceTaxLines
                  );
                });

                self.setState(
                  { formData: formData, documentType: documentType },
                  function() {
                    self.fetchFromWho();
                    self.setFromWhos(self.props);
                  }
                );
              });
            } else {
              formData.rows = lines.map(function(line) {
                return self.prepareInvoiceLineForEdit(line, false);
              });
              self.setState(
                { formData: formData, documentType: documentType },
                function() {
                  self.fetchFromWho();
                  self.setFromWhos(self.props);
                }
              );
            }
          } else {
            self.setState(
              { formData: formData, documentType: documentType },
              function() {
                self.fetchFromWho();
                self.setFromWhos(self.props);
              }
            );
          }
        }
      );
    } else if (this.props.params && this.props.params.type) {
      let documentType = this.state.documentType;
      switch (this.props.params.type) {
        case "toCustomer":
          documentType = 1;
          break;
        case "toSupplier":
          documentType = 2;
          break;
        case "toFixedAsset":
          documentType = 4;
          break;
        default:
          documentType = 3;
      }
      self.setState({ documentType: documentType }, function() {
        self.fetchFromWho();
        self.setFromWhos(self.props);
      });
    } else if (this.props.route.name && this.props.route.name === "copied") {
      const id = localStorage.getItem("sales_invoice_copied");
      if (id) {
        this.onCopied(id);
      } else this.props.router.push("/dashboard/salesInvoice");
    }
  }

  onCopied(invoiceId) {
    const self = this;
    let formData = this.state.formData;
    Api.paralel(
      [
        "/salesInvoice/" + invoiceId,
        "/salesInvoiceLine/showAll/" + invoiceId,
        "/salesInvoice/RelatedTags/" + invoiceId
      ],
      function(responses, error) {
        responses[0].salesInvoice.tags = responses[2];
        responses[0].salesInvoice.relatedTags = responses[2].map(el => el.id);
        formData.invoice = self.prepareInvoiceForEdit(
          responses[0].salesInvoice,
          true
        );
        let documentType = formData.invoice.document_type;
        let taxApis = [];
        let lines = [];

        if (
          responses[1].salesInvoiceLines &&
          responses[1].salesInvoiceLines.length > 0
        ) {
          lines = responses[1].salesInvoiceLines;

          lines.forEach(function(line) {
            taxApis.push("/salesInvoiceTaxLine/showAll/" + line.id);
          });

          if (taxApis.length > 0) {
            Api.serialGet(taxApis, function(taxResponses, taxErrors) {
              console.log(taxResponses);
              formData.rows = lines.map(function(line, index) {
                return self.prepareInvoiceLineForEdit(
                  line,
                  taxResponses[index].salesInvoiceTaxLines,
                  true
                );
              });

              self.setState(
                { formData: formData, documentType: documentType },
                function() {
                  self.fetchFromWho();
                  self.setFromWhos(self.props);
                }
              );
            });
          } else {
            formData.rows = lines.map(function(line) {
              return self.prepareInvoiceLineForEdit(line, false, true);
            });
            self.setState(
              { formData: formData, documentType: documentType },
              function() {
                self.fetchFromWho();
                self.setFromWhos(self.props);
              }
            );
          }
        }
      }
    );
  }

  callBackXMLData = (data) => {
    const self = this;
    const invoice = this.prepareInvoiceForEdit(data.invoice);
    const rows = data.rows.map(r => self.prepareInvoiceLineForEdit(r, r.otherTaxes || false))
    const d = {...this.state.formData, invoice, rows, inXml: true};
    if(this.props.params?.id){
      invoice.id = this.props.params.id
    }
    
    this.setState({formData: d, otherAmount: data.invoice?.otherAmount})
    setTimeout(() => {
      this.setState({calculate: true});
    }, 500);
  }

  onCancel() {
    this.props.router.push("/dashboard/salesInvoice");
  }

  render() {
    if (!this.state.documentType) return <EkoSpinner />;
    const editId =
      this.props.params && this.props.params.id ? this.props.params.id : false;

    return Style.it(
      VCSS,
      <div>
        <AddSalesInvoice
          {...this.state}
          editId={editId}
          paramType={this.props?.params?.type}
          callBackXMLData={this.callBackXMLData}
          onRefresh={this.fetchFromWho.bind(this)}
          errors={this.defaultErrors()}
          defaultErrors={() => this.defaultErrors()}
          defaultRowData={this.defaultRowData()}
          formData={this.state.formData}
          defaultFormData={() => this.defaultFormData()}
          onCancel={this.onCancel.bind(this)}
          viewOptions={this.props.viewOptions}
          redirectToBase={this.onCancel.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customersAll: state.data.customersAll,
    suppliersAll: state.data.suppliersAll,
    viewOptions: state.data.viewOptions,
    pageLoading: state.page.loading
  };
}

export default connect(mapStateToProps, actions)(SalesInvoiceAddUpdate);
