import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { EkoFormInputText, EkoFormButton } from "../elements/EkoForm";
import axios from "axios";
import EkoSpinner from "../elements/EkoSpinner";
import "../../css/SupportWidget.css";
import Api from "../../util/Api";
import { connect } from "react-redux";

const SupportWidget = ({ show, handleClose, page }) => {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearchedbyUser, setIsSearchedbyUser] = useState(false);

  const foundArticles = searchResults.map((el) => (
    /*  <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid gray",

        margin: 10,
      }}
    >
      <p style={{ paddingRight: 10, paddingLeft: 10, fontSize: 16 }}>
        <a rel="noopener noreferrer" target="_blank" href={el.url}>
          {el.title}
        </a>
      </p>
    </div> */

    <li>
      <a rel="noopener noreferrer" target="_blank" href={el.url}>
        {el.title}
      </a>
    </li>
  ));

  useEffect(() => {
    setLoading(true);
    Api.post("/support/filter", { page: page.pathname }, function (
      response,
      error
    ) {
      if (response) {
        setSearchResults(response.articles);
        setLoading(false);
        setIsSearchedbyUser(false);
        setSearchValue("");
      } else {
        setLoading(false);
        setIsSearchedbyUser(false);
        setSearchValue("");
      }
    });
  }, [page.pathname]);

  const getText = () => {
    if (foundArticles.length && isSearchedbyUser)
      return `Arama sonuçları (${foundArticles.length})`;
    if (isSearchedbyUser && !foundArticles.length)
      return "Gösterilecek sonuç yok";
    if (!isSearchedbyUser && foundArticles.length) {
      return "En çok arananlar";
    }
    return "Henüz herhangi bir arama yapmadınız";
  };

  useEffect(() => {
    // 500ms timeout

    const timer = setTimeout(() => {
      if (inputRef && inputRef.current) {
        if (searchValue && searchValue === inputRef.current.value) {
          setLoading(true);
          setIsSearchedbyUser(true);
          axios
            .get(
              `https://ekohesap.com/wp-json/wp/v2/search?search=${searchValue}&subtype=knowledgebase`
            )
            .then((response) => {
              console.log(response, "response from search");
              setSearchResults(response.data);
              setLoading(false);
            });
        }
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, inputRef]);

  const renderModal = () => (
    <Modal
      show={show}
      onHide={handleClose}
      bsSize="sm"
      dialogClassName="supportWidget"
    >
      <Modal.Header
        style={{
          backgroundColor: "#4A8EC0",
          color: "black",
          height: 40,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>Rehber</span>
        <svg
          onClick={handleClose}
          style={{ position: "absolute", right: 10, top: 12 }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
        </svg>
      </Modal.Header>
      <Modal.Body bsClass="modalBody">
        <input
          ref={inputRef}
          class="form-control"
          type="text"
          placeholder="Konu başlığını yazın"
          aria-label="ara"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        {loading ? (
          <EkoSpinner />
        ) : (
          <>
            <div
              style={{
                fontWeight: "bold",
                marginTop: 20,
                marginLeft: 15,
              }}
            >
              {getText()}
            </div>
            <ul style={{ fontSize: 16, marginTop: 10 }}>{foundArticles}</ul>
          </>
        )}
      </Modal.Body>
    </Modal>
  );

  return ReactDOM.createPortal(renderModal(), document.getElementById("root"));
};

function mapStateToProps(state) {
  return {
    page: state.routing.locationBeforeTransitions,
  };
}

export default connect(mapStateToProps, null)(SupportWidget);
