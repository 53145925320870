import React, { Component } from "react";
import { EkoFormButton } from "../elements/EkoForm";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "../elements/EkoSpinner";
import "sweetalert/dist/sweetalert.css";
import ZreportIntegrationModal from "./zreportIntegrationModal";
import { EkoFormInputText } from "../elements/EkoForm";

class zreportIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsertModal: false,
            errors: {},
            page_loading: false,
            records: {},
        };
    }

    componentWillMount() {
        this.setState({ page_loading: true });
        Api.get("/company/zReportServiceList", (res, err) => {
            if (err) {
                toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
                this.setState({ page_loading: false });
                return;
            }
            this.setState({ records: res.list, page_loading: false });
        });
    }

    componentWillReceiveProps(nextProps) {}
    onSave() {}

    openInsert() {
        this.setState({ showInsertModal: true });
    }

    hideModalHandler(addUpdate = false) {
        if (addUpdate) {
            this.componentWillMount();
        }
        this.setState({ showInsertModal: false });
    }

    form(fields) {
        return (
            <>
                <EkoFormInputText
                    {...fields}
                    label={"API Kullanıcı Adı"}
                    id={"api_username"}
                    name={"api_username"}
                />
                <EkoFormInputText
                    {...fields}
                    label={"API Parola"}
                    id={"api_password"}
                    name={"api_password"}
                />
            </>
        );
    }

    deleteButtonHandler(id) {
        toastr.confirm(
            "Silmek istediğinize emin misiniz?",
            {
                onOk: () => {
                    Api.get(`/company/zReportServiceDelete/${id}`, (res, err) => {
                        if (err) {
                            toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
                            return;
                        }
                        toastr.success("Başarılı", "Kayıt başarıyla silindi");

                        this.componentWillMount();
                    });
                },
                onCancel: () => {},
                okText: "Evet",
                cancelText: "Hayır",
            },
            "warning"
        );
    }

    render() {
        return (
            <>
                {this.state.page_loading === true ? (
                    <EkoSpinner />
                ) : (
                    <div className="portlet light">
                        <div className="portlet-title">
                            <div className="caption font-dark">
                <span className="caption-subject bold uppercase">
                  Z Raporu Entegrasyonları
                </span>
                            </div>
                            <div className="pull-right">
                                <EkoFormButton
                                    onClick={this.openInsert.bind(this)}
                                    label={"Yeni"}
                                />
                            </div>
                        </div>

                        <div className="portlet-body">
                            <div className={"row"}>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Api Kullanıcı Adı</th>
                                        <th>Api Parola</th>
                                        <th>Cihaz Tipi</th>
                                        <th>İşlemler</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.records.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center" }}>
                                                Uygun bir kayıt bulunamadı
                                            </td>
                                        </tr>
                                    ) : (
                                        this.state.records.map((record) => {
                                            return (
                                                <tr key={record.id}>
                                                    <td>{record.api_username}</td>
                                                    <td>{record.api_password}</td>
                                                    <td>{record.device_type}</td>
                                                    <td>
                                                        <EkoFormButton
                                                            onClick={this.openInsert.bind(this)}
                                                            label={"Düzenle"}
                                                        />
                                                        <EkoFormButton
                                                            onClick={this.deleteButtonHandler.bind(
                                                                this,
                                                                record.id
                                                            )}
                                                            label={"Sil"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ZreportIntegrationModal
                            title={"Yeni Ekle"}
                            showModal={this.state.showInsertModal}
                            formElements={this.form.bind(this)}
                            onHide={this.hideModalHandler.bind(this)}
                        />
                    </div>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(zreportIntegration);
