import React, {Component} from 'react'
import {
    EkoForm,
    EkoFormButton,
    EkoFormDate,
    EkoFormInputText,
    EkoFormSelect
} from '../../components/elements/EkoForm'
import {Modal, Nav, NavItem, Row, Tab} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import Util from '../../util/Util';
import TooltipService from "../../services/TooltipService";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddEmployee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            activeTab: 'personal',
            personalTab: [],
            disabled: {},
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:19,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        }
    }
    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({
            newTagRows: rows,
        });
    };
    getNewTagModalList = (formData) => {
        formData = formData || this.state.formData;
        if (formData.employee.id) {
            Api.get("/company/newTagRecordDocumentList/19/" + formData.employee.id, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }else{
                    this.resetNewTagModalList();
                }
            });
        }else{
            this.resetNewTagModalList();
        }
    }
    resetNewTagModalList = () => {
        this.setState(prevState => ({
            newTagRows: {
                ...prevState.newTagRows,
                rows: [
                    { id:null,new_tag_type_id:19,new_tag_id: "",record_id:null, rate: "%100,00" }
                ],
            },
        }));
    }
    defaultFormData() {
        return {
            employee: {
                handicap_level: 0,
                payroll_follow: 0,
            },
        }
    }


    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}
        let personalTab = ['firstname', 'lastname', 'middlename', 'gender', 'maiden', 'identity_number',
            'birthdate', 'marital_status', 'phone', 'email', 'address',
            'handicap_level', 'number_of_children', 'is_spouse_working',];
        this.setState({formData: formData, errors: errors, personalTab: personalTab});
    }

    decideTab() {
        const self = this;
        let activeTab = "personal";
        let keys = this.state.errors.employee ? Object.keys(this.state.errors.employee) : []
        let intersect = keys.filter(function (n) {
            return self.state.personalTab.indexOf(n) !== -1;
        });
        if (keys.length !== 0 && intersect.length === 0) activeTab = "work";
        this.setState({activeTab: activeTab})
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        let disabled = {...this.state.disabled};
        this.getNewTagModalList(formData);
        if (formData.employee.is_min_salary === 1) {
            disabled.mlc_in_net = true;
            disabled.salary_type = true;
            disabled.salary = true;
        } else {
            disabled.mlc_in_net = false;
            disabled.salary_type = false;
            disabled.salary = false;
        }
        disabled.mlc_in_net = formData.employee.salary_type === 1;

        let moneyFields = Util.mapDecimalToMoney([
            'ihbar_stamp_tax', 'kidem_stamp_tax', 'kidem_net', 'ihbar_net', 'ihbar_income_tax'
        ], formData.employee);


        formData.employee = {...formData.employee, ...moneyFields};


        this.setState({formData: formData, disabled: disabled, errors: {}, showModal: nextProps.showModal});
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        let disabled = {...this.state.disabled};

        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type] && value !== null)
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        if (type === 'employee') {

            switch (field) {
                case 'termination_date':
                    if (!value) bigForm.employee.termination_reason = '';
                    break;

                case 'marital_status':
                    if (value !== 2) bigForm.employee.is_spouse_working = '';

                    break;
                case 'gender':
                    if (value !== 1) bigForm.employee.maiden = '';
                    break;

                case 'is_min_salary':
                    if (value === 1) {
                        bigForm.employee.salary_type = 1;
                        disabled.salary_type = true;
                        bigForm.employee.mlc_in_net = 0;
                        disabled.mlc_in_net = true;
                        disabled.salary = true;
                        bigForm.employee.salary = Util.convertDecimalToMoney(this.props.viewOptions ? this.props.viewOptions.min_salary_value : '0.00');

                    } else {
                        disabled.salary_type = false;
                        disabled.salary = false;

                    }
                    break;

                default:

                    break;

                case  'salary_type':
                    if (value === 1) {
                        bigForm.employee.mlc_in_net = 0;
                        disabled.mlc_in_net = true;
                    } else {
                        disabled.mlc_in_net = false;
                    }


                    break;
            }

        }


        this.setState({formData: bigForm, errors: errors, disabled: disabled});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    onSave() {
        const self = this;
        self.setState({saving: true});

        let salary = Util.convertMoneyToDecimal(this.state.formData.employee.salary || '0,00')
        let phone = Util.deleteAllExceptNumbers(this.state.formData.employee.phone || "")

        let moneyFields = Util.mapFieldsToMoney([
            'ihbar_stamp_tax', 'kidem_stamp_tax', 'kidem_net', 'ihbar_net', 'ihbar_income_tax'
        ], this.state.formData.employee);

        let dateFields = [
            'termination_date', 'birthdate', 'employment_date'
        ];


        dateFields = Util.mapFieldsToDatabaseDateClean(dateFields, this.state.formData.employee);

        let employee = Object.assign({}, self.state.formData.employee, {
            newTagRows:this.state.newTagRows,
            phone: phone,
            salary: salary,
            ...dateFields,
            ...moneyFields
        });

        let errors = self.state.errors;

        let url = "/employee"
        let func = "post";

        if (employee.id) {
            func = "put";
            url = "/employee/" + employee.id
        }
        Api[func](url, employee, function (response, postErrors) {
            if (postErrors) {
                errors.employee = postErrors;
                self.setState({saving: false, errors: errors});
                self.decideTab();
            } else {
                if (employee.id) {
                    if (response && response.status && response.status === 'ok') {
                        toastr.success('Başarılı', 'Başarıyla Güncellendi');
                        self.setState({saving: false, errors: {}, activeTab: 'personal'});
                    } else {
                        toastr.error(response && response.message ? response.message : 'Bir hata oluştu');
                    }

                }
                else {
                    if (response && response.status && response.status === 'ok') {
                        self.setState({
                            saving: false,
                            errors: {},
                            formData: self.defaultFormData(),
                            activeTab: 'personal'
                        });
                        toastr.success('Başarılı', 'Başarıyla Kaydedildi');
                    } else {
                        toastr.error(response && response.message ? response.message : 'Bir hata oluştu');
                    }

                }
                self.props.onSave();
                self.decideTab();
            }

        });
    }

    onCancel() {
        this.resetNewTagModalList();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    getBtnText() {
        if (!this.state.formData.employee.id) return "Kaydet";
        else return "Güncelle";
    }

    handleTabSelect(key) {
        this.setState({activeTab: key})
    }

    render() {
        let payrollStatuses = this.props.payrollStatuses || []
        let yesOrNo = this.props.viewOptions && this.props.viewOptions.general && this.props.viewOptions.general.yesOrNo
        let termination_reason = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.termination_reason
        let is_spouse_working = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.is_spouse_working
        let handicap_level = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.handicap_level
        let salary_type = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.salary_type
        let gender = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.gender
        let marital_status = this.props.viewOptions && this.props.viewOptions.employees && this.props.viewOptions.employees.marital_status
        let btn = this.getBtnText();
        let title = this.state.formData.employee.id ? "Personel Güncelle" : "Personel Ekle"
        const activeTab = this.state.activeTab;
        const employeeProps = {
            onChange: this.setFieldChange.bind(this, "employee"),
            onMount: this.setFieldMount.bind(this, "employee"),
            formData: this.state.formData.employee,
            errors: this.state.errors.employee
        }
        return <div>
            <EkoModal
                showModal={this.state.showModal}
                title={title}
                dialogClassName="employee-modal"
                onHide={() => {
                    this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                    this.onCancel();
                }}
                spinner={this.state.saving}>
                <EkoForm formId={"add_employee_modal"}>
                    <Tab.Container id="detailTab" onSelect={this.handleTabSelect} activeKey={activeTab}>
                        <Row className="clearfix">
                            <Nav bsStyle="tabs" justified onSelect={this.handleTabSelect.bind(this)}>
                                <NavItem eventKey="personal">Kişisel Bilgiler</NavItem>
                                <NavItem eventKey="work">İş Bilgileri</NavItem>
                                <NavItem eventKey={"quit"}>İşten Çıkış</NavItem>
                            </Nav>
                            <Tab.Content animation>
                                <Tab.Pane eventKey="personal">
                                    <div className="m-grid">
                                        <div className="m-grid-row">
                                            <div className="m-grid-col m-grid-col-middle m-grid-col-center m-grid-col-md-6">
                                                <div className="row">
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Adı"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      id="firstname"/>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="İkinci Adı"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      id="middlename"/>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Soyadı"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      id="lastname"/>
                                                    <EkoFormSelect {...employeeProps}
                                                                   id="gender"
                                                                   colMd={8}
                                                                   labelMd={4}
                                                                   defaultText="Seçiniz"
                                                                   options={gender}
                                                                   label="Cinsiyet"/>
                                                    {
                                                        this.state.formData.employee.gender === 1 ?
                                                            <EkoFormInputText {...employeeProps}
                                                                              label="Kızlık Soyadı"
                                                                              colMd={8}
                                                                              labelMd={4}
                                                                              id="maiden"/>
                                                            : ""
                                                    }

                                                    <EkoFormInputText {...employeeProps}
                                                                      label="TC No"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      type="number"
                                                                      maxLength={11}
                                                                      id="identity_number"/>
                                                    <EkoFormDate{...employeeProps}
                                                                id="birthdate"
                                                                dateFormat="DD-MM-YYYY"
                                                                colMd={8}
                                                                labelMd={4}
                                                                clearable={false}
                                                                label="Doğum Tarihi"/>
                                                    <EkoFormSelect {...employeeProps}
                                                                   id="marital_status"
                                                                   colMd={8}
                                                                   labelMd={4}
                                                                   defaultText="Seçiniz"
                                                                   options={marital_status}
                                                                   label="Medeni Hali"/>

                                                    {/*  <EkoFormCheckbox
                                                    {...employeeProps}
                                                    label={"Bu çalışan için bordro takibi yapacağım"}
                                                    id={"payroll_follow"}/>*/}
                                                </div>
                                            </div>
                                            <div className="m-grid-col m-grid-col-md-6">
                                                <div className="row">
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Telefon"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      mask="(999) 999-99-99"
                                                                      type="mask"
                                                                      id="phone"/>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Email"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      id="email"/>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Adres"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      type="textarea"
                                                                      id="address"/>
                                                    <EkoFormSelect {...employeeProps}
                                                                   id="handicap_level"
                                                                   colMd={8}
                                                                   labelMd={4}
                                                                   options={handicap_level}
                                                                   label="Kendinin/Bakmakla Yükümlü Olduğu Kişinin Özürlülük Derecesi"/>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label="Agi'ye Dahil Çocuk Sayısı"
                                                                      colMd={8}
                                                                      labelMd={4}
                                                                      maxLength={3}
                                                                      type="number"
                                                                      id="number_of_children"/>
                                                    {
                                                        this.state.formData.employee.marital_status === 2 ?
                                                            <EkoFormSelect {...employeeProps}
                                                                           id="is_spouse_working"
                                                                           colMd={8}
                                                                           labelMd={4}
                                                                           defaultText="Seçiniz"
                                                                           options={is_spouse_working}
                                                                           label="Eşinin Çalışma Durumu"/>
                                                            : ""
                                                    }


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="work">
                                    <div className="m-grid">
                                        <div className="m-grid-row">
                                            <div className="m-grid-col m-grid-col-md-6">
                                                <EkoFormDate{...employeeProps}
                                                            id="employment_date"
                                                            dateFormat="DD-MM-YYYY"
                                                            colMd={8}
                                                            labelMd={4}
                                                            clearable={true}
                                                            label="İşe Giriş Tarihi"/>
                                                <EkoFormInputText {...employeeProps}
                                                                  label="Faaliyet Kodu"
                                                                  colMd={8}
                                                                  labelMd={4}
                                                                  id="speciality"/>
                                                <EkoFormSelect {...employeeProps}
                                                               id="is_min_salary"
                                                               colMd={8}
                                                               labelMd={4}
                                                               defaultText="Seçiniz"
                                                               options={yesOrNo}
                                                               disabled={this.state.disabled.is_min_salary || false}
                                                               label="Asgari Ücret Mi?"/>
                                                <EkoFormSelect {...employeeProps}
                                                               id="salary_type"
                                                               colMd={8}
                                                               labelMd={4}
                                                               defaultText="Seçiniz"
                                                               options={salary_type}
                                                               disabled={this.state.disabled.salary_type || false}

                                                               label="Maaş Tipi"/>
                                                <EkoFormSelect {...employeeProps}
                                                               id="mlc_in_net"
                                                               colMd={8}
                                                               labelMd={4}
                                                               defaultText="Seçiniz"
                                                               options={yesOrNo}
                                                               disabled={this.state.disabled.mlc_in_net || false}

                                                               label="AGİ Net Ücrete Dahil Mi?"/>


                                            </div>

                                            <div className="m-grid-col m-grid-col-md-6">
                                                <EkoFormInputText {...employeeProps}
                                                                  label="SGK No"
                                                                  colMd={8}
                                                                  labelMd={4}
                                                                  type="number"
                                                                  maxLength={13}
                                                                  id="sgk_number"/>
                                                <EkoFormSelect {...employeeProps}
                                                               id="payroll_status_id"
                                                               colMd={8}
                                                               labelMd={4}
                                                               optionValue={'description'}
                                                               defaultText="Seçiniz"
                                                               options={payrollStatuses}
                                                               label="Çalışma Tipi"/>
                                                <div className="col-md-12">
                                                    <label className="control-label col-md-6" htmlFor={"payment_day"}>
                                                        {TooltipService.getLabelWithTooltipCheckbox('payment_day', 'Maaş Ödeme')}
                                                    </label>
                                                    <div className="col-md-2 tright autoheight">Her
                                                        ayın
                                                    </div>
                                                    <EkoFormInputText {...employeeProps}
                                                                      label={''}
                                                                      colMd={2}
                                                                      type="number"
                                                                      maxLength={2}
                                                                      id="payment_day">
                                                        <div className="col-md-2">. günü</div>
                                                    </EkoFormInputText>
                                                </div>
                                                <EkoFormInputText {...employeeProps}
                                                                  label="Tutar"
                                                                  colMd={8}
                                                                  labelMd={4}
                                                                  type="money"
                                                                  disabled={this.state.disabled.salary || false}
                                                                  id="salary"/>


                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey={"quit"}>
                                    <div className="col-md-6">
                                        <EkoFormDate{...employeeProps}
                                                    id="termination_date"
                                                    dateFormat="DD-MM-YYYY"
                                                    colMd={8}
                                                    labelMd={4}
                                                    clearable={true}
                                                    label="İşten Çıkış Tarihi"/>

                                        {
                                            this.state.formData.employee.termination_date ?
                                                <EkoFormSelect {...employeeProps}
                                                               id="termination_reason"
                                                               colMd={8}
                                                               labelMd={4}
                                                               defaultText="Seçiniz"
                                                               options={termination_reason}
                                                               label="İşten Çıkış Nedeni"/>
                                                : ""
                                        }
                                        <EkoFormInputText {...employeeProps}
                                                          label="İhbar Damga Vergisi"
                                                          colMd={8}
                                                          labelMd={4}
                                                          type="money"
                                                          disabled={this.state.disabled.ihbar_stamp_tax || false}
                                                          id="ihbar_stamp_tax"/>
                                        <EkoFormInputText {...employeeProps}
                                                          label="Kıdem Damga Vergisi"
                                                          colMd={8}
                                                          labelMd={4}
                                                          type="money"
                                                          disabled={this.state.disabled.kidem_stamp_tax || false}
                                                          id="kidem_stamp_tax"/>

                                    </div>
                                    <div className="col-md-6">

                                        <EkoFormInputText {...employeeProps}
                                                          label="Net Kıdem Tazminatı"
                                                          colMd={8}
                                                          labelMd={4}
                                                          type="money"
                                                          disabled={this.state.disabled.kidem_net || false}
                                                          id="kidem_net"/>

                                        <EkoFormInputText {...employeeProps}
                                                          label="Net İhbar Tazminatı"
                                                          colMd={8}
                                                          labelMd={4}
                                                          type="money"
                                                          disabled={this.state.disabled.ihbar_net || false}
                                                          id="ihbar_net"/>

                                        <EkoFormInputText {...employeeProps}
                                                          label="Gelir Vergisi Kesintisi"
                                                          colMd={8}
                                                          labelMd={4}
                                                          type="money"
                                                          disabled={this.state.disabled.ihbar_income_tax || false}
                                                          id="ihbar_income_tax"/>
                                    </div>


                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Tab.Container>
                    <Modal.Footer>
                        <br/>
                        <div className="row buttonProccess2">
                            <EkoFormButton label={btn}
                                           faClass="fa fa-plus"
                                           isLoading={this.state.saving}
                                           onClick={this.onSave.bind(this)}
                            />
                            <EkoFormButton label="İptal" className="btn"
                                           onClick={this.onCancel.bind(this)}
                            />
                        </div>
                        <hr/>
                        <div style={{"textAlign": "left"}}>
                            <a onClick={this.openNewTagModal}>Etiket Ekle</a>
                        </div>
                    </Modal.Footer>
                </EkoForm>
            </EkoModal>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
        </div>
    }

}

function mapStateToProps(state) {
    return {
        payrollStatuses: state.data.payrollStatuses,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddEmployee);
