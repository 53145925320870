import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../util/Api";

export default function(ComposedComponent, key) {
  class HocPermissionPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        permitted: false,
        loading: true
      };
    }

    componentWillMount() {
      Api.checkPermission(key, (res, err) => {
        this.setState({ loading: false });
        if (res.permission) {
          this.setState({ permitted: true });
        } else {
          this.setState({ error: err.message });
        }
      });
    }

    render() {
      if (this.state.loading) return null;
      if (!this.state.permitted) {
        return (
          <div>
            <h2>{this.state.error}</h2>
          </div>
        );
      } else return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps)(HocPermissionPage);
}
