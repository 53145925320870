import React, {Component} from 'react';
import EkoDataTable from "../../components/elements/EkoDataTable";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {FETCH_WAREHOUSE_TRANSFERS} from "../../actions/types";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import { toast } from 'react-toastify';


class TransferWarehousesList extends Component {


    constructor(props){
        super(props);
        this.state = {
            transferList : [],
            loading: true
        }
    }

    fetchTransferList(){
        Api.get('/transfer-warehouses/list', (response,err) => {
            if(!err){
                this.setState({
                    transferList: response.items,
                    loading: false
                })
            }
        })
    }


    componentWillMount() {
        this.props.fetchData('/warehouses/showAll', FETCH_WAREHOUSE_TRANSFERS);
        this.fetchTransferList();
    }

    openInsert() {
        window.location.replace("/dashboard/transfer-warehouse/add");
    }

    openUpdate(row) {
        window.location.replace('/dashboard/transfer-warehouse/edit/' + row.id);
    }

    onDelete({id}){
        Api.delete('/transfer-warehouses/' + id, (response, errors) => {
            if (response) {
                toastr.success('Başarılı', 'Transfer işlemi silindi');
                this.fetchTransferList();
            }else{
                toastr.error('Hata', 'Transfer işlemi silinirken bir hata oluştu!');
            }
        })
    }

    render() {

        const insertMenu = this.props.viewOptions && this.props.viewOptions.transferWarehouses ? this.props.viewOptions.transferWarehouses.addRecordMenu : false;


        return (
            <div>
                <EkoDataTable
                    insertMenu={insertMenu}
                    name={"transfer-warehouses"}
                    onSelectedRowChange={this.onSelectedRowChange}
                    openInsert={this.openInsert.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.state.transferList}
                    loading={this.state.loading}
                    showDelete={true}
                    title={"Depolar Arası Transfer"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        data: state.data.warehouses ? state.data.warehouses.warehouses : undefined,
        viewOptions: state.data.viewOptions

    }
}

export default connect(mapStateToProps, actions)(TransferWarehousesList);

