import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoSpinner from "../elements/EkoSpinner";
import { FETCH_TEMPLATES } from "../../actions/types";
import { Link } from "react-router";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import CopyTemplateModal from "../elements/CopyTemplateModal";
import Util from "../../util/Util";

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showModal: false,
      copyId: null
    };
  }

  checkData() {
    this.props.fetchData("/template/showAll", FETCH_TEMPLATES);
  }

  componentWillMount() {
    this.checkData();
  }

  onDelete(id) {
    const self = this;
    toastr.confirm(`Bu şablonu silmek istediğinize emin misiniz?`, {
      onOk: () => {
        self.setState({ deleteLoading: true });
        Api.delete("/template/" + id, function(response, err) {
          if (response) {
            self.checkData();
            self.setState({ deleteLoading: false });
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else {
            self.setState({ deleteLoading: false });
            toastr.error("Hata", err.message);
          }
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  closeModal() {
    this.setState({ copyId: null, showModal: false });
  }

  render() {
    if (!this.props.templates) return <EkoSpinner />;
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              Yazdırma Şablonları
            </span>
              </div>
              <div className="pull-right">
                <Link to="/dashboard/template/new" className="btn btn-default blue">
                  <i className="fa fa-plus"/>
                  Yeni Şablon Ekle
                </Link>
              </div>
            </div>
            {this.props.templates.length === 0 ? (
                <div
                    style={{
                      width: "80%",
                      margin: "auto"
                    }}
                    className="alert alert-info"
                >
                  <strong>
                    <i className="fa fa-info-circle"/> Hiç şablon eklenmemiş
                  </strong>
                  .
                </div>
            ) : (
                this.props.templates.map((item, i) => {
                  return (
                      <div
                          key={i}
                          style={{
                            backgroundColor: "#4a8bc2",
                            color: "#fff",
                            width: "80%",
                            marginRight: "auto",
                            marginLeft: "auto"
                          }}
                          className="portlet light"
                      >
                        <div className="portlet-title2">
                          <div className="caption2">
                    <span className="caption-subject uppercase">
                      {item.name}
                    </span>
                          </div>
                        </div>
                        <div className="actions2">
                          <div
                              className="btn-group btn-group-devided"
                              data-toggle="buttons"
                          >
                            <button
                                onClick={() => {
                                  this.setState({showModal: true, copyId: item.id});
                                }}
                                style={{marginTop: "-30px", color: "#4a8bc2"}}
                                type="button"
                                id="print3"
                                className="btn btn-default"
                            >
                      <span>
                        <i className="fa fa-copy"/> Kopyala
                      </span>
                            </button>
                            <Link
                                to={"/dashboard/template/" + item.id}
                                style={{marginTop: "-30px", color: "#4a8bc2"}}
                                type="button"
                                id="print2"
                                className="btn btn-default"
                            >
                              <i className="fa fa-pencil"/> Düzenle
                            </Link>
                            <button
                                onClick={() => {
                                  this.onDelete(item.id);
                                }}
                                style={{marginTop: "-30px", color: "#4a8bc2"}}
                                type="button"
                                id="print"
                                className="btn btn-default"
                            >
                              {this.state.deleteLoading ? (
                                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw"/>
                              ) : (
                                  <span>
                          <i className="fa fa-trash"/> Sil
                        </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                  );
                })
            )}
            <CopyTemplateModal
                showModal={this.state.showModal}
                closeModal={this.closeModal.bind(this)}
                copyId={this.state.copyId}
                afterSave={this.checkData.bind(this)}
            />
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    templates: state.data.templates
  };
}

export default connect(mapStateToProps, actions)(Templates);
