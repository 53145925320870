import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormInputText,
    EkoFormDate,
    EkoFormSelect,
    EkoFormRadioButton
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import EkoModal from "../../components/elements/EkoModal";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import $ from "jquery";

class LoanPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            totalSelectedInstallment: 0,
            totalSelectedInstallmentAmount: 0,
            enteredAmountNotification: "",
            firstInstallmentAmount: 0,
            showPaymentReason: false,
        };
    }
    defaultFormData() {
        return {
            "loan_id": this.props.loanId,
            "date": "",
            "selectedInstallments": []
        }
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {};

        this.setState({ formData: formData, errors: errors });
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        let errors = nextProps.errors || {};
        this.setState({ formData: formData, errors: errors, showModal: nextProps.showModal });
    }


    onSave() {
        var self = this;
        self.setState({ saving: true });
        let formData = self.state.formData;

        Api.post("/loanPaymentPlan/payNew/"+self.state.formData.loan_id, formData, (res, err) => {
            if (res.status === "ok") {
                toastr.success("Başarılı", res.message);
                self.setState({ saving: false, showModal: false, formData: {}, errors: {}, totalSelectedInstallment: 0, totalSelectedInstallmentAmount: 0, enteredAmountNotification: "", firstInstallmentAmount: 0, showPaymentReason: false });
                self.props.onSave(formData);
            } else {
                toastr.error("Hata", err.message, { timeOut: 10000 });
                self.setState({ saving: false });
            }
        });

    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    setFieldChange(type, field, value) {
        let _self = this;
        let formData = _self.state.formData;
        let errors = _self.state.errors || {};
        formData[field] = value;
        /*
        if (field === "paid_amount") {
            _self.setState({ enteredAmountNotification: "", showPaymentReason: false });
            let firstInstallmentRecordId = $(".installments:first").val();
            let enteredAmount = Util.convertMoneyToDecimal(value);
            // select first installments and get installment_remaining_amount value and set
            let installmentData = _self.props.planData.find((item) => {
                return item.id === parseInt(firstInstallmentRecordId);
            });
            if (installmentData) {
                if (_self.state.firstInstallmentAmount === 0) {
                    _self.setState({firstInstallmentAmount: installmentData.installment_remaining_amount});
                }
            }else{
                toastr.error("Hata", "Taksit bulunamadı.");
            }

            if (_self.state.firstInstallmentAmount > 0 && enteredAmount === _self.state.firstInstallmentAmount) {
                _self.setState({enteredAmountNotification: "<span class='text-success' style='color: darkgreen;'>Sıradaki ilk taksit ödendi olarak işaretlenecektir.</span>" });
            }else if (_self.state.firstInstallmentAmount > 0 && enteredAmount !== 0 && enteredAmount < _self.state.firstInstallmentAmount) {
                _self.setState({enteredAmountNotification: "<span class='text-danger'>Girilen tutar ilk taksit tutarından <u>küçük</u>. Lütfen az ödeme sebebini seçiniz.</span>", showPaymentReason: true});
            }else if (_self.state.firstInstallmentAmount > 0 &&  enteredAmount > _self.state.firstInstallmentAmount) {
                _self.setState({enteredAmountNotification: "<span class='text-primary'>Girilen tutar ilk taksit tutarından <u>büyük</u>. Lütfen fazla ödeme sebebini seçiniz.</span>"});
            }
            // unchecked all installments
            let installments = document.querySelectorAll(".installments");
            installments.forEach((item) => {
                item.checked = false;
            });
            formData.selectedInstallments = [];
        }
        */
        let bigForm = _self.state.formData;
        _self.setState({ formData: bigForm, errors: errors});

    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else {
            formFields[type].push(field);
        }

        // payment_reason varsayılan hepsi 1 olsun dendiği için, ilk yüklemede 1 olarak hafızaya atalım
        let formData = this.state.formData;
        // payment_reason[1351] - explode brackets get name
        let fieldName = field.split("[")[0];
        if (fieldName === "payment_reason") {
            formData[field] = "1";
        }

        this.setState({ formFields: formFields });
    }

    calculateSelectedInstallments(self, installment_remaining_amount) {
        // calculate selected installments
        return function (e) {
            let formData = self.state.formData;
            let selectedInstallments = formData.selectedInstallments || [];
            let installmentId = e.target.value;
            let checked = e.target.checked;
            let installmentData = self.props.planData.find((item) => {
                return item.id === parseInt(installmentId);
            });
            if (checked) {
                selectedInstallments.push(installmentData);
            } else {
                selectedInstallments = selectedInstallments.filter((item) => {
                    return item.id !== parseInt(installmentId);
                });
            }
            formData.selectedInstallments = selectedInstallments;
            let total_selected_paid_amount = 0;
            selectedInstallments.forEach((item) => {
                total_selected_paid_amount += parseFloat(item.installment_remaining_amount);
            });
            total_selected_paid_amount = Util.convertDecimalToMoney(total_selected_paid_amount);
            formData.paid_amount = total_selected_paid_amount;
            self.setState({ formData: formData, totalSelectedInstallment: selectedInstallments.length, totalSelectedInstallmentAmount: total_selected_paid_amount, enteredAmountNotification: "", showPaymentReason: false });

        }
    }
    calculateSelectedInstallment(self, installment_remaining_amount) {
        // get remaining amount, update paid_amount field
        return function (e) {
            let formData = self.state.formData;
            formData.selectedInstallments = [];
            let selectedInstallments = formData.selectedInstallments || [];

            let installmentId = e.target.value;
            let checked = e.target.checked;
            let installmentData = self.props.planData.find((item) => {
                return item.id === parseInt(installmentId);
            });
            if (checked) {
                selectedInstallments.push(installmentData);
            }
        }
    }

    render() {

        const inputProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }

        return (
            <EkoModal
                showModal={this.state.showModal}
                title={"Taksit Ödemesi"}
                onHide={() => {
                    this.setState({ showModal: false, formData: {}, errors: {} });
                    this.onCancel();
                }}
                spinner={this.state.saving}
                bsSize={"lg"}
            >
                <EkoForm formId={"loan_payment"}>
                    <div>
                        {this.props.planData.length > 0 ? (
                            <div className="col-md-12">
                                <label className="col-md-12 control-label text-center" style={{ textAlign: "center", marginBottom: "10px"}}><h4>Ödeme Yapılan Taksitleri Seçiniz</h4><p>Ödeme yapacağınız taksiti işaretleyiniz. Taksitten kalan ödeme veya taksit tutarından fazla ödeme yapmanız durumunda lütfen sebep seçiniz.</p></label>

                                <table className={""} style={{ width: "100%"}}>
                                    {this.props.planData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {item.is_paid === 1 ? (
                                                <React.Fragment>

                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td width={"320"}>
                                                        <input type={"radio"} className={"installments"} name={"installment"} id={"t_" + index} value={item.id} onChange={this.calculateSelectedInstallment(this, item.installment_remaining_amount)} /> &nbsp;
                                                        <label htmlFor={"t_" + index}>Taksit {item.installment_number} - {item.installment_str} (Kalan: {item.installment_remaining_amount} ₺)</label>
                                                    </td>
                                                    <td width={"150"}>
                                                        <EkoFormInputText
                                                            {...inputProps}
                                                            id={"paid_amount["+item.id+"]"}
                                                            name={"paid_amount["+item.id+"]"}
                                                            disabled={false}
                                                            type="money"
                                                            colMd={"12"}
                                                            placeHolder={"Lütfen ödeme yapılan tutarı giriniz"}
                                                            value={Util.convertDecimalToMoney(item.installment_remaining_amount)}
                                                            currency={"₺"}
                                                        />
                                                    </td>
                                                    <td>
                                                        <EkoFormSelect
                                                            {...inputProps}
                                                            id={"payment_reason["+item.id+"]"}
                                                            name={"payment_reason["+item.id+"]"}
                                                            colMd={"12"}
                                                            options={[
                                                                { id: "1", name: "Zamanında Yapılan Ödeme" },
                                                                { id: "2", name: "Erken Ödeme İndirimi" },
                                                                { id: "3", name: "Az / Kalan Ödeme Yapıldı" },
                                                                { id: "4", name: "Gecikme Faizli Taksit Ödemesi" },
                                                            ]}
                                                            value={"1"}
                                                        />
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    )
                                })}
                                </table>
                            </div>
                        ) : null
                        }
                        <div className="col-md-12">
                            <EkoFormDate
                                {...inputProps}
                                id="date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                labelMd="4"
                                label="Taksit Ödeme Tarihi"
                            />
                        </div>
                    </div>
                    <Modal.Footer>
                        <div>
                            <div className="col-md-12">
                                <EkoFormButton
                                    label="Kaydet"
                                    faClass="fa fa-plus"
                                    isLoading={this.state.saving}
                                    onClick={this.onSave.bind(this)}
                                />
                                <EkoFormButton label="İptal" className="btn" onClick={this.onCancel.bind(this)} />
                            </div>
                        </div>
                    </Modal.Footer>
                </EkoForm>
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
    actions
)(LoanPayment);
