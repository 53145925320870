import React, {Component} from 'react';
import EkoDataTable from "../../components/elements/EkoDataTable";
import PaginatedDataTable from "../../components/elements/PaginatedDataTable";
import Style from "style-it";
import {CSS} from "../../css/tab_datatable";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Link} from "react-router";
import Util from "../../util/Util";
class PurchaseReceiptList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showInsertModal: false,
            selectedRow: {},
            data: [],
            selectedTab: 0,
            tableLoading: false
        }
    }

    componentWillMount() {
        this.fetchData()
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedTab: 15,
        });
    }
    fetchData = () => {
        this.setState({
            tableLoading: true
        })
        Api.get('/purchaseReceipt/list', (res, err) => {
            if (!err) {
                this.setState({
                    data: res.items,
                    tableLoading: false
                })
            }
        })
    }

    onSelectedRowChange = (row) => {
        console.log('ROW ', row)
        this.setState({
            selectedRow: row
        })
    }

    openInsert(menuItem) {
        const pages = [
            { id: 1, path: "productServiceInvoice" },
            { id: 2, path: "expenseInvoice" },
            { id: 3, path: "customerReturnInvoice" },
            { id: 4, path: "receipt" },
            { id: 5, path: "otherReceipt" },
            { id: 6, path: "insurancePolicy" },
            { id: 7, path: "purchaseReceipt"}
        ];
        const page = pages.filter(p => p.id === menuItem)[0];

        if(menuItem === 7){
            this.props.router.push('/dashboard/purchases/purchaseReceipt/add');
            return;
        }

        if (menuItem > 3)
            this.props.router.push("/dashboard/purchases/" + page.path + "/add");
        else
            this.props.router.push(
                "/dashboard/purchases/purchaseInvoice/add/" + page.path
            );
    }

    openUpdate = () => {
        this.props.router.push('/dashboard/purchases/purchaseReceipt/edit/' + this.state.selectedRow.id);
    }

    onDelete = () => {
        const {id} = this.state.selectedRow;
        Api.delete('/purchaseReceipt/' + id, (response, errors) => {
            if (response && response.status === 'ok') {
                toastr.success('Başarılı', response.message)
                this.fetchData()
            }
            if (errors) {
                toastr.error('HATA', response.message)
            }
        })
    }
    sideLink() {
        if (this.state.tabName === "purchaseInvoice" || this.state.selectedTab===15) {
            const all = this.props.purchaseInvoiceAll;
            if (all) {
                return (
                    <Link
                        to="/dashboard/purchases/eInBoxes"
                        className="btn btn-default blue"
                    >
                        <i className="fa fa-file" />
                        {(all.countTicari+all.countTemel+all.countInteraktifVDEarchive) +
                            " e-Fatura / e-Arşiv Fatura Bulunmaktadır. "}
                    </Link>
                );
            } else return null;
        } else return null;
    }
    render() {
        console.log(this.props);

        let insertMenu = this.props.viewOptions
            ? this.props.viewOptions.purchases.addRecordMenu
            : [];

        const tabMenu = [];
            tabMenu.push({
                id: 9,
                title: "FATURA",
                link: "/dashboard/purchases/purchaseInvoice"
            });
            tabMenu.push({
                id: 12,
                title: "FİŞ",
                link: "/dashboard/purchases/receipt"
            });
            tabMenu.push({
                id: 14,
                title: "POLİÇE",
                link: "/dashboard/purchases/insurancePolicy"
            });
            tabMenu.push({
                id: 13,
                title: "DİĞER GİDERLER",
                link: "/dashboard/purchases/otherReceipt"
            });

        // FIXME SULEYMAN : page permit değeri kaç olacak ?
        tabMenu.push({
            id: 15,
            title: "ALIŞ İRSALİYESİ",
            link: "/dashboard/purchases/purchaseReceipt"
        });

        return (
            <>
                {Style.it(
                    CSS,
                    <div>
                        <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                           onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri
                            Dön</a>
                        <PaginatedDataTable
                            tabMenu={tabMenu}
                            sideLink={this.sideLink()}
                            selectedTab={15}
                            loading={this.state.tableLoading}
                            name={"purchaseReceipt"}
                            selectRow={true}
                            onSelectedRowChange={this.onSelectedRowChange}
                            openInsert={this.openInsert.bind(this)}
                            openUpdate={() => this.openUpdate()}
                            onDelete={() => this.onDelete()}
                            fetchData={() => this.fetchData()}
                            data={this.state.data}
                            showDelete
                            insertMenu={insertMenu}
                            title={"Alış İrsaliyesi"}
                        />
                    </div>
                )}

            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
        dtConfig: state.data.dtConfig,
        purchaseInvoiceAll: state.data.purchaseInvoices,
    };
}

export default connect(mapStateToProps, actions)(PurchaseReceiptList);
