import React, { Component } from "react";

//NOTE IMPORTANT @bocek arkadaşlar form icin kullanacaksanız formdan tureyen form altındaki EkoFormInput kullanın
//type olarak money verin
export default class EkoMoneyText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    labelClass: "control-label control-line",
    disabled: false,
    thisClass: "form-control tright",
    placeholder: "",
    mask_money: false,
    precision: 2,
    currency: null
  };

  getPrecisionText() {
    return Array(this.props.precision)
      .fill("0")
      .join("");
  }

  handleBlur = e => {
    if (e.target.value === "") {
      e.target.value = "0," + this.getPrecisionText();
    } else if (!e.target.value.includes(",")) {
      e.target.value += "," + this.getPrecisionText();
    }
    this.props.onChange(e.target.value, this.props.pair);
  };

  handleKeyUp = e => {
    var clean = e.target.value
      .replace(/[^-?0-9,.]/g, "")
      .replace(/(,.*?),(.*,)?/, "$1");
    if (clean !== e.target.value) e.target.value = clean;
    e.target.value = this.format(e.target.value, this.props.precision);
    this.props.onChange(e.target.value, this.props.pair);
  };

  handleFocus = e => {
    if (e.target.value === "0,00") e.target.value = "";
    this.props.onChange(e.target.value, this.props.pair);
  };

  format(num, precision) {
    var str = num.toString().replace("$", ""),
      parts = false,
      output = [],
      i = 1,
      formatted = null;
    var isNegative = str.includes("-");
    if (str.indexOf(",") > 0) {
      parts = str.split(",");
      str = parts[0];
    }
    str = str
      .replace(/[#-]/g, "")
      .split("")
      .reverse();
    for (var j = 0, len = str.length; j < len; j++) {
      if (str[j] !== ".") {
        output.push(str[j]);
        if (i % 3 === 0 && j < len - 1) {
          output.push(".");
        }
        i++;
      }
    }
    formatted = output.reverse().join("");
    if (isNegative) formatted = "-" + formatted;

    return formatted + (parts ? "," + parts[1].substr(0, precision || 2) : "");
  }

  render() {
    var className = this.props.className;
    var thisClass = this.props.thisClass;
    var currency = this.props.currency;
    var val = this.props.value == null ? "0,00" : this.props.value;
    var curComp = <span style={{display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 15, margin: 4}}>{currency}</span>;
    
    var input = (
      <input
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        value={val}
        onFocus={this.handleFocus}
        onChange={this.handleKeyUp.bind(this)}
        className={thisClass}
        type="text"
        placeholder={this.props.placeholder}
        onBlur={this.handleBlur}
      />
    );
    var input2 = input;
    if (this.props.justInput) return input2;
    if (this.props.className2)
      input2 = <div className={this.props.className2}>{input}</div>;
    if (this.props.className2 && this.props.help)
      input2 = (
        <div className={this.props.className2}>
          {input}
          <span className="help-block"> {this.props.help} </span>
        </div>
      );
    if (this.props.hasError) className = this.props.className + " has-error";
    var label = this.props.label ? (
      <label className={this.props.labelClass}>{this.props.label}</label>
    ) : (
      ""
    );
    label = this.props.span ? (
      <label className={this.props.labelClass}>
        {this.props.label}
        <span className="required"> {this.props.span} </span>
      </label>
    ) : (
      label
    );
    return (
      <div style={{display: 'flex'}} className={className}>
        {label}
        {currency ? curComp : null}
        {input2}
      </div>
    );
  }
}
