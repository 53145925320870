import React, {Component} from 'react'
import Sugar from 'sugar'
import EkoModal from '../elements/EkoModal'
import Api from '../../util/Api'
import Util from '../../util/Util';
import EkoInputTable from '../elements/EkoInputTable'
import {CSS} from '../../css/inputtable_modal.js'
import LoginHelper from "../../services/LoginHelper";

export default class AddOtherTax extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            saving: false,
            errors: this.defaultErrors(),
            formFields: {},
            tevkifat_code:[],
            stoppage_code:[],
            ozelmatrah_code:[],
            tableData: {
                headers: [
                    {label: 'Vergi Tipi', width: '30%'},
                    {label: 'Vergi Oranı', width: '15%'},
                    {label: 'Vergi Tutarı', width: '15%'},
                    {label: 'Kodu', width: '15%'}
                ],
                footer: [],
                customFooter: null,
                rows: this.defaultRows()
            }
        }
        //  this.onCancel = this.onCancel.bind(this);
    }


    defaultRows() {
        return [
            [
                {
                    type: 'select',
                    id: 'tax_type_id',
                    optionValue: 'description',
                    selectedOptionValue: 'shortDesc',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    data: [],
                    searchable:true
                },
                {type: 'percent', id: 'tax_rate', vertical: true},
                {type: 'money', id: 'tax_amount_fc', vertical: true, disabled: true},
                {
                    type: 'select',
                    id: 'vat_reduction_type_code',
                    selectedOptionValue: 'code',
                    defaultText: '',
                    optionValue: 'name',
                    disabled: true,
                    optionId: 'code',
                    data: [],
                    searchable: true
                }

            ]
        ]
    }

    static defaultRowData() {
        return Object.assign({}, {
            tax_type_id: 0,
            tax_rate: Util.convertNumberToPercent(0),
            tax_amount_fc: Util.convertDecimalToMoney(0),
            vat_reduction_type_code: ''
        })
    }

    static getDefaultForm() {
        return {
            rows: [AddOtherTax.defaultRowData()]
        }
    }

    defaultErrors() {
        return {
            rows: [{
                tax_type_id: false,
                tax_rate: false,
                tax_amount_fc: false,
                vat_reduction_type_code: false

            }],
            footer: {}
        }
    }


    componentWillMount() {
        let formData = this.props.formData
        this.setState({formData: formData});
    }


    componentWillReceiveProps(nextProps) {
        const self = this
        this.getReductionList();
        if (nextProps.formData) {
            let tableData = this.state.tableData
            if (nextProps.formData.rows && tableData.rows) {
                if (nextProps.formData.rows.length < tableData.rows.length) {
                    tableData.rows = this.defaultRows()
                }
                while (tableData.rows.length < nextProps.formData.rows.length) {
                    const row = this.defaultRows()[0]
                    tableData.rows.push(row)
                }

                let formData = nextProps.formData
                formData.rows.forEach(function (row, index) {
                    if (row.tax_type_id && (!row.subject_to_vat || !row.reduction_type_id)) {
                        formData.rows[index].tax_type_code = self.getTaxTypeCode(row.tax_type_id)
                        formData.rows[index].subject_to_vat = self.getTaxSubjectToVat(row.tax_type_id)
                        formData.rows[index].reduction_type_id = self.getTaxReductionType(row.tax_type_id)
                    }
                })

                this.setState({formData: nextProps.formData, errors: this.defaultErrors()}, function () {
                    formData.rows.forEach(function (row, index) {
                        self.onChangeRow(index, row, "tax_rate")

                    })
                    self.filterTaxTypes()
                    if (nextProps.vatReductionTypeCodes && nextProps.taxTypes) {
                        const vatReductionTypeCodes = nextProps.vatReductionTypeCodes.map(function (typeCode) {
                            typeCode.name = typeCode.code
                            if (typeCode.description)
                                typeCode.name += " : " + typeCode.description
                            return typeCode
                        });
                        tableData.rows.forEach(function (row, index) {
                            tableData.rows[index][0]['data'] = nextProps.taxTypes
                            tableData.rows[index][3]['data'] = vatReductionTypeCodes
                            if (self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === '9015') {
                                tableData.rows[index][3]['disabled'] = false
                                tableData.rows[index][1]['disabled'] = true
                            }else if (self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === '0003') {
                                tableData.rows[index][3]['disabled'] = false;
                                tableData.rows[index][1]['disabled'] = false;
                            }else if (self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === '0011') {
                                tableData.rows[index][3]['disabled'] = false;
                                tableData.rows[index][1]['disabled'] = false;
                            }else if (self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === 'OZELMATRAH') {
                                tableData.rows[index][3]['disabled'] = false;
                                tableData.rows[index][2]['disabled'] = false;
                                tableData.rows[index][1]['disabled'] = false;
                            }
                            else if (self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === '0000' || self.getTaxTypeCode(self.state.formData.rows[index]["tax_type_id"]) === '9999') {
                                //@oguz yuvarlama hesabı
                                tableData.rows[index][3]['disabled'] = true;
                                tableData.rows[index][2]['disabled'] = false;
                                tableData.rows[index][1]['disabled'] = true;
                            }
                        })
                    }

                })
            }

        }

    }

    filterTaxTypes() {
        let tableData = this.state.tableData
        let currentTaxIds = this.state.formData.rows.map(function (row) {
            return row.tax_type_id
        })

        // let taxTypes = this.props.taxTypes.filter(function (taxType) {
        //     return currentTaxIds.indexOf(taxType.id) < 0
        // })

        const formData = this.state.formData
        const self = this
        tableData.rows.forEach(function (row, index) {
            let taxTypes = self.props.taxTypes.filter(function (taxType) {
                taxType.shortDesc = taxType.description.substr(0, 30)
                return currentTaxIds.indexOf(taxType.id) < 0 || formData.rows[index].tax_type_id === taxType.id
            })
            tableData.rows[index][0]['data'] = taxTypes
        })

        this.setState({tableData: tableData})
    }

    onRowAdd(index) {
        this.filterTaxTypes()
    }

    onRowDelete(index, row) {
        this.filterTaxTypes()
        let formData = this.state.formData
        let deleteRowIds = this.state.formData.deleteRowIds;
        if (!deleteRowIds)
            deleteRowIds = []
        if (row.id)
            deleteRowIds.push(row.id)
        formData.deleteRowIds = deleteRowIds
        this.setState({formData: formData})
    }

    getTaxTypeCode(taxId) {
        if (taxId && this.props.taxTypes) {
            const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
                return type.id === taxId
            })
            return taxType.code
        }
        return ""
    }

    getTaxSubjectToVat(taxId) {
        if (taxId && this.props.taxTypes) {
            const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
                return type.id === taxId
            })
            return taxType.subject_to_vat
        }
        return 0
    }

    getTaxReductionType(taxId) {
        if (taxId && this.props.taxTypes) {
            const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
                return type.id === taxId
            })
            return taxType.reduction_type
        }
        return 0
    }

    getReductionRate(reductionCode) {
        if (this.props.vatReductionTypeCodes) {
            const reduction = Sugar.Array.find(this.props.vatReductionTypeCodes, function (type) {
                return type.code === reductionCode
            })
            return Util.convertNumberToPercent(reduction.rate)
        }
        return Util.convertNumberToPercent(0)
    }


    calculateTaxAmount(formData, row) {
        var final = 0;
        if (row.tax_amount_manual && row.tax_amount_fc) final = row.tax_amount_fc
        if (row.tax_type_code === '9015')
            final =  Util.convertDecimalToMoney(Util.convertPercentToNumber(row.tax_rate) * formData.vat_amount_fc)
        else final = Util.convertDecimalToMoney(Util.convertPercentToNumber(row.tax_rate) * formData.net_amount_fc)
        return final==='NaN'?0:final;
    }

    getReductionList() {
            var get = localStorage.getItem("getAllVatReductionTypeCode");
            if(get!==null){
                get = JSON.parse(get);
                this.setState({tevkifat_code:get.tevkifat_code,stoppage_code:get.stoppage_code,ozelmatrah_code:get.ozelmatrah_code});
            }
    }

    onChangeRow(index, rowData, field) {
        const self = this
        let tableData = this.state.tableData
        let formData = this.state.formData
        let formErrors = this.state.errors
        if (formErrors.rows && formErrors.rows[index])
            formErrors.rows[index][field] = false

        if (field === "tax_amount_fc")
            formData.rows[index].tax_amount_manual = true

        if (field === "tax_rate") {
            if (formData.rows[index].tax_type_code === 'OZELMATRAH') { formData.rows[index].tax_amount_manual = true; }
            formData.rows[index].tax_amount_fc = this.calculateTaxAmount(formData, rowData)
        }

        if (field === 'tax_type_id') {
            formData.rows[index].tax_type_code = this.getTaxTypeCode(rowData[field])
            formData.rows[index].subject_to_vat = this.getTaxSubjectToVat(rowData[field])
            formData.rows[index].reduction_type_id = this.getTaxReductionType(rowData[field])
            if (formData.rows[index].tax_type_code === 'OZELMATRAH') { formData.rows[index].tax_amount_manual = true; }
            formData.rows[index].tax_amount_fc = this.calculateTaxAmount(formData, rowData[field])

            if (formData.rows[index].tax_type_code === '9015') {
                tableData.rows[index][3]['disabled'] = false
                tableData.rows[index][1]['disabled'] = true;
                tableData.rows[index][3]['data'] = this.state.tevkifat_code;
            }else if (formData.rows[index].tax_type_code === '0003') {
                tableData.rows[index][3]['disabled'] = false;
                tableData.rows[index][1]['disabled'] = false;
                tableData.rows[index][3]['data'] = this.state.stoppage_code;
            }else if (formData.rows[index].tax_type_code === '0011') {
                tableData.rows[index][3]['disabled'] = false;
                tableData.rows[index][1]['disabled'] = false;
                tableData.rows[index][3]['data'] = this.state.stoppage_code;
            }else if (formData.rows[index].tax_type_code === 'OZELMATRAH') {
                tableData.rows[index][3]['disabled'] = false;
                tableData.rows[index][2]['disabled'] = false;
                tableData.rows[index][1]['disabled'] = false;
                tableData.rows[index][3]['data'] = this.state.ozelmatrah_code;
            }else if (formData.rows[index].tax_type_code === '0000' || formData.rows[index].tax_type_code === '9999') {
                //@oguz yuvarlama hesabı
                tableData.rows[index][3]['disabled'] = true;
                tableData.rows[index][2]['disabled'] = false;
                tableData.rows[index][1]['disabled'] = true;
                tableData.rows[index][3]['data'] = [];
            }
            else {
                if (tableData.rows[index][3]['disabled'] === false) {
                    //onceden 9015 simdi baska bisi
                    formData.rows[index].vat_reduction_type_code = ""
                    formData.rows[index].tax_rate = Util.convertNumberToPercent(0)
                    self.onChangeRow(index, formData.rows[index], "tax_rate")
                }
                //self.props.onChangeRow(index,formData.rows[index],"tax_type_id")
                self.onChangeRow(index, formData.rows[index], "tax_rate")
                tableData.rows[index][3]['disabled'] = true
                tableData.rows[index][1]['disabled'] = false
            }
            self.onChange(formData)
            self.setState({tableData: tableData, errors: formErrors}, function () {
                self.filterTaxTypes()
            })
        }
        else if (!this.props.inXml && field === 'vat_reduction_type_code' && rowData.vat_reduction_type_code) {
            let formData = this.state.formData
            formData.rows[index].tax_rate = this.getReductionRate(rowData.vat_reduction_type_code)
            this.onChange(formData)
            self.onChangeRow(index, formData.rows[index], "tax_rate")


            this.setState({tableData: tableData, errors: formErrors})
        }
        this.props.onChangeRow(index, rowData, field)
    }


    onChange(formData) {
        this.props.onChange(formData)
        this.setState({formData: formData})
    }

    onSave() {
        const self = this

        const url = "/salesInvoiceTaxLine/validation"
        let endpoints = []
        this.state.formData.rows.forEach(function (row, index) {
            let params = {...row}

            params.tax_rate = Util.convertPercentToNumber(params.tax_rate)
            params.tax_amount_fc = Util.convertMoneyToDecimal(params.tax_amount_fc)
            params.tax_amount = params.tax_amount_fc // asil duzeltme adsales invoiceda
            if (index > 0 || params.tax_rate > 0 || params.tax_amount_fc > 0 || params.tax_type_id) {
                endpoints.push({endpoint: url, params: params, method: "post"})
            }

        })

        if (endpoints.length === 0) return self.props.onSave(false)
        self.setState({saving: true})
        Api.serialPutPost(endpoints, function (responses, errors) {
            console.log(responses);
            self.setState({saving: false})
            if (errors.length === 0) {
                self.props.onSave(self.state.formData)
            }
            else {
                let formErrors = self.state.errors
                formErrors.rows = errors
                self.setState({errors: formErrors})
                console.log(errors)
            }
        })

    }

    onCancel() {
        this.props.onCancel()
    }

    render() {

        return this.props.isNotModal!==undefined?<EkoInputTable data={this.state.tableData} className="extramargin" formData={this.state.formData}
                                                                errors={this.state.errors}
                                                                onSave={this.onSave.bind(this)}
                                                                notMust={true}
                                                                saving={this.state.saving}
                                                                onRowAdd={this.onRowAdd.bind(this)}
                                                                defaultRow={AddOtherTax.defaultRowData()}
                                                                buttonSaveLabel="Devam"
                                                                formId={"add_other_tax"}
                                                                buttonAddClass="btn-mleft"
                                                                //onCancel={this.onCancel.bind(this)}
                                                                onChangeRow={this.onChangeRow.bind(this)}
                                                                onChange={this.onChange.bind(this)}
                                                                onRowDelete={this.onRowDelete.bind(this)}/>:<EkoModal
            extraCss={CSS}
            showModal={this.props.showModal}
            title="Diğer Vergiler"
            bsSize="large"
            onHide={() => {
                this.setState({showModal: false})
                this.onCancel();
            }}
            spinner={false}
        >
            <EkoInputTable data={this.state.tableData} className="extramargin" formData={this.state.formData}
                           errors={this.state.errors}
                           onSave={this.onSave.bind(this)}
                           notMust={true}
                           saving={this.state.saving}
                           onRowAdd={this.onRowAdd.bind(this)}
                           defaultRow={AddOtherTax.defaultRowData()}
                           buttonSaveLabel="Devam"
                           formId={"add_other_tax"}
                           buttonAddClass="btn-mleft"
                           onCancel={this.onCancel.bind(this)}
                           onChangeRow={this.onChangeRow.bind(this)}
                           onChange={this.onChange.bind(this)}
                           onRowDelete={this.onRowDelete.bind(this)}/>
        </EkoModal>
    }

}
