import React, { Component } from "react";
import PropTypes from "prop-types";

export default class EkoFormButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  render() {
    let labelText = this.props.label;
    let labelClass = "";

    if (this.props.faClass)
      labelClass = <i className={this.props.faClass}>&nbsp;</i>;

    return (
      <button
        disabled={this.props.disabled || this.props.isLoading}
        style={{ marginRight: "5px", ...this.props.style }}
        className={this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.isLoading ? (
          <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
        ) : (
          <span>
            {labelClass} {labelText}
          </span>
        )}
      </button>
    );
  }
}
EkoFormButton.propTypes = {
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  faClass: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
EkoFormButton.defaultProps = {
  faClass: "",
  spinner: false,
  className: "btn btn-default blue",
  isLoading: false,
  disabled: false,
  style: {}
};
