import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import LoanPayment from "./LoanPayment";
import Util from "../../util/Util";
import Api from "../../util/Api";

class LoanPaymentPlans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showPayModal: false,
            errors: {},
            planData: [], //table Item
            dataLoading: false
        };
    }

    fetchPlans = (
        sizePerPage = 10,
        page = 1,
        order = [],
        date = [],
        search = ""
    ) => {
        const self = this;
        this.setState({ dataLoading: true });
        Api.get("/loanPaymentPlan/list/" + this.props.params.id,
            (res, err) => {
                if (res.status === "ok") {
                    self.setState({
                        planData: res.loanPaymentPlans,
                        dataLoading: false
                    });
                } else {
                    toastr.error("Hata", err.message);
                    self.props.router.push("/dashboard/loans");
                }
            }
        );
    };

    componentWillMount() {
        if (this.props.params && this.props.params.id) {
            this.fetchPlans();
        }
    }
    onSave(formData, formFields) {
        this.fetchPlans();
        this.setState({ showPayModal: false });
    }


    render() {
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/loans")}><i
                    className="fa fa-arrow-left"></i> Geri Dön</a>

                <LoanPayment
                    showModal={this.state.showPayModal}
                    loanId={this.props.params.id}
                    planData={this.state.planData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showPayModal: false});
                    }}
                    errors={this.state.errors}
                />
                <div className="row">
                    <div className="col-md-12">

                        <div className="portlet light">
                            <div className="portlet-title">
                                <div className={"caption"}><span className={"caption-subject bold uppercase"}>Kredi Ödeme Planı</span>
                                </div>
                                <div className="actions">
                                    {this.state.planData && this.state.planData.length > 0 ? (
                                        <a href={"javascript:;"} onClick={() => this.setState({showPayModal: true})}
                                           className="btn blue">
                                            TAKSİT ÖDEMESİ YAP
                                        </a>
                                    ) : (
                                        <a href={"javascript:;"} className="btn blue disabled">
                                            TAKSİT ÖDEMESİ YAP
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="portlet-body table-overflow-cell">

                                {this.state.dataLoading ? (
                                        <div className="text-center">
                                            <i className="fa fa-spinner fa-spin fa-3x"/>
                                        </div>
                                    ) :
                                    <>

                                        {this.state.planData.length > 0 ? (
                                            <div className="table-responsive">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th>Taksit Numarası</th>
                                                        <th>Taksit Tarihi</th>
                                                        <th>Taksit Tutarı</th>
                                                        <th>KKDF Tutarı</th>
                                                        <th>BSMV Tutarı</th>
                                                        <th>Ödeme Durumu</th>
                                                        <th>Taksitten Kalan</th>
                                                        <th>Kredi Kalan Tutar</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.planData.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.installment_number}</td>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.installment_str}</td>
                                                                    <td>{item.kkdf_str}</td>
                                                                    <td>{item.bsmv_str}</td>
                                                                    <td>{item.is_paid_str}</td>
                                                                    <td>{item.installment_remaining_amount} ₺</td>
                                                                    <td>{item.remaining_amount}₺</td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div>
                                                <h4>Ödeme Planı Bulunamadı</h4>
                                            </div>
                                        )
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, actions)(LoanPaymentPlans);
