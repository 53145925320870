class SalaryService {


    setSalaryData(salary){
        this.salary = salary;
    }

    setTableData(tableData){
        this.tableData = tableData;
    }

    getTableData(){
        return this.tableData;
    }

    getSalaryData(){
        return this.salary;
    }

    getValue(name){
        return this.salary && this.salary[name] !== undefined ? this.salary[name] : false;
    }
}


const salaryService = new SalaryService();

export default salaryService;