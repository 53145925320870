import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddCollection from "./AddCollection";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";

class CollectionAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      fromWhoType: localStorage.getItem("new_collection_from_who_type")
    };
  }

  componentWillMount() {
    var self = this;
    if (this.props.params && this.props.params.id) {
      //NOTE @bocek come for edit
      Api.get("/collection/" + this.props.params.id, function(response, err) {
        //TODO errorlari da handle etmek lazim da neyse @bocek
        if (response.collection) {
          let formData = { collection: response.collection };
          Api.get("/bankTransaction/collectionIdToBankTransaction/" + formData.collection.id, function(response2, err) {
            try {
              formData.collection.customer_currency = formData.collection.currency;
              formData.collection.customer_rate = Util.convertDecimalToString(formData.collection.currency_rate, 4);
              if (response2.currency_rate_bank === null){
                formData.collection.currency_rate = "1,0000";
                //response2.currency_rate_bank = formData.collection.currency_rate;
              }else {
                formData.collection.currency_rate = response2.currency_rate_bank;
                formData.collection.currency = response2.currency;
              }
              formData.collection.date = Util.convertFromDatabaseDate(
                  formData.collection.date
              );
              formData.collection.amount_fc = Util.convertDecimalToMoney(
                  formData.collection.amount_fc
              );
              if (formData.collection.customer_currency === formData.collection.currency){
                formData.collection.amount = formData.collection.amount_fc;
              }
              else if (formData.collection.customer_currency !== formData.collection.currency && formData.collection.currency !== 'TRY'){
                formData.collection.amount = Util.convertDecimalToMoney(response2.amount);
              }
              else {
                formData.collection.amount = Util.convertDecimalToMoney(formData.collection.amount);
              }
              formData.collection.currency_rate = formData.collection.currency_rate
                  .toString()
                  .replace(/[#.]/g, ",");
              formData.collection.customer_rate = formData.collection.customer_rate
                  .toString()
                  .replace(/[#.]/g, ",");
            } catch (ex) {
              console.log(ex);
            }
            self.setState({
              formData: formData,
              fromWhoType: formData.collection.type
            });
          });
        } else {
          toastr.error("Hata", err.message);
          self.props.router.push("/dashboard/collections");
        }
      });
    }
  }

  getTitle() {
    let viewOptions = this.props.viewOptions
        ? this.props.viewOptions.collections.type
        : [];
    let selected = viewOptions.filter(
        item => item.id === parseInt(this.state.fromWhoType, 10)
    );
    return selected.length === 0 ? "" : " - " + selected[0].name;
  }

  redirectToBase() {
    this.props.router.push("/dashboard/collections");
  }

  render() {
    let title = this.getTitle();
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/collections")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              TAHSİLAT{title}
            </span>
              </div>
            </div>
            <div className="portlet-body">
              <AddCollection
                  formData={this.state.formData}
                  fromWhoType={this.state.fromWhoType}
                  redirectToBase={this.redirectToBase.bind(this)}
              />
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collections: state.data.collections, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(CollectionAddUpdate);
