import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import AddSupplier from "../Supplier/AddSupplier";
import AddCustomer from "../Customer/AddCustomer";
import AddEmployee from "../Employee/AddEmployee";
import AddBank from "../Bank/AddBank";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
import Api from "../../util/Api";
import moment from "moment";
import { withRouter } from "react-router";
import NewTagDocumentModal from "../Company/newTagDocument";

import {
  FETCH_BANK_ACCOUNTS_ALL,
  FETCH_CUSTOMERS_ALL,
  FETCH_EMPLOYEES_ALL,
  FETCH_SUPPLIERS_ALL
} from "../../actions/types";
import EkoFormButtonGroup from "../../components/elements/form/EkoFormButtonGroup";
import EkoModal from "../../components/elements/EkoModal";

class AddCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      saving: false,
      formFields: {},
      fromWhoMenu: [],
      methods: [],
      method: "",
      showSupplierModal: false,
      showCustomerModal: false,
      showEmployeeModal: false,
      showBankModal: false,
      savingSupplier: false,
      savingCustomer: false,
      savingEmployee: false,
      defaultText: "Kişi seçiniz",
      savingBank: false,
      selectedCustomerorSupplier:null,
      formDisplay:true,
      diffCurrency:false,
      openWarningModal: false,
      activeBuyOrSellTxt: 0,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:4,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
  }
  clickBuyorSell = (sellOrBuy) => {
    var collection = this.state.formData.collection;
    if(collection.currency && collection.customer_currency){
      if(collection.currency !== collection.customer_currency){
        var currency ="";
        if(collection.currency==='TRY'){
          currency = collection.customer_currency;
        }else{
          currency = collection.currency;
        }
        var date = collection.date;
        var url = "/exchangeRate/" + currency + "/" + date;
        Api.get(url, (response, error) => {
          if(response.response && response.response.length!=0){
            if(sellOrBuy=='buy'){
              this.setState({activeBuyOrSellTxt:1});
              if(collection.currency==='TRY'){
                this.setFieldChange("collection","customer_rate", Util.convertDecimalToString(response.response.buying_forex, 4))
              }else{
                this.setFieldChange("collection","currency_rate", Util.convertDecimalToString(response.response.buying_forex, 4))
              }
            }else if(sellOrBuy=='sell'){
              this.setState({activeBuyOrSellTxt:2});
              if(collection.currency==='TRY'){
                this.setFieldChange("collection","customer_rate", Util.convertDecimalToString(response.response.selling_forex, 4))
              }else{
                this.setFieldChange("collection","currency_rate", Util.convertDecimalToString(response.response.selling_forex, 4))
              }
            }else{
              this.setState({activeBuyOrSellTxt:0})
            }
          }
        })
      }
    }
  }
  closeWarningModal() {
    this.setState({ openWarningModal: false });
  }
  openWarningModal = () => {
    this.setState({ openWarningModal: true });
  };

  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/4/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  defaultFormData() {
    return {
      collection: {}
    };
  }

  componentWillMount() {
    let formData = this.state.formData.collection;
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
    this.props.fetchData("/employee/showAllActive", FETCH_EMPLOYEES_ALL);
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    formData.amount_fc = localStorage.getItem("selected_list_receivable_amount_fc");
    this.getNewTagModalList();
  }

  componentWillReceiveProps(nextProps) {
    this.decideFromWhoMenu(nextProps);
  }

  setCurrencyRate(getCurrency) {
    let type = this.props.fromWhoType;
    const self = this;
    if (
        this.state.formData.collection.currency &&
        this.state.formData.collection.date
    ) {
      let date = moment(this.state.formData.collection.date).format(
          "YYYY-MM-DD"
      );
      let currency = getCurrency
          ? getCurrency
          : this.state.formData.collection.currency;
      Api.getCurrencyRate(currency, date, (rate, err) => {
        if (rate) {
          let currency_rate = Util.convertDecimalToString(rate, 4);

          if (this.props.params.id === undefined) {
            self.setFieldChange("collection", "currency_rate", currency_rate);
          } else {
            self.setFieldChange("collection", "currency_rate", this.state.formData.collection.currency_rate);
          }
          if (this.state.formData.collection.customer_currency == this.state.formData.collection.currency) {
            if (type != 2) {
              this.setState({formDisplay: true});
            }
          } else {
            if (type != 2) {
              if (this.state.formData.collection.customer_currency!==undefined) {
                this.setState({formDisplay: false});
              }
            }
          }
        }
      });

    }
  }

  setCurrencyRate2(getCurrency) {
    let type = this.props.fromWhoType;
    const self = this;
    if (
        this.state.formData.collection.currency &&
        this.state.formData.collection.date
    ) {
      let date = moment(this.state.formData.collection.date).format(
          "YYYY-MM-DD"
      );
      let currency = getCurrency
          ? getCurrency
          : self.state.selectedCustomerorSupplier?.currency;
      Api.getCurrencyRate(currency, date, (rate, err) => {
        if (rate) {
          let currency_rate = Util.convertDecimalToString(rate, 4);
          self.setFieldChange("collection", "customer_rate", currency_rate);
          if (this.state.formData.collection.customer_currency == this.state.formData.collection.currency) {
            if (type != 2) {
              this.setState({formDisplay: true});
            }
          } else {
            if (type != 2) {
              this.setState({formDisplay: false});
            }
          }
        }
      });
    }
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada supplier.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    if (type === "collection" && field === "currency_rate" && value!=undefined) {
      let clean = value.replace(/[^0-9,]/g, "").replace(/(,.*?),(.*,)?/, "$1");
      if (clean !== value) value = clean;
    }
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;
    if(field==='amount'){
      formData.customer_rate = formData['customer_rate'];
      formData['amount_fc'] = 0;
      if (value) {
        if (this.state.formDisplay === true){
          formData.amount_fc = Util.convertMoneyToDecimal(formData.amount);
          formData.amount_fc = formData.amount_fc.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        else {
          formData.amount_fc = (Util.convertMoneyToDecimal(formData['currency_rate']) * Util.convertMoneyToDecimal(formData.amount)) / Util.convertMoneyToDecimal(formData.customer_rate);
          formData.amount_fc = formData.amount_fc.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      }
    }else if(field==='amount_fc'){
      formData.customer_rate = formData['customer_rate'];
      formData['amount'] = 0;
      if (value) {
        if (this.state.formDisplay === true){
          formData.amount = Util.convertMoneyToDecimal(formData.amount_fc);
          formData.amount = formData.amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        else {
          formData.amount = (Util.convertMoneyToDecimal(formData.customer_rate) * Util.convertMoneyToDecimal(formData.amount_fc)) / Util.convertMoneyToDecimal(formData['currency_rate']);
          formData.amount = formData.amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      }
    }else if(field==='currency_rate'){
      setTimeout(() => {
        let type = this.props.fromWhoType;
        if (type ==2){
          this.setState({formDisplay: true});
        }
        else if (type !== 2){
          if (this.state.formData.collection.customer_currency === undefined){
            this.setState({diffCurrency: false})
            this.setState({formDisplay: true});
          }
          else if ((this.state.formData.collection.customer_currency !== 'TRY' && this.state.formData.collection.currency !== 'TRY') && (this.state.formData.collection.customer_currency !== this.state.formData.collection.currency)){
            this.setState({diffCurrency: true})
            this.openWarningModal();
          }
          else if (this.state.formData.collection.customer_currency !== this.state.formData.collection.currency) {
            this.setState({diffCurrency: false})
            this.setState({formDisplay: false});
          }

          else {
            this.setState({diffCurrency: false})
            this.setState({formDisplay: true});
          }
        }

        formData.customer_rate = formData['customer_rate'];
        formData['amount_fc'] = 0;
        if (value) {
          if (this.state.formDisplay === true){
            formData.amount_fc = Util.convertMoneyToDecimal(formData.amount);
            formData.amount_fc = formData.amount_fc.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
          else {
            formData.amount_fc = (Util.convertMoneyToDecimal(formData.currency_rate) * Util.convertMoneyToDecimal(formData.amount)) / Util.convertMoneyToDecimal(formData.customer_rate);
            formData.amount_fc = formData.amount_fc.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
          if (!formData.customer_rate) { formData.amount_fc=0; }
        }

      }, 1000);
    }else if(field==='customer_rate'){
      if (this.state.formDisplay === true){
        formData.customer_rate = value;
        formData.amount = Util.convertMoneyToDecimal(formData['amount_fc']);
        formData.amount = formData.amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      else {
        formData.customer_rate = value;
        formData.amount = (Util.convertMoneyToDecimal(formData.customer_rate) * Util.convertMoneyToDecimal(formData['amount_fc'])) / Util.convertMoneyToDecimal(formData['currency_rate']);
        formData.amount = formData.amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      if (!formData.currency_rate) { formData.amount=0; }
    }
    if (field === "date") {
      this.setCurrencyRate();
      this.setCurrencyRate2();
    }
    if (field === "type_source_id"){
      setTimeout(() => {
        if (type !== 2){
          if (this.state.formData.collection.customer_currency === undefined){
            this.setState({diffCurrency: false})
          }
          else if ((this.state.formData.collection.customer_currency !== 'TRY' && this.state.formData.collection.currency !== 'TRY') && (this.state.formData.collection.customer_currency !== this.state.formData.collection.currency)){
            this.setState({diffCurrency: true})
            this.openWarningModal();
          }
          else if (this.state.formData.collection.customer_currency !== this.state.formData.collection.currency) {
            this.setState({diffCurrency: false})
          }
          else {
            this.setState({diffCurrency: false})
          }
        }
      }, 1000);
    }
    if (type === "collection" && field === "type_source_id") {
      for (var i in this.props.customers) {
        if (this.props.customers[i].id === value) {
          this.setState({selectedCustomerorSupplier:this.props.customers[i]});
          this.state.formData.collection.customer_currency=this.props.customers[i].currency;
          this.setCurrencyRate2(this.props.customers[i].currency);
          return true;
        }
      }
      for (var i in this.props.suppliers) {
        if (this.props.suppliers[i].id === value) {
          this.setState({selectedCustomerorSupplier:this.props.suppliers[i]});
          this.state.formData.collection.customer_currency=this.props.suppliers[i].currency;
          this.setCurrencyRate2(this.props.suppliers[i].currency);
          return true;
        }
      }
    }

    if (type === "collection" && field === "method_source_id") {
      for (var i in this.props.customers) {
        if (this.props.customers[i].id === value) {
          this.setState({selectedCustomerorSupplier:this.props.customers[i]});
          this.state.formData.collection.customer_currency=this.props.customers[i].currency;
          this.setCurrencyRate2(this.props.customers[i].currency);
          return true;
        }
      }
      for (var i in this.props.suppliers) {
        if (this.props.suppliers[i].id === value) {
          this.setState({selectedCustomerorSupplier:this.props.suppliers[i]});
          this.state.formData.collection.customer_currency=this.props.suppliers[i].currency;
          this.setCurrencyRate2(this.props.suppliers[i].currency);
          return true;
        }
      }
    }
    if (type === "collection" && field === "method") {
      if (parseInt(value, 10) === 4) {
        toastr.confirm(
            `Çeki çek sayfasında eklemelisiniz çek sayfasına yönlendirilmek istermisiniz?`,
            {
              onOk: () => {
                this.props.router.push("/dashboard/cheques");
              },
              onCancel: () => {},
              okText: "Evet",
              cancelText: "Hayır"
            }
        );
      }
    }
    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors }, () => {
      if (type === "collection" && field === "method_source_id") {
        this.getMethodBankAccount();
      }
    });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSupplierSave() {
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
    this.setState({ showSupplierModal: false, savingSupplier: true });
  }

  onCustomerSave() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.setState({ showCustomerModal: false, savingCustomer: true });
  }

  onEmployeeSave() {
    this.props.fetchData("/employee/showAllActive", FETCH_EMPLOYEES_ALL);
    this.setState({ showEmployeeModal: false, savingEmployee: true });
  }

  onBankSave() {
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    this.setState({ showBankModal: false, savingBank: true });
  }

  onSave() {
    if (this.state.diffCurrency == true){
      return;
    }

    const redirect = arguments[0];
    const self = this;
    self.setState({ saving: true });

    let formData = self.state.formData;
    let amount_fc = Util.convertMoneyToDecimal(formData.collection.amount_fc);
    let type = this.props.fromWhoType;
    let date = Util.convertToDatabaseDate(formData.collection.date);
    let currency_rate = Util.convertMoneyToDecimal(
        formData.collection.currency_rate || "0,00"
    );
    let amount = parseFloat(amount_fc) * parseFloat(currency_rate) || 0;
    let errors = self.state.errors;
    var last_amount = 0;

    if(formData.collection.currency === 'TRY' && formData.collection.customer_currency!=='TRY'){
      last_amount = formData.collection.amount.toString();
    }else if(formData.collection.currency !== 'TRY' && formData.collection.customer_currency==='TRY'){
      last_amount = formData.collection.amount_fc.toString();
    }else if(formData.collection.currency === formData.collection.customer_currency){
      last_amount = Util.convertMoneyToDecimal(formData.collection.amount) * Util.convertMoneyToDecimal(formData.collection.currency_rate);
      last_amount = last_amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }else{
      last_amount = Util.convertMoneyToDecimal(formData.collection.amount) * Util.convertMoneyToDecimal(formData.collection.currency_rate);
      last_amount = last_amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    var amount_fc_last = 0;
    amount_fc_last = Util.convertMoneyToDecimal(formData.collection.amount);
    var last_amount_fc = 0;
    if(formData.collection.currency === 'TRY' && formData.collection.customer_currency!=='TRY'){
      if(formData.collection.customer_currency){
        last_amount_fc = formData.collection.amount_fc.toString();
      }else{
        last_amount_fc = last_amount;
        formData.collection.customer_currency = 'TRY'
      }
    }else if(formData.collection.currency !== 'TRY' && formData.collection.customer_currency==='TRY'){
      last_amount_fc = formData.collection.amount_fc.toString();
    }else if(formData.collection.currency === formData.collection.customer_currency){
      last_amount_fc = formData.collection.amount_fc.toString();
    }else{
      last_amount_fc = formData.collection.amount_fc.toString();
    }

    if(type=="2" && formData.collection.currency !== 'TRY'){
      last_amount = Util.convertMoneyToDecimal(formData.collection.amount) * Util.convertMoneyToDecimal(formData.collection.currency_rate);
      last_amount = last_amount.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      last_amount_fc = last_amount;
      formData.collection.currency = 'TRY'
      formData.collection.customer_currency = 'TRY'
    }else if(type=="2"){
      last_amount_fc = last_amount;
      formData.collection.currency = 'TRY';
      formData.collection.customer_currency = 'TRY';
    }
    last_amount_fc = Util.convertMoneyToDecimal(last_amount_fc);
    last_amount = Util.convertMoneyToDecimal(last_amount);
    let collection = Object.assign({}, formData.collection, {
      date: date,
      type: type,
      amount_fc_last: amount_fc_last,
      amount: last_amount,
      amount_fc: last_amount_fc,
      currency: formData.collection.currency !== formData.collection.customer_currency? formData.collection.customer_currency:formData.collection.currency,
      currency_rate_bank:Util.convertMoneyToDecimal(formData.collection.currency_rate),
      currency_rate: formData.collection.currency === formData.collection.customer_currency? currency_rate:Util.convertMoneyToDecimal(formData.collection.customer_rate),
      method:
          this.state.method === ""
              ? formData.collection.method
              : this.state.method,
      newTagRows:this.state.newTagRows
    });
    if (localStorage.getItem("selected_list_sales_invoice_id")!=null) {
      collection.sales_invoice_id = localStorage.getItem("selected_list_sales_invoice_id") ;
    }

    let func = "post";
    let url = "/collection";

    if (collection.id) {
      func = "put"; //NOTE edit işlemi
      url = "/collection/" + collection.id;
    }

    let bank_id = this.state.formData.collection.method_source_id;

    Api.get("/bankAccount/" + bank_id, (response, err) => {
      let method = response.bankAccount.bank_account_type;
      collection.method = method;
      Api[func](url, collection, function(response, postErrors) {
        if (postErrors) {
          errors.collection = postErrors;
          self.setState({ saving: false, errors: errors });

          if (postErrors.message) {
            toastr.error(postErrors.message);
          }
        } else {
          if (collection.id) {
            toastr.success("Başarılı", "Başarıyla Güncellendi");

            if (redirect && redirect === true) {
              self.props.router.push("/dashboard/collections");
            } else {
              self.props.router.push("/dashboard/collections/add");
            }
          } else {
            toastr.success("Başarılı", "Başarıyla Kaydedildi");
            if (redirect && redirect === true) {
              self.props.redirectToBase();
            } else {
              self.setState({
                saving: false,
                errors: {},
                formData: self.defaultFormData()
              });
            }
          }
        }
      });
    });



  }

  onCancel() {
    this.setState({ formData: this.defaultFormData(), errors: {} });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.router.push("/dashboard/collections");
  }

  newTypeSource() {
    switch (parseInt(this.props.fromWhoType, 10)) {
      case 1:
      case 3:
        this.setState({ showCustomerModal: true });
        break;
      case 2:
        this.setState({ showEmployeeModal: true });
        break;
      case 4:
      case 5:
        this.setState({ showSupplierModal: true });
        break;
      default:
        window.alert("Bu tipin ekleme modal sayfası henuz yapılmamış");
    }
  }

  decideFromWhoMenu(nextProps) {
    let methods = this.props.viewOptions?.collections?.method;
    let formData = nextProps.formData || this.state.formData;
    //NOTE @bocek tum istedigim veriler gelmeden set işlemi yapıp boşuna render ettirmeyelim
    if (
        Util.isUndefined(nextProps.suppliers) ||
        Util.isUndefined(nextProps.employees) ||
        Util.isUndefined(nextProps.customers)
    )
      return;

    let menu = [];
    let fromWhoType = 0;
    if (nextProps.fromWhoType) {
      //NOTE view optionsdan string geliyor niyeyse
      fromWhoType = parseInt(nextProps.fromWhoType, 10);
    }
    switch (fromWhoType) {
      case 1:
      case 3:
        if (nextProps.customers) {
          menu = nextProps.customers;
        }
        break;
      case 2:
        methods = methods.filter(item => {
          return item.id !== 4;
        });
        if (nextProps.employees) {
          menu = nextProps.employees.filter(item => {
            return !item.termination_date;
          });
        }
        break;
      case 4:
      case 5:
        methods = methods.filter(item => {
          return item.id !== 4;
        });
        if (nextProps.suppliers) {
          menu = nextProps.suppliers;
        }
        break;
      case 6:
        methods = methods.filter(item => {
          return item.id !== 4;
        });
        formData.collection.method = 3;
        break;
      default:
    }

    let fromWhoMenu = [];
    menu.forEach(function(item) {
      // if (item.is_active && item.is_active === 1) {
      let lastname = item.lastname ? " - " + item.lastname : "";
      item.title = item.firstname + " " + lastname;
      if([1,3,4,5].includes(fromWhoType)){
        item.title = item.fullname;
      }
      fromWhoMenu.push(item);
      // }
    });

    if (
        this.state.fromWhoType === nextProps.fromWhoType &&
        this.state.fromWhoMenu
    ) {
      //menu zaten set edilmse ve degisen birsey yoksa birsey yapma
      if (!nextProps.formData) {
        //eger formData falan da gelmemişse editten
      }
    }
    if (
        (this.state.savingSupplier ||
            this.state.savingCustomer ||
            this.state.savingEmployee) &&
        fromWhoMenu.length > 0
    ) {
      formData = this.state.formData;
      formData.collection = Object.assign({}, formData.collection, {
        type_source_id: fromWhoMenu[0].id
      });
      this.setState({
        fromWhoMenu: fromWhoMenu,
        formData: formData,
        savingSupplier: false,
        methods: methods
      });
    } else
      this.setState({
        fromWhoMenu: fromWhoMenu,
        formData: formData,
        methods: methods
      });
  }

  getMethodBankAccount() {
    if (Util.isUndefined(this.props.bankAccounts)) {
      return;
    }
    let bank_id = this.state.formData.collection.method_source_id;

    Api.get("/bankAccount/" + bank_id, (response, err) => {
      let method = response.bankAccount.bank_account_type;
      if (method === 2) {
        method = 3;
      } else if (method === 3 || method === 4) {
        method = 2;
      }
      this.setState({ method: method });
    });
  }
  //
  // getMethodSources() {
  //     if (Util.isUndefined(this.props.bankAccounts)
  //     /*|| Util.isUndefined(this.props.cheques) */)
  //         return;
  //     let method_sources = [];
  //     let bankAccounts = this.props.bankAccounts;
  //     // let cheques      = this.props.cheques;
  //     // cheques      = cheques.map((item) => {item.name = item.description});
  //     switch (parseInt(this.state.formData.collection.method, 10)) {
  //         case 1:
  //             method_sources = bankAccounts.filter((data) => {
  //                 return parseInt(data.bank_account_type, 10) === 1
  //             })
  //             break;
  //         case 2:
  //             method_sources = bankAccounts.filter((data) => {
  //                 return parseInt(data.bank_account_type, 10) === 4
  //             })
  //             break;
  //         case 3:
  //             method_sources = bankAccounts.filter((data) => {
  //                 return parseInt(data.bank_account_type, 10) === 2
  //             })
  //             break;
  //         case 4:
  //             // if (this.state.collections.type_source_id !== ""){
  //             //     method_sources = cheques.filter((data) => {
  //             //         return parseInt(data.customer_id) === this.state.collections.type_source_id
  //             //     })
  //             //     this.setState({
  //             //         collections: Object.assign({}, this.state.collections, {
  //             //             amount_fc: this.state.selectedAmount,
  //             //         }),
  //             //     })
  //             // }
  //             break;
  //         default:
  //     }
  //     return method_sources;
  // }

  newBank() {
    if (this.state.formData.collection.method === 4)
      toastr.warning("Uyarı", "Çeki çek sayfasından eklemelisiniz");
    else this.setState({ showBankModal: true });
  }

  methodToAccountType(method) {
    switch (method) {
      case 2:
        return 4;
      case 3:
        return 2;
      default:
        return method;
    }
  }

  render() {
    let formData = this.state.formData.collection;
    if (formData.type_source_id === undefined) {
      formData.type_source_id = parseInt(
          localStorage.getItem("new_customer_id_take_collection"),
          10
      );
      formData.amount_fc = localStorage.getItem("new_collection_amount_fc");
      if (localStorage.getItem("new_customer_id_take_collection") != null){
        this.setCurrencyRate("collection", "type_source_id", localStorage.getItem("new_customer_id_take_collection"));
        for (var i in this.props.customers) {
          if (this.props.customers[i].id === parseInt(localStorage.getItem("new_customer_id_take_collection"))) {
            this.setState({selectedCustomerorSupplier:this.props.customers[i]});
            this.state.formData.collection.customer_currency=this.props.customers[i].currency;
            this.setCurrencyRate2(this.props.customers[i].currency);
            return true;
          }
        }
        for (var i in this.props.suppliers) {
          if (this.props.suppliers[i].id === parseInt(localStorage.getItem("new_customer_id_take_collection"))) {
            this.setState({selectedCustomerorSupplier:this.props.suppliers[i]});
            this.state.formData.collection.customer_currency=this.props.suppliers[i].currency;
            this.setCurrencyRate2(this.props.suppliers[i].currency);
            return true;
          }
        }
      }
    }


    let methodSourceList = this.props.bankAccounts;

    const sourceId = formData.method_source_id;
    const self = this;
    let selectedCurrency = "TRY";

    if (sourceId) {
      methodSourceList.forEach(function(source) {
        if (source.id === sourceId) {
          selectedCurrency = source.currency;
          if (formData.currency !== source.currency)
            self.setCurrencyRate(source.currency);
        }
      });
    }

    formData.currency = selectedCurrency;

    if (!formData.amount_fc) {
      formData.amount_fc = "";
    }

    const collectionProps = {
      onChange: this.setFieldChange.bind(this, "collection"),
      onMount: this.setFieldMount.bind(this, "collection"),
      formData: formData,
      errors: this.state.errors.collection
    };

    return (
        <EkoForm formId={"add_collection"}>
          <AddSupplier
              showModal={this.state.showSupplierModal}
              formData={false}
              onSave={this.onSupplierSave.bind(this)}
              onCancel={() => {
                this.setState({showSupplierModal: false});
              }}
              errors={{}}
          />
          <AddCustomer
              showModal={this.state.showCustomerModal}
              formData={false}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({showCustomerModal: false});
              }}
              errors={{}}
          />
          <AddEmployee
              showModal={this.state.showEmployeeModal}
              formData={false}
              onSave={this.onEmployeeSave.bind(this)}
              onCancel={() => {
                this.setState({showEmployeeModal: false});
              }}
              errors={{}}
          />
          <AddBank
              showModal={this.state.showBankModal}
              formData={false}
              onSave={this.onBankSave.bind(this)}
              bankAccountType={this.methodToAccountType(
                  this.state.formData.collection.method
              )}
              onCancel={() => {
                this.setState({showBankModal: false});
              }}
              errors={{}}
          />
          <div className="row grcolor">
            <div className="row">
              <div className="col-md-2">
                <EkoFormDate
                    {...collectionProps}
                    id="date"
                    dateFormat="DD-MM-YYYY"
                    isVertical={true}
                    clearable={true}
                    label="Tahsilat Tarihi"
                ></EkoFormDate>
              </div>
              <div className="col-md-2">
                <EkoFormInputText
                    {...collectionProps}
                    id="description"
                    isVertical={true}
                    label="Açıklama"
                ></EkoFormInputText>
              </div>
              <div className="col-md-2">
                <EkoFormSelect
                    {...collectionProps}
                    searchable={true}
                    disabled={parseInt(this.props.fromWhoType, 10) === 6}
                    id="type_source_id"
                    defaultText={this.state.defaultText}
                    isVertical={true}
                    options={this.state.fromWhoMenu}
                    optionValue="title"
                    width={"300px"}
                    onAddNewItemClick={this.newTypeSource.bind(this)}
                    label="Kimden"
                />
              </div>
              <div className="col-md-2">
                <EkoFormSelect
                    {...collectionProps}
                    searchable={true}
                    id="method_source_id"
                    onAddNewItemClick={this.newBank.bind(this)}
                    isVertical={true}
                    defaultText="Seçiniz"
                    options={methodSourceList}
                    width={"300px"}
                    label="İlişkili Hesap"
                />
              </div>
              <div className="col-md-1">
                <EkoFormInputText
                    {...collectionProps}
                    id="currency"
                    isVertical={true}
                    label="Döviz Tip"
                    disabled={true}
                ></EkoFormInputText>
              </div>
              <div className="col-md-1">
                <EkoFormInputText
                    {...collectionProps}
                    id="currency_rate"
                    isVertical={true}
                    label="Kur"
                    disabled={this.state.formData.collection.currency === "TRY"}
                ></EkoFormInputText>
                <div className="row" style={{
                  "marginBottom": "20px",
                  "display": this.state.formData.collection.currency !== 'TRY' ? "" : "none"
                }}>
                  <div className="col-md-2"></div>
                  <div style={{"color": this.state.activeBuyOrSellTxt == 1 ? "#4a8cc3" : "black", "cursor": "pointer"}}
                       className="col-md-4" onClick={this.clickBuyorSell.bind(this, 'buy')}>Alış
                  </div>
                  <div style={{"color": this.state.activeBuyOrSellTxt == 2 ? "#4a8cc3" : "black", "cursor": "pointer"}}
                       className="col-md-4 text-right" onClick={this.clickBuyorSell.bind(this, 'sell')}>Satış
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <EkoFormInputText
                    {...collectionProps}
                    id="amount"
                    type="money"
                    isVertical={true}
                    label="Tutarı"
                    placeHolder={"0"}
                    //value={0}
                ></EkoFormInputText>
              </div>
            </div>
          </div>
          <div className="row grcolor" style={{display: this.state.formDisplay ? "none" : "block"}}>
            <div className="col-md-2" style={{marginLeft: "55%"}}>
              <label style={{display: "flex", alignItems: "center", marginTop: "-16px"}}>
                <i className="fa fa-arrow-down"></i>
              </label>
            </div>
            <div className="col-md-4" style={{marginLeft: "33.3%"}}>
              <label style={{display: "flex", alignItems: "center", marginTop: "1px", marginLeft: "48%"}}>
                İlgili Carinin Kuruna Dönüştürülmesi İçin&nbsp;&nbsp;<i className="fa fa-arrow-right"
                                                                        style={{marginLeft: "13px"}}></i>
              </label>
            </div>
            <div className="col-md-1" style={{marginTop: "-55px"}}>
              <EkoFormInputText
                  {...collectionProps}
                  id="customer_currency"
                  label="Döviz Tip"
                  isVertical={true}
                  disabled={true}
              ></EkoFormInputText>
            </div>
            <div className="col-md-1" style={{marginTop: "-55px"}}>
              <EkoFormInputText
                  {...collectionProps}
                  id="customer_rate"
                  label="Kur"
                  isVertical={true}
                  disabled={false}
              ></EkoFormInputText>
              <div className="row" style={{"display": this.state.formData.collection.currency === 'TRY' ? "" : "none"}}>
                <div className="col-md-2"></div>
                <div style={{"color": this.state.activeBuyOrSellTxt == 1 ? "#4a8cc3" : "black", "cursor": "pointer"}}
                     className="col-md-4" onClick={this.clickBuyorSell.bind(this, 'buy')}>Alış
                </div>
                <div style={{"color": this.state.activeBuyOrSellTxt == 2 ? "#4a8cc3" : "black", "cursor": "pointer"}}
                     className="col-md-4 text-right" onClick={this.clickBuyorSell.bind(this, 'sell')}>Satış
                </div>
              </div>
            </div>
            <div className="col-md-2" style={{marginTop: "-55px"}}>
              <EkoFormInputText
                  {...collectionProps}
                  id="amount_fc"
                  type="money"
                  isVertical={true}
                  label="Tutarı"
                  placeHolder={"0"}
              ></EkoFormInputText>
            </div>
          </div>
          <div>&nbsp;</div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-actions">
                <div className="buttonProccess">
                  <EkoFormButtonGroup
                      label={this.props.buttonSaveLabel || "Kaydet"}
                      faClass="fa fa-plus"
                      className="btn blue mt-ladda-btn ladda-button"
                      isLoading={this.state.saving}
                      buttonsType={"default"}
                      onClick={this.onSave.bind(this, true)}
                      buttons={[
                        {
                          onClick: this.onSave.bind(this, false),
                          labelText: "Kaydet ve Yeni Ekle"
                        }
                      ]}
                  />{" "}
                  &nbsp;
                  <EkoFormButton
                      label="İptal"
                      className="btn dark btn-outline"
                      onClick={this.onCancel.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <a onClick={this.openNewTagModal}>Etiket Ekle</a>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
          <EkoModal
              showModal={this.state.openWarningModal}
              onHide={this.closeWarningModal.bind(this)}
              title={"DİKKAT"}
          >
            <div>
              <p style={{color: "black", "text-align": "center"}}>
                Türk Lirasından Döviz Hesaplarına - Döviz Hesaplarından Türk Lirası hesaplarınız dışında işlem yapmak
                için <span style={{color: "red"}}>İŞLEMLER</span> menüsü <span
                  style={{color: "red"}}>VİRMAN</span> ekranından <span
                  style={{color: "red"}}>HESAPLAR ARASI VİRMAN</span> işlemi yapmanız gerekmektedir.
              </p>
            </div>
          </EkoModal>
        </EkoForm>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.data.customersAll,
    suppliers: state.data.suppliersAll,
    employees: state.data.employeesAll,
    bankAccounts: state.data.bankAccountsAll,
    cheques: state.data.chequesAll,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(withRouter(AddCollection));
