import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect, EkoFormSwitch
} from "../../components/elements/EkoForm";
import AddSupplier from "../Supplier/AddSupplier";
import AddCustomer from "../Customer/AddCustomer";
import AddBank from "../Bank/AddBank";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
import Api from "../../util/Api";
import EkoSpinner from "../../components/elements/EkoSpinner";
import NewTagDocumentModal from "../Company/newTagDocument";

import {
  FETCH_BANK_ACCOUNTS_ALL,
  FETCH_CUSTOMERS_ALL,
  FETCH_SUPPLIERS_ALL
} from "../../actions/types";
import EkoFormButtonGroup from "../../components/elements/form/EkoFormButtonGroup";
import { withRouter } from "react-router";
import moment from "moment";

class AddTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      saving: false,
      loading: true,
      formFields: {},
      fromWhoMenu: [],
      methods: [],
      showSupplierModal: false,
      showCustomerModal: false,
      showBankModal: false,
      savingSupplier: false,
      savingCustomer: false,
      savingEmployee: false,
      savingBank: false,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:5,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      from_type:0,
      to_type:1,
      toggle1: false,
      toggle2: true,
      toggle1Data: [],
    };
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/5/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  defaultFormData() {
    return {
      transfer: {}
    };
  }

  componentWillMount() {
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    this.getNewTagModalList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formData)
      this.setState({ formData: nextProps.formData, loading: false });
    else if (nextProps.formData === false && this.state.loading)
      this.setState({ loading: false });
    if(nextProps.formData && nextProps.formData.transfer.id){
      this.toggle1(nextProps.formData.transfer.from_type !== 0);
      this.toggle2(nextProps.formData.transfer.to_type !== 0);
    }else{
      this.toggle1(false);
      this.toggle2(true);
    }
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada supplier.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    var self = this;
    let formData = this.state.formData[type];
    var to_amount_change = false;
    var from_amount_change = false;
    if (field === "to_amount") {
      to_amount_change = formData["to_amount"]!==value;
    }else if (field === "from_amount") {
      from_amount_change = formData["from_amount"]!==value;
    }
    formData[field] = value;
    if (field === "from_amount" && value!==undefined && from_amount_change) {
      if(this.state.formData.transfer.from_currency === 'TRY'){
        if (value.includes(',')) {
          formData["to_amount"]=Util.convertDecimalToMoney(Util.convertMoneyToDecimal(value)/Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }else{
          formData["to_amount"]=Util.convertDecimalToMoney(value/Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }
      }else{
        if (value.includes(',')) {
          formData["to_amount"]=Util.convertDecimalToMoney(Util.convertMoneyToDecimal(value)*Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }else{
          formData["to_amount"]=Util.convertDecimalToMoney(value*Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }
      }

    }else if (field === "to_amount" && value!==undefined && to_amount_change) {
      if(this.state.formData.transfer.from_currency === 'TRY'){
        if (value.includes(',')) {
          formData["from_amount"]=Util.convertDecimalToMoney(Util.convertMoneyToDecimal(value)/Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }else{
          formData["from_amount"]=Util.convertDecimalToMoney(value/Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }
      }else{
        if (value.includes(',')) {
          formData["from_amount"]=Util.convertDecimalToMoney(Util.convertMoneyToDecimal(value)*Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }else{
          formData["from_amount"]=Util.convertDecimalToMoney(value*Util.convertMoneyToDecimal(this.state.formData.transfer.currency_rate));
        }
      }

    }
    if (field === "currency_rate") {
      self.setFieldChange("transfer", "from_amount", this.state.formData.transfer.from_amount);
    }
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;
    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors }, () => {
      if (field === "from_id" || field === "to_id" || field === "date") {
        self.setCurrencyRate(this.state.formData.transfer.from_currency,this.state.formData.transfer.to_currency);
      }
    });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSupplierSave() {
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
    this.setState({ showSupplierModal: false, savingSupplier: true });
  }

  onCustomerSave() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.setState({ showCustomerModal: false, savingCustomer: true });
  }

  onBankSave() {
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    this.setState({ showBankModal: false, savingBank: true });
  }

  onSave() {
    const redirect = arguments[0];
    const self = this;
    self.setState({ saving: true });

    let formData = self.state.formData;
    let from_amount = Util.convertMoneyToDecimal(formData.transfer.from_amount);
    let to_amount = Util.convertMoneyToDecimal(formData.transfer.to_amount);
    let type = this.props.type;
    let date = Util.convertToDatabaseDate(formData.transfer.date);
    /*let currency_rate =
      formData.transfer.from_currency === "TRY"
        ? parseFloat(from_amount) / parseFloat(to_amount)
        : parseFloat(to_amount) / parseFloat(from_amount);*/
    let errors = self.state.errors;
    let transfer = Object.assign({}, formData.transfer, {
      date: date,
      type: type,
      to_amount: to_amount ? to_amount : null,
      from_amount: from_amount ? from_amount : null,
      currency_rate: formData.transfer.currency_rate ? Util.convertMoneyToDecimal(formData.transfer.currency_rate) : 1,
      newTagRows:this.state.newTagRows,
      from_type:this.state.toggle1?1:0,
      to_type:this.state.toggle2?1:0,
    });

    let func = "post";
    let url = "/internalTransfer";

    if (transfer.id) {
      func = "put";
      url = "/internalTransfer/" + transfer.id;
    }
    Api[func](url, transfer, function(response, postErrors) {
      if (postErrors) {
        errors.transfer = postErrors;
        self.setState({ saving: false, errors: errors });

        if (postErrors.message) {
          toastr.error(postErrors.message);
        }
      } else {
        if (transfer.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");

          if (redirect && redirect === true)
            self.props.router.push("/dashboard/transfers");
          else self.props.router.push("/dashboard/transfers/add");
        } else {
          toastr.success("Başarılı", "Başarıyla Kaydedildi");
          if (redirect && redirect === true) {
            self.props.redirectToBase();
          } else {
            self.setState({
              saving: false,
              errors: {},
              formData: self.defaultFormData()
            });
          }
        }
      }
    });
  }

  onCancel() {
    this.setState({ formData: this.defaultFormData(), errors: {} });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.router.push("/dashboard/transfers");
  }

  newTypeSource(isSupplier) {
    switch (parseInt(this.props.type, 10)) {
      case 1:
        this.setState({ showBankModal: true });
        break;
      case 2:
        if (isSupplier) this.setState({ showSupplierModal: true });
        else this.setState({ showCustomerModal: true });
        break;
      default:
        window.alert("Bu tipin ekleme modal sayfası henuz yapılmamış");
    }
  }

  getWho() {
    /*if (Util.isUndefined(this.props.bankAccounts))
      return { fromWho: [], fromTo: [] };*/
    let fromWho = [];
    let fromTo = [];
    let bankAccounts = this.props.bankAccounts || [];
    let customers = this.props.customers || [];
    let suppliers = this.props.suppliers || [];

    if(this.props.customers===undefined){
      Api.get("/customer/list", (res, err) => {
        if (!err) {
          customers = res.customers;
        }
      });
    }
    if(this.props.suppliers===undefined){
      Api.get("/supplier/list", (res, err) => {
        if (!err) {
          suppliers = res.suppliers;
        }
      });
    }

    switch (parseInt(this.props.type, 10)) {
      case 1:
        fromWho = bankAccounts.filter(data => {
          return parseInt(data.is_active, 10) === 1;
        });
        fromTo = bankAccounts.filter(data => {
          return parseInt(data.is_active, 10) === 1;
        });
        break;
      case 2:
        customers.forEach(function(item) {
          //let lastname = item.lastname ? " - " + item.lastname : "";
          //item.name = item.firstname + " " + lastname;
          item.name = item.fullname;
          fromWho.push(item);
        });
        suppliers.forEach(function(item) {
          //let lastname = item.lastname ? " - " + item.lastname : "";
          //item.name = item.firstname + " " + lastname;
          item.name = item.fullname;
          fromTo.push(item);
        });
        break;
      default:
    }
    return { fromWho: fromWho, fromTo: fromTo };
  }
  onFalse() {}
  toggle1(state) {
    if(this.state.formData && this.state.formData.transfer && this.state.formData.transfer.id){
      return false;
    }
    if(state===true){
      this.setState({toggle1: state, toggle1Data: this.getWho().fromTo || [] });
    }else if(state===false){
      this.setState({toggle1: state, toggle1Data: this.getWho().fromWho || [] });
    }
  }
  toggle2(state) {
    if(this.state.formData && this.state.formData.transfer && this.state.formData.transfer.id){
      return false;
    }
    if(state===true){
      this.setState({toggle2: state, toggle2Data: this.getWho().fromTo || [] });
    }else if(state===false){
      this.setState({toggle2: state, toggle2Data: this.getWho().fromWho || [] });
    }
  }
  setCurrencyRate(currency1,currency2) {
    const self = this;
    if (currency1 && currency2 && this.state.formData.transfer.date) {
      let date = moment(this.state.formData.transfer.date).format("YYYY-MM-DD");
      Api.getCurrencyRateMulti(currency1,currency2, date, (rate, err) => {
        if (rate) {
          let currency_rate = Util.convertDecimalToString(rate, 4);
          self.setFieldChange("transfer", "currency_rate", currency_rate);
        }
      });
    }
  }
  render() {
    var self = this;
    if (this.state.loading) return <EkoSpinner />;
    let formData = this.state.formData.transfer;
    const type = parseInt(this.props.type, 10);

    const sourceId = formData.from_id;
    const sourceId2 = formData.to_id;
    let selectedCurrency = null;
    let selectedCurrency2 = null;

    if (type === 1) {
      if (sourceId) {
        this.state.toggle2Data.forEach(function(source) {
          if (source.id === sourceId) {
            selectedCurrency = source.currency;
          }
        });
      }

      if (sourceId2) {
        this.state.toggle1Data.forEach(function(source) {
          if (source.id === sourceId2) {
            selectedCurrency2 = source.currency;
          }
        });
      }
    }else if (type === 2) {
      if (sourceId2) {
        this.state.toggle2Data.forEach(function(source) {
          if (source.id === sourceId2) {
            selectedCurrency2 = source.currency;
          }
        });
      }

      if (sourceId) {
        this.state.toggle1Data.forEach(function(source) {
          if (source.id === sourceId) {
            selectedCurrency = source.currency;
          }
        });
      }
    }


    formData.from_currency = selectedCurrency;
    formData.to_currency = selectedCurrency2;

    if (!formData.amount_fc) {
      formData.amount_fc = "";
    }

    const transferProps = {
      onChange: this.setFieldChange.bind(this, "transfer"),
      onMount: this.setFieldMount.bind(this, "transfer"),
      formData: formData,
      errors: this.state.errors.transfer
    };

    return (
        <EkoForm formId={"add_transfer"}>
          <AddSupplier
              showModal={this.state.showSupplierModal}
              formData={false}
              onSave={this.onSupplierSave.bind(this)}
              onCancel={() => {
                this.setState({showSupplierModal: false});
              }}
              errors={{}}
          />
          <AddCustomer
              showModal={this.state.showCustomerModal}
              formData={false}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({showCustomerModal: false});
              }}
              errors={{}}
          />
          <AddBank
              showModal={this.state.showBankModal}
              formData={false}
              onSave={this.onBankSave.bind(this)}
              onCancel={() => {
                this.setState({showBankModal: false});
              }}
              errors={{}}
          />
          <div className="row grcolor">
            <div className="col-md-3">
              <EkoFormDate
                  {...transferProps}
                  id="date"
                  dateFormat="DD-MM-YYYY"
                  isVertical={true}
                  clearable={true}
                  label="Tahsilat Tarihi"
              />
            </div>
            <div className="col-md-5">
              <EkoFormInputText
                  {...transferProps}
                  id="description"
                  isVertical={true}
                  label="Açıklama"
              />
            </div>
            <div className="col-md-4"/>
          </div>
          <div className="row grcolor">
            <div className="col-md-3">
              {
                type === 2 ? <EkoFormSwitch
                    onChange={this.toggle1.bind(this)}
                    value={this.state.toggle1}
                    textOff="Müşteri"
                    textOn="Tedarikçi"
                /> : ""
              }
              <EkoFormSelect
                  {...transferProps}
                  searchable={true}
                  id="from_id"
                  onAddNewItemClick={this.newTypeSource.bind(this)}
                  isVertical={true}
                  defaultText="Seçiniz"
                  options={this.state.toggle1Data}
                  width={"300px"}
                  label="Para Çıkan Hesap"
              />
            </div>
            <div className="col-md-1">
              {type === 2 ? <div style={{"height": "46px"}}></div> : ""}
              <EkoFormInputText
                  {...transferProps}
                  id="from_currency"
                  isVertical={true}
                  label="Döviz Tip"
                  disabled={true}
              />
            </div>
            <div className="col-md-1">
              {type === 2 ? <div style={{"height": "46px"}}></div> : ""}
              <EkoFormInputText
                  {...transferProps}
                  id="from_amount"
                  type="money"
                  isVertical={true}
                  label="Tutarı"
                  placeHolder={"0"}
              />
            </div>
            <div className="col-md-1">
              {type === 2 ? <div style={{"height": "46px"}}></div> : ""}
              <EkoFormInputText
                  {...transferProps}
                  id="currency_rate"
                  type="money"
                  isVertical={true}
                  label="Kur / Parite"
                  placeHolder={"0"}
                  precision={4}
              />
            </div>
            <div className="col-md-3">
              {
                type === 2 ? <EkoFormSwitch
                    onChange={this.toggle2.bind(this)}
                    value={this.state.toggle2}
                    textOff="Müşteri"
                    textOn="Tedarikçi"
                /> : ""
              }
              <EkoFormSelect
                  {...transferProps}
                  searchable={true}
                  id="to_id"
                  onAddNewItemClick={this.newTypeSource.bind(this, true)}
                  isVertical={true}
                  defaultText="Seçiniz"
                  options={this.state.toggle2Data}
                  width={"300px"}
                  label="Para Giren Hesap"
              />
            </div>
            <div className="col-md-1">
              {type === 2 ? <div style={{"height": "46px"}}></div> : ""}
              <EkoFormInputText
                  {...transferProps}
                  id="to_currency"
                  isVertical={true}
                  label="Döviz Tip"
                  disabled={true}
              ></EkoFormInputText>
            </div>
            <div className="col-md-2">
              {type === 2 ? <div style={{"height": "46px"}}></div> : ""}
              <EkoFormInputText
                  {...transferProps}
                  id="to_amount"
                  type="money"
                  isVertical={true}
                  label="Tutarı"
                  placeHolder={"0"}
              />
            </div>
          </div>
          <div>&nbsp;</div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-actions">
                <div className="buttonProccess">
                  <EkoFormButtonGroup
                      label={this.props.buttonSaveLabel || "Kaydet"}
                      faClass="fa fa-plus"
                      className="btn blue mt-ladda-btn ladda-button"
                      isLoading={this.state.saving}
                      buttonsType={"default"}
                      onClick={this.onSave.bind(this, true)}
                      buttons={[
                        {
                          onClick: this.onSave.bind(this, false),
                          labelText: "Kaydet ve Yeni Ekle"
                        }
                      ]}
                  />{" "}
                  &nbsp;
                  <EkoFormButton
                      label="İptal"
                      className="btn dark btn-outline"
                      onClick={this.onCancel.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <a onClick={this.openNewTagModal}>Etiket Ekle</a>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
        </EkoForm>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.data.customersAll,
    suppliers: state.data.suppliersAll,
    bankAccounts: state.data.bankAccountsAll,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(withRouter(AddTransfer));
