import React, {Component} from "react";
import EkoModal from "../../components/elements/EkoModal";
import {EkoFormButton, EkoFormInputText, EkoFormSelect} from "../../components/elements/EkoForm";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Tab, Tabs} from "react-bootstrap";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import Util from "../../util/Util";

class ModalOtherInformation extends Component {
    static defaultData() {
        return {
            item: {}
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            formData: {},
            formFields: {},
        };
    }

    closeModal = () => {
        this.props.closeModal();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({formData: this.props.formData});
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;
        let bigForm = {...this.state.formData, [type]: {...formData}};
        this.setState({formData: bigForm, errors: {...errors}});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    onSave() {
        const self = this
        self.setState({saving: false});
        self.props.onSave(self.state.formData);

    }

    render() {
        const inputProps = {
            onChange: this.setFieldChange.bind(this, "item"),
            onMount: this.setFieldMount.bind(this, "item"),
            formData: this.state.formData.item,
            errors: this.state.errors,
            buttons: this.state.radioButtons,
        };
        const taxOffices = this.props.taxOffices
            ? this.props.taxOffices.map(function(obj) {
                if (obj && obj.city) {
                    obj.full_name = obj.name + " - " + obj.city.name;
                }
                return obj;
            })
            : [];
        const tab1SelectedCity = this.state.formData.item?.tab1_city_id;
        const tab2SelectedCity = this.state.formData.item?.tab2_city_id;
        const tab3SelectedCity = this.state.formData.item?.tab3_city_id;
        const tab4SelectedCity = this.state.formData.item?.tab4_city_id;
        const tab5SelectedCity = this.state.formData.item?.tab5_city_id;

        const countiesTab1 = this.props.counties ? this.props.counties.filter(function(obj) { return obj.city_id === tab1SelectedCity; }) : [];
        const countiesTab2 = this.props.counties ? this.props.counties.filter(function(obj) { return obj.city_id === tab2SelectedCity; }) : [];
        const countiesTab3 = this.props.counties ? this.props.counties.filter(function(obj) { return obj.city_id === tab3SelectedCity; }) : [];
        const countiesTab4 = this.props.counties ? this.props.counties.filter(function(obj) { return obj.city_id === tab4SelectedCity; }) : [];
        const countiesTab5 = this.props.counties ? this.props.counties.filter(function(obj) { return obj.city_id === tab5SelectedCity; }) : [];
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showModal}
                title={"Sevk İrsaliyesi Bilgiler"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <div>
                    <Tabs defaultActiveKey={"tab1"} id="uncontrolled-tab-example">
                        <Tab eventKey="tab1" title="Teslimat Adresi">
                            <div className="row">
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Kısa Ad"
                                        id="tab1_shortname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adres"
                                        id="tab1_address"
                                        isVertical
                                        type="textarea"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab1_city_id" //formData.address.city_id
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={this.props.cities}
                                        label="İl"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab1_county_id"
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={countiesTab1}
                                        label="İlçe"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Posta Kodu"
                                        id="tab1_post_code"
                                        type="text"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab2" title="Taşıyıcı Firma">
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adı / Firma Ünvanı"
                                        id="tab2_firstname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Soyadı / Firma Ünvanı Devamı"
                                        id="tab2_lastname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Vergi No / TC Kimlik No"
                                        id="tab2_tax_identity_number"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Posta Kodu"
                                        id="tab2_post_code"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adres"
                                        id="tab2_address"
                                        isVertical
                                        type="textarea"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab2_tax_office_id"
                                        clearable={true}
                                        optionValue="full_name"
                                        options={taxOffices}
                                        optionId="id"
                                        label="Vergi Dairesi"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab2_city_id" //formData.address.city_id
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={this.props.cities}
                                        label="İl"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab2_county_id"
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={countiesTab2}
                                        label="İlçe"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Email"
                                        id="tab2_email"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Telefon"
                                        id="tab2_phone"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Fax"
                                        id="tab2_fax"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Web Adresi"
                                        id="tab2_web_addres"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab3" title="Satın Alan Taraf">
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adı / Firma Ünvanı"
                                        id="tab3_firstname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Soyadı / Firma Ünvanı Devamı"
                                        id="tab3_lastname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Vergi No / TC Kimlik No"
                                        id="tab3_tax_identity_number"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Posta Kodu"
                                        id="tab3_post_code"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adres"
                                        id="tab3_address"
                                        isVertical
                                        type="textarea"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab3_tax_office_id"
                                        clearable={true}
                                        optionValue="full_name"
                                        options={taxOffices}
                                        optionId="id"
                                        label="Vergi Dairesi"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab3_city_id" //formData.address.city_id
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={this.props.cities}
                                        label="İl"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab3_county_id"
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={countiesTab3}
                                        label="İlçe"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Email"
                                        id="tab3_email"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Telefon"
                                        id="tab3_phone"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Fax"
                                        id="tab3_fax"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Web Adresi"
                                        id="tab3_web_addres"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab4" title="Malları Sağlayan Taraf">
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adı / Firma Ünvanı"
                                        id="tab4_firstname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Soyadı / Firma Ünvanı Devamı"
                                        id="tab4_lastname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Vergi No / TC Kimlik No"
                                        id="tab4_tax_identity_number"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Posta Kodu"
                                        id="tab4_post_code"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adres"
                                        id="tab4_address"
                                        isVertical
                                        type="textarea"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab4_tax_office_id"
                                        clearable={true}
                                        optionValue="full_name"
                                        options={taxOffices}
                                        optionId="id"
                                        label="Vergi Dairesi"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab4_city_id" //formData.address.city_id
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={this.props.cities}
                                        label="İl"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab4_county_id"
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={countiesTab4}
                                        label="İlçe"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Email"
                                        id="tab4_email"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Telefon"
                                        id="tab4_phone"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Fax"
                                        id="tab4_fax"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Web Adresi"
                                        id="tab4_web_addres"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab5" title="Malın Alımını Başlatan Taraf">
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adı / Firma Ünvanı"
                                        id="tab5_firstname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Soyadı / Firma Ünvanı Devamı"
                                        id="tab5_lastname"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Vergi No / TC Kimlik No"
                                        id="tab5_tax_identity_number"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Posta Kodu"
                                        id="tab5_post_code"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Adres"
                                        id="tab5_address"
                                        isVertical
                                        type="textarea"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab5_tax_office_id"
                                        clearable={true}
                                        optionValue="full_name"
                                        options={taxOffices}
                                        optionId="id"
                                        label="Vergi Dairesi"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab5_city_id" //formData.address.city_id
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={this.props.cities}
                                        label="İl"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EkoFormSelect
                                        {...inputProps}
                                        searchable={true}
                                        id="tab5_county_id"
                                        clearable={true}
                                        optionValue="name"
                                        defaultText="Seçiniz"
                                        options={countiesTab5}
                                        label="İlçe"
                                        labelMd={12}
                                        colMd={12}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Email"
                                        id="tab5_email"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Telefon"
                                        id="tab5_phone"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Fax"
                                        id="tab5_fax"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText
                                        {...inputProps}
                                        label="Web Adresi"
                                        id="tab5_web_addres"
                                        isVertical
                                        type="text"
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                    <div style={{"marginTop":"15px"}}>
                        <EkoFormButton
                            label={"Kaydet"}
                            faClass="fa fa-plus"
                            onClick={this.onSave.bind(this)}
                            isLoading={this.state.saving}
                        />
                    </div>
                </div>
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties,
    };
}

export default connect(mapStateToProps, actions)(ModalOtherInformation);
