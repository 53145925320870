import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { CSS } from "../../css/tab_datatable";
import Style from "style-it";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { FETCH_SOLEPROFV2 } from "../../actions/types";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import UtilHelper from "../../util/UtilHelper";
import EkoButtonOutline from "../../components/forms/EkoButtonOutline";
import ESMMSendModal from "../../components/forms/ESmmSendModal";

/**
 *
 */
class Soleprofv2Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      soleprofv2s: [],
      showUpdate: false,
      showSendModal: false,
      selectedSendId: 0,
    };

    this.table = null;
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchData("/soleprofv2/list", FETCH_SOLEPROFV2);
  }

  openInsert(menuItem) {
    //localStorage.setItem("new_collection_from_who_type", menuItem);

    if (menuItem === 1) {
      this.props.router.push("/dashboard/salesInvoice/add/toCustomer");
    } else if (menuItem === 2) {
      this.props.router.push("/dashboard/salesInvoice/add/toSupplier");
    } else if (menuItem === 3) {
      this.props.router.push("/dashboard/salesInvoice/zreports/add");
    } else if (menuItem === 4) {
      this.props.router.push("/dashboard/salesInvoice/soleprofv2/add");
    }else if (menuItem === 5) {
      this.props.router.push('/dashboard/salesInvoice/otherSales/add')
    }else if (menuItem === 6) {
      this.props.router.push('/dashboard/salesInvoice/soleprofv2/add')
    }
  }

  openUpdate(row) {
    this.props.router.push("/dashboard/salesInvoice/soleprofv2/edit/" + row.id);
  }

  showMethod(selectedRow) {
    window.open(
        process.env.REACT_APP_API_URL +
        "/proforma-print/" +
        selectedRow.id +
        "?token=" +
        localStorage.getItem("token"),
        "_blank"
    );
  }

  get moneyFields() {
    return [
      "price_amount_fc",
      "discount_amount_fc",
      "net_amount_fc",
      "vat_amount_fc",
      "reduction_amount_fc",
      "total_amount_fc"
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.soleprofv2s) {
      const soleprofv2s = nextProps.soleprofv2s.map(report => {
        const moneyFields = UtilHelper.money.mapDecimalToMoney(
            this.moneyFields,
            report
        );
        const dateFields = {
          //date: Util.convertFromDatabaseDate(report.date),
          //due_date: Util.convertFromDatabaseDate(report.due_date)
        };

        return {
          ...report,
          ...moneyFields,
          ...dateFields
        };
      });

      this.setState({
        soleprofv2s
      });
    }
  }

  onDelete(row) {
    Api.delete("/soleprofv2/" + row.id, (response, error) => {
      if (!error && response.status === "ok") {
        toastr.success("Başarıyla Silindi");
        this.fetchData();
      } else {
        toastr.error(error.message ? error.message : "Silinemedi");
      }
    });
  }

  get items() {
    var user_json = JSON.parse(localStorage.getItem("user"));
    if(user_json.company_type == 9 || user_json.company_type == 6){
      return [
        {
          id: 5,
          link: "/dashboard/salesInvoice/soleprofv2s",
          title: "Serbest Meslek Makbuzu"
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        }
      ];
    }else{
      return [
        {
          id: 1,
          link: '/dashboard/salesInvoice',
          title: 'Satışlar'
        },

        {
          id: 2,
          link: '/dashboard/salesInvoice/zreports',
          title: 'Z Raporları'
        },
        {
          id: 3,
          link: '/dashboard/salesInvoice/proformas',
          title: 'Teklifler/Siparişler'
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        }
      ]
    }
  }


  updateStatus = (status, id, row) => {
    Api.post(
        "/soleprofv2/update-status/" + id,
        {
          status: status
        },
        (response, error) => {
          if (!error) {
            // verileri güncelle ve seçimi sıfırla
            this.table.cleanSelect();
            this.fetchData();
            toastr.success("İşlem başarılı!");
          } else {
            const message = error.message || "İşlem başarısız";

            toastr.error(message);
          }
        }
    );
  };

  onSelectedRowChange = selected => {
    const { status } = selected;

    this.setState({ showUpdate: parseInt(status, 10) === 1 });
  };

  createCustomButtons = ({ isSelected, selectedRow }) => {
    if (isSelected) {
      const { status } = selectedRow;

      return <span></span>;
    }
  };
  soleprofSendButton(state) {
    const self = this;
    let integrator = JSON.parse(localStorage.getItem("integrator"));

    if (integrator === 1) {
      //self.sendEInvoice(state); @oguz sovos e-smm gonderimi
      this.setState({showSendModal:true,selectedSendId:state.selectedRow.id});
    } else if (integrator === 3) {
      //self.nesESmmInvoice(state); //@oguz nesv2 e-smm gonderimi
      this.setState({showSendModal:true,selectedSendId:state.selectedRow.id});
    }
  }
  nesESmmInvoice(state) {
    const integrator = JSON.parse(localStorage.getItem("integrator"));
    const self = this;
    self.setState({ eInvoiceLoading: true });
    Api.post(
        "/template/checkPrint",
        { object_id: state.selectedRow.id, type: 0 },
        (response, err) => {
          if (response) {
            Api.post(
                `/${integrator === 3 ? 'nesv2' : 'nes' }/sendSmm`,
                {
                  soleprofv2_id: state.selectedRow.id,
                  receiver: null
                },
                (response, err) => {
                  if (response) {
                    let eInvoiceType = response.receiver[0]
                        ? response.receiver[0].status
                        : response.receiver.status;
                    self.setState({
                      showEInvoiceNes: true,
                      selectedId: state.selectedRow.id,
                      eInvoiceLoading: false,
                      errors: {},
                      showCustomer: false,
                      showSupplier: false,
                      showInsertModal: false,
                      showPrintModal: false,
                      eInvoiceResponse: response,
                      eInvoiceType: eInvoiceType
                    });
                  } else {
                    self.setState({ eInvoiceLoading: false });
                    err =
                        err === "Unauthorized"
                            ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                            : err;
                    toastr.error("Hata", err.message);
                  }
                }
            );
          } else {
            if (state.selectedRow.customer_id) {
              let customerErr = { customer: err };
              if (err.address) {
                customerErr = { customer: err, address: err };
              }
              self.setCustomer(state.selectedRow.customer_id, () => {
                self.setState({
                  errors: customerErr,
                  showCustomer: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            } else {
              let supplierErr = { supplier: err };
              if (err.address) {
                supplierErr = { supplier: err, address: err };
              }
              self.setSupplier(state.selectedRow.supplier_id, () => {
                self.setState({
                  errors: supplierErr,
                  showSupplier: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            }
          }
        }
    );
  }
  openSMMCancel(state) {
    const self = this;
    let integrator = JSON.parse(localStorage.getItem("integrator"));
    let id = state.selectedRow.id;
    let endpoint = "";

    if (integrator === 1) {
      toastr.error("Entegrator smm iptal için desteklenmiyor.");
      return false;
    } else if (integrator === 2) {
      toastr.error("Entegrator smm iptal için desteklenmiyor.");
      return false;
    } else if (integrator === 3) {
      endpoint = "/nesv2/cancel-smm";
    } else {
      toastr.error("Entegratorunuz bulunamdı.");
    }
    let message = (
        <span>
        Bu e-smm belgesini iptal ettikten sonra ilgili belgeye ait kayıdı
        silmeyi unutmayınız! <br />
        İptal etmek istediğinize emin misiniz?
      </span>
    );
    toastr.confirm(message, {
      onOk: () => {
        Api.post(endpoint, { object_id: id }, (response, err) => {
          if (response) {
            toastr.success("Başarılı", response.message);
          } else {
            toastr.error("Hata", err.message);
          }
        });
      },
      onCancel: () => { },
      okText: "Evet",
      cancelText: "Hayır"
    });
  }
  render() {
    const insertMenux = this.props.viewOptions
        ? this.props.viewOptions.sales.addRecordMenu
        : false;
    if(insertMenux!=false){
      var user_json = JSON.parse(localStorage.getItem("user"));
      if(user_json.company_type == 9 || user_json.company_type == 6){
        var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 2 && obj.id !== 3 && obj.id !== 4 && obj.id !== 7);
        insertMenu.unshift({id: 6, name: 'Serbest Meslek Makbuzu'});
      }else{
        insertMenu = insertMenux;
      }
    }

    return (
        <div>
          {Style.it(
              CSS,
              <div>
                <EkoDataTable
                    onRef={item => (this.table = item)}
                    title="Serbest Meslek Makbuzu"
                    tabMenu={this.items}
                    openSMMCancel={this.openSMMCancel.bind(this)}
                    selectedTab={5}
                    showDelete
                    onSelectedRowChange={this.onSelectedRowChange}
                    soleprofSendButton={this.soleprofSendButton.bind(this)}
                    addUpdateShow={true}
                    showMethod={this.showMethod}
                    createCustomRowButtons={this.createCustomButtons}
                    openInsert={this.openInsert.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.state.soleprofv2s}
                    insertMenu={insertMenu}
                    name={"soleprofv2s"}
                />
                <ESMMSendModal
                    activeTab={"address"}
                    showModal={this.state.showSendModal}
                    formData={this.state.selectedSendId}
                    onCancel={() => {
                      this.setState({
                        showSendModal: false
                      });
                    }}
                    errors={this.state.errors}
                />
              </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    soleprofv2s: state.data.soleprofv2s
        ? state.data.soleprofv2s.Soleprofv2
        : undefined,
    viewOptions: state.data.viewOptions
  };
};

export default connect(mapStateToProps, actions)(Soleprofv2Invoice);
