import React from 'react';


const EkoSpinner = props => {
    return (
        <div className="loader">
            <svg height="40px" style={{"enableBackground": "new 0 0 50 50"}} width="40px" viewBox="0 0 50 50"
                 xmlSpace="preserve">
                <path style={{"fill": "#4a8bc2"}}
                      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                      fill="#000" transform="rotate(214.556 25 25)">
                    <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25"
                                      to="360 25 25"
                                      dur="0.6s" repeatCount="indefinite"></animateTransform>
                </path>
            </svg>
        </div>
    );
};

export default EkoSpinner;


