class PermissionHelper {
    constructor(){
        this.is_self_employment = null;
    }

    /**
     *
     * @param {array} types
     * @returns {boolean}
     */
    isPermitted(types) {

        const localUser = localStorage.getItem('user');
        let user = localUser ? JSON.parse(localUser) : false;


        return types.indexOf(parseInt(user.company_type, 10)) > -1;
    }

    isSelfEmployment(){

        if (this.is_self_employment === null) {

            this.is_self_employment = this.isPermitted([1,2]);
        }

        return this.is_self_employment;
    }

}

const CompanyTypePermission = new PermissionHelper();

export default CompanyTypePermission;