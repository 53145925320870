import React from "react";
import { EkoFormAutoComplete, EkoFormButton } from "./EkoForm";
import EkoFormTooltip from "./EkoFormTooltip";
import { toastr } from "react-redux-toastr";
import Sugar from "sugar";
import EkoFormButtonGroup from "./form/EkoFormButtonGroup";
import TooltipService from "../../services/TooltipService";
import EkoInputTableLine from "./EkoInputTableLine";
import { EkoFormDate, EkoFormInputText, EkoFormSelect } from "./EkoForm";
import PropTypes from "prop-types";
import Tags from "./Tags";
import Util from "../../util/Util";
import { connect } from "react-redux";
import * as actions from "../../actions";
class EkoInputTableButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      rows: [],
      spinner: false,
      defaultRows: [],
      errors: { rows: {}, footer: {} },
    };
  }

  componentWillMount() {
    if (this.props.data.rows) {
      this.setState({
        rows: this.props.data.rows,
        defaultRows: this.props.data.rows,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.rows) {
      this.setState({
        rows: nextProps.data.rows,
        defaultRows: nextProps.data.rows,
      });
    }
  }

  deleteRow = (index) => {
    let len = this.props.allDelete ? 0 : 1;
    if (this.state.rows.length > len) {
      this.props.data.rows = Sugar.Array.removeAt(this.props.data.rows, index);
      let formData = this.props.formData;
      const deletedRow = formData.rows[index];
      formData.rows = Sugar.Array.removeAt(formData.rows, index);
      if (this.props.onChange) {
        this.props.onChange(formData);
      }

      if (this.props.onRowDelete) {
        this.props.onRowDelete(index, deletedRow);
      }

      this.setState({ rows: this.props.data.rows });
    } else if (!this.props.notMust) {
      toastr.warning("Dikkat!", "En az 1 kayıt girilmelidir");
    }
  };

  addRow() {
    let row = [];
    if (this.props.row) {
      if (typeof this.props.row === "function") row = this.props.row();
      else row = this.props.row;
    } else
      this.props.data.rows[0].forEach(function (field, index) {
        row.push({ ...field });
      });
    this.props.data.rows.push(row);
    let formData = this.props.formData;
    if (this.props.defaultRow) {
      if (typeof this.props.defaultRow === "function") {
        formData.rows.push({ ...this.props.defaultRow() });
      } else {
        formData.rows.push({ ...this.props.defaultRow });
      }
    } else formData.rows.push({});
    this.setState({ rows: this.props.data.rows });

    if (this.props.onChange) {
      this.props.onChange(formData);
    }

    if (this.props.onRowAdd) this.props.onRowAdd(formData.rows.length - 1);
  }

  setFieldChange(type, index, field, value) {
    let reallyChanged = false;
    let oldValue = false;
    let oldValue2 = false;
    let formData = { ...this.props.formData };
    formData = formData[type];

    if (index > -1) {
      if (!formData[index]) {
        reallyChanged = true;
        oldValue = null;
        formData[index] = {};
      }
      // TODO: burası kontrol edilecek
      oldValue2 = value;
      if (formData[index][field] !== value) {
        reallyChanged = true;
        oldValue = formData[index][field];
        oldValue2 = formData[index][field];
      }
      formData[index][field] = value;
    } else {
      // TODO: burası kontrol edilecek

      if (formData[field] !== value) {
        reallyChanged = true;
        oldValue = formData[field];
      }
      formData[field] = value;
    }

    // let errors = this.state.errors || {}
    // if (errors[type]) errors[type][field] = false

    let bigForm = { ...this.props.formData };
    bigForm[type] = formData;

    //	this.setState({ formData: bigForm, errors: errors })
    if (this.props.onChange) {
      this.props.onChange(bigForm);
    }

    if (this.props.onChangeRow) {
      this.props.onChangeRow(index, bigForm.rows[index], field,oldValue2);
    }
    if (reallyChanged && this.props.onRealChange) {
      this.props.onRealChange(index, field, oldValue, value);
    }
  }

  createInput(field, inputProps) {
    let rElement = null;
    switch (field.type) {
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            tooltip={true}
            label={field.label ? field.label : ""}
            type="text"
            isVertical={!!field.vertical}
          />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="number"
            isVertical={!!field.vertical}
          />
        );
        break;
      case "percent":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="percent"
            isVertical={!!field.vertical}
          />
        );
        break;
      case "money":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            placeHolder={field.placeholder ? field.placeholder : ""}
            precision={field.precision || 2}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="money"
            isVertical={!!field.vertical}
          />
        );
        break;
      case "complete":
        rElement = (
          <EkoFormAutoComplete
              tooltip={true}
              {...inputProps}
              label={field.label ? field.label : ""}
              options={field.data}
              optionValue={field.optionValue}
              searchValue={field.searchValue}
              tooltip={true}
              id={field.id}
              onSelect={field.onSelect}
              selectValue={field.selectValue}
              isVertical={!!field.vertical}

          />
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            tooltip={true}
            label={field.label ? field.label : ""}
            disabled={field.disabled}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            isVertical={true}
            clearable={false}
          />
        );
        break;
      case "select":
        let width = "100px";
        if (field.id === "expense_category_id") width = "500px";
        else if (field.id === "item_id") width = "400px";
        else if (field.id === "expense_category_id") width = "400px";
        else if (field.id === "expense_category_id_select") width = "400px";
        else if (field.id === "nace_code_id") width = "400px";
        else if (field.id === "nace_code") width = "400px";
        else if (field.id === "gelir_kayit_alt_turu_id") width = "300px";
        rElement = (
          <EkoFormSelect
            tooltip={true}
            {...inputProps}
            selectedOptionValue={field.selectedOptionValue}
            defaultKey={field.defaultKey}
            searchable={field.searchable}
            searchFilter={field.searchFilter}
            disabled={field.disabled}
            id={field.id}
            width={width}
            isVertical={true}
            defaultText={field.defaultText}
            optionId={field.optionId}
            options={field.data}
            onAddNewItemClick={field.onAddNewItemClick}
            optionValue={field.optionValue}
          />
        );
        break;
      case "clickarea":
        rElement = <i className={field.faClass} aria-hidden="true" />;
        break;
      case "custom":
        rElement = <span>{field.onRender(field.props)}</span>;
        break;
      case "footertext":
        rElement = (
          <tr>
            <td>{field.name}</td>
          </tr>
        );
        break;
      default:
    }

    return rElement;
  }

  getCurrencyCode(code) {
    switch (code) {
      case "TRY":
        return "₺";
      case "EUR":
        return "€";
      case "USD":
        return "$";
      case "GBP":
        return "£";
      case "CHF":
        return "₣";
    }
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  showFooterInput(field) {
    const inputProps = {
      onChange: this.setFieldChange.bind(this, "footer", -1),
      onMount: this.setFieldMount.bind(this, "footer", -1),
      formData: this.props.formData.footer,
      errors: this.props.errors.footer,
    };

    return this.createInput(field, inputProps);
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  getInputProps = (i) => {
    return {
      onChange: this.setFieldChange.bind(this, "rows", i),
      onMount: this.setFieldMount.bind(this, "rows", i),
      formData: this.props.formData.rows[i] || {},
      errors: this.props.errors.rows[i] || {},
      //errors : this.state.errors.rows
    };
  };

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const isSuper = this.props.companies.filter(c => c.company_type === 9).length > 0;
    let currency = this.props.currency;
    console.log('this.props.data.headers',this.props.data.headers);
    const headers = this.props.data.headers.filter(function (header) {
      return !header.hide;
    });
    const rows = this.state.rows.map(function (row) {
      row = row.filter(function (field) {
        return !field.hide;
      });
      return row;
    });
    let className =
      "tinvoice table-cucu table table-striped table-bordered table-hover";
    if (this.props.className)
      className = this.props.className + " " + className;
    let addButtonClass = "btn tinvoicend btn-outline";
    if (this.props.buttonAddClass)
      addButtonClass = addButtonClass + " " + this.props.buttonAddClass;

    let footerTable = [];
    if (this.props.data.footerTable) {
      footerTable = this.props.data.footerTable.filter(function (f, i) {
        return f.visible;
      });
    }

    return (
      <EkoFormTooltip formId={this.props.formId || null}>
        <table id="tableFirst" className={className}>
          <thead>
            <tr>
              {headers.map((h, i) => (
                <th
                  key={i}
                  hidden={h.hidden}
                  width={h.width ? h.width : 100 / headers.length + "%"}
                  colSpan={i === 0 ?
                      this.props.orderNumber?3:2
                      : 0}
                  id={"header_th_" + i}
                  className={i === 0 ? "fcolumn" : ""}
                >
                  {TooltipService.getLabelWithTooltip(
                    "header_th_" + i,
                    h.label
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, x) => {
              return (
                <EkoInputTableLine
                  createInput={this.createInput}
                  getInputProps={this.getInputProps}
                  key={x}
                  deletable={this.props.deletable}
                  orderNumber={this.props.orderNumber}
                  row={row}
                  deleteRow={this.deleteRow}
                  currency={this.getCurrencyCode(currency)}
                  number={x}
                />
              );
            })}
          </tbody>
        </table>
        {this.props.onRowAdd ? (
          <button
            id="add"
            onClick={this.addRow.bind(this)}
            className={addButtonClass}
            data-dismiss="modal"
          >
            {this.props.addBtnTitle ? (
              <span style={{ fontSize: "13px" }}>
                <i className="fa fa-plus" aria-hidden="true" />{" "}
                {this.props.addBtnTitle}
              </span>
            ) : (
              <i className="fa fa-plus" aria-hidden="true" />
            )}
          </button>
        ) : null}
        {this.props.data.footer && (
          <div className="buttonProccess footerItems">
            <table className="tplus2">
              <tbody>
                {this.props.data.footer.map((f, i) => (
                  <tr key={i}>
                    <td>{this.showFooterInput(f)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.data.footerPrices && (
          <div className="buttonProccess">
            <table className="tplus">
              <tbody>
                {footerTable.map((f, i) => (
                  <tr key={i} className={f.className}>
                    <td>{f.label}</td>
                    <td>{this.props.data.footerData[f.id]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {this.props.data.footerTable && (
          <div className="buttonProccess">
            <table className="tplus">
              <tbody>
                {footerTable.map((f, i) => {
                  let priceTR = false;
                  let currency = this.props.currency;

                  if (f.id.indexOf("_fc") > 0) {
                    const trKey = f.id.replace("_fc", "");
                    priceTR = this.props.data.footerData[trKey];
                    if (priceTR === this.props.data.footerData[f.id])
                      priceTR = false;
                  }
                  return (
                    <tr key={i} className={f.className}>
                      <td>{f.label}</td>
                      <td>
                        {this.props.data.footerData[f.id]}{" "}
                        {this.getCurrencyCode(currency)}
                      </td>
                      {priceTR ? <td>=</td> : null}
                      {priceTR ? <td>{priceTR} ₺</td> : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {this.props.showTags ? (
          <div className="row" style={{ marginTop: -120, marginLeft: -25 }}>
            <div className="col-md-4 col-sm-4">
              <div
                className="portlet light"
                style={{
                  backgroundColor: "white",
                  border: "1px solid ghostwhite",
                }}
              >
                <div
                  className="modal-title"
                  style={{ padding: "5px 0 10px 0", fontSize: 16 }}
                >
                  <div
                    style={{
                      width: "230px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="caption"
                  >
                    <span title="Etiket Ekle" className="caption-subject bold">
                      Etiket Ekle
                    </span>
                  </div>
                </div>

                <Tags
                  onHandleTags={this.props.onHandleTags}
                  options={this.props.defaultTagOptions}
                  defaultValue={this.props.defaultTagValues}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.data.customFooter}

        <br />
        <div className="buttonProccess actionButtons">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.isCheckButton && isSuper ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <EkoFormButton
                  label={"Kontrol Edildi"}
                  faClass="fa fa-check"
                  buttonsType={this.props.buttonsType || "default"}
                  className="btn yellow mt-ladda-btn ladda-button"
                  isLoading={this.props.saving}
                  disabled={!this.props.editId}
                  onClick={() => this.props.onSave(false, true)}
                />{" "}
                <span style={{fontWeight: 'bold'}}>
                  {this.props.updatedAt
                    ? Util.convertToDatabaseDateTime(this.props.updatedAt)
                    : null}
                </span>
              </div>
            ) : null}
            {this.props.onSave ? (
              this.props.buttons && this.props.buttons.length > 0 ? (
                <EkoFormButtonGroup
                  label={this.props.buttonSaveLabel || "Kaydet"}
                  faClass="fa fa-plus"
                  buttonsType={this.props.buttonsType || "default"}
                  className="btn blue mt-ladda-btn ladda-button"
                  isLoading={this.props.saving}
                  onClick={() => this.props.onSave(true)}
                  buttons={this.props.buttons}
                />
              ) : (
                <EkoFormButton
                  style={{height: 34.2}}
                  label={this.props.buttonSaveLabel || "Kaydet"}
                  faClass="fa fa-plus"
                  buttonsType={this.props.buttonsType || "default"}
                  className="btn blue mt-ladda-btn ladda-button"
                  isLoading={this.props.saving}
                  onClick={() => this.props.onSave(true)}
                />
              )
            ) : (
              ""
            )}
            &nbsp;
            {this.props.onCancel ? (
              <EkoFormButton
                label="İptal"
                style={{height: 34.2}}
                className="btn dark btn-outline"
                onClick={this.onCancel.bind(this)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </EkoFormTooltip>
    );
  }
}

EkoInputTableButtonGroup.propTypes = {
  data: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  buttonsType: PropTypes.string,
  onRowAdd: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onCancel: PropTypes.func,
  onChangeRow: PropTypes.func,
  onRowDelete: PropTypes.func,
  buttons: PropTypes.array,
};

EkoInputTableButtonGroup.defaultProps = {
  saving: false,
};

function mapStateToProps(state) {
  return {
    companies: state.data.companies || []
  };
}

export default connect(mapStateToProps, actions)(EkoInputTableButtonGroup);
