import React, { Component } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import SupportWidget from "../common/SupportWidget";

import ChromeRecommendation from "../../components/common/ChromeRecommendation";
import ReduxToastr, { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../actions";
import EkoVideo from "../elements/EkoVideo";
import Api from "../../util/Api";
import PromotionModal from "../../screens/Promotion/PromotionModal";
import EmailModal from "../../components/forms/EmailModal";
import PromotionPhoneModal from "../../screens/Promotion/PromotionPhoneModal";
import { FETCH_VIDEO_CONFIGS, FETCH_VIDEO_DATAS } from "../../actions/types";
import EkoFormButton from "../elements/form/EkoFormButton";
import TooltipService from "../../services/TooltipService";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      sending: false,
      showEmailModal: false,
      showPhoneModal: false,
      showSupportWidget: false,
    };
  }

  componentWillMount() {
    this.props.fetchCachedDataAsKey(
      "/getVideoDatas",
      FETCH_VIDEO_DATAS,
      "videos",
      "3:day"
    );
    this.props.updateFetchedData(
      FETCH_VIDEO_CONFIGS,
      JSON.parse(localStorage.getItem("videos_config"))
    );
    localStorage.setItem("lastActivity", moment().toISOString());
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      let name = null;
      if (user.firstname !== null && user.lastname !== null)
        name = `${user.firstname} ${user.lastname}`;
      window.setDesk(name, user.email);
    }
  }

  // promosyon kodu popup kontrolü
  componentDidMount() {
    TooltipService.fetchLabels();
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (
        user.shouldShowPromotionPopup &&
        user.shouldShowPromotionPopup === true
      ) {
        user.shouldShowPromotionPopup = false;
        localStorage.setItem("user", JSON.stringify(user));
        this.showPhoneConfirm();
      }
    }
  }

  openModal() {
    const self = this;
    Api.post("/campaignIsThere", {}, (response, err) => {
      if (err) {
        toastr.error("Hata", err.message);
      } else self.setState({ showModal: true });
    });
  }

  openEmailModal = () => {
    this.setState({ showEmailModal: true });
  };

  forceRefresh = () => {
    this.forceUpdate();
  };

  showPhoneConfirm = () => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user.is_free === 1) {
      toastr.error(
        "Ücretsiz Paket ile Sadece Mobil Uygulamamızı Kullanabilirsiniz"
      );
    }

    this.setState({ showPhoneModal: true });
  };

  resendMail = () => {
    this.setState({ sending: true }, () => {
      Api.get("/user/send-activation", (response, err) => {
        this.setState({
          sending: false,
        });

        if (err) {
          toastr.error("Hata", err.message);
        } else {
          toastr.success("Başarılı!", response.message);
        }
      });
    });
  };

  closePhoneModal = () => {
    this.setState({ showPhoneModal: false });
  };


  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    return (
      <div className="page-wrapper" lang="tr">
        <ChromeRecommendation />
        {user && parseInt(user.is_activated, 10) !== 1 ? (
          <div
            className="alert alert-warning"
            style={{ textAlign: "center", marginBottom: 0, padding: 8 }}
            role="alert"
          >
            <div style={{ color: "#333", display: "inline-block" }}>
              {user.email} adresinize göndermiş olduğumuz maildeki aktivasyon
              linkine tıklayarak hesabınızı aktive ediniz!{" "}
              <EkoFormButton
                className={"btn btn-activation"}
                isLoading={this.state.sending}
                onClick={this.resendMail}
                label={"Tekrar Gönder"}
              />
              <EkoFormButton
                className={"btn btn-activation"}
                onClick={this.openEmailModal}
                label={"E-posta Değiştir"}
              />
            </div>
          </div>
        ) : null}


        {parseInt(user.is_activated, 10) == 1 && user.expiryDays<15 ? (
            <div
                className="alert alert-warning"
                style={{ textAlign: "center", marginBottom: 0, padding: 8 }}
                role="alert"
            >
              <div style={{ fontSize: 16, color: "#333", display: "inline-block" }}>
                Üyeliğinizin bitmesine <label style={{fontWeight: "bold"}}>{user.expiryDays}</label> gün kalmıştır.
                <EkoFormButton
                    className={"btn btn-activation"}
                    style={{marginLeft: 15 }}
                    isLoading={this.state.sending}
                    onClick={event =>  window.location.href='/subscriber'}
                    label={"Süreyi Uzat"}
                />
              </div>
            </div>
        ) : null}


        <SupportWidget
          show={this.state.showSupportWidget}
          handleClose={() => this.setState({ showSupportWidget: false })}
        />
        <Header
          visibleMenu={this.props.visibleMenu}
          path={window.location.pathname}
        />
        <div className="page-wrapper-row full-height">
          <div className="page-wrapper-middle">
            <div className="page-container">
              {/*<BrowserUpdate path={location.pathname}/>*/}
              <div className="page-content-wrapper">
                <div className="page-content">
                  <div className="container">
                    <EkoVideo path={window.location.pathname} />
                    {this.props.children}
                    {this.props.authenticated && (
                      <>
                        <Fab
                          mainButtonStyles={{
                            backgroundColor: "#3498db",
                            width: 150,
                            borderRadius: 48,
                          }}
                          position={{ bottom: 0, right: 0 }}
                          icon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "unset",
                                alignItems: "center",
                                width: 150,
                                paddingLeft: 10,
                                paddingRight: 10,
                                transform: "none",
                                borderRadius: "48px",
                              }}
                            >
                              <i
                                style={{ marginLeft: 10 }}
                                className="fas fa-question-circle"
                              ></i>
                              <span
                                style={{ transform: "none", margin: "0 auto" }}
                              >
                                Destek
                              </span>
                            </div>
                          }
                          event={"hover"}
                        >
                          <Action
                            style={{
                              display: "flex",
                              justifyContent: "unset",
                              alignItems: "center",
                              width: 150,
                              paddingLeft: 10,
                              paddingRight: 10,
                              transform: "none",
                              borderRadius: "48px",
                            }}
                            onClick={() =>
                              this.setState({ showSupportWidget: true })
                            }
                          >
                            <i
                              style={{ marginLeft: 10 }}
                              className="fas fa-book-open"
                            ></i>
                            <span style={{ margin: "0 auto" }}>Rehber</span>
                          </Action>
                          <Action
                            style={{
                              display: "flex",
                              justifyContent: "unset",
                              alignItems: "center",
                              width: 150,
                              paddingLeft: 10,
                              paddingRight: 10,
                              transform: "none",
                              borderRadius: "48px",
                            }}
                            onClick={() =>
                              window.zE.activate({ hideOnClose: true })
                            }
                          >
                            <i
                              style={{ marginLeft: 10 }}
                              className="fas fa-envelope"
                            ></i>
                            <span style={{ marginLeft: 10 }}>Bize Ulaşın</span>
                          </Action>
                        </Fab>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {this.props.subscribeDesc ? (
              <div className="campaign-faq" style={{ width: "100%" }}>
                <div className="container">
                  <br />
                  <br />
                  <div className="col-md-4 col-sm-6">
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </div>
                  <h3>Banka havalesi ile ödeme yapabilir miyim?</h3>
                  <p>
                    Banka havalesi ile ödeme yapmak ister iseniz, kullanmak
                    istediğiniz paketin{" "}
                    <span style={{ fontWeight: "bold" }}>
                      minimum 12 aylık bedelini
                    </span>{" "}
                    havale edip bize e-posta atmanız yeterlidir. E-posta’da
                    hangi paketi kullanmak istediğinizi lütfen belirtiniz. Hesap
                    bilgilerimiz: ALICI: EkoHesap Bilgi Teknolojileri A.Ş.
                    BANKA: Türkiye İş Bankası A.Ş. IBAN: TR29 0006 4000 0011
                    3770 0924 73 AÇIKLAMA: Abonelik Ücreti Banka dekontu ile
                    birlikte aboneliğinizin başlatılmasını istediğiniz hesabın
                    e-posta adresini ve fatura bilgilerinizi
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      iletisim@ekohesap.com
                    </span>{" "}
                    adresine gönderin.
                  </p>
                  <h3>Aboneliğimi nasıl iptal edebilirim?</h3>
                  <p>
                    Aboneliğiniz başladıktan sonra istediğiniz zaman bize
                    ulaşarak aboneliğinizi iptal edebilirsiniz. İptali
                    gerçekleştirdiğinizde paketinizin bir sonraki abonelik
                    yenileme dönemi için ücretlendirmesi yapılmaz. Geçmiş
                    dönemler için ücret iadesi bulunmamaktadır.
                  </p>
                  <h3>
                    Aboneliğimi iptal edersem, verilerimi dışarı aktarabilir
                    miyim?
                  </h3>
                  <p>
                    Tabi ki. Uygulamaya girdiğiniz tüm veriler size aittir.
                    Aboneliğinizi iptal etme işlemi esnasında verilerinizi dışa
                    aktarmak istediğinizi destek ekibimize lütfen iletin.
                  </p>
                  <h3>Promosyon kodumu nasıl kullanabilirim?</h3>
                  <p>
                    Promosyon kodunuzu girerek size özel avantajlardan
                    faydalanabilirsiniz.
                    <a onClick={this.openModal.bind(this)}>
                      Promosyon kodunuzu girmek için tıklayın.
                    </a>
                  </p>
                </div>
                <PromotionModal
                  showModal={this.state.showModal}
                  closeModal={() => {
                    this.setState({ showModal: false });
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <PromotionPhoneModal
              showModal={this.state.showPhoneModal}
              closeModal={this.closePhoneModal}
            />
            <EmailModal
              showModal={this.state.showEmailModal}
              forceRefresh={this.forceRefresh}
              closeModal={() => {
                this.setState({ showEmailModal: false });
              }}
            />
          </div>
        </div>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps, actions)(Dashboard);
