import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class BulkStockUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            list: [],
            date: false,
            searchTerm: ''
        };
    }
    handleSearchTermChange = (e) => {
        this.setState({ searchTerm: e.target.value });
    };
    rowChangeUpdate = (val, obj) => {
        const updatedList = [...this.state.list];
        updatedList[obj].quantity = val;
        this.setState({ list: updatedList });
    };
    dateUpdate = (date) => {
        this.setState({ date: date });
    };
    list() {
        Api.get("/warehouses/showAllItemsWareHouseList", (data, errors) => {
            if (data) {
                this.setState({list: data});
            }
        });
    }

    update(){
        if(this.state.date===false){
            toast.error("Lütfen Tarih Seçin", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: true
            });
        }else{
            Api.post("/warehouses/updateStocksItems", {list:this.state.list,date:this.state.date}, (data, errors) => {
                if (data) {
                    this.list();
                    toast.success("Başarıyla Güncellendi", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: true
                    });
                }
            });
        }

    }


    componentWillMount() {
        this.list();
    }


    render() {
        const filteredList = this.state.list.filter(item =>
            item.item_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
        );
        return (
            <div className="portlet light">
                <div>
                    <input
                        type="text"
                        placeholder="Ürün Adı Ara"
                        value={this.state.searchTerm}
                        onChange={this.handleSearchTermChange}
                        className="form-control"
                    />
                </div>
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            Toplu Stok Güncelleme
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <div><ToastContainer  limit={1} />
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th style={{width:"20%"}} className="fcolumn">Ürün Adı</th>
                                <th style={{width:"20%"}} className="fcolumn">Depo Adı</th>
                                <th style={{width:"20%"}} className="fcolumn">Mevcut Stok Miktarı Giriniz</th>
                            </tr>
                            </thead>
                        </table>
                        {filteredList.map((item) => {
                            return (
                                <table key={item.index} className="table table-striped table-bordered table-hover">
                                    <tbody>
                                    <tr>
                                        <td style={{width:"20%"}}>
                                            {item.item_name}
                                        </td>
                                        <td style={{width:"20%"}}>{item.warehouse_name}</td>
                                        <td style={{width:"20%"}}>
                                            <input
                                                value={this.state.list.find(el => el.index === item.index).quantity}
                                                className="form-control" type="number" min="1" max="100"
                                                onChange={(e) => this.rowChangeUpdate(e.target.value, item.index)}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            );
                        })}

                        <div style={{"marginTop":"20px","marginBottom":"20px","width":"400px","float":"right"}}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    Tarih Seçin:
                                    <input
                                        type={"date"}
                                        value={this.state.date}
                                        className="form-control"
                                        onChange={(e) => this.dateUpdate(e.target.value)}
                                    />
                                    <small>
                                        Stok düzeltme kayıtları, yukarıdaki tarih ile stok hareketlerinize atılacaktır.
                                    </small>
                                </div>
                                <div className={"col-md-6"}>
                                    <br />
                                    <button className="btn blue btn-sm" onClick={this.update.bind(this)}>
                                        <i className="fa fa-plus"/>&nbsp; Güncelle
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{"clear":"both"}}></div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties,
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(BulkStockUpdate);