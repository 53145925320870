import React, {Component} from 'react';
import Api from "../../util/Api";
import EkoSpinner from "../../components/elements/EkoSpinner";

class TransactionPdf extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null,
            loading: true,
        }
    }

    componentWillMount() {
        const id = this.props.params.id

        Api.get('/customerTransaction/exportUrl/' + id, (response, error) => {
            if (response && response.data) {
                this.setState({
                    url: response.data.url
                })
            }
        })
    }


    render() {
        if (this.state.url === null) {

            return <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <EkoSpinner/>
            </div>
        }

        return (
            <div style={{
                height: "100%",
                width: '100%',
                display: 'inline-block'
            }}>


                <iframe
                    onLoadStart={() => this.setState({loading: true})}
                    onLoad={() => this.setState({loading: false})}
                    src={"https://viewer.ekohesap.com/?zoom=1.25#" + this.state.url + "?version=1"}
                    style={{
                        position: 'relative',
                        height: '100%',
                        width: '100%'
                    }}
                    frameBorder="0"/>

            </div>
        );
    }
}

export default TransactionPdf;