import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoFormButton,
  EkoFormInputText,
  EkoFormRadioButton,
  EkoFormDate,
  EkoFormSelect,
  EkoForm
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Util from "../../util/Util";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import { Link } from "react-router";

class EInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        value: { is_net: 0, send_info: "HICBIRI", note: null },
        value2: { profileID: 1, note: null }
      },
      saving: false,
      errors: {},
      formFields: {},
      status: 0,
      requiredFieldsResponse: {
        status: "",
        message: "",
        requiredInformations: []
      },
      reasons: [],
      reason: false,
      loading: false,
      title: "e-Fatura Bilgileri",
      radioButtons: [
        {
          label: "Hayır, yok",
          id: "is_net",
          radio_id: "no",
          value: 0,
          checked: true
        },
        {
          label: "Evet, var",
          id: "is_net",
          radio_id: "yes",
          value: 1
        }
      ],
      radioButtons2: [
        {
          label: "Gönderici yok",
          id: "send_info",
          radio_id: "none",
          value: "HICBIRI",
          checked: true
        },
        {
          label: "Kargo",
          id: "send_info",
          radio_id: "cargo",
          value: "KARGO/LOJISTIK"
        },
        {
          label: "Kurye",
          id: "send_info",
          radio_id: "messenger",
          value: "KISI"
        }
      ],
      radioButtons3: [
        {
          label: "Ticari Fatura",
          id: "profileID",
          radio_id: "ticari",
          value: 1,
          checked: true
        },
        {
          label: "Temel Fatura",
          id: "profileID",
          radio_id: "temel",
          value: 0
        }
      ]
    };
  }

  componentDidMount() {
    Api.post("/company/checkCommercialInfo", {}, (res, err) => {
      this.setState({ requiredFieldsResponse: { ...res } });
    });
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.type || nextProps.type === 0) {
      console.log(nextProps.type, "NEXTTYPE");
      let status = 0;
      if (nextProps.type === 1) status = 10;
      this.setState({ status: status });
      this.title(status);
    }

    if(nextProps.objectId !== 0){
      Api.get('/einformation/addDocumentReference/'+nextProps.objectId,(res,err)=>{
        if (res.status === 'ok'){
          this.setState({
            formData : {
              value : {...this.state.formData.value,...res.data,payment_method:"KREDIKARTI/BANKAKARTI"},
              value2 : {...this.state.formData.value2}
            }
          })
        }
      })
    }



    if (
      nextProps.response &&
      nextProps.response.receiver &&
      nextProps.response.receiver[0] &&
      nextProps.response.receiver[0].identifier &&
      !this.state.formData.value2.identifier
    ) {
      this.setFieldChange(
        "value2",
        "identifier",
        nextProps.response.receiver[0].identifier
      );
    }

    const self = this;
    let response = nextProps.response;
    // check taxExempReasons
    Api.post("/checkTaxPercent", { invoice: response.invoice }, function(
      res,
      err
    ) {
      if (res.status === "fail") {
        self.setState({ reasons: res, reason: true });
      } else {
        self.setState({ reasons: [], reason: false });
      }
    });
  }

  renderGoToCompanyInfo() {
    const requiredFields = {
      firstname: "Adı/Firma Ünvanı",
      tax_office_code: "Vergi Dairesi Adı",
      tax_identity_number: "Vergi/TC No ",
      address: "Açık Adres ",
      city_id: "İl",
      county_id: "İlçe"
    };

    // Iterate through keys of requiredFields and filter which doesnt exist in the state.
    const requiredFieldsTranslated = Object.keys(requiredFields)
      .map((key, index) =>
        this.state.requiredFieldsResponse &&
        this.state.requiredFieldsResponse.requiredInformations &&
        this.state.requiredFieldsResponse.requiredInformations.includes(key)
          ? requiredFields[key]
          : null
      )
      .filter(el => !!el);

    return (
      <EkoModal
        showModal={this.props.showModal}
        title="Eksik Bilgi"
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <div className="row">
          <div className={"col-md-12"}>
            <EkoForm>
              {this.state.requiredFieldsResponse.message}
              <p>
                e-Fatura/e-Arşiv gönderebilmeniz için; <br />
                <br />
                <b style={{ color: "red" }}>
                  {requiredFieldsTranslated.join(", ")}
                </b>
                <br />
                <br /> bilgilerinizin dolu olması gerekmektedir. Lütfen Ticari
                Bilgiler sayfasında gerekli alanları doldurunuz.
              </p>
            </EkoForm>
          </div>
        </div>
        <Modal.Footer>
          <br />
          <Link to="/dashboard/companySettings">
            <EkoFormButton
              label={"Ticari Bilgiler Sayfasına Git"}
              faClass="fa fa-arrow-right"
            />
          </Link>
        </Modal.Footer>
      </EkoModal>
    );
  }

  setFieldChange(type, field, value) {
    if (field === "reason") {
      this.setState({ reason: false });
    }

    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors, reason: false });
  }

  setFieldMount(type, field) {
    var self = this;
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
    //@oguz istisna faturadan seçildiyse aşağıdaki kod ile  dolu getiriliyor
    var exemption_reasons = JSON.parse(localStorage.getItem('exemption_reasons'));
    Api.get("/salesInvoiceLine/showAll/" + this.props.response.invoice.id, function(responses) {
      var codeId = exemption_reasons.findIndex(o => o.code == responses.salesInvoiceLines[0].istisna_kodu);
      self.setFieldChange(
          "value2",
          "reason",
          exemption_reasons[codeId].id
      );
      self.setFieldChange(
          "value",
          "reason",
          exemption_reasons[codeId].id
      );
    });
  }

  closeModal() {
    this.setState({
      status: 0,
      saving: false,
      errors: {},
      formData: {
        value: { is_net: 0, send_info: "HICBIRI", note: null },
        value2: { profileID: 1, note: null }
      },
      response: { customer: {}, invoice: {}, receiver: [] },
      title: "e-Fatura Bilgileri",
      reasons: []
    });
    this.props.closeModal();
  }

  addBalance() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    formData.value2.note = (formData.value2.note+"\nBu Fatura Dahil Güncel Bakiyeniz: "+response.customer.balance+" "+response.customer.currency).trimStart();
    formData.value.note = (formData.value.note+"\nBu Fatura Dahil Güncel Bakiyeniz: "+response.customer.balance+" "+response.customer.currency).trimStart();
    this.setState({formData: formData});
  }
  addBalance2() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    formData.value2.note = (formData.value2.note+"\nBu Fatura Dahil Güncel Bakiyeniz: "+response.customer.balance+" "+response.customer.currency).trimStart();
    formData.value.note = (formData.value.note+"\nBu Fatura Dahil Güncel Bakiyeniz: "+response.customer.balance+" "+response.customer.currency).trimStart();
    this.setState({formData: formData});
  }
  addCurrencyTxt() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    if(response.invoice.currency!=='TRY'){
      formData.value2.note = (formData.value2.note+"\nKur Bilgisi: "+response.invoice.currency_rate+" TL").trimStart();
      formData.value.note = (formData.value.note+"\nKur Bilgisi: "+response.invoice.currency_rate+" TL").trimStart();
      this.setState({formData: formData});
    }else{
      toastr.error("Hata", "Faturanın para birimi TL olduğu için kur bilgisi eklenemez.");
    }
  }
  addCurrencyTxt2() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    if(response.invoice.currency!=='TRY'){
      formData.value2.note = (formData.value2.note+"\nKur Bilgisi: "+response.invoice.currency_rate+" TL").trimStart();
      formData.value.note = (formData.value.note+"\nKur Bilgisi: "+response.invoice.currency_rate+" TL").trimStart();
      this.setState({formData: formData});
    }else{
      toastr.error("Hata", "Faturanın para birimi TL olduğu için kur bilgisi eklenemez.");
    }
  }
  addDueDateTxt() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    if(response.invoice.due_date!==null){
      formData.value2.note = (formData.value2.note+"\nVade Tarihi: "+response.invoice.due_date).trimStart();
      formData.value.note = (formData.value.note+"\nVade Tarihi: "+response.invoice.due_date).trimStart();
      this.setState({formData: formData});
    }else{
      toastr.error("Hata", "Faturanın vade tarihi girilmediği için eklenemez.");
    }
  }
  addDueDateTxt2() {
    let formData = {...this.state.formData};
    let response = this.props.response;
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    if(response.invoice.due_date!==null){
      formData.value2.note = (formData.value2.note+"\nVade Tarihi: "+response.invoice.due_date).trimStart();
      formData.value.note = (formData.value.note+"\nVade Tarihi: "+response.invoice.due_date).trimStart();
      this.setState({formData: formData});
    }else{
      toastr.error("Hata", "Faturanın vade tarihi girilmediği için eklenemez.");
    }
  }
  addShipmentTxt() {
    let formData = {...this.state.formData};
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
      formData.value2.note = (formData.value2.note+"\nİrsaliye Yerine Geçer.").trimStart();
      formData.value.note = (formData.value.note+"\nİrsaliye Yerine Geçer.").trimStart();
      this.setState({formData: formData});
  }
  addShipmentTxt2() {
    let formData = {...this.state.formData};
    if(formData.value2.note===null){formData.value2.note="";}
    if(formData.value.note===null){formData.value.note="";}
    formData.value2.note = (formData.value2.note+"\nİrsaliye Yerine Geçer.").trimStart();
    formData.value.note = (formData.value.note+"\nİrsaliye Yerine Geçer.").trimStart();
    this.setState({formData: formData});
  }
  title(status) {
    var type="";
    if(status===10 || status===11){
      type="E-Fatura";
    }else{
      type="E-Arşiv";
    }
    let title = type+" -e-Fatura Bilgileri";
    switch (status) {
      case 1:
        title = type+" - Teslimat Yöntemi";
        break;
      case 2:
      case 11:
        title = type+" - Fatura Bilgilerini Onaylayın";
        break;
      case 10:
        title = type+" - Gönderim Bilgileri";
        break;
      default:
        title = type+" - İnternet Üzerinden Satış";
        break;
    }
    this.setState({ title: title });
    return title;
  }

  go() {
    let status = this.state.status;
    if (this.state.formData.value.is_net === 0 && status === 0) status = 2;
    else status = this.state.status + 1;
    const title = this.title(status);
    this.setState({ status: status, title: title });
  }

  onFalse() {}

  renderContent() {
    let value = this.state.formData.value;
    let value2 = this.state.formData.value2;
    let status = this.state.status;
    console.log(this.props.type, "TYPE");
    if (this.props.type === 0) return this.eArchiveView(value, status);
    else return this.eInvoiceView(value2, status);
  }

  eInvoiceView(value, status) {
    let response = this.props.response;
    const value2Props = {
      onChange: this.setFieldChange.bind(this, "value2"),
      onMount: this.setFieldMount.bind(this, "value2"),
      formData: this.state.formData.value2,
      errors: this.state.errors.value2
    };

    let name = response.customer.firstname ? response.customer.firstname : null;
    name = response.customer.lastname
      ? name + " " + response.customer.lastname
      : name;
    let receiver = [];
    if (value && value.identifier)
      receiver = response.receiver.filter(
        item => item.identifier == value.identifier
      );

    if (status === 11)
      return (
        <div style={{ textAlign: "center" }}>
          <div className="row">
            <div className="col-md-8 col-md-push-2">
              {this.staticHtml("Müşteri", name)}
              {this.staticHtml(
                "Düzenlenme Tarihi",
                response.invoice.date
                  ? moment(response.invoice.date, "DD-MM-YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : null
              )}
              {this.staticHtml(
                "Genel Toplam",
                response.invoice.total_amount_fc
                  ? Util.convertDecimalToMoney(response.invoice.total_amount_fc)
                  : null,
                1,
                  response.invoice.currency
              )}
            </div>
          </div>
          <hr />
          <div className="row" style={{ position: "relative" }}>
            <div className="col-md-3" style={{ fontWeight: "bold" }}>
              <br />
              GÖNDERİM BİLGİLERİ
            </div>
            <div className="col-md-8">
              {this.staticHtml("Alıcı", receiver[0] ? receiver[0].title : "")}
              {this.staticHtml(
                "Adres",
                value.identifier ? value.identifier : ""
              )}
              {this.staticHtml(
                "Senaryo",
                value.profileID === 1 ? "Ticari e-Fatura" : "Temel e-Fatura"
              )}
            </div>
            <div className="col-md-8" />
            <div
              style={{ position: "absolute", bottom: 0, right: 0 }}
              className="col-md-1"
            >
              <a
                onClick={() =>
                  this.setState({ status: 10, title: this.title(10) })
                }
              >
                <i className="fa fa-pencil-square-o fa-2x" />
              </a>
            </div>
          </div>
          <hr />
          {Object.values(this.state.reasons).length > 0 ? (
            <div className="row">
              <p style={{ color: "red", fontWeight: "bold" }}>
                {this.state.reasons.message}
              </p>
              <EkoFormSelect
                {...value2Props}
                label="KDV İstisna Kodu : "
                lableMd={4}
                options={this.state.reasons.reasons}
                id="reason"
              />
            </div>
          ) : (
            ""
          )}

          <hr />
          <div className="row">
            <EkoFormButton
                label="BAKİYE EKLE"
                className="btn"
                onClick={this.addBalance2.bind(this)}
            />
            <EkoFormButton
                label="DÖVİZ KURU EKLE"
                className="btn"
                onClick={this.addCurrencyTxt2.bind(this)}
            />
            <EkoFormButton
                label="VADE TARİHİ EKLE"
                className="btn"
                onClick={this.addDueDateTxt2.bind(this)}
            />
            <EkoFormButton
                label="İRS. YER. GEÇER"
                className="btn"
                onClick={this.addShipmentTxt2.bind(this)}
            />
            <EkoFormInputText
              {...value2Props}
              id="note"
              type="textarea"
              placeHolder={"Fatura Notu Giriniz"}
              colMd={12}
              rows={8}
            />
          </div>
        </div>
      );
    else
      return (
        <div style={{ textAlign: "center" }}>
          <div className="row">
            <EkoFormRadioButton
              {...value2Props}
              id="radioButtons3"
              buttons={this.state.radioButtons3}
              onFalse={this.onFalse}
            />
            {value.profileID !== 1 ? (
              ""
            ) : (
              <p style={{ background: "#c1c1c1", color: "#333" }}>
                <span style={{ fontWeight: "bold" }}>Ticari</span> olarak
                gönderdiğiniz e-Fatura'ya karşıdan kabul ya da red mesajı
                alabilirsiniz.
              </p>
            )}
          </div>
          <hr/>
          { response.isKamu && response.kamuInformation.status === "fail" ?
              <p className={"alert alert-danger"}>{response.kamuInformation.message}</p> : ""
          }
          <hr />
          <div className="row">
            {this.staticHtml("Alıcı", receiver[0] ? receiver[0].title : null)}
            {this.staticHtml(
              null,
              <EkoFormSelect
                {...value2Props}
                label="Adres *"
                optionId="identifier"
                optionValue="identifier"
                abelMd={4}
                colMd={7}
                options={response.receiver}
                id="identifier"
              />,
              2
            )}
          </div>
        </div>
      );
  }

  eArchiveView(value, status) {

    const methods = [
      { id: "KREDIKARTI/BANKAKARTI", name: "KREDIKARTI/BANKAKARTI" },
      { id: "EFT/HAVALE", name: "EFT/HAVALE" },
      { id: "KAPIDAODEME", name: "KAPIDAODEME" },
      { id: "ODEMEARACISI", name: "ODEMEARACISI" },
      { id: "DIGER", name: "DIGER" }
    ];
    const response = this.props.response;
    const valueProps = {
      onChange: this.setFieldChange.bind(this, "value"),
      onMount: this.setFieldMount.bind(this, "value"),
      formData: this.state.formData.value,
      errors: this.state.errors.value
    };


    let name = response.customer.firstname ? response.customer.firstname : null;
    name = response.customer.lastname
      ? name + " " + response.customer.lastname
      : name;
    if (status === 1)
      return (
        <div style={{ textAlign: "center" }}>
          <div>
            <p>Fatura ürünlerin teslimat yöntemini seçin.</p>
            <EkoFormRadioButton
              {...valueProps}
              id="radioButtons2"
              buttons={this.state.radioButtons2}
              onFalse={this.onFalse}
            />
            <hr />
            {value.send_info === "KARGO/LOJISTIK" ? (
              <div className="row">
                <EkoFormDate
                  {...valueProps}
                  id="delivery_date"
                  dateFormat="DD-MM-YYYY"
                  clearable={false}
                  label="Gönderim Tarihi"
                />
                <EkoFormInputText
                  {...valueProps}
                  label="Kargo Şirketi"
                  id="carrier_name"
                />
                <EkoFormInputText
                  {...valueProps}
                  label="Vergi No"
                  id="carrier_tax_identity"
                  placeHolder="Kargo Şirketi Vergi Numarası"
                  type="mask"
                  mask="9999999999"
                  maskChar=""
                />
              </div>
            ) : (
              ""
            )}
            {value.send_info === "KISI" ? (
              <div className="row">
                <EkoFormDate
                  {...valueProps}
                  id="delivery_date"
                  dateFormat="DD-MM-YYYY"
                  clearable={false}
                  label="Gönderim Tarihi"
                />
                <EkoFormInputText
                  {...valueProps}
                  label="Kurye Adı Soyadı"
                  id="messenger_name"
                />
                <EkoFormInputText
                  {...valueProps}
                  label="Kurye TC No"
                  id="messenger_tax_identity"
                  type="mask"
                  mask="99999999999"
                  maskChar=""
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    else if (status === 2)
      return (
        <div style={{ textAlign: "center" }}>
          {!response.email ? (
            <span style={{ fontWeight: "bold", color: "#ad3d38" }}>
              <i className="fas fa-exclamation-triangle" />
              Uyarı: Müşterinizin iletişim maili boş olduğundan bilgi emaili
              gitmeyecek !
            </span>
          ) : null}
          <div className="row">
            <div className="col-md-8 col-md-push-2">
              {this.staticHtml("Müşteri", name)}
              {this.staticHtml(
                "Düzenlenme Tarihi",
                response.invoice.date
                  ? moment(response.invoice.date, "DD-MM-YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : null
              )}
              {this.staticHtml(
                "Genel Toplam",
                response.invoice.total_amount_fc
                  ? Util.convertDecimalToMoney(response.invoice.total_amount_fc)
                  : null,
                1,
                  response.invoice.currency
              )}
            </div>
          </div>
          <hr />

          <div className="row" style={{ position: "relative" }}>
            <div className="col-md-3" style={{ fontWeight: "bold" }}>
              <br />
              ONLİNE SATIŞ
            </div>
            {value.is_net === 1 ? (
              <div className="col-md-8">
                {this.staticHtml("Web Adresi", value.website)}
                {this.staticHtml("Ödeme Şekli", value.payment_method)}
                {this.staticHtml("Ödeme Tarihi", value.payment_date)}
              </div>
            ) : (
              <div className="col-md-8" />
            )}
            <div
              style={{ position: "absolute", bottom: 0, right: 0 }}
              className="col-md-1"
            >
              <a
                onClick={() =>
                  this.setState({ status: 0, title: this.title(0) })
                }
              >
                <i className="fa fa-pencil-square-o fa-2x" />
              </a>
            </div>
          </div>
          {value.is_net === 1 ? <hr /> : ""}
          {value.is_net === 1 ? (
            <div className="row" style={{ position: "relative" }}>
              <div className="col-md-3" style={{ fontWeight: "bold" }}>
                <br />
                TESLİMAT BİLGİLERİ
              </div>
              <div className="col-md-8">
                {value.send_info === "KARGO/LOJISTIK" ? (
                  <span>
                    {this.staticHtml("Gönderim Tarihi", value.delivery_date)}
                    {this.staticHtml("Kargo Şirketi", value.carrier_name)}
                    {this.staticHtml("Vergi No", value.carrier_tax_identity)}
                  </span>
                ) : (
                  ""
                )}
                {value.send_info === "KISI" ? (
                  <span>
                    {this.staticHtml("Gönderim Tarihi", value.delivery_date)}
                    {this.staticHtml("Kurye Adı Soyadı", value.messenger_name)}
                    {this.staticHtml(
                      "Kurye TC No",
                      value.messenger_tax_identity
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{ position: "absolute", bottom: 0, right: 0 }}
                className="col-md-1"
              >
                <a
                  onClick={() =>
                    this.setState({ status: 1, title: this.title(1) })
                  }
                >
                  <i className="fa fa-pencil-square-o fa-2x" />
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
          <hr />
          {Object.values(this.state.reasons).length > 0 ? (
            <div className="row">
              <p style={{ color: "red", fontWeight: "bold" }}>
                {this.state.reasons.message}
              </p>
              <EkoFormSelect
                {...valueProps}
                label="KDV İstisna Kodu : "
                lableMd={4}
                options={this.state.reasons.reasons}
                id="reason"
              />
            </div>
          ) : (
            ""
          )}
          <hr />
          <div className="row">
            <div className={"col-md-12"}>
              <EkoFormButton
                  label="BAKİYE EKLE"
                  className="btn"
                  onClick={this.addBalance.bind(this)}
              />
              <EkoFormButton
                  label="DÖVİZ KURU EKLE"
                  className="btn"
                  onClick={this.addCurrencyTxt.bind(this)}
              />
              <EkoFormButton
                  label="VADE TARİHİ EKLE"
                  className="btn"
                  onClick={this.addDueDateTxt.bind(this)}
              />
              <EkoFormButton
                  label="İRS. YER. GEÇER"
                  className="btn"
                  onClick={this.addShipmentTxt.bind(this)}
              />
              <EkoFormInputText
                  {...valueProps}
                  id="note"
                  type="textarea"
                  placeHolder={"Fatura Notu Giriniz"}
                  colMd={12}
                  rows={8}
              />
            </div>
          </div>
        </div>
      );
    else
      return (
        <div style={{ textAlign: "center" }}>
          <p>
            Fatura satış internet üzerinden (online) gerçekleşen ürün var mı?
          </p>
          <EkoFormRadioButton
            {...valueProps}
            id="radioButtons"
            buttons={this.state.radioButtons}
            onFalse={this.onFalse}
          />
          <hr />
          {this.state.formData.value.is_net === 1 ? (
            <div className="row">
              <EkoFormInputText
                {...valueProps}
                label="Web Adresi"
                lableMd={4}
                placeHolder="www.ekohesap.com"
                id="website"
              />
              <EkoFormSelect
                {...valueProps}
                label="Ödeme Şekli"
                lableMd={4}
                options={methods}
                id="payment_method"
              />
              <EkoFormDate
                {...valueProps}
                id="payment_date"
                dateFormat="DD-MM-YYYY"
                clearable={false}
                label="Ödeme Tarihi"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
  }

  staticHtml(lbl, text, special,currency=false) {
    let style =
      special === 2
        ? {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
            fontWeight: "bold"
          }
        : {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "ellipsis"
          };
    if (!lbl) return <div className="row col-md-11">{text}</div>;
    return (
      <div className="row">
        <label htmlFor="name" className="col-md-4 control-label">
          <span>{lbl}</span>
        </label>
        <div id="name" style={style} className="col-md-8 form-control-static">
          {text}
          {currency ? " "+currency : ""}
        </div>
      </div>
    );
  }

  renderButtons() {
    let sendInfo1 =
      this.state.formData.value.carrier_tax_identity &&
      this.state.formData.value.carrier_name &&
      this.state.formData.value.delivery_date;
    let sendInfo2 =
      this.state.formData.value.messenger_tax_identity &&
      this.state.formData.value.messenger_name &&
      this.state.formData.value.delivery_date;
    if (this.state.status === 1)
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label={"Devam Et"}
            disabled={
              (this.state.formData.value.send_info === "KARGO/LOJISTIK" &&
                !sendInfo1) ||
              (this.state.formData.value.send_info === "KISI" && !sendInfo2)
            }
            faClass="fa fa-arrow-right"
            onClick={this.go.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
    if (this.state.status === 2 || this.state.status === 11)
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label={"Kaydet Ve Gönder"}
            isLoading={this.state.loading}
            faClass="fa fa-arrow-right"
            onClick={this.onSave.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
    else
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label={"Devam Et"}
            disabled={
              this.state.formData.value.is_net === 1 &&
              !(
                this.state.formData.value.website &&
                this.state.formData.value.payment_method &&
                this.state.formData.value.payment_date
              )
            }
            faClass="fa fa-arrow-right"
            onClick={this.go.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
  }

  onSave() {
    const self = this;
    self.setState({ loading: true });
    const formData = this.state.formData;
    const response = this.props.response;
    let value = this.props.type === 1 ? formData.value2 : formData.value;
    let receiver = response.receiver[0]
      ? response.receiver.filter(
          item => item.identifier == formData.value2.identifier
        )[0]
      : response.receiver;
    if (this.props.type === 1) {
      /*       delete formData.value2.identifier; */
    }
    let reason = self.state.formData.value2.reason
      ? self.state.formData.value2.reason
      : self.state.formData.value.reason;

    // kdv istisnası var ama kullanıcı dropdown içerisinden bir data seçmemiş ise bu hata döner
    if (this.state.reason) {
      toastr.error("KDV istisna alanını boş bırakamazsınız. !");
      self.setState({ loading: false });
      return;
    }

    if(this.props.type === 0 ){
      let eArchiveValue = formData.value;

      switch (eArchiveValue.send_info) {
        case "HICBIRI":
            delete formData.value.messenger_name
            delete formData.value.messenger_tax_identity_number
            delete formData.value.carrier_name
            delete formData.value.carrier_tax_identity_number
            break;
        case "KARGO/LOJISTIK" :
            delete formData.value.messenger_name
            delete formData.value.messenger_tax_identity
            break
        case "KISI" :
          delete formData.value.carrier_name
          delete formData.value.carrier_tax_identity
          break
      }
    }


    let params = {
      sales_invoice_id: this.props.objectId,
      receiver: receiver,
      note: value.note,
      reason: reason
    };
    if (this.props.type === 1) params.profileID = value.profileID;
    else {
      if (value.is_net === 1) params.params = value;
      else params.params = { is_net: 0 };
    }
    Api.post("/eInvoice/send", params, (response, err) => {
      if (response) {
        toastr.success("Başarılı", response.message);
        self.closeModal();
      } else {
        if (typeof err.message === "object")
          err.message.forEach(item => toastr.error("Hata", item[0]));
        else toastr.error("Hata", err.message);
      }
      self.props.fetchRefresh();
      self.setState({ loading: false, reason: [] });
    });
  }

  render() {
    console.log("FormData",this.props)
    return this.state.requiredFieldsResponse.status === "ok" ? (
      <EkoModal
        showModal={this.props.showModal}
        title={this.state.title}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <div className="row">
          <div className={"col-md-12"}>
            <EkoForm>{this.renderContent()}</EkoForm>
          </div>
        </div>
        <Modal.Footer>
          <br />
          {this.renderButtons()}
        </Modal.Footer>
      </EkoModal>
    ) : (
      this.renderGoToCompanyInfo()
    );
  }
}

function mapStateToProps(state) {
  return {
    cities: state.data.cities,
    counties: state.data.counties
  };
}

export default connect(mapStateToProps, actions)(EInvoiceModal);
