import React, {Component} from 'react';
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import axios from "axios";
import {ROOT_URL} from "../../actions";


class NaceCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            naceCodeList: [],
            newNaceCodeState: "",
            newActivityTypeState: "",
            selectBoxVal: 0,
            checkboxArr:[],
            varsayilanNaceCodeState:"",
        };
    }

    setDefaultStorage() {
        axios.get(ROOT_URL + "/company/naceCodeListSelect", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(response => {
            var data = JSON.stringify(response.data.naceCode, null, 2);
            localStorage.setItem("nace_code_list", '');
            localStorage.setItem("nace_code_list", data);
        });
    }

    fetch() {
        Api.get("/item/list", (data, errors) => {
            if (errors) {
                this.setState({dataLoading: false});
                return;
            }
            if (data.items) {
                this.setState({productList: data.items});
            }
        });
    }

    naceCodeList() {
        Api.get("/company/naceCodeList", (data, errors) => {
            if (errors) {
                this.setState({dataLoading: false});
                return;
            }
            if (data) {
                this.setState({naceCodeList: data.list});
            }
        });
    }

    componentWillMount() {
        this.fetch();
        this.naceCodeList();
    }

    add() {
        if (this.state.newNaceCodeState != '' && this.state.newActivityTypeState != '') {
            var params = {
                naceCode: this.state.newNaceCodeState,
                activityType: this.state.newActivityTypeState,
            };
            Api.post("/company/naceCodeAdd", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.setState({newNaceCodeState: "",newActivityTypeState:""});
                    this.naceCodeList();
                    this.render();
                    this.setDefaultStorage();
                    toastr.success("Başarılı", "NACE Kodu Başarıyla Güncellendi.");
                }
            });
        }else {
            toastr.error("Hata", "NACE Kodu ve Faaliyet Adı Boş Geçilemez");
        }
    }

    varsayilanAta(id) {
            var params = {
                varsayilanNaceCode: id
            };
            Api.post("/company/varsayilanNaceCodeUpdate", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.naceCodeList();
                    this.render();
                    toastr.success("Başarılı", "Varsayılan NACE Kodu Başarıyla Güncellendi.");
                }
            });
    }

    onDelete(id) {
            var params = {
                naceCodeId: id
            };
            Api.post("/company/naceCodeDelete", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.naceCodeList();
                    this.render();
                    toastr.success("Başarılı", "NACE Kodu Başarıyla Silindi.");
                }
            });
    }

    sendProducutToNaceCode(){
        if(this.state.checkboxArr.length==0){
            toastr.error("Hata!", "En Az Bir Kayıt Seçmelisiniz");
        }else{
            var params = {
                ProducutToNaceCode: this.state.checkboxArr,
                nace_code_id: this.state.selectBoxVal
            };
            Api.post("/company/sendProducutToNaceCode", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.naceCodeList();
                    this.fetch();
                    this.render();
                    toastr.success("Başarılı", "Başarıyla Güncellendi.");
                }
            });
        }

    }

    checkboxChange(id){
        var obj = this.state.checkboxArr.findIndex(o => o == id);
        if(obj==-1){
            this.state.checkboxArr.push(id);
        }else{
            this.state.checkboxArr.splice(obj);
        }
    }

    render() {
        return (<>
            <EkoForm>
                <div className="row w-100">
                    <div className="col-md-12">
                        <h2 className="h2 left">Nace Kodu İşlemleri</h2>
                        <div className={"row"}>
                            <div className={"col-md-4"}><input
                                placeholder={"NACE Kodu Girin"}
                                type="number"
                                className="form-control"
                                value={this.state.newNaceCodeState}
                                onChange={evt => this.setState({newNaceCodeState: evt.target.value})}
                                ref={ref => (this.fileInput = ref)}
                            /></div>
                            <div className={"col-md-6"}><input
                                placeholder={"Faaliyet Adını Giriniz... Örn: Kuaförlük, Restoran, Kırtasiye"}
                                type="text"
                                className="form-control"
                                value={this.state.newActivityTypeState}
                                onChange={evt => this.setState({newActivityTypeState: evt.target.value})}
                                ref={ref => (this.fileInput = ref)}
                            /></div>
                            <div className={"col-md-2"}><EkoFormButton
                                label={"Ekle"}
                                faClass="fa fa-plus"
                                onClick={() => this.add()}
                            /></div>
                        </div>
                        <hr/>

                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th className="fcolumn">Nace Kodu</th>
                                <th className="fcolumn">Faaliyet Adı</th>
                                <th className="fcolumn">İşlem</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.naceCodeList.map((item, index) => {
                                    if(item.varsayilan!=0){
                                        this.state.varsayilanNaceCodeState=item.nace_code;
                                    }
                                    return <tr key={index}>
                                        <td>{item.nace_code}</td>
                                        <td>{item.activity_type}</td>
                                        <td>
                                            <EkoFormButton
                                                label={"Varsayılan Olarak Ata"}
                                                faClass="fa fa-check"
                                                className={item.varsayilan!=0?"btn btn-danger":"btn btn-white"}
                                                disabled={item.varsayilan!=0?true:false}
                                                onClick={() => this.varsayilanAta(item.id)}
                                            />
                                            <EkoFormButton
                                                label={"Sil"}
                                                faClass="fa fa-trash"
                                                className={item.varsayilan!=0?"btn btn-danger":"btn btn-white"}
                                                disabled={item.varsayilan!=0?true:false}
                                                onClick={() => this.onDelete(item.id)}
                                            />
                                        </td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12">
                        <hr/>
                        <h2 className="h2 left">Nace Kodu Ürün Eşleştirme</h2>
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <select
                                    className="form-control"
                                    value={this.state.selectBoxVal}
                                    onChange={(e) => this.setState({selectBoxVal: e.target.value})}>
                                    <option>
                                        Seçiniz...
                                    </option>
                                    {this.state.naceCodeList.map((e, key) => {
                                        return <option
                                            key={e.id}
                                            value={e.id}>{e.nace_code+" - "+e.activity_type}</option>;
                                    })}
                                </select>
                            </div>
                            <div className={"col-md-4"}>
                                <EkoFormButton
                                    label={"Eşleştir"}
                                    faClass="fa fa-check"
                                    onClick={() => this.sendProducutToNaceCode()}
                                />
                            </div>
                        </div>
                        <div style={{"height":"25px"}}></div>
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th className="fcolumn">Ürün Kodu / Ürün Adı</th>
                                <th className="fcolumn">Seçili Nace Kodu</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.productList.map((item, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <input style={{'marginTop': '25px'}} type="checkbox"
                                                   className="form-check-input"
                                                   onClick={() => this.checkboxChange(item.id)}
                                            /> {item.item_code} {item.item_name}
                                        </td>
                                        <td>
                                            {item.nace_code_name=='(Varsayılan)'?this.state.varsayilanNaceCodeState:""}
                                            {item.nace_code_name}
                                        </td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </EkoForm>

        </>);
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(NaceCode);