import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import EkoSpinner from "../../components/elements/EkoSpinner";
import GetInModal from "../../components/forms/GetInModal";
import {
  FiArrowLeft, FiArrowLeftCircle,
  FiList,
  FiSkipBack, FiSkipForward,
} from 'react-icons/fi';

class EInBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: { eInBox: {} },
      saving: false,
      errors: {},
      formFields: {},
      eInBox: null,
      showGetIn: false,
      frameType:0
    };
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  componentWillMount() {
    const self = this;
    const id = this.props.params.id;
    Api.get("/eInBox/" + id, (response, error) => {
      if (response.eInBox) {
        let eInBox = response.eInBox;
        self.setState({ eInBox: eInBox,frameType:0 });
      } else {
        if (error.message){
          Api.get("/showEarchives/" + id, (response2, error) => {
            if (response2.eInBox) {
              let eInBox = response2.eInBox;
              self.setState({ eInBox: eInBox,frameType:1 });
            } else {
              if (error.message){
                toastr.error("Hata", error.message);
                self.props.router.push("/dashboard/purchases/eInBoxes");
              }
            }
          });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {}

  onSave(status) {
    const self = this;
    let integrator = JSON.parse(localStorage.getItem("integrator"));
    let endpoint = "";

    toastr.confirm(`Faturayı yanıtlamak istediğinize emin misiniz?`, {
      onOk: () => {
        let note = this.state.formData.eInBox.note
          ? this.state.formData.eInBox.note
          : null;
        let eInBox = this.state.eInBox;
        self.setState({ saving: true });
        let data = {};

        if (integrator === 1) {
          endpoint = "/eInvoice/sendAppResp";
          data = {
            id: eInBox.id,
            note: note,
            responseCode: status
          };
        } else if (integrator === 2) {
          endpoint = "/nes/send-app-response";
          data = {
            id: eInBox.id,
            note: note,
            responseCode: status,
            uuid: this.props.params.id
          };
        }else if (integrator === 3) {
          endpoint = "/nesv2/send-app-response";
          data = {
            id: eInBox.id,
            note: note,
            responseCode: status,
            uuid: this.props.params.id
          };
        } else {
          toastr.error("Entegratorunuz bulunamdı.");
        }

        Api.post(endpoint, data, (response, err) => {
          if (response) {
            toastr.success(response.message);
            if (status === 1) {
              eInBox.UStatus = 1;
              self.setState({ eInBox: eInBox, saving: false });
            } else {
              self.props.router.push("/dashboard/purchases/eInBoxes");
            }
          } else {
            toastr.error("Hata", err.message);
            self.setState({ saving: true });
          }
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }
  render() {
    if (!this.state.eInBox) return <EkoSpinner />;
    let eInBox = this.state.eInBox;
    const eInBoxProps = {
      onChange: this.setFieldChange.bind(this, "eInBox"),
      onMount: this.setFieldMount.bind(this, "eInBox"),
      formData: this.state.formData.eInBox,
      errors: this.state.errors.eInBox
    };
    return (
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption font-dark">
            <span  onClick={() => { this.props.router.push('/dashboard/purchases/eInBoxes') } } className="caption-subject bold"><FiArrowLeftCircle style={{"fontSize":"19px","marginRight":"5px","cursor":"pointer"}} /><span style={{"fontSize":"20px","cursor":"pointer"}}>Listeye Dön</span></span>
          </div>
        </div>
        <div style={{ height: "740px" }} className="portlet-body">
          <div className="col-md-9">
            {
              this.state.frameType === 0 ?
                  <embed
                      style={{width: "100%"}}
                      src={eInBox.url + "EFatura/EInBoxes/" + eInBox.pdf}
                      height="700"
                  /> : <iframe
                      style={{width: "100%"}}
                      src={eInBox.html}
                      height="700"
                  ></iframe>
            }
          </div>
          <div className="col-md-3">
            {parseInt(eInBox.profileID, 10) === 1 &&
            parseInt(eInBox.UStatus, 10) === 0 ? (
              <EkoForm id="eInBox">
                <div className="row">
                  <EkoFormButton
                    label="KABUL ET"
                    className={"form-control colorWhite btn btn-success"}
                    isLoading={this.state.saving}
                    onClick={() => this.onSave(1)}
                  />
                </div>
                <br />
                <div className="row">
                  <EkoFormButton
                    label="REDDET"
                    className={"form-control colorWhite btn btn-danger"}
                    isLoading={this.state.saving}
                    onClick={() => this.onSave(0)}
                  />
                </div>
                <div className="row">
                  <EkoFormInputText
                    {...eInBoxProps}
                    id="note"
                    label="Not"
                    type="textarea"
                    isVertical={true}
                  />
                </div>
              </EkoForm>
            ) : (
              <EkoForm id="eInBox">
                <div className="row">
                  <EkoFormButton
                    label="Kayıtlara İşle"
                    isLoading={this.state.saving}
                    className={"form-control colorWhite btn btn-info"}
                    onClick={() => this.setState({ showGetIn: true })}
                  />
                </div>
              </EkoForm>
            )}
          </div>
        </div>
        <GetInModal
          showModal={this.state.showGetIn}
          closeModal={() => this.setState({ showGetIn: false })}
          uuid={this.props.params.id}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(EInBox);
