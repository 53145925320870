import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormSwitch,
  EkoFormDate
} from "../../components/elements/EkoForm";
import { Modal, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import { FETCH_OTHER_RECEIPT_AUTOCOMPLETE } from "../../actions/types";
import Util from "../../util/Util";
import Permitted from "../../services/Permitted";
import moment from "moment";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      showModal: false,
      detailIsOpened: false,
      gercekTuzel: true,
      counties: [],
      saving: false,
      errors: {},
      activeTab: "address",
      formFields: {},
      savingVkn: false,
      vknLoad: false,
      copy_record:false,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:16,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
    //  this.onCancel = this.onCancel.bind(this);
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = (formData) => {
    formData = formData || this.state.formData;
    if (formData.supplier.id) {
      Api.get("/company/newTagRecordDocumentList/16/" + formData.supplier.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }else{
          this.resetNewTagModalList();
        }
      });
    }else{
      this.resetNewTagModalList();
    }
  }
  resetNewTagModalList = () => {
    this.setState(prevState => ({
      newTagRows: {
        ...prevState.newTagRows,
        rows: [
          { id:null,new_tag_type_id:16,new_tag_id: "",record_id:null, rate: "%100,00" }
        ],
      },
    }));
  }
  defaultFormData() {
    return {
      supplier: {
        currency: "TRY",
        opening_date: moment().format("YYYY-MM-DD"),
        amount_type:0,
        company_type:1
      },
      contact: {},
      address: {}
    };
  }

  static getDefaultForm() {
    return {
      supplier: {
        currency: "TRY"
      },
      contact: {},
      address: {}
    };
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};

    this.setState({ formData: formData, errors: errors });
    this.props.fetchData(
      "/otherReceipt/autoComplete",
      FETCH_OTHER_RECEIPT_AUTOCOMPLETE
    );
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    let errors = nextProps.errors || {};
    this.getNewTagModalList(formData);
    this.setState({
      formData: formData,
      errors: errors,
      showModal: nextProps.showModal
    });
    this.decideTab(nextProps.detailIsOpened);
  }

  decideTab(isOpened) {
    let activeTab = "address";
    let detailIsOpened = isOpened || this.state.detailIsOpened;

    if (this.props.activeTab) {
      activeTab = this.props.activeTab;
    } else {
      if (!Sugar.Object.isEmpty(this.state.errors.address)) {
        activeTab = "address";
        detailIsOpened = true;
      } else if (!Sugar.Object.isEmpty(this.state.errors.contact)) {
        activeTab = "contact";
        detailIsOpened = true;
      }
    }
    this.setState({ activeTab: activeTab, detailIsOpened: detailIsOpened });
  }

  toggleDetail(state) {
    this.setState({ detailIsOpened: state });
  }
  toggleDetail2(state) {
    if(state){

    }
    this.setState({ gercekTuzel: state });
  }
  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada supplier.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    if (field === "city_id") {
      if (formData[field] !== value) {
        formData["county_id"] = null;
      }
    }
    if (field === "opening_balance") {
      if(formData['amount_type']==0){
        //borcum da alacağım da yok
        value = Util.convertDecimalToMoney(0);
      }else if(formData['amount_type']==1){
        //borcum var
        if(value>0){
          value = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(value)));
        }
      }else if(formData['amount_type']==2){
        //alacağım var
        if(value<0) {
          value = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(value)));
        }
      }
    }
    if (field === "balance") {
      if(formData['amount_type']==0){
        //borcum da alacağım da yok
        value = Util.convertDecimalToMoney(0);
      }else if(formData['amount_type']==1){
        //borcum var
        if(Util.convertMoneyToDecimal(value)>0) {
          value = -1 * value;
        }
      }else if(formData['amount_type']==2){
        //alacağım var
        if(Util.convertMoneyToDecimal(value)<0) {
          value = -1 * value;
        }
      }
    }
    if (field === "amount_type") {
      if (formData.id) {
        if(value==0){
          //borcum da alacağım da yok
          formData['opening_balance'] = Util.convertDecimalToMoney(0);
        }else if(value==1){
          //borcum var
          if(Util.convertMoneyToDecimal(formData['opening_balance'])>0) {
            formData['opening_balance'] = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(formData['opening_balance'])));
          }
        }else if(value==2){
          //alacağım var
          if(Util.convertMoneyToDecimal(formData['opening_balance'])<0) {
            formData['opening_balance'] = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(formData['opening_balance'])));
          }
        }
      }else{
        if(value==0){
          //borcum da alacağım da yok
          formData['balance'] = Util.convertDecimalToMoney(0);
        }else if(value==1){
          //borcum var
          if(Util.convertMoneyToDecimal(formData['balance'])>0) {
            formData['balance'] = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(formData['balance'])));
          }
        }else if(value==2){
          //alacağım var
          if(Util.convertMoneyToDecimal(formData['balance'])<0) {
            formData['balance'] = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(formData['balance'])));
          }
        }
      }
    }
    if (field === "company_type") {
      if (value === 2) {
        formData['tax_identity_number'] = "2222222222";
      }
      if (value === 0) {
        this.state.gercekTuzel = false;
      } else {
        this.state.gercekTuzel = true;
      }
    }
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = {
      ...this.state.formData,
      [type]: {
        ...formData
      }
    };

    var title = JSON.parse(localStorage.getItem("user"));

    let company_id = title["company_id"];

    if (field === "tax_identity_number") {
      let supplier_tax_identity_number = this.state.formData.supplier
        .tax_identity_number;

      let tax_identity_number_length = supplier_tax_identity_number.length;

      if (tax_identity_number_length >= 10) {
        Api.get("/check-supplier/" + supplier_tax_identity_number, function(
          response
        ) {
          if (response.status === "fail") {
            toastr.warning(response.message);
          }
        });
      }
    }

    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }
  cleanString(str) {
    if(str !==undefined){
      str = str.replace(/&/g, '');
      str = str.replace(/%/g, '');
    }
    return str;
  }
  onSave() {
    var self = this;
    let formData = this.state.formData;
    console.log("save called");

    if (this.state.formData.contact.phone) {
      let phone = Util.deleteAllExceptNumbers(
        this.state.formData.contact.phone || ""
      );
      let contact = Object.assign({}, self.state.formData.contact, {
        phone: phone
      });
      formData.contact = contact;
    }
    self.setState({ saving: true });
    let validationRules = [];
    let postRules = [];
    //NOTE @bocek hangi form kisimlairni, hangi validationa gonderecegiz tanimliyoruz,
    //buradaki keyler AddSupplier formundaki formData ile aynı olmasi gerek
    if (formData.address.address || formData.address.city_id)
      validationRules.push({
        key: "address",
        url: "/supplierAddress/validation"
      });
    validationRules.push({
      key: "contact",
      url: "/supplierContact/validation"
    });

    //pustRules.push({key:"supplier",url:"/supplier"});
    if (formData.address.address || formData.address.city_id)
      postRules.push({
        key: "address",
        url: "/supplierAddress"
      });
    postRules.push({ key: "contact", url: "/supplierContact" });

    if (formData.address.is_abroad) {
      formData.address.city_id = null;
      formData.address.county_id = null;
      formData.address.is_abroad = 1;
    } else formData.address.is_abroad = 0;
    formData.supplier.newTagRows=this.state.newTagRows;
    // axios.all(['/x/valida','yvadalida']).
    HelperContainer.validateContainerData(
      self.props,
      validationRules,
      formData,
      self.state.errors,
      function(validated, errors, validationFields) {
        if (validated) {
          formData.supplier.balance = Util.convertMoneyToDecimal(
            formData.supplier.balance || "0,00"
          );
          let func = "post";
          let url = "/supplier";
          if (formData.supplier.id) {
            func = "put";
            url = "/supplier/" + formData.supplier.id;
            formData.supplier.opening_balance = Util.convertMoneyToDecimal(
              formData.supplier.opening_balance || "0,00"
            );
          } else {
            formData.supplier.opening_balance = formData.supplier.balance;
            delete formData.supplier.balance;
          }

          formData.supplier.firstname = self.cleanString(formData.supplier.firstname);
          formData.supplier.lastname = self.cleanString(formData.supplier.lastname);
          Api[func](url, formData.supplier, function(response, postErrors) {
            if (postErrors) {
              if (postErrors.message) {
                toastr.error("Hata", postErrors.message);
                errors.supplier = {};
              } else {
                errors.supplier = postErrors;
              }

              errors.address = {};
              self.setState({ saving: false, errors: errors });
              self.decideTab();
            } else {
              formData.address["supplier_id"] = response.supplier.id;
              formData.contact["supplier_id"] = response.supplier.id;
              formData.contact["is_main_contact"] = 1;
              formData.address["address_type"] = 2; // fatura adres
              HelperContainer.postContainerData(
                self.props,
                validationFields,
                postRules,
                formData,
                self.state.errors,
                function(validated, postErrors) {
                  if (postErrors.hasOwnProperty("sysError")) {
                    toastr.error(
                      "Hata",
                      "Genel bir hata oluştu, lütfen daha sonra deneyiniz"
                    );
                  } else {
                    if(!self.state.formData.supplier.id){
                      if(self.state.copy_record===true){
                        var postRulesCopy = postRules;
                        postRulesCopy.map((element,index) => {
                          if(postRulesCopy[index].url=='/supplierContact'){
                            postRulesCopy[index].url = '/customerContact';
                          }
                          if(postRulesCopy[index].url=='/supplier'){
                            postRulesCopy[index].url = '/customer';
                          }
                          if(postRulesCopy[index].url=='/supplierAddress'){
                            postRulesCopy[index].url = '/customerAddress';
                          }
                        });
                        formData.supplier.opening_balance = 0;
                        formData.supplier.amount_type = 0;
                        Api[func]("/customer", formData.supplier, function (response, postErrors) {
                          formData.address["customer_id"] = response.customer.id;
                          formData.contact["customer_id"] = response.customer.id;
                          formData.contact["is_main_contact"] = 1;
                          formData.address["address_type"] = 2; // fatura adres
                          console.log('formData',formData);
                          HelperContainer.postContainerData(
                              self.props,
                              validationFields,
                              postRulesCopy,
                              formData,
                              self.state.errors,
                              function (validated, postErrors) {}
                          );
                        })
                      }
                    }
                    self.setState({
                      saving: false,
                      errors: postErrors,
                      formData: self.defaultFormData()
                    });
                    self.decideTab();
                    toastr.success("Başarılı", "Başarıyla Kaydedildi");
                    self.props.onSave(response.supplier.id);
                  }
                }
              );
            }
          });
        } else {
          if (errors.hasOwnProperty("sysError")) {
            toastr.error(
              "Hata",
              "Genel bir hata oluştu, lütfen daha sonra deneyiniz"
            );
          } else {
            self.setState({ saving: false, errors: errors });
            self.decideTab();
          }
          //toastr.error("Lütfen Kontrol Ediniz","",{component:<EkoToastrError errors={errors}></EkoToastrError>});
          //  self.setState({saving:false,errors:errors});
        }
      }
    );
  }

  onCancel() {
    this.resetNewTagModalList();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
  copyRecord(e) {
    this.setState({copy_record:e.target.checked});
  }
  newItem(val) {
    console.log(val);
  }

  handleTabSelect(key) {
    this.setState({ activeTab: key });
  }

  abroadChange(e) {
    this.setFieldChange("address", "is_abroad", e.target.checked);
  }

  queryVkn() {
    const self = this;
    let formData = this.state.formData;
    let no = this.state.formData.supplier.tax_identity_number;
    let noApi = { no: this.state.formData.supplier.tax_identity_number };

    self.setState({ savingVkn: true });

    if (!no || no.length <= 9) {
      toastr.error("Lütfen sorgulama için 10 veya 11 karakter giriniz");
      self.setState({ savingVkn: false });
    } else {
      Api.post("/notary/query", noApi, function(response, error) {
        if (response[0]) {
          if (no.length === 11) {
            let address =
              response[0].GercekSahisMukellefMerkezBilgiSorguResult
                .IkametgahAdresi;
            formData.supplier.firstname = response[0].company_info.firstname;
            formData.supplier.lastname = response[0].company_info.lastname;
            formData.address.address =
              address.MahalleSemt +
              " " +
              address.CaddeSokak +
              " " +
              address.KapiNO +
              "/" +
              address.DaireNO +
              " - " +
              address.IlAdi;
            formData.address.city_id = parseInt(
              response[0].company_info.Address.city_id
            );
            formData.address.county_id =
              response[0].company_info.Address.county_id;
            formData.supplier.no =
              response[0].GercekSahisMukellefMerkezBilgiSorguResult.SorguKimlikNO;
          } else if (no.length === 10) {
            console.log(response[0]);
            formData.supplier.firstname = response[0].company_info.firstname;
            formData.supplier.lastname = response[0].company_info.lastname;
            formData.address.address = response[0].company_info.Address.address;
            formData.address.city_id = parseInt(
              response[0].company_info.Address.city_id
            );
            formData.address.county_id =
              response[0].company_info.Address.county_id;
            formData.supplier.no =
              response[0].TuzelSahisMukellefMerkezBilgiSorguResult.SorguKimlikNO;
          } else {
            toastr.error("Bir hata oluştu");
          }

          self.setState({ savingVkn: false });
        } else if (response.status === "fail") {
          toastr.error(response.message);
          self.setState({ savingVkn: false });
        } else {
          toastr.error("Beklenmedik bir hata oluştu");
          self.setState({ savingVkn: false });
        }
      });
    }
  }

  onVknSearch() {

    const self = this;
    let formData = self.state.formData;
    self.setState({vknLoad: true});
    Api.post('/customer/searchVkn', {identity: formData.supplier?.tax_identity_number}, function(response, error) {
    self.setState({vknLoad: false});
      if(error){
        self.setState({
          errors: {
            ...self.state.errors,
            supplier: {
              ...self.state.errors.supplier,
              tax_identity_number:
                "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
            },
          },
        });
      } else {
        const info = response.data?.info || {};
        const taxOffice = response.data?.taxOffice || {};
        const c = formData.supplier;
        const gercekTuzel = !!info?.gercekTuzel;
        if(gercekTuzel){
          //gerçek kişi
          self.state.formData.supplier.company_type = 1;
        }else{
          //aş ltd
          self.state.formData.supplier.company_type = 0;
        }
        if (info?.companyName) {
          self.setState({
            errors: {
              ...self.state.errors,
              supplier: {
                ...self.state.errors.supplier,
                tax_identity_number: null,
              },
            },
          });
        self.setState({
          detailIsOpened: true,
          gercekTuzel,
          formData: {
            ...formData, 
            supplier: {
              ...formData.supplier, 
              firstname: info?.firstname || c.firstname, 
              lastname: info?.lastname || c.lastname || "", 
              tax_office_code: taxOffice?.code || c.tax_office_code
            },
            address: {
              ...formData.address,
              address: info.address || formData.address.address,
              city_id: info.city_id || formData.address.city_id,
              county_id: info.county_id || formData.address.county_id,
            }
          }
        })
      } else {
        self.setState({
          errors: {
            ...self.state.errors,
            supplier: {
              ...self.state.errors.supplier,
              tax_identity_number:
                "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
            },
          },
        });
      }
      }
    })
  }

  render() {
    const company_type_list = [
      { id: 0, name: "A.Ş. / L.T.D." },
      { id: 1, name: "Gerçek Kişi" },
      { id: 2, name: "Yurtdışı Satıcı" },
    ];
    const activeTab = this.state.activeTab;
    const supplierProps = {
      onChange: this.setFieldChange.bind(this, "supplier"),
      onMount: this.setFieldMount.bind(this, "supplier"),
      formData: this.state.formData.supplier,
      errors: this.state.errors.supplier
    };

    const addressProps = {
      onChange: this.setFieldChange.bind(this, "address"),
      onMount: this.setFieldMount.bind(this, "address"),
      formData: this.state.formData.address,
      errors: this.state.errors.address
    };

    const contactProps = {
      onChange: this.setFieldChange.bind(this, "contact"),
      onMount: this.setFieldMount.bind(this, "contact"),
      formData: this.state.formData.contact,
      errors: this.state.errors.contact
    };

    const taxOffices = this.props.taxOffices
      ? this.props.taxOffices.map(function(obj) {
          if (obj && obj.city) {
            obj.full_name = obj.name + " - " + obj.city.name;
          }
          return obj;
        })
      : [];
    if(this.state.formData.supplier.id){
      if(this.state.formData.supplier.company_type===0){
        this.state.gercekTuzel=false;
      }else{
        this.state.gercekTuzel=true;
      }
    }
    const selectedCity = this.state.formData.address.city_id;

    const counties = this.props.counties
      ? this.props.counties.filter(function(obj) {
          return obj.city_id === selectedCity;
        })
      : [];

    const displayDetail = this.state.detailIsOpened ? "" : "none";
    const gercekTuzelDetail = this.state.gercekTuzel==true ? "" : "none";
    var amount_type_select = JSON.parse('[ { "id": 0, "name": "Alacağım, Borcum Yok" },{ "id": 1, "name": "Borcum Var" },{ "id": 2, "name": "Alacağım Var" } ]');
    return (
      <div>
        <EkoModal
            showModal={this.state.showModal}
            title={
              this.state.formData.supplier && this.state.formData.supplier.id
                  ? "Tedarikçi Güncelle"
                  : "Tedarikçi Ekle"
            }
            onHide={() => {
              this.setState({
                showModal: false,
                formData: this.defaultFormData(),
                errors: {}
              });
              this.onCancel();
            }}
            spinner={this.state.saving}
        >
          <EkoForm
              noPermit={!Permitted.isPermitted("13")}
              formId={"add_supplier_modal"}
          >
            <div className="row">
              <div className="col-md-10">
                <EkoFormInputText
                    {...supplierProps}
                    labelMd={6}
                    colMd={6}
                    mask="99999999999"
                    maskChar=""
                    type="mask"
                    label="Vergi No / TC Kimlik No"
                    id="tax_identity_number"
                    // queryVkn={this.queryVkn.bind(this)}
                />
              </div>
              <div className="col-md-2">
                <EkoFormButton
                    label="Sorgula"
                    // faClass="fa fa-plus"
                    isLoading={this.state.vknLoad}
                    onClick={this.onVknSearch.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5" style={{"textAlign": "right"}}>Şirket Tipi</div>
              <div className="col-md-6">
                <EkoFormSelect
                    {...supplierProps}
                    id="company_type"
                    isVertical
                    optionValue={"name"}
                    optionId={"id"}
                    defaultText="Seçiniz"
                    options={company_type_list}
                    searchable={true}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <EkoFormInputText
                {...supplierProps} //formData.supplier.firrsname =
                label="Adı / Firma Ünvanı"
                id="firstname" //errors.supplier.firstname = bla bla hatasi
                required={true}
            />
            <div style={{display: gercekTuzelDetail}}>
              <EkoFormInputText
                  {...supplierProps}
                  label="Soyadı / Firma Ünvanı Devamı"
                  id="lastname"
              />
            </div>
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7">
                {!this.state.formData.supplier.id ? (<div><input
                    className="form-check-input"
                    onChange={this.copyRecord.bind(this)}
                    type="checkbox"
                />&nbsp;Tedarikçi Kartını Müşteri Olarak da Aç</div>) : ("")}
              </div>
            </div>
            <div className="row buttonProccess2">
              <div className="col-md-3">
                <EkoFormDate
                    {...supplierProps}
                    id="opening_date"
                    labelMd={8}
                    colMd={4}
                    dateFormat="DD-MM-YYYY"
                    isVertical={true}
                    clearable={false}
                    label="Bakiye Tarihi"
                />
              </div>
              <div className="col-md-4">
                <EkoFormSelect
                    {...supplierProps}
                    id="amount_type"
                    optionValue={"name"}
                    optionId={"id"}
                    defaultText="Seçiniz"
                    options={amount_type_select}
                    label="Bakiye Tipi"
                    isVertical
                />
              </div>
              <div className="col-md-3">
                {this.state.formData.supplier.id ? (
                    <EkoFormInputText
                        labelMd={8}
                        colMd={4}
                        {...supplierProps}
                        label={"Açılış Bakiyesi"}
                        id="opening_balance"
                        type="money"
                        placeHolder="0,00"
                        disabled={this.state.formData.supplier && this.state.formData.supplier.amount_type == 0 ? true : false}
                        isVertical
                    />
                ) : (
                    <EkoFormInputText
                        {...supplierProps}
                        labelMd={8}
                        colMd={4}
                        label={"Açılış Bakiyesi"}
                        id="balance"
                        type="money"
                        placeHolder="0,00"
                        disabled={this.state.formData.supplier && this.state.formData.supplier.amount_type == 0 ? true : false}
                        isVertical
                    />
                )}
              </div>

              <div className="col-md-2">
                <EkoFormSelect
                    {...supplierProps}
                    id="currency"
                    isVertical
                    optionValue={"code"}
                    optionId={"code"}
                    defaultText="Seçiniz"
                    options={this.props.currencies}
                    disabled={this.state.formData.supplier && this.state.formData.supplier.id ? true : false}
                    label="Döviz Tipi"
                />
              </div>
            </div>
            <EkoFormSwitch
                onChange={this.toggleDetail.bind(this)}
                value={this.state.detailIsOpened}
                label="Gelişmiş Seçenekler"
                leftBox={false}
            />

            <div style={{display: displayDetail}}>
              <div className="portlet-body">
                <Tab.Container
                    id="detailTab"
                    onSelect={this.handleTabSelect}
                    activeKey={activeTab}
                >
                  <Row className="clearfix">
                    <Nav bsStyle="tabs">
                      <NavItem
                          eventKey="address"
                          onClick={() => this.handleTabSelect("address")}
                      >
                        Fatura Adresi Bilgileri
                      </NavItem>
                      <NavItem
                          eventKey="supplier"
                          onClick={() => this.handleTabSelect("supplier")}
                      >
                        Ödeme Bilgileri
                      </NavItem>
                      <NavItem
                          eventKey="contact"
                          onClick={() => this.handleTabSelect("contact")}
                      >
                        İletişim
                      </NavItem>
                    </Nav>
                    <Tab.Content animation>
                      <Tab.Pane eventKey="address">
                        <EkoFormSelect
                            {...supplierProps}
                            searchable={true}
                            id="tax_office_code"
                            clearable={true}
                            optionValue="full_name"
                            options={taxOffices}
                            optionId="code"
                            label="Vergi Dairesi Adı"
                        />
                        <EkoFormInputText
                            {...addressProps}
                            label="Adres" //formData.address.address
                            id="address"
                            type="textarea"
                            childrenShow
                            childValue={this.state.formData.address.is_abroad}
                        >
                          <label
                              className="form-check-label border"
                              style={
                                this.state.formData.address.is_abroad
                                    ? {
                                      display: "block",
                                      borderColor: "#ccc",
                                      padding: ".5em",
                                      borderRadius: "0 0 3px 3px",
                                      fontWeight: "bold",
                                      fontSize: "small"
                                    }
                                    : {
                                      display: "block",
                                      borderColor: "#ccc",
                                      padding: ".5em",
                                      borderRadius: "0 0 3px 3px",
                                      fontSize: "small"
                                    }
                              }
                          >
                            <input
                                className="form-check-input"
                                checked={
                                    this.state.formData.address &&
                                    this.state.formData.address.is_abroad
                                }
                                onChange={this.abroadChange.bind(this)}
                                type="checkbox"
                            />
                            &nbsp;Adresi yurtdışında
                          </label>
                        </EkoFormInputText>
                        {this.state.formData.address.is_abroad ? (
                            ""
                        ) : (
                            <span>
                          <EkoFormSelect
                              {...addressProps}
                              searchable={true}
                              id="city_id" //formData.address.city_id
                              clearable={true}
                              optionValue="name"
                              defaultText="Seçiniz"
                              options={this.props.cities}
                              label="İl"
                          />
                          <EkoFormSelect
                              {...addressProps}
                              searchable={true}
                              id="county_id"
                              clearable={true}
                              optionValue="name"
                              defaultText="Seçiniz"
                              options={counties}
                              label="İlçe"
                          />
                        </span>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="supplier">
                        <EkoFormSelect
                            {...supplierProps}
                            searchable={false}
                            id="due_date_calculation_type"
                            clearable={true}
                            defaultText="Seçiniz"
                            options={
                                this.props.viewOptions &&
                                this.props.viewOptions.suppliers &&
                                this.props.viewOptions.suppliers
                                    .due_date_calculation_type
                            }
                            label="Vade Hesaplama Metodu"
                        />
                        <EkoFormInputText
                            {...supplierProps}
                            type="number"
                            label="Vade Günü"
                            id="due_days"
                        />
                        <EkoFormInputText
                            {...supplierProps}
                            type="text"
                            label="IBAN"
                            id="iban"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact">
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Ad"
                            tooltipId="contactFirstname"
                            id="firstname"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Soyad"
                            tooltipId="contactLastname"
                            id="lastname"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            label="Telefon"
                            id="phone"
                            mask="(999) 999 99 9999999"
                            maskChar=""
                            type="mask"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Email"
                            id="email"
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </div>
            </div>
            <Modal.Footer>
              <div>
                <div className="col-md-12">
                  <EkoFormButton
                      label="Kaydet"
                      faClass="fa fa-plus"
                      isLoading={this.state.saving}
                      onClick={this.onSave.bind(this)}
                  />
                  <EkoFormButton
                      label="İptal"
                      className="btn"
                      onClick={this.onCancel.bind(this)}
                  />
                </div>
              </div>
              <div style={{
                "backgroundColor": "#efefef",
                height: "1px",
                "marginTop": "50px",
                "marginBottom": "10px"
              }}></div>
              <div style={{"textAlign": "left"}}>
                <a onClick={this.openNewTagModal}>Etiket Ekle</a>
              </div>
            </Modal.Footer>
          </EkoForm>
        </EkoModal>
        <NewTagDocumentModal
            newTagRows={this.state.newTagRows}
            saveNewTag={this.saveNewTag}
            showNewTagModal={this.state.showNewTagModal}
            closeNewTagModal={this.closeNewTagModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    taxOffices: state.data.taxOffices,
    cities: state.data.cities,
    counties: state.data.counties,
    currencies: state.data.currencies,
    viewOptions: state.data.viewOptions,
    otherReceiptsAutoComplete: state.data.otherReceiptAutoComplete
  };
}

export default connect(mapStateToProps, actions)(AddSupplier);
