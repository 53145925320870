//NOTE GENCLER tum fetch islemleri burada,
import {
    FETCH_ASSET_TYPES,
    FETCH_ASSETS,
    FETCH_BANK_ACCOUNTS,
    FETCH_BANK_ACCOUNTS_ALL,
    FETCH_BANK_TRANSACTION,
    FETCH_CHEQUES,
    FETCH_CHEQUES_ALL,
    FETCH_CITIES,
    FETCH_COLLECTIONS,
    FETCH_COMPANY,
    FETCH_COMPANY_ALL,
    FETCH_COUNTIES,
    FETCH_CURRENCIES,
    FETCH_CUSTOMER_TRANSACTION,
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_ALL,
    FETCH_DT_CONFIGS,
    FETCH_EMPLOYEE,
    FETCH_EMPLOYEE_SALARY,
    FETCH_EMPLOYEE_SALARIES,
    FETCH_EMPLOYEE_TRANSACTION,
    FETCH_EMPLOYEES,
    FETCH_EMPLOYEES_ALL,
    FETCH_EXPENSE_CATEGORIES,
    FETCH_INSURANCE_POLICIES,
    FETCH_INVITATIONS,
    FETCH_ITEMS,
    FETCH_ITEMS_ALL,
    FETCH_LOANS,
    FETCH_OTHER_RECEIPT_AUTOCOMPLETE,
    FETCH_OTHER_RECEIPTS,
    FETCH_PAYMENTS,
    FETCH_PAYROLL_STATUSES,
    FETCH_PLAN_CHECK,
    FETCH_PLANS,
    FETCH_PROJECTS,
    FETCH_PIE_CHART_VALUES,
    FETCH_PURCHASE_CHART_VALUES,
    FETCH_PURCHASE_INVOICES,
    FETCH_RECEIPTS,
    FETCH_REPORTS,
    FETCH_ROLES,
    FETCH_SALARY_PREVIEW,
    FETCH_SALES_CHART_VALUES,
    FETCH_SALES_INVOICE,
    FETCH_SOLE_INVOICE_AUTOCOMPLETE,
    FETCH_SOLE_PROF_SALES_INVOICES,
    FETCH_STATEMENT_DUES,
    FETCH_STATEMENT_TYPES,
    FETCH_DECLARATION_TYPES,
    FETCH_STOPPAGE_TYPES,
    FETCH_ITEM_TRANSACTION,
    FETCH_SUPPLIER_TRANSACTION,
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_ALL,
    FETCH_TAX_OFFICES,
    FETCH_TAX_TYPES,
    FETCH_DELIVERY_TYPES,
    FETCH_TRANSPORT_TYPES,
    FETCH_PACKAGE_TYPES,
    FETCH_TEMPLATES,
    FETCH_UNITS,
    FETCH_USER,
    FETCH_VAT_RATE_TYPES,
    FETCH_VAT_REDUCTION_TYPE_CODES,
    FETCH_VAT_REDUCTION_TYPES,
    FETCH_VIDEO_CONFIGS,
    FETCH_VIDEO_DATAS,
    FETCH_TABLE_DATA,
    FETCH_TRANSFERS,
    FETCH_DBS_PURCHASES,
    FETCH_DBS_SALES,
    FETCH_DBS_Z_SALES,
    FETCH_VIEW_OPTIONS,
    FETCH_ZREPORTS,
    FETCH_ZREPORT,
    FETCH_E_IN_BOXES,
    FETCH_PROFORMAS,
    FETCH_FIXED_ASSET,
    FETCH_SOLEPROFV2,
    FETCH_OTHER_SALES_LIST,
    FETCH_OTHER_SALES,
    FETCH_EXPENSE_TEMPLATES,
    FETCH_WAREHOUSE_TRANSACTIONS
} from '../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_DT_CONFIGS:
            return {...state, dtConfig: action.payload}
        case FETCH_VIEW_OPTIONS:
            return {...state, viewOptions: action.payload}
        case FETCH_TAX_OFFICES:
            return {...state, taxOffices: action.payload}
        case FETCH_EMPLOYEE:
            return {...state, employee: action.payload};
        case FETCH_CITIES:
            return {...state, cities: action.payload}
        case FETCH_COUNTIES :
            return {...state, counties: action.payload}
        case FETCH_CURRENCIES :
            return {...state, currencies: action.payload.currencies}
        case FETCH_UNITS :
            return {...state, units: action.payload.units}
        case FETCH_TAX_TYPES :
            return {...state, taxTypes: action.payload.taxTypes}
        case FETCH_DELIVERY_TYPES :
            return {...state, deliveryTypes: action.payload.deliveryTypes}
        case FETCH_TRANSPORT_TYPES :
            return {...state, transportTypes: action.payload.transportTypes}
        case FETCH_PACKAGE_TYPES :
            return {...state, packageTypes: action.payload.packageTypes}
        case FETCH_VAT_RATE_TYPES:
            return {...state, vatRateTypes: action.payload.vatRateTypes}
        case FETCH_ASSET_TYPES:
            return {...state, fixedAssetTypes: action.payload.fixedAssetTypes}
        case FETCH_COLLECTIONS:
            return {...state, collection: action.payload.collections}
        case FETCH_PAYMENTS:
            return {...state, payments: action.payload.payments}
        case FETCH_SUPPLIERS:
            return {...state, suppliers: action.payload.suppliers}
        case FETCH_SUPPLIERS_ALL:
            return {...state, suppliersAll: action.payload.suppliers}
        case FETCH_CUSTOMERS_ALL:
            return {...state, customersAll: action.payload.customers}
        case FETCH_EMPLOYEES_ALL:
            return {...state, employeesAll: action.payload.employees}
        case FETCH_BANK_ACCOUNTS:
            return {...state, bankAccounts: action.payload.bankAccounts}
        case FETCH_BANK_ACCOUNTS_ALL:
            return {...state, bankAccountsAll: action.payload.bankAccounts}
        case FETCH_CHEQUES_ALL:
            return {...state, chequesAll: action.payload.cheques}
        case FETCH_CUSTOMERS:
            return {...state, customers: action.payload.customers}
        case FETCH_CHEQUES:
            return {...state, cheques: action.payload.cheques}
        case FETCH_LOANS:
            return {...state, loans: action.payload.loans}
        case FETCH_COMPANY:
            return {...state, company: action.payload.company}
        case FETCH_COMPANY_ALL:
            return {...state, companies: action.payload.companies}
        case FETCH_OTHER_RECEIPT_AUTOCOMPLETE :
            return {...state, otherReceiptAutoComplete: action.payload.otherReceipts}
        case FETCH_EMPLOYEES :
            return {...state, employees: action.payload.employees}
        case FETCH_PAYROLL_STATUSES :
            return {...state, payrollStatuses: action.payload.payrollStatuses}
        case FETCH_ITEMS:
            return {...state, items: action.payload.items}
        case FETCH_ITEMS_ALL:
            return {...state, itemsAll: action.payload.items}
        case FETCH_ITEM_TRANSACTION:
            return {...state, itemTransactions: action.payload}
        case FETCH_WAREHOUSE_TRANSACTIONS:
            return { ...state, warehousesTransactions: action.payload.warehousesTransactions };
        case FETCH_ASSETS:
            return {...state, assets: action.payload.fixedAssets}
        case FETCH_EXPENSE_CATEGORIES:
            return {...state, expenseCategories: action.payload.expenseCategories}
        case FETCH_EXPENSE_TEMPLATES:
            return {...state, expenseTemplates: action.payload.expenseTemplates}
        case FETCH_STOPPAGE_TYPES:
            return {...state, stoppageTypes: action.payload.stoppageTypes}
        case FETCH_VAT_REDUCTION_TYPES:
            return {...state, vatReductionTypes: action.payload.vatReductionTypes}
        case FETCH_VAT_REDUCTION_TYPE_CODES:
            return {...state, vatReductionTypeCodes: action.payload.vatReductionTypeCodes}
        case FETCH_SOLE_INVOICE_AUTOCOMPLETE:
            return {...state, soleProfSalesInvoices: action.payload.soleProfSalesInvoices}
        case FETCH_PURCHASE_INVOICES:
            return {...state, purchaseInvoices: action.payload}
        case FETCH_PROFORMAS:
            return {...state, proformas: action.payload}
        case FETCH_FIXED_ASSET:
            return {...state, fixedAsset: action.payload}
        case FETCH_SOLEPROFV2:
            return {...state, soleprofv2s: action.payload}
        case FETCH_RECEIPTS:
            return {...state, receipts: action.payload}
        case FETCH_OTHER_RECEIPTS:
            return {...state, otherReceipts: action.payload}
        case FETCH_INSURANCE_POLICIES:
            return {...state, insurancePolicies: action.payload}
        case FETCH_SOLE_PROF_SALES_INVOICES:
            return {...state, soleProfSalesInvoices: action.payload.soleProfSalesInvoices}
        case FETCH_PROJECTS:
            return {...state, projects: action.payload.projects}
        case FETCH_TRANSFERS:
            return {...state, transfers: action.payload.internalTransfer}
        case FETCH_DBS_PURCHASES:
            return {...state, dbspurchases: action.payload.dbspurchases}
        case FETCH_DBS_SALES:
            return {...state, dbssales: action.payload.dbssales}
        case FETCH_DBS_Z_SALES:
            return {...state, dbsZsales: action.payload.dbsZsales}
        case FETCH_CUSTOMER_TRANSACTION:
            return {...state, customerTransactions: action.payload.customerTransactions}
        case FETCH_SUPPLIER_TRANSACTION:
            return {...state, supplierTransactions: action.payload.supplierTransactions}
        case FETCH_EMPLOYEE_TRANSACTION:
            return {...state, employeeTransactions: action.payload.employeeTransactions}
        case FETCH_BANK_TRANSACTION:
            return {...state, bankTransactions: action.payload.bankTransactions}
        case FETCH_INVITATIONS:
            return {...state, invitations: action.payload.invitations}
        case FETCH_SALES_INVOICE:
            return {...state, salesInvoices: action.payload}
        case FETCH_ROLES:
            return {...state, roles: action.payload.roles}
        case FETCH_EMPLOYEE_SALARIES:
            return {...state, employeeSalaries: action.payload.employeeSalaries}
        case FETCH_EMPLOYEE_SALARY:
            return {...state, employeeSalary: action.payload.employeeSalary}
        case FETCH_SALARY_PREVIEW:
            return {...state, salary: action.payload.salary};
        case FETCH_USER:
            return {...state, user: action.payload.user}
        case FETCH_SALES_CHART_VALUES:
            return {...state, salesChartValues: action.payload}
        case FETCH_PURCHASE_CHART_VALUES:
            return {...state, purchaseChartValues: action.payload}
        case FETCH_PIE_CHART_VALUES:
            return {...state, pieChartValues: action.payload}
        case FETCH_STATEMENT_DUES:
            return {...state, statementDues: action.payload.statementDues}
        case FETCH_STATEMENT_TYPES:
            return {...state, statementTypes: action.payload.statementTypes}
        case FETCH_DECLARATION_TYPES:
            return {...state, declaration_types: action.payload.declaration_types}
        case FETCH_PLANS:
            return {...state, plans: action.payload.plans}
        case FETCH_PLAN_CHECK:
            return {...state, checkPlans: action.payload.result}
        case FETCH_TEMPLATES:
            return {...state, templates: action.payload.templates}
        case FETCH_VIDEO_DATAS:
            return {...state, videos: action.payload.videos}
        case FETCH_VIDEO_CONFIGS:
            return {...state, videoConfigs: action.payload || {}}
        case FETCH_REPORTS:
            return {...state, reports: action.payload.reports || {}}
        case FETCH_ZREPORTS:
            return {...state, zreports: action.payload.zreports};
        case FETCH_OTHER_SALES_LIST:
            return {...state, othersales: action.payload.othersales};
        case FETCH_OTHER_SALES:
            return {...state, othersales: action.payload.othersales};
        case FETCH_ZREPORT:
            return {...state, zreport: action.payload.zReports};
        case FETCH_E_IN_BOXES:
            return {...state, eInBoxes: action.payload.eInBoxes};
        case FETCH_TABLE_DATA:
            return {...state, tableData: action.payload.tableData};
        default:
            return state;
    }


}