import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoSpinner from "../../components/elements/EkoSpinner";
import EkoMoneyText from "../../components/elements/EkoMoneyText";
import AddBank from "./AddBank";
import { toastr } from "react-redux-toastr";
import HelperContainer from "../../util/HelperContainer";
import moment from "moment";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
//import EkoToastrError from '../../components/elements/EkoToastrError'
import { FETCH_BANK_ACCOUNTS, FETCH_LOANS } from "../../actions/types";

class Banks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showInsertModal: false,
      errors: {},
      validationFields: [],
      bankAccounts: [],
      loans: [],
      updateBankBalance: 0,
      updateLoanBalance: 0,
      balance: "",
      balanceLoading: false,
      passiveButton: ""
    };
  }

  fetchBanks() {
    this.props.fetchData("/bankAccount/list", FETCH_BANK_ACCOUNTS);
    // this.props.fetchData("/loan/list", FETCH_LOANS);
  }

  componentWillMount() {
    this.fetchBanks();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bankAccounts) {
      this.setState({ bankAccounts: nextProps.bankAccounts });
    }
    if (nextProps.loans) {
      this.setState({ loans: nextProps.loans });
    }
  }

  openUpdate(id, status) {
    var self = this;
    let formData = {};
    if (status === 0) {
      Api.get("/bankAccount/" + id, function(response) {
        formData = {};
        if (response.bankAccount) {
          formData.bank = HelperContainer.filterObject(response.bankAccount);

          try {
            // formData.bank.card_number = (formData.bank.card_number) ? Util.convertCardNumber(formData.bank.card_number) : ""
            formData.bank.opening_balance = Util.convertDecimalToMoney(
              formData.bank.opening_balance
            );
            formData.card_numberShow =
              parseInt(formData.bank.bank_account_type, 10) === 2 ||
              parseInt(formData.bank.bank_account_type, 10) === 3;
          } catch (ex) {
            console.log(ex);
          }
          self.setState({
            showInsertModal: true,
            errors: {},
            formData: formData
          });
        }
      });
    } else {
      Api.get("/loan/" + id, function(response) {
        formData = {};
        if (response.loan) {
          formData.bank = HelperContainer.filterObject(response.loan);
          try {
            formData.bank.amount_fc = Util.convertDecimalToMoney(
              formData.bank.amount
            );
            formData.bank.interest_rate_total =
              "%" +
              Util.convertDecimalToMoney(
                (formData.bank.interest_rate_total || 0) * 100
              );
            formData.bank.currency_rate = formData.bank.currency_rate
              .toString()
              .replace(/[#.]/g, ",");
            formData.bank.start_date = moment(
              formData.bank.start_date,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");
            formData.bank.name = formData.bank.description;
            formData.bank.bank_account_type = 5;
          } catch (ex) {
            console.log(ex);
          }
          self.setState({
            showInsertModal: true,
            errors: {},
            formData: formData
          });
        }
      });
    }
  }

  onDelete(id, status) {
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        if (status === 0) {
          Api.delete("/bankAccount/" + id, function(response, err) {
            if (response) {
              self.fetchBanks();
              toastr.success("Başarılı", "Başarıyla Silindi");
            } else toastr.error("Hata", err.message);
          });
        } else {
          Api.delete("/loan/" + id, function(response, err) {
            if (response) {
              self.fetchBanks();
              toastr.success("Başarılı", "Başarıyla Silindi");
            } else toastr.error("Hata", err.message);
          });
        }
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  passiveActive = id => {
    var self = this;
    Api.get("/bankAccount/" + id, function(responses) {
      let formData = {};
      formData.bankAccount = HelperContainer.filterObject(
        responses.bankAccount
      );
      if (formData.bankAccount.is_active === 1) {
        self.setState({ passiveButton: "Pasifleştir" });
      } else {
        self.setState({ passiveButton: "Aktifleştir" });
      }
    });
  };

  togglePassive = id => {
    var self = this;
    Api.get("/bankAccount/" + id, function(responses) {
      let formData = {};
      formData.bankAccount = HelperContainer.filterObject(
        responses.bankAccount
      );
      if (responses.bankAccount) {
        let toggleName = "";

        if (formData.bankAccount.is_active === 1) {
          toggleName = " pasifleştirmek ";
        } else {
          toggleName = " aktifleştirmek ";
        }
        toastr.confirm(
          responses.bankAccount.name +
            " hesabını" +
            toggleName +
            "istediğinize emin misiniz?",
          {
            onOk: () => {
              let func = "put";
              let url = "/bankAccount/" + formData.bankAccount.id;

              if (formData.bankAccount.is_active === 1) {
                formData.bankAccount.is_active = 0;
              } else if (formData.bankAccount.is_active === 0) {
                formData.bankAccount.is_active = 1;
                self.state.passiveButton = "Pasifleştir";
              }

              Api[func](url, formData.bankAccount, function(
                response,
                postErrors
              ) {
                if (postErrors) {
                  if (postErrors.message) {
                    toastr.error("Başarısız", postErrors.message);
                  }
                } else {
                  if ((formData.bankAccount.is_active = 1)) {
                    let toggleName = "";
                    if (responses.bankAccount.is_active === 1) {
                      toggleName = " pasifleştirildi";
                      self.state.passiveButton = "Aktifleştir";
                    } else {
                      toggleName = " aktifleştirildi";
                      self.state.passiveButton = "Pasifleştir";
                    }
                    toastr.success("Başarılı", "Hesap" + toggleName + "");
                    self.fetchBanks();
                  }
                }
              });
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
          }
        );
      }
    });
  };

  balanceChange(value) {
    this.setState({ balance: value });
  }

  updateBalance(type, btntype, id) {
    const self = this;
    let updateBlance = btntype !== 0 ? 0 : id;
    if (btntype === 0) {
      if (type === "bank")
        self.setState({
          blanceLoading: true,
          balance: "",
          updateBankBalance: updateBlance,
          updateLoanBalance: 0
        });
      else
        self.setState({
          blanceLoading: true,
          balance: "",
          updateLoanBalance: updateBlance,
          updateBankBalance: 0
        });
      let formData = {};
      if (type === "bank") {
        Api.get("/bankAccount/" + id, function(response) {
          formData = {};
          if (response.bankAccount) {
            formData.bank = HelperContainer.filterObject(response.bankAccount);

            self.setState({
              formData: formData,
              blanceLoading: false,
              balance: Util.convertDecimalToMoney(formData.bank.balance)
            });
          }
        });
      } else {
        Api.get("/loan/" + id, function(response) {
          formData = {};
          if (response.loan) {
            formData.bank = HelperContainer.filterObject(response.loan);

            self.setState({
              formData: formData,
              blanceLoading: false,
              balance: Util.convertDecimalToMoney(formData.bank.balance)
            });
          }
        });
      }
    } else if (btntype === 1) {
      // kaydet
      self.setState({ blanceLoading: true });
      let bank = this.state.formData.bank;
      if (!this.state.balance) {
        self.setState({ blanceLoading: false });
        return toastr.warning("Uyarı", "Bakiye alanını boş bıraktınız");
      }
      bank.balance = Util.convertMoneyToDecimal(this.state.balance);
      bank.start_date = Util.convertFromDatabaseDate(bank.start_date);
      let url =
        type === "bank" ? "/bankAccount/" + bank.id : "/loan/" + bank.id;

      Api.put(url, bank, function(response, postErrors) {
        if (postErrors) toastr.error("Hata", "Bakiye Güncellenemedi");
        else {
          self.fetchBanks();
          toastr.success("Başarılı", "Bakiye Başarıyla Güncellendi");
          self.setState({
            blanceLoading: false,
            updateBankBalance: 0,
            updateLoanBalance: 0,
            balance: ""
          });
        }
      });
    } else {
      this.setState({
        updateBankBalance: updateBlance,
        updateLoanBalance: updateBlance,
        balance: ""
      });
    }
  }

  openInsert(menuItem) {
    this.setState({ showInsertModal: true, formData: false });
  }

  onSave(formData, formFields) {
    this.fetchBanks();
    this.setState({ showInsertModal: false, formData: false });
  }

  render() {
    if (!this.props.bankAccounts) return <EkoSpinner />;
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <AddBank
              showModal={this.state.showInsertModal}
              formData={this.state.formData}
              onSave={this.onSave.bind(this)}
              onCancel={() => {
                this.setState({showInsertModal: false});
              }}
              errors={this.state.errors}
          />
          <div className="portlet light">
            <div className="portlet-title2">
              <div className="caption2 font-dark">
              <span className="caption-subject bold uppercase">
                KASA/BANKALAR
              </span>
              </div>

              <div className="actions2">
              <div className="btn-group btn-group-devided" data-toggle="buttons">
                {/*<button onClick={() => {
                const windowFeatures = `width=${window.screen.width},height=${window.screen.height},toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,left=10,top=10`;
                window.open(apiUrl + "panel/bulk-bank-integration.php?t=" + localStorage.getItem('token'), 'ekoHesap', windowFeatures);
              }} type="button" id="print" className="btn blue btn-sm">Toplu Banka Hareketleri</button>*/}
              </div>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.bankAccounts.map((data, key) => {
              let bankType = "";
              switch (parseInt(data.bank_account_type, 10)) {
                case 1:
                  bankType = "far fa-money-bill-alt";
                  break;
                case 2:
                case 4:
                  bankType = "fa fa-university";
                  break;
                case 3:
                  bankType = "fa fa-credit-card";
                  break;
                default:
                  break;
              }
              return (
                  <div key={key} className="col-md-4 col-sm-4">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div
                            style={{
                              width: "230px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis"
                            }}
                            className="caption"
                        >
                      <span
                          title={data.name}
                          className="caption-subject font-blue-sharp bold uppercase"
                      >
                        {data.name}
                      </span>
                        </div>
                        <div className="actions">
                          <DropdownButton
                              id="drop_down"
                              title="İşlemler"
                              bsStyle="primary"
                              noCaret
                              className="btn blue btn-outline btn-sm"
                              onMouseOver={() => {
                                this.passiveActive(data.id, 0);
                              }}
                          >
                            <MenuItem
                                eventKey="1"
                                onSelect={() => {
                                  this.openUpdate(data.id, 0);
                                }}
                            >
                              Düzenle
                            </MenuItem>
                            <MenuItem
                                onSelect={() => {
                                  this.togglePassive(data.id, 0);
                                }}
                            >
                              {this.state.passiveButton}{" "}
                            </MenuItem>
                            <MenuItem
                                eventKey="2"
                                onSelect={() => {
                                  this.props.router.push(
                                      "/dashboard/transaction/bank/" + data.id
                                  );
                                }}
                            >
                              Hesap Hareketleri
                            </MenuItem>
                            <MenuItem
                                eventKey="3"
                                onSelect={() => {
                                  this.onDelete(data.id, 0);
                                }}
                            >
                              Sil
                            </MenuItem>
                          </DropdownButton>
                        </div>
                      </div>
                      <strong>{data.bank_account_type_str}</strong>
                      <br/>
                      <br/>
                      <div className="col-md-10 col-sm-3 col-xs-6">
                        <div className="font-grey-mint font-sm">
                          {" "}
                          Bakiye&nbsp;
                          {/*  {
                                                this.state.blanceLoading && this.state.updateBankBalance === data.id ?
                                                    <i className="fa fa-spinner fa-pulse fa-1x fa-fw"/>
                                                    :
                                                    <span>
                                                        <a onClick={() => this.updateBalance('bank', this.state.updateBankBalance === data.id ? 1 : 0, data.id)}>
                                                        <strong>{this.state.updateBankBalance === data.id ? 'Kaydet' : 'Güncelle'}</strong>
                                                        </a>
                                                        &nbsp;{this.state.updateBankBalance === data.id ?
                                                        <a onClick={() => this.updateBalance('bank', 2, data.id)}><strong>{'İptal'}</strong></a> : ""}
                                                    </span>
                                            }*/}
                        </div>
                        {this.state.updateBankBalance === data.id &&
                        !this.state.blanceLoading ? (
                            <div className="col-md-8">
                              <EkoMoneyText
                                  thisClass={"form-control"}
                                  value={this.state.balance}
                                  type={"text"}
                                  onChange={this.balanceChange.bind(this)}
                              />
                            </div>
                        ) : (
                            <div
                                style={
                                  parseInt(data.balance, 10) >= 0
                                      ? {color: "#5c9bd1"}
                                      : {color: "red"}
                                }
                                className="uppercase font-hg"
                            >
                              {" "}
                              {data.balance_str}{" "}
                            </div>
                        )}
                      </div>
                      <div className="bank-type">
                        <i className={bankType} aria-hidden="true"/>
                      </div>
                      <div className="clearfix"/>
                      {data.KullanilabilirBakiye != 0 ? (
                          <div className="col-md-10 col-sm-3 col-xs-6">
                            <div className="font-grey-mint font-sm" style={{fontSize: "10px"}}>
                              Banka Hesabı Anlık Bakiye: {data.KullanilabilirBakiye}
                            </div>
                          </div>
                      ) : (
                          ""
                      )}
                    </div>
                  </div>
              );
            })}
            <div className="col-md-4 col-sm-4">
              <div className="portlet light">
                <p className="text-center text-margin">
                  <a
                      className="btn blue btn-outline"
                      data-toggle="modal"
                      onClick={this.openInsert.bind(this)}
                  >
                    <i className="fa fa-plus" aria-hidden="true"/> Ekle
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bankAccounts: state.data.bankAccounts,
    loans: state.data.loans
  };
}

export default connect(mapStateToProps, actions)(Banks);
