import {DropdownButton, MenuItem} from "react-bootstrap";
import React from "react";
import Api from "../../util/Api";
import * as actions from "../../actions";
import {withRouter} from "react-router";
import { connect } from "react-redux";
import { FETCH_COMPANY_ALL } from "../../actions/types";
import { setDefaultStorage } from '../../app.js';

class CompanyMenu extends React.Component {

    state = {
        companies: [],
        selected: {},
        loading: false,
    };

    componentDidMount() {

        this.fetchCompanies();
    }

    fetchCompanies = () => {
        this.props.fetchData("/company/user-companies", FETCH_COMPANY_ALL);

        let localCompanies = sessionStorage.getItem('companies');

        if (localCompanies) {
            localCompanies = JSON.parse(localCompanies);

            this.setState({companies: localCompanies.companies});
        } else {
            Api.get('/company/user-companies', (data, errors) => {

                if (errors === false) {
                    this.setState({
                        companies: data.companies,
                    });
                }
            });
        }


    }

    changeSelect = (id) => {
        var self = this;
        this.setState({
            loading: true
        }, () => {
            Api.post('/company/change-company', { id: id }, (data, errors) => {
                if (errors === false && data.status === "ok") {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    setDefaultStorage(true);
                    setTimeout(() => {
                        if (parseInt(data.user.plan_id, 10) === 0) {
                            self.props.router.push('/subscriber');
                        } else {
                            if (self.props.path === '/subscriber') {
                                self.props.router.push('/dashboard');
                            } else {
                                window.location.reload();
                            }
                        }
                    }, 6000);
                }
            });
        })
    };


    get title() {
        const user = JSON.parse(localStorage.getItem('user'));

        if (this.state.loading === true) {
            return "Değiştiriliyor...";
        }

        let selected = '';

        this.state.companies.forEach((company) => {
            if (parseInt(company.id, 10) === parseInt(user.company_id, 10)) {
                selected = company.firstname;
                if (company.lastname !== null && company.lastname !== '') {
                    selected += ' ' + company.lastname;
                }
            }
        });

        return selected === '' ? 'Şirket Seçiniz' : selected;
    }

    render() {

        return (
            this.state.companies.length > 1 ? <div style={{display: "inline-block"}}>
                <DropdownButton style={{marginTop: 10, marginRight: 10}}
                                title={this.title} id="bg-nested-dropdown">
                    {this.state.companies.map((company) => <MenuItem onSelect={this.changeSelect}
                                                                     key={company.id}
                                                                     eventKey={company.id}>{company.firstname}{company.lastname !== null && company.lastname !== '' ? " " + company.lastname : ""}</MenuItem>)}

                </DropdownButton>

            </div> : null
        );
    }

}

function mapStateToProps(state) {
    return {
        companies: state.data.companies || []
    };
}

export default withRouter(connect(mapStateToProps, actions)(CompanyMenu))
