import React from "react";
import {
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormRadioButton,
} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import Util from "../../util/Util";
import HelperContainer from "../../util/HelperContainer";
import AddCustomer from "../Customer/AddCustomer";
import AddSupplier from "../Supplier/AddSupplier";
import moment from "moment";
import AddDocumentButton from "../../components/forms/AddDocumentButton";
import UploadXMLButton from "../../components/forms/UploadXMLButton";
import { toastr } from "react-redux-toastr";
import Sugar from "sugar";
import InputMask from "react-input-mask";
import EkoModal from "../../components/elements/EkoModal";
export default class AddSalesInvoiceHeader extends React.Component {
  constructor(props) {
    super(props);
    let fields = [
      {
        title: "Kimden",
        type: "select",
        id: "customer_id",
        defaultText: "SEÇİNİZ",
        optionValue: "name",
        data: [],
        bankAccounts: [],
        value: this.props.fromWhoId || 0,
        onAddNewItemClick: this.onAddNewFromWhoClick.bind(this),
        optionId: "id",
        searchable: true,
        isVertical: true,
      },
      {
        title: "Döviz Tipi",
        type: "select",
        id: "currency",
        defaultText: "SEÇİNİZ",
        optionValue: "code",
        data: [],
        optionId: "code",
        isVertical: true,
      },
      {
        title: "Kur",
        type: "money",
        id: "currency_rate",
        isVertical: true,
        precision: 4,
      },
      { title: "Seri/Sıra No", type: "mask", id: "legal_no",order:3 },
      { title: "Fatura Tarihi", type: "date", id: "date" },
      { title: "Vade Tarihi", type: "date", id: "due_date" },
      {
        title: "Doküman Tipi",
        type: "select",
        id: "document_type_tag",
        defaultText: "SEÇİNİZ",
        optionValue: "name",
        data: [],
        searchable: true,
        optionId: "id",
        isVertical: true,
      },
      { title: "Seri No", type: "mask", id: "legal_serial",mask:"",order:7 },
      { title: "Sıra No", type: "mask", id: "legal_no2",mask:"",order:8 },
      {
        title: "Depo Seçin",
        type: "select",
        id: "warehouse_id",
        defaultText: "SEÇİNİZ",
        optionValue: "name",
        data: [],
        optionId: "id",
        isVertical:false,
        searchable: true
      },
      {
        title: "Stok Durum",
        type: "select",
        id: "stock_status",
        defaultText: "SEÇİNİZ",
        optionValue: "name",
        data: JSON.parse('[ { "id": 0, "name": "Stoğa Ekle" },{ "id": 1, "name": "Stoğa Ekleme" }]'),
        optionId: "id",
        isVertical:false
      },
      // { title: 'Sevk Tarihi', type: 'date', id: 'shipping_date' }
    ];

    this.state = {
      showCustomer: false,
      showSupplier: false,
      detailIsOpened: false,
      gercekTuzel: false,
      formDataCustomer: AddCustomer.getDefaultForm(),
      formDataSupplier: AddSupplier.getDefaultForm(),
      fromWho: false,
      is_checked: "not_paid",
      formData: {},
      fromWhoId: 0,
      fromWhoAddress: false,
      fromWhoAddress2: false,
      docType: "",
      fields: fields,
      check1: false,
      check2: "",
      activeBuyOrSellTxt: 0,
      serial_order_choose:1,
      mask_text:"",
      mask_char:"",
      legal_no_count:0,
      legal_no_max:0,
      stock_status_warning: false,
      radioButtons: [
        {
          label: "Ödenecek",
          id: "is_net",
          radio_id: "not_paid",
          value: 1,
          checked: true,
        },
        {
          label: "Ödendi",
          id: "is_net",
          radio_id: "paid",
          value: 0,
        },
      ],
      warehouses:[],
      radioButtonsPurchaseTypesSelectedVal:this.props.documentType === 1?0:3,
      radioButtonsPurchaseTypes: [
        { // 0:normal alış, 1:kur farkı, 2:fiyat farkı, 3:iade
          label: this.props.documentType === 1 || this.props.documentType === 4?"Normal Alış":"İade",
          id: "purchase_types",
          radio_id: this.props.documentType === 1|| this.props.documentType === 4?"normal_alis":"iade",
          value: this.props.documentType === 1 || this.props.documentType === 4?0:3,
          checked: true
        },
        {
          label: "Kur Farkı",
          id: "purchase_types",
          radio_id: "kur_farki",
          value: 1,
        },
        {
          label: "Fiyat Farkı",
          id: "purchase_types",
          radio_id: "fiyat_farki",
          value: 2,
        },
      ],
    };
  }

  componentWillMount() {
    let fields = this.state.fields;
    if (this.props.documentType === 3) {
      fields[0].id = "customer_id";
      this.setState({ docType: "customer", fields: fields });
      if (this.props.fromWhoId > 0)
        this.setCustomer(this.props.fromWhoId, true);
    } else {
      fields[0].id = "supplier_id";
      this.setState({ docType: "supplier", fields: fields });
      if (this.props.fromWhoId > 0)
        this.setSupplier(this.props.fromWhoId, true);
    }
    this.getDocumentType();
  }
  fetchWarehouses = async () => {
    let { data } = await Api.getAsync("/warehouses/showAll");
    return (data && data.items) || [];
  };
  componentWillReceiveProps(nextProps) {
    let fields = this.state.fields;

    if (nextProps.fromWhoId && this.props.fromWhoId !== nextProps.fromWhoId) {
      if (nextProps.documentType === 3) this.setCustomer(nextProps.fromWhoId);
      else this.setSupplier(nextProps.fromWhoId);
    }
    if (nextProps.fromWhos) {
      fields[0].data = nextProps.fromWhos;
    }
    if (nextProps.formData.id){
      fields[10].disabled = true;
    }
    if (nextProps.currencies) {
      fields[1].data = nextProps.currencies;
    }
    if(this.state.warehouses.length===0){
      this.fetchWarehouses().then((warehouses) => {
        this.setState({
          warehouses,
        });
        fields[9].data = warehouses;
      });
    }
    if (nextProps.formData.document_type_tag) {
      const get = Sugar.Array.find(JSON.parse(localStorage.getItem("getDBSExpenseDocumentType")).documentType, function (type) {
        return type.id === nextProps.formData.document_type_tag
      });
      if (get.code !== undefined) {
        this.setState({ serial_order_choose: get.serial_order_choose });
      }
    }

    if (nextProps.formData.currency && nextProps.formData.currency === "TRY") {
      fields[2].disabled = true;
    }

    if (!nextProps.formData.currency) {
      fields[2].disabled = true;
      this.onChange("currency", "TRY");
    }

    if (nextProps.calculate && !this.props.calculate) {
      if (
        (this.props.documentType === 3 && !nextProps.formData?.customer_id) ||
        (this.props.documentType !== 3 && !nextProps.formData?.supplier_id)
      ) {
        const self = this;
        let data = null;
        if(this.props.documentType === 3){
         data = AddCustomer.getDefaultForm();
         const d = nextProps.formData?.supplierData || {};
         const lastname = d?.tckn ? d.name?.split(' ').pop() : "";
         const firstname = d?.tckn ? d.name?.replace(' '+lastname, '') : d.name;
         data.customer.firstname = firstname;
         data.customer.lastname = lastname;
         data.customer.tax_identity_number = d.tax_identity_number;
         data.customer.tax_office_code = d.tax_office_code;
         data.address.address = d.address;
         data.address.city_id = d.city_id;
         data.address.county_id = d.county_id;
        } else {
          data = AddSupplier.getDefaultForm();
          const d = nextProps.formData?.supplierData || {};
          const lastname = d.name?.split(' ').pop();
          const firstname = d.name?.replace(' '+lastname, '');
          data.supplier.firstname = firstname;
          data.supplier.lastname = lastname;
          data.supplier.tax_identity_number = d.tax_identity_number;
          data.supplier.tax_office_code = d.tax_office_code;
          data.address.address = d.address;
          data.address.city_id = d.city_id;
          data.address.county_id = d.county_id;
        }
        const customerOptions = {
          okText: "Evet",
          cancelText: "Hayır",
          onOk: () => self.onAddNewFromWhoClick(data),
          onCancel: () => null,
        };
        let customerText =
          this.props.documentType === 3
            ? "Bu müşteri kayıtlı değil kayıt etmek ister misiniz ?"
            : "Bu tedarikçi kayıtlı değil kayıt etmek ister misiniz ?";
        toastr.confirm(customerText, customerOptions);
      }
    }

    this.setState({ fields: fields });
  }
  getDocumentType() {
    Api.get("/DBS/getDBSExpenseDocumentType2/list", (response, err) => {
      let fields = this.state.fields;
      fields[6].data = response.documentType;
      this.setState({ fields: fields });
    });
  }

  getMethodBankAccount(bank_id) {
    if (Util.isUndefined(this.props.bankAccounts)) {
      return;
    }

    Api.get("/bankAccount/" + bank_id, (response, err) => {
      let method = response.bankAccount.bank_account_type;
      let bank_currency = response.bankAccount.currency;

      /*
      collection ve payment'a hatalı method atıyordu o yüzden kaldırdım. @Gökberk
      if (method === 2) {
        method = 3;
      } else if (method === 3 || method === 4) {
        method = 2;
      }
*/


      this.props.bankCurrencyAndMethod(bank_currency, method);
    });
  }
  maskCharCalculate(){
    if(this.state.legal_no_max-this.state.legal_no_count>=0){
      this.setState({mask_char:"("+(this.state.legal_no_max-this.state.legal_no_count)+")"});
    }
  }
  onChange(field, value, passRateChange) {
    if (value === "not_paid") {
      this.setState({ check1: false, check2: true, is_checked: "not_paid" });
    }
    if (field === "legal_no") {
      this.setState({ legal_no_count:value.replace(/_/g, "").length });
      this.maskCharCalculate();
    }

    if (value === "paid") {
      let bankAccounts = this.props.bankAccounts;
      bankAccounts = bankAccounts.filter(account => account.currency === "TRY" || account.currency === this.props.formData.currency);
      this.setState({
        check1: true,
        check2: false,
        bankAccounts: bankAccounts,
        is_checked: "paid",
      });
    }
    if (value === "bank_list_change") {
      let bankAccounts = this.props.bankAccounts;
      if(bankAccounts){
        bankAccounts = bankAccounts.filter(account => account.currency === "TRY" || account.currency === this.props.formData.currency);
        this.setState({
          bankAccounts: bankAccounts,
        });
      }
    }
    if (field === "stock_status") {
      this.openStockStatusWarningModal();
    }
    var self = this;
    var fields = this.state.fields;
    if (field === "document_type_tag") {
        const get = Sugar.Array.find(JSON.parse(localStorage.getItem("getDBSExpenseDocumentType")).documentType, function (type) {
          return type.id === value
        });
        if (get.code !== undefined) {
          this.setState({ serial_order_choose: get.serial_order_choose });
          var get_mask = JSON.parse(get.mask);
          if (get_mask.seri !== undefined) {
            fields[7].mask=`${"*".repeat(get_mask.seri)}`;
            fields[8].mask=`${"9".repeat(get_mask.sira)}`;
            this.setState({ fields: fields,mask_text:"Örnek: AA123456" });
          }else if (get_mask.seri_sira !== undefined) {
            fields[3].mask=`${"*".repeat(get_mask.seri_sira)}`;
            this.setState({ mask_text:get.id!=18?"Örnek: FTR20230000000001":"", legal_no_max:get_mask.seri_sira-1});
            this.maskCharCalculate()
          }
        }
    }
    if (field === "method_source_id") {
      this.getMethodBankAccount(value);
    }

    if (this.props.onChange) {
      this.props.onChange("invoice", field, value);
    }

    if (!passRateChange && (field === "currency" || field === "date")) {
      this.calculateCurrencyRate(field, value);
    }

    if (field === "currency") {
      let fields = this.state.fields;

      if (value === "TRY") {
        fields[2].disabled = true;
      } else if (!passRateChange) fields[2].disabled = false;
      this.setState({ fields: fields });
    }

    if (field === "date") {
      this.setState({ formData: { date: value } }, () => {
        if (value) this.calculateDueDate();
      });
    }

    if (field === "customer_id") {
      this.setState({ fromWhoId: value });
      this.setCustomer(value);
    }

    if (field === "supplier_id") {
      this.setState({ fromWhoId: value });
      this.setSupplier(value);
    }
    if (field === "radioButtonsPurchaseTypes" && value==='kur_farki') {
      //@oguz kur farkı radio seçilirse currency i TRY ye çeviriyoruz
      this.props.formData.currency='TRY';
      this.onChange("currency_rate", "1,0000");
    }
    if (field === "radioButtonsPurchaseTypes") {
      //0:normal alış, 1:kur farkı, 2:fiyat farkı, 3:iade
      var val = 0;
      if(value==='normal_alis'){ val=0; }
      else if(value==='kur_farki'){ val=1; }
      else if(value==='fiyat_farki'){ val=2; }
      else if(value==='iade'){ val=3; }
      this.setState({radioButtonsPurchaseTypesSelectedVal:val});
    }
  }

  onAddNewFromWhoClick(data) {
    if (this.props.documentType === 3) {
      this.setState({
        showCustomer: true,
        formDataCustomer: data || AddCustomer.getDefaultForm(),
        detailIsOpened: data ? true : false,
        gercekTuzel: data?.tckn ? true : false,
      });
    } else
      this.setState({
        showSupplier: true,
        formDataSupplier: data || AddSupplier.getDefaultForm(),
        detailIsOpened: data ? true : false,
        gercekTuzel: data?.tckn ? true : false,
      });
  }

  calculateDueDate() {
    const self = this;
    let fromWhoId =
      self.props.formData.customer_id || self.props.formData.supplier_id;
    if (!fromWhoId) return;
    Api.get(
      "/calculation/invoiceDueDate?id=" +
        fromWhoId +
        "&date=" +
        moment(self.props.formData.date).format("YYYY-MM-DD") +
        "&type=" +
        self.state.docType,
      function (response, err) {
        if (!err) {
          var parsedDate = moment(response.response, "DD-MM-YYYY");
          if (parsedDate.isValid()) {
            const date = moment(response.response, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
            );
            self.onChange("due_date", date);
          } else {
            if(response.response!==null){toastr.error("Hata", "Vade tarihi hesaplanamadı. Girilen Tarih: "+response.response);}
          }

        }
      }
    );
  }
  clickBuyorSell = (date,currency,sellOrBuy) => {
    var url = "/exchangeRate/" + currency + "/" + date;
    Api.get(url, (response, error) => {
      if(response.response && response.response.length!=0){
        if(sellOrBuy=='buy'){
          this.setState({activeBuyOrSellTxt:1})
          this.onChange(
              "currency_rate",
              Util.convertDecimalToString(response.response.buying_forex, 4)
          )
        }else if(sellOrBuy=='sell'){
          this.setState({activeBuyOrSellTxt:2})
          this.onChange(
              "currency_rate",
              Util.convertDecimalToString(response.response.selling_forex, 4)
          )
        }else{
          this.setState({activeBuyOrSellTxt:0})
        }
      }
    })
  }
  calculateCurrencyRate(field, value) {
    const self = this;
    if (value == "TRY") {
      setTimeout(function () {
        self.onChange("currency_rate", Util.convertDecimalToString(1, 4));
      }, 1000);
      return;
    }

    if (this.props.formData.currency == "TRY" && field == "date") {
    } else {
      let currency = this.props.formData.currency;

      let date = this.props.formData.date;
      if (field === "currency") {
        currency = value;
      }
      if (field === "date") date = value;
      if (currency) {
        Api.getCurrencyRate(
          currency,
          moment(date).format("YYYY-MM-DD"),
          function (rate, err) {
            if (rate) {
              self.onChange(
                "currency_rate",
                Util.convertDecimalToString(rate, 4)
              );
            }
          }
        );
      }
    }
  }

  setCustomer(id, passCurrencyCheck) {
    var self = this;
    Api.paralel(
      [
        "/customer/" + id,
        "/customerContact/showAll/" + id,
        "/customerAddress/showAll/" + id,
      ],
      function (responses) {
        let formData = {};
        formData.customer = HelperContainer.filterObject(responses[0].customer);
        if(self.state.radioButtonsPurchaseTypesSelectedVal===1){
          self.onChange("radioButtonsPurchaseTypes", 'kur_farki');
          self.onChange("currency_rate", "1,0000",false);
          const fields = self.state.fields;
          fields[1].disabled = true;
        }else{
        if (!passCurrencyCheck) {
          if (formData.customer.currency !== "TRY") {
            const fields = self.state.fields;
            fields[1].disabled = true;
            fields[2].disabled = false;
            self.setState({ fields }, () => {
              self.onChange("currency", formData.customer.currency, true);
              self.onChange("currency_rate", 1);
              self.calculateCurrencyRate(
                "currency",
                formData.customer.currency
              );
            });
            self.calculateDueDate();
          } else {
            const fields = self.state.fields;
            fields[1].disabled = false;
            self.setState({ fields }, () => {
              self.onChange("currency", formData.customer.currency, true);
              self.onChange("currency_rate", 1);
            });
            self.calculateDueDate();
          }
        }
        }

        if (
          responses[1].customerContacts &&
          responses[1].customerContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].customerContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].customerAddresses &&
          responses[2].customerAddresses.length > 0
        ) {
          const address = responses[2].customerAddresses.filter(function (a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
          if (!formData.customer.delivery_same_as_invoice_address) {
            const addressDelivery = responses[2].customerAddresses.filter(
              function (a) {
                return a.address_type === 1;
              }
            );
            formData.deliveryAddress = HelperContainer.filterObject(
              addressDelivery[0]
            );
          } else formData.deliveryAddress = formData.address;
        } else {
          formData.deliveryAddress = {};
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address)
          fromWhoAddress =
            formData.address.address +
            "\n" +
            formData.address.county.name +
            "\n" +
            formData.address.city.name;
        else fromWhoAddress = false;

        let fromWhoAddress2 = self.state.fromWhoAddress2;
        if (formData.deliveryAddress && formData.deliveryAddress.address)
          fromWhoAddress2 =
            formData.deliveryAddress.address +
            "\n" +
            formData.deliveryAddress.county.name +
            "\n" +
            formData.deliveryAddress.city.name;
        else fromWhoAddress2 = false;

        self.setState({
          formDataCustomer: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: fromWhoAddress2,
        });
        self.onChange("bank_list_change", "bank_list_change");
        self.onChange("address", fromWhoAddress);
        self.onChange("address2", fromWhoAddress2);
      }
    );
  }

  setSupplier(id, passCurrencyCheck) {
    var self = this;

    Api.paralel(
      [
        "/supplier/" + id,
        "/supplierContact/showAll/" + id,
        "/supplierAddress/showAll/" + id,
      ],
      function (responses) {
        let formData = {};
        formData.supplier = HelperContainer.filterObject(responses[0].supplier);

        if(self.state.radioButtonsPurchaseTypesSelectedVal===1){
          self.onChange("radioButtonsPurchaseTypes", 'kur_farki');
          self.onChange("currency_rate", "1,0000",false);
          const fields = self.state.fields;
          fields[1].disabled = true;
        }else{
        if (!passCurrencyCheck) {
          if (formData.supplier.currency !== "TRY") {
            const fields = self.state.fields;
            fields[1].disabled = true;

            //
            fields[2].disabled = false;

            self.setState({ fields }, () => {
              self.onChange("currency", formData.supplier.currency, true);
              self.onChange("currency_rate", 1);
              self.calculateCurrencyRate(
                "currency",
                formData.supplier.currency
              );
            });
            self.calculateDueDate();
          } else {
            const fields = self.state.fields;
            fields[1].disabled = false;
            self.setState({ fields }, () => {
              self.onChange("currency", formData.supplier.currency, true);
              self.onChange("currency_rate", 1);
            });
            self.calculateDueDate();
          }
        }
        }

        if (
          responses[1].supplierContacts &&
          responses[1].supplierContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].supplierContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].supplierAddresses &&
          responses[2].supplierAddresses.length > 0
        ) {
          const address = responses[2].supplierAddresses.filter(function (a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
        } else {
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address)
          fromWhoAddress =
            formData.address.address +
            "\n" +
            formData.address.county.name +
            "\n" +
            formData.address.city.name;
        else fromWhoAddress = false;

        self.setState({
          formDataSupplier: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: false,
        });

        self.onChange("address", fromWhoAddress);
        self.onChange("bank_list_change", "bank_list_change");
      }
    );
  }

  onMount() {}
  openStockStatusWarningModal = () => {
    this.setState({ stock_status_warning: true });
  };
  closeStockStatusWarningModal() {
    this.setState({ stock_status_warning: false });
  }
  getInputByType(field) {
    const inputProps = {
      onChange: this.onChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.props.formData,
      errors: this.props.errors,
    };

    let rElement = null;
    switch (field.type) {
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            mask={field.mask}
            id={field.id}
            disabled={false}
            type="text"
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="number"
            precision={field.precision}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "money":
        rElement = (
            <div><EkoFormInputText
                {...inputProps}
                id={field.id}
                disabled={field.disabled}
                precision={field.precision}
                type="money"
                isVertical={field.isVertical}
                label={field.title}
            />
              {field.id ==='currency_rate' ? (
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div style={{"color":this.state.activeBuyOrSellTxt==1?"#4a8cc3":"black","cursor":"pointer"}} className="col-md-4" onClick={this.clickBuyorSell.bind(this,this.state.formData.date, this.props.formData.currency,'buy')}>Alış</div>
                    <div style={{"color":this.state.activeBuyOrSellTxt==2?"#4a8cc3":"black","cursor":"pointer"}} className="col-md-4 text-right" onClick={this.clickBuyorSell.bind(this,this.state.formData.date, this.props.formData.currency,'sell')}>Satış</div>
                  </div>
              ):("")}
            </div>
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            clearable={true}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
        case "mask":
        rElement = (
            <EkoFormInputText
                {...inputProps}
                id={field.id}
                disabled={field.disabled}
                precision={field.precision}
                type="mask"
                mask={field.mask}
                isVertical={field.isVertical}
                label={field.title}
            />
        );
        break;
      case "textarea":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="textarea"
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "select":
        rElement = (
          <EkoFormSelect
            {...inputProps}
            onAddNewItemClick={field.onAddNewItemClick}
            disabled={field.disabled}
            searchable={field.searchable}
            id={field.id}
            isVertical={field.isVertical}
            defaultText={field.defaultText}
            optionId={field.optionId}
            options={field.data}
            optionValue={field.optionValue}
            label={field.title}
            labelMd={4}
            colMd={7}
          />
        );
        break;
      default:
    }
    return rElement;
  }

  onCustomerSave(id) {
    this.setState({ showCustomer: false });
    this.setCustomer(this.state.fromWhoId);
    if (this.props.onRefresh) this.props.onRefresh();
    this.onChange("customer_id", id);
  }

  onSupplierSave(id) {
    this.setState({ showSupplier: false });
    this.setSupplier(this.state.fromWhoId);
    if (this.props.onRefresh) this.props.onRefresh();
    this.onChange("supplier_id", id);
  }

  render() {
    const fields = this.state.fields;
    const inputProps = {
      onChange: this.onChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.props.formData,
      errors: this.props.errors,
    };
    const documentType = this.props.documentType;
    return (
      <div className="portlet light" style={{ paddingBottom: 100 }}>
        <div className="portlet-title">
          <div
            className="portlet-title"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="caption font-dark">
              <span className="caption-subject bold uppercase">
                {this.props.title}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6" style={{display:""}}>
            <div style={{ paddingBottom: 25,paddingTop:10,display:this.props.documentType !== 2 && this.props.documentType !== 4?(this.props.formData.id === undefined || this.props.formData.id === null) ?"":"none" :"none"}}>
              <EkoFormRadioButton
                  {...inputProps}
                  id="radioButtonsPurchaseTypes"
                  buttons={this.state.radioButtonsPurchaseTypes}
                  onFalse={this.onFalse}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  flex: "auto",
                }}
            >
              <AddDocumentButton
                  style={{ margin: 0, transform: "translateY(-25%)" }}
              />
              <UploadXMLButton
                  style={{
                    margin: "0 0 0 10px",
                    transform: "translateY(-25%)",
                  }}
                  callBack={this.props?.callBackXMLData}
                  paramType={this.props.paramType}
              />
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="alldiv">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  {this.props.isPermitted()
                    ? this.getInputByType(fields[0])
                    : null}
                </div>
                <div className="col-md-6">{this.getInputByType(fields[1])}</div>
                <div className="col-md-6">{this.getInputByType(fields[2])}</div>
              </div>
            </div>
            <div className="col-md-6" />
            <div className="col-md-3">
              <div className="heighte row">
                {this.getInputByType(fields[6])}
              </div>
              <div className="heighte row"  style={{"display":this.state.serial_order_choose==1?"contents":"none"}}>
                {this.getInputByType(fields[3])}
              </div>
              <div className="heighte row" style={{"display":this.state.serial_order_choose==0?"contents":"none"}}>
                {this.getInputByType(fields[7])}
              </div>
              <div className="heighte row" style={{"display":this.state.serial_order_choose==0?"contents":"none"}}>
                {this.getInputByType(fields[8])}
              </div>
              <p style={{"color":"red","font-size":"10px","text-align":"right","display":this.state.mask_text==''?"none":""}}>{this.state.mask_text} {this.state.mask_char}</p>
              <div className="heighte row">
                {this.getInputByType(fields[4])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[5])}
              </div>
              <div className="heighte row" style={{ display: (Util.companyModules(2) && this.props.documentType !== 2) ? "" : "none" }}>
              {this.getInputByType(fields[9])}
              </div>
              <div className="heighte row" style={{ display: (Util.companyModules(2) && this.props.documentType !== 2) ? "" : "none" }}>
                {this.getInputByType(fields[10])}
              </div>
              {/*<div className="heighte row">*/}
              {/*{this.getInputByType(fields[6])}*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="clearfix" />
          <div style={{ marginTop: 30 }}>{this.props.children}</div>

          <div className="col-md-6" style={{ marginTop: -45 }}>
            <div className="row">
              {(this.props.documentType === 1 ||
                this.props.documentType === 2) &&
              !this.props.formData.id ? (
                <EkoFormRadioButton
                  {...inputProps}
                  id="radioButtons"
                  buttons={this.state.radioButtons}
                  onFalse={this.onFalse}
                />
              ) : (
                ""
              )}

              {(this.props.documentType === 1 ||
                this.props.documentType === 2) &&
              this.state.check1 === true ? (
                <div>
                  <div className="col-md-6 dropdownTop">
                    <EkoFormSelect
                      {...inputProps}
                      id={"method_source_id"}
                      defaultText={"Seçiniz"}
                      isVertical={true}
                      optionId={"id"}
                      searchable={true}
                      options={this.state.bankAccounts}
                      optionValue={"name"}
                      label={"İlişkili Hesap"}
                    />
                  </div>

                  <div className="col-md-6">
                    <EkoFormDate
                      {...inputProps}
                      id={"date1"}
                      dateFormat="DD-MM-YYYY"
                      isVertical={true}
                      calendarPlacement={"top"}
                      clearable
                      label={"Ödeme Tarihi"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <AddSupplier
          activeTab="address"
          showModal={this.state.showSupplier}
          detailIsOpened={this.state.detailIsOpened}
          gercekTuzel={this.state.gercekTuzel}
          formData={this.state.formDataSupplier}
          onSave={this.onSupplierSave.bind(this)}
          onCancel={() => {
            this.setState({ showSupplier: false });
          }}
          erors={{}}
        ></AddSupplier>
        <AddCustomer
          activeTab={"address"}
          showModal={this.state.showCustomer}
          detailIsOpened={this.state.detailIsOpened}
          gercekTuzel={this.state.gercekTuzel}
          formData={this.state.formDataCustomer}
          onSave={this.onCustomerSave.bind(this)}
          onCancel={() => {
            this.setState({ showCustomer: false });
          }}
          errors={{}}
        />
        <EkoModal
            showModal={this.state.stock_status_warning}
            onHide={this.closeStockStatusWarningModal.bind(this)}
            title={"STOK DURUM UYARISI"}
        >
          <p>Bu ürünleri alış irsaliyesi ile stoklarınıza eklediyseniz STOĞA EKLEME'yi seçiniz. Aksi takdirde STOKĞA EKLE'yi seçiniz</p>
        </EkoModal>
      </div>
    );
  }
}
