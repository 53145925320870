import { PERMISSIONS } from './PermittedPages'

const Permitted = {
    isPermitted(pageId) {
        const selectedPage = PERMISSIONS.filter(function (permission) {
            return permission[0] === pageId
        })[0]

        if (selectedPage == null) return false

        let planIds, companyTypes, roleIds = []
        if (selectedPage != null) {
            planIds = selectedPage[2].split(',');
            companyTypes = selectedPage[3].split(',');
            roleIds = selectedPage[4].split(',');

        }

        const user = JSON.parse(localStorage.getItem('user'));
        let permitted1, permitted2, permitted3 = false;
        if (user.plan_id && planIds.indexOf(user.plan_id + "") > -1) {
            permitted1 = true;
        }

        if (user.company_type && companyTypes.indexOf(user.company_type) > -1)
            permitted2 = true;

        if (user.role_id && roleIds.indexOf(user.role_id + "") > -1)
            permitted3 = true;

        if (permitted1 && permitted2 && permitted3) return true
        else return false
    }
}

export default Permitted
