import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class EkoInputTableLine extends Component {

    createInput(field, inputProps) {
        return this.props.createInput(field, inputProps);
    }

    showRowInput = (field, i) => {
        const inputProps = this.props.getInputProps(i);
        if(field.currency && this.props.currency){
            inputProps.currency = this.props.currency;
        }
        return this.createInput(field, inputProps);
    };


    deleteRow = () => {
        this.props.deleteRow(this.props.number);
    };


    render() {
        const x = this.props.number;
        const orderNumber = this.props.orderNumber;

        return <tr>
            <td className="ticon" onClick={this.deleteRow}>
                <i style={{"display":this.props.deletable === false ? "none":""}} className="fa fa-times delete" aria-hidden="true"/>
            </td>
            {orderNumber?<td className="ticon" style={{"fontWeight":"bold"}}>
                {x + 1}
            </td>:""}
            {this.props.row.map((row, i) => {

                    return <td hidden={row.hidden} key={i} onClick={row.onClick} className={row.tdClass}>
                        {this.showRowInput(row, x)}
                    </td>
                }
            )}
        </tr>
    }
}

EkoInputTableLine.propTypes = {
    row: PropTypes.array.isRequired,
    deleteRow: PropTypes.func.isRequired,
    number: PropTypes.number,
};
