import React, { Component } from "react";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import EkoFormInput from "../../components/elements/form/EkoFormInputText";
import Api from "../../util/Api";
import { EkoFormSelect } from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import Portlet from "../../components/elements/Portlet";
import AddItem from "../Item/AddItem";
import Util from "../../util/Util";

/**
 * Ürün ekleme ve düzenleme aşamasında Ürün alanında tüm ürünler görüntülenecek.
 * Alt kısımda bulunan tablo alanında ise Ürün Adı alanına getirilecek ürünler listesinden
 * Seçili olan ürün kaldırılacak.
 *
 * TransferWarehousesAddUpdate.js dosyasından inspire alınabilir.
 */
class RecipeAddUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultData;
    }

    componentWillMount() {
        if (this.props.params && this.props.params.id) {
            const id = this.props.params && this.props.params.id;
            Api.getAsync("/recipe/" + id)
                .then((response) => {
                    if (response.data && response.data.recipe) {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                name: response.data.recipe.name,
                                note: response.data.recipe.note ?? "",
                                product: response.data.recipe.item_id,
                                rows: response.data.lines.map((item) => {
                                    return {
                                        id: item.id,
                                        item_id: item.item_id,
                                        quantity: item.quantity,
                                        sales_total_price: "0",
                                        purchase_total_price: "0",
                                    };
                                }),
                            },
                            items: response.data.lines.map((item) => {
                                return {
                                    id: item.id,
                                    item_id: item.item_id,
                                    quantity: item.quantity,
                                    sales_total_price: "0",
                                    purchase_total_price: "0",
                                };
                            }),
                        });
                    }
                })
                .then(() => {
                    let tableData = { ...this.state.tableData };
                    let itemLength = this.state.items.length;
                    for (let i = 0; i < itemLength - 1; i++) {
                        tableData.rows.push(this.row);
                    }
                    return tableData;
                })
                .then((td) => {
                    this.setState({
                        tableData: td,
                    });
                })
                .then(() => {
                    this.fillData();
                });
        } else {
            this.fillData();
        }
    }

    fillData() {
        Api.get("/item/showAll", (response, err) => {
            let items =
                response &&
                response.items.map(function (item) {
                    item.codeName = item.item_code + ":" + item.item_name;
                    return item;
                });

            this.setState({
                items: items || [],
            });

            let tableData = this.state.tableData;

            this.setState({
                tableData: {
                    ...this.state.tableData,
                    rows: (tableData.rows = tableData.rows.map((row, index) => {
                        row[0].data = items;
                        return row;
                    })),
                },
            });

            let formData = this.state.formData;

            this.setState({
                formData: {
                    ...this.state.formData,
                    rows: formData.rows.map((row) => {
                        let item = items.find((x) => x.id === row.item_id);
                        if (item) {
                            row.sales_total_price = (item.sales_unit_price * row.quantity).toFixed(2);
                            row.purchase_total_price = (item.purchase_unit_price * row.quantity).toFixed(2);
                            row.single_purchase_amount = item.purchase_unit_price;
                            row.single_sales_amount = item.sales_unit_price;
                            this.updateFooter();
                        }
                        return row;
                    }),
                },
            });
        });
    }
    onItemSaved() {
        this.setState({ showAddItem: false });
        this.fillData();
    }
    get defaultData() {
        return {
            showAddItem: false,
            selectedLine: 0,
            deletedRows: [],
            items: [],
            errors: {
                rows: [],
            },
            formData: {
                name: "",
                note: "",
                product: 0,
                rows: [],
            },
            saving: false,
            tableData: {
                headers: [
                    { label: "Ürün Adı" },
                    { label: "Miktar" },
                    { label: "Alış Fiyatı" },
                    { label: "Satış Fiyatı" },
                ],
                rows: [this.row],
                footer: [],
                footerData: {
                    purchase_total_price: 0,
                    sales_total_price: 0,
                },
                footerTable: [
                    {
                        label: "Toplam Alış Fiyatı",
                        value: "0",
                        id: "purchase_total_price",
                        visible: true,
                    },
                    {
                        label: "Toplam Satış Fiyatı",
                        value: "0",
                        id: "sales_total_price",
                        visible: true,
                    },
                ],
            },
        };
    }

    get row() {
        return [
            {
                type: "select",
                id: "item_id",
                optionValue: "item_name",
                selectedOptionValue: "item_name",
                value: 0,
                optionId: "id",
                defaultText: "SEÇ",
                searchable: true,
                onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
                data: [],
            },
            {
                label: "",
                placeholder: "Miktar",
                type: "text",
                colMd: 12,
                id: "quantity",
            },
            {
                label: "",
                placeholder: "Alış Fiyatı",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "purchase_total_price",
            },
            {
                label: "",
                placeholder: "Satış Fiyatı",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "sales_total_price",
            },
        ];
    }

    updateFooter() {
        const tData = this.state.tableData;
        let footerData = {
            purchase_total_price: 0,
            sales_total_price: 0,
        };

        this.state.formData.rows.forEach((row) => {
            footerData.purchase_total_price += parseFloat(row.quantity * row.single_purchase_amount);
            footerData.sales_total_price += parseFloat(row.quantity * row.single_sales_amount);
        });

        tData.footerData = {
            purchase_total_price: footerData.purchase_total_price.toFixed(2),
            sales_total_price: footerData.sales_total_price.toFixed(2),
        };
    }

    onValidation() {
        // error degerler boş ise error bas
        let formData = this.state.formData;
        let rows = formData.rows;
        let errors = this.state.errors;
        let error = false;
        rows.forEach((row, index) => {
            errors.rows[index] = {
                and_or: false,
                field: false,
                operator: false,
                value: false,
            };
            if (!row.quantity && row.quantity.length > 4) {
                errors.rows[index]["quantity"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.item_id) {
                errors.rows[index]["item_id"] = ["Zorunlu alan"];
                error = true;
            }
        });
        return error ? errors : false;
    }
    /**
     *
     */
    onSave = () => {
        //@todo: Validation kontrolü yapılacak.
        let formData = {
            ...this.state.formData,
        };

        let isEdit = !!this.props.params.id;
        let endpoint = isEdit
            ? `/recipe/update/${this.props.params.id}`
            : "/recipe";
        let requestType = isEdit ? "put" : "post";

        if (isEdit) {
            formData.deletedRows = this.state.deletedRows;
            formData.bom_id = this.props.params.id;
        }

        Api[requestType](endpoint, formData, (response, err) => {
            if (err !== false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                if (response.status === "ok") {
                    toastr.success("Başarılı", "Başarıyla Güncellendi");
                    window.location.replace("/dashboard/product-service#recipe");
                } else {
                    toastr.error("Hata!", "Bir veya daha fazla alanda hata oluştu!");
                    if (response.status === "header_validation") {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                ...response.validation,
                            },
                        });
                    } else if (response.status === "line_validation") {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                rows: [...this.state.rows, ...response.validation],
                            },
                        });
                    }
                }
            }
        });
    };

    /**
     *  veri değişitiğinde çağrılacak
     */
    onChange = (data) => {
        //this.updateTotals();
    };

    /**
     * Triggered when any form field is edited on the page
     * @param {string} field Depends on input ID
     * @param {any} value Input's value
     */
    onFieldChange = (field, value) => {
        /*
         * When the `Product` field is edited, the selected `Product`
         * will be removed from the `Product` data of the rows in the `tableData`.
         */
        if (field === "product") {
            /**
             * Remove the selected product from the list
             * of products to be assigned to the rows in the table.
             */
            let tableData = this.state.tableData;
            let items = [...this.state.items]; //Create a copy of state.items
            items.map((item, index) => {
                if (item.id === value) {
                    delete items[index]; //Delete the selected product from state.items copy.
                }
                return item;
            });
            /**
             * Assign the edited items data to the rows in tableData.
             */
            this.setState({
                tableData: {
                    ...this.state.tableData,
                    rows: (tableData.rows = tableData.rows.map((row, index) => {
                        row[0].data = items;
                        return row;
                    })),
                },
            });
        }

        /**
         * Assign the changed data to formData in state.
         */
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            },
        });
    };

    onRowDelete(row, value) {
        this.setState({
            deletedRows: [...this.state.deletedRows, value],
        });
    }

    onMount = () => null;

    onAddNewItemClick(index) {
        this.setState({ selectedLine: index, showAddItem: true });
    }

    /**
     * Triggered when a row is edited in tableData.
     * @template {{item_id?: number, quantity?: string}} RowData
     * @param {number} index
     * @param {RowData} value
     */
    onChangeRow(index, value) {
        let tableData = this.state.tableData;
        let items = [...this.state.items]; //Create a copy of state.items
        items.map((item, index) => {
            if (
                item.id === value.item_id ||
                item.id === this.state.formData.product
            ) {
                delete items[index]; //Delete the selected product from state.items copy.
            }

            return item;
        });

        /**
         * This will work when there are no more products
         * to be added to the product list.
         */
        if (Object.keys(items).length === 0) {
            this.setState({
                hideAddButton: true,
            });
        }
        //update sales_unit_price and purchase_unit_price
        this.setState({
            formData: {
                ...this.state.formData,
                rows: this.state.formData.rows.map((row, rowIndex) => {
                    if (index === rowIndex) {
                        this.state.items.map((item) => {
                            if (item.id === value.item_id) {
                                let quantity = 0;
                                if(!isNaN(row.quantity)){
                                    quantity = row.quantity;
                                }
                                row = {
                                    ...row,
                                    sales_total_price: (item.sales_unit_price * quantity).toFixed(2),
                                    purchase_total_price: (item.purchase_unit_price * quantity).toFixed(2),
                                    single_purchase_amount: item.purchase_unit_price,
                                    single_sales_amount: item.sales_unit_price
                                }
                            }
                            return item;
                        })
                    }
                    return row;
                }),
            },
        });

        this.updateFooter();

        this.setState({
            tableData: {
                ...this.state.tableData,
                rows: (tableData.rows = tableData.rows.map((row, rowIndex) => {
                    if (index !== rowIndex) {
                        row[0].data = items;
                    }
                    return row;
                })),
            },
        });
    }
    addRow() {}
    render() {
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/product-service")}><i
                    className="fa fa-arrow-left"></i> Geri Dön</a>
                <Portlet title={"Reçete"}>
                    <div
                        className="col-md-12"
                        style={{backgroundColor: "#fff", marginBottom: 5}}
                    >
                        <div className="col-md-5">
                            <EkoFormInput
                                isVertical
                                label={"Ad"}
                                name={"name"}
                                errors={this.state.errors}
                                value={this.state.formData.name}
                                onChange={this.onFieldChange}
                                id={"name"}
                            />
                            <EkoFormSelect
                                isVertical
                                id={"product"}
                                optionValue={"item_name"}
                                selectedOptionValue={"item_name"}
                                errors={this.state.errors}
                                defaultText={"Ürün Seçin"}
                                onChange={this.onFieldChange}
                                optionId={"id"}
                                searchable={true}
                                value={this.state.formData.product}
                                options={this.state.items}
                                label={"Ürün"}
                            />
                        </div>
                        <div className="col-md-7">
                            <label className="control-label control-line">Not</label>
                            <textarea
                                onChange={(data) => this.onFieldChange("note", data.target.value)}
                                name={"note"}
                                errors={this.state.errors}
                                id={"note"}
                                style={{height: 140}}
                                value={this.state.formData.note}
                                placeholder={"Not Giriniz"}
                                className={"form-control adress-area address-editable"}
                            ></textarea>
                        </div>
                    </div>
                    <div
                        style={{
                            padding: 25,
                        }}
                    >
                        <EkoInputTableButtonGroup
                            onCancel={this.props.router.goBack}
                            onSave={this.onSave}
                            onChange={this.onChange}
                            onChangeRow={this.onChangeRow.bind(this)}
                            onRowAdd={this.addRow}
                            onRowDelete={this.onRowDelete.bind(this)}
                            buttonAddClass={this.state.hideAddButton ? "hidden" : ""}
                            errors={this.state.errors}
                            formData={this.state.formData}
                            data={this.state.tableData}
                        />
                    </div>
                    <AddItem
                        showModal={this.state.showAddItem}
                        formData={false}
                        onSave={this.onItemSaved.bind(this)}
                        onCancel={() => {
                            this.setState({showAddItem: false});
                        }}
                        errors={false}
                    />
                </Portlet>
            </div>
        );
    }
}

export default RecipeAddUpdate;
