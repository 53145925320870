import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./index";
import reduxThunk from "redux-thunk";

const configureStore = (preloadedState) => {
  let composeFn = composeWithDevTools;

  const store = createStore(
    reducers,
    preloadedState,
    composeFn(applyMiddleware(reduxThunk))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./", () => {
      const nextRootReducer = require("./").default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
};

export const store = configureStore();
