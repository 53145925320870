import React from "react";

const ReactHighcharts = require("react-highcharts");
const Highcharts = require("highcharts");

export default class EkoChartEmptyDonut extends React.Component {
  changeTitle(title) {
    let chart = this.refs.chart.getChart();
    chart.update({ title: { text: title } });
  }

  shouldComponentUpdate(nextProps) {
    const differentTitle = this.props.title !== nextProps.title;
    const differentData =
      JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data);
    return differentTitle || differentData;
  }

  render() {
    let colors = Highcharts.getOptions().colors;

    colors[0] = "#d3d3d3";
    colors[1] = "#d3d3d3";
    colors[2] = "#d3d3d3";
    colors = colors.map(function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7,
        },
        stops: [
          [0, color],
          [1, Highcharts.Color(color).brighten(-0.3).get("rgb")], // darken
        ],
      };
    });

    const formatter = this.props.formatter;
    let config = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: 'Open Sans",sans-serif',
        },
        height: 305,
      },
      legend: {
        verticalAlign: "bottom",
        layout: "horizontal",
        itemMarginTop: 35,
      },
      title: {
        text: this.props.title,
        align: "center",
        verticalAlign: "middle",
        y: -30,
        style: { fontSize: "11px" },
      },
      yAxis: {
        title: {
          text: this.props.yTitle,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: colors,
        },
      },

      tooltip: {
        enabled: false,
        // formatter: function () {
        //     if (formatter && formatter == 'amount')
        //         return '<b>' + this.point.name + '</b>: ' + Util.convertDecimalToMoney(this.y) + ' ₺';
        //     else return '<b>' + this.point.name + '</b>: ' + this.y;
        // }
      },
      series: [
        {
          name: "outStandingsalesInvoices",
          data: [{ name: "Boş", y: 100 }],
          size: "100%",
          innerSize: "75%",
          showInLegend: true,
          dataLabels: {
            enabled: false,
            formatter: function () {
              if (formatter && formatter === "amount") return "";
              else return "<b>" + this.point.name + "</b>: " + this.y;
            },
          },
        },
      ],
    };

    return <ReactHighcharts config={config} isPureConfig ref="chart" />;
  }
}
