import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { CSS } from "../../css/tab_datatable";
import Style from "style-it";
import EkoDataTable from "../../components/elements/EkoDataTable";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import UtilHelper from "../../util/UtilHelper";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import Util from "../../util/Util";
/**
 *
 */
class FixedAssetSalesInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FixedAssetSalesInvoice: [],
      showUpdate: false
    };

    this.table = null;
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    Api.get("/salesInvoice/getFixedAssetInvoice", (response, err) => {
      this.setState({
        fixedAssetSalesInvoice: response.salesInvoices,
      });
    });
    //
    axios.get(ROOT_URL + "/fixedAsset/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.fixedAssets, null, 2);
      localStorage.setItem("fixed_asset_list", '');
      localStorage.setItem("fixed_asset_list", data);
    });
  }

  openInsert(menuItem) {
    //localStorage.setItem("new_collection_from_who_type", menuItem);

    if (menuItem === 1) {
      this.props.router.push("/dashboard/purchases/purchaseInvoice/add/toFixedAssetInvoice");
    }else if (menuItem === 2) {
      this.props.router.push("/dashboard/assets");
    } else if (menuItem === 3) {
      this.props.router.push("/dashboard/salesInvoice/add/toFixedAsset");
    }
  }

  openUpdate(row) {
    this.props.router.push("/dashboard/salesInvoice/edit/" + row.id);
  }


  get moneyFields() {
    return [
      "price_amount_fc",
      "discount_amount_fc",
      "net_amount_fc",
      "vat_amount_fc",
      "reduction_amount_fc",
      "total_amount_fc"
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fixedAssetSalesInvoice) {
      const fixedAssetSalesInvoice = nextProps.fixedAssetSalesInvoice.map(report => {
        const moneyFields = UtilHelper.money.mapDecimalToMoney(
            this.moneyFields,
            report
        );
        const dateFields = {
          //date: Util.convertFromDatabaseDate(report.date),
          //due_date: Util.convertFromDatabaseDate(report.due_date)
        };

        return {
          ...report,
          ...moneyFields,
          ...dateFields
        };
      });

      this.setState({
        fixedAssetSalesInvoice
      });
    }
  }

  onDelete(row) {
    Api.delete("/fixedAssetSalesInvoice/" + row.id, (response, error) => {
      if (!error && response.status === "ok") {
        toastr.success("Başarıyla Silindi");
        this.fetchData();
      } else {
        toastr.error(error.message ? error.message : "Silinemedi");
      }
    });
  }

  get items() {
    var list = [];
    list =  [
      {
        id: 1,
        link: '/dashboard/assets',
        title: 'Demirbaşlar'
      },
      {
        id: 2,
        link: '/dashboard/fixedAsset',
        title: 'Demirbaş Alış Faturaları'
      },
      {
        id: 4,
        link: '/dashboard/fixedAssetSalesInvoice',
        title: 'Demirbaş Satış Faturaları'
      },
      {
        id: 3,
        link: '/dashboard/depreciation',
        title: 'Amortismanlar'
      }
    ];
    return list;
  }

  goToSource(selectedRow) {
    if (parseInt(selectedRow.id, 10) !== 0)
      this.props.router.push(
          "/dashboard/fixedAssetSalesInvoice/" + selectedRow.id
      );
  }

  onSelectedRowChange = selected => {
    const { status } = selected;

    this.setState({ showUpdate: parseInt(status, 10) === 1 });
  };

  createCustomButtons = ({ isSelected, selectedRow }) => {
    if (isSelected) {
      const { status } = selectedRow;

      return <span></span>;
    }
  };

  render() {
    const insertMenu = [
      {id: 1, name: 'Demirbaş Alış Faturası Ekle'},
      {id: 3, name: 'Demirbaş Satış Faturası Ekle'},
    ];
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          {Style.it(
              CSS,
              <div>
                <EkoDataTable
                    //onRef={item => (this.table = item)}
                    title="Demirbaş Faturaları"
                    tabMenu={this.items}
                    selectedTab={4}
                    showDelete
                    onSelectedRowChange={this.onSelectedRowChange}
                    //addUpdateShow={this.state.showUpdate}
                    createCustomRowButtons={this.createCustomButtons}
                    openInsert={this.openInsert.bind(this)}
                    goToSource={this.goToSource.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.state.fixedAssetSalesInvoice}
                    insertMenu={insertMenu}
                    name={"fixedAssetSalesList"}
                />
              </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewOptions: state.data.viewOptions
  };
};

export default connect(mapStateToProps, actions)(FixedAssetSalesInvoice);
