import React from "react";
import Api from "../../util/Api";

const HighchartsReact = require("react-highcharts");
const Highcharts = require("highcharts");

export default class EkoChartBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      purchases: []
    };
  }
  changeTitle(title) {
    let chart = this.chartRef.getChart();
    chart.update({ title: { text: title } });
  }

  componentWillMount() {
    this.test();
  }

  test() {
    const self = this;

    // console.log("servise girmedi")

    Api.get("/dashboard/bar", (response, err) => {
      let arr = [];
      let arrSales = [];
      let arrMonth = [];
      response.data.forEach(item => {
        // console.log("api girdi")
        let purchases = [];
        purchases = item.Alıslar;
        arr.push(parseFloat(purchases));

        let sales = [];
        sales = item.Satıslar;
        arrSales.push(parseFloat(sales));

        let month = [];
        month = item.month_str;
        arrMonth.push(month);
        self.setState({ purchases: arr, sales: arrSales, month: arrMonth });
      });
    });
  }

  render() {
    let dataPurchases = this.state.purchases;
    let dataSales = this.state.sales;
    let dataMonth = this.state.month;

    let colors = Highcharts.getOptions().colors;
    colors[0] = "#4a8bc2";
    colors[1] = "#4ac268";
    colors[2] = "#ca4741";
    // colors[0] = '#4a8bc2';
    // colors[1] = '#ca4741';
    // colors[2] = '#4a8bc2';
    colors = colors.map(function(color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7
        },
        stops: [
          [0, color],
          [
            1,
            Highcharts.Color(color)
              .brighten(-0.3)
              .get("rgb")
          ] // darken
        ]
      };
    });

    let config = {
      credits: {
        enabled: false
      },
      chart: {
        type: "column",
        style: {
          fontFamily: 'Open Sans",sans-serif'
        },
        height: 381.5
      },
      legend: {
        itemStyle: {
          cursor: "default"
        },
        verticalAlign: "bottom",
        layout: "horizontal"
      },
      title: {
        text: this.props.title,
        align: "center",
        verticalAlign: "middle",
        y: -30,
        style: { fontSize: "11px" }
      },
      xAxis: {
        categories: dataMonth
      },
      yAxis: {
        min: 0,
        title: {
          text: "Tutar"
        }
      },
      plotOptions: {
        column: {
          shadow: false,
          colors: colors
        }
      },

      tooltip: {
        enabled: true
        // formatter: function () {
        //     if (formatter && formatter == 'amount')
        //         return '<b>' + this.point.name + '</b>: ' + Util.convertDecimalToMoney(this.y) + ' ₺';
        //     else return '<b>' + this.point.name + '</b>: ' + this.y;
        // }
      },

      series: [
        {
          name: "Alışlar",
          size: "100%",
          innerSize: "95%",
          showInLegend: true,
          data: dataPurchases
        },

        {
          name: "Satışlar",
          size: "100%",
          innerSize: "95%",
          showInLegend: true,
          data: dataSales
        }
      ]
    };

    return (
      <HighchartsReact
        config={config}
        isPureConfig
        ref={ref => (this.chartRef = ref)}
      />
    );
  }
}
