import React, { Component } from "react";
import Layout from "../../components/layouts/Dashboard";
import {
  EkoFormButton,
  EkoFormCheckbox,
  EkoFormInputText,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import moment from "moment";
import Api from "../../util/Api";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import { Link } from "react-router";

import {
  FETCH_CITIES,
  FETCH_COMPANY,
  FETCH_COUNTIES,
  FETCH_PLAN_CHECK,
  FETCH_TAX_OFFICES
} from "../../actions/types";

class SubscriberPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      saving: false,
      errors: {},
      formFields: {},
      months: [],
      years: [],
      show: false,
      plan: {},
      selectedPlan: {},
      cart_body: {},
      modulesList: [],
      campaign: null
    };
  }


  defaultFormData() {
    return {
      company: {},
      payment: { register_card: true }
    };
  }

  componentWillMount() {
    const self = this;
    if (this.props.params && this.props.params.id) {
      var cart_body = localStorage.getItem("cart_body");
      localStorage.removeItem("cart_body");
      if(!cart_body){
        this.props.router.push("/subscriber");
      }else{
        cart_body = JSON.parse(cart_body);
        this.setState({cart_body:cart_body});
      }
    }
    this.props.fetchCachedData(
      "/data/taxOffices",
      FETCH_TAX_OFFICES,
      "taxOffices",
      "1:day"
    );
    this.props.fetchCachedData("/data/cities", FETCH_CITIES, "cities", "4:day");
    this.props.fetchCachedData(
      "/data/counties",
      FETCH_COUNTIES,
      "counties",
      "4:day"
    );
    this.props.fetchData("/company", FETCH_COMPANY);

    let months = [
      { id: "01" },
      { id: "02" },
      { id: "03" },
      { id: "04" },
      { id: "05" },
      { id: "06" },
      { id: "07" },
      { id: "08" },
      { id: "09" },
      { id: "10" },
      { id: "11" },
      { id: "12" }
    ];
    moment().locale("tr");
    let year = parseInt(moment().format("YYYY"), 10);
    let years = [];
    for (let i = 0; i < 8; i++) {
      let value = { id: (year + i).toString() };
      years.push(value);
    }
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};

    this.setState({
      formData: formData,
      errors: errors,
      months: months,
      years: years
    });
    Api.get("/plan/getModulesExtraList", (response, err) => {
      if (err) {
        toastr.error("Hata", "Modüller Extra listelenirken hata oluştu.");
      } else {
        this.setState({ modulesExtra: response });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.company &&
      this.state.formData.company !== nextProps.company
    ) {
      this.getCompany(HelperContainer.filterObject(nextProps.company));
      this.getModulesList();
      this.getSelectedPackageData();
    }
  }

  getCompany = values => {
    let formData = this.state.formData;
    formData.company = Object.assign({}, values, {});
    this.setState({ formData: formData });
  };

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    if (field === "city_id") {
      if (formData[field] !== value) {
        formData["county_id"] = null;
      }
    }
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    if (type === "company" && field === "tax_office_code") {
      let selected = this.props.taxOffices.filter(data => {
        return data.code === value;
      });
      bigForm.company.tax_office_name = selected[0].name || "";
    }
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    self.setState({ saving: true });

    let company = Object.assign({}, self.state.formData.company, {
      expiry_date: Util.convertFromDatabaseDate(
        self.state.formData.company.expiry_date
      ),
      lock_before: Util.convertFromDatabaseDate(
        self.state.formData.company.lock_before
      )
    });
    let card_number = self.state.formData.payment.card_number || "";
    let payment = Object.assign({}, self.state.formData.payment, {
      tax_identity_number: company.tax_identity_number,
      firstname: company.firstname,
      lastname: company.lastname,
      city_id: company.city_id,
      county_id: company.county_id,
      address: company.address,
      register_card: self.state.formData.payment.register_card ? 1 : 0,
      card_number: card_number.replace(/[#-]/g, ""),
      plan_id: self.props.params.id,
      payment_type: this.state.cart_body.payment_type,
      selectedModulesList: this.state.modulesList.filter(module => this.state.cart_body.modules.includes(module.id)),
      modulesExtra:{bank_account_integration_package_val:this.state.cart_body.modules_extra.bank_account_integration_package_val,kontor_val:this.state.cart_body.modules_extra.kontor_val},
      phone: company.phone
    });

    let errors = self.state.errors;

    let url = "/company";
    let func = "put";

    Api[func](url, company, function(response, postErrors) {
      if (postErrors) {
        errors.company = postErrors;
        self.setState({ saving: false, errors: errors });
      } else {
        if (company.id) {
          Api.post("/subscriberPayment", payment, (response, postErrors) => {
            if (response) {
              let result = response.result;
              self.setState({ saving: false });
              if (result.status === "success") {
                localStorage.setItem("pay_plan_id", self.props.params.id);
                // window.location.href = result.url + "api/subscriberPayment/redirect?token=" + result.threeDSHtmlContent;
                window.location.href = result.url;
              } else {
                toastr.error("Hata", result.errorMessage);
                self.setState({ saving: false });
              }
            } else {
              errors.company = postErrors;
              errors.payment = postErrors;
              self.setState({ saving: false, errors: errors });
              if (postErrors.message) {
                toastr.error(postErrors.message);
              }
            }
          });
        }
        self.props.onSave();
      }
    });
  }

  onCancel() {}

  onFalse() {
    // silme hata veriyor
  }

  taxOfficeAddNewColumn() {
    let taxOffices = this.props.taxOffices || [];
    taxOffices.map(item => {
      if (item && item.city) {
        item.value = item.name + " - " + item.city.name;
      }
      return item;
    });
    return taxOffices;
  }

  getCounties(id) {
    let counties = this.props.counties || [];
    return counties.filter(data => {
      return data.city_id === id;
    });
  }
  getSelectedPackageData(){
    var rt = "";
    Api.get("/plan/" + this.props.params.id, (response, error) => {
      if (response.plan) {
        this.setState({ selectedPlan: {'desc':response.plan.description,"price":(this.state.cart_body.payment_type==='aylik'?parseInt(response.plan.price_exc_vat, 10):parseInt(response.plan.annually_price, 10))+" ₺"} });
      }
    });
    return rt;
  }
  getSelectedPackageBankAccountIntegrationPackage(id) {
    const foundItem = this.state.modulesExtra.hesap_hareketi.find(item => item.id === parseInt(id, 10));
    if(foundItem){
      return [foundItem.name,parseInt(foundItem.price),parseInt(foundItem.val)];
    } else {
      return ["",0,0];
    }
  }
  getModulesList() {
    var _self = this;
    Api.get("/plan/getModulesList", (response, err) => {
      if (err) {
        toastr.error("Hata", "Modüller listelenirken hata oluştu.");
      } else {

        const selectedModules = response.result;
        selectedModules.forEach((selectedModule) => {
        if (selectedModule) {
          if(selectedModule.id===1){
            selectedModule.price_monthly = this.getSelectedPackageBankAccountIntegrationPackage(this.state.cart_body.modules_extra.bank_account_integration_package_id)[1];
            selectedModule.price_yearly = this.getSelectedPackageBankAccountIntegrationPackage(this.state.cart_body.modules_extra.bank_account_integration_package_id)[1];
            selectedModule.enterprise_price_monthly = this.getSelectedPackageBankAccountIntegrationPackage(this.state.cart_body.modules_extra.bank_account_integration_package_id)[1];
            selectedModule.enterprise_price_yearly = this.getSelectedPackageBankAccountIntegrationPackage(this.state.cart_body.modules_extra.bank_account_integration_package_id)[1];
          }
        }
        });

        _self.setState({ modulesList: response.result });
      }
    });
  }
  generateSelectedModulesJSX() {
    const cartModules = this.state.cart_body?.modules || []; // Varsayılan olarak boş dizi
    const paymentType = this.state.cart_body?.payment_type || 'aylik'; // Varsayılan olarak 'aylik'

    return (<div>
          {cartModules.length > 0 ? (
              cartModules.map(selectedId => {
                const selectedModule = this.state.modulesList.find(module => module.id === selectedId);
                if (selectedModule) {
                  const modulePrice = parseInt(paymentType === 'aylik' ? selectedModule.price_monthly : selectedModule.price_yearly, 10);

                  return (
                      <div className={"row"} key={selectedModule.id}>
                        <div className={"col-md-8"} style={{ "fontSize": "18px" }}>{selectedModule.name}</div>
                        <div className={"col-md-4"} style={{ "fontSize": "18px" }}>{this.state.cart_body.plan_id!==9?modulePrice:"0.00" + " ₺"}</div>
                        {selectedModule.id===1?
                            <div className={"col-md-12"} style={{"fontSize": "14px","color":"gray"}}>{this.state.cart_body.modules_extra.bank_account_integration_package_name} ({this.state.cart_body.modules_extra.bank_account_integration_package_price} TL)</div>
                            :""}
                      </div>

                  );
                }
                return null;
              })
          ) : (
              <div></div>
          )}
        </div>
    );
  }
  render() {
    //if (!this.state.show || !this.props.checkPlans) return <EkoSpinner />;
    let taxOffices = this.taxOfficeAddNewColumn();
    let cities = this.props.cities || [];
    const user = JSON.parse(localStorage.getItem("user"));
    const campaign = this.state.campaign;
    moment.locale("tr");
    let expiryDate = moment(user.expiry_date, "YYYY-MM-DD")
      .add(1, "M")
      .format("LL");
    let validTo = campaign
      ? moment(campaign.valid_to, "DD-MM-YYYY").format("LL")
      : null;
    let today = moment().format("YYYY-MM-DD");
    let plan = this.state.plan || [];
    let counties = this.getCounties(this.state.formData.company.city_id || "");
    let style = this.state.campaign ? { textDecoration: "line-through" } : {};
    let price_inc_vat = this.state.campaign
      ? (
          plan.price_inc_vat -
          plan.price_inc_vat * this.state.campaign.discount_rate
        ).toFixed(2)
      : null;
    document.body.className += " page-header-menu-fixed";
    const companyProps = {
      onChange: this.setFieldChange.bind(this, "company"),
      onMount: this.setFieldMount.bind(this, "company"),
      formData: this.state.formData.company,
      errors: this.state.errors.company
    };
    const paymentProps = {
      onChange: this.setFieldChange.bind(this, "payment"),
      onMount: this.setFieldMount.bind(this, "payment"),
      formData: this.state.formData.payment,
      errors: this.state.errors.payment
    };
    return (
      <Layout visibleMenu={true}>
        <div>
          <div className="row">
            <div className="col-md-4">
              <h3>Seçilen Paket ve Modüller</h3>
                  {
                    this.state.cart_body.plan_id!==0?<div className={"row"}>
                      <div className={"col-md-8"} style={{"fontSize":"18px"}}>{this.state.selectedPlan.desc}</div>
                      <div className={"col-md-4"} style={{"fontSize":"18px"}}>{this.state.selectedPlan.price}</div>
                    </div>:""
                  }
              {
                this.state.cart_body.modules_extra.kontor_id!=="0"?<div className={"row"}>
                  <div className={"col-md-12"} style={{"fontSize":"14px","color":"gray"}}>{this.state.cart_body.modules_extra.kontor_name} ({this.state.cart_body.modules_extra.kontor_price} TL)</div>
                </div>:""
              }
              {this.generateSelectedModulesJSX()}
              <h4  style={{"borderTop":"1px #bdbdbd solid","paddingTop":"10px"}}>
                <b>{this.state.cart_body.payment_type==='aylik'?"Aylık":"Yıllık"}</b>
              </h4>
              <b>
                <span style={style}>{this.state.cart_body.payment_amount}</span>{" "}
                {price_inc_vat ? price_inc_vat : ""} TL / {this.state.cart_body.payment_type==='aylik'?"Aylık":"Yıllık"}
              </b>{" "}
              KDV Dahil
              <br />
              <p>
                Kredi kartınızdan,{" "}
                <span style={style}>{this.state.cart_body.payment_amount}</span>{" "}
                {price_inc_vat ? price_inc_vat : ""} TL tahsil edilerek
                aboneliğiniz başlayacak, elektronik faturanız 7 gün içerisinde
                e-posta adresinize gönderilecektir.
              </p>
              <Link to="/subscriber">
                Paketinizi değiştirmek için tıklayın.
              </Link>
            </div>
            <div className="col-md-8">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption  text-center">
                    <span className="caption-subject">
                      Kredi kartı ve fatura adres bilgilerinizi girerek EkoHesap
                      aboneliğinizi başlatabilirsiniz.
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <EkoFormInputText
                    {...paymentProps}
                    label="KART ÜZERİNDEKİ İSİM"
                    id="card_holder_name"
                    isVertical={true}
                  />
                  <EkoFormInputText
                    {...paymentProps}
                    label="KREDİ KARTI NUMARASI"
                    id="card_number"
                    mask="9999-9999-9999-9999"
                    type="mask"
                    isVertical={true}
                  />
                  <label className="control-label control-line">
                    SON KULLANMA TARİHİ
                  </label>
                  <div className="row">
                    <div className="col-md-5">
                      <EkoFormSelect
                        {...paymentProps}
                        id="expire_month"
                        optionValue={"id"}
                        isVertical={true}
                        defaultText="AY"
                        options={this.state.months}
                        label=""
                      />
                    </div>
                    <div className="col-md-7">
                      <EkoFormSelect
                        {...paymentProps}
                        id="expire_year"
                        optionValue={"id"}
                        isVertical={true}
                        defaultText="YIL"
                        options={this.state.years}
                        label=""
                      />
                    </div>
                  </div>
                  <EkoFormInputText
                    {...paymentProps}
                    label="CVC NUMARASI"
                    id="cvc"
                    mask="999"
                    type="mask"
                    isVertical={true}
                  />
                  <div className="row">
                    <img
                      src="/app/images/iyzico@2x.png"
                      width={180}
                      height={100}
                      alt="Bulunamadı"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <EkoFormInputText
                    {...companyProps}
                    label="AD/FİRMA ÜNVANI"
                    id="firstname"
                    isVertical={true}
                  />
                  <EkoFormInputText
                    {...companyProps}
                    label="SOYAD/FİRMA ÜNVANI DEVAMI"
                    id="lastname"
                    isVertical={true}
                  />
                  <EkoFormSelect
                    {...companyProps}
                    id="tax_office_code"
                    searchable={true}
                    optionId={"code"}
                    optionValue={"value"}
                    isVertical={true}
                    defaultText="Seçiniz"
                    options={taxOffices}
                    label="VERGİ DAİRESİ"
                  />
                  <EkoFormInputText
                    {...companyProps}
                    label="VERGİ/TC NO"
                    id="tax_identity_number"
                    maxLength={11}
                    type="number"
                    isVertical={true}
                  />
                  <EkoFormInputText
                    {...companyProps}
                    label="ADRES"
                    id="address"
                    isVertical={true}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <EkoFormSelect
                        {...companyProps}
                        searchable={true}
                        id="city_id"
                        isVertical={true}
                        defaultText="Seçiniz"
                        options={cities}
                        label="İL"
                      />
                    </div>
                    <div className="col-md-6">
                      <EkoFormSelect
                        {...companyProps}
                        searchable={true}
                        id="county_id"
                        isVertical={true}
                        defaultText="Seçiniz"
                        options={counties}
                        label="İLÇE"
                      />
                    </div>
                  </div>
                  <br />
                </div>
                <div className="buttonProccess">
                  <div className="buttonProccess3">
                    <EkoFormCheckbox
                      {...paymentProps}
                      className="mt-checkbox mt-padding"
                      id="register_card"
                      onFalse={this.onFalse}
                      label=" Kredi kartımı kaydet ve ödemelerimi otomatik olarak yap"
                    />
                  </div>
                  <EkoFormButton
                    label="ABONELİĞİMİ BAŞLAT"
                    isLoading={this.state.saving}
                    onClick={this.onSave.bind(this)}
                  />
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    taxOffices: state.data.taxOffices,
    cities: state.data.cities,
    counties: state.data.counties,
    company: state.data.company,
    checkPlans: state.data.checkPlans
  };
}

export default connect(mapStateToProps, actions)(SubscriberPayment);
