import React, {Component} from 'react'
import {browserHistory, Link} from 'react-router'

export default class Index extends Component {

    componentWillMount() {
        browserHistory.push('/login')
    }

    render() {
        return (
            <div>
                <div className="container">
                    <Link to='/login'>Giriş yap</Link> yada <Link to='/dashboard'>Panel</Link>'e git
                </div>
            </div>
        )
    }
}
