import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import {EkoForm, EkoFormButton} from "../elements/EkoForm";

class paynetApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      savingModal: false,
      showModal: false,
      errors: {},
      formFields: {},
      secret_code:"",
      publishable_code:"",
    };
  }
  componentWillMount() {
    this.fetch();
  }
  fetch() {
    Api.get("/paynet/getInformation", (response, errors) => {
      console.log('responseresponseresponseresponse',response);
      if (errors) {
        return;
      }
      let getData = response.data;
      this.setState({
        secret_code: getData.secret_code,
        publishable_code: getData.publishable_code,
      });
    });
  }
  onSave() {
    this.setState({ saving: true });
    Api.post("/paynet/saveInformation", {
        secret_code: this.state.secret_code,
        publishable_code: this.state.publishable_code,
        }, (response, errors) => {
        if (errors) {
            this.setState({ saving: false });
            toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            return;
        }
        toastr.success("Başarılı!", "İşlem tamamlandı");
        this.setState({ saving: false });
        });

    }
  render() {
    return (
    <div className="portlet light">
      <div className="portlet-title">
        <div className="caption font-dark">
          <span className="caption-subject uppercase">
            Paynet Bilgileri
          </span>
        </div>
      </div>
      <div className="portlet-body">
        <EkoForm>
          <div className="row">
            <div className="col-md-12">
              <p style={{"textAlign":"center"}}>Paynet paneline giriş yaptıktan sonra sağ taraftaki hesabım menüsü altında "Yönetim Paneli"ne tıklayın.<br />Ardından açılan sayfada "Entegrasyon Bilgileri"ne tıklayın. Sayfa içindeki bilgileri aşağıdaki kutulara yazın.</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="name_surname" className="control-line control-label"><span>Secret Key</span></label>
              <div>
                <input
                    id="secret_code"
                    type="text"
                    className="form-control"
                    value={this.state.secret_code}
                    onChange={evt => this.setState({secret_code: evt.target.value})}
                />
              </div>
            </div>


            <div className="col-md-6">
              <label htmlFor="oda_sicil" className="control-line control-label"><span>Publishable Key</span></label>
              <input
                  id="publishable_code"
                  type="text"
                  className="form-control"
                  value={this.state.publishable_code}
                  onChange={evt => this.setState({publishable_code: evt.target.value})}
              />
            </div>
          </div>

          <div style={{"display": "flex", "justifyContent": "center", "marginTop": 20}}>
            <EkoFormButton
                label={"Kaydet"}
                faClass="fa fa-plus"
                isLoading={this.state.saving}
                onClick={() => this.onSave()}
            />
          </div>


        </EkoForm>
      </div>
      <br/>
    </div>
  )
    ;
  }
}

export default paynetApplication;
