import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import Style from "style-it";
import { VCSS } from "../../css/voucher.js";
import { FETCH_CUSTOMERS_ALL, FETCH_SUPPLIERS_ALL } from "../../actions/types";
import UtilHelper from "../../util/UtilHelper";
import AddSoleprofv2Invoice from "./AddSoleprofv2Invoice";

class Soleprofv2InvoiceAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      fromWhos: []
    };
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        item_id: 0,
        item_code: "",
        istisna_kodu: 0,
        description: "",
        stoppage_ratio: "",
        vat_reduction_amount_fc: 0,
        vat_reduction_type_id: 0,
        quantity: 1,
        unit_price_fc: 0.0,
        unit_id: 0,
        net_amount_fc: 0,
        vat_rate_type_id: 0,
        vat_amount_fc: 0
      }
    );
  }

  defaultFormData() {
    return {
      invoice: {
        customer_id: 0
      },
      rows: [this.defaultRowData()],
      footer: {}
    };
  }

  defaultErrors() {
    return {
      rows: [
        {
          item_id: false,
          description: false,
          vat_reduction_type_id: false,
          vat_reduction_amount_fc: false,
          stoppage_ratio: false,
          quantity: false,
          unit_price_fc: false,
          unit_id: false,
          net_amount_fc: false,
          vat_rate_type_id: false,
          vat_amount_fc: false
        }
      ],
      invoice: {},
      footer: {}
    };
  }

  fetchFromWho() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
  }

  setFromWhos(nextProps) {
    if (nextProps.customersAll) {
      const fromWhos = nextProps.customersAll.filter(function(customer) {
        return customer.is_active === 1;
      });
      this.setState({ fromWhos: fromWhos });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customersAll) this.setFromWhos(nextProps);
  }

  prepareInvoiceLineForEdit(line, isCopied) {
    let row = {};

    if (!isCopied) row.id = line.id;
    row.soleprofv2_id = line.soleprofv2_id;
    row.item_id = line.item_id;
    row.item_code = line.item_code;
    row.istisna_kodu = line.istisna_kodu;
    row.description = line.description;
    row.vat_reduction_type_id = line.vat_reduction_type_id;
    row.stoppage_code_id = line.stoppage_code_id;
    row.stoppage_ratio = line.stoppage_ratio;
    row.vat_reduction_amount_fc = Util.convertDecimalToMoney(line.vat_reduction_amount_fc);
    row.quantity = Util.convertDecimalToMoney(line.quantity);
    row.unit_id = line.unit_id;
    row.vat_rate_type_id = line.vat_rate_type_id;
    row.unit_price_fc = UtilHelper.money.convertDecimalToMoney(
      line.unit_price_fc
    );
    row.net_amount_fc = Util.convertDecimalToMoney(line.net_amount_fc);
    row.vat_amount_fc = Util.convertDecimalToMoney(line.vat_amount_fc);

    return row;
  }

  prepareInvoiceForEdit(soleprofv2) {
    let ret = {};

    ret.id = soleprofv2.id;
    ret.customer_id = soleprofv2.customer_id
      ? parseInt(soleprofv2.customer_id, 10)
      : null;
    ret.currency = soleprofv2.currency;
    ret.currency_rate = Util.convertDecimalToMoney(soleprofv2.currency_rate);
    ret.description = soleprofv2.description;
    ret.vat_reduction_type_id = soleprofv2.vat_reduction_type_id;
    ret.stoppage_ratio = soleprofv2.stoppage_ratio;
    ret.customer_name = soleprofv2.customer_name || "";
    ret.document_number = soleprofv2.document_number;
    ret.date = Util.convertFromDatabaseDate(soleprofv2.date);
    ret.due_date = soleprofv2.due_date
      ? Util.convertFromDatabaseDate(soleprofv2.due_date)
      : "";
    ret.updated_at = soleprofv2.updated_at || null;
    ret.is_checked = soleprofv2.is_checked || false;

    return ret;
  }

  componentWillMount() {
    var self = this;
    this.fetchFromWho();

    if (this.props.params && this.props.params.id) {
      const invoiceId = this.props.params.id;
      let formData = this.state.formData;
      Api.paralel(
        ["/soleprofv2/" + invoiceId, "/soleprofv2Line/showAll/" + invoiceId],
        function(responses, error) {
          const docUrl = responses[0].soleprofv2?.document_url;
          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }
          formData.invoice = self.prepareInvoiceForEdit(responses[0].soleprofv2);

          let lines = [];
          if (
            responses[1].soleprofv2Lines &&
            responses[1].soleprofv2Lines.length > 0
          ) {
            lines = responses[1].soleprofv2Lines;

            formData.rows = lines.map(function(line) {
              return self.prepareInvoiceLineForEdit(line, false);
            });
            self.setState({ formData: formData });
          }
        }
      );
    }
  }

  onCancel() {
    this.props.router.push("/dashboard/salesInvoice/soleprofv2s");
  }

  render() {
    const editId =
      this.props.params && this.props.params.id ? this.props.params.id : false;
    return Style.it(
      VCSS,
      <div>
        <AddSoleprofv2Invoice
          {...this.state}
          editId={editId}
          onRefresh={this.fetchFromWho.bind(this)}
          errors={this.defaultErrors()}
          defaultErrors={() => this.defaultErrors()}
          defaultRowData={this.defaultRowData()}
          formData={this.state.formData}
          defaultFormData={() => this.defaultFormData()}
          onCancel={this.onCancel.bind(this)}
          viewOptions={this.props.viewOptions}
          redirectToBase={this.onCancel.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customersAll: state.data.customersAll,
    viewOptions: state.data.viewOptions,
    pageLoading: state.page.loading
  };
}

export default connect(mapStateToProps, actions)(Soleprofv2InvoiceAddUpdate);
