import React, {Component} from "react";
import {Modal, Tab, Tabs} from "react-bootstrap";
import IVDConfiscation from "./IVDConfiscation";
import IVDNotification from "./IVDNotification";
import IVDTaxPayment from "./IVDTaxPayment";
import IVDTaxDebt from "./IVDTaxDebt";
import IVDTaxPlate from "./IVDTaxPlate";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import Util from "../../util/Util";
import {EkoForm, EkoFormButton, EkoFormCheckbox, EkoFormInputText} from "../../components/elements/EkoForm";

class IVDScreens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {password: {}, onay: {}},
            formFields: {},
            errors: {},
            goverment: false,
            sms: false,
            rand: ""
        }
    }

    componentWillMount() {
        Api.get("/company/goverment-information", (data, errors) => {
            data = data.data;
            if (data.EArsivUsername == '' || data.EArsivPassword == '' || data.EArsivUsername == null || data.EArsivPassword == null) {
                this.setState({goverment: false});
            } else {
                this.setState({goverment: true});
                var rand = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000).toString();
                let encoded = btoa(btoa(rand));
                this.setState({rand: rand});
                Api.get("/EArchiveInteraktifVD/ivd-check-sms/" + encoded, (data, errors) => {
                    data = data.data;
                    if (data && data.result === true) {
                        //sms onayı daha önce alınmış sayfayı direkt aç
                        this.setState({sms: true});
                    } else {
                        //sms onayı için ekrana formu getir
                        this.setState({sms: false});
                    }
                });
            }
        });
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type] && errors[type][field])
            errors[type][field] = false;
        let state = this.state;
        state.formData[type] = formData;
        state.errors = errors;
        this.setState(state);
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    send() {
        if (typeof this.state.formData.password.password !== "undefined") {
            if (this.state.formData.password.password.toString() == this.state.rand.toString()) {
                this.setState({sms: true});
                if (typeof this.state.formData.password.onay !== "undefined") {
                    if (this.state.formData.password.onay == true) {
                        Api.get("/EArchiveInteraktifVD/ivd-onay-sms", (data, errors) => {});
                    }
                }
            } else {
                toastr.error("SMS Şifrenizi Yanlış Girdiniz");
            }
        }
    }

    onFalse() {
        // silme hata veriyor
    }

    render() {
        const inputProps = {
            onChange: this.setFieldChange.bind(this, "password"),
            onMount: this.setFieldMount.bind(this, "password"),
            formData: this.state.formData.password,
            errors: this.state.errors.password
        }
        if (this.state.goverment == true && this.state.sms == true) {
            return (
                <div>
                    <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                       onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                    <div className="portlet light">
                        <div className="portlet-title">
                            <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            İnteraktif Vergi Dairesi İşlemleri
          </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <Tabs defaultActiveKey="e_haciz" id="uncontrolled-tab-example">
                                <Tab eventKey="e_haciz" title="E-Haciz">
                                    <IVDConfiscation/>
                                </Tab>
                                <Tab eventKey="e_tebligat" title="E-Tebligat Listesi">
                                    <IVDNotification/>
                                </Tab>
                                <Tab eventKey="odeme_ve_alindilarim" title="Ödemelerim ve Alındılarım">
                                    <IVDTaxPayment/>
                                </Tab>
                                <Tab eventKey="borc_odeme_ve_detay" title="Borç Ödeme ve Detay">
                                    <IVDTaxDebt/>
                                </Tab>
                                <Tab eventKey="e_vergi_levhasi" title="E-Vergi Levhası">
                                    <IVDTaxPlate/>
                                </Tab>
                            </Tabs>
                        </div>
                        <br/>
                    </div>
                </div>
            );
        } else if (this.state.goverment == false) {
            return (
                <div>
                    <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                       onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                    <div className="container">
                        <div style={{textAlign: "center"}}
                             className="alert alert-danger  fade in alert-dismissable red">
                            Şirket Bilgileri Sayfası, Kurum Giriş Bilgileri Sekmesinden IVD Bilgilerinizi Girmelisiniz.
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.sms == false) {
            return (
                <div>
                    <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                       onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                    <div className="portlet light">
                        <div className="portlet-title">
                            <div className="caption font-dark">
                          <span className="caption-subject bold uppercase">
                            SMS Onayı
                          </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="container">
                                <div style={{textAlign: "center", width: "94%"}}
                                     className="alert alert-info  fade in alert-dismissable red">
                                    İnternet Vergi Dairesinde Kayıtlı Olan Numaranıza Bir SMS Gönderdik. Gelen SMS'i
                                    Aşağıdaki Kutuya Giriniz.
                                    <br/>Bu SMS Personellerinizin Bu Sayfaya Erişememesi İçindir. <br/>İsterseniz
                                    Aşağıdaki
                                    Onay Kutusuna Tıklayarak Bir Sonraki İşlemde SMS Şifresi Girmeden Devam
                                    Edebilirsiniz.
                                    <br/><br/>
                                    Önemli Not: Bu Sayfayı Sadece Rolü Admin Olan Kullanıcılar Görecektir. Lütfen
                                    Çalışanlarınıza Admin Dışında Roller Tanımlayıp Ekohesap'a Giriş Yapmasını
                                    Sağlayınız.
                                </div>
                                <EkoForm formId={"update_user_modal"}>
                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <EkoFormInputText {...inputProps}
                                                              id="password"
                                                              type="text"
                                                              label="SMS İle Gelen Şifre"
                                                              isVertical/>
                                            <EkoFormCheckbox
                                                {...inputProps}
                                                className="mt-checkbox mt-padding"
                                                id="onay"
                                                onFalse={this.onFalse}
                                                label="IVD Bilgilerim Sayfasını Açmak İçin Bir Daha SMS Gönderme"
                                            />
                                            <EkoFormButton label={'Gönder'}
                                                           onClick={this.send.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </EkoForm>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            );
        }
    }
}

export default IVDScreens;
