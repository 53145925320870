import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
import Api from "../../util/Api";
//import EkoToastrError from '../../components/elements/EkoToastrError'
import { FETCH_TRANSFERS } from "../../actions/types";

class Transfers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      errors: {},
      validationFields: [],
      transfers: [] //table Item
    };
  }

  fetchCollections() {
    this.props.fetchData("/internalTransfer/list", FETCH_TRANSFERS);
  }

  componentWillMount() {
    this.fetchCollections();
  }

  openUpdate(selectedRow) {
    this.props.router.push("/dashboard/transfers/edit/" + selectedRow.id);
  }

  openInsert(menuItem) {
    localStorage.setItem("new_transfer_type", menuItem);
    this.props.router.push("/dashboard/transfers/add");
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/internalTransfer/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchCollections();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  render() {
    const insertMenu = [
      {
        id: 1,
        name: "Hesaplar Arası"
      },
      {
        id: 2,
        name: "Firmalar Arası"
      }
    ];
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <EkoDataTable
              openInsert={this.openInsert.bind(this)}
              openUpdate={this.openUpdate.bind(this)}
              onDelete={this.onDelete.bind(this)}
              data={this.props.transfers}
              insertMenu={insertMenu}
              name={"internalTransfer"}
          ></EkoDataTable>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transfers: state.data.transfers
  };
}

export default connect(mapStateToProps, actions)(Transfers);
