import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";
import Api from '../../util/Api'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";
import AddNewWage from './AddNewWage'
import {Link} from "react-router";

class WagesList extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            records: [],
            formData: {},
            page_loading: true,
            sgkBranches: {},
            sgkBranchesLoaded: false,
        }

    }
    componentWillMount() {
        this.getCompanySgkBranches();
        this.fetchRecordedData();
    }
    componentDidMount() {
        this.render();
    }
    componentWillUnmount() {
    }
    fetchRecordedData() {
        var self = this;
        self.setState({page_loading: true, showInsertModal: false, formData: false, records: []});
        //if (self.state.sgkBranchesLoaded){
            Api.get("/company/getCompanyWagesList", (response, errors) => {
                if (errors) {
                    return;
                }
                let getData = response.data;
                self.setState({records: getData, page_loading: false });
            });
        //}
    }
    onAfterSave() {
        this.fetchRecordedData();
    }
    openPopup() {
        if (this.state.sgkBranches.length > 0){
            this.setState({showInsertModal: true});
        }else{
            toastr.confirm('Kayıtlarda işletmenize ait Merkez/Şube bilgisi bulunamamıştır. Bordro ekleyebilmek için lütfen önce Merkez/Şube ekleyiniz.',
                {
                    onOk: () => {
                        window.top.location = '/dashboard/companySettings#sgk_isyeri';
                    },
                    onCancel: () => {
                    },
                    okText: "Merkez/Şube Ekle",
                    cancelText: "Tamam"
                }
            );
        }
    }
    
    getCompanySgkBranches() {
        var self = this;

        Api.get("/company/getCompanySgkBranches", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            self.setState({sgkBranches: getData, sgkBranchesLoaded: true});
        });
    }
    deleteRecord(itemId) {
        var self = this;
        toastr.confirm('İlgili döneme ait daha önce yüklenmiş bordro kayıtlarınız varsa silinecek/güncellenecektir. Onaylıyor musunuz?',
            {
                onOk: () => {
                    Api.put("/company/deleteCompanyWage", {id: itemId}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == "1") {
                                toastr.success("Başarılı", "Kayıt silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                self.fetchRecordedData();
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    showRecordDetails(itemId) {
        var self = this;
        Api.get("/company/getCompanyWageDetails/"+itemId, (response, errors) => {
            if (errors) {
                return;
            }
            //console.log(response.data);
            //this.setState({formData: response.data, showInsertModal: true});
        });
    }
    getMonthName(monthNumber) {
        if (monthNumber == 1){
            return "Ocak"
        }else if (monthNumber == 2) {
            return "Şubat"
        }else if (monthNumber == 3) {
            return "Mart"
        }else if (monthNumber == 4) {
            return "Nisan"
        }else if (monthNumber == 5) {
            return "Mayıs"
        }else if (monthNumber == 6) {
            return "Haziran"
        }else if (monthNumber == 7) {
            return "Temmuz"
        }else if (monthNumber == 8) {
            return "Ağustos"
        }else if (monthNumber == 9) {
            return "Eylül"
        }else if (monthNumber == 10) {
            return "Ekim"
        }else if (monthNumber == 11) {
            return "Kasım"
        }else if (monthNumber == 12) {
            return "Aralık"
        }
    }
    findBranchName(sgkBranchId) {
        if (sgkBranchId > 0) {
            let data = this.state.sgkBranches;
            if (this.state.sgkBranches.length > 0){
                let buldum = data.find((element) => {
                    return element.id === sgkBranchId;
                })
                if (buldum?.isyeri_adi){
                    return buldum.isyeri_adi;
                }
            }
            return "-";
        }
        return "-";
    }

    render() {
        let apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);
        var employee_list = localStorage.getItem("employee_list");
        const user = JSON.parse(localStorage.getItem('user'));
        employee_list = JSON.parse(employee_list);

        return (<>
            <div>
                {(this.state.page_loading == true && this.state.sgkBranchesLoaded == false) ? <EkoSpinner /> :
                    <>
                        {this.state.sgkBranches.length > 0 &&
                            <AddNewWage
                                showModal={this.state.showInsertModal}
                                editData={this.state.formData}
                                branchesList={this.state.sgkBranches}
                                onSave={() => {
                                    this.setState({ showInsertModal: false })
                                    this.fetchRecordedData()
                                } }
                                onCancel={() => {
                                    this.setState({ showInsertModal: false })
                                    this.fetchRecordedData()
                                } }
                                errors={this.state.errors} />
                        }
                    
                    
                    <div style={{textAlign: "right"}} onClick={this.openPopup.bind(this)}>
                        <button type='button' className='btn btn-default blue btn-sm'><i className='fa fa-plus'></i>Yeni Bordro Ekle</button>
                    </div>
                        {employee_list.employees.length >= 50  && user.plan_id!=="10"?
                            <div className="alert alert-danger text-center">
                                50 den Fazla Çalışan Eklediniz. Bu Durumda Bir Üst Pakete Geçmeniz Gerekli.<br /> <span style={{"fontWeight":"bold"}}>Entrprise Paketi (50'den Fazla Çalışan Seçeneği)</span> Seçmelisiniz.
                                <br/>
                                <br/>
                                <Link to="/subscriber" className="btn btn-default blue">
                                    <i className="icon-envelope"/>
                                    Abone Ol
                                </Link>
                            </div> : ""}

                        <table className='table table-striped'>
                            <thead>
                            <tr>
                            <th>Dönem</th>
                                <th>Şube</th>
                                <th>Yüklenme Tarihi</th>
                                <th>İçe Aktarım</th>
                                <th>Personel Listesi & Detaylar</th>
                                <th>Yüklenen Dosya</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.records.map((record) =>
                                    <tr key={record.id}>
                                        <td>{record.year}-{this.getMonthName(record.month)}</td>
                                        <td>{this.findBranchName(record.company_branch_id)}</td>
                                        <td>{record.created_at}</td>
                                        <td>{record.file_type == "1" ? "Zirve" : "Luca"}</td>
                                        <td>
                                            <a href={'/dashboard/wagesheet/' + record.id} className='btn btn-sm btn-primary'><i className='fa fa-eye'></i></a>
                                        </td>
                                        <td>
                                            <a href={apiUrl + "/panel/download.php?isLocal=1&folder=wagesheets&file=" + record.file} target={"_blank"} className='btn btn-sm btn-primary'><i className='fa fa-download'></i></a>
                                        </td>
                                        <td>
                                            <button type='button' className='btn btn-sm btn-danger' onClick={this.deleteRecord.bind(this, record.id)}><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                this.state.records.length == 0 ? <><tr><td colSpan={6} style={{textAlign: "center"}}>Kayıt bulunamadı</td></tr></> : null
                            }
                        </tbody>
                    </table>
                    
                    </>
                }
            </div>
            
        </>)
    }
}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(WagesList);
