import React from 'react'
import {browserHistory, Link} from 'react-router'

export default class Index extends React.PureComponent {

    componentWillMount() {
        browserHistory.push('/login')
    }

    render() {
        return (
            <div style={{display: "flex","flex-direction":"column","textAlign": "center","paddingTop": "15%","fontWeight": 600}}>
                <img style={{height: 44,width: 200,"margin": "auto"}} src="/app/images/logo-default.png" alt="logo" className="logo-default"/>
                <h1 style={{display: "flex",justifyContent: "center"}}>Site geçici olarak kullanılamıyor.</h1>
                <div style={{display: "flex",justifyContent: "center", "flexDirection": "column"}}>
                    <p>Planlamış oldugumuz bakım şuanda devam ediyor. Lütfen kısa süre sonra tekrar kontrol ediniz.</p>
                    <p>Verdiğimiz rahatsızlıktan dolayı özür dileriz.</p> 
                </div>
          </div>
        )
    }
}
