import React from "react";
import Util from "../../util/Util";
import EkoChartEmptyDonutReports from "./EkoChartEmptyDonutReports.js";

const HighchartsReact = require("react-highcharts");
const Highcharts = require("highcharts");

export default class EkoChartDonut extends React.Component {
  changeTitle(title) {
    let chart = this.refs.chart.getChart();
    chart.update({ title: { text: title } });
  }

  shouldComponentUpdate(nextProps) {
    const differentTitle = this.props.title !== nextProps.title;
    const differentData =
      JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data);
    return differentTitle || differentData;
  }

  render() {
    if (this.props.data && this.props.data.length > 0) {
      let total = 0;
      this.props.data.forEach(function (val) {
        total += val.y;
      });
      if (total === 0)
        return (
          <EkoChartEmptyDonutReports
            title={this.props.title}
            formatter="amount"
          />
        );
    }

    let colors = Highcharts.getOptions().colors;
    // colors[0] = '#293caa';
    // colors[1] = '#cf5517';
    // colors[2] = '#13cfb5';
    // colors[3] = '#757575';
    // colors[4] = '#2d9587';
    // colors[5] = '#f09835';
    // colors[6] = '#00A4CF';
    // colors[7] = '#dbd7d3';

    colors[0] = "#B7B8B6";
    colors[1] = "#4D85BD";
    colors[2] = "#FFBB00";
    colors[3] = "#FF420E";
    colors[4] = "#598234";
    colors[5] = "#EB8A44";
    colors[6] = "#9B4F0F";
    colors[7] = "#000000";

    colors = colors.map(function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7,
        },
        stops: [
          [0, color],
          [1, Highcharts.Color(color).brighten(-0.3).get("rgb")], // darken
        ],
      };
    });

    const formatter = this.props.formatter;
    const self = this;
    let config = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: 'Open Sans",sans-serif',
        },
        height: 305,
      },
      legend: {
        itemStyle: {
          cursor: "default",
        },
        verticalAlign: "bottom",
        layout: "horizontal",
      },

      title: {
        text: this.props.title,
        align: "center",
        verticalAlign: "middle",
        y: -30,
        style: { fontSize: "11px" },
      },
      yAxis: {
        title: {
          text: this.props.yTitle,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: colors,
          point: {
            // title state atadıgımda state değiştirmede hata veriyor
            events: {
              mouseOver: (e) => {
                let title =
                  formatter && formatter === "amount"
                    ? "<b>" + e.target.name + "</b>: " + e.target.y + " ₺"
                    : "<b>" + e.target.name + "</b>: " + e.target.y;
                self.changeTitle(title);
              },
              mouseOut: () => {
                self.changeTitle(self.props.title);
              },
              legendItemClick: function () {
                return true; // <== returning false will cancel the default action
              },
            },
          },
        },
        allowPointSelect: true,
      },

      tooltip: {
        enabled: true,
        // formatter: function () {
        //     if (formatter && formatter == 'amount')
        //         return '<b>' + this.point.name + '</b>: ' + Util.convertDecimalToMoney(this.y) + ' ₺';
        //     else return '<b>' + this.point.name + '</b>: ' + this.y;
        // }
      },
      series: [
        {
          name: "Toplam",
          data: this.props.data,
          size: "100%",
          innerSize: "75%",
          showInLegend: true,
          dataLabels: {
            enabled: false,
            formatter: function () {
              if (formatter && formatter === "amount")
                return (
                  "<b>" +
                  this.point.name +
                  "</b>: " +
                  Util.convertDecimalToMoney(this.y) +
                  ""
                );
              else return "<b>" + this.point.name + "</b>: " + this.y;
            },
          },
        },
      ],
    };

    return <HighchartsReact config={config} isPureConfig ref="chart" />;
  }
}
