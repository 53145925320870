/*
    COREBYTE TEKNOLOJİ YAZILIM DANIŞMANLIK TİC. LTD. ŞTİ.
    www.corebyte.tech - 0850 360 6789 - info@corebyte.tech
    OutgoingStatementsModal.js - 3.05.2023
*/
import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormCheckbox,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormDate
} from "../../components/elements/EkoForm";
import { Modal, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import ReactDOM from 'react-dom'


class OutgoingStatementsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            showModal: false,
            errors: {},
            formFields: {},
            saving: false,
            iFrameHeight: '800',
            allParamsOk: false
        };
        //  this.onCancel = this.onCancel.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showModal: nextProps.showModal,
        });
    }
    render() {
        let serviceType = process.env.SERVICE_TYPE;
        var apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);

        return (
            <EkoModal
                showModal={this.state.showModal}
                title="Banka Hesap Hareketleri (Çıkan Para)"
                bsSize="xlg" // xlg
                onHide={() => {
                    this.setState({
                        showModal: false
                    });
                    //this.onCancel();
                }}
                spinner={this.state.saving}
            >

                <iframe
                    style={{overflow: 'visible'}}
                    ref="iframe"
                    src={apiUrl + "panel/bank-statements.php?type=2&t="+ this.state.formData.token}
                    scrolling="auto"
                    frameBorder="0"
                    width="100%"
                    height={this.state.iFrameHeight}
                />

            </EkoModal>
        );
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(OutgoingStatementsModal);
