import React, {Component} from 'react';
import {Link} from "react-router";

class ExportLayout extends Component {

    goIos = () => {
        window.open("https://itunes.apple.com/us/app/ekohesap/id1437652631?mt=8", "_blank")
    }

    render() {
        return (
            <div>
                <div className="page-wrapper-row">
                    <div className="page-wrapper-top">
                        <div className="page-header" style={{
                            height: 'inherit'
                        }}>
                            <div className="page-header-top">
                                <div className="container">
                                    <div className="page-logo">
                                        <Link to="/dashboard">
                                            <img src="/app/images/logo-default.png" alt="logo"
                                                 className="logo-default"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    height: '65vh'
                }}>
                    {this.props.children}
                </div>


                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '30vh',
                    color: '#fff',
                    fontSize: 14,
                    textAlign: "center",
                    backgroundColor: '#4a8bc2'
                }}>

                    <div>
                        <p style={{
                            textAlign: 'center'
                        }}>Parasını Kolayca Kontrol Etmek İsteyen Patronlara...</p>
                        <p style={{
                            textAlign: 'center'
                        }}>Hemen ÜCRETSİZ deneyin</p>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <a href='https://play.google.com/store/apps/details?id=com.ekohesap&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img alt="Google Play'den alın" style={{width: 180, marginTop: 7, height: 'auto'}}
                                 src='https://play.google.com/intl/en_us/badges/images/generic/tr_badge_web_generic.png'/>
                        </a>
                        <button className={"ios-button"}
                                onClick={this.goIos}></button>
                    </div>

                </div>


            </div>


        );
    }
}

export default ExportLayout;