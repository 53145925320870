import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import moment from "moment";
class ModalDriver extends Component {
    get row() {
        return [
            {
                label: "",
                placeholder: "Adı",
                type: "text",
                colMd: 12,
                id: "firstname"
            },
            {
                label: "",
                placeholder: "Soyadı",
                type: "text",
                colMd: 12,
                id: "lastname"
            },
            {
                label: "",
                placeholder: "Kimlik Numarası",
                type: "text",
                colMd: 12,
                id: "identity_number"
            },
        ];
    }

    get defaultData() {
        return {
            rows: [this.row],
            errors: {
                rows: []
            },
            saving: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.defaultData;
    }

    checkValid = data => {
        return !!(data && data !== "");
    };

    onSave = () => {
        const errors = this.checkErrors();

        this.setState({ errors: { rows: errors } });

        if (errors.length === 0) {
            this.props.closeModal();
        }
    };

    checkErrors = () => {
        const rows = this.props.driverRows.rows;

        const errors = [];

        rows.map((row, index) => {
            if (
                (row.firstname === "" || row.firstname === null) &&
                (row.identity_number === "" || row.identity_number === null)
            ) {
                return row;
            }
            if (this.checkValid(row.identity_number) === false) {
                errors[index] = {
                    identity_number: ["Lütfen  Kimlik Numarası Giriniz"]
                };
            }
            if (this.checkValid(row.firstname) === false) {
                errors[index] = {
                    firstname: ["Lütfen Geçerli Bir Ad Giriniz"]
                };
            }
            return row;
        });

        return errors;
    };

    closeModal = () => {
        const errors = this.checkErrors();

        this.setState({ errors: { rows: errors } });

        if (errors.length > 0) {
            toastr.warning("Hatalı bir veri girdiniz, lütfen kontrol ediniz");
            return;
        }
        if (this.props.closeModal && errors.length === 0) {
            this.props.closeModal();
        }
    };

    onChange = data => {
        this.props.saveDriver(data);
    };

    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showModal}
                title={"Şoför Bilgileri Ekle"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <EkoInputTable
                    onSave={this.onSave}
                    className={"modalTableFix"}
                    onCancel={this.closeModal}
                    onChange={this.onChange}
                    errors={this.state.errors}
                    formData={this.props.driverRows}
                    data={{
                        headers: [{ label: "Adı" }, { label: "Soyadı" }, { label: "Kimlik Numarası" }],
                        rows: this.props.driverRows.rows.map(() => this.row)
                    }}
                />
            </EkoModal>
        );
    }
}

export default ModalDriver;
