import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";

class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { email: {} },
      saving: false,
      errors: {},
      formFields: {}
    };
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type] || {};
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  closeModal() {
    this.setState({ saving: false, formData: { promotion: {} } });
    this.props.closeModal();
  }

  onSave() {
    const self = this;
    let params = {
      email: this.state.formData.email.email
    };

    if (!this.state.formData.email.email) {
      let errors = { email: { email: ["E-posta alanı boş bırakılamaz."] } };
      self.setState({ errors: errors });
    } else {
      this.setState({ saving: true }, () => {
        Api.post("/user/changeEmail", params, (response, err) => {
          this.setState(
            {
              saving: false
            },
            () => {
              if (response) {
                self.closeModal();
                const user = JSON.parse(localStorage.getItem("user"));
                user.email = params.email;
                localStorage.setItem("user", JSON.stringify(user));
                toastr.success("Başarılı", "E-posta başarıyla değiştirildi!");
                this.props.forceRefresh();
              } else {
                if (err.email) {
                  this.setState({ errors: { email: { email: [err.email] } } });
                } else {
                  toastr.error("Hata", err.message || "Kaydedilemedi");
                }
              }
            }
          );
        });
      });
    }
  }

  render() {
    const emailProps = {
      onChange: this.setFieldChange.bind(this, "email"),
      onMount: this.setFieldMount.bind(this, "email"),
      formData: this.state.formData.email,
      errors: this.state.errors.email
    };

    return (
      <EkoModal
        showModal={this.props.showModal}
        title={"E-posta Değiştir"}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <EkoForm formId={"email_change"}>
          <div>
            <div className="row">
              <div className="col-md-11">
                <EkoFormInputText
                  {...emailProps}
                  id={"email"}
                  type="email"
                  tooltip={true}
                  label="E-posta Adresi"
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <br />
            <EkoFormButton
              isLoading={this.state.saving}
              label={"Gönder"}
              onClick={this.onSave.bind(this)}
            />
            <EkoFormButton
              isLoading={this.state.saving}
              label={this.props.buttonName ? this.props.buttonName : "İPTAL"}
              className="btn"
              onClick={this.closeModal.bind(this)}
            />
          </Modal.Footer>
        </EkoForm>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(EmailModal);
