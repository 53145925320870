import Sugar from "sugar";
import moment from "moment";
import { FETCH_COLLECTIONS, FETCH_SUPPLIERS } from "../actions/types";
import {browserHistory} from "react-router";

Number.isInteger =
  Number.isInteger ||
  function(value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };
const Util = {
  mapFieldsToMoney(fields, formData) {
    let data = {};

    fields.forEach(field => {
      if (formData[field]) {
        data[field] = this.convertMoneyToDecimal(formData[field]);
      }
    });

    return data;
  },

  mapDecimalToMoney(fields, formData) {
    let data = {};

    fields.forEach(field => {
      if (formData[field]) {
        data[field] = this.convertDecimalToMoney(formData[field]);
      }
    });

    return data;
  },

  mapFieldsToDatabaseDate(fields, formData) {
    let data = {};

    fields.forEach(field => {
      if (formData[field]) {
        data[field] = this.convertToDatabaseDate(formData[field]);
      }
    });

    return data;
  },

  mapFieldsToDatabaseDateClean(fields, formData) {
    let data = {};

    fields.forEach(field => {
      if (formData[field] && formData[field] !== "") {
        data[field] = this.convertToDatabaseDate(formData[field]);
      }
    });

    return data;
  },

  filterDtConfig(dtConfig, name) {
    if (dtConfig) {
      dtConfig = Sugar.Array.filter(dtConfig, function(object) {
        return object.name === name;
      });
      dtConfig = Sugar.Array.first(dtConfig);

      if (!dtConfig) {
        dtConfig = { has_search: false };
        dtConfig[name] = [];
      }

      switch (dtConfig.name) {
        case "collection":
          dtConfig.type = FETCH_COLLECTIONS;
          break;
        case "supplier":
          dtConfig.type = FETCH_SUPPLIERS;
          break;
        default:
      }
    }
    if (!dtConfig) {
      dtConfig = { has_search: false };
      dtConfig[name] = [];
    }

    return dtConfig;
  },
  isEmpty(obj) {
    return obj ? Object.keys(obj).length === 0 : true;
  },
  isUndefined(obj) {
    return "undefined" === typeof obj;
  },
  escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  },
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
  },
  convertMoneyToDecimal(money) {
    if (isNaN(parseFloat(money))) money = "0,00";
    if (Number.isInteger(money) || !isNaN(parseFloat(money))) {
      money += "";
    }
    let amount = Util.replaceAll(money, ".", "");
    return (amount = parseFloat(amount.replace(",", ".")));
  },
  convertDecimalToMoney(money, p) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }
    return money
      .toFixed(p || 2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  },
  convertDecimalToMoney4(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 4,
      maximumFractionDigits: 5
    }).format(money).replace("₺", "")
  },
  convertDecimalToMoney4x(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    }).format(money).replace("₺", "")
  },
  convertDecimalToMoney8x(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 8,
      maximumFractionDigits: 8
    }).format(money).replace("₺", "")
  },
  convertDecimalToMoney5(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }

    var currency_symbol = "₺";

    var formattedOutput = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0,
      maximumFractionDigits: 5
    });


    return formattedOutput.format(money).replace(currency_symbol, "");
  },convertDecimalToMoney6x(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }

    var currency_symbol = "₺";

    var formattedOutput = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0,
      maximumFractionDigits: 6
    });


    return formattedOutput.format(money).replace(currency_symbol, "");
  },
  convertDecimalToMoney6(money) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money.toFixed(6));
    }
    return money;
  },
  convertDecimalToMoney7(money, p) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money.toFixed(p || 6));
    }
    var parts = money.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  },
  convertDecimalToMoneyNew(money, precision = 4) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }

    var currency_symbol = "₺";

    var formattedOutput = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0,
      maximumFractionDigits: precision
    });

    return formattedOutput.format(money).replace(currency_symbol, "");
  },
  convertDecimalToString(money, fixed) {
    if (!Number.isInteger(money)) {
      money = parseFloat(money);
    }
    return money.toFixed(fixed).replace(".", ",");
  },
  deleteAllExceptNumbers(val) {
    return val.replace(/[^0-9.]/g, "");
  },
  convertToDatabaseDate(date) {
    if (date) return moment(date).format("YYYY-MM-DD");
    else return "";
  },
  convertFromDatabaseDate(date) {
    if (date) return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    else return "";
  },
  convertToDatabaseDateTime(date) {
    if (date) return moment(date).format("DD/MM/YYYY HH:mm");
    else return "";
  },
  convertPercentToNumber(percent) {
    return (
      this.convertMoneyToDecimal(percent ? percent.replace("%", "") : "0,00") /
      100
    );
  },
  convertNumberToPercent(number) {
    return "%" + this.convertDecimalToMoney(number ? number * 100 : 0, 3);
  },
  getErrorAsStr(error) {
    let str = "";
    for (var key in error) {
      if (error.hasOwnProperty(key) && error[key] !== "fail") {
        str += error[key] + "\n";
      }
    }
    return str;
  },
  parseInt(string) {
    return isNaN(parseInt(string, 10)) ? 0 : parseInt(string, 10);
  },

  /**
   * a pure function that converts money fields to decimal
   * @param fields
   * @param formData
   * @returns {{}}
   */
  convertMoneyFieldsToDecimal(fields, formData) {
    let newFormData = { ...formData };

    fields.forEach(field => {
      if (newFormData[field] !== undefined) {
        newFormData[field] = Util.convertMoneyToDecimal(newFormData[field]);
      }
    });

    return newFormData;
  },
  companyModules(module_id){
    const storedModules = localStorage.getItem("company_modules");
    const user = JSON.parse(localStorage.getItem('user'));
    const parsedModules = JSON.parse(storedModules);
    if (Array.isArray(parsedModules)) {
      const rt = parsedModules.find(item => item.module_id === module_id && item.company_id === user.company_id);
      return rt ? true : false;
    } else {
      return false;
    }
  },
  backButton(uri){
    if (this && this.props && this.props.router) {
      this.props.router.push(uri);
    }else{
      window.location.assign(uri);
    }
    
  }
};

export default Util;
