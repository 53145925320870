/**
 * Created by mehmetyurtar on 31/08/2017.
 */
import React from 'react';
import {browserHistory} from 'react-router'

const DesignerToolbar = props => {
    return (
        <div style={{width:"100%", background:"#fbfbfb", paddingLeft:"10px", overflow: "hidden", padding: "8px 0 4px 10px",lineHeight:"30px"}}>
                    <div className="designer--page__toolbar--item__container" style={{width: "20%",borderLeft: 0, paddingLeft: 0}}>
                        <span>Şablon ismi: </span>
                        <input style={{width: "65%"}} type="text" key="templateName" value={props.templateName} onChange={props.onTemplateNameChange}/>
                    </div>
            <div className="designer--page__toolbar" style={{display:'inline-block', float:"right", width:"80%"}}>
                <ul style={{listStyle: "none", padding:0, margin:0}}>
                    <li className="designer--page__toolbar--item__container">
                        <button className="button_style" onClick={() => props.openOrCloseModal(true)}><i className="fa fa-upload"/> Evrak Görseli Yükle</button>
                    </li>
                    <li className="designer--page__toolbar--item__container">
                        <span style={{marginRight: "5px"}}>Kagit Boyutu: </span>
                        <span className="designer--page--size__container">
                          <span
                              className={
                                  props.paperWidth ===  595 && props.paperHeight === 842 ? "designer--page--size designer--page--size__selected" : "designer--page--size"}
                              onClick={props.predefinedPaperSize.bind(null, 595, 842)}>
                              A4
                          </span>
                      </span>
                        <span className="designer--page--size__container">
                          <span
                              className={props.paperWidth ===  420 && props.paperHeight === 595 ? "designer--page--size designer--page--size__selected" : "designer--page--size"}
                              onClick={props.predefinedPaperSize.bind(null, 420, 595)}>
                              A5
                          </span>
                      </span>
                        <span className="designer--page--size__container">
                          <span className="designer--page--size__spec">
                              <input type="text" name="paperWidth" value={props.paperWidth} onChange={props.onCustomPaperSizeChange}/>
                              px
                          </span>
                          <span style={{marginLeft: "10px"}}>x</span>
                          <span className="designer--page--size__spec">
                              <input type="text" name="paperHeight" value={props.paperHeight} onChange={props.onCustomPaperSizeChange}/>
                              px
                          </span>
                      </span>
                    </li>
                    <li className="designer--page__toolbar--item__container">
                      <span
                          className={props.paperOrientation === "v" ? "designer--page--orientation designer--page__v selected" : "designer--page--orientation designer--page__v"}
                          onClick={props.onPaperOrientationChange.bind(null, "v")}
                      >
                      </span>
                        <span
                            className={props.paperOrientation === "h" ? "designer--page--orientation designer--page__h selected" : "designer--page--orientation designer--page__h"}
                            onClick={props.onPaperOrientationChange.bind(null,'h')}
                        >
                      </span>

                    </li>
                    <li className="designer--page__toolbar--item__container">
                        <form target="_blank" action={process.env.REACT_APP_API_URL+"/template/testPrint"} method="POST" encType="application/json">
                            <input type="hidden" name="config" value={JSON.stringify(props.config, null, 2)}/>
                            <input type="hidden" name="values" value={JSON.stringify(props.values, null, 2)}/>
                        <button className="button_style"> Test Ciktisi Yazdir</button>
                        </form>
                    </li>
                    <li className="designer--page__toolbar--item__container">
                        <button className="button_style" onClick={props.save}>Kaydet</button>
                    </li>
                    <li className="designer--page__toolbar--item__container">
                        <button onClick={() => { browserHistory.push('/dashboard/templates')}} className="button_style">İptal</button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DesignerToolbar;