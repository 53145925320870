import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const Portlet = (props) => {
  return (
    <div className="portlet light ">
      <div
        className="portlet-title"
        // style={
        //   props.addDocument
        //     ? { display: "flex", flexDirection: "row", alignItems: "center" }
        //     : {}
        // }
      >
        <div className="caption font-dark">
          {props.title ? (
            <>
              <span className="caption-subject bold uppercase">
                {props.title}
              </span>
            </>
          ) : (
            props.content
          )}
        </div>
        {(props.addDocument && (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              flex: "auto",
            }}
          >
            {props.addDocument}
          </div>
        )) ||
          null}

        {props.children}
      </div>
    </div>
  );
};

export default Portlet;

Portlet.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  children: PropTypes.element.isRequired,
};
