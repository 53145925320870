import React, {Component} from 'react'
import {Col, Tab, Tabs} from 'react-bootstrap';
import EkoInputTab from "../../components/elements/EkoInputTab";
import PropTypes from 'prop-types';
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoFormSaveButtonGroup from "../../components/elements/form/EkoFormSaveButtonGroup";
import Api from "../../util/Api";
import {FETCH_EMPLOYEE} from "../../actions/types";
import EkoFormTooltip from "../../components/elements/EkoFormTooltip";
import {toastr} from 'react-redux-toastr'
import Util from '../../util/Util';

class AddPayroll extends Component {

    constructor(props) {
        super(props);


        this.state = {
            formData: this.props.getDefaultFormData(),
            errors: {},
            employee: {},
            tabs: props.tabRows,
            key: 0,
            saving: false,
            height: 350,
            validated: false,
        };
        this.tabs = {};
    }

    componentWillMount() {
        this.props.fetchData('/employee/' + this.props.router.params.id, FETCH_EMPLOYEE);
    }

    componentWillReceiveProps(props) {

        if (props.employee && props.employee.employee) {
            let employee = {...props.employee.employee};

            let formData = Object.assign(this.state.formData, {
                is_min_salary: employee.is_min_salary,
                mlc_in_net: employee.mlc_in_net,
                employee_id: employee.id,
                salary_type: employee.salary_type,
                salary: Util.convertDecimalToMoney(employee.salary),
                payroll_status_id: employee.payroll_status_id
            });

            this.setState({employee: employee, formData: formData});
        }
    }

    handleSelect(key) {
        let height = 350;

        if (key === 2) {
            height = 410;
        }

        this.setState({
            key: key,
            height: height
        });
    }

    onMount() {
    }


    validateData() {
        let validated = false;

        Api.post('/employeeSalary/validateData/' + this.props.router.params.id, {
            month: this.state.formData.month,
            year: this.state.formData.year
        }, (response, err) => {
            if (err) {
                validated = false;
            } else {

                if (response && response.status && response.status === 'ok') {
                    validated = response.validated;
                } else {
                    validated = false;
                }
            }

            this.setState({
                validated: validated,
            });
        });
    }

    onChange = (field, value) => {

        let formData = {...this.state.formData};


        if (value === null) {
            delete formData[field];
        } else {
            formData[field] = value;
        }


        this.setState({formData: formData}, () => {

            if ( (field === 'month' || field === 'year') && (formData.month && formData.year)) {
                this.validateData();
            }
        });
    };


    componentDidMount() {
        this.validateData();
    }

    renderTab = (key) => {
        const selectedTab = this.state.tabs[key];

        const inputProps = {
            onChange: this.onChange,
            onMount: this.onMount,
            errors: this.state.errors,
            formData: this.state.formData,
            validated: this.state.validated
        };


        return <EkoInputTab md={selectedTab.md} rows={selectedTab.rows}
                            inputProps={inputProps}/>
    };

    fillDataWithZero(formData) {
        let fields = [
            "financial_giveaway",
            "insurance_by_employee",
            "insurance_by_employer",
            "additional_subj_sgk",
            "additional_excl_sgk",
            "deduction"
        ];

        fields.forEach((field) => {
            formData[field] = formData[field] === undefined ? 0 : formData[field];
        })
    }

    convertMoneyToDecimal = (formData) => {

        let fields = [
            "salary",
            "income_cumulative_tax",
            "income_cumulative_matrah",
            "financial_giveaway",
            "insurance_by_employee",
            "insurance_by_employer",
            "additional_subj_sgk",
            "additional_excl_sgk",
            "deduction"
        ];


        return Util.convertMoneyFieldsToDecimal(fields, formData);

    };

    onSave = () => {
        this.setState({saving: true});
        const endpoint = '/employeeSalary';
        const type = this.props.router.params.type && this.props.router.params.type === 'add' ? 'post' : 'put';
        let formData = {...this.state.formData};

        this.fillDataWithZero(formData);
        formData = this.convertMoneyToDecimal(formData);

        if (this.state.validated === false) {

            if (formData.income_cumulative_tax !== undefined) {
                delete formData['income_cumulative_tax'];
            }

            if (formData.income_cumulative_matrah !== undefined) {
                delete formData['income_cumulative_matrah'];

            }
        }

        Api[type](endpoint, formData, (response, err) => {
            this.setState({
                saving: false
            });

            if (!response && err) {
                if (err) {
                    this.setState({
                        errors: err
                    });
                }
            }


            if (type === 'post') {


                if (response && response.status !== undefined) {
                    if (response.status === "ok") {

                        toastr.confirm(`Başaryıla kaydedildi, Bordroyu Önizlemek istermisiniz`, {
                            onOk: () => {
                                this.props.router.push('/dashboard/employee/employeeSalary/preview/' + response.employeeSalary.id + '/' + response.employeeSalary.employee_id);
                            },
                            onCancel: () => {
                            },
                            okText: 'Evet',
                            cancelText: 'Hayır'
                        });
                    } else {
                        toastr.error('Hata', response.message || 'Veri eklenirken hata oluştu', {
                            timeOut: 0,
                        });
                    }
                }


            } else {
                if (response && response.status && response.status === "ok") {
                    toastr.success('Başarılı', 'Başarıyla Güncellendi');
                } else {
                    toastr.error('Hata', response.message || 'Veri güncellenirken hata oluştu', {
                        timeOut: 0,
                    });
                }
            }


        });

    };


    onCancel = () => {
        this.props.onCancel();
    };

    render() {

        return (
            <div className="portlet-body" style={{height: this.state.height}}>
                <EkoFormTooltip hortizonal={false} formId={"add_payroll"}>
                    <Col md={12}>
                        <Tabs activeKey={this.state.key} onSelect={this.handleSelect.bind(this)}
                              id={"controlled tab-example"}>
                            {this.state.tabs.map((t, i) => <Tab key={i} eventKey={i}
                                                                title={t.title}>{this.renderTab(i)}</Tab>)}
                        </Tabs>
                    </Col>
                    <Col md={12} style={{marginTop: 20}}>
                        <EkoFormSaveButtonGroup saving={this.state.saving} onSave={this.onSave.bind(this)}
                                                onCancel={this.onCancel}/>
                    </Col>
                </EkoFormTooltip>
            </div>
        );


    }

}

AddPayroll.propTypes = {
    tabRows: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    offDayVisible: PropTypes.func,
    getDefaultFormData: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
        employee: state.data.employee
    };
}

export default connect(mapStateToProps, actions)(AddPayroll);
