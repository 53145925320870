import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { FETCH_E_IN_BOXES } from "../../actions/types";
import {Tab, Tabs} from "react-bootstrap";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import moment from "moment";
import GetInModal from "../../components/forms/GetInModal";
import EInBoxesIrsaliye from "./EInBoxesIrsaliye";
import Util from "../../util/Util";
import {Link} from "react-router";

class EInBoxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      data: [],
      saving: false,
      showGetIn: false,
      earchiveUuId:0,
        showModal: false,
        showModal2: false,
        errorInvoiceText:"Hatalı Faturalar"
    };
  }

  fetchTransactions() {
    if (this.table) this.table.cleanSelect();
      var self = this;
      Api.get("/eInBox/list", function(response, err) {
          if (response.eInBoxes) {
              //@oguz Interaktif e arşiv sorgusu basladı
              Api.get("/EArchiveInteraktifVD/getAll", function(response2, err) {
                  if (response2.list) {
                      response2.list.map((r, i) => {
                          response.eInBoxes = [...response.eInBoxes, {
                              UStatus: -1,
                              amount_str: r['amount'],
                              doc_id: r['doc_id'],
                              id: r['id'],
                              issueDate: moment(r['date'], "YYYY-MM-DD").format("DD-MM-YYYY"),
                              profileID: 99,
                              profileID_str: "e-Arşiv Fatura",
                              title:r['title'],
                              uuid:r['id'],
                              xml:""
                          }];
                      })
                      self.setState({data: response});
                  } else {
                      self.setState({data: response});
                  }
              });
              //@oguz Interaktif e arşiv sorgusu bitti
          } else {
              toastr.error("Hata", err.message);
          }
      });
  }
    fetchErrorInvoiceList(){
        var self = this;
        Api.get("/EArchiveInteraktifVD/getErrorInvoiceCount", function(response, err) {
            if (response) {
                self.setState({errorInvoiceText:"Hatalı Faturalar ("+response.count+")"});
            } else {
                toastr.error("Hata", err.message);
            }
        });

    }
  componentWillMount() {
    this.fetchTransactions();
    this.fetchErrorInvoiceList();
  }

  sendAppResp(id, status, uuid) {
    const integrator = JSON.parse(localStorage.getItem("integrator"));

    const self = this;
    toastr.confirm(`Faturayı yanıtlamak istediğinize emin misiniz?`, {
      onOk: () => {
        self.setState({ saving: true });

        if (integrator === 1) {
          Api.post(
            "/eInvoice/sendAppResp",
            {
              id: id,
              note: null,
              responseCode: status
            },
            (response, err) => {
              if (response) {
                toastr.success(response.message);
                self.fetchTransactions();
              } else toastr.error("Hata", err.message);
              self.setState({ saving: false });
            }
          );
        } else if (integrator === 2) {
          Api.post(
            "/nes/send-app-response",
            {
              id: id,
              note: null,
              uuid: uuid,
              responseCode: status
            },
            (response, err) => {
              if (response) {
                toastr.success(response.message);
                self.fetchTransactions();
              } else toastr.error("Hata", err.message);
              self.setState({ saving: false });
            }
          );
        }else if (integrator === 3) {
            Api.post(
                "/nesv2/send-app-response",
                {
                    id: id,
                    note: null,
                    uuid: uuid,
                    responseCode: status
                },
                (response, err) => {
                    if (response) {
                        toastr.success(response.message);
                        self.fetchTransactions();
                    } else toastr.error("Hata", err.message);
                    self.setState({ saving: false });
                }
            );
        }
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

    delete(id,type) {
        toastr.confirm(`Faturayı silmek istediğinize emin misiniz?`, {
            onOk: () => {
                if(type=='efatura'){
                    Api.get("/eInBox/delete/"+id, function(response, err) {
                        window.location.reload();
                    });
                }
                else if(type=='earsivfatura'){
                    Api.get("/EArchiveInteraktifVD/delete/"+id, function(response, err) {
                        window.location.reload();
                    });
                }
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });
    }

  createCustomRowButtons(state) {
    let checkBtn = "";
    if (
      state.selectedRow &&
      parseInt(state.selectedRow.profileID, 10) === 1 &&
        parseInt(state.selectedRow.UStatus, 10) === 0
    )
      checkBtn = (
        <span>
          <button
            className="btn blue tablebuttons btn-sm"
            onClick={() =>
              this.sendAppResp(state.selectedRow.id, 1, state.selectedRow.uuid)
            }
            disabled={this.state.saving}
          >
            {this.state.saving ? (
              <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
            ) : (
              <span>
                <i className="fa fa-check-circle" /> Kabul Et
              </span>
            )}
          </button>
          <button
            className="btn blue tablebuttons btn-sm"
            onClick={() =>
              this.sendAppResp(state.selectedRow.id, 0, state.selectedRow.uuid)
            }
            disabled={this.state.saving}
          >
            {this.state.saving ? (
              <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
            ) : (
              <span>
                <i className="fa fa-times-circle" /> Reddet
              </span>
            )}
          </button>
        </span>
      );
    return state.isSelected && state.selectedRow ? (
            state.selectedRow.profileID!=99 ? (
      <span style={{"width":"350px","position":"absolute"}}>
        <button
          className="btn blue tablebuttons btn-sm"
          onClick={() =>
            this.props.router.push(
              "/dashboard/purchases/eInBoxes/" + state.selectedRow.uuid
            )
          }
        >
          <i className="fa fa-eye" /> Kayıtlara İşle
        </button>
          <button
              className="btn blue tablebuttons btn-sm"
              onClick={() => this.delete(state.selectedRow.id, 'efatura') }
          >
          <i className="fa fa-trash" /> Sil
        </button>
        {checkBtn}
      </span>):(
                <span>
        <button
            className="btn blue tablebuttons btn-sm"
            onClick={() =>
                this.props.router.push(
                    "/dashboard/purchases/eInBoxes/" + state.selectedRow.uuid
                )
            }
        >
          <i className="fa fa-download" /> Kayıtlara İşle
        </button>
                    <button
                        className="btn blue tablebuttons btn-sm"
                        onClick={() => this.delete(state.selectedRow.id, 'earsivfatura') }
                    >
          <i className="fa fa-trash" /> Sil
        </button>
                    {checkBtn}
      </span>
            )
    ) : (
      ""
    );
  }

  render() {
      var apiUrl = process.env.REACT_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length-3);
    if (!this.state.data.eInBoxes) return <EkoSpinner />;
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                       onClick={Util.backButton.bind(this, "/dashboard/purchases")}><i
                        className="fa fa-arrow-left"></i> Geri
                        Dön</a>
                </div>
                <div className="col-md-6 text-right" style={{"textAlign":"right"}}>
                    <Link
                        onClick={() => this.setState({
                            showModal: true
                        })}
                        className="btn btn-default blue"
                    >
                        e-Arşiv Faturalarımı Getir
                    </Link>
                    <Link
                        onClick={() => this.setState({
                            showModal2: true
                        })}
                        className="btn btn-default blue"
                    >
                        {this.state.errorInvoiceText}
                    </Link>
                </div>
            </div>
            <EkoModal
                showModal={this.state.showModal}
                title="e-Arşiv Faturalarımı Getir"
                bsSize="lg"
                onHide={() => {
                    this.setState({
                        showModal: false
                    });
                    window.location.reload();
                }}
            >

                <iframe
                    style={{overflow: 'visible'}}
                    ref="iframe"
                    src={apiUrl + "panel/gibten-getir.php?cid="+JSON.parse(localStorage.getItem("user")).company_id+"&action=url/earsiv/alici&t=" + localStorage.getItem('token')}
                    scrolling="auto"
                    frameBorder="0"
                    width="100%"
                    height='800'
                />

            </EkoModal>
            <EkoModal
                showModal={this.state.showModal2}
                title={"Hatalı Faturalar"}
                bsSize="xlg"
                onHide={() => {
                    this.setState({
                        showModal2: false
                    });
                    window.location.reload();
                }}
            >

                <iframe
                    style={{overflow: 'visible'}}
                    ref="iframe"
                    src={apiUrl + "panel/gib-hatali-faturalar.php?cid="+JSON.parse(localStorage.getItem("user")).company_id+"&action=url/earsiv/alici&t=" + localStorage.getItem('token')}
                    scrolling="auto"
                    frameBorder="0"
                    width="100%"
                    height='800'
                />

            </EkoModal>
            <div className="portlet light">
                <div className="portlet-body">
                    <Tabs defaultActiveKey={"fatura_liste"} id="uncontrolled-tab-example">
                        <Tab eventKey="fatura_liste" title="GELEN E-FATURALAR / E-ARŞİV FATURALAR">
                            <div>
                                <EkoDataTable
                                    onRef={ref => (this.table = ref)}
                                    data={this.state.data.eInBoxes}
                                    addButtonShow={false}
                                    createCustomRowButtons={this.createCustomRowButtons.bind(this)}
                                    hideSelectButtons
                                    name={"eInBox"}
                                />
                                <GetInModal
                                    showModal={this.state.showGetIn}
                                    closeModal={() => this.setState({showGetIn: false})}
                                    uuid={this.state.earchiveUuId}
                                />
                            </div>
                        </Tab>
                        {Util.companyModules(2) === true && (
                            <Tab eventKey="irsaliye_liste" title="GELEN e-İRSALİYELER">
                                <EInBoxesIrsaliye/>
                            </Tab>
                        )}
                    </Tabs>
                </div>
            </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        eInBoxes: state.data.eInBoxes
    };
}

export default connect(mapStateToProps, actions)(EInBoxes);
