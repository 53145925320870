import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import AddShareHolder from './AddShareHolder'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";

import Api from '../../util/Api'
//import EkoToastrError from '../../components/elements/EkoToastrError'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";

class TaxPayerShareHolderInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            records: [],
            formData: {},
            share_holders_loading: true
        }

    }
    componentWillMount() {
        this.fetchShareHolders();
    }
    componentDidMount() {
        //this.render();
    }
    fetchShareHolders() {
        this.setState({share_holders_loading: true, showInsertModal: false, formData: false, records: []});
        Api.get("/company/getShareHolders", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({records: getData, share_holders_loading: false });
        });
    }
    findTaxOfficeName(findme) {
        if (findme != null) {
            let taxOffices = JSON.parse(localStorage.getItem('fetch_tax_offices'));
            let taxOfficesData = taxOffices.data;
            let buldum = taxOfficesData.find((element) => {
                return element.code === findme;
            })
            if (buldum.name){
                return buldum.name;
            }
        }else{
            return "-";
        }
    }

    onSave() {
        this.fetchShareHolders();
    }
    openPopup() {
        this.setState({showInsertModal: true});
    }
    deleteShareHolder(itemId) {
        var self = this;
        toastr.confirm('Kayıt silinecektir. Onaylıyor musunuz?', 
            {
                onOk: () => {
                    Api.put("/company/deleteCompanyShareHolder", {id: itemId}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == "1") {
                                toastr.success("Başarılı", "Kayıt silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                self.fetchShareHolders();
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    editShareHolder(itemId) {
        var self = this;
        Api.get("/company/getShareHolder/"+itemId, (response, errors) => {
            if (errors) {
                return;
            }
            console.log(response.data);
            this.setState({formData: response.data, showInsertModal: true});
        });
    }

    render() {
        return (<>
            <div>
                {this.state.share_holders_loading == true ? <EkoSpinner /> : 
                    <>
                    
                    <AddShareHolder
                    showModal={this.state.showInsertModal}
                    editData={this.state.formData}
                    onSave={() => {
                        this.setState({ showInsertModal: false })
                        this.fetchShareHolders()
                    } }
                    onCancel={() => {
                        this.setState({ showInsertModal: false })
                        this.fetchShareHolders()
                    } }
                    errors={this.state.errors} />

                    
                    <div style={{textAlign: "right"}} onClick={this.openPopup.bind(this)}>
                        <button type='button' className='btn btn-default blue btn-sm'><i className='fa fa-plus'></i>Yeni Ekle</button>
                    </div>
                    

                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Adı Soyadı</th>
                                <th>TC / VKN</th>
                                <th>Vergi Dairesi</th>
                                <th>Adresi</th>
                                <th>E-posta</th>
                                <th>Telefon</th>
                                <th>Hisse (Pay) Oranı</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.records.map((record) =>
                                    <tr>
                                        <td>{record.name} {record.surname}</td>
                                        <td>{record.tc_number} {record.tax_number}</td>
                                        <td>{this.findTaxOfficeName(record.tax_office_code)}</td>
                                        <td>{record.address}</td>
                                        <td>{record.email}</td>
                                        <td>{record.phone}</td>
                                        <td>%{record.share}</td>
                                        <td>
                                            <button type='button' className='btn btn-sm btn-warning' onClick={this.editShareHolder.bind(this, record.id)}><i className='fa fa-edit'></i></button>
                                            <button type='button' className='btn btn-sm btn-danger' onClick={this.deleteShareHolder.bind(this, record.id)}><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                this.state.records.length == 0 ? <><tr><td colSpan={8} style={{textAlign: "center"}}>Kayıt bulunamadı</td></tr></> : ""
                            }
                        </tbody>
                    </table>
                    
                    </>
                }
            </div>
            
        </>)
    }
}

function mapStateToProps(state) {
    return {
        records: state.data.records

    };
}

export default connect(mapStateToProps, actions)(TaxPayerShareHolderInformation);
