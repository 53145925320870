import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Dropdown, MenuItem} from 'react-bootstrap'

export default class EkoFormButtonGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    static propTypes = {
        label: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        faClass: PropTypes.string,
        labelClass: PropTypes.string,
        className: PropTypes.string,
        buttonsType: PropTypes.string,
        buttons: PropTypes.array.isRequired,
    };

    static defaultProps = {
        faClass: "",
        spinner: false,
        className: "btn btn-default blue",
        isLoading: false
    };

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.isLoading !== this.props.isLoading) {
            return true
        }

        if (nextProps.className !== this.props.className) {
            return true
        }

        if (nextProps.label !== this.props.label) {
            return true
        }

        return false;
    }


    renderDropdown = () => {
        let title = this.props.label;

        const menuItemView = this.props.buttons ? this.props.buttons.map((button,i) => {
            return <MenuItem key={i} eventKey={button.id} 
                             onClick={button.onClick}>{button.labelText}</MenuItem>;
        }) : null;

        return <Dropdown id="dropdown-custom-1">
            <Dropdown.Toggle title={title} noCaret disabled={this.props.isLoading} className={this.props.className}>
                {this.props.isLoading ?
                    <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    : title}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-align dropdown-menu">  {menuItemView}</Dropdown.Menu>

        </Dropdown>
    }

    render() {
        if (this.props.buttonsType !== 'default') {
            return this.renderDropdown();
        }

        let labelText = this.props.label;
        let labelClass = ''
        if (this.props.faClass)
            labelClass = <i className={this.props.faClass}>&nbsp;</i>;

        const menuItemView = this.props.buttons ? this.props.buttons.map((menuItem, index) =>
            <MenuItem eventKey={menuItem.id} key={index}
                      onSelect={menuItem.onClick}>{menuItem.labelText}</MenuItem>
        ) : null;

        return <div className="btn-group">

            <button disabled={this.props.isLoading} style={{marginRight: "0"}} className={this.props.className}
                    onClick={this.props.onClick}>
                {this.props.isLoading ?
                    <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    :
                    <span>{labelClass} {labelText}</span>
                }
            </button>


            <Dropdown disabled={this.props.isLoading} id="dropdown-custom-1">
                <Dropdown.Toggle title="" noCaret className="btn blue dropdown-toggle mt-ladda-button ladda-button"><i
                    className="fa fa-angle-down"></i></Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-align dropdown-menu">  {menuItemView}</Dropdown.Menu>

            </Dropdown>
        </div>;
    }
}
