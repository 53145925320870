/*
    COREBYTE TEKNOLOJİ YAZILIM DANIŞMANLIK TİC. LTD. ŞTİ.
    www.corebyte.tech - 0850 360 6789 - info@corebyte.tech
    ObifinBankApis.js - 10.05.2023
*/
import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormCheckbox,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormDate
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "../elements/EkoSpinner";
import ObifinBankApiAdd from "../../screens/Obifin/ObifinBankApiAdd";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";

class ObifinBankApis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsertModal: false,
            errors: {},
            saving: false,
            page_loading: false,
            records: {},
            recordSwitches: {}, // Add the recordSwitches state variable
            editBankId: null,
        };
    }

    componentWillMount() {
        let _self = this;
        _self.getBankApis();
        if (_self.state.showInsertModal == false) {
            swal({
                title: "Banka Bağlantılarınızı (API) Aktif Etmeniz Gerekiyor",
                text: "Çalıştığınız bankalara ait dilekçlerinizi indirip, dokümanlar içerisindeki <span style='color: red; font-weight: bold;'>KIRMIZI</span> alanları doldurup bankanıza iletmeniz gerekmektedir."+
                    "<br/><br/><span style='font-weight: bold;'>NOT:</span> Bazı dilekçelerde teknik personel bilgisi istenmektedir. Eğer teknik personel yoksa işletme sahibinin/yetkilisinin bilgilerini yazabilirsiniz."+
                    "<br/><br/>Bankanızdan <span style='font-weight: bold;'>SMS/E-posta</span> ile kullanıcı adı ve şifre geldiği zaman bu sayfadan ilgili bankanın durumunu Pasif'ten Aktif'e çekerek size verilen bilgileri girebilirsiniz."+
                    "<br/><br/><a href='"+(process.env.REACT_APP_API_URL).replace('/public/api', '/public').replace('api.ekohesap.com/api', 'api.ekohesap.com')+"/panel/download.php?folder=documents/open-banking-application-forms&file=Banka_Entegrasyon_Dokumani.pdf' class='btn btn-primary blue'>Banka Entegrasyon Klavuzunu İndir</a>",
                showConfirmButton: true,
                confirmButtonText: "Tamam",
                confirmButtonColor: "darkgray",
                showCancelButton: false,
                cancelButtonText: "İptal",
                closeOnConfirm: true,
                closeOnCancel: true,
                html: true,
                type: "info",
                customClass: 'sweet-alert',
            }, function(isConfirm) {
                if (isConfirm) {

                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {

    }
    onSave() {

    }
    getBankApis() {
        let _self = this;
        _self.setState({recordSwitches: {}, editBankId: null });
        let params = {};
        _self.setState({page_loading: true });
        Api.post("/getObifinBanks", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response.result;
                _self.setState({records: getData, page_loading: false });
            }
        });
    }
    updateObifinBank(bank_id) {
        let _self = this;
        _self.setState({ showInsertModal: true, editBankId: bank_id });
    }
    updateObifinBankApiState(bank_id, state) {
        let _self = this;
        _self.setState((prevState) => ({
            recordSwitches: {
                ...prevState.recordSwitches,
                [bank_id]: state,
            },
        }));
        if (state) {
            // open modal
            _self.updateObifinBank(bank_id);
        } else {
            let params = {};
            Api.post("/setOBifinBankApiDeactive/"+bank_id, params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    let getData = response.result;
                    toastr.info("Başarılı!", "Banka API'si Pasif Edildi");
                }
            });
        }
    }


    render() {


        const { recordSwitches } = this.state;

        return (<>
            <div>
                {(this.state.page_loading == true) ? <EkoSpinner /> :
                    <>
                        <ObifinBankApiAdd
                            showModal={this.state.showInsertModal}
                            editId={this.state.editBankId}
                            onSave={() => {
                                this.setState({ showInsertModal: false })
                                this.getBankApis()
                            } }
                            onCancel={() => {
                                this.setState({ showInsertModal: false })
                                this.getBankApis()
                            } }
                            errors={this.state.errors} />

                        <div className="portlet light">
                            <div className="portlet-title">
                                <div className="caption font-dark"><span className="caption-subject bold uppercase">Banka API Yönetimi</span></div>
                                <div className="pull-right">
                                    {/* <button className="btn btn-primary blue" onClick={() => this.addNewApi()}>Yeni API Ekle</button> */}
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className={"row"}>
                                    <div className={"col-md-12 col-xs-12"}>
                                        <div className={"alert alert-info"}>
                                            <strong>Önemli Bilgi!</strong> Bankalarınızdan aldığınız API'leri aktif etmek için bu bölümü kullanınız.
                                            Size verilen bilgiler ile ilgili banka satırını düzenleyerek aktif edebilirsiniz.
                                        </div>
                                    </div>

                                    <table className='table table-striped'>
                                        <thead>
                                        <tr>
                                            <th>Banka</th>
                                            <th>Başvuru Evrakları</th>
                                            <th style={{textAlign: "center"}}>Durum</th>
                                            <th width={"90"}>İşlem</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.records.map((record) => {
                                                const switchValue = recordSwitches[record.id] !== undefined ? recordSwitches[record.id] : record.is_active;
                                                let documentsToDownload = "";
                                                if (record.application_files != null) {
                                                    // split comma and add download link
                                                    let files = record.application_files.split(",");
                                                    let saydir = 1;
                                                    files.map((file) => {
                                                        documentsToDownload += "<a href='"+(process.env.REACT_APP_API_URL).replace('/public/api', '/public').replace('api.ekohesap.com/api', 'api.ekohesap.com')+'/panel/download.php?folder=documents/open-banking-application-forms&file='+file+"' class='btn btn-sm btn-default'><i class='fa fa-download'></i> Dilekçe "+saydir+"</a> ";
                                                        saydir++;
                                                    });
                                                }
                                                return (
                                                    <tr key={record.id}>
                                                        <td>{record.bank_name}</td>
                                                        <td><div dangerouslySetInnerHTML={{ __html: documentsToDownload }}></div></td>
                                                        <td>
                                                            <EkoFormSwitch
                                                                textOn="Aktif"
                                                                textOff="Pasif"
                                                                onChange={(newSwitchStatus) => this.updateObifinBankApiState(record.id, newSwitchStatus)}
                                                                value={switchValue}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-primary blue'
                                                                    onClick={this.updateObifinBank.bind(this, record.id)}><i
                                                                className='fa fa-edit'></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </>);
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(ObifinBankApis);
