/**
 * Created by mehmetyurtar on 29/07/2017.
 */
import React from 'react';

const DesignerToolbar = props => {
    return (
        <ul className="designer--toolbar">
            <li className="designer--toolbar--name">{props.selectedEl.label}</li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontsize">
                    <input key="fontSize" type="text" name="fontSize" value={props.selectedEl.fontSize} onChange={props.onSelectedElPropertyChange} />
                    <span>px</span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item">
                    <select name="fontFamily" value={props.selectedEl.fontFamily} onChange={props.onSelectedElPropertyChange}>
                        <option value="Pt Sans" style={{fontFamily:"'PT Sans', sans-serif"}}>Tirnaksiz</option>
                        <option value="Pt Serif" style={{fontFamily:"'PT Serif', serif"}}>Tirnakli</option>
                        <option value="Ubuntu Mono" style={{fontFamily:"'Ubuntu Mono', monospace"}}>Es Aralikli</option>
                    </select>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontStyle">
                    <span onClick={props.onSelectedElFontWeightChange}
                          className={props.selectedEl.fontWeight === "bold" ? " designer--toolbar__f designer--toolbar__f--first designer--toolbar--fselected" : "designer--toolbar__f designer--toolbar__f--first"}>
                        <i className="fa fa-bold"></i>
                    </span>
                    <span onClick={props.onSelectedElFontStyleChange}
                          className={props.selectedEl.fontStyle  === "italic" ? "designer--toolbar__f designer--toolbar--fselected" : "designer--toolbar__f"}>
                        <i className="fa fa-italic"></i>
                    </span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontStyle">
                    <span onClick={props.onSelectedElTextAlignChange.bind(this,"left")}
                          className={props.selectedEl.textAlign === "left" ? " designer--toolbar__f designer--toolbar__f--first designer--toolbar--fselected" : "designer--toolbar__f designer--toolbar__f--first"}>
                        <i className="fa fa-align-left"></i>
                    </span>
                    <span onClick={props.onSelectedElTextAlignChange.bind(this, "center")}
                          className={props.selectedEl.textAlign  === "center" ? "designer--toolbar__f designer--toolbar--fselected" : "designer--toolbar__f"}>
                        <i className="fa fa-align-center"></i>
                    </span>
                    <span onClick={props.onSelectedElTextAlignChange.bind(this, "right")}
                          className={props.selectedEl.textAlign  === "right" ? "designer--toolbar__f designer--toolbar--fselected" : "designer--toolbar__f"}>
                        <i className="fa fa-align-right"></i>
                    </span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontsize">
                    <span className="designer--toolbar--item__label">SOLDAN</span>
                    <input type="text" name="positionX" value={props.selectedEl.positionX} onChange={props.onSelectedElPropertyChange} />
                    <span>px</span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontsize">
                    <span className="designer--toolbar--item__label">USTTEN</span>
                    <input type="text" name="positionY" value={props.selectedEl.positionY} onChange={props.onSelectedElPropertyChange} />
                    <span>px</span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontsize">
                    <span className="designer--toolbar--item__label">GENISLIK</span>
                    <input type="text" name="width" value={props.selectedEl.width} onChange={props.onSelectedElPropertyChange} />
                    <span>px</span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__fontsize">
                    <span className="designer--toolbar--item__label">YUKSEKLIK</span>
                    <input type="text" name="height" value={props.selectedEl.height} onChange={props.onSelectedElPropertyChange} />
                    <span>px</span>
                </span>
            </li>
            <li className="designer--toolbar--item__container">
                <span className="designer--toolbar--item designer--toolbar__trash">
                    <span onClick={props.onSelectedElRemove.bind(this)}
                          className="designer--toolbar__f designer--toolbar__f--first">
                        <i className="fa fa-trash"></i>
                    </span>
                </span>
            </li>
        </ul>
    );
};

export default DesignerToolbar;