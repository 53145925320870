import React, {Component} from 'react'
import {EkoFormButton, EkoFormCheckbox, EkoFormInputText} from '../../components/elements/EkoForm'
import {browserHistory, Link} from 'react-router'
import Layout from '../../components/layouts/Auth'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import {getUrlParameter} from "../../services/QueryService";
import {Alert} from 'react-bootstrap';
import "../../components/common/stil.css";
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {login: {}},
            saving: false,
            errors: {},
            formFields: {},
            registerSuccess: null,
            alertShow: false,
        }

    }

    onSave(e) {
        e.preventDefault();
        toastr.removeByType('error')
        toastr.removeByType('success')
        this.setState({saving: true});
        this.props.loginUser(this.state.formData.login, this.getRedirectUrl());
    }

    componentWillReceiveProps(nextProps) {
        let errors = nextProps.errorMessage || {};
        let saving = false;
        if (nextProps.errorMessage && !nextProps.errorMessage.message) errors = {login: nextProps.errorMessage};
        if (nextProps.errorMessage && nextProps.errorMessage.message) toastr.error('Hata', nextProps.errorMessage.message);
        this.setState({errors: errors, saving: saving});
    }


    getRedirectUrl(){

        const {redirect} = this.props.location.query;

        let url = '/dashboard';

        if (redirect) {
            url = redirect;
        }

        return url;
    }

    // handleAlertDismiss() {
    //     //auth.error u sifirla
    //     this.props.authError('');
    // }

    redirectToDashboard = () => {
        sessionStorage.removeItem('companies');
        browserHistory.push(this.getRedirectUrl());
    };



    componentWillMount() {
        if (this.props.authenticated) {
            this.redirectToDashboard();
        }

        const registerSuccess = getUrlParameter('register');

        if (registerSuccess && registerSuccess === 'success') {
            this.setState({
                registerSuccess: true,
                alertShow: true,
            });
        }
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    render() {
        const loginProps = {
            onChange: this.setFieldChange.bind(this, "login"),
            onMount: this.setFieldMount.bind(this, "login"),
            formData: this.state.formData.login,
            errors: this.state.errors.login
        }
        return (
            <div>
                <Layout type="login">
                    <div className="content">
                        {this.state.registerSuccess && this.state.alertShow ? <Alert onDismiss={() => {
                            this.setState({
                                alertShow: !this.state.alertShow
                            });
                        }} style={{backgroundColor: '#60bb71', color: '#fff'}} bsStyle={"success"}>
                            Başarılı! E-posta adresinize gönderdiğimiz mail ile üyeliğinizi tamamlayabilirsiniz.
                        </Alert> : null}

                        <h3 className="form-title">Giriş Yap</h3>
                        <form>
                            <div className="input-icon">
                                <i className="fa fa-user"/>
                                <EkoFormInputText {...loginProps}
                                                  id="email"
                                                  style={{paddingLeft: "33px"}}
                                                  label=""
                                                  placeHolder="E-posta Adresi"
                                                  isVertical={true}/>
                            </div>
                            <div className="input-icon">
                                <i className="fa fa-lock"/>
                                <EkoFormInputText {...loginProps}
                                                  id="password"
                                                  type="password"
                                                  style={{paddingLeft: "33px"}}
                                                  label=""
                                                  placeHolder="Şifre"
                                                  isVertical={true}/>
                            </div>
                            <div className="form-actions">
                                <EkoFormCheckbox {...loginProps} className="rememberme mt-checkbox mt-checkbox-outline"
                                                 id="remember" label="Beni Hatırla"/>
                                {/*<div><div className="grecaptcha-badge" style={{width: 256, height: 60, transition: 'right 0.3s ease', position: 'fixed', bottom: 14, right: '-186px', boxShadow: 'gray 0px 0px 5px'}}><div className="grecaptcha-logo"><iframe src="https://www.google.com/recaptcha/api2/anchor?k=6LdxICIUAAAAAPURWJyOYC6_ZisJH7z7F1NRu6dn&co=aHR0cHM6Ly90YXNhcmltLmVrb2hlc2FwLmNvbTo0NDM.&hl=tr&v=r20170906140949&size=invisible&cb=twm7wpje7fx9" title="recaptcha widget'ı" width={256} height={60} frameBorder={0} scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox" /></div><div className="grecaptcha-error" /><textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{width: 250, height: 40, border: '1px solid #c1c1c1', margin: '10px 25px', padding: 0, resize: 'none', display: 'none'}} defaultValue={""} /></div></div>*/}
                                <EkoFormButton label={'Giriş Yap'}
                                               className={'btn btn-default blue pull-right'}
                                               isLoading={this.state.saving}
                                               onClick={this.onSave.bind(this)}
                                />
                                <br/>
                            </div>
                        </form>
                        <div className="clearfix"></div>
                        <div className="forget-password">
                            <p><Link to="/signup" id="register-btn"> Yeni bir hesap mı açmak istiyorsunuz?</Link></p>
                            <p><Link to="/forgot" activeClassName={'test'}> Şifremi Unuttum </Link></p>
                        </div>
                    </div>
                    <div className="desc">
                        <div style={{
                            padding: "15px",
                            fontSize: "35px",
                            textAlign: "center",
                            marginTop: "74px",
                            fontWeight: "bold",
                            color: "#337ab7"
                        }}>
                            Dünyanın<br />en kolay ve özellikli<br />ön muhasebesi
                        </div>
                    </div>
                </Layout>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md-12 login_kutu"}>
                            <i className={"fas fa-file-medical-alt"}></i>
                            <h3>e-Belge</h3>
                            <ul>
                                <li>e-Fatura</li>
                                <li>e-İhracat</li>
                                <li>GİB e-Arşiv</li>
                                <li>e-İrsaliye</li>
                                <li>e-SMM</li>
                            </ul>
                        </div>
                        <div className={"col-md-12 login_kutu"}>
                            <i className={"fas fa-book"}></i>
                            <h3>Ön Muhasebe</h3>
                            <ul>
                                <li>Banka Hesap Hareketleri</li>
                                <li>Mobilden Fatura Gönderim</li>
                                <li>Cari Takip</li>
                                <li>İşletme Raporları</li>
                                <li>e-Ticaret</li>
                            </ul>
                        </div>
                        <div className={"col-md-12 login_kutu"}>
                            <i className={"fa fa-list"}></i>
                            <h3>Üretim / Depo</h3>
                            <ul>
                                <li>Sevk / Alış İrsaliyesi</li>
                                <li>Ürün Varyantı</li>
                                <li>Reçete (Ürün Birleştirme)</li>
                                <li>Depolar Arası Transfer</li>
                                <li>Üretim Planlama</li>
                            </ul>
                        </div>
                        <div className={"col-md-12 login_kutu"}>
                            <i className={"fas fa-calculator"}></i>
                            <h3>Vergi</h3>
                            <ul>
                                <li>Defter Beyan</li>
                                <li>KDV,Muhtasar,Geçici,Yıllık</li>
                                <li>Bordro</li>
                                <li>Demirbaş</li>
                                <li>Amortisman</li>
                            </ul>
                        </div>
                        <div className={"col-md-12 login_kutu"}>
                            <i className={"fa fa-book"}></i>
                            <h3>Genel Muhasebe</h3>
                            <ul>
                                <li>Ön Tanımlı Hesap Planı</li>
                                <li>Mizan</li>
                                <li>Bilanço / Gelir Tablosu</li>
                                <li>Yevmiye / Kebir</li>
                                <li>e-Defter</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

function mapStateToProps(state) {
    return {errorMessage: state.auth.error, authenticated: state.auth.authenticated};
}

export default connect(mapStateToProps, actions)(Login);