import React, {Component} from 'react';
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from "../../util/Api";
import {toastr} from 'react-redux-toastr'
import EkoSpinner from '../elements/EkoSpinner'
import CreateXml from './CreateXml';

class WitholdingPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            witholding: {},
            company: {},
            title: ''
        };
    }

    componentWillMount() {

        Api.get('/company', (response, error) => {
            if (response) {
                let lastname = (response.company.lastname) ? " " + response.company.lastname : "";
                let firstname = (response.company.firstname) ? response.company.firstname : "";
                let name = firstname + lastname;

                this.setState({
                    name: name,
                    company: response.company
                });
            } else {
                toastr.error(error.message);
            }
        });

        let selected = localStorage.getItem('statement_selected');
        const due = JSON.parse(localStorage.getItem('statement_selected') || '{}');

        if (!selected) {
            this.props.router.push   ('/dashboard/statements');
        }


        selected = JSON.parse(selected);

        const url = localStorage.getItem('statement_preview_url')
        const title = localStorage.getItem('statement_preview_title')

        this.setState({
            title: title,
            due: due.id || 0

        });


        Api.post(url + '/' + selected.frequency.toString(), {}, (response, err) => {
            if (err) {
                toastr.error(err.message);
            } else {


                this.setState({
                    witholding: response.data
                })
            }
        });
    }

    buildEmployee = (isciler) => {

        let asgariUcret = {
            title: 'Asgeri Ücretli',

            data: []
        };

        let data = [1, 2, 3];


        if (isciler.asgariUcretli !== undefined) {
            asgariUcret.data.push(isciler.asgariUcretli);

            asgariUcret.data = asgariUcret.data.concat(["", "", ""]);

        } else {
            asgariUcret.data.push("");

            data.forEach((d) => {
                asgariUcret.data.push(isciler["asgariUcAylikAy" + d]);
            });
        }
        let digerUcretli = {
            title: 'Diğer Ücretli',

            data: []
        };


        if (isciler.digerUcretli !== undefined) {
            digerUcretli.data.push(isciler.digerUcretli);

            digerUcretli.data = digerUcretli.data.concat(["", "", ""]);
        } else {
            digerUcretli.data.push("");

            data.forEach((d) => {
                digerUcretli.data.push(isciler["digerUcAylikAy" + d]);
            });
        }


        let toplam = {
            title: 'Toplam',

            data: []
        };

        if (isciler.toplam !== undefined) {
            toplam.data.push(isciler.toplam);

            toplam.data = toplam.data.concat(["", "", ""]);

        } else {
            toplam.data.push("");

            data.forEach((d) => {
                toplam.data.push(isciler["toplamUcAylikAy" + d.toString()]);
            });
        }

        let istisnaVeTesvikKapsamindakiUcretli = {
            title: 'İstisna ve Teşvik Kapsamındaki Ücretliler ',

            data: []
        };

        if (isciler.istisnaVeTesvikKapsamindakiUcretli !== undefined) {
            istisnaVeTesvikKapsamindakiUcretli.data.push(isciler.istisnaVeTesvikKapsamindakiUcretli);

            istisnaVeTesvikKapsamindakiUcretli.data = istisnaVeTesvikKapsamindakiUcretli.data.concat(["", "", ""]);
        } else {

            istisnaVeTesvikKapsamindakiUcretli.data.push("");

            data.forEach((d) => {
                istisnaVeTesvikKapsamindakiUcretli.data.push(isciler["istisnaVeTesvikKapsamiUcAylikAy" + d.toString()]);
            });
        }


        return [
            digerUcretli,
            asgariUcret,
            istisnaVeTesvikKapsamindakiUcretli,
            toplam
        ];
    };


    render() {
        let matrahBildirimi = this.state.witholding.matrahBildirimleri ? this.state.witholding.matrahBildirimleri.matrahBildirimi : [];
        let isciler = this.state.witholding.calisanIsciler ? this.buildEmployee({...this.state.witholding.calisanIsciler}) : [];
        let inputs = [
            {name: 'company', value: this.state.company || {}},
            {name: 'due', value: this.state.due},
            {
                name: 'data', value: {
                muhtasar: this.state.witholding || {},
                isciler: isciler || {},
                beyanimVarYok: 1,
            }
            }
        ];

        return !this.state.witholding ? <EkoSpinner/> : (
            <div className="portlet light ">
                <div className="portlet-title">
                    <div className="col-md-3 pull-left">
                        <h4 className="font-dark">
                        <span className="caption-subject bold"
                              style={{textTransform: "uppercase"}}>{localStorage.getItem('statement_preview_title')}</span>
                        </h4>
                    </div>


                    <div className="col-md-3 pull-right">
                        <CreateXml action={process.env.REACT_APP_API_URL + '/xml/Muhtasar'}
                                   loading={!!this.state.witholding} inputs={inputs}/>

                    </div>
                </div>

                <div className="col-md-1"></div>
                <div className="portlet-title col-md-10">
                    <div style={{width: "100%"}} className="caption font-dark">


                        <div className="col-md-6">
                            {this.state.name}
                        </div>
                        <div className="col-md-6">
                            <span className={"pull-right"}>Ödenecek: {this.state.witholding.odenecek}</span>
                        </div>
                        <span className="caption-subject bold uppercase"></span>
                    </div>


                </div>
                <div className="portlet-body">

                    <div className="col-md-1"></div>
                    <div className="col-md-10">


                        <table className="beyanname table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th className="fcolumn  bg-red-thunderbird bg-font-red-thunderbird text-center"
                                    colSpan={3}>
                                    Matrah ve Vergi Bildirimi
                                </th>
                            </tr>
                            <tr className="fcolumn  bg-grey-cascade bg-font-grey-cascade">

                                <th className="fcolumn bg-grey-cascade bg-font-grey-cascaded">Ödemelerin Kodu</th>
                                <th className="fcolumn bg-grey-cascade bg-font-grey-cascade">Ödemelerin Gayrisafi
                                    Tutarı
                                </th>
                                <th width="30%" className="fcolumn bg-grey-cascade bg-font-grey-cascade">Gelir Vergisi
                                    Kesinti Tutarı
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                matrahBildirimi.map((notification, index) => {
                                    return <tr key={index}>
                                        <td>{notification.turKodu}</td>

                                        <td>{notification.gayrisafiTutar}</td>
                                        <td>{notification.kesintiTutari}</td>
                                    </tr>;
                                })
                            }

                            </tbody>

                        </table>
                        <table className="beyanname table table-striped table-bordered table-hover">
                            <thead>
                            <tr colSpan={6}>
                                <th className="fcolumn  bg-red-thunderbird bg-font-red-thunderbird text-center"
                                    colSpan={6}>
                                    Çalıştırılan İşçi Sayısı
                                </th>
                            </tr>
                            <tr className="fcolumn  bg-grey-cascade bg-font-grey-cascade">

                                <th colSpan={2} className="fcolumn bg-grey-cascade bg-font-grey-cascaded"></th>
                                <th colSpan={1} className="fcolumn bg-grey-cascade bg-font-grey-cascade">
                                    Aylık
                                </th>
                                <th colSpan={3} className="fcolumn bg-grey-cascade bg-font-grey-cascade">
                                    3 Aylık
                                </th>
                            </tr>
                            </thead>
                        </table>

                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th colSpan={2}></th>
                                <th colSpan={1}></th>
                                <th colSpan={1}>1. Ay</th>
                                <th colSpan={1}>2. Ay</th>
                                <th colSpan={1}>3. Ay</th>
                            </tr>
                            </thead>

                            <tbody>

                            {isciler.map((t, index) => {
                                return <tr key={index}>
                                    <td colSpan={2}>{t.title}</td>

                                    {t.data.map((data_m, key) => {
                                        return <td colSpan={1} key={key}>{data_m}</td>;
                                    })}
                                </tr>;
                            })}
                            </tbody>
                        </table>

                        <table className="beyanname table table-striped table-bordered table-hover">
                            <thead>
                            <tr colSpan={6}>
                                <th className="fcolumn  bg-red-thunderbird bg-font-red-thunderbird text-center"
                                    colSpan={6}>
                                    Tahakkuka Esas İcmal Cetveli
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td colSpan={2}>
                                    Matrah
                                </td>
                                <td>
                                    {this.state.witholding.matrah}

                                </td>
                                <td colSpan={2}>
                                    Tahakkuk Eden
                                </td>
                                <td>
                                    {this.state.witholding.tahakkukEden}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Mahsup
                                </td>
                                <td>
                                    {this.state.witholding.mahsup}
                                </td>
                                <td colSpan={2}>
                                    Tescil Edilecek Tutar
                                </td>
                                <td>
                                    {this.state.witholding.tecilEdilecekTutar}

                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Ödenecek
                                </td>
                                <td>
                                    {this.state.witholding.odenecek}

                                </td>
                                <td colSpan={2}>
                                    İade Edilecek
                                </td>
                                <td>
                                    {this.state.witholding.iadeEdilecek}

                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    Tevkifakata İlişkin Damga Vergisi
                                </td>
                                <td>
                                    {this.state.witholding.damgaVergileri ? this.state.witholding.damgaVergileri.tevkifatDamgaVergisi : 0}

                                </td>
                                <td colSpan={2}>
                                    Beyanname Alt Damga Vergisi
                                </td>
                                <td>
                                    {this.state.witholding.odenecekDamgaVergisi}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
    }
}

export default connect(mapStateToProps, actions)(WitholdingPreview);