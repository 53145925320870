export const PERMISSIONS = [
    ["1", "Ortaklar Sayfası", "4", "3,4,5,6,9", "1,2"],
    ["2", "CompanyUserRole-Kullanıcı ve Yetki Ekle Sayfası", "3,4", "3,4,5,6,9", "1"],
    ["3", "Abonelik Sayfası", "1,2,3,4", "1,2,3,4,5,6,9", "1"],
    ["4", "CompanyOtherInfo-Şirket Bilgileri", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["5", "CompanyTradeInfo-Ticari Bilgiler", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["6", "Payments-ÖdemelerAnasayfa", "3,4", "3,4,5,6,9", "1,2,3"],
    ["7", "Payments/addEdit-ÖdemeEkleSayfası", "3,4", "3,4,5,6,9", "1,2,3"],
    ["8", "Collections-TahsilatAnasayfa", "3,4", "3,4,5,6,9", "1,2,3"],
    ["9", "Collections/addEdit-TahsilatEkle", "3,4", "3,4,5,6,9", "1,2,3"],
    ["10", "Cheque-ÇekAnasayfa", "3,4", "3,4,5,6,9", "1,2,3"],
    ["11", "ChequeModal-ÇekEkleGuncelle", "3,4", "3,4,5,6,9", "1,2,3"],
    ["12", "Supplier-TedarikçiAnasayfa", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["13", "SupplierModal-TedarikçiEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["14", "SupplierTransactions-TedarikçiHareketleri", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["15", "Customer-MüşteriAnasayfa", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["16", "CustomerModal-MüşteriEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["17", "CustomerTransactions-MüşteriHareketleri", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["18", "Purchases-AlışlarAnasayfa", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["19", "Sales-SatışlarAnasayfa", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["20", "ExpenseInvoice-GiderFaturasıEkle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["21", "ProductServiceInvoice-MalHizmetGelenFaturaEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["22", "CustomerReturnInvoice-MüşteriİadeFaturasıEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["70", "PurchaseInvoiceEdit-AlışFaturalarıGüncelle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["23", "SalesInvoice-MüşteriSatışFaturasıEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["24", "PurchaseReturnInvoice-TedarikçiİadeFaturasıEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["25", "ProdutService-ÜrünHizmetAnasayfa", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["26", "ProdutServiceModal-ÜrünHizmetEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["27", "FixedAsset-DemirbaşAnasayfa", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["28", "FixedAssetModal-DemirbaşEkle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["29", "Employee-PersonelAnasayfa", "2,3,4", "1,2,3,4,5,6,9", "1,2"],
    ["30", "EmployeeModal-PersonelEkle", "2,3,4", "1,2,3,4,5,6,9", "1,2"],
    ["31", "EmployeeTransactions-PersonelHareketleri", "2,3,4", "1,2,3,4,5,6,9", "1,2"],
    ["32", "EmployeeSalary-BordroAnasayfa", "2,4", "1,2,3,4,5,6,9", "1,2"],
    ["33", "EmployeeSalary/Add/Edit-BordroEkle", "2,4", "1,2,3,4,5,6,9", "1,2"],
    ["34", "Banks-BankaAnasayfa", "3,4", "3,4,5,6,9", "1,2,3"],
    ["35", "Bank/addEdit-BankaEkle", "3,4", "3,4,5,6,9", "1,2,3"],
    ["36", "BankTransactions-Banka Hareketleri", "3,4", "3,4,5,6,9", "1,2,3"],
    ["37", "BankTransfer-BankalarArasıVirman", "3,4", "3,4,5,6,9", "1,2,3"],
    ["38", "Statements-BeyannameOluşturSayfası", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["39", "SoleProfSalesInvoice-SerbestMeslekMakbuzuAnasayfa", "1,2", "1,2", "1"],
    ["71", "SoleProfSalesInvoice/addEdit-SerbestMeslekMakbuzuEkle", "1,2", "1,2", "1"],
    ["40", "OtherRecipts-DiğerGiderler(Makbuzlar) Anasayfa", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["72", "OtherRecipts/addEdit-DiğerGiderler(Makbuzlar)Ekle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["41", "InsurancePolicy-SigortaPoliçesiAnasayfa", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["73", "InsurancePolicy/addEdit-SigortaPoliçesiEkle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["42", "Receipt-FişGirişAnasayfa", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["43", "Receipt/addEdit-FişEkle", "1,2,3,4", "1,2,3,4,5,6,9", "1,2,3"],
    ["47", "GeçiciVergiSayfası", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["48", "KDV", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["49", "Muhtasar", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["50", "YıllıkGelirVergisi", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["51", "Ebildirge", "2,4,6", "1,2,3,4,5,6,9", "1,2"],
    ["52", "Dashboard", "1,2,3,4,5,6", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["53", "RaporlarSayfası", "1,2,3,4,5,6", "1,2,3,4,5,6,9", "1,2,3,4"],
    ["54", "statementPreview-KDVOnizleme", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["55", "witholdingPreview-MuhtasarOnizleme", "1,2,4", "1,2,3,4,5,6,9", "1,2"],
    ["56", "templates-YazdirmaSablonlariAnasayfa", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["57", "templates-YazdirmaSablonlariEkle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["58", "templates-YazdirmaSablonlariGuncelle", "3,4", "3,4,5,6,9", "1,2,3,4"],
    ["59", "Depolar", "3,4", "3,4,5,6", "1,2,3,4"],
    ["60", "Receipt-Recete", "3,4", "3,4,5,6", "1,2,3,4"],
    ["61", "TransferWarehouse-Transfer", "3,4", "3,4,5,6", "1,2,3,4"],
    ["62", "ProductionPlaning-UretimPlanlama", "3,4", "3,4,5,6", "1,2,3,4"],
    ["63", "SalesShipment-SatışIrsaliyesi", "3,4", "3,4,5,6", "1,2,3,4"],
    ["64", "PurchaseReceipt-AlışIrsaliyesi", "3,4", "3,4,5,6", "1,2,3,4"],
]