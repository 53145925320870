import React, {PureComponent} from 'react';

type Props = {
    isSelected: boolean,
    item: Object,
    value: any,
    style: Object,
    selectItem: Function,
    id: any
}

class SelectResult extends PureComponent<Props> {
    props: Props;

    render() {
        const {value, item, isSelected, id, style, selectItem, index} = this.props

        return (
            <li
                style={{
                    textAlign: "left",
                    fontSize:item.id=='dokuman_tipi_seciniz'?"12px":"",
                    ...style,
                }}
                key={id}

                onClick={() => item && item.disabled==true?"":selectItem(item, index)}
                className={isSelected ? 'selectedItemColor' : item && item.disabled==true? "disabledItem":""}
            >
                {value}

            </li>
        );
    }
}


export default SelectResult;