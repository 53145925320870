import React, {Component} from "react";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import {toastr} from "react-redux-toastr";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import moment from "moment";
import styled from "styled-components";
import Soleprofv2Invoice from "../../screens/SoleProfv2/Soleprofv2Invoice";
class ESMMSendModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData || this.defaultFormData(),
            showModal: false,
            detailIsOpened: false,
            counties: [],
            saving: false,
            savingVkn: false,
            sendBtnDisabledStatus: false,
            invoiceId: 0,
            customerId: 0,
            errors: {},
            formFields: {},
        };
    }



    defaultFormData() {
        return {
            customer: {
                delivery_same_as_invoice_address: true,
                currency: "TRY",
                opening_date: moment().format("YYYY-MM-DD")
            },
            contact: {},
            address: {},
            deliveryAddress: {}
        };
    }

    static getDefaultForm() {
        return {
            customer: {delivery_same_as_invoice_address: true},
            contact: {},
            address: {},
            deliveryAddress: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        let errors = nextProps.errors || {};
        this.setState({
            formData: formData,
            errors: errors,
            showModal: nextProps.showModal
        });
        this.decideTab();
    }

    decideTab() {
        let activeTab = "address";
        let detailIsOpened = this.state.detailIsOpened;

        if (this.props.activeTab && Sugar.Object.isEmpty(this.state.errors)) {
            activeTab = this.props.activeTab;
            detailIsOpened = true;
        } else {
            if (!Sugar.Object.isEmpty(this.state.errors.address)) {
                activeTab = "address";
                detailIsOpened = true;
            } else if (!Sugar.Object.isEmpty(this.state.errors.deliveryAddress)) {
                activeTab = "deliveryAddress";
                detailIsOpened = true;
            } else if (!Sugar.Object.isEmpty(this.state.errors.contact)) {
                activeTab = "contact";
                detailIsOpened = true;
            }
        }
        this.setState({activeTab: activeTab, detailIsOpened: detailIsOpened});
    }

    toggleDetail(state) {
        this.setState({detailIsOpened: state});
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        if (field === "city_id") {
            if (formData[field] !== value) {
                formData["county_id"] = null;
            }
        }
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = {
            ...this.state.formData,
            [type]: {
                ...formData
            }
        };

        if (field === "tax_identity_number") {
            let customer_tax_identity_number = this.state.formData.customer
                .tax_identity_number;

            let tax_identity_number_length = customer_tax_identity_number.length;

            if (tax_identity_number_length >= 10) {
                Api.get("/check-customer/" + customer_tax_identity_number, function (
                    response
                ) {
                    if (response.status === "fail") {
                        toastr.warning(response.message);
                    }
                });
            }
        }

        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onSend() {
        var selectedRow = this.state.formData;
        const integrator = JSON.parse(localStorage.getItem("integrator"));
        const self = this;
        self.setState({ eInvoiceLoading: true,sendBtnDisabledStatus:true });
        Api.post(
            `/${integrator === 3 ? 'nesv2' : (integrator === 2 ? 'nes' : 'sovosSMM')}/sendSmm`,
            {
                soleprofv2_id: selectedRow,
                receiver: null
            },
            (response, err) => {
                if (response) {
                    if(response.status==='fail'){
                        toastr.error("Hata", response.message);
                    }else{
                        toastr.success("Başarılı", "Başarıyla Gönderildi");
                    }
                    self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                    this.onCancel();
                } else {
                    toastr.error("Hata", "Sistemsel Bir Hata Oluştu.");
                    self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                    err =
                        err === "Unauthorized"
                            ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                            : err;
                    toastr.error("Hata", err.message);
                }
            }
        );
    }


    render() {
        const Wrap = styled.div`
          .hidden{display:none !important}
          .showDiv{display:block !important}`;
        return (
            <EkoModal
                showModal={this.state.showModal}
                title={"E-SMM Gönder"}
                onHide={() => {
                    this.setState({
                        showModal: false,
                        formData: this.defaultFormData(),
                        errors: {}
                    });
                    this.onCancel();
                }}
            >
                <div className={"row showDiv"}>
                    <div className={"col-md-12 text-center"}>
                        E-SMM Belgesini Göndermek Üzeresiniz.<br/>Bu İşlemi Yapmak İstediğinize Eminmisiniz?
                        <div style={{"height":"60px"}}></div>
                    </div>
                    <div className={"col-md-12"}>
                        <div className="col-md-12 text-center">
                            <button disabled={this.state.sendBtnDisabledStatus} className="btn btn-default blue btn-sm" onClick={this.onSend.bind(this)}>
                                Gönder
                            </button>
                            <button className="btn btn-default btn-sm" onClick={this.onCancel.bind(this)}>
                                İptal
                            </button>
                        </div>
                    </div>
                </div>
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices
    };
}

export default connect(mapStateToProps, actions)(ESMMSendModal);
