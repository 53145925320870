import React, { Component, useRef } from "react";
import Sugar from "sugar";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoHeaderForm from "../../components/elements/EkoHeaderForm";
import Util from "../../util/Util";
import moment from "moment";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import LoginHelper from "../../services/LoginHelper";
import axios from "axios";
import { ROOT_URL } from "../../actions";
import { EkoFormButton } from "../../components/elements/EkoForm";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddReceipt extends Component {
  constructor(props) {
    super(props);
    this.permitted = [];
    this.setReset = null;

    this.state = {
      naceCodeList: [],
      formData: {},
      saving: false,
      errors: this.defaultErrors(),
      deleteRowIds: [],
      vatRateTypes: [],

      headerFields: [
        {
          title: "VKN/TCKN",
          type: "text",
          id: "vkn",
          maxLength: 11,
          col: 3,
          addComponentKey: 'searchVkn',
          rows: [
            {
              title: "Fiş Numarası",
              type: "number",
              id: "receipt_no",
              clearable: true,
              col: 3,
            },
          ],
        },
        {
          title: "Vergi Dairesi",
          type: "select",
          id: "tax_office_code",
          defaultText: "SEÇİNİZ",
          optionValue: "name",
          searchable: true,
          data: this.props.taxOffices,
          optionId: "code",
          col: 3,
          rows: [{ title: "Fiş Tarihi", type: "date", id: "date", col: 2 }],
        },
        {
          title: "Ad/Unvan",
          type: "text",
          id: "name",
          col: 3,
          rows: [
            {
              title: "Ödeme Şekli",
              type: "select",
              id: "payment_method",
              defaultText: "SEÇİNİZ",
              optionValue: "name",
              data: props.viewOptions.receipts.payment_method,
              optionId: "id",
              col: 2,
            },
          ],
        },
        {
          title: "Soyad/Unvan Devam",
          type: "text",
          id: "surname",
          col: 3,
          rows: [
            {
              title: "İlişkili Hesap",
              type: "select",
              id: "bank_account_id",
              optionValue: "name",
              optionId: "id",
              data: [],
              defaultText: "SEÇİNİZ",
              col: 3,
            },
          ],
        },
      ],
      tableData: {
        headers: [
          { label: "Gider Kategorisi", width: "17%" },
          {
            label: "Faaliyet Adı",
            width: "17%",
            hidden: this.naceCodeHidden(),
          },
          { label: "Açıklama", width: "17%" },
          { label: "Tutar", width: "17%" },
          { label: "KDV Oranı", width: "17%" },
          { label: "KDV Tutarı", width: "17%" },
        ],
        footer: [
          // {
          //     label: 'Toplam Tutar',
          //     type: 'money',
          //     id: 'total_amount',
          //     disabled: true,
          //     visible: true
          // },
          {
            label: "Toplam KDV",
            type: "money",
            id: "vat_amount",
            disabled: true,
          },
          {
            label: "Fiş Toplamı",
            type: "money",
            id: "amount",
            disabled: true,
          },
        ],
        customFooter: null,
        rows: this.defaultRows(),
      },
      vatRates: [],
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:10,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };

    //  this.onCancel = this.onCancel.bind(this);
  }

  defaultErrors() {
    return {
      rows: [
        {
          expense_category_id: false,
          description: false,
          amount: false,
          vat_rate: false,
          vat_amount: false,
        },
      ],
      receipt: {},
      footer: {},
    };
  }

  onVknSearch() {
    const self = this;
    let formData = self.state.formData;
    self.setState({ vknLoad: true });
    Api.post(
      "/customer/searchVkn",
      { identity: formData.receipt?.vkn },
      function (response, error) {
        self.setState({ vknLoad: false });
        if (error) {
          self.setState({
            errors: {
              ...self.state.errors,
              receipt: {
                ...self.state.errors.receipt,
                vkn:
                  "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
              },
            },
          });
        } else {
          const info = response.data?.info || {};
          const taxOffice = response.data?.taxOffice || {};
          const c = formData.receipt;
          const gercekTuzel = info.gercekTuzel
          if (info?.companyName) {
            self.setState({
              errors: {
                ...self.state.errors,
                receipt: {
                  ...self.state.errors.receipt,
                  vkn: null,
                },
              },
            });
            self.setState({
              detailIsOpened: true,
              gercekTuzel,
              formData: {
                ...formData,
                receipt: {
                  ...formData.receipt,
                  name: info?.firstname || c.name,
                  surname: info?.lastname || c.surname || "",
                  tax_office_code: taxOffice?.code || c.tax_office_code,
                },
                // address: {
                //   ...formData.address,
                //   address: info.address || formData.address.address,
                //   city_id: info.city_id || formData.address.city_id,
                //   county_id: info.county_id || formData.address.county_id,
                // },
              },
            });
          } else {
            self.setState({
              errors: {
                ...self.state.errors,
                receipt: {
                  ...self.state.errors.receipt,
                  vkn:
                    "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                },
              },
            });
          }
        }
      }
    );
  }

  isPermitted(fieldName) {
    let fieldCompanyTypes;

    if (
      this.props.viewOptions &&
      this.props.viewOptions.receipts.fieldCompanyTypes
    ) {
      fieldCompanyTypes = this.props.viewOptions.receipts.fieldCompanyTypes;
    }

    if (fieldCompanyTypes === undefined) {
      return false;
    }

    let finded = fieldCompanyTypes.filter((type) => type.id === fieldName);

    if (this.permitted[fieldName] !== undefined) {
      return this.permitted[fieldName];
    }

    if (finded.length === 0) {
      this.permitted[fieldName] = true;
      return true;
    }

    finded = finded[0].name;

    const returnValue = CompanyTypePermission.isPermitted(finded);

    this.permitted[fieldName] = returnValue;

    return returnValue;
  }

  defaultFormData() {
    return {
      receipt: {
        date: null,
        payment_method: 0,
        bank_account_id: 0,
        receipt_no: null,
      },
      items: {
        date: null,
        payment_method: 0,
        bank_account_id: 0,
        receipt_no: null,
      },
      rows: [
        {
          expense_category_id: 0,
          nace_code_id: 0,
          description: "",
          amount: null,
          vat_rate: 0,
          vat_amount: null,
        },
      ],
      footer: {},
    };
  }

  defaultRows() {
    return [
      [
        {
          type: "select",
          id: "expense_category_id",
          optionValue: "description",
          defaultKey: "diğer",
          searchFilter: "key_words",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          data: [],
        },
        {
          type: "select",
          hidden: this.naceCodeHidden(),
          id: "nace_code_id",
          optionValue: "description",
          defaultKey: "diğer",
          searchFilter: "key_words",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          data: [],
        },
        { type: "text", id: "description", vertical: true },
        { type: "money", id: "amount", vertical: true },
        {
          type: "select",
          id: "vat_rate",
          defaultText: "SEÇ",
          optionValue: "name",
          optionId: "id",
          data: [],
        },
        { type: "money", id: "vat_amount", vertical: true },
      ],
    ];
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = this.defaultErrors();

    if (formData.rows.length > errors.rows.length) {
      const defaultErrors = this.defaultErrors();
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
    }
    if (!formData.receipt.id) {
      var json = JSON.parse(localStorage.getItem("nace_code_list"));
        if (json.length > 1) {
          let result = json.find(obj => obj.varsayilan === 1);
        formData.rows[0].nace_code_id = result.id;
      }
    }
    // errors.rows.push({amount:"Zorunludur"});
    this.setState({ formData: formData, errors: errors });
    this.getNewTagModalList();
    this.assignExpenseAndVat();
  }

  assignExpenseAndVat = (next) => {
    let vatRates = {};
    Api.paralel(["/data/vatRateTypes","/DBS/purchaseExpenseCategoriesSelect/list/1/okc"], (resp) => {
      let tableData = this.state.tableData;
      if (resp[0]) {
        tableData.rows[0][4].data = resp[0].vatRateTypes;
        resp[0].vatRateTypes.forEach(function (vr) {
          vatRates[vr.id] = vr;
        });
      }
      if (resp[1]) {
        let items = resp[1].purchaseExpenseCategories.map(function (item) {
          item.codeName = item.description;
          return item;
        });
        let stateItems = {};
        items.forEach(function (item, index) {
          stateItems[item.id] = item;
        });
        tableData.rows.map((row) => {
          row[0].data = items;
          row[0].selectedOptionValue = "codeName";
          row[0].searchFilter = "key_words";
          //row[0].defaultKey = "diğer";
          row[0].id = "expense_category_id";
          return row;
        });
      }
      this.setState({ tableData: tableData, vatRates: vatRates }, () =>
        next ? next() : null
      );
    });
    let tableData = this.state.tableData;
    tableData.rows[0][1].data =
      localStorage.getItem("nace_code_list") !== "undefined"
        ? JSON.parse(localStorage.getItem("nace_code_list"))
        : [];
    tableData.rows[0][0].data = JSON.parse(
      localStorage.getItem("purchase_expense_categories")
    );
    this.setState({ tableData: tableData });
  };

  naceCodeHidden() {
    try {
      this.setState({
        naceCodeList: JSON.parse(localStorage.getItem("nace_code_list")),
      });
      if (JSON.parse(localStorage.getItem("nace_code_list")).length <= 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("catch error", error);
      axios
        .get(ROOT_URL + "/company/naceCodeListSelect", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          var data = JSON.stringify(response.data.naceCode, null, 2);
          localStorage.setItem("nace_code_list", "");
          localStorage.setItem("nace_code_list", data);
        });
      window.setTimeout(function () {
        window.location.href = "/dashboard";
      }, 3000);
    }
  }
  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;

    if (
      formData &&
      formData.rows &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        tableData.rows.push(tableData.rows[0]); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = this.defaultErrors();
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);

      this.setState({ tableData: tableData, errors: errors });
    } else if (
      formData &&
      formData.rows &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({ errors: this.defaultErrors(), tableData: tableData });
    }
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    let errors = this.state.errors;
    const defaultErrors = this.defaultErrors();
    while (errors.rows.length < formData.rows.length)
      errors.rows.push(defaultErrors.rows[0]);

    let headerFields = this.state.headerFields;
    if (nextProps.paymentMethods) {
      headerFields[2].rows[0]["data"] = nextProps.paymentMethods;
    }
    if (formData.receipt.id) {
      this.onReceiptChange({
        items: { payment_method: formData.receipt.payment_method },
      });
      if (
        formData.receipt.vkn == "11111111111" ||
        formData.receipt.vkn == "1111111111"
      ) {
        this.state.headerFields[1].title = "Ülke";
        this.state.headerFields[1].data = JSON.parse(
          localStorage.getItem("country_list")
        );
      } else {
        this.state.headerFields[1].title = "Vergi Dairesi";
        this.state.headerFields[1].data = this.props.taxOffices;
      }
    }

    if (
      this.props.docUploaded !== nextProps.docUploaded &&
      nextProps.docUploaded
    ) {
      const self = this;
      this.documentMessage(() => {
        self.setState({ saving: true });
        let receipt = Object.assign({}, this.state.formData.receipt);
        const func = receipt.id ? "put" : "post";
        const url = receipt.id
          ? "/receipt/updateDocument/" + receipt.id
          : "/receipt/updateDocument";
        Api[func](url, {document_url: nextProps.addDocument.uri}, function (response, error) {
          self.redirect(false, self, true);
        });
      }, () => {
        self.setState({ saving: true });
        let receipt = Object.assign({}, this.state.formData.receipt);
        const func = receipt.id ? "put" : "post";
        const url = receipt.id
          ? "/receipt/updateDocument/" + receipt.id
          : "/receipt/updateDocument";
        Api[func](url, {document_url: nextProps.addDocument.uri}, function (response, error) {
          window.location.href = '/dashboard/purchases/receipt/add';
        });
      });
    }

    this.setState({
      formData: formData,
      errors: errors,
      headerFields: headerFields,
    });
  }

  redirect(isChecked, self, redirectToBase) {
    if (isChecked === true) {
      Api.get(`/receipt/next/${self.props?.receiptId}`, function (res, error) {
        if (res.item) {
          const path = window.location.pathname;
          const ex = path.split("/");
          ex.pop();
          ex.push(res.item.id);
          self.setState({
            tableData: { ...self.state.tableData },
            errors: { ...self.defaultErrors() },
            formData: { ...self.defaultFormData() },
            saving: false,
          });
          window.location.href = ex.join("/");
        } else {
          self.handleSuccess(true);
        }
      });
    } else {
      if (redirectToBase && redirectToBase === true) {
        self.handleSuccess(true);
      } else {
        self.handleSuccess(false);
      }
    }
  }

  documentMessage(callback, callback2) {
    const options = {
      onOk: () => callback(),
      okText: "Tamam",
      disableCancel: true,
      closeOnShadowClick: true,
    }
    if(callback2){
      options.onCancel = () => callback2();
      options.cancelText = "Yeni Fiş Ekle";
      options.disableCancel = false;
    }

    toastr.confirm("Fiş bilgileriniz görüntü işleme ile taranıp doldurulacaktır.", options);
    // const toastrMessageOptions = {
    //   timeOut: 5500, // Default value is 0
    //   component: (
    //     <p>Fiş bilgileriniz görüntü işleme ile taranıp doldurulacaktır</p>
    //   ),
    // };
    // toastr.message("Bilgi", toastrMessageOptions);
  }

  saveReceipt(self, formData, receipt, isFirst, isChecked, redirectToBase) {
    formData.receipt.supplier_id = null;
    if (receipt.payment_method === 4) {
      receipt.employee_id = receipt.bank_account_id;
      receipt.bank_account_id = null;
    } else {
      receipt.employee_id = null;
    }
    receipt.description = receipt.receipt_no + " Numaralı Fiş Giriş";
    if (receipt.date) receipt.date = moment(receipt.date).format("YYYY-MM-DD");
    receipt.total_amount = Util.convertMoneyToDecimal(formData.footer.amount);
    receipt.vat_amount = Util.convertMoneyToDecimal(formData.footer.vat_amount);
    receipt.amount = receipt.total_amount - receipt.vat_amount;

    if (
      !self.isPermitted("payment_method") &&
      !self.isPermitted("bank_account_id")
    ) {
      receipt.payment_method = null;
      receipt.bank_account_id = null;
    }

    const func = receipt.id ? "put" : "post";
    const url = receipt.id ? "/receipt/" + receipt.id : "/receipt";

    if (
      formData.rows.filter((r) => r.amount || r.expense_category_id).length ===
        0 &&
      receipt.document_url &&
      !receipt.bank_account_id &&
      !receipt.receipt_no
    ) {
      const url = receipt.id
        ? "/receipt/updateDocument/" + receipt.id
        : "/receipt/updateDocument";
      Api[func](url, receipt, function (response, error) {
        if (isFirst) {
          self.documentMessage(() =>
            self.redirect(isChecked, self, redirectToBase)
          );
        } else {
          self.redirect(isChecked, self, redirectToBase);
        }
      });
      return;
    }

    Api[func](url, receipt, function (response, error) {
      if (error) {
        self.setState({ saving: false });
        if (error.message) toastr.error(Util.getErrorAsStr(error));
        let errors = self.state.errors;
        if (error.employee_id) error.bank_account_id = error.employee_id;
        errors.receipt = error;
        self.setState({ errors: errors });
      } else {
        let endpointWithParams = [];

        formData.rows.forEach(function (row) {
          row.receipt_id = response.receipt.id;
          let myRow = Object.assign({}, row);
          if (row.amount) {
            myRow.total_amount = Util.convertMoneyToDecimal(row.amount);
            myRow.amount =
              Util.convertMoneyToDecimal(row.amount) -
              Util.convertMoneyToDecimal(row.vat_amount);
          }
          if (row.vat_amount)
            myRow.vat_amount = Util.convertMoneyToDecimal(row.vat_amount);
          if (row.vat_rate) myRow.vat_rate_type_id = row.vat_rate;

          myRow.nace_code_id = row.nace_code_id;

          if (row.id) {
            endpointWithParams.push({
              endpoint: "/receiptLine/" + row.id,
              params: myRow,
              method: "put",
            });
          } else endpointWithParams.push({ endpoint: "/receiptLine", params: myRow, method: "post" });
        });

        if (self.state.deleteRowIds.length > 0) {
          self.state.deleteRowIds.forEach(function (id) {
            endpointWithParams.push({
              endpoint: "/receiptLine/" + id,
              method: "delete",
            });
          });
        }
        if (endpointWithParams.length > 0) {
          Api.paralelAll(endpointWithParams, function (responses, error) {
            if (!error) {
              if (isFirst) {
                self.documentMessage(() =>
                  self.redirect(isChecked, self, redirectToBase)
                );
              } else {
                self.redirect(isChecked, self, redirectToBase);
              }
            } else {
              self.setState({ saving: false });
              toastr.error(Util.getErrorAsStr(error));
            }
          });
        } else {
          self.setState({ saving: false });
          if (isFirst) {
            self.documentMessage(() =>
              self.redirect(isChecked, self, redirectToBase)
            );
          } else {
            self.redirect(isChecked, self, redirectToBase);
          }
        }
      }
    });
  }

  async onSave(refresh, isChecked) {
    var redirectToBase = arguments[0];
    var self = this;
    let formData = this.state.formData;
    let formErrors = this.state.errors;

    /*if(!formData.footer.amount){
         toastr.error('Toplam tutar 0 olamaz');
         }
         else {*/
    self.setState({ saving: true });
    let endpointWithParams = [];
    formData.rows
      .filter((r) => r.amount || r.expense_category_id)
      .forEach(function (row) {
        let myRow = Object.assign({}, row);
        if (row.amount) {
          myRow.total_amount = Util.convertMoneyToDecimal(row.amount);
          myRow.amount =
            Util.convertMoneyToDecimal(row.amount) -
            Util.convertMoneyToDecimal(row.vat_amount);
        }
        if (row.vat_amount)
          myRow.vat_amount = Util.convertMoneyToDecimal(row.vat_amount);
        if (row.vat_rate) myRow.vat_rate_type_id = row.vat_rate;
        endpointWithParams.push({
          method: "post",
          endpoint: "/receiptLine/validation",
          params: myRow,
        });
      });
    let receipt = Object.assign({}, formData.receipt);

    if (
      self.props.addDocument?.uri &&
      receipt.document_url !== self.props.addDocument?.uri
    ) {
      receipt.document_url = self.props.addDocument?.uri;
    }
    receipt.is_checked = isChecked === true;
    receipt.newTagRows=this.state.newTagRows;

    // const docRes = await Api.getAsync("/receipt/getDocumentCount");
    // const docCount = docRes?.data?.count || 0;
    // const isFirst = docCount === 0 && receipt.document_url;
    const isFirst = false;

    if (endpointWithParams.length > 0) {
      Api.serialPutPost(endpointWithParams, function (responses, errors) {
        if (errors.length > 0) {
          self.setState({ saving: false });
          for (let index in errors) {
            formErrors.rows[index] = errors[index];
            if (formErrors.rows[index].vat_rate_type_id) {
              formErrors.rows[index].vat_rate =
                formErrors.rows[index].vat_rate_type_id;
              delete formErrors.rows[index].vat_rate_type_id;
            }
          }
          self.setState({ errors: formErrors });
          //toastr.error(Util.getErrorAsStr(error));
        } else {
          self.saveReceipt(
            self,
            formData,
            receipt,
            isFirst,
            isChecked,
            redirectToBase
          );
        }
      });
    } else {
      self.saveReceipt(
        self,
        formData,
        receipt,
        isFirst,
        isChecked,
        redirectToBase
      );
    }

    //  }
  }

  handleSuccess = (redirectToBase = false) => {
    toastr.success("Başarılı şekilde kaydedildi");

    if (!this.props.receiptId) {
      if (redirectToBase === true) {
        this.props.redirectToBase();
      } else {
        let tableData = { ...this.state.tableData };
        tableData.rows = [...this.defaultRows()];

        this.setState(
          {
            tableData: tableData,
            errors: { ...this.defaultErrors() },
            formData: { ...this.defaultFormData() },
            saving: false,
          },
          () => {
            this.assignExpenseAndVat(() => {
              this.setReset();
              this.onReceiptChange({
                items: {
                  date: null,
                  payment_method: 0,
                  bank_account_id: 0,
                  receipt_no: null,
                },
              });
            });
          }
        );
      }
    } else {
      if (redirectToBase === true) {
        this.props.redirectToBase();
      } else {
        this.props.redirectToAdd();
      }
    }
  };

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onRowDelete(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = Sugar.Array.removeAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.receipt.id) {
      deleteRowIds.push(row.id);
      /*  Api.delete('/receiptLine/'+row.id,function(response,err){
             toastr.success('Fiş satırı silindi');
             });*/
    }
  }

  onReceiptChange(formData,type, field, value) {
    if (this.state.saving) return;
    let headerFields = this.state.headerFields;
    let stateFormData = { ...this.state.formData };
    let errors = this.state.errors;
    if(stateFormData.receipt.id && field!=='date'){
      formData.items.date = stateFormData.receipt.date;
    }
    if (formData.items) {
      stateFormData.receipt = Object.assign(
        stateFormData.receipt,
        formData.items
      );
    }

    if (formData.rows) {
      stateFormData.rows = formData.rows;
      stateFormData = this.calculateTotal(stateFormData);
      if (formData.rows.length > errors.rows.length) {
        const defaultErrors = this.defaultErrors();
        while (errors.rows.length < formData.rows.length)
          errors.rows.push(defaultErrors.rows[0]);
      }
    }

    const paymentMethod = stateFormData.receipt.payment_method;
    if (this.props.bankAccounts && paymentMethod) {
      let bankAccounts = this.props.bankAccounts.filter(
        (b) =>
          b.bank_account_type === paymentMethod &&
          b.is_active === 1 &&
          b.currency === "TRY"
      );
      if (paymentMethod === 4) {
        if (stateFormData.receipt.employee_id) {
          stateFormData.receipt.bank_account_id =
            stateFormData.receipt.employee_id;
        }
        bankAccounts = this.props.employees.filter(
          (b) => b.termination_date === null
        );
        bankAccounts = bankAccounts.map(function (employee) {
          return {
            name: employee.firstname + " " + employee.lastname,
            id: employee.id,
          };
        });
      }
      headerFields[3].rows[0]["data"] = bankAccounts;
      this.setState({
        headerFields: headerFields,
        formData: stateFormData,
        errors: errors,
      });
    } else {
      this.setState({ formData: stateFormData, errors: errors });
    }
  }

  onChangeField(field, value) {
    let formData = this.state.formData;
    if (field === "payment_method" && !this.state.saving) {
      delete formData.receipt.bank_account_id;
    }
    if (field === "vkn" && !this.state.saving) {
      if (value == "11111111111" || value == "1111111111") {
        this.state.headerFields[1].title = "Ülke";
        this.state.headerFields[1].data = JSON.parse(
          localStorage.getItem("country_list")
        );
      } else {
        this.state.headerFields[1].title = "Vergi Dairesi";
        this.state.headerFields[1].data = this.props.taxOffices;
      }
    }
    let errors = this.state.errors;
    errors.receipt[field] = false;
    this.setState({ errors: errors, formData: formData });
  }

  calculateTotal(stateFormData) {
    stateFormData.footer.amount = 0;
    stateFormData.footer.vat_amount = 0;
    stateFormData.footer.total_amount = 0;
    stateFormData.rows.forEach(function (row) {
      if (row.amount)
        stateFormData.footer.amount += Util.convertMoneyToDecimal(row.amount);
      if (row.vat_rate && row.vat_amount)
        stateFormData.footer.vat_amount += Util.convertMoneyToDecimal(
          row.vat_amount
        );
    });

    if (stateFormData.footer.amount > 0) {
      stateFormData.footer.total_amount += stateFormData.footer.amount;
      stateFormData.footer.amount = Util.convertDecimalToMoney(
        stateFormData.footer.amount
      );
    }

    if (stateFormData.footer.vat_amount > 0) {
      stateFormData.footer.total_amount += stateFormData.footer.vat_amount;
      stateFormData.footer.vat_amount = Util.convertDecimalToMoney(
        stateFormData.footer.vat_amount
      );
    }

    if (stateFormData.footer.total_amount > 0) {
      stateFormData.footer.total_amount = Util.convertDecimalToMoney(
        stateFormData.footer.total_amount
      );
    }

    return stateFormData;
  }

  addRow = () => {
    // do nothing
  };

  onReceptLineChange(index, formData, field) {
    let stateFormData = this.state.formData;
    stateFormData.rows[index] = formData;
    const vatId = stateFormData.rows[index].vat_rate;
    let amount = stateFormData.rows[index].amount;
    let errors = this.state.errors;

    if (errors.rows[index]) {
      errors.rows[index][field] = false;
    }

    if ((field === "amount" || field === "vat_rate") && amount && vatId) {
      amount = Util.convertMoneyToDecimal(amount);
      stateFormData.rows[index].vat_amount = Util.convertDecimalToMoney(
        (amount / (1 + this.state.vatRates[vatId].rate)) *
          this.state.vatRates[vatId].rate
      );
      errors.rows[index].vat_amount = false;
    }

    stateFormData = this.calculateTotal(stateFormData);

    this.setState({ formData: stateFormData, errors: errors });
  }

  filterOnlyPermittedFields() {
    const filtered = this.state.headerFields.filter((field) => {
      return this.isPermitted(field.id);
    });

    if (CompanyTypePermission.isSelfEmployment()) {
      filtered.push({});
    }

    return filtered;
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    let receiptId = this.props.receiptId;
    if (receiptId) {
      Api.get("/company/newTagRecordDocumentList/10/" + receiptId, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  render() {
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}} onClick={Util.backButton.bind(this, "/dashboard/purchases/receipt")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <EkoHeaderForm
              setReset={(click) => (this.setReset = click)}
              fields={this.filterOnlyPermittedFields()}
              formData={this.state.formData.receipt}
              errors={this.state.errors.receipt}
              onChangeField={this.onChangeField.bind(this)}
              onChangeFields={this.onReceiptChange.bind(this)}
              searchVkn={<div className="col-md-3" style={{top: 50}}>
                <EkoFormButton
                    label="Sorgula"
                    // faClass="fa fa-plus"
                    isLoading={this.state.vknLoad}
                    onClick={this.onVknSearch.bind(this)}
                />
              </div>}
              title="FİŞ GİRİŞİ"
          >
            <EkoInputTableButtonGroup
                data={this.state.tableData}
                formData={this.state.formData}
                errors={this.state.errors}
                onSave={(refresh, isChecked) => this.onSave(refresh, isChecked)}
                saving={this.state.saving}
                formId={"add_receipt"}
                onRowAdd={this.addRow}
                onCancel={this.onCancel.bind(this)}
                onChangeRow={this.onReceptLineChange.bind(this)}
                onChange={() => null}
                onRowDelete={this.onRowDelete.bind(this)}
                buttons={[
                  {
                    onClick: this.onSave.bind(this, false),
                    labelText: "Kaydet ve Yeni Ekle",
                  },
                ]}
                isCheckButton={true}
                editId={this.props?.receiptId}
                updatedAt={
                  this.state?.formData?.receipt?.is_checked
                      ? this.state?.formData?.receipt?.updated_at
                      : null
                }
            />
          </EkoHeaderForm>

          <a onClick={this.openNewTagModal} style={{top: -60, left: 10, position: "relative"}}>Etiket Ekle</a>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    taxOffices: state.data.taxOffices,
    addDocument: state.page.addDocument,
    docUploaded: state.page.docUploaded,
  };
}

export default connect(mapStateToProps, actions)(AddReceipt);
