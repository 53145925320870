import React, {Component} from "react";

import * as actions from "../../actions";
import {connect} from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import ModalTransaction from "./ModalTransaction";
import PurchasesModal from "../PurchaseInvoice/PurchasesModal";
import Api from "../../util/Api";
import HelperContainer from "../../util/HelperContainer";
import AddCheque from "../Cheque/AddCheque";

import {toastr} from "react-redux-toastr";
import {
  FETCH_BANK_TRANSACTION,
  FETCH_CUSTOMER_TRANSACTION,
  FETCH_EMPLOYEE_TRANSACTION,
  FETCH_ITEM_TRANSACTION,
  FETCH_SUPPLIER_TRANSACTION,
  FETCH_WAREHOUSE_TRANSACTIONS,
} from "../../actions/types";
import EkoSpinner from "../../components/elements/EkoSpinner";
import UtilHelper from "../../util/UtilHelper";
import {EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";
import Util from "../../util/Util";

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      data: [],
      title: "",
      subTitle: "",
      tableName: "",
      showModal: false,
      showModalSales: false,
      showModalPurchase: false,
      showModalCheque: false,
      showModalChequeNumber: 0,
      formChequeData: false,
      isOut: 0,
      fetchApi: {},
      salesApi: {},
      purchaseApi: {},
      purchaseDetailApi: {}
    };
  }

  fetchTransactions() {
    const self = this;
    const transactions = this.props.location.pathname.split("/");
    const transaction = transactions[3];
    const id = this.props.params.id;
    let nameApi = "";
    let backUrl = "";
    let isBank = false;
    switch (transaction) {
      case "customer":
        this.props.fetchData(
          `/customerTransaction/list/` + id,
          FETCH_CUSTOMER_TRANSACTION
        );
        nameApi = "customer";
        backUrl = "/dashboard/customers";
        break;
      case "supplier":
        this.props.fetchData(
          `/supplierTransaction/list/` + id,
          FETCH_SUPPLIER_TRANSACTION
        );
        nameApi = "supplier";
        backUrl = "/dashboard/suppliers";
        break;
      case "employee":
        this.props.fetchData(
          `/employeeTransaction/list/` + id,
          FETCH_EMPLOYEE_TRANSACTION
        );
        nameApi = "employee";
        backUrl = "/dashboard/employee";
        break;
      case "bank":
        this.props.fetchData(
          `/bankTransaction/list/` + id,
          FETCH_BANK_TRANSACTION
        );
        nameApi = "bankAccount";
        backUrl = "/dashboard/banks";
        isBank = true;
        break;
      case "item":
        this.props.fetchData(
          `/itemTransaction/list/` + id,
          FETCH_ITEM_TRANSACTION
        );
        nameApi = "item";
        backUrl = "/dashboard/product-service";
        break;
      case "warehouse":
        this.props.fetchData(
            `/warehouses/transactions/${id}`,
            FETCH_WAREHOUSE_TRANSACTIONS
        );
        console.log("prop",this.props)
        backUrl = "/dashboard/warehouses";
        nameApi = `warehouses/transactions`;
        break;
      default:
    }
    Api.get(`/${nameApi}/` + id, function(response, err) {
      if (response) {
        if (nameApi === "warehouses/transactions") {
        } else {
        let title = null;
        let cur = response[nameApi].currency
            ? response[nameApi].currency.toLowerCase()
            : "try";
        let balance = response[nameApi].balance;
        let add = "";

        if (nameApi === "item") {
          let title =
              response.item.item_name +
              " - ( Ü/H Kod : " +
              response.item.item_code +
              " )";
          let subTitle =
              response.item.type === 1
                  ? " Stok Adedi : " + response.item.quantity
                  : "";

          self.setState({title, subTitle});
          return;
        }

        if (nameApi !== "bankAccount") {
          if (response[nameApi].balance > 0) {
            add =
                "<span style='font-family: \"Open Sans\", sans-serif'>&nbsp Alacak</span>";
          } else {
            balance *= -1;
            add =
                "<span style='font-family: \"Open Sans\", sans-serif'>&nbsp Borç</span>";
          }
        }

        let subTitle =
            cur === "try"
                ? UtilHelper.money.convertDecimalToMoney(balance) +
                " " +
                `<i className="font15 fa fa-${cur}"/>` +
                add
                : `<i className="font15 fa fa-${cur}"/>` +
                " " +
                UtilHelper.money.convertDecimalToMoney(balance) +
                add;
        if (isBank) title = response[nameApi].name;
        else {
          title = response[nameApi].lastname
              ? response[nameApi].firstname + " " + response[nameApi].lastname
              : response[nameApi].firstname;
        }
        self.setState({title: title, subTitle: subTitle});
      }
      } else {
        toastr.error("Hata", err.message);
        self.props.router.push(backUrl);
      }
    });
  }

  componentWillMount() {
    this.fetchTransactions();
  }

  componentWillReceiveProps(nextProps) {
    this.getTable(nextProps);
  }

  setCheque(selectedRow) {
    const self = this;
    Api.get("/cheque/" + selectedRow.record_id, function(response) {
      if (response.cheque) {
        response.cheque.amount=selectedRow.amount;
        response.cheque.amount_fc=selectedRow.amount_fc;
        response.cheque.currency_rate=Util.convertDecimalToMoney4(response.cheque.currency_rate);
        response.cheque.transaction_line_id=selectedRow.id;
        self.setState({
          showModalChequeNumber:1,
          showModalCheque: true,
          formChequeData: response.cheque
        });
      }
    });
    /*
    @oguz aşağıdaki kod iptal edildi zamanında çek düzenle için yazılmış
    let isOut = 0;
    const self = this;
    Api.get("/cheque/" + id, function(response) {
      let formData = {};
      if (response.cheque) {
        formData.cheque = HelperContainer.filterObject(response.cheque);
        try {
          formData.cheque.amount = Util.convertDecimalToMoney(
            formData.cheque.amount
          );
          formData.cheque.commission = Util.convertDecimalToMoney(
            formData.cheque.commission
          );
          formData.cheque.currency_rate = formData.cheque.currency_rate
            .toString()
            .replace(/[#.]/g, ",");
          formData.cheque.due_date = Util.convertFromDatabaseDate(
            formData.cheque.due_date
          );
          if (formData.cheque.from_id && formData.cheque.to_id) isOut = 0;
          else if (formData.cheque.from_id) isOut = 1;
          else if (formData.cheque.to_id) isOut = 2;
        } catch (ex) {
          console.log(ex);
        }
      }
      self.setState({
        showModal: true,
        formChequeData: formData,
        isOut: isOut
      });
    });
    */
  }

  getTable(nextProps) {
    let name = "";
    const transactions = this.props.location.pathname.split("/");
    const transaction = transactions[3];
    switch (transaction) {
      case "customer":
        name = "customerTransaction";
        break;
      case "supplier":
        name = "supplierTransaction";
        break;
      case "employee":
        name = "employeeTransaction";
        break;
      case "bank":
        name = "bankTransaction";
        break;
      case "item":
        name = "itemTransaction";
        break;
      case "warehouse":
        name = "warehousesTransaction";
        break;
      default:
    }
    //
    this.setState({
      data: nextProps[name + "s"],
      tableName: name
    });
  }

  createCustomRowButtons(state) {
    if (state.isSelected) {
      if (state.selectedRow.document_type === "Stok Sayım Fazlası" || state.selectedRow.document_type === "Stok Sayım Noksanı" || state.selectedRow.document_type === "Açılış Stok Miktarı") {
        return (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={() => this.onDelete(state.selectedRow)}
            >
              <i className="fa fa-arrow-circle-o-right" /> Sil
            </button>
        );
      }else if (state.selectedRow.transaction_type === "Bakiye Düzeltme") {
        return (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={() => this.onDeleteCustomerOrSupplier(state.selectedRow)}
            >
              <i className="fa fa-arrow-circle-o-right" /> Sil
            </button>
        );
      }else if (state.selectedRow.transaction_type === "Maaş Tahakkuku") {
        return (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={() => this.onDeleteEmployeeTransaction(state.selectedRow)}
            >
              <i className="fa fa-arrow-circle-o-right" /> Sil
            </button>
        );
      }else if (this.isGo(state.selectedRow)) {
        return (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={this.go.bind(this, state.selectedRow)}
            >
              <i className="fa fa-arrow-circle-o-right" /> Kaynağa Git
            </button>
        );
      } else if (this.editCheque(state.selectedRow)) {
        return (
            <button
                className="btn blue tablebuttons btn-sm"
                onClick={this.go.bind(this, state.selectedRow)}
            >
              <i className="fa fa-arrow-circle-o-right" /> Çek Düzenle
            </button>
        );
      }
    }
    return null;
  }
  onDelete(row) {
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        Api.get("/itemTransaction/delete/" + row.id+"/"+row.document_type, function(response, err) {
          if (response) {
            self.fetchTransactions();
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }
  onDeleteEmployeeTransaction(row) {
    console.log('rowrowrow',row);
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        Api.get("/employeeTransaction/delete/" + row.id, function(response, err) {
          if (response) {
            self.fetchTransactions();
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }
  onDeleteCustomerOrSupplier(row) {
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        var url = "supplierTransactionDelete";
        if(this.state.tableName === "customerTransaction"){
          url = "customerTransactionDelete";
        }else if(this.state.tableName === "supplierTransaction"){
          url = "supplierTransactionDelete";
        }
        Api.get("/"+url+"/" + row.id, function(response, err) {
          if (response) {
            self.fetchTransactions();
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  isGo(selectedRow) {
    switch (parseInt(selectedRow.table_id, 10)) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 8:
      case 9:
      case 11:
      case 12:
      case 19:
        return true;
      default:
        return false;
    }
  }

  editCheque(selectedRow) {
    switch (parseInt(selectedRow.table_id, 10)) {
      case 14:
        return true;
      default:
        return false;
    }
  }

  goToApi(selectedRow) {
    var self = this;
    const id = selectedRow;

    let formData = {};
    Api.get("/salesInvoiceLine/showAll/" + id, function(responses) {
      formData = {};
      if (responses.salesInvoiceLines) {
        formData.sales = HelperContainer.filterObject(
          responses.salesInvoiceLines
        );

        let values = formData.sales;

        self.setState({ fetchApi: values, showModalSales: true });
      }
    });
  }

  goToSales(selectedRow) {
    var self = this;
    const id = selectedRow;

    let formData = {};
    Api.get("/salesInvoice/" + id, function(responses) {
      formData = {};
      if (responses.salesInvoice) {
        formData.sales = HelperContainer.filterObject(responses.salesInvoice);

        let values = formData.sales;

        self.setState({ salesApi: values });
      }
    });
  }

  goToApiPurchase(selectedRow) {
    const self = this;
    const id = selectedRow;

    let formData = {};
    Api.get("/purchaseInvoiceLine/showAll/" + id, function(responses) {
      formData = {};
      if (responses.purchaseInvoiceLines) {
        formData.purchases = HelperContainer.filterObject(
          responses.purchaseInvoiceLines
        );

        let values = formData.purchases;

        self.setState({ purchaseApi: values, showModalPurchase: true });
      }
    });
  }

  goToPurchaseDetail(selectedRow) {
    const self = this;
    const id = selectedRow;
    let formData = {};

    Api.get("/purchaseInvoice/" + id, function(responses) {
      formData = {};
      if (responses.purchaseInvoice) {
        formData.purchase = HelperContainer.filterObject(
          responses.purchaseInvoice
        );

        let purchaseDetail = formData.purchase;

        self.setState({ purchaseDetailApi: purchaseDetail });
      }
    });
  }

  go(selectedRow) {
    const self = this;
    switch (parseInt(selectedRow.table_id, 10)) {
      case 1:
        self.props.router.push(
          "/dashboard/employee/employeeSalary/preview/" +
            selectedRow.record_id +
            "/" +
            selectedRow.employee_id
        );
        break;
      case 2:
        self.goToApiPurchase(selectedRow.record_id);
        self.goToPurchaseDetail(selectedRow.record_id);
        break;
      case 4:
        self.props.router.push(
          "/dashboard/purchases/receipt/edit/" + selectedRow.record_id
        );
        break;
      case 6:
        self.goToApi(selectedRow.record_id);
        self.goToSales(selectedRow.record_id);
        break;
      case 8:
        self.props.router.push(
          "/dashboard/collections/edit/" + selectedRow.record_id
        );
        break;
      case 9:
        self.props.router.push(
          "/dashboard/payments/edit/" + selectedRow.record_id
        );
        break;
      case 11:
        self.props.router.push(
          "/dashboard/purchases/otherReceipt/edit/" + selectedRow.record_id
        );
        break;
      case 12:
        self.props.router.push(
          "/dashboard/purchases/insurancePolicy/edit/" + selectedRow.record_id
        );
        break;
      case 14:
        //self.setCheque(selectedRow.record_id); @oguz burası iptal edilmiş sanırım çeki düzenlemek olabilir amaç, burayı ben çekin hesap harketini düzenlemek için kullanacağım
        self.setCheque(selectedRow);
        break;
      case 19:
        self.props.router.push(
          "/dashboard/transfers/edit/" + selectedRow.record_id
        );
        break;
      default:
        alert("Hata ekstre detayı bulunamadı");
    }
  }

  onSave() {
    this.setState({ showModal: false, formChequeData: false });
  }
  closeChequeModal() {
    this.setState({ showModalCheque: false });
  }
  saveChequeModal = () => {
    var cheque_id=this.state.formChequeData.id;
    var transaction_line_id=this.state.formChequeData.transaction_line_id;
    var currency_rate=this.state.formChequeData.currency_rate;
    var amount_fc=this.state.formChequeData.amount_fc;
    var endp = "";
    if(this.state.tableName === "customerTransaction"){
      endp = '/cheque/customerTransactionUpdate';
    }
    else if(this.state.tableName === "supplierTransaction"){
      endp = '/cheque/supplierTransactionUpdate';
    }
    Api.post(endp, {
      transaction_line_id: transaction_line_id,
      cheque_id:cheque_id,
      amount_fc:amount_fc,
      currency_rate:Util.convertMoneyToDecimal(currency_rate),
    }, (response, errors) => {
      if(response){
        toastr.success("Başarılı", "Başarıyla Güncellendi");
        window.location.reload(false)
      }else{
        toastr.error("Hata", "Düzenleme Sırasında Bir Hata Oluştu");
      }

    });
    this.closeChequeModal();
  };
  chequeAmountOnChange = (e) => {
    if(e===''){ e=1; }
    if(this.state.showModalChequeNumber===2){
      this.setState(prevState => ({
        formChequeData: {
          ...prevState.formChequeData,
          amount_fc: (Util.convertMoneyToDecimal(this.state.formChequeData.amount)/Util.convertMoneyToDecimal(e)).toFixed(2),
          currency_rate:e
        },
      }));
    }
    this.setState({
      showModalChequeNumber:2
    });
  }
  render() {
    if (!this.state.tableName) return <EkoSpinner />;
    const transactionsx = this.props.location.pathname.split("/");
    const transactionx = transactionsx[3];
    var backUrl = "/dashboard";
      switch (transactionx) {
          case "customer":
              backUrl = "/dashboard/customers";
              break;
          case "supplier":
              backUrl = "/dashboard/suppliers";
              break;
          case "employee":
              backUrl = "/dashboard/employee";
              break;
          case "bank":
              backUrl = "/dashboard/banks";
              break;
          case "item":
              backUrl = "/dashboard/product-service";
              break;
          case "warehouse":
              backUrl = "/dashboard/warehouses";
              break;
          default:
      }
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, backUrl)}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          {this.state.tableName ? (
              <EkoDataTable
                  title={this.state.title}
                  subTitle={this.state.subTitle}
                  data={this.state.data}
                  addButtonShow={false}
                  columnClassNames={
                    this.state.tableName === "customerTransaction" ||
                    this.state.tableName === "supplierTransaction"
                        ? {
                          3: "bakiye-punto"
                        }
                        : null
                  }
                  createCustomRowButtons={this.createCustomRowButtons.bind(this)}
                  hideSelectButtons
                  exportImportId={this.props.params.id} // export import için id gönderilmeli
                  // hideExportMenu={
                  //   this.state.tableName !== "itemTransaction" ? false : true
                  // }
                  name={this.state.tableName}
              ></EkoDataTable>
          ) : (
              ""
          )}
          <AddCheque
              showModal={this.state.showModal}
              formData={this.state.formChequeData}
              isOut={this.state.isOut}
              isGiro={false}
              isCollection={0}
              onSave={this.onSave.bind(this)}
              onCancel={() => {
                this.setState({showModal: false});
              }}
              errors={{}}
          />

          <ModalTransaction
              showModal={this.state.showModalSales}
              fetchApi={this.state.fetchApi}
              salesApi={this.state.salesApi}
              closeModal={() => {
                this.setState({showModalSales: false});
              }}
          />

          <PurchasesModal
              showModal={this.state.showModalPurchase}
              purchaseSend={this.state.purchaseApi}
              purchaseDetailApi={this.state.purchaseDetailApi}
              closeModal={() => {
                this.setState({showModalPurchase: false});
              }}
          />
          <EkoModal
              showModal={this.state.showModalCheque}
              onHide={this.closeChequeModal.bind(this)}
              title={"ÇEK DÜZENLE"}
          >
            <div className={"row"}>
              <div className={"col-md-12"}>
                <EkoFormInputText
                    value={this.state.formChequeData.legal_no}
                    colMd={12}
                    placeHolder="Evrak No"
                    label="Evrak No"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.from?.from_whom}
                    colMd={12}
                    placeHolder="Kimden"
                    label="Kimden"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.to?.to_whom}
                    colMd={12}
                    placeHolder="Kime"
                    label="Kime"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.transaction_date}
                    colMd={12}
                    placeHolder="Tarih"
                    label="Tarih"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.due_date}
                    colMd={12}
                    placeHolder="Vade Tarihi"
                    label="Vade Tarihi"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.currency}
                    colMd={12}
                    placeHolder="Döviz Tipi"
                    label="Döviz Tipi"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    onChange={(_, value) => this.chequeAmountOnChange(value)}
                    value={this.state.formChequeData.currency_rate}
                    colMd={12}
                    placeHolder="Kur"
                    label="Kur"
                    type="money"
                    precision={4}
                    id="cheque_currency_rate"
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.amount}
                    colMd={12}
                    placeHolder="Tutar"
                    label="Tutar"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-6"}>
                <EkoFormInputText
                    value={this.state.formChequeData.amount_fc}
                    colMd={12}
                    placeHolder="Döviz Tutar"
                    label="Döviz Tutar"
                    type="text"
                    id=""
                    disabled={true}
                />
              </div>
              <div className={"col-md-12"} style={{"textAlign": "right", "marginTop": "10px"}}>
                <EkoFormButton
                    label={"Kaydet"}
                    faClass="fa fa-plus"
                    buttonsType={"default"}
                    className="btn blue"
                    onClick={this.saveChequeModal}
                />
              </div>
            </div>
          </EkoModal>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerTransactions: state.data.customerTransactions,
    supplierTransactions: state.data.supplierTransactions,
    employeeTransactions: state.data.employeeTransactions,
    bankTransactions: state.data.bankTransactions,
    itemTransactions: state.data.itemTransactions,
    warehousesTransactions: state.data.warehousesTransactions,
  };
}

export default connect(mapStateToProps, actions)(Transactions);
