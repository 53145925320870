import React, {Component} from "react";

import * as actions from "../../actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import AddCustomer from "./AddCustomer";
import Util from "../../util/Util";
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from "../../util/HelperContainer";
import AskCollection from "../Collection/AskCollection";
import Api from "../../util/Api";
//import EkoToastrError from '../../components/elements/EkoToastrError'
import PaginatedDataTable from "../../components/elements/PaginatedDataTable";
import EkoSpinner from "../../components/elements/EkoSpinner";
import {EkoForm, EkoFormButton, EkoFormDate, EkoFormInputText, EkoFormSwitch} from "../../components/elements/EkoForm";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            showBulkPaymentModal: false,
            showBulkPaymentModalFinal: false,
            showTradeReconciliationModal: false,
            errors: {},
            validationFields: [],
            customers: [], //table Item
            sums: false,
            totalCount: null,
            lastPage: null,
            currentPage: null,
            showModal: false,
            dataLoading: false,
            switchValueCommission:true,
            switchValueInstallment:true,
            switchValuePaynet:false,
            formDataModal: [],
            emailTextarea:"",
            trade_reconciliation_start_date:"",
            trade_reconciliation_end_date:"",
        };
    }

    fetchCustomers = (
        sizePerPage = 10,
        page = 1,
        order = [],
        date = [],
        search = ""
    ) => {
        this.setState({dataLoading: true});
        if (this.table) this.table.cleanSelect();

        Api.get(
            Api.generateUrl(
                "/customer/list",

                //search === "" ? "/customer/list" : "/search/customer",
                sizePerPage,
                page,
                order,
                date,
                search
            ),
            (res, err) => {
                if (res.status === "ok") {
                    this.setState({
                        customers: res.customers,
                        totalCount: res.total,
                        lastPage: res.last_page,
                        currentPage: res.current_page,
                        sums: res.sums || this.state.sums,
                        dataLoading: false,
                        formDataModal:res.customers
                    });
                }
            }
        );
    };

    componentWillMount() {
        localStorage.removeItem("new_customer_id_take_collection");
        localStorage.removeItem("new_collection_amount_fc");
        localStorage.removeItem("selected_list_sales_invoice_id");
        localStorage.removeItem("selected_list_purchases_invoice_id");
        localStorage.removeItem("selected_list_receivable_amount_fc");
        localStorage.removeItem("selected_list_payable_amount_fc");
        localStorage.removeItem("new_collection_from_who_type", 1);
        this.fetchCustomers();
    }

    accountTransaction(selectedRow) {
        this.props.router.push("/dashboard/transaction/customer/" + selectedRow.id);
    }

    addCollectionn(selectedRow) {
        localStorage.setItem("new_collection_from_who_type", 1);
        localStorage.setItem("new_customer_id_take_collection", selectedRow.id);
        localStorage.setItem("new_collection_amount_fc", Util.convertDecimalToMoney(selectedRow.balance < 0 ? 0 : selectedRow.balance));
        this.props.router.push("/dashboard/collections/add");
    }

    passiveActive(state) {
        var self = this;
        Api.get("/customer/" + state.selectedRow.id, function (responses) {
            let formData = {};
            formData.customer = HelperContainer.filterObject(responses.customer);
            if (responses.customer) {
                let toggleName = "";
                if (formData.customer.is_active === 1) {
                    toggleName = " pasifleştirmek ";
                } else {
                    toggleName = " aktifleştirmek ";
                }
                toastr.confirm(
                    responses.customer.firstname +
                    " hesabını" +
                    toggleName +
                    "istediğinize emin misiniz?",
                    {
                        onOk: () => {
                            let func = "put";
                            let url = "/customer/" + formData.customer.id;

                            if (formData.customer.is_active === 1) {
                                formData.customer.is_active = 0;
                            } else if (formData.customer.is_active === 0) {
                                formData.customer.is_active = 1;
                            }

                            Api[func](url, formData.customer, function (response, postErrors) {
                                if (postErrors) {
                                    if (postErrors.message) {
                                        toastr.error("Başarısız", postErrors.message);
                                    }
                                } else {
                                    if ((formData.customer.is_active = 1)) {
                                        let toggleName = "";
                                        if (responses.customer.is_active === 1) {
                                            toggleName = " pasifleştirildi";
                                        } else {
                                            toggleName = " aktifleştirildi";
                                        }

                                        toastr.success("Başarılı", "Hesap" + toggleName + "");
                                        self.fetchCustomers();
                                    }
                                }
                            });
                        },
                        onCancel: () => {
                        },
                        okText: "Evet",
                        cancelText: "Hayır"
                    }
                );
            }
        });
    }

    openUpdate(selectedRow) {
        var self = this;
        const id = selectedRow.id;
        Api.paralel(
            [
                "/customer/" + id,
                "/customerContact/showAll/" + id,
                "/customerAddress/showAll/" + id
            ],
            function (responses) {
                // console.log(responses);
                let formData = {};
                formData.customer = HelperContainer.filterObject(responses[0].customer);
                formData.customer.opening_balance = Util.convertDecimalToMoney(
                    formData.customer.opening_balance
                );
                formData.customer.balance = Util.convertDecimalToMoney(
                    formData.customer.balance
                );

                if (
                    responses[1].customerContacts &&
                    responses[1].customerContacts.length > 0
                )
                    formData.contact = HelperContainer.filterObject(
                        responses[1].customerContacts[0]
                    );
                else formData.contact = {};

                if (
                    responses[2].customerAddresses &&
                    responses[2].customerAddresses.length > 0
                ) {
                    const address = responses[2].customerAddresses.filter(function (a) {
                        return a.address_type === 2;
                    });
                    address[0].is_abroad = !address[0].city_id;
                    formData.address = HelperContainer.filterObject(address[0]);
                    if (!formData.customer.delivery_same_as_invoice_address) {
                        const addressDelivery = responses[2].customerAddresses.filter(
                            function (a) {
                                return a.address_type === 1;
                            }
                        );
                        if (addressDelivery[0].city_id === null)
                            addressDelivery[0].is_abroad = true;
                        formData.deliveryAddress = HelperContainer.filterObject(
                            addressDelivery[0]
                        );
                    } else formData.deliveryAddress = formData.address;
                } else {
                    formData.deliveryAddress = {};
                    formData.address = {};
                }
                self.setState({
                    showInsertModal: true,
                    errors: {},
                    formData: formData
                });
            }
        );
    }

    askCollection(selectedRow) {
        let self = this;

        let params = Object.assign(
            {},
            {
                customer_id: selectedRow.id,
                price: selectedRow.balance,
                row : selectedRow
            }
        );

        let paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));

        let new_paynet_status = paynet_status.toString();

        if (new_paynet_status === "0") {
            self.setState({showModal: true});
        } else if (new_paynet_status === "1") {
            Api.post("/paynet/create-payment", params, function (response,err) {
                if (response) {
                    let url = process.env.REACT_APP_API_URL.substr(
                        0,
                        process.env.REACT_APP_API_URL.length - 3
                    );
                    self.setState({showModal: true, link: url + "paynet/" + response});
                }
                if(err){
                    toastr.error(err.message)
                }
            });
        }
    }

    onDelete(selectedRow) {
        const self = this;
        Api.delete("/customer/" + selectedRow.id, function (response, err) {
            if (response) {
                self.fetchCustomers();
                toastr.success("Başarılı", "Başarıyla Silindi");
            } else toastr.error("Hata", err.message);
        });
        /*  let newData = Sugar.Array.filter(this.props.customers,function(object){
                  return (object.id!==selectedRow.id);
              });
              this.props.updateFetchedData(FETCH_SUPPLIERS,{customers:newData});*/
    }

    togglePassive = id => {
        var self = this;
        Api.get("/customer/" + id, function (responses) {
            let formData = {};
            formData.customer = HelperContainer.filterObject(responses.customer);
            if (responses.customer) {
                let toggleName = "";
                if (formData.customer.is_active === 1) {
                    toggleName = " pasifleştirmek ";
                } else {
                    toggleName = " aktifleştirmek ";
                }
                toastr.confirm(
                    responses.customer.firstname +
                    " hesabını" +
                    toggleName +
                    "istediğinize emin misiniz?",
                    {
                        onOk: () => {
                            let func = "put";
                            let url = "/customer/" + formData.customer.id;

                            if (formData.customer.is_active === 1) {
                                formData.customer.is_active = 0;
                            } else if (formData.customer.is_active === 0) {
                                formData.customer.is_active = 1;
                            }

                            Api[func](url, formData.customer, function (response, postErrors) {
                                if (postErrors) {
                                    if (postErrors.message) {
                                        toastr.error("Başarısız", postErrors.message);
                                    }
                                } else {
                                    if ((formData.customer.is_active = 1)) {
                                        let toggleName = "";
                                        if (responses.customer.is_active === 1) {
                                            toggleName = " pasifleştirildi";
                                        } else {
                                            toggleName = " aktifleştirildi";
                                        }

                                        toastr.success("Başarılı", "Hesap" + toggleName + "");
                                        self.fetchCustomers();
                                    }
                                }
                            });
                        },
                        onCancel: () => {
                        },
                        okText: "Evet",
                        cancelText: "Hayır"
                    }
                );
            }
        });
    };

    onSave(formData, formFields) {
        console.log("parent customer save called");
        this.fetchCustomers();
        this.setState({showInsertModal: false, formData: false});
    }
    showBulkPaymentModal(e) {
        this.setState({showBulkPaymentModal: true});
    }

    showTradeReconciliationModal(e) {
        this.setState({showTradeReconciliationModal: true});
    }

    getSumText = sums => {
        return sums
            .map(sum => {
                let currency = "₺";
                if (sum.currency === "USD") currency = "$";
                else if (sum.currency === "EUR") currency = "€";
                else if (sum.currency === "GBP") currency = "£";
                else if (sum.currency === "CHF") currency = "CHF";
                const totalBalance =
                    sum.total_balance < 0 ? sum.total_balance * -1 : sum.total_balance;
                const money =
                    sum.currency && sum.currency !== "TRY"
                        ? currency + " " + Util.convertDecimalToMoney(totalBalance)
                        : Util.convertDecimalToMoney(totalBalance) + " " + currency;
                return sum.total_balance < 0
                    ? money + " Borç <br/>"
                    : money + " Alacak <br/>";
            })
            .join("");
    };
    tgl(switchStatus) {
        this.setState({switchValueCommission: switchStatus});
    }
    tgl2(switchStatus) {
        this.setState({switchValueInstallment: switchStatus});
    }
    tgl3(switchStatus) {
        this.setState({switchValuePaynet: switchStatus});
    }
    emailTextareaChange = (e) => {
        this.setState({ emailTextarea:e.target.value });
    }
    handleInputChange = (e, index, rowId) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 'checked' : '') : value;
        let formDataModal = [...this.state.formDataModal];
        formDataModal[index] = { ...formDataModal[index], ['customer_id']: rowId };
        formDataModal[index] = { ...formDataModal[index], [name]: newValue };
        this.setState({ formDataModal });
    };

    handleSubmit = async () => {
        this.setState({saving:true});
        const hasCheckboxCustomerId = this.state.formDataModal.some(item => item.checkbox_customer_id !== undefined && item.checkbox_customer_id !== '');
        if (!hasCheckboxCustomerId) {
            toastr.error("En az 1 müşteri seçmelisiniz.");
            return false;
        }
        var data = {};
        data.data = this.state.formDataModal;
        data.emailTextarea = this.state.emailTextarea;
        data.commission = this.state.switchValueCommission;
        data.installment = this.state.switchValueInstallment;
        Api.post("/paynet/sendEmailBulkBalance", {data}, (resp, errors) => {
            if (resp) {
                this.setState({showBulkPaymentModal: false,showBulkPaymentModalFinal:true});
                this.setState({saving:false});
            }else{
                toastr.error("Bir Hata Oluştu");
                this.setState({saving:false});
            }
        });
    };
    handleSubmit2 = async () => {
        this.setState({saving:true});
        const hasCheckboxCustomerId = this.state.formDataModal.some(item => item.checkbox_customer_id !== undefined && item.checkbox_customer_id !== '');
        if (!hasCheckboxCustomerId) {
            toastr.error("En az 1 müşteri seçmelisiniz.");
            return false;
        }
        if (this.state.trade_reconciliation_start_date==='' || this.state.trade_reconciliation_end_date==='') {
            toastr.error("Tarih seçmelisiniz.");
            return false;
        }
        var data = {};
        data.data = this.state.formDataModal;
        data.emailTextarea = this.state.emailTextarea;
        data.commission = this.state.switchValueCommission;
        data.installment = this.state.switchValueInstallment;
        data.start_date = this.state.trade_reconciliation_start_date;
        data.end_date = this.state.trade_reconciliation_end_date;
        data.paynet = this.state.switchValuePaynet;
        data.paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
        data.type = 0;
        Api.post("/customerTransaction/sendEmailTradeReconciliation", {data}, (resp, errors) => {
            if (resp) {
                this.setState({showTradeReconciliationModal: false,showBulkPaymentModalFinal:true});
                this.setState({saving:false});
            }else{
                toastr.error("Bir Hata Oluştu");
                this.setState({saving:false});
            }
        });
    };
    onTradeReconciliationStartDateChange(dateField, value, formattedValue) {
        this.setState({
            trade_reconciliation_start_date:value
        })
    }
    onTradeReconciliationEndDateChange(dateField, value, formattedValue) {
        this.setState({
            trade_reconciliation_end_date:value
        })
    }
    render() {
        let paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
        const sum =
            this.state.sums !== false ? this.getSumText(this.state.sums) : null;
        const insertMenu = [
            {id: 1, name: 'Müşteri Ekle'},
            {id: 2, name: 'Toplu Bakiye Düzenle'},
        ];
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <AskCollection
                    showModal={this.state.showModal}
                    link={this.state.link}
                    closeModal={() => {
                        this.setState({showModal: false});
                    }}
                />
                <AddCustomer
                    showModal={this.state.showInsertModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showInsertModal: false});
                    }}
                    errors={this.state.errors}
                />
                <PaginatedDataTable
                    onRef={item => (this.table = item)}
                    openInsert={(menuItem) => {
                        if (menuItem === 1) {
                            this.setState({
                                showInsertModal: true,
                                formData: false,
                                errors: {}
                            });
                        } else if (menuItem === 2) {
                            this.props.router.push("/dashboard/BulkBalanceUpdateCustomer");
                        }
                    }}
                    openUpdate={this.openUpdate.bind(this)}
                    passiveActive={this.passiveActive.bind(this)}
                    addCollectionn={this.addCollectionn.bind(this)}
                    showBulkPaymentModal={this.showBulkPaymentModal.bind(this)}
                    showTradeReconciliationModal={this.showTradeReconciliationModal.bind(this)}
                    askCollection={this.askCollection.bind(this)}
                    accountTransaction={this.accountTransaction.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    onFetch={this.fetchCustomers.bind(this)} // import varsa eklenmeli
                    data={this.state.customers}
                    subTitle={sum}
                    columnClassNames={{
                        3: "bakiye-punto"
                    }}
                    fetchData={this.fetchCustomers}
                    totalCount={this.state.totalCount}
                    currentPage={this.state.currentPage}
                    pageCount={this.state.lastPage}
                    name={"customer"}
                    loading={this.state.dataLoading}
                    insertMenu={insertMenu}
                />
                <EkoModal
                    bsSize="xlg"
                    showModal={this.state.showBulkPaymentModal}
                    title={"Toplu Ödeme Talebi Oluştur"}
                    onHide={() => {
                        this.setState({showBulkPaymentModal: false});
                    }}>

                    {
                        <div>
                            {paynet_status === 0 ?
                                <div style={{"textAlign": "center", "padding": "20px"}}>
                                    Paynet Üyesi Olmadığınız İçin Bu Hizmetten Yararlanamıyorsunuz. <br/>
                                    Kayıt Olmak İçin Aşağıdaki Linke Tıklayınız <br/><br/>
                                    <a href="https://basvuru.paynet.com.tr/basvuru-formu" className="enrollLink"
                                       target="_blank">Kayıt İşlemi İçin Tıklayınız</a>
                                </div>
                                : <EkoForm formId={"add_bulk_payment_data"}>
                                    <div className="row" id={"form-fields"}>
                                        <div className="col-md-12" style={{"padding": "20px"}}>
                                            <div className="row">
                                                <div className="col-md-8">

                                                    <p>
                                                        <b>Eposta İçeriği:</b><br/>
                                                        Merhaba Sayın ...,<br/>
                                                        ... firmasına ... tarihi itibari ile ... TL borç bakiyeniz
                                                        bulunmaktadır.<br/>Aşağıdaki linke tıklayıp kredi kartınız ile
                                                        ödeme
                                                        yapabilirsiniz.<br/>
                                                        Ödeme için teşekkür ederiz. İyi günler dileriz,<br/>
                                                        ...<br/>
                                                        Bakiyeniz: ... TL<br/>
                                                        Not: Ödemeniz Paynet firmasının güvencesi ile alınmaktadır.
                                                        <br/>
                                                        <b>Eposta içeriği yukarıdadır. Ekstra not eklemek isterseniz
                                                            aşağıdaki kutuya giriş yapabilirsiniz.</b>
                                                    </p>
                                                    <textarea style={{"height": "100px"}} className="form-control"
                                                              onChange={(e) => this.emailTextareaChange(e)}></textarea>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            Ödeme Aracı Kurum Komisyonunu Kimin Ödeyeceğini Seçiniz
                                                            <EkoFormSwitch
                                                                leftBox={false}
                                                                md={12}
                                                                textOn="Komisyonu Ben Ödeyeceğim"
                                                                textOff="Karşı Taraf Ödeyecek"
                                                                onChange={(newSwitchStatus) => this.tgl(newSwitchStatus)}
                                                                value={this.state.switchValueCommission}
                                                            />
                                                        </div>
                                                        <div className="col-md-12" style={{"marginTop": "20px"}}>
                                                            Ödeme Aracı Kurum Bu Ödeme İçin Taksit Seçeneği Sunsun Mu?
                                                            <EkoFormSwitch
                                                                leftBox={false}
                                                                md={12}
                                                                textOn="Evet"
                                                                textOff="Hayır"
                                                                onChange={(newSwitchStatus) => this.tgl2(newSwitchStatus)}
                                                                value={this.state.switchValueInstallment}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{"height": "20px"}}></div>
                                            <div>
                                                <table className="table table-striped table-bordered table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th style={{"width": "30px"}}>#</th>
                                                        <th>Müşteri Bilgisi</th>
                                                        <th>Bakiye</th>
                                                        <th>Eposta Adresi</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.customers.map((row, i) => {
                                                        if (row.balance > 0 && row.currency === 'TRY') {
                                                            return (
                                                                <tr key={i}>
                                                                    <td style={{"width": "30px"}}><input type="checkbox"
                                                                                                         name="checkbox_customer_id"
                                                                                                         onChange={(e) => this.handleInputChange(e, i, row.id)}/>
                                                                    </td>
                                                                    <td>{row.fullname}</td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="balance"
                                                                            value={this.state.formDataModal[i]?.balance || ''}
                                                                            onChange={(e) => this.handleInputChange(e, i, row.id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="contactEmail"
                                                                            value={this.state.formDataModal[i]?.contactEmail || ''}
                                                                            onChange={(e) => this.handleInputChange(e, i, row.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal.Footer>
                                        <div className="row buttonProccess2">
                                            <EkoFormButton label={"Gönder"}
                                                           faClass="fa fa-envelope"
                                                           isLoading={this.state.saving}
                                                           onClick={this.handleSubmit}
                                            />
                                        </div>
                                    </Modal.Footer>

                                </EkoForm>
                            }
                        </div>
                    }

                </EkoModal>
                <EkoModal
                    showModal={this.state.showBulkPaymentModalFinal}
                    title={"İşlem Başarılı"}
                    onHide={() => {
                        this.setState({showBulkPaymentModalFinal: false});
                    }}>

                    {
                        <div>
                            <p className={"alert alert-info"} style={{"paddingBottom": "10px"}}>Başarılı, eposta
                                gönderildi.<br/><span style={{"color": "red"}}>Müşterilerinizi spam klasörlerini kontrol etme konusunda uyarınız.</span>
                            </p>
                        </div>
                    }

                </EkoModal>
                <EkoModal
                    bsSize="xlg"
                    showModal={this.state.showTradeReconciliationModal}
                    title={"Toplu Mutabakat Gönder"}
                    onHide={() => {
                        this.setState({showTradeReconciliationModal: false});
                    }}>

                    {
                        <div>
                            <EkoForm formId={"add_bulk_payment_data"}>
                                <div className="row" id={"form-fields"}>
                                    <div className="col-md-12" style={{"padding": "20px"}}>
                                        <div className="row">
                                            <div className="col-md-8">

                                                <p>
                                                    <b>Eposta İçeriği:</b><br/>
                                                    Merhaba Sayın ...,<br/>
                                                    ... firması ile ..., ... Tarihleri arasında mutabakat evrakı
                                                    gönderilmiştir. <br/>Aşağıdaki linke tıklayıp mutabakat sayfasını
                                                    görüntüleyebilirsiniz.<br/>

                                                    ...<br/>
                                                    Güncel Bakiyeniz: ... TL<br/>
                                                    <br/>
                                                    <b>Eposta içeriği yukarıdadır. Ekstra not eklemek isterseniz
                                                        aşağıdaki kutuya giriş yapabilirsiniz.</b>
                                                </p>
                                                <textarea style={{"height": "100px"}} className="form-control"
                                                          onChange={(e) => this.emailTextareaChange(e)}></textarea>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="col-md-12">
                                                    Epostaya ödeme linki eklensin mi?
                                                    <EkoFormSwitch
                                                        leftBox={false}
                                                        md={12}
                                                        textOn="Evet"
                                                        textOff="Hayır"
                                                        onChange={(newSwitchStatus) => this.tgl3(newSwitchStatus)}
                                                        value={this.state.switchValuePaynet}
                                                    />
                                                </div>
                                                <div className="col-md-12"
                                                     style={{"display": this.state.switchValuePaynet === false ? "none" : ""}}>
                                                    {paynet_status !== 0 ? <div className="row">
                                                        <div className="col-md-12">
                                                            Ödeme Aracı Kurum Komisyonunu Kimin Ödeyeceğini Seçiniz
                                                            <EkoFormSwitch
                                                                leftBox={false}
                                                                md={12}
                                                                textOn="Komisyonu Ben Ödeyeceğim"
                                                                textOff="Karşı Taraf Ödeyecek"
                                                                onChange={(newSwitchStatus) => this.tgl(newSwitchStatus)}
                                                                value={this.state.switchValueCommission}
                                                            />
                                                        </div>
                                                        <div className="col-md-12" style={{"marginTop": "20px"}}>
                                                            Ödeme Aracı Kurum Bu Ödeme İçin Taksit Seçeneği Sunsun Mu?
                                                            <EkoFormSwitch
                                                                leftBox={false}
                                                                md={12}
                                                                textOn="Evet"
                                                                textOff="Hayır"
                                                                onChange={(newSwitchStatus) => this.tgl2(newSwitchStatus)}
                                                                value={this.state.switchValueInstallment}
                                                            />
                                                        </div>

                                                    </div> : <div className="row">
                                                        <div className="col-md-12">
                                                            <div style={{"textAlign": "center", "padding": "20px"}}>
                                                                Paynet Üyesi Olmadığınız İçin Bu Hizmetten
                                                                Yararlanamıyorsunuz. <br/>
                                                                Kayıt Olmak İçin Aşağıdaki Linke Tıklayınız <br/><br/>
                                                                <a href="https://basvuru.paynet.com.tr/basvuru-formu"
                                                                   className="enrollLink" target="_blank">Kayıt İşlemi
                                                                    İçin
                                                                    Tıklayınız</a>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                                <div className="col-md-12" style={{"marginTop": "20px"}}>
                                                    <EkoFormDate
                                                        id="trade_reconciliation_start_date"
                                                        dateFormat="DD-MM-YYYY"
                                                        clearable={false}
                                                        label="Mutabakat Başlangıç Tarihi"
                                                        value={this.state.trade_reconciliation_start_date}
                                                        onChange={this.onTradeReconciliationStartDateChange.bind(this)}
                                                    />
                                                </div>
                                                <div className="col-md-12" style={{"marginTop": "20px"}}>
                                                    <EkoFormDate
                                                        id="trade_reconciliation_end_date"
                                                        dateFormat="DD-MM-YYYY"
                                                        clearable={false}
                                                        label="Mutabakat Bitiş Tarihi"
                                                        value={this.state.trade_reconciliation_end_date}
                                                        onChange={this.onTradeReconciliationEndDateChange.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{"height": "20px"}}></div>
                                        <div>
                                            <table className="table table-striped table-bordered table-hover">
                                                <thead>
                                                <tr>
                                                    <th style={{"width": "30px"}}>#</th>
                                                    <th>Müşteri Bilgisi</th>
                                                    <th>Bakiye</th>
                                                    <th>Eposta Adresi</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.customers.map((row, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{"width": "30px"}}><input type="checkbox"
                                                                                                 name="checkbox_customer_id"
                                                                                                 onChange={(e) => this.handleInputChange(e, i, row.id)}/>
                                                            </td>
                                                            <td>{row.fullname}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="balance"
                                                                    value={this.state.formDataModal[i]?.balance || ''}
                                                                    onChange={(e) => this.handleInputChange(e, i, row.id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="contactEmail"
                                                                    value={this.state.formDataModal[i]?.contactEmail || ''}
                                                                    onChange={(e) => this.handleInputChange(e, i, row.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );

                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <Modal.Footer>
                                    <div className="row buttonProccess2">
                                        <EkoFormButton label={"Gönder"}
                                                       faClass="fa fa-envelope"
                                                       isLoading={this.state.saving}
                                                       onClick={this.handleSubmit2}
                                        />
                                    </div>
                                </Modal.Footer>

                            </EkoForm>
                        </div>
                    }

                </EkoModal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        customers: state.data.customers
    };
}

export default connect(mapStateToProps, actions)(Customers);
