import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "./EkoSpinner";

class AddChangingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { change: { changings: "" } },
      formFields: {},
      subItems: [],
      loading: false
    };
  }
  componentWillReceiveProps(nextProps) {
    var self = this;
    this.setState({ loading: true });
    if (nextProps.changingData.id) {
      Api.get("/subItem/showAll/" + nextProps.changingData.id, function(
        res,
        err
      ) {
        if (res.status === "fail") {
          self.setState({ subItems: [], loading: false });
        } else if (res.status === "ok") {
          self.setState({ subItems: res.items, loading: false });
        }
      });
    }
  }

    closeModal() {
        this.setState({loading: false });
        this.props.closeModal(false);
    }

  changingSave() {
    this.setState({ loading: true });
    var self = this;
    if (this.props.changingData.id && this.state.formData.change.changings) {
      Api.post(
        "/subItem/add",
        {
          item_id: this.props.changingData.id,
          name: this.state.formData.change.changings
        },
        (response, err) => {
          if (response.status === "ok") {
            toastr.success("Başarılı", "Başarıyla Eklendi");
            this.setState({
              loading: false,
              formData: {
                change: { changings: "" }
              },
              subItems: [
                ...this.state.subItems,
                {
                  item_id: this.props.changingData.id,
                  name: this.state.formData.change.changings,
                  id: response.inserted_id
                }
              ]
            });
          } else if (response.status === "fail") {
            toastr.error("Hata", err.message);
            self.setState({ subItems: [] });
          } else {
            toastr.error("Beklenmedik bir sorunla karşılaşıldı.");
          }
        }
      );
    } else {
      toastr.error("Lütfen bir değer girin");
    }
  }

  setFieldChange = (type, field, value) => {
    let formData = this.state.formData[type];
    formData[field] = value;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm});

    }

    setFieldMount = (type, field) => null;

  subItemDelete(id) {
    var self = this;
    toastr.confirm(`Bu varyantı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        Api.post("/subItem/delete", { id: id }, function(response, err) {
          if (response) {
            if (response.status === "fail") {
              toastr.error("Hata", response.message);
              return;
            }else{
              toastr.success("Başarılı", response.message);
            }

            return this.props.changingItems;
          } else toastr.error("Hata", err.message);
        });
        //
        Api.get("/subItem/showAll/" + this.props.changingData.id, function(
            res,
            err
        ) {
          if (res.status === "fail") {
            self.setState({ subItems: [], loading: false });
          } else if (res.status === "ok") {
            self.setState({ subItems: res.items, loading: false });
          }
        });
        //
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  render() {
    const addChangingProps = {
      onChange: this.setFieldChange.bind(this, "change"),
      onMount: this.setFieldMount.bind(this, "change"),
      formData: this.state.formData.change
    };

    return (
        this.props.changingData.id?
        <EkoForm>
          <br />
          <div className="row">
            <div className="row">
              <div className="col-md-10">
                <EkoFormInputText
                    {...addChangingProps}
                    id="changings"
                    style={{ paddingLeft: "10px" }}
                    label=""
                    placeHolder="Varyant Ekle"
                    isVertical={true}
                />
              </div>
              <div className="col-md-2">
                <EkoFormButton
                    label="Ekle"
                    isLoading={this.state.loading}
                    className="btn btn-info"
                    style={{ display: "inline",width:"100%" }}
                    onClick={this.changingSave.bind(this)}
                    disabled={this.state.formData.change.changings.length == 0}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12 mx-auto justify-content-center">
                <h4>
                  Eklenen Varyantlar{" "}
                  <i className="fa fa-arrow-alt-circle-down"> &nbsp; &nbsp;</i>
                </h4>
                <br />
                {this.state.loading ? (
                    <EkoSpinner />
                ) : (
                    <div>
                      {this.state.subItems.length > 0 ? (
                          this.state.subItems.map((element, i) => {
                            let subItem = { ...this.state.subItems[i] };
                            let newArray = [...this.state.subItems];
                            newArray[i] = subItem;
                            if (element.name) {
                              return (
                                  <div
                                      key={element.id}
                                      className="alert"
                                      style={{
                                        border: "1px solid #c2cad8",
                                        marginTop: -13,
                                        borderRadius: "4px"
                                      }}
                                  >
                                    <div
                                        className="row"
                                        style={{
                                          display: "flex"
                                        }}
                                    >
                                      <div className="col-md-6">
                                        {subItem.editing ? (
                                            <div className={"row"}>
                                              <div className={"col-md-6"}>
                                                Varyant Adı:
                                                <input
                                                    type="text"
                                                    value={
                                                      subItem.updatedName === undefined
                                                          ? subItem.name
                                                          : subItem.updatedName
                                                    }
                                                    className="form-control"
                                                    onChange={e => {
                                                      subItem.updatedName = e.target.value;
                                                      this.setState({ subItems: newArray });
                                                    }}
                                                />
                                              </div>
                                              <div className={"col-md-6"}>
                                                Stok Miktarı:
                                                <input
                                                    type="text"
                                                    value={
                                                      subItem.updatedQuantity === undefined
                                                          ? subItem.quantity
                                                          : subItem.updatedQuantity
                                                    }
                                                    className="form-control"
                                                    onChange={e => {
                                                      subItem.updatedQuantity = e.target.value;
                                                      this.setState({ subItems: newArray });
                                                    }}
                                                />
                                              </div>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                  paddingTop: "8px"
                                                }}
                                            >
                                              <span>{i + 1}</span> - {subItem.name} - Stok Miktarı: {subItem.quantity??0}
                                            </div>
                                        )}
                                        {/*  <span>{element.name}</span> */}
                                      </div>
                                      <div
                                          className="col-md-6"
                                          style={{
                                            display: "inline",
                                            textAlign: "center"
                                          }}
                                      >
                                        {subItem.editing ? (
                                            <button
                                                className="btn btn-success"
                                                onClick={() => {
                                                  subItem.editing = false;
                                                  subItem.name = subItem.updatedName || subItem.name;
                                                  subItem.quantity = subItem.updatedQuantity || subItem.quantity;
                                                  Api.post(
                                                      "/subItem/update",
                                                      {
                                                        item_id: subItem.item_id,
                                                        name: subItem.name,
                                                        quantity: subItem.quantity,
                                                        id: subItem.id
                                                      },
                                                      (response, err) => {
                                                        if (response) {
                                                          this.setState({
                                                            subItems: newArray
                                                          });
                                                        } else {
                                                          toastr.error("Hata", err.message);
                                                        }
                                                      }
                                                  );
                                                }}
                                            >
                                              <i className="fa fa-save">&nbsp;</i>
                                              Kaydet
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-link"
                                                style={{ border: "1px solid #ddd" }}
                                                onClick={() => {
                                                  subItem.editing = true;
                                                  this.setState({
                                                    subItems: newArray
                                                  });
                                                }}
                                            >
                                              <i className="fa fa-edit">&nbsp;</i>
                                              Düzenle
                                            </button>
                                        )}

                                        {subItem.editing ? (
                                            <button
                                                className="btn btn-default"
                                                style={{ marginLeft: 5 }}
                                                onClick={() => {
                                                  subItem.editing = false;
                                                  subItem.updatedName = undefined;
                                                  subItem.updatedQuantity = undefined;
                                                  this.setState({ subItems: newArray });
                                                }}
                                            >
                                              <i className="fa fa-window-close">
                                                &nbsp;
                                              </i>
                                              İPTAL
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-link"
                                                style={{
                                                  marginLeft: 5,
                                                  color: "red",
                                                  border: "1px solid #ddd"
                                                }}
                                                onClick={() =>
                                                    this.subItemDelete(element.id)
                                                }
                                            >
                                              <i className="fa fa-trash">&nbsp;</i>
                                              SİL
                                            </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                              );
                            }
                          })
                      ) : (
                          <div className="alert alert-danger">
                            {" "}
                            Varyant bulunamadı. Üstteki alandan yeni varyant
                            ekleyebilirsiniz.
                          </div>
                      )}
                    </div>
                )}
              </div>
            </div>
          </div>
        </EkoForm>:<div className="alert alert-danger">
              Ürün varyantı eklemek için, önce Ürün Bilgileri sekmesini girip KAYDET'e tıklamalısınız. Sonra ilgili ürünü seçip, İşlemler tuşunda GÜNCELLE diyerek ürün varyantı bilgisi girebilirsiniz.
            </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(AddChangingModal);