import {ROOT_URL, setHeaders} from '../util/Api';
import axios from 'axios';


class NotificationService {

    saveId(id) {
        setHeaders();

        return axios.post(ROOT_URL + '/notifications/saveId', {id : id});
    }

}

export default NotificationService;