import React, {Component} from 'react'
import {
    EkoForm,
    EkoFormButton,
    EkoFormDate,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormRadioButton
} from '../../components/elements/EkoForm'
//import {Nav, NavItem, Tab, Row} from 'react-bootstrap'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import moment from 'moment'
import Util from '../../util/Util';
import NewTagDocumentModal from "../Company/newTagDocument";
import {FETCH_BANK_ACCOUNTS_ALL,} from '../../actions/types'

class AddLoan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            radioButtons: [
                {
                    label: 'Yeni Kredi',
                    id: 'is_new',
                    radio_id: 'new',
                    value: 1
                },
                {
                    label: 'Mevcut Kredi',
                    id: 'is_new',
                    radio_id: 'current',
                    value: 0
                }
            ],
            term_disabled: false,
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:18,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        };

        this.fetchBankAccounts.bind(this);
    }
    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({
            newTagRows: rows,
        });
    };
    getNewTagModalList = (formData) => {
        formData = formData || this.state.formData;
        if (formData.loan.id) {
            Api.get("/company/newTagRecordDocumentList/18/" + formData.loan.id, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }else{
                    this.resetNewTagModalList();
                }
            });
        }else{
            this.resetNewTagModalList();
        }
    }
    resetNewTagModalList = () => {
        this.setState(prevState => ({
            newTagRows: {
                ...prevState.newTagRows,
                rows: [
                    { id:null,new_tag_type_id:18,new_tag_id: "",record_id:null, rate: "%100,00" }
                ],
            },
        }));
    }
    defaultFormData() {
        return {
            loan: {},
        }
    }

    static defaultProps = {
        status: 2,
    }

    fetchBankAccounts() {
        this.props.fetchData('/bankAccount/showAll', FETCH_BANK_ACCOUNTS_ALL);
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}
        this.fetchBankAccounts();
        this.setState({formData: formData, errors: errors});
    }


    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        this.getNewTagModalList(formData);
        let radio = this.state.radioButtons[0];
        let radio2 = this.state.radioButtons[1];
        if (formData.loan.is_new === 0) {
            radio['checked'] = false
            radio2['checked'] = true
        } else {
            formData.loan.is_new = 1
            radio['checked'] = true
            radio2['checked'] = false
        }


        this.setState({formData: formData, errors: {}, showModal: nextProps.showModal});
    }

    setCurrencyRate() {
        const self = this;
        if (this.state.formData.loan.currency && this.state.formData.loan.start_date) {
            let date = moment(this.state.formData.loan.start_date).format("YYYY-MM-DD");
            let currency = this.state.formData.loan.currency;
            Api.getCurrencyRate(currency, date, (rate, err) => {
                if (rate) {
                    let currency_rate = Util.convertDecimalToString(rate, 4);
                    let formData = this.state.formData;
                    formData.loan = Object.assign({}, formData.loan, {
                        currency_rate: currency_rate
                    })
                    self.setState({formData: formData})
                }
            });
        }

    }

    setFieldChange(type, field, value) {
        if (type === 'loan' && field === 'currency_rate') {
            let clean = value.replace(/[^0-9,]/g, "")
                .replace(/(,.*?),(.*,)?/, "$1");
            if (clean !== value) value = clean
        }
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = {
            ...this.state.formData, [type]: {
                ...formData
            }
        };

        if (type === 'loan' && (field === 'currency' || field === 'start_date')) {
            this.setCurrencyRate()
        }
        if (field === "bank_account_id") {
            const bankAccount = this.props.bankAccounts.filter(item => item.id === value);
            if (bankAccount[0]) {
                bigForm[type].currency = bankAccount[0].currency;
            }
        }
        if (type == 'term' && this.state.formData.loan.id) {
            this.setState({term_disabled: true})
        }


        this.setState({
            formData: bigForm,
            errors: errors
        }, () => field === "bank_account_id" ? this.setCurrencyRate : null);
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});

        if (this.state.formData.loan.id && this.state.formData.loan.term != "") {
            this.setState({term_disabled: true})
        }else{
            this.setState({term_disabled: false})
        }
    }


    onSave() {
        const self = this;
        self.setState({saving: true});

        let amount = Util.convertMoneyToDecimal(this.state.formData.loan.amount || '0,00')
        let interest_rate_total = Util.convertMoneyToDecimal(this.state.formData.loan.interest_rate_total ?
            this.state.formData.loan.interest_rate_total.replace('%', '') : "0,00") / 100
        let interest_rate_monthly = Util.convertMoneyToDecimal(this.state.formData.loan.interest_rate_monthly ?
            this.state.formData.loan.interest_rate_monthly.replace('%', '') : "0,00") / 100
        let currency_rate = Util.convertMoneyToDecimal(this.state.formData.loan.currency_rate || '0,00')
        let start_date = Util.convertToDatabaseDate(this.state.formData.loan.start_date);

        let loan = Object.assign({}, self.state.formData.loan, {
            amount: amount,
            balance: amount,
            interest_rate_total: interest_rate_total,
            start_date: start_date,
            description: this.state.formData.loan.name,
            currency_rate: currency_rate,
            term: this.state.formData.loan.term,
            interest_rate_monthly: interest_rate_monthly,
            loan_payment_date: this.state.formData.loan.loan_payment_date,
            newTagRows:this.state.newTagRows
        });

        let errors = self.state.errors;
        let url = "/loan";
        let func = "post";

        if (loan.id) {
            func = "put";
            url = "/loan/" + loan.id
            if (loan.term === 0)
                delete loan.term;

        }
        Api[func](url, loan, function (response, postErrors) {
            if (postErrors) {
                errors.loan = postErrors;
                if (errors.loan.description) errors.loan.name = errors.loan.description
                if (postErrors.message) {
                    toastr.error(postErrors.message)
                }
                self.setState({saving: false, errors: errors});
            } else {
                if (loan.id) {
                    toastr.success('Başarılı', 'Başarıyla Güncellendi');
                    self.setState({saving: false, errors: {}});
                } else {
                    self.setState({saving: false, errors: {}, formData: self.defaultFormData()});
                    toastr.success('Başarılı', 'Başarıyla Kaydedildi');
                }
                self.props.onSave();
            }

        });
    }

    onCancel() {
        this.resetNewTagModalList();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    getBtnText() {
        if (!this.state.formData.loan.id) return "Kaydet";
        else return "Güncelle";
    }

    onFalse() {
        console.log(this.id, "radio")
    }

    render() {
        let bankAccounts = this.props.bankAccounts;
        let btn = this.getBtnText();
        let title = this.state.formData.loan.id ? "Kredi Güncelle" : "Kredi Ekle"
        const loanProps = {
            onChange: this.setFieldChange.bind(this, "loan"),
            onMount: this.setFieldMount.bind(this, "loan"),
            formData: this.state.formData.loan,
            errors: this.state.errors.loan
        }
        return <div>
            <EkoModal
                showModal={this.state.showModal}
                title={title}
                refreshData={this.fetchBankAccounts.bind(this)}
                onHide={() => {
                    this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                    this.onCancel();
                }}
                spinner={this.state.saving}>
                <EkoForm formId={"add_loan_modal"}>
                    <div className="row">
                        <div className="col-md-6">
                            <EkoFormRadioButton {...loanProps} buttons={this.state.radioButtons} onFalse={this.onFalse}/>
                        </div>
                        <div className="col-md-6">
                            <span style={{fontSize: 13}}>Yeni kredi seçilmesi durumunda kredi kullanım tutarı ilgili banka hesap hareketine işlenecektir.</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <EkoFormInputText {...loanProps}
                                              label="Kredi Adı"
                                              id="name"
                                              isVertical={true}/>
                        </div>
                        <div className="col-md-6">
                            <EkoFormSelect {...loanProps}
                                           disabled={this.state.formData.loan.id}
                                           id="bank_account_id"
                                           isVertical={true}
                                           defaultText="Seçiniz"
                                           options={bankAccounts}
                                           label="Banka Hesabı"
                            />
                        </div>
                    </div>
                    {/*
                <div className="row">
                    <div className="col-md-6">
                        <EkoFormInputText {...loanProps}
                                          label="Yıllık Faiz Oranı"
                                          id="interest_rate_total"
                                          type="percent"
                                          isVertical={true}/>
                    </div>
                    <div className="col-md-6">
                        <EkoFormInputText {...loanProps}
                                          label="Aylık Faiz Oranı"
                                          id="interest_rate_monthly"
                                          type="percent"
                                          isVertical={true}/>
                    </div>
                </div>
                */}
                    <div className="row">
                        <div className={'col-md-6'}>
                            <EkoFormInputText {...loanProps}
                                              disabled={this.state.formData.loan.id}
                                              label="Kredi Tutarı"
                                              id="amount"
                                              type="money"
                                              isVertical={true}/>
                        </div>
                        <div className={'col-md-6'}>
                            <EkoFormInputText {...loanProps}
                                              disabled={this.state.term_disabled}
                                              label="Taksit Sayısı"
                                              id="term"
                                              type="number"
                                              maxLength={3}
                                              isVertical={true}/>
                        </div>
                        <div className="col-md-6">
                            <EkoFormDate
                                {...loanProps}
                                id="start_date"
                                dateFormat="DD-MM-YYYY"
                                isVertical={true}
                                clearable={false}
                                label="Kredi Kullanım Tarihi"
                            />
                        </div>
                        <div className="col-md-6">
                            <EkoFormDate
                                {...loanProps}
                                id="loan_payment_date"
                                dateFormat="DD-MM-YYYY"
                                isVertical={true}
                                clearable={false}
                                label="Kredi Ödeme Tarihi"
                            />
                        </div>
                    </div>
                    <Modal.Footer>
                        <br/>
                        <div className="row buttonProccess2">
                            <EkoFormButton label={btn}
                                           faClass="fa fa-plus"
                                           isLoading={this.state.saving}
                                           onClick={this.onSave.bind(this)}
                            />
                            <EkoFormButton label="İptal" className="btn"
                                           onClick={this.onCancel.bind(this)}
                            />
                        </div>
                        <hr/>
                        <div style={{"textAlign": "left"}}>
                            <a onClick={this.openNewTagModal}>Etiket Ekle</a>
                        </div>
                    </Modal.Footer>
                </EkoForm>
            </EkoModal>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
        </div>
    }

}

function mapStateToProps(state) {
    return {
        bankAccounts: state.data.bankAccountsAll
    };
}

export default connect(mapStateToProps, actions)(AddLoan);
