

export const VCSS = `.tinvoice {
    width: calc(100% + 42px)!important;
    max-width: calc(100% + 42px)!important;
    margin: -21px;
    margin-bottom: 20px;
}

tbody tr td {
    padding: 0!important;
}

.form-control2:focus {
    cursor: initial;
    border: 1px solid #4a8bc2;
    outline: 0;
}

.form-control2 {
    width: calc(100% + 1px);
    height: 36px;
    margin: -1px;
    padding: 5px;
    cursor: default;
    border: 1px solid #e7ecf1;
    border-right: 0;
}

thead tr th {
    font-size: 12px!important;
    color: #999;
}

thead {
    background: #fcfcfc;
}

.ticon {
    width: 3%;
    text-align: center;
    color: #828282;
}

.ticon2 {
    text-align: center;
    color: #828282;
}

thead tr th {
    border: 0!important;
}

.fcolumn {
    text-indent: 30px;
}

.propextra button {
    padding: 8px 8px 9px!important;
    border: 0!important;
    box-shadow: none!important;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    border-bottom: 2px solid rgb(231, 236, 241);
}

.tplus2 {
    width: 30%;
    margin: auto 0 auto auto;
    text-align: right;
}

.tplus2 tr {
    line-height: 2;
}

.tplus-plus {
    font-size: 20px;
    border-top: 1px solid;
    border-bottom: 3px double;
}

.tplus2 td {
    padding: 6px 0 0 12px!important;
}

.buttonProccess3 {
    display: inline-block;
    float: left;
}

.tinvoice thead tr th {
    text-align: center;
}

.alldiv {
    position: relative;
    display: flex;
    margin: -12px -20px;
    padding: 5px 20px 20px 20px;
    background: #f9f9f9;
}

.tinvoicend {
    font-size: 16px!important;
    position: absolute!important;
    margin: -8px;
    padding: 1px 9px!important;
    color: #818285;
    border: 1px solid;
    background: #fff;
}

#tableFirst input.form-control {
    width: calc(100% + 1px);
    height: 36px;
    margin: -1px;
    padding: 5px;
    cursor: default;
    border: 1px solid #e7ecf1;
    border-right: 0;
    cursor: text;
}

#tableFirst {
    table-layout: auto !important;
}
#tableFirst .form-group {
    margin: 0px;
}

#tableFirst .dropdown-toggle {
    box-shadow: none;
    border: 0px;
}
#tableFirst .ticon {
    cursor: pointer;
}

.actionButtons button {
    margin-right: 3px;
}

.actionButtons button:last-child {
    margin-right: 0px;
}

.footerItems {
    margin-right: -22px;
}`
