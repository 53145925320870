import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { FETCH_E_IN_BOXES } from "../../actions/types";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import moment from "moment";
import GetInModal from "../../components/forms/GetInModal";
import SalesShipmentResponseEnvelopeModal from "./SalesShipmentResponseEnvelopeModal";
import {withRouter} from "react-router";

class EInBoxesIrsaliye extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            data: [],
            saving: false,
            showGetIn: false,
            responseModal: false,
            responseModalRows: {
                rows: [],
            },
            selectedRowId:0,
            selectedRowUuid:0,
        };
    }

    fetchTransactions() {
        if (this.table) this.table.cleanSelect();
        var self = this;
        Api.get("/purchaseReceipt/eInBoxlist", function(response, err) {
            if (response.eInBoxIrsaliye) {
                self.setState({data: response.eInBoxIrsaliye});
            } else {
                toastr.error("Hata", "e-İrsaliye Listesi Alınırken Bir Hata Oluştu");
            }
        });
    }

    componentWillMount() {
        this.fetchTransactions();
    }

    sendAppResp(id,uuid, status) {
        const integrator = JSON.parse(localStorage.getItem("integrator"));
        const self = this;
        toastr.confirm(`Faturayı yanıtlamak istediğinize emin misiniz?`, {
            onOk: () => {
                self.setState({ saving: true });
                if (integrator === 3) {
                    Api.post(
                        "/nesv2/send-app-response-irsaliye",
                        {
                            id: id,
                            uuid: uuid,
                            responseCode: status
                        },
                        (response, err) => {
                            if (response) {
                                if(response.status=='fail'){
                                    toastr.error(response.message);
                                }else{
                                    toastr.success(response.message);
                                }
                                self.fetchTransactions();
                            } else toastr.error("Hata", err.message);
                            self.setState({ saving: false });
                        }
                    );
                }else if (integrator === 1) {
                    Api.post(
                        "/send-app-response-shipment",
                        {
                            id: id,
                            uuid: uuid,
                            responseCode: status
                        },
                        (response, err) => {
                            if (response) {
                                if(response.status=='fail'){
                                    toastr.error(response.message);
                                }else{
                                    toastr.success(response.message);
                                }
                                self.fetchTransactions();
                            } else toastr.error("Hata", err.message);
                            self.setState({ saving: false });
                        }
                    );
                }else{
                    toastr.error("Hata", "Entegratörünüz İrsaliye Yanıtı Göndermek İçin Uygun Değildir.");
                }
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });
    }

    delete(id) {
        toastr.confirm(`e-İrsaliyeyi silmek istediğinize emin misiniz?`, {
            onOk: () => {
                Api.get("/purchaseReceipt/eInBoxlist/delete/"+id, function(response, err) {
                    window.location.reload();
                });
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });
    }
    insertUser(row) {
        const self = this;
        self.setState({ saving: true });
        Api.post(
            "/purchaseReceipt/eInBoxlist/addSupplierTcknVkn",
            { row: row },
            (response, error) => {
                if (response) {
                    this.props.router.push( "/dashboard/purchases/purchaseReceipt/add/" + row.uuid )
                } else
                    toastr.error(
                        "Hata",
                        "Beklenmedik bir hata ile karşılaşıldı lütfen daha sonra tekrar deneyiniz."
                    );
                self.setState({ saving: false, reply: false });
            }
        );
    }
    checkSupplier(row) {
        console.log('row',row);
        const self = this;
        const customerOptions = {
            okText: "Evet",
            cancelText: "Hayır",
            onOk: () => self.insertUser(row),
            onCancel: () => this.props.router.push( "/dashboard/purchases/purchaseReceipt/add/" + row.uuid )
        };
        let customerText = "Bu tedarikçi kayıtlı değil kayıt etmek ister misiniz ?";
        self.setState({ saving: true });
        Api.post(
            "/purchaseReceipt/eInBoxlist/getSupplierTcknVkn",
            { vkn_tckn: row.vkn_tckn,id: row.id, },
            (response, error) => {
                if (response) {

                    if(response.data!=null){
                        this.props.router.push("/dashboard/purchases/purchaseReceipt/add/" + row.uuid)
                    }else{
                        //ekle uyarısı
                        toastr.confirm(customerText, customerOptions);
                    }

                } else
                    toastr.error(
                        "Hata",
                        "Beklenmedik bir hata ile karşılaşıldı lütfen daha sonra tekarar deneyiniz."
                    );
                self.setState({ saving: false });
            }
        );
    }
    createCustomRowButtons(state) {
        let checkBtn = "";
        if (
            state.selectedRow &&
            state.selectedRow.answer_type === null
        )
            checkBtn = (
                <span>
                  <button
                      className="btn blue tablebuttons btn-sm"
                      onClick={() =>
                          this.sendAppResp(state.selectedRow.id,state.selectedRow.uuid, "AllAccepted")
                      }
                      disabled={this.state.saving}
                  >
                    {this.state.saving ? (
                        <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    ) : (
                        <span>
                        <i className="fa fa-check-circle" /> Tümünü Kabul Et
                      </span>
                    )}
                  </button>
                  <button
                      className="btn blue tablebuttons btn-sm"
                      onClick={() =>
                          this.sendAppResp(state.selectedRow.id,state.selectedRow.uuid, "AllRejected")
                      }
                      disabled={this.state.saving}
                  >
                    {this.state.saving ? (
                        <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    ) : (
                        <span>
                        <i className="fa fa-times-circle" /> Tümünü Reddet
                      </span>
                    )}
                  </button>
                    <button
                        className="btn blue tablebuttons btn-sm"
                        onClick={() =>
                            this.openResponseModal(state.selectedRow.id,state.selectedRow.uuid)
                        }
                        disabled={this.state.saving}
                    >
                    {this.state.saving ? (
                        <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    ) : (
                        <span>
                        <i className="fa fa-list" /> Kısmi Kabul
                      </span>
                    )}
                  </button>
                </span>
            );
        return state.isSelected && state.selectedRow ? (
            <span style={{"width":"680px","position":"absolute","zIndex":"9999"}}>
                <button
                    className="btn blue tablebuttons btn-sm"
                    onClick={() =>
                        this.checkSupplier(state.selectedRow)
                    }
                >
                  <i className="fa fa-download" /> Kayıtlara İşle
                </button>
                  <button
                      className="btn blue tablebuttons btn-sm"
                      onClick={() => this.delete(state.selectedRow.id) }
                  >
                  <i className="fa fa-trash" /> Sil
                </button>
                <a
                    target={"_blank"}
                    href={
                        "https://earsiv.co/fatura.php?f=Irsaliye/EInBoxes/" +
                        state.selectedRow.pdf
                    }
                    className="btn blue tablebuttons btn-sm"
                >
                  <i className="fa fa-file-pdf" /> PDF
                </a>{checkBtn}
            </span>
        ) : (
            ""
        );
    }
    closeResponseModal = () => {
        this.fetchTransactions();
        this.setState({
            responseModal: !this.state.responseModal,
        });
    };
    openResponseModal = (id,uuid) => {
        var self = this;
        this.state.responseModalRows.rows=[];
        this.setState({
            selectedRowId:id,
            selectedRowUuid:uuid,
        })
        Api.get("/purchaseReceipt/eInBoxlist/getXmlContentInDespatchLine/"+id, function(response, err) {
            if(response){
                response.data.forEach((data, index) => {
                    self.state.responseModalRows.rows.push({ id:data.ID,ItemName: data.ItemName,Quantity:data.DeliveredQuantity,ReceivedQuantity:data.DeliveredQuantity,RejectedQuantity:"0,000",RejectReason:"",ShortQuantity:"0,000",OversupplyQuantity:"0,000"});
                });
                self.setState({
                    responseModal: !self.state.responseModal,
                });
            }
        });

    };
    render() {
        if (!this.state.data) return <EkoSpinner />;
        return (
            <div>
                <EkoDataTable
                    onRef={ref => (this.table = ref)}
                    data={this.state.data}
                    addButtonShow={false}
                    createCustomRowButtons={this.createCustomRowButtons.bind(this)}
                    hideSelectButtons
                    name={"eInBoxIrsaliye"}
                />
                <SalesShipmentResponseEnvelopeModal
                    responseModalRows={this.state.responseModalRows}
                    showModal={this.state.responseModal}
                    closeResponseModal={this.closeResponseModal}
                    selectedRowId={this.state.selectedRowId}
                    selectedRowUuid={this.state.selectedRowUuid}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        EInBoxesIrsaliye: state.data.EInBoxesIrsaliye
    };
}

export default connect(mapStateToProps, actions)(withRouter(EInBoxesIrsaliye));
