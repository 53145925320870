import React, { Component } from "react";
import {
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import { browserHistory } from "react-router";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Layout from "../../components/layouts/Auth";
import Api from "../../util/Api";
import NotFound from "../../components/dashboard/NotFound";
import Loading from "react-loading-spinner";
import EkoSpinner from "../../components/elements/EkoSpinner";

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { reset: {} },
      saving: false,
      errors: {},
      show: 0,
      formFields: {}
    };
  }

  componentWillMount() {
    const self = this;
    if (this.props.authenticated) browserHistory.push("/dashboard");
    let code = this.props.params.code;
    Api.post(
      "/auth/codeCheck",
      { activation_code: code },
      (response, postErrors) => {
        if (!postErrors) {
          this.setFieldChange("reset", "activation_code", code);
          self.setState({ show: 1 });
        } else self.setState({ show: 2 });
      }
    );
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    self.setState({ saving: true });

    let errors = self.state.errors;
    Api.post(
      "/auth/forgotPassword",
      self.state.formData.reset,
      (response, postErrors) => {
        if (postErrors) {
          errors.reset = postErrors;
          self.setState({ saving: false, errors: errors });
        } else {
          toastr.success("Başarılı", response.message);
          browserHistory.push("/login");
          self.setState({ saving: false, errors: {}, formData: { reset: {} } });
        }
      }
    );
  }

  render() {
    const resetProps = {
      onChange: this.setFieldChange.bind(this, "reset"),
      onMount: this.setFieldMount.bind(this, "reset"),
      formData: this.state.formData.reset,
      errors: this.state.errors.reset
    };

    return (
      <Loading
        isLoading={this.state.show === 0}
        loadingClassName="loading-spinner"
        spinner={EkoSpinner}
      >
        {this.state.show === 1 ? (
          <Layout type="register" containerClass=" container-register">
            <div className="rcenter">
              <h3 className="form-title blue">Şifre Değiştir</h3>
            </div>
            <div className="input-icon">
              <i className="fa fa-lock" />
              <EkoFormInputText
                {...resetProps}
                id="password"
                style={{ paddingLeft: "33px" }}
                label=""
                type="password"
                placeHolder="Şifre"
                isVertical={true}
              />
            </div>
            <div className="input-icon">
              <i className="fa fa-lock" />
              <EkoFormInputText
                {...resetProps}
                id="password_confirmation"
                style={{ paddingLeft: "33px" }}
                label=""
                type="password"
                placeHolder="Şifre Tekrar"
                isVertical={true}
              />
            </div>
            <div className="form-actions">
              <EkoFormButton
                label={"Şifre Değiştir"}
                className={"g-recaptcha btn blue pull-right"}
                isLoading={this.state.saving}
                onClick={this.onSave.bind(this)}
              />
            </div>
            <br />
            <br />
            <br />
          </Layout>
        ) : (
          <NotFound />
        )}
      </Loading>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps, actions)(Reset);
