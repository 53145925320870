import React, {Component} from 'react'
import {Link} from 'react-router'
import {connect} from 'react-redux'
import * as actions from '../../actions'
import TopNavigationMenu from './TopNavigationMenu'
import HeaderMenu from "./HeaderMenu";
import LoginHelper from "../../services/LoginHelper";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuPosition:null
        };
    }
    logout() {
        LoginHelper.logout()

        this.props.logoutUser();
    }

    mobileMenuController() {
        if(this.state.mobileMenuPosition === null){
            this.setState({mobileMenuPosition:"opened"})
        }else if(this.state.mobileMenuPosition === "opened"){
            this.setState({mobileMenuPosition:"closed"})
        }else if(this.state.mobileMenuPosition === "closed"){
            this.setState({mobileMenuPosition:"opened"})
        }else{
            this.setState({mobileMenuPosition:"closed"})
        }
    }

    renderSignout() {
        if (this.props.authenticated) {
            return <li aria-haspopup="true" className="menu-dropdown classic-menu-dropdown ">
                <a onClick={this.logout.bind(this)}>
                    Çıkış Yap
                </a>
            </li>
        }
        return ''
    }


    render() {

        return (
            <div className="page-wrapper-row">
                <div className="page-wrapper-top">
                    <div className="page-header">
                        <div className="page-header-top">
                            <div className="container">
                                <div className="page-logo">
                                    <Link to="/dashboard">
                                        <img src="/app/images/logo-default.png" alt="logo"
                                             className="logo-default"/>
                                    </Link>
                                </div>

                                {/* BEGIN RESPONSIVE MENU TOGGLER */}
                                <a onClick={this.mobileMenuController.bind(this)} className="menu-toggler"/>
                                {/* END RESPONSIVE MENU TOGGLER */}

                                {/* BEGIN TOP NAVIGATION MENU */}
                                <TopNavigationMenu path={this.props.path} subscriberExpiredMenu={this.props.visibleMenu}
                                                   logout={this.logout.bind(this)}/>
                                {/* END TOP NAVIGATION MENU */}
                            </div>
                        </div>
                        {
                            this.props.visibleMenu ?
                                ''
                                :
                                <HeaderMenu visibleMobileMenu={this.state.mobileMenuPosition} path={this.props.path} visibleMenu={this.props.visibleMenu}/>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.data.videoConfigs,
        authenticated: state.auth.authenticated
    };
}

export default connect(mapStateToProps, actions)(Header);
