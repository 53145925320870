import React, {Component} from 'react';
import {EkoForm, EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import EkoSpinner from "../../components/elements/EkoSpinner";

class DeclarationPeriodYears extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.editData || this.defaultFormData(),
            formFields: {},
            recordedDataLoading: true,
            recordedData: [],
            dataLoading: true,
            saving: false,
            companyType: 0,
            shareHolderRecords: []
        };
    }
    defaultFormData() {
        return {
            
        }
    }
    
    setFieldChange(type, field, value) {
        let formData = this.state.formData;
        let errors = this.state.errors || {};
        formData[field] = value;
        let bigForm = this.state.formData;
        this.setState({ formData: bigForm, errors: errors });
        //console.log("------------");
        //console.log(this.state.formData);
    }
    
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
          formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }

    componentWillMount() {
        let _self = this;
        _self.fetchRecordedData();
        _self.fetchShareHolders();
        
        var ct_id = 0;
        if (localStorage.getItem("user")){
            ct_id = JSON.parse(localStorage.getItem("user")).company_type;
        }
        _self.setState({companyType: ct_id});
    }
    fetchRecordedData() {
        let _self = this;
        _self.setState({recordedDataLoading: true});
        Api.get("/company/GetDeclarationPeriodYears", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            _self.setState({recordedData: getData, recordedDataLoading: false});
            console.log("****");
            console.log(_self.state.recordedData);
        });
    }
    onSave() {
        this.setState({saving: true});
        
        Api.post("/company/saveDeclarationPeriodYears", {data: this.state.formData}, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else if (response.actionResult == 1) {
                toastr.success("Başarılı", "Bilgiler Başarıyla Güncellendi.");
            } else {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu-2");
            }
            this.setState({saving: false});
        });
    }
    fetchShareHolders() {
        this.setState({dataLoading: true, shareHolderRecords: []});
        Api.get("/company/getShareHolders", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({shareHolderRecords: getData, dataLoading: false });
            //console.log(getData);
        });
    }
    findRecordedData(shareHolderId, year, type) {
        const data = this.state.recordedData[shareHolderId];
        if (data && data[year]) {
            if (type == "diger") {
                return data[year].diger.replace(".",",");
            }
            if (type == "istisna") {
                return data[year].istisna.replace(".",",");
            }
        }
        return "";
      }
      

    render() {

        const currentYear = new Date().getFullYear();
        const years = [];
        years.push(currentYear);
        for(let i = 1; i < 5; i++) {
            years.push(currentYear - i);
        }
        //years.reverse(); // yılları tersten yaz

        
        const formProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }

        return (
            <>
            {(this.state.dataLoading == true && this.state.recordedDataLoading == true) ? <EkoSpinner /> : 

                <EkoForm>
                    <div className="row">
                        <div className="col-md-12 text-center"><h3 className="h4 text-center">Beyannamelerde Kullanmak Üzere Geçmiş Dönem Zarar Bilgilerini Giriniz</h3></div>

                        {
                            // şahıs ise
                            this.state.companyType == 3 ? 

                                this.state.shareHolderRecords.map((record) =>
                                    
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Yıl</th>
                                                <th>Diğer Zarar</th>
                                                <th>İstisna Zarar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key="0_0_0">
                                                <td rowSpan={6} style={{verticalAlign: "middle"}}>{record.name} {record.surname}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {                                
                                                years.map(year => (
                                                    <tr key={year}>
                                                        <td>{year}</td>
                                                        <td>
                                                            <EkoFormInputText {...formProps}
                                                                label=""
                                                                id={record.id + "_" + year + "_diger"}
                                                                isVertical={true}
                                                                type="money"
                                                                value={this.findRecordedData(record.id, year, "diger")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <EkoFormInputText {...formProps}
                                                                label=""
                                                                id={record.id + "_" + year + "_istisna"}
                                                                isVertical={true}
                                                                type="money"
                                                                value={this.findRecordedData(record.id, year, "istisna")}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                            
                                // limited ise
                            : this.state.companyType == 5 ? 
                                
                                        
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Yıl</th>
                                                <th>Diğer Zarar</th>
                                                <th>İstisna Zarar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key="0_0_0">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {                                
                                                years.map(year => (
                                                    <tr key={year}>
                                                        <td>{year}</td>
                                                        <td>
                                                            <EkoFormInputText {...formProps}
                                                                label=""
                                                                id={"0_" + year + "_diger"}
                                                                isVertical={true}
                                                                type="money"
                                                                value={this.findRecordedData(0, year, "diger")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <EkoFormInputText {...formProps}
                                                                label=""
                                                                id={"0_" + year + "_istisna"}
                                                                isVertical={true}
                                                                type="money"
                                                                value={this.findRecordedData(0, year, "istisna")}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                            : 
                            <>
                                <div className='col-md-12 alert alert-danger'>İşletme türünüze göre kaydedilecek veri bulunamadı.</div>
                            </>
                        }

                    </div>

                    <div style={{"display": "flex", "justifyContent": "center", "marginTop": 20}}>
                        <EkoFormButton
                            label={"Kaydet"}
                            faClass="fa fa-plus"
                            isLoading={this.state.saving}
                            onClick={() => this.onSave()}
                        />
                    </div>
                </EkoForm>
            }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(DeclarationPeriodYears);