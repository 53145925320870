import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import format from "date-fns/format";
import parse from "date-fns/parse";
import "../../css/DatePicker.css";

registerLocale("tr", tr);
setDefaultLocale("tr");

const parsedDate = date => {
  // Dont crash if date is Invalid date

  if (date) {
    let dateStr = parse(date, "yyyy-MM-dd", new Date());
    dateStr =
      dateStr === "Invalid date" || dateStr === "Invalid Date" ? null : dateStr;
    return dateStr;
  } else return null;
};

const toISOLocal = d => {
  var z = n => ("0" + n).slice(-2);
  var zz = n => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off < 0 ? "+" : "-";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    "Z"
  );
};

const EkoDatePicker = props => {
  const { value, name, onChange: onChangeProps } = props;

  const onChange = date => {
    let iso8601 = date ? toISOLocal(date) : null;
    let formatted = date ? format(date, "dd-MM-yyyy") : null;
    onChangeProps(name, iso8601, formatted);
  };

  return (
    <DatePicker
      selected={parsedDate(value)}
      onChange={onChange}
      dateFormat="dd-MM-yyyy"
      showMonthDropdown
      showYearDropdown
      isClearable={props.showClearButton ? props.showClearButton : false}
      disabled={props.disabled}
      placeholderText={props.placeholder ? props.placeholder : "DD-MM-YYYY"}
      className={props.className || "date-picker form-control"}
      value={parsedDate(value)}
      autoComplete="off"
      todayButton={
        <button
          type="button"
          className="btn btn-xs btn-primary"
          style={{ width: "90%", textAlign: "center", margin: "0 auto" }}
        >
          {props.todayButtonLabel ? props.todayButtonLabel : "Bugün"}
        </button>
      }
    />
  );
};

export default EkoDatePicker;
