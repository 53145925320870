import React from 'react'
import {browserHistory} from "react-router";

const Welcome = () => {

    const redirectPage = () =>{
        browserHistory.push('/dashboard');
    }

    return (
        <div >
            <div style={styles.container}>
                <div className="row h-100 bg-light">
                    <div className="col-sm-12 my-auto">

                        <div className={"w-75 mx-auto"}>
                            <div className={" w-75 mx-auto row justify-content-center"}>
                                <h4 style={styles.header} className={" h4 text-center"}>
                                    Teşekkürler
                                </h4>
                                <p style={styles.subText} className={"p text-center"}>
                                    Ödemeniz alınmıştır.
                                </p>

                                <div style={styles.messageBox}>

                                    <div style={styles.message} className="col-md-12">
                                        Üyeliğiniz başlamıştır.
                                    </div>

                                    <div style={styles.tick} className="col-sm-4 ">
                                        <span>&#10004;</span>
                                    </div>
                                </div>

                                <div style={styles.support}>
                                    <p>
                                        <b>Tüm sorularınız için ;</b>
                                    </p>
                                    <p>
                                        <b>destek@ekohesap.com</b> adresine yazarak
                                    </p>
                                    <p><b>0850 346 97 78</b>'i arayarak </p>
                                    <p>bize ulaşabilirsiniz.</p>

                                    <button onClick={()=>redirectPage()} style={styles.go} >
                                        Hesabıma Git
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default Welcome;

const MAIN_COLOR = '#1556a0'

const styles = {
    header : {
        fontSize: "24pt",
        color : MAIN_COLOR,
        fontWeight: "bold"
    },

    subText : {
        fontSize: "14pt",
        marginTop: 10,
        color: MAIN_COLOR,
    },

    messageBox :{
        backgroundColor: MAIN_COLOR,
        textAlign: "center",
        margin: "0 auto",
        width: "400px",
        borderRadius: 5,
        color: "#fff",
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 20,
        display: "flex",
        justifyContent: "flex-end"
    },

    message: {
        fontSize: "18pt",
        display : "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
    },

    tick : {
        float: "right",
        marginRight: -15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        width: 40,
        borderRadius: "100px",
        color: "#fff",
        backgroundColor: "#76bd22",
    },

    container : {
        // marginTop: "-250px",
        top : 0,
        bottom: 0,
        right:0,
        left:0,
        position: "absolute",
        display: "flex",
        justifyContent : "center",
        alignItems: "center"
    },

    support : {
        color: MAIN_COLOR,
        marginTop: 25,
        textAlign: "center",
    },

    go : {
        border: "none",
        background: MAIN_COLOR,
        color: "#fff",
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius : 15,
        marginTop : 40
    }
}