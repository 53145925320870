import React, { Component } from "react";

// eski taraycılar için polyfill
if (!String.prototype.includes || !Object.values) {
  require("babel-polyfill");
}

const App = props => {
  return <div>{props.children}</div>;
};

export default App;
