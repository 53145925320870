import Sugar from 'sugar'
import Api from './Api'

const HelperContainer  = {

  filterObject(data){
    let obj = {...data}; // problem olmamalı

    const propNames = Object.getOwnPropertyNames(obj);
    for (var i = 0; i < propNames.length; i++) {
      var propName = propNames[i];
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  },

  validateContainerData(props,validationRules,formData,formErrors,next){
    let validated = true;
    let validationFields = [];
    let responses = [];
    let nextCalled = false;
    validationRules.forEach(function(validationRule){
      // NOTE eger formData da hic dokunulmamissa validationa gerek yok
      if(!Sugar.Object.isEmpty(formData[validationRule.key])){
        //NOTE @bocek valiationFields cevap geldikce tum validationlar bitti mi diye konntrol ederken lazim
        validationFields.push(validationRule.key);

        //NOTE @bocek , validasyon işlemi icin sorgu atıyoruz
        //NOTE @bocek , cevap gelince actions 'dan eger hata yoksa true donuyor, varsa hatayi field ismi
        //ile donuyor firstname gibi o yuzden onu bizdeki  state errorse atıp AddSupplier formuna gecirince
        //formda ilgili field kırmiziya donuyor, validateData fonksiyonu actions icinde
        Api.validate(validationRule.url,formData[validationRule.key],function(response){
         //NOTE responses cevap geldikce, validationFields ile kıyaslanıp, tum validation cevapları geldi mi diye kontrol etmek icin
            responses[validationRule.key] = response;
            if(response!==true){
               formErrors[validationRule.key] = response;
               //NOTE error yediysek validated da artik false dur
               validated = false;
            }else {
              //validation ok ise ilgli field errorunu temizle
                formErrors[validationRule.key] = {}
            }

            //NOTE @bocek , her cevap geldikten sonra validationFields ve responselari kıyaslayıp, tum validationlar
            //bitti mi diye kontrol eidyoruz, qual diyince order farklı keyler esit degils aniyor, o yuzden kesişimin
            //sizelarını kiyasladim
            if(Sugar.Array.intersect(Sugar.Object.keys(responses),validationFields).length === validationFields.length){
                nextCalled = true;
                next(validated,formErrors,validationFields);
            }

        });
      }
    });

    //NOTE bir sekidle validationFields bos da olabilir, kullanıcı arayuzden validation gerektiriecek birsey yaymamis olabilir

    if(validationFields.length === 0){
        nextCalled = true;
        next(validated,formErrors,validationFields);
    }

    //TODO @bocek, işlem cok uzun surer diye buraya birsey ekledim simdlik uzerine calısmak lazım
    setTimeout(function(){
      if(!nextCalled)
        next(false,{sysError:"Geçici Sistem Hatası"},validationFields);
    },6000);
  },
  postContainerData(props,validationFields,postRules,formData,formErrors,next){

    let validated = true;
    let responses = [];
    let nextCalled = false;
    postRules.forEach(function(postRule){

      if(validationFields.indexOf(postRule.key)>-1){
        let url = postRule.url;
        let func = "post";
        if(formData[postRule.key].hasOwnProperty("id")){
          url = postRule.url+"/"+formData[postRule.key]["id"];
          func = "put";
        }

        Api[func](url,formData[postRule.key],function(response,errors){
         //NOTE responses cevap geldikce, validationFields ile kıyaslanıp, tum validation cevapları geldi mi diye kontrol etmek icin
            responses[postRule.key] = response;
            if(errors){
               formErrors[postRule.key] = response;
               //NOTE error yediysek validated da artik false dur
               validated = false;
            }else {
              //validation ok ise ilgli field errorunu temizle
                formErrors[postRule.key] = {}

            }

            //NOTE @bocek , her cevap geldikten sonra validationFields ve responselari kıyaslayıp, tum validationlar
            //bitti mi diye kontrol eidyoruz, qual diyince order farklı keyler esit degils aniyor, o yuzden kesişimin
            //sizelarını kiyasladim
            if(Sugar.Array.intersect(Sugar.Object.keys(responses),validationFields).length === validationFields.length){
                nextCalled = true;
                next(validated,formErrors);
            }

        });
      }
    });

    //NOTE bir sekidle validationFields bos da olabilir, kullanıcı arayuzden validation gerektiriecek birsey yaymamis olabilir

    if(validationFields.length === 0){
        nextCalled = true;
        next(validated,formErrors);
    }

    //TODO @bocek, işlem cok uzun surer diye buraya birsey ekledim simdlik uzerine calısmak lazım
    setTimeout(function(){
      if(!nextCalled)
        next(false,{sysError:"Geçici Sistem Hatası"});
    },6000);
  },
  checkValid(result,validationFields){
    let validated = true;
    validationFields.forEach(function(field){
      if(result!==true){
        validated = false;
      }
    })
    return validated
  },
  getErrors(result,field,currentErrors){
      if(result!==true){
         currentErrors[field] = result;
      }else {
        currentErrors[field] = {}
      }
    return currentErrors
  }
}

export default HelperContainer
