import React, { Component } from "react";
import Sugar from "sugar";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import AddItem from "../Item/AddItem";
import AddOtherTax from "../../components/forms/AddOtherTax";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import UtilHelper from "../../util/UtilHelper";
import AddProformaHeader from "./AddProformaHeader";
import { withRouter } from "react-router";
import NewTagDocumentModal from "../Company/newTagDocument";
import {Button, DropdownButton, MenuItem} from "react-bootstrap";

import SelectItemModal from "../../components/elements/SelectItemModal";


class AddProforma extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddItem: false,
      showAddTax: false,
      formData: props.formData,
      calculatedFormData: {},
      saving: false,
      taxForm: AddOtherTax.getDefaultForm(),
      selectedLine: 0,
      errors: props.errors,
      items: {},
      saved: false,
      deleteRowIds: [],
      deleteTaxIds: [],
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:7,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      tableData: {
        headers: [
          { label: "Ürün / Hizmet", width: "18%" },
          { label: "Açıklama", width: "20%" },
          { label: "Miktar", width: "5%" },
          { label: "Birim Fiyatı", width: "8%" },
          { label: "Birim", width: "7%" },
          { label: "İsk. %", width: "5%" },
          { label: "İsk. Tutar", width: "7%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "7%" },
          {
            label: "Toplam Tutar",
            width: "10%",
          },
          { label: "Diğer Vergiler", width: "8%" },
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true
          },
          {
            id: "taxes_sbjvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true
          },
          {
            id: "taxes_excvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            visible: true
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: false
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: false
          },
          {
            id: "receivable_amount_fc",
            label: "Ödenecek Tutar",
            className: "tplus-plus",
            visible: true
          }
        ],
        customFooter: null,
        rows: this.defaultRows()
      },
      itemModalValues: {
        showItemModal: false,
        refresh: false,
        isChecked: false,
      },
    };
  }

  defaultRows() {
    let rows = [
      [
        {
          type: "select",
          id: "item_id",
          optionValue: "codeName",
          selectedOptionValue: "item_code",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
          data: []
        },
        // { type: "text", id: "description", vertical: true },
        {
          type: "complete",
          id: "description",
          optionId: "description",
          data: [],
          vertical: true,
          optionValue: "description",
          searchValue: "searchValue",
          selectValue: "id",
          onSelect: this.onSelectDescription.bind(this, 0),
        },
        {
          type: "money",
          id: "quantity",
          vertical: true,
          value: 1,
          precision: 6
        },
        {
          type: "money",
          id: "unit_price_fc",
          vertical: true,
          value: 0,
          precision: 4,
          currency: true
        },
        {
          type: "select",
          id: "unit_id",
          defaultText: "",
          optionValue: "description",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: []
        },
        { type: "percent", id: "discount_rate", vertical: true, value: 0 },
        { type: "money", id: "discount_amount_fc", vertical: true },
        { type: "money", id: "net_amount_fc", vertical: true, disabled: true },
        {
          type: "select",
          id: "vat_rate_type_id",
          defaultText: "",
          optionValue: "name",
          optionId: "id",
          data: []
        },
        { type: "money", id: "vat_amount_fc", vertical: true, disabled: true },
        {
          type: "money",
          id: "with_tax_amount_fc",
          vertical: true,
        },
        {
          type: "custom",
          id: "button",
          tdClass: "ticon2",
          props: { itemCount: 0, warning: false },
          onRender: this.invoiceLineButtonGroup.bind(this, 0),
        },
      ]
    ];

    return rows;
  }
  invoiceLineButtonGroup(index, props) {
    const title = props.itemCount + " Adet";
    const menuItems = [];
    let ret = "";


    if (props.itemCount > 0) {
      menuItems.push({
        id: "add",
        name: (
            <span>
            <i className="fa fa-edit" />
            Düzenle
          </span>
        ),
      });
      menuItems.push({
        id: "delete",
        name: (
            <span>
            <i className="fa fa-trash" />
            Temizle
          </span>
        ),
      });
      const menuItemView = menuItems.map((menuItem) => (
          <MenuItem
              eventKey={menuItem.id}
              key={menuItem.id}
              onSelect={this.onInvoiceLineButtonClick.bind(
                  this,
                  menuItem.id,
                  index
              )}
          >
            {menuItem.name}
          </MenuItem>
      ));
      ret = (
          <DropdownButton
              id="drop_down"
              style={{ width: "100%" }}
              title={title}
              noCaret
              className="btn dropdown-toggle btn-sm"
          >
            {menuItemView}
          </DropdownButton>
      );
    } else {
      ret = (
          <Button
              className="btn"
              style={{ width: "100%" }}
              onClick={this.onInvoiceLineButtonClick.bind(this, "add", index)}
          >
            <i className="fa fa-plus" />
          </Button>
      );
    }

    return ret;
    /*if(warning === '') return ret
    else
    return
    <OverlayTrigger placement="top" overlay={warning}>
        {ret}
    </OverlayTrigger>*/
  }
  onInvoiceLineButtonClick(id, index) {
    if (id === "add") {
      return this.onOtherTaxClick(index);
    } else if (id === "delete") {
      this.setState({ selectedLine: index }, function () {
        this.onTaxFormSave(false);
      });
    }
  }

  onSelectDescription(index, id, value) {
    const defaultRowData = {
      item_id: 0,
      item_code: "",
      istisna_kodu: 0,
      description: "te",
      quantity: "1",
      unit_price_fc: 0,
      discount_rate: 0,
      unit_id: 0,
      discount_amount_fc: "0,00",
      net_amount_fc: "0,00",
      vat_rate_type_id: 0,
      vat_amount_fc: "0,00",
      tags: [],
      with_tax_amount_fc: "0,00",
    };
    const self = this;
    const formData = { ...self.state.formData };
    let rows = [...formData.rows];
    rows[index] = defaultRowData;
    this.setState({ formData: { ...formData, rows } }, () => {
      self.onInvoiceLineChange(
        index,
        { ...defaultRowData, item_id: value },
        "item_id"
      );
    });
    console.log("onSelectDescription", index, id, value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    //NOTE hersey gelince render etsin
    return (
      !Util.isUndefined(nextProps.vatRateTypes) &&
      !Util.isUndefined(nextProps.vatReductionTypeCodes) &&
      !Util.isUndefined(nextProps.units) &&
      !Util.isUndefined(nextProps.taxTypes)
    );
  }
  onOtherTaxClick(index) {
    const self = this;
    let taxForm = AddOtherTax.getDefaultForm();
    let otherTaxes = this.state.formData.rows[index].otherTaxes;
    if (otherTaxes) taxForm = { rows: [...otherTaxes] };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    taxForm.vat_amount_fc = calculatedFormData.rows[index].vat_amount_fc;
    taxForm.net_amount_fc = calculatedFormData.rows[index].net_amount_fc;
    self.setState({ selectedLine: index, taxForm: taxForm }, function () {
      self.setState({ showAddTax: true });
    });
  }
  fetchItems(setFirstItem) {
    const self = this;
    Api.get("/item/showAll", function(response, err) {
      let items = response.items.map(function(item) {
        item.codeName = item.item_code + ":" + item.item_name;
        return item;
      });
      items = items.filter(function(item) {
        return item.is_sales === 1;
      });
      let stateItems = {};
      items.forEach(function(item, index) {
        stateItems[item.id] = item;
      });

      let tableData = self.state.tableData;
      tableData.rows = tableData.rows.map(function(row, index) {
        row[0]["data"] = items;
        return row;
      });

      const index = self.state.selectedLine || 0;
      tableData.rows[index][0]["data"] = items;
      tableData.rows[index][1]["data"] = items.map((t) => ({
        ...t,
        description:
          t[
            self.props.documentType === 1
              ? "sales_description"
              : "purchase_description"
          ],
      }));
      self.setState({ tableData: tableData, items: stateItems }, function() {
        if (setFirstItem) {
          let formData = self.state.formData;
          formData.rows[index].item_id = items[0].id;
          formData.rows[index].item_code = items[0].item_code;
          self.setState({ formData: formData }, function() {
            self.onInvoiceLineChange(index, formData.rows[index], "item_id");
          });
        }
      });
    });
  }

  onAddNewItemClick(index) {
    this.setState({ selectedLine: index, showAddItem: true });
  }

  onItemSaved() {
    this.setState({ showAddItem: false });
    this.fetchItems(true);
  }

  componentWillMount() {
    this.fetchItems(false);
    this.getNewTagModalList();
    try {
      this.prepareFormToPost(this.state.formData);
    } catch (ex) {
      console.log(ex);
    }
  }

  componentWillReceiveProps(nextProps) {
    const self = this;
    if (nextProps.formData) {
      this.setState({ formData: nextProps.formData }, function() {
        self.prepareFormToPost(this.state.formData);
        self.renewTableData();
      });
    }
    if (
      nextProps.vatRateTypes &&
      nextProps.vatReductionTypeCodes &&
      nextProps.units
    ) {
      let tableData = this.state.tableData;
      tableData.rows[0][8]["data"] = nextProps.vatRateTypes;
      tableData.rows[0][4]["data"] = nextProps.units;
      this.setState({ tableData: tableData });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;
    if (
      formData &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        let row = this.defaultRows()[0];
        row[0].data = tableData.rows[0][0].data;
        row[8]["data"] = nextProps.vatRateTypes;
        row[4]["data"] = nextProps.units;
        tableData.rows.push(row); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = nextProps.errors;
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
      const self = this;
      this.setState({ tableData: tableData, errors: errors }, function() {
        self.renewTableData();
      });
    } else if (
      formData &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({
        errors: this.props.defaultErrors(),
        tableData: tableData
      });
    }
  }

  prepareFormToPost = (formData, shouldUseNetAmount = true) => {
    let calculatedFormData = {};
    const self = this;
    if (formData.invoice) {
      calculatedFormData.invoice = { ...formData.invoice };
      calculatedFormData.invoice.customer_id = Util.parseInt(
        calculatedFormData.invoice.customer_id
      );
      calculatedFormData.invoice.currency_rate = Util.convertMoneyToDecimal(
        calculatedFormData.invoice.currency_rate
      );
      calculatedFormData.invoice.proforma_date = calculatedFormData.invoice
        .proforma_date
        ? moment(calculatedFormData.invoice.proforma_date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.due_date = calculatedFormData.invoice.due_date
        ? moment(calculatedFormData.invoice.due_date).format("YYYY-MM-DD")
        : null;
    }

    let footer = {
      price_amount: 0,
      price_amount_fc: 0,
      discount_amount: 0,
      discount_amount_fc: 0,
      net_amount: 0,
      net_amount_fc: 0,
      taxes_sbjvat_amount: 0,
      taxes_sbjvat_amount_fc: 0,
      vat_amount: 0,
      vat_amount_fc: 0,
      taxes_excvat_amount: 0,
      taxes_excvat_amount_fc: 0,
      total_amount: 0,
      total_amount_fc: 0,
      vat_reduction_amount: 0,
      vat_reduction_amount_fc: 0,
      stoppage_amount: 0,
      stoppage_amount_fc: 0,
      receivable_amount: 0,
      receivable_amount_fc: 0
    };

    if (formData.rows) {
      const currencyRate = calculatedFormData.invoice.currency_rate || 1;

      calculatedFormData.rows = formData.rows.map(frow => {
        let row = { ...frow };
        if (row.item_id === 0) row.item_id = null;

        row.quantity = Util.convertMoneyToDecimal(row.quantity);

        row.discount_amount_fc = Util.convertMoneyToDecimal(
          row.discount_amount_fc
        );
        row.discount_amount = row.discount_amount_fc * currencyRate;

        row.unit_price_fc = UtilHelper.money.convertMoneyToDecimal(
          row.unit_price_fc
        );
        row.unit_price = row.unit_price_fc * currencyRate;

        row.net_amount_fc = Util.convertMoneyToDecimal(row.net_amount_fc);
        row.net_amount = row.net_amount_fc * currencyRate;

        row.discount_rate = Util.convertPercentToNumber(row.discount_rate);

        row.vat_amount_fc = Util.convertMoneyToDecimal(row.vat_amount_fc);
        row.vat_amount = row.vat_amount_fc * currencyRate;

        if (row.vat_rate_type_id && !row.vat_rate) {
          const vatRate = Sugar.Array.find(self.props.vatRateTypes, function(
            type
          ) {
            return type.id === row.vat_rate_type_id;
          });
          if (vatRate) row.vat_rate = vatRate.rate;
        } else row.vat_rate = row.vat_rate || 0;
        // bu kod vergi oranı değişitiğinde sorun çıkartıyor

        if (shouldUseNetAmount === false) {
          row.with_tax_amount_fc = Util.convertMoneyToDecimal(
              row.with_tax_amount_fc
          );

          row.with_tax_amount = row.with_tax_amount_fc * currencyRate;

          row.net_amount_fc = row.with_tax_amount_fc / (1 + row.vat_rate);

          row.net_amount = row.net_amount_fc * currencyRate;
        } else {
          //  eğer vergi tipi değiştiyse hesaplamalar net_amount üzerinden yapılmalı
          row.with_tax_amount_fc =
              Util.convertMoneyToDecimal(row.net_amount_fc) + row.vat_amount_fc;
          row.with_tax_amount = row.net_amount * currencyRate + row.vat_amount;
        }

        //new end

        row.taxes_sbjvat_amount_fc = 0;
        row.taxes_excvat_amount_fc = 0;
        row.stoppage_amount_fc = 0;
        row.vat_reduction_amount_fc = 0;

        if (row.otherTaxes) {
          let otherTaxes = [];
          row.otherTaxes.forEach(function (rowOtherTax) {
            let otherTax = { ...rowOtherTax };
            otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
                otherTax.tax_amount_fc
            );
            otherTax.tax_rate = Util.convertPercentToNumber(otherTax.tax_rate);

            if (otherTax.tax_type_code === "9015")
              otherTax.tax_amount_fc = otherTax.tax_rate * row.vat_amount_fc;
            else otherTax.tax_amount_fc = otherTax.tax_rate * row.net_amount_fc;

            otherTax.tax_amount = otherTax.tax_amount_fc * currencyRate;

            if (otherTax.tax_type_id && !otherTax.subject_to_vat) {
              otherTax.subject_to_vat = self.getTaxSubjectToVat(
                  otherTax.tax_type_id
              );
              otherTax.reduction_type_id = self.getTaxReductionType(
                  otherTax.tax_type_id
              );
            }
            if (otherTax.subject_to_vat === 1) {
              row.taxes_sbjvat_amount_fc += otherTax.tax_amount_fc;
            } else if (
                !otherTax.vat_reduction_type_code &&
                otherTax.reduction_type_id === 0
            ) {
              row.taxes_excvat_amount_fc += otherTax.tax_amount_fc;
            }

            if (otherTax.reduction_type_id === 1)
              row.stoppage_amount_fc += otherTax.tax_amount_fc;

            if (otherTax.tax_type_code === "9015") {
              row.vat_reduction_amount_fc = Util.convertMoneyToDecimal(
                  Util.convertDecimalToMoney(
                      otherTax.tax_rate * row.vat_amount_fc
                  )
              );
              // otherTax.tax_rate * row.vat_amount_fc;
            }
            if (otherTax.tax_type_code === "OZELMATRAH") {
              otherTax.tax_amount = Util.convertMoneyToDecimal(
                  rowOtherTax.tax_amount_fc
              );
              otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
                  rowOtherTax.tax_amount_fc
              );
              var taxVatRate =
                  Util.convertMoneyToDecimal(
                      parseInt(rowOtherTax.tax_rate.replace("%", ""))
                  ) / 100;
              footer.vat_amount +=
                  Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                  taxVatRate;
              footer.vat_amount_fc +=
                  Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                  taxVatRate;
            }
            otherTaxes.push(otherTax);
          });
          delete row.otherTaxes;
          row.otherTaxes = otherTaxes;
        } else row.otherTaxes = [];
        if (row.otherTaxes.length != 0) {
          if (row.otherTaxes[0].tax_type_code == "OZELMATRAH") {
            row.total_amount_fc = row.net_amount_fc ;
            row.total_amount = row.net_amount ;
          } else {
            row.total_amount_fc =
                row.net_amount_fc +
                row.vat_amount_fc +
                row.taxes_sbjvat_amount_fc +
                row.taxes_excvat_amount_fc;

            row.total_amount = row.total_amount_fc * currencyRate;
          }
        } else {
          row.total_amount_fc =
              row.net_amount_fc +
              row.vat_amount_fc +
              row.taxes_sbjvat_amount_fc +
              row.taxes_excvat_amount_fc;

          row.total_amount = row.total_amount_fc * currencyRate;
        }
        row.taxes_sbjvat_amount = row.taxes_sbjvat_amount_fc * currencyRate;
        row.taxes_excvat_amount = row.taxes_excvat_amount_fc * currencyRate;
        row.total_amount_fc = row.net_amount_fc + row.vat_amount_fc;
        row.total_amount = row.total_amount_fc * currencyRate;

        row.stoppage_amount = row.stoppage_amount_fc * currencyRate;
        row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;

        footer.price_amount += row.net_amount + row.discount_amount;
        footer.price_amount_fc += row.net_amount_fc + row.discount_amount_fc;
        footer.net_amount += row.net_amount;
        footer.net_amount_fc += row.net_amount_fc;
        footer.discount_amount += row.discount_amount;
        footer.discount_amount_fc += row.discount_amount_fc;
        if (row.otherTaxes.length != 0) {
          if (row.otherTaxes[0].tax_type_code != "OZELMATRAH") {
            //footer.vat_amount += row.vat_amount;
            //footer.vat_amount_fc += row.vat_amount_fc;
          } else {
            footer.vat_amount += 0;
            footer.vat_amount_fc += 0;
          }
        } else {
          //footer.vat_amount += row.vat_amount;
          //footer.vat_amount_fc += row.vat_amount_fc;
        }
        footer.taxes_sbjvat_amount += row.taxes_sbjvat_amount;
        footer.taxes_sbjvat_amount_fc += row.taxes_sbjvat_amount_fc;
        footer.taxes_excvat_amount += row.taxes_excvat_amount;
        footer.taxes_excvat_amount_fc += row.taxes_excvat_amount_fc;
        footer.vat_amount += row.vat_amount;
        footer.vat_amount_fc += row.vat_amount_fc;
        footer.total_amount += row.total_amount;
        footer.total_amount_fc += row.total_amount_fc;
        footer.vat_reduction_amount += row.vat_reduction_amount;
        footer.vat_reduction_amount_fc += row.vat_reduction_amount_fc;
        footer.stoppage_amount += row.stoppage_amount;
        footer.stoppage_amount_fc += row.stoppage_amount_fc;

        console.log(row);

        return row;
      });
    }

    footer.receivable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.receivable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;
    footer.discount_rate =
      Math.round((footer.discount_amount_fc / footer.price_amount_fc) * 10000) /
      10000;
    footer.received_amount_fc = 0;
    footer.received_amount = 0;
    calculatedFormData.footer = footer;

    let tableData = this.state.tableData;

    Object.keys(footer).map(key => {
      if (footer[key] > 0) {
        tableData.footerTable.map(tableEl => {
          if (tableEl.id === key) {
            tableEl.visible = true;
          }

          return tableEl;
        });
      }

      return (tableData.footerData[key] = Util.convertDecimalToMoney(
        footer[key]
      ));
    });
    this.setState({ tableData: tableData });

    return calculatedFormData;
  };

  onChange(type, field, value) {
    const self = this;
    let formData = this.state.formData;

    formData[type][field] = value;
    self.setState({ formData: formData }, function() {
      self.resetErrorForInvoice(field);
    });
  }

  onRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  handleSuccess(redirect = false) {
    const self = this;
    toastr.success("Başarılı şekilde kaydedildi");
    self.setState({ saving: false, saved: true });
    if (!self.props.router.params.id) {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        let tableData = this.state.tableData;
        tableData = Object.assign(tableData, {
          footerData: {
            discount_amount: "0,00",
            discount_amount_fc: "0,00",
            discount_rate: "0,00",
            net_amount: "0,00",
            net_amount_fc: "0,00",
            price_amount: "0,00",
            price_amount_fc: "0,00",
            receivable_amount: "0,00",
            receivable_amount_fc: "0,00",
            received_amount: "0,00",
            received_amount_fc: "0,00",
            stoppage_amount: "0,00",
            stoppage_amount_fc: "0,00",
            taxes_excvat_amount: "0,00",
            taxes_excvat_amount_fc: "0,00",
            taxes_sbjvat_amount: "0,00",
            taxes_sbjvat_amount_fc: "0,00",
            total_amount: "0,00",
            total_amount_fc: "0,00",
            vat_amount: "0,00",
            vat_amount_fc: "0,00",
            vat_reduction_amount: "0,00",
            vat_reduction_amount_fc: "0,00"
          }
        });

        this.setState(
          {
            tableData: tableData,
            formData: self.props.defaultFormData()
          },
          function() {
            self.fetchItems(false);
            self.renewTableData();
          }
        );
      }
    } else {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        this.props.router.push("/dashboard/salesInvoice/proforma/add");
      }

      if (self.props.onSave) {
        self.props.onSave();
      }
    }
  }

  onSave(refresh, isChecked) {
    const redirect = arguments[0];
    const self = this;


    let formData = self.state.formData;

    const baseCurrency = formData.invoice.currency;
    for (var fi in formData.rows) {
      const items = this.state.tableData.rows[fi][0].data;
      for (var i in items) {
        if (items[i].id === formData.rows[fi].item_id) {
          // tedarikçiye iade
          // if (this.props.documentType === 2) {
          //   if (items[i].currency_purchase !== baseCurrency) {
          //     toastr.error(
          //         "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
          //     );
          //     return;
          //   }
          // } else {
          //   if (items[i].currency_sales !== baseCurrency) {
          //     toastr.error(
          //         "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
          //     );
          //     return;
          //   }
          // }
        }
      }
    }



    self.setState({ saving: true });

    self.validateInvoiceLines(true, function(linesOk) {
      if (linesOk) {
        if (
          formData.rows.filter((r) => !r.item_id && !r.unSelected).length > 0 &&
          !self.props?.editId
        ) {
          self.setState({
            itemModalValues: { showItemModal: true, refresh, isChecked },
          });
          return;
        }
        self.saveInvoice(function(invoiceOk) {
          if (invoiceOk) {
            let formData = self.state.formData;
            formData.rows = formData.rows.map(function(row) {
              row.proforma_id = formData.invoice.id;
              return row;
            });
            self.saveInvoiceLines(function(invoiceLinesSaved) {
              let calculatedFormData = self.prepareFormToPost(
                  self.state.formData
              );
              calculatedFormData.rows.forEach(function (row) {
                row.otherTaxes = row.otherTaxes.map(function (tax) {
                  tax.proforma_invoice_line_id = row.id;
                  tax.proforma_invoice_id = calculatedFormData.invoice.id;
                  return tax;
                });

                self.saveTaxLines(row.otherTaxes, function (taxLinesOk) {
                  if (taxLinesOk) {
                    if(isChecked === true){
                      Api.get(`/proforma/next/${self.props?.editId}`,function (res, error) {
                        if(res.item) {
                          const path = window.location.pathname;
                          const ex = path.split('/');
                          ex.pop();
                          ex.push(res.item.id);
                          self.setState({
                            saving: false
                          });
                          window.location.href = ex.join('/');
                        } else {
                          self.handleSuccess(true);
                        }
                      })
                    } else {
                      if (invoiceLinesSaved) {
                        self.handleSuccess(redirect);
                      } else {
                        self.setState({
                          saving: false
                        });
                      }
                    }
                  }
                });
              });
            });
          } else {
            self.setState({ saving: false });
            console.log("invoice not validated");
          }
        }, isChecked);
      } else {
        self.setState({ saving: false });
        console.log("lines not validated");
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  renewTableData() {
    const self = this;
    let tableData = this.state.tableData;
    const formData = this.state.formData;
    tableData.rows.forEach(function(row, index) {
      //tableData.rows[index][10].onClick = self.onOtherTaxClick.bind(self,index)
      const taxCount = formData.rows[index].otherTaxes
          ? formData.rows[index].otherTaxes.length
          : 0;
      tableData.rows[index][11].props = { itemCount: taxCount, warning: "" };
      tableData.rows[index][11].onRender = self.invoiceLineButtonGroup.bind(
          self,
          index
      );
      tableData.rows[index][0].onAddNewItemClick = self.onAddNewItemClick.bind(
        self,
        index
      );

      tableData.rows[index][8]["data"] = self.props.vatRateTypes;
      tableData.rows[index][4]["data"] = self.props.units;

      if (formData.rows[index]["item_id"]) {
        tableData.rows[index][4].disabled = true;
      } else tableData.rows[index][4].disabled = false;
    });
    self.setState({ tableData: tableData });
  }
  onTaxRowChange() {
    ///
  }

  onTaxChange() {
    //
  }
  onTaxFormSave(taxForm) {
    let formData = this.state.formData;
    let tableData = this.state.tableData;
    let deleteTaxIds = this.state.deleteTaxIds;
    const index = this.state.selectedLine;
    let row = formData.rows[index];
    if (!taxForm) {
      tableData.rows[index][11]["props"]["itemCount"] = 0;
      row.otherTaxes.map((item) => deleteTaxIds.push(item.id));
      row.otherTaxes = false;
    } else {
      row.otherTaxes = [...taxForm.rows];
      tableData.rows[index][11]["props"]["itemCount"] = row.otherTaxes.length;
      if (taxForm.deleteRowIds) {
        taxForm.deleteRowIds.forEach(function (id) {
          deleteTaxIds.push(id);
        });
      }
    }

    formData.rows[index] = row;
    const calculatedFormData = this.prepareFormToPost(formData);
    calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
    );
    formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
    );
    this.prepareFormToPost(formData);
    this.setState({
      showAddTax: false,
      formData: formData,
      tableData: tableData,
      deleteTaxIds: deleteTaxIds,
    });
  }
  getWithTaxAmount(rowData) {
    return this.getNetAmount(rowData) + this.getVatAmount(rowData);
  }

  calculateNetAmountByWithTax(rowData) {
    return rowData.with_tax_amount_fc / (1 + rowData.vat_rate);
  }

  calculateUnitPrice(rowData) {
    return (
        (this.calculateNetAmountByWithTax(rowData) + rowData.discount_amount_fc) /
        rowData.quantity
    );
  }
  onInvoiceLineRemoved(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = Sugar.Array.removeAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.invoice.id && row.id) {
      deleteRowIds.push(row.id);
    }

    this.prepareFormToPost(this.state.formData);
    this.renewTableData();
  }

  onInvoiceLineAdded() {
    this.renewTableData();
    this.validateInvoiceLines(false, null);
  }

  getDiscountAmount(rowData) {
    return rowData.unit_price_fc * rowData.quantity * rowData.discount_rate;
  }

  getNetAmount(rowData) {
    return (
      rowData.unit_price_fc * rowData.quantity - rowData.discount_amount_fc
    );
  }

  getDiscountRate(rowData) {
    return (
      rowData.discount_amount_fc / (rowData.unit_price_fc * rowData.quantity)
    );
  }

  getVatAmount(rowData) {
    console.log(rowData);

    return (
        (rowData.taxes_sbjvat_amount_fc + rowData.net_amount_fc) *
        rowData.vat_rate
    );
  }

  resetErrorForInvoiceLine(index, field) {
    let errors = this.state.errors;
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;

    this.setState({ errors: errors });
  }

  resetErrorForInvoice(field) {
    let errors = this.state.errors;
    errors.invoice[field] = false;

    this.setState({ errors: errors });
  }

  onInvoiceLineReallyChange(index, field, value, oldValue) {
    this.resetErrorForInvoiceLine(index, field);
    if (field === "item_id") {
      this.resetErrorForInvoiceLine(index, "vat_rate_type_id");
      this.resetErrorForInvoiceLine(index, "unit_price_fc");
      this.resetErrorForInvoiceLine(index, "unit_id");
      this.resetErrorForInvoiceLine(index, "description");
    }

    if (
      field === "item_id" ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      this.resetErrorForInvoiceLine(index, "discount_amount_fc");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "discount_amount_fc") {
      this.resetErrorForInvoiceLine(index, "discount_rate");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "vat_rate_type_id") {
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }
  }

  onInvoiceLineChange(index, rowData, field) {
    const self = this;
    let formData = this.state.formData;
    //  self.resetErrorForInvoiceLine(index,field)
    if (field === "item_id") {
      let items = this.state.items;
      let itemId = rowData.item_id;
      formData.rows[index].description = items[itemId].item_name;
      formData.rows[index].item_code = items[itemId].item_code;
      formData.rows[index].description +=
        " - " + (items[itemId].sales_description || "");
      formData.rows[
        index
      ].unit_price_fc = UtilHelper.money.convertDecimalToMoney4(
        items[itemId].sales_unit_price
      );
      formData.rows[index].vat_rate_type_id =
        items[itemId].sales_vat_rate_type_id;
      formData.rows[index].unit_id = items[itemId].unit_id;
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      let tableData = this.state.tableData;
      tableData.rows[index][4].disabled = true;
      this.setState({ tableData: tableData });
      self.onInvoiceLineChange(index, formData.rows[index], "vat_rate_type_id");
    }

    if (
      field === "item_id" ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[
        index
      ].discount_amount_fc = this.getDiscountAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].with_tax_amount_fc = this.getWithTaxAmount(
          calculatedFormData.rows[index]
      );
      formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].discount_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].with_tax_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "discount_amount_fc") {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);

      calculatedFormData.rows[index].discount_rate = this.getDiscountRate(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].with_tax_amount_fc = this.getWithTaxAmount(
          calculatedFormData.rows[index]
      );
      formData.rows[index].discount_rate = Util.convertNumberToPercent(
        calculatedFormData.rows[index].discount_rate
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].with_tax_amount_fc
      );
    } else if (field === "vat_rate_type_id") {
      if (this.state.countVat > 0) {
        formData.rows[index][field] = rowData[field];
        const calculatedFormData = this.prepareFormToPost(formData);
        calculatedFormData.rows[index].discount_amount_fc =
            this.getDiscountAmount(calculatedFormData.rows[index]);
        calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
            calculatedFormData.rows[index]
        );
        calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
            calculatedFormData.rows[index]
        );
        calculatedFormData.rows[index].with_tax_amount_fc =
            this.getWithTaxAmount(calculatedFormData.rows[index]);

        formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
            calculatedFormData.rows[index].discount_amount_fc
        );
        formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
            calculatedFormData.rows[index].net_amount_fc
        );
        formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
            calculatedFormData.rows[index].with_tax_amount_fc
        );

        formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
            calculatedFormData.rows[index].vat_amount_fc
        );
      } else {
        formData.rows[index][field] = rowData[field];
        const vatRate = Sugar.Array.find(
            this.props.vatRateTypes,
            function (type) {
              return type.id === rowData[field];
            }
        );
        if (vatRate) {
          formData.rows[index].vat_rate = vatRate.rate;

          // hesaplar net_amount_fc üzerinden yapılnalı
          const calculatedFormDataq = this.prepareFormToPost(formData);

          calculatedFormDataq.rows[index].vat_amount_fc = this.getVatAmount(
              calculatedFormDataq.rows[index]
          );
          formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
              calculatedFormDataq.rows[index].vat_amount_fc
          );

          const newData = this.prepareFormToPost(formData);

          formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
              newData.rows[index].with_tax_amount_fc
          );
        }
      }
    }else if (field === "with_tax_amount_fc") {
      formData.rows[index][field] = rowData[field];

      const calculatedFormData = this.prepareFormToPost(formData, false);

      calculatedFormData.rows[index].net_amount_fc =
          this.calculateNetAmountByWithTax(calculatedFormData.rows[index]);

      calculatedFormData.rows[index].unit_price_fc = this.calculateUnitPrice(
          calculatedFormData.rows[index]
      );

      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
          calculatedFormData.rows[index]
      );

      formData.rows[index].unit_price_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].unit_price_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].vat_amount_fc
      );
    } else {
      formData.rows[index][field] = rowData[field];
    }

    self.setState({ formData: formData }, function() {
      self.prepareFormToPost(self.state.formData);
    });
  }
  deleteTaxLines(next) {
    const self = this;
    if (self.state.deleteTaxIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteTaxIds.forEach(function (id) {
        endpointWithParams.push({
          endpoint: "/proformaTaxLine/" + id,
          method: "delete",
        });
      });

      Api.paralelAll(endpointWithParams, function (responses, error) {
        next(true);
      });
    } else next(true);
  }
  saveTaxLines(otherTaxes, next) {
    const self = this;

    let endpoints = [];
    otherTaxes.forEach(function (tax) {
      // E-fatura gönderiminde bu alan frontend ile aynı değer olarak kayıt edilsin.
      tax.tax_amount = Util.convertMoneyToDecimal(
          Util.convertDecimalToMoney(tax.tax_amount)
      );
      tax.tax_amount_fc = Util.convertMoneyToDecimal(
          Util.convertDecimalToMoney(tax.tax_amount_fc)
      );
      const url = tax.id
          ? "/proformaTaxLine/" + tax.id
          : "/proformaTaxLine";
      const method = tax.id ? "put" : "post";
      endpoints.push({ endpoint: url, method: method, params: tax });
    });

    if (endpoints.length === 0) {
      self.deleteTaxLines(next);
      return next(true);
    }
    Api.serialPutPost(endpoints, function (responses, errors) {
      console.log(responses);
      if (errors.length === 0) {
        console.log(responses);
        self.deleteTaxLines(next);
      } else {
        errors.forEach(function (error) {
          toastr.error(Util.getErrorAsStr(error));
        });

        /*  let formErrors = self.state.errors
        formErrors.rows = errors
        self.setState({errors:formErrors},function(){
        if(next !==null) next(false)
        })*/
      }
    });
  }
  getTaxSubjectToVat(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.subject_to_vat;
    }
    return 0;
  }

  getTaxReductionType(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.reduction_type;
    }
    return 0;
  }
  getReductionRate(reductionCode) {
    if (this.props.vatReductionTypeCodes) {
      const reduction = Sugar.Array.find(
          this.props.vatReductionTypeCodes,
          function (type) {
            return type.code === reductionCode;
          }
      );
      return Util.convertNumberToPercent(reduction.rate);
    }
    return Util.convertNumberToPercent(0);
  }
  validateInvoiceLines(all, next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    const url = "/proforma/validation";
    let endpoints = [];
    calculatedFormData.rows.forEach(function(row) {
      endpoints.push({ endpoint: url, method: "post", params: row });
    });

    if (!all) {
      endpoints.pop(); //son eklenen rowu kontrol etme
    }

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        if (next !== null) next(true);
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });
      }
    });
  }

  deleteInvoiceLines(next) {
    const self = this;
    if (self.state.deleteRowIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteRowIds.forEach(function(id) {
        endpointWithParams.push({
          endpoint: "/proformaLine/" + id,
          method: "delete"
        });
      });

      Api.paralelAll(endpointWithParams, function(responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveInvoiceLines(next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);

    let formData = this.state.formData;
    let endpoints = [];
    var rowcount = 0 ;
    calculatedFormData.rows.forEach(function(row) {
      rowcount++;
      row.sortable_id = rowcount;
      const method = row.id ? "put" : "post";
      const url = row.id ? "/proformaLine/" + row.id : "/proformaLine";
      endpoints.push({ endpoint: url, method: method, params: row });
    });

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        responses.forEach(function(response, index) {
          formData.rows[index].id = response.proformaLine.id;

          if (!!response.warnings) {
            response.warnings.forEach(warning => {
              toastr.warning(warning);
            });
          }
        });
        self.setState({ formData: formData }, function() {
          if (next !== null) {
            self.deleteInvoiceLines(next);
          }
        });
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });

        if (errors.message) toastr.error(Util.getErrorAsStr(errors));
      }
    });
  }

  saveInvoice(next,isChecked) {
    const self = this;
    const formData = { ...this.state.formData };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    let manualValidations = true;
    let formErrors = self.state.errors;
    if (calculatedFormData.footer.price_amount_fc === 0) {
      toastr.error("Toplam fatura tutarı sıfır olamaz");
      manualValidations = false;
    }

    if (!manualValidations) return next(false);

    const url = formData.invoice.id
      ? "/proforma/" + formData.invoice.id
      : "/proforma";
    const method = formData.invoice.id ? "put" : "post";
    const postData = Object.assign(
      {},
      calculatedFormData.invoice,
      calculatedFormData.footer
    );

    if (postData.customer_id === 0) {
      postData.customer_id = null;
    }
    if(this.props.addDocument?.uri && postData.document_url !== this.props.addDocument?.uri){
      postData.document_url = this.props.addDocument?.uri; 
    }
    postData.is_checked = isChecked === true;
    postData.newTagRows=this.state.newTagRows;
    if(postData.types==='siparis'){postData.types=1;}
    else if(postData.types==='teklif'){postData.types=0;}
    else{postData.types=0;}
    if (formData.invoice.id) postData.updated_id = formData.invoice.id;
    Api[method](url, postData, function(response, errors) {
      if (errors) {
        if (errors.message) toastr.error(Util.getErrorAsStr(errors));

        formErrors.invoice = Object.assign({}, formErrors.invoice, errors);
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
      } else if (!manualValidations) {
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
        next(false);
      } else {
        if (!response.proforma) {
          toastr.error("Muhtemel SQL Hatası");
          return next(false);
        }
        let formData = self.state.formData;
        formData.invoice.id = response.proforma.id;
        self.setState({ formData: formData }, function() {
          next(true);
        });
      }
    });
  }

  saveNormal(refresh, isChecked) {
    this.onSave(refresh, isChecked);
  }

  itemModalClose() {
    this.setState({
      saving: false,
      itemModalValues: { ...this.state.itemModalValues, showItemModal: false },
    });
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/7/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  render() {
    let fromWhos = this.props.fromWhos.map(function(fromWho) {
      // fromWho.name = fromWho.firstname;
      // if (fromWho.lastname) fromWho.name += " " + fromWho.lastname;
      fromWho.name = fromWho.fullname;
      return fromWho;
    });

    let headerTitle = "Teklif/Sipariş";

    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/salesInvoice/proforma")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <AddProformaHeader
              fromWhos={fromWhos}
              fromWhoId={
                  this.state.formData.invoice.customer_id ||
                  this.state.formData.invoice.supplier_id
              }
              currencies={this.props.currencies}
              formData={this.state.formData.invoice}
              errors={this.state.errors.invoice}
              onChange={this.onChange.bind(this)}
              onRefresh={this.onRefresh.bind(this)}
              saved={this.state.saved}
              title={headerTitle}
          >
            <EkoInputTableButtonGroup
                data={this.state.tableData}
                orderNumber={true}
                currency={this.state.formData.invoice.currency}
                formData={this.state.formData}
                errors={this.state.errors}
                onSave={(refresh, isChecked) => this.saveNormal(refresh, isChecked)}
                saving={this.state.saving}
                buttonSaveLabel={"Kaydet"}
                onRowAdd={this.onInvoiceLineAdded.bind(this)}
                defaultRow={this.props.defaultRowData}
                onCancel={this.onCancel.bind(this)}
                onChangeRow={this.onInvoiceLineChange.bind(this)}
                onRealChange={this.onInvoiceLineReallyChange.bind(this)}
                onChange={() => null}
                onRowDelete={this.onInvoiceLineRemoved.bind(this)}
                formId={"add_proforma"}
                buttons={[]}
                isCheckButton={true}
                editId={this.props?.editId}
                updatedAt={this.state?.formData?.invoice?.is_checked ? this.state?.formData?.invoice?.updated_at : null}
            />
            <hr/>
            <a onClick={this.openNewTagModal} style={{top: 0, position: "relative"}}>Etiket Ekle</a>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
            <AddItem
                showModal={this.state.showAddItem}
                formData={false}
                onSave={this.onItemSaved.bind(this)}
                onCancel={() => {
                  this.setState({showAddItem: false});
                }}
                errors={false}
            />

            <AddOtherTax
                taxTypes={this.props.taxTypes}
                vatReductionTypeCodes={this.props.vatReductionTypeCodes}
                onChangeRow={this.onTaxRowChange.bind(this)}
                onChange={this.onTaxChange.bind(this)}
                showModal={this.state.showAddTax}
                formData={this.state.taxForm}
                errors={{}}
                onSave={this.onTaxFormSave.bind(this)}
                onCancel={() => {
                  this.setState({showAddTax: false});
                }}
            />
            <SelectItemModal
                onSave={() => {
                  this.onSave(
                      this.state.itemModalValues.refresh,
                      this.state.itemModalValues.isChecked
                  );
                  this.fetchItems();
                }}
                itemModalValues={this.state.itemModalValues}
                showModal={this.state.itemModalValues.showItemModal}
                onClose={this.itemModalClose.bind(this)}
                items={this.state.formData.rows}
                onChangeLines={(rows, callback) =>
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        rows: [...rows]
                      }
                    }, () => callback ? callback() : null)
                }
                isSales={this.props.documentType === 1 ? true : false}

            />
          </AddProformaHeader>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vatRateTypes: state.data.vatRateTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    units: state.data.units,
    taxTypes: state.data.taxTypes,
    currencies: state.data.currencies,
    addDocument: state.page.addDocument
  };
}

export default connect(mapStateToProps, actions)(withRouter(AddProforma));
