import React, { Component } from "react";
import { connect } from "react-redux";
import { PERMISSIONS } from "../../services/PermittedPages";

export default function (ComposedComponent, pageId) {
  class Permitted extends Component {
    constructor(props) {
      super(props);
      this.state = {
        permitted: false
      };
    }

    componentWillMount() {
      const selectedPage = PERMISSIONS.filter(function (permission) {
        return Array.isArray(pageId) === false
          ? permission[0] === pageId
          : pageId.indexOf(permission[0]) > -1;
      })[0];

      let planIds,
        companyTypes,
        roleIds = [];
      if (selectedPage !== null || selectedPage !== undefined) {
        planIds = selectedPage[2].split(",");
        companyTypes = selectedPage[3].split(",");
        roleIds = selectedPage[4].split(",");
        console.log("plan ids", planIds);
        console.log("companytypes", companyTypes);
        console.log("roleids", roleIds);
      }

      const user = JSON.parse(localStorage.getItem("user"));
      console.log("user  ---> ", user);
      let permitted1,
        permitted2,
        permitted3 = false;
      if (user) {
        if (user.plan_id && planIds.indexOf(user.plan_id + "") > -1) {
          permitted1 = true;
        }
        if (user.company_type && companyTypes.indexOf(user.company_type) > -1)
          permitted2 = true;

        if (user.role_id && roleIds.indexOf(user.role_id + "") > -1)
          permitted3 = true;
      }

      this.setState({ permitted: permitted1 && permitted2 && permitted3 });
    }

    render() {
      if (!this.state.permitted) {
        return <div>Bu sayfayı görmeye yetkiniz yok</div>;
      } else return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps)(Permitted);
}
