/*
    COREBYTE TEKNOLOJİ YAZILIM DANIŞMANLIK TİC. LTD. ŞTİ.
    www.corebyte.tech - 0850 360 6789 - info@corebyte.tech
    Obifin.js - 3.05.2023
*/
import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormCheckbox,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormDate
} from "../../components/elements/EkoForm";
import { Modal, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import ReactDOM from 'react-dom';
import EkoSpinner from "../elements/EkoSpinner";
import { Link } from "react-router";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";

class Obifin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            errors: {},
            saving: false,
            page_loading: false,
            no_obifin_data: true,
            obifin_username: "",
            obifin_password: "",
            obifin_api_key: "",
            obifin_api_address: "",
            obifin_application_data: null,
            formData: this.defaultFormData(),
            formDataApplication: {},
        };
    }
    defaultFormData() {
        return {
            "company_name"  : "",
            "tax_office"    : "",
            "tax_number"    : "",
            "email"         : "",
            "address"       : "",
            "phone"         : "",
            "name_surname"  : "",
            "identity_number" : "",
        }
    }
    componentWillMount() {
        let self = this;
        self.checkObifinIntegration();
    }

    componentWillReceiveProps(nextProps) {

    }
    checkObifinIntegration() {
        let _self = this;
        _self.setState({ page_loading: true });
        Api.get("/getObifinIntegrationData", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.hasOwnProperty("application_data") && response.application_data != null) {
                _self.setState({ obifin_application_data: response.application_data });
            }else{
                // get company default data and fill form data
                Api.get("/getUserCompanyDetails", (response, errors) => {
                    if (errors) {
                        toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                        return;
                    }
                    if (response.result != null) {
                        // create object
                        let formDataApplication = _self.state.formDataApplication;
                        formDataApplication.company_name = response.result.firstname + " " + response.result.lastname;
                        formDataApplication.tax_office = response.result.tax_office_name;
                        formDataApplication.tax_number = response.result.tax_identity_number;
                        formDataApplication.address = response.result.address;
                        let user = JSON.parse(localStorage.getItem("user"));
                        formDataApplication.email = user.email;
                        formDataApplication.name_surname = user.firstname + " " + user.lastname;
                        formDataApplication.phone = user.phone;
                        _self.setState({ formDataApplication: formDataApplication });
                    }
                });
            }
            if (response.hasOwnProperty("result") && response.result != null) {
                _self.setState({
                    no_obifin_data: false,
                    obifin_username: response.result.obifin_username,
                    obifin_password: response.result.obifin_password,
                    obifin_api_key: response.result.obifin_api_key,
                    obifin_api_address: response.result.obifin_api_address,
                });
            } else {
                _self.setState({ no_obifin_data: true });
            }
            _self.setState({ page_loading: false });
        });
    }
    onSave() {
        this.setState({saving: true});
        var params = {
            obifin_username: this.state.obifin_username,
            obifin_password: this.state.obifin_password,
            obifin_api_key: this.state.obifin_api_key,
            obifin_api_address: this.state.obifin_api_address,
        };
        Api.post("/setObifinIntegrationData", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else if (response.result == 1) {
                //toastr.success("Başarılı", "Bilgiler başarıyla güncellendi ve açık bankacılık entegrasyonunuz aktif edildi.");
                //toastr.info("API Tanımları", "Banka API Tanımları sayfasından bankalardan almış olduğunuz API'leri tanımlayınız.");
                swal({
                    title: "Webservis Bağlantınız Başarıyla Gerçekleştirildi",
                    text: "Sağ üstte bulunan <span style='font-weight: bold;'>Banka API Tanımları</span> butonuna tıklayarak çalıştığınız bankaların API'lerini tanımlayabilir veya yeni başvuru yapabilirsiniz.",
                    showConfirmButton: true,
                    confirmButtonText: "Tamam",
                    confirmButtonColor: "darkgray",
                    showCancelButton: false,
                    cancelButtonText: "İptal",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    html: true,
                    type: "success",
                    customClass: 'sweet-alert',
                }, function(isConfirm) {
                    if (isConfirm) {

                    }
                });
                this.setState({no_obifin_data: false});
            } else if (response.result == 3) {
                toastr.error("Bilgilerde Hata Mevcut", "Webservis bilgilerinizde hata mevcut. Bilgileri doğru girdiyseniz başarılı sonucu alacaksınız. Dönen hata değeri: " + response.error_text, {timeOut: 10000});
            }  else {
                toastr.error("Başarısız", "Veriler kaydedilemedi. Tüm alanları doldurduğunuzdan emin olunuz.");
            }
            this.setState({saving: false});
        });
    }
    onSaveApplication() {
        this.setState({saving: true});
        if (this.state.formDataApplication.company_name != null && this.state.formDataApplication.company_name != "" && this.state.formDataApplication.tax_office != null && this.state.formDataApplication.tax_office != "" && this.state.formDataApplication.tax_number != null && this.state.formDataApplication.tax_number != "" && this.state.formDataApplication.email != null && this.state.formDataApplication.email != "" && this.state.formDataApplication.address != null && this.state.formDataApplication.address != "" && this.state.formDataApplication.phone != null && this.state.formDataApplication.phone != "" && this.state.formDataApplication.name_surname != null && this.state.formDataApplication.name_surname != "" && this.state.formDataApplication.identity_number != null && this.state.formDataApplication.identity_number != "") {
            Api.post("/setObifinApplication", this.state.formDataApplication, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else if (response.result == 1) {
                    //toastr.success("Başarılı", "Başvurunuz alınmıştır. En kısa zamanda açık bankacılık modülü kullanımınıza açılacaktır.");
                    // swal alert, use html in text
                    swal({
                        title: "Başvurunuz alınmıştır.",
                        text: "Webservis bilgilerini ve entegrasyon adımlarını anlatan PDF size e-posta olarak gönderilecektir."+
                            "<br/><br/>E-posta gelene kadar beklemeniz gerekmektedir.<br/><br/><a href='"+(process.env.REACT_APP_API_URL).replace('/public/api', '/public').replace('api.ekohesap.com/api', 'api.ekohesap.com')+"/panel/download.php?folder=documents/open-banking-application-forms&file=Banka_Entegrasyon_Dokumani.pdf' class='btn btn-primary blue'>Banka Entegrasyon Klavuzunu İndir</a>",
                        showConfirmButton: true,
                        confirmButtonText: "Tamam",
                        confirmButtonColor: "#1ab394",
                        showCancelButton: false,
                        cancelButtonText: "İptal",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        html: true,
                        type: "success",
                        customClass: 'sweet-alert',
                    }, function(isConfirm) {
                        if (isConfirm) {
                            window.location.href = "/dashboard/obifin";
                        }
                    });
                } else if (response.result == 2) {
                    toastr.error("Başarılı", "Zaten bir başvurunuz var.");
                } else {
                    toastr.error("Başarısız", "Veriler kaydedilemedi. Tüm alanları doldurduğunuzdan emin olunuz.");
                }
                this.setState({saving: false});
            });
        } else {
            toastr.error("Başarısız", "Tüm alanları doldurduğunuzdan emin olunuz.");
            this.setState({saving: false});
        }
    }
    setFieldChange(type, field, value) {
        let _self = this;
        let formData = _self.state.formData;
        let errors = _self.state.errors || {};
        formData[field] = value;
        let bigForm = _self.state.formData;
        _self.setState({ formData: bigForm, errors: errors});
    }
    setFieldChangeApplication(type, field, value) {
        let _self = this;
        let formData = _self.state.formData;
        let errors = _self.state.errors || {};
        formData[field] = value;
        let bigForm = _self.state.formData;
        _self.setState({ formDataApplication: bigForm, errors: errors});
    }
    render() {

        const inputProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldChange.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }
        const inputPropsApplication = {
            onChange: this.setFieldChangeApplication.bind(this, "data"),
            onMount: this.setFieldChangeApplication.bind(this, "data"),
            formData: this.state.formDataApplication,
            errors: this.state.errors
        }

        // read localstorage company_modules, decode json and check if module_id 1 exists and expiry time
        const localstorage_modules = JSON.parse(localStorage.getItem("company_modules"));
        let obifin_module_exists = false;
        let obifin_expiry_date = false;
        let obifin_expired = false;
        let obifin_module_buyed = false;
        obifin_module_exists = localstorage_modules.find(x => x.module_id === 1);
        if (obifin_module_exists != null && obifin_module_exists != "") {
            obifin_module_buyed = true;
            obifin_expiry_date = obifin_module_exists.expiry_date;
            if (obifin_expiry_date != null && obifin_expiry_date != "") {
                if (obifin_expiry_date < moment().format("YYYY-MM-DD")) {
                    obifin_expired = true;
                }
            }
        }
        if (obifin_module_buyed == false || obifin_expired == true) {
            swal({
                title: "Açık Bankacılık Modülü",
                text: "Açık bankacılık işlemlerini kullanabilmek için lütfen önce modülü satın alınız.",
                showConfirmButton: true,
                confirmButtonText: "Satın Al",
                confirmButtonColor: "#1ab394",
                showCancelButton: false,
                closeOnConfirm: false,
                closeOnCancel: false,
                html: true,
                type: "warning",
                customClass: 'sweet-alert',
                // disable esc
                allowEscapeKey: false,
                // disable click outside
                allowOutsideClick: false,

            }, function(isConfirm) {
                if (isConfirm) {
                    window.location.href = "/subscriber";
                }
            });
        }


        return (<>
            <div>
                {(this.state.page_loading == true) ? <EkoSpinner /> :
                    <>
                        <div className="portlet light">
                            <div className="portlet-title">
                                <div className="caption font-dark"><span className="caption-subject bold uppercase"> Açık Bankacılık Entegrasyonu</span></div>
                                <div className="pull-right">
                                    <a href={(process.env.REACT_APP_API_URL).replace('/public/api', '/public').replace('api.ekohesap.com/api', 'api.ekohesap.com')+"/panel/download.php?folder=documents/open-banking-application-forms&file=Banka_Entegrasyon_Dokumani.pdf"} style={{marginRight: "10px"}}><i className="fa fa-download"></i> Entegrasyon Klavuzu</a>
                                    {this.state.no_obifin_data ?
                                        <>
                                            <a className="btn btn-default blue disabled"><i className="fa fa-list"></i> Banka API Tanımları</a>
                                        </>
                                        :
                                        <>
                                            <Link className="btn btn-default blue" to="/dashboard/obifin/bank-apis"><i className="fa fa-list"></i> Banka API Tanımları</Link>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="portlet-body">
                                <div className={"row"}>
                                    {this.state.no_obifin_data && (
                                        <>
                                            {this.state.obifin_application_data == null &&  (
                                                <>
                                                    <div className={"col-md-12 col-xs-12 text-center"}>
                                                        <h3>Açık Bankacılık Entegrasyon Başvurusu</h3>
                                                        <p className={"blue"}>Modülü aktif edebilmek için lütfen başvuru formunu doldurunuz. Hesap aktivasyonu sonrası entegre etmek istediğiniz bankaları tanımlayabileceksiniz.</p>
                                                        <hr/>
                                                    </div>
                                                    <div className={"col-md-12 col-xs-12 text-left"} style={{marginBottom: "30px"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="company_name"
                                                                    label={"Firma Unvanı"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Firmanızın tam unvanını giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.company_name}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="tax_number"
                                                                    type="number"
                                                                    label={"Vergi Numarası"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Firmanızın vergi numarasını giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.tax_number}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="tax_office"
                                                                    label={"Vergi Dairesi"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Firmanızın vergi dairesini giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.tax_office}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="address"
                                                                    label={"Firma Adresi"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Firmanızın tam adresini giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.address}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="name_surname"
                                                                    label={"Yetkili Adı Soyadı"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Yetkili kişinin adını ve soyadını giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.name_surname}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="identity_number"
                                                                    type="number"
                                                                    label={"Yetkili TC Kimlik Numarası"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Yetkili kişinin TC Kimlik numarasını giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.identity_number}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="phone"
                                                                    label={"Yetkili Telefon Numarası"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Yetkili kişinin telefonunu giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.phone}
                                                                />
                                                            </div>
                                                            <div className={"col-md-6 col-xs-12"}>
                                                                <EkoFormInputText
                                                                    {...inputPropsApplication}
                                                                    id="email"
                                                                    label={"Yetkili E-posta Adresi"}
                                                                    style={{width: "100%"}}
                                                                    placeHolder="Geçerli bir e-posta giriniz"
                                                                    isVertical={true}
                                                                    value={this.state.formDataApplication.email}
                                                                />
                                                            </div>
                                                            <div className={"col-md-12 col-xs-12 text-center"}>
                                                                <EkoFormButton
                                                                    label="Başvuru Yap"
                                                                    faClass="fa fa-plus"
                                                                    isLoading={this.state.saving}
                                                                    onClick={() => this.onSaveApplication()}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {this.state.obifin_application_data != null && (this.state.obifin_application_data.status == 1 || this.state.obifin_application_data.status == 0) && (
                                        <>
                                            <div className={"col-md-12 col-xs-12 text-center"}>
                                                <p className={"text-primary"}>Bankalarınızdan aldığınız API erişim bilgilerini tanımlayınız.</p>
                                                <p>Bankalarınıza yaptığınız API erişim başvuruları sonrası aldığınız erişim bilgilerini lütfen sağ üst bölümden tanımlayınız.</p>
                                                <hr/>
                                            </div>
                                            {this.state.obifin_application_data != null && this.state.obifin_application_data.status == 0 && (
                                                <>
                                                    <div className={"col-md-12 col-xs-12 text-left"} style={{marginBottom: "30px"}}>
                                                        <div className={"alert alert-info"}>
                                                            <strong>Bilgi!</strong> Açık bankacılık modülü için başvurunuz alınmıştır. En kısa zamanda açık bankacılık modülü kullanımınıza açılacaktır.
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className={"col-md-12 col-sm-6 col-xs-12 margin-bottom-10"}>
                                                <label>Erişim Adresi</label>
                                                <input
                                                    id="obifin_api_address"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={"https://prodapio.obifin.com şeklinde sonunda / olmadan giriniz"}
                                                    value={this.state.obifin_api_address}
                                                    onChange={evt => this.setState({obifin_api_address: evt.target.value})} />
                                            </div>
                                            <div className={"col-md-4 col-sm-6 col-xs-12 margin-bottom-10"}>
                                                <label>Mail Adresi</label>
                                                <input
                                                    id="obifin_username"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.obifin_username}
                                                    onChange={evt => this.setState({obifin_username: evt.target.value})} />
                                            </div>
                                            <div className={"col-md-4 col-sm-6 col-xs-12 margin-bottom-10"}>
                                                <label>Şifre</label>
                                                <input
                                                    id="obifin_username"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.obifin_password}
                                                    onChange={evt => this.setState({obifin_password: evt.target.value})} />
                                            </div>
                                            <div className={"col-md-4 col-sm-6 col-xs-12 margin-bottom-10"}>
                                                <label>API Key</label>
                                                <input
                                                    id="obifin_username"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.obifin_api_key}
                                                    onChange={evt => this.setState({obifin_api_key: evt.target.value})} />
                                            </div>
                                            <div className={"col-md-12 col-xs-12 text-center margin-bottom-10"} style={{marginTop: "20px"}}>
                                                <EkoFormButton
                                                    label={this.state.no_obifin_data ? "Kaydet & Aktif Et" : "Güncelle"}
                                                    faClass="fa fa-plus"
                                                    isLoading={this.state.saving}
                                                    onClick={() => this.onSave()}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>

        </>);
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(Obifin);
