import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import EkoChartBarReports from "../../components/elements/EkoChartBarReports";
import EkoChartDonutReports from "../../components/elements/EkoChartDonutReports";

import {
  FETCH_SALES_CHART_VALUES,
  FETCH_TABLE_DATA,
} from "../../actions/types";
import { EkoFormSelect } from "../../components/elements/EkoForm";
import Api from "../../util/Api";

class detailReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { getIn: { is_detail: 0 } },
      months: [],
      productData: [],
      supplierData: [],
      customerData: [],
    };
  }

  fetchChartValues() {
    this.props.fetchData(
      "/customer/outStandingSalesInvoices",
      FETCH_SALES_CHART_VALUES
    );
    this.props.fetchData("/dashboard/tableData", FETCH_TABLE_DATA);
  }

  componentWillMount() {
    const self = this;
    self.fetchChartValues();
    Api.get("/mobile-chart-reports/customer/satislar/", (data, errors) => {
      if (errors === false) {
        this.setState({
          customerData: data.result,
        });
      }
    });
    Api.get("/mobile-chart-reports/supplier/alislar/", (data, errors) => {
      if (errors === false) {
        this.setState({
          supplierData: data.result,
        });
      }
    });
    Api.get("/mobile-chart-reports/product/satislar/", (data, errors) => {
      if (errors === false) {
        this.setState({
          productData: data.result,
        });
      }
    });
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];

    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    this.setState({ formData: bigForm });
  }

  setFieldMount = () => null;

  render() {
    const salesChartValues =
      this.props.salesChartValues && this.props.salesChartValues.chartValues;

    let chart1Data = [];
    let chart1Title = "Rapor Bilgileri";
    console.log(salesChartValues, "chart1Data");

    const getInProps = {
      onChange: this.setFieldChange.bind(this, "getIn"),
      onMount: this.setFieldMount.bind(this, "getIn"),
      formData: this.state.formData.getIn,
    };

    salesChartValues &&
      salesChartValues.forEach((s) => {
        // salesChartValuesData.push([s.description,s.amount])
        chart1Data.push({ name: s.description, y: s.amount });
      });

    let types = [
      { id: 1, name: "Mal/Hizmet Faturası" },
      { id: 2, name: "Gider Faturası" },
      { id: 3, name: "Müşteriden Fatura" },
    ];

    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-5"
            style={{
              marginTop: "15px",
              background: "#fff",
              paddingBottom: "170px",
            }}
          >
            <div>
              <EkoFormSelect
                {...getInProps}
                labelMd={3}
                defaultText="MÜŞTERİ SEÇİNİZ"
                options={types}
                id="document_type"
              />
            </div>

            <table className="table table-bordered table-light">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Müşteri Ünvanı</th>
                  <th scope="col">Bakiye</th>
                  <th scope="col">İletişim</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mehmet</td>
                  <td>1500₺</td>
                  <td>Ankara</td>
                </tr>

                <tr>
                  <th scope="row">2</th>
                  <td>Kenan</td>
                  <td>35000₺</td>
                  <td>İstanbul</td>
                </tr>

                <tr>
                  <th scope="row">3</th>
                  <td>Hakan</td>
                  <td>-3000₺</td>
                  <td>İzmir</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-7" style={{ marginTop: "15px" }}>
            <EkoChartBarReports />
          </div>
          <div
            className="col-md-5"
            style={{ marginTop: "15px", background: "#fff" }}
          >
            <h3
              style={{ fontSize: "13x", color: "#4d4c4f", paddingLeft: "23px" }}
            >
              Average Purchase Value
            </h3>
            <div className="row" style={{ padding: "23px" }}>
              <div className="col-md-2">
                <span
                  className="glyphicon glyphicon-triangle-top topDetail"
                  aria-hidden="true"
                ></span>
              </div>
              <div className="col-md-10">
                <h2>2500₺</h2> <br />
                Ortalama Satış Değerleri
              </div>

              <div className="col-md-2">
                <span
                  className="glyphicon glyphicon-triangle-top topDetail"
                  aria-hidden="true"
                ></span>
              </div>
              <div className="col-md-10">
                <h2>2500₺</h2> <br />
                Ortalama Alış Değerleri
              </div>
            </div>
          </div>
          <div className="col-md-7" style={{ marginTop: "15px" }}>
            <EkoChartDonutReports
              data={chart1Data}
              title={chart1Title}
              formatter="amount"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    salesChartValues: state.data.salesChartValues,
    tableData: state.data.tableData,
  };
}

export default connect(mapStateToProps, actions)(detailReports);
