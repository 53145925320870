import React, {Component} from 'react';
import {EkoForm, EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import EkoSpinner from "../../components/elements/EkoSpinner";
import {browserHistory} from "react-router";
import {FiArrowUpCircle, FiArrowDownCircle, FiCheckSquare, FiAlertTriangle, FiEye, FiInfo} from 'react-icons/fi';


class GovermentLoginInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SMMApiKey:"",
            SMMApiSecret:"",
            EArsivUsername:"",
            EArsivPassword:"",
            VergiKimlikNo:"",
            TCKimlikNo:"",
        };
    }
    componentWillMount() {
        this.fetch();
    }
    fetch() {
        Api.get("/company/goverment-information", (data, errors) => {
            if (errors) {
                this.setState({dataLoading: false});
                return;
            }
            data = data.data;
                this.setState({
                    SMMApiKey: data.SMMApiKey,
                    SMMApiSecret: data.SMMApiSecret,
                    EArsivUsername: data.EArsivUsername,
                    EArsivPassword: data.EArsivPassword,
                    VergiKimlikNo: data.VergiKimlikNo,
                    TCKimlikNo: data.TCKimlikNo,
                });
        });
    }
    onSave() {
            var params = {
                SMMApiKey: this.state.SMMApiKey,
                SMMApiSecret: this.state.SMMApiSecret,
                EArsivUsername: this.state.EArsivUsername,
                EArsivPassword: this.state.EArsivPassword,
                VergiKimlikNo: this.state.VergiKimlikNo,
                TCKimlikNo: this.state.TCKimlikNo,
            };
            if(this.state.EArsivUsername){
                if(this.state.EArsivUsername=='' || this.state.EArsivUsername.length>=11){
                    toastr.error("Hata!", "Kaydedilmedi. Girilen VKN Bilgisi Yanlış");
                    return false;
                }
                if (isNaN(this.state.EArsivUsername)) {
                    toastr.error("Hata!", "Kaydedilmedi. Girilen VKN Bilgisi Yanlış");
                    return false;
                }
            }
            Api.post("/company/goverment-information-update", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    toastr.success("Başarılı", "Bilgiler Başarıyla Güncellendi.");
                }
            });
    }

    render() {
        return (<>
            <EkoForm>
                <div className="row">
                    <div className="col-md-6" style={{"border-right": "3px solid #ddd", "padding": "20px"}}>
                        <h3 className="h3 text-center">Defter Beyan Sistemi Bilgileri</h3>
                        <p style={{padding: "0", margin: "0"}}>SMM Api Key</p>
                        <input
                            placeholder={"SMM Api Key"}
                            type="text"
                            className="form-control"
                            value={this.state.SMMApiKey}
                            onChange={evt => this.setState({SMMApiKey: evt.target.value})}
                        />
                        <br/>
                        <p style={{padding: "0", margin: "0"}}>SMM Api Secret</p>
                        <input
                            placeholder={"SMM Api Secret"}
                            type="text"
                            className="form-control"
                            value={this.state.SMMApiSecret}
                            onChange={evt => this.setState({SMMApiSecret: evt.target.value})}
                        />
                    </div>
                    <div className="col-md-6" style={{"padding": "20px"}}>
                        <h3 className="h3 text-center">Interaktif / E-Arşiv Portal Bilgileri</h3>
                        <p style={{padding: "0", margin: "0"}}>E-Arşiv Portal Kullanıcı Adı</p>
                        <input
                            placeholder={"E-Arşiv Portal Kullanıcı Adı"}
                            type="text"
                            className="form-control"
                            value={this.state.EArsivUsername}
                            onChange={evt => this.setState({EArsivUsername: evt.target.value})}
                        />
                        <br/>
                        <p style={{padding: "0", margin: "0"}}>E-Arşiv Portal Parola</p>
                        <input
                            placeholder={"E-Arşiv Portal Parola"}
                            type="text"
                            className="form-control"
                            value={this.state.EArsivPassword}
                            onChange={evt => this.setState({EArsivPassword: evt.target.value})}
                        />
                        <br/>
                        <p style={{padding: "0", margin: "0"}}>Vergi Kimlik No</p>
                        <input
                            placeholder={"Vergi Kimlik No"}
                            type="text"
                            className="form-control"
                            value={this.state.VergiKimlikNo}
                            onChange={evt => this.setState({VergiKimlikNo: evt.target.value})}
                        />
                        <br/>
                        <p style={{padding: "0", margin: "0"}}>TC Kimlik No</p>
                        <input
                            placeholder={"TC Kimlik No"}
                            type="text"
                            className="form-control"
                            value={this.state.TCKimlikNo}
                            onChange={evt => this.setState({TCKimlikNo: evt.target.value})}
                        />
                    </div>
                </div>

                <div style={{"display": "flex", "justifyContent": "center", "marginTop": 20}}>
                    <EkoFormButton
                        label={"Kaydet"}
                        faClass="fa fa-plus"
                        isLoading={this.state.saving}
                        onClick={() => this.onSave()}
                    />
                    <EkoFormButton
                        label="İptal"
                        className="btn"
                        onClick={() => browserHistory.goBack()}
                    />
                </div>
            </EkoForm>

        </>);
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(GovermentLoginInformation);