import {AUTH_NO, AUTH_YES, ERROR_AUTH, AUTH_LOGOUT} from '../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
        case AUTH_YES:
            return {...state, error: '', authenticated: true};
        case AUTH_NO:
            return {...state, authenticated: false};
        case AUTH_LOGOUT:
            return {...state, authenticated: false, loggedOut: true}
        case ERROR_AUTH:
            return {...state, error: action.payload};
        default:
            return state;
    }


}
