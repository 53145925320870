import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";

export default class CreatableMulti extends Component {
  render() {
    return (
      <CreatableSelect
        placeholder="Etiket seçin ya da ekleyin"
        isMulti
        menuPortalTarget={document.body}
        onChange={this.props.onHandleTags}
        options={this.props.options}
        defaultValue={this.props.defaultValue}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#F5F5F5",
            primary: "grey",
            primary50: "#BEBEBE"
          }
        })}
        formatCreateLabel={label => <span>"{label}" Yeni Ekle</span>}
      />
    );
  }
}
