import React, {PureComponent} from 'react';


export default class CreateXml extends PureComponent {

    handleSubmit = (e) => {
        if (this.props.check) {
            if (this.props.check() === true) {
                return true;
            }

            e.preventDefault();

            return false;
        }

        return true;
    };

    render() {

        return <form onSubmit={this.handleSubmit} style={{"display": "inline-block"}} method={"post"} target={"_blank"}
                     action={this.props.action}>

            <button type="submit" className="btn blue btn-sm">
                {!this.props.loading ? <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i> :
                    <span> <i className="fa fa-file"></i> XML Oluştur</span>}

            </button>

            {this.props.inputs.map((input) => {
                let data = typeof input.value === 'object' ? JSON.stringify(input.value) : input.value;

                return <input type="hidden" name={input.name}
                              value={data}/>
            })}

        </form>

    }
}

CreateXml.defaultProps = {
    inputs: [],
    loading: false,
    check: null,
    action : ''
};