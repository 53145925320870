import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddTransfer from "./AddTransfer";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";

class CollectionAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      type: localStorage.getItem("new_transfer_type") || 1
    };
  }

  componentWillMount() {
    var self = this;
    if (this.props.params && this.props.params.id) {
      //NOTE @bocek come for edit
      Api.get("/internalTransfer/" + this.props.params.id, function(
        response,
        err
      ) {
        if (response.internalTransfer) {
          let formData = { transfer: response.internalTransfer };

          try {
            //
            formData.transfer.from_amount = Util.convertDecimalToMoney(
              formData.transfer.from_amount || 0
            );
            formData.transfer.to_amount = Util.convertDecimalToMoney(
              formData.transfer.to_amount || 0
            );
            formData.transfer.date = Util.convertFromDatabaseDate(
              formData.transfer.date
            );
            formData.transfer.currency_rate = formData.transfer.currency_rate
              .toString()
              .replace(/[#.]/g, ",");
          } catch (ex) {
            console.log(ex);
          }
          self.setState({ formData: formData, type: formData.transfer.type });
        } else {
          toastr.error("Hata", err.message);
          self.props.router.push("/dashboard/transfers");
        }
      });
    }
  }

  getTitle() {
    if (parseInt(this.state.type, 10) === 1) return " - Hesaplar Arası";
    else return " - Firmalar Arası";
  }

  redirectToBase() {
    this.props.router.push("/dashboard/transfers");
  }

  render() {
    let title = this.getTitle();
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/transfers")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              VİRMAN{title}
            </span>
              </div>
            </div>
            <div className="portlet-body">
              <AddTransfer
                  formData={this.state.formData}
                  type={this.state.type}
                  redirectToBase={this.redirectToBase.bind(this)}
              />
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collections: state.data.collections, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(CollectionAddUpdate);
