import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddOtherReceipt from "./AddOtherReceipt";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";

class OtherReceiptAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      fromWhoType: localStorage.getItem("new_other_receipt_from_who_type")
    };
  }

  componentWillMount() {
    var self = this;
    if (this.props.params && this.props.params.id) {
      //NOTE @bocek come for edit
      Api.get("/otherReceipt/" + this.props.params.id, function(response, err) {
        //TODO errorlari da handle etmek lazim da neyse @bocek
        if (response.otherReceipt) {
          const docUrl = response.otherReceipt?.document_url;
          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }
          let formData = { otherReceipt: response.otherReceipt,footer:{} };

          try {
            //
            formData.otherReceipt.date = Util.convertFromDatabaseDate(
              formData.otherReceipt.date
            );
            formData.otherReceipt.due_date = Util.convertFromDatabaseDate(
                formData.otherReceipt.due_date
            );
            formData.otherReceipt.payment_date = Util.convertFromDatabaseDate(
                formData.otherReceipt.payment_date
            );
            formData.otherReceipt.amount = Util.convertDecimalToMoney(
              formData.otherReceipt.amount
            );
            formData.otherReceipt.vat_amount = Util.convertDecimalToMoney(
              formData.otherReceipt.vat_amount
            );
          } catch (ex) {
            console.log(ex);
          }
          formData.footer.amount =99;
          self.setState({
            formData: formData,
            fromWhoType: formData.otherReceipt.type
          });
        } else {
          toastr.error("Hata", err.message);
          self.props.router.push("/dashboard/purchases/otherReceipt");
        }
      });
    }
  }

  redirectToBase() {
    this.props.router.push("/dashboard/purchases/otherReceipt");
  }

  render() {
    return (
      <AddOtherReceipt
        title="DİĞER GİDERLER"
        editId={this.props.params.id}
        formData={this.state.formData}
        fromWhoType={this.state.fromWhoType}
        redirectToBase={this.redirectToBase.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    otherReceipts: state.data.otherReceipts, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions,
    addDocument: state.page.addDocument
  };
}

export default connect(mapStateToProps, actions)(OtherReceiptAddUpdate);
