import React, { Component } from 'react'
import EkoModal from '../../components/elements/EkoModal'
import { EkoFormRadioButton, EkoFormButton, EkoFormDate } from '../../components/elements/EkoForm'
import Api from '../../util/Api'
import {toastr} from 'react-redux-toastr'

class ImportDetailModal3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            formData: { customer: {is_type: 0 }},
            downloadLoading: false,
            uploadLoading: false,
            date1: '',
            date2: '',
            downloadExcel: false,
            detailIsOpened: false,
            formFields: {},
            showModal: false,
            radioButtons: [
                {
                    label: 'Excel Olarak',
                    id: 'is_type',
                    radio_id: 'excel',
                    value: 0,
                    checked: true
                }
            ]
        }
    }

    defaultFormData() {
        let importId = this.props.importId;
        let formData = this.state.formData;

        var title = JSON.parse(localStorage.getItem("user"));

        let company_id = title['company_id']

        return {
            customer: {
                customer_id: importId,
                start_date: this.state.date1,
                end_date: this.state.date2,
                type: formData.customer.is_type,
                company_id: company_id
            },
        }

    }


    closeModal() {
        this.props.closeModal();
    }

    toggleDetail(state) {
        this.setState({ detailIsOpened: !this.state.detailIsOpened });
    }

    onExportSend() {

        const dtConfig = this.props.dtConfig;
        //let paramsId = this.props.importId;

        // console.log("dtConfig Burada -->", dtConfig)

        let formData = this.defaultFormData();

        const self = this;

        self.setState({downloadExcel: true});
        /* let params = {};


         if (paramsId){
             params = {

                 id: this.props.importId,
                 date1: this.state.date1,
                 date2: this.state.date2
             }
         }

         else if (this.state.date1 || this.state.date2) {
             params = {
                 date1: this.state.date1,
                 date2: this.state.date2
             }
         }*/
        Api.get('/' + dtConfig.name + '/aggregateBalance'+'/'+formData.customer.company_id+'/'+formData.customer.type + '/'+formData.customer.start_date+'/'+formData.customer.end_date, function (responses) {
            // console.log(formData.customer.type);
            if (responses) {
                if(responses.status === 'fail'){
                    toastr.warning('Dikkat',responses.message)
                    return;
                }
                let date1 = formData.customer.start_date == null ? null : formData.customer.start_date;
                let date2 = formData.customer.end_date == null ? null : formData.customer.end_date;
                window.open(process.env.REACT_APP_API_URL + '/' + dtConfig.name + '/aggregateBalance'+'/'+formData.customer.company_id+'/'+formData.customer.type + '/'+date1+'/'+date2+'?token=' + localStorage.getItem('token'), "_blank")
            } else {
                toastr.error("Hata")
            }
        })

    }


    setFieldChange(type, field, formattedValue) {

        const self = this;

        if (type === "customer" && field === "start_date") {
            let newDate1 = formattedValue;
            self.setState({ date1: newDate1 })
        } else if (type === "customer" && field === "end_date") {
            let newDate2 = formattedValue;
            self.setState({ date2: newDate2 })
        }

    }

    onMount() {

    }


    render() {

        const detailProps = {
            onChange: this.setFieldChange.bind(this, "customer"),
            onMount: this.onMount.bind(this),
            formData: this.state.formData.customer,
        }

        const displayDetail = this.state.detailIsOpened ? '' : 'none'

        return (
            <EkoModal
                showModal={this.props.showModal}
                title={"Toplu Bakiye (Müşteri + Tedarikçi)"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}>
                <div className="row">
                    <div className="col-md-6">
                        <EkoFormRadioButton {...detailProps} id="radioButtons" buttons={this.state.radioButtons}
                                            onFalse={this.onFalse} />
                    </div>

                </div><br /><br />

                <div className="row">
                    <div className="col-md-12" style={{ marginBottom: 20}}>
                        <label className="mt-checkbox">
                            <input type="checkbox" onClick={this.toggleDetail.bind(this)} defaultChecked={this.state.detailIsOpened} /> <div>Tarih Bilgisini Seçerek Arama Yapın</div>
                            <span></span>
                        </label>
                    </div>
                </div>

                <div style={{display: displayDetail}}>
                    <div className="row">
                        <div className="col-md-4" style={{ marginBottom: 20 }}>
                            <EkoFormDate
                                {...detailProps}
                                id="start_date"
                                dateFormat="DD-MM-YYYY"
                                isVertical={true}
                                label="Başlangıç Tarihi"
                                value={this.state.date1}
                            />
                        </div>

                        <div className="col-md-4" style={{ marginBottom: 20 }}>
                            <EkoFormDate
                                {...detailProps}
                                id="end_date"
                                dateFormat="DD-MM-YYYY"
                                isVertical={true}
                                label="Bitiş Tarihi"
                                value={this.state.date2}
                            />
                        </div>

                        <br />

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <EkoFormButton
                            label={'Hesap Hareketlerini Gör'}
                            faClass="fa fa-eye"
                            onClick={this.onExportSend.bind(this)}
                        />
                    </div>
                </div>
            </EkoModal>
        )
    }
}

export default ImportDetailModal3;
