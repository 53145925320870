import React, { Component } from "react";
import EkoInputTable from "../../components/elements/EkoInputTable";
import EkoFormInputText from "../../components/elements/form/EkoFormInputText";
import { EkoFormDate, EkoFormSelect } from "../../components/elements/EkoForm";
import { EkoModal } from "../../components/elements/EkoModal";
import Portlet from "../../components/elements/Portlet";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
const WAREHOUSE = {
  IN: "enter",
  OUT: "exit",
};

class TransferWarehousesAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultData;
  }

  //Retrieve product list.
  async fetchItems(warehouse_id = false) {
    return new Promise((resolve, reject) => {
      let endpoint;
      if (warehouse_id !== false) {
        endpoint = `/warehouses/products/${warehouse_id}`;
      } else {
        endpoint = "/item/showAll";
      }

      Api.get(endpoint, (response, err) => {
        if (err !== false) {
          reject(err);
        }
        resolve(response);
      });
    });
  }

  get defaultData() {
    return {
      warehouseList: [],
      productList: [],
      formErrors: {},
      errors: {
        rows: [],
      },
      formData: {
        exit: 0,
        enter: 0,
        documentNo: "",
        date: "",
        note: "",
        rows: [],
      },
      rowChanges: {
        deletedRows: [],
        changes: [],
      },
      saving: false,
      tableData: {
        headers: [{ label: "Ürün Adı" }, { label: "Adet" }],
        rows: [this.row],
      },
    };
  }

  get row() {
    return [
      {
        type: "select",
        id: "item_id",
        optionValue: "item_name",
        selectedOptionValue: "item_name",
        value: 0,
        optionId: "id",
        defaultText: "SEÇ",
        searchable: true,
        onAddNewItemClick: false,
        data: [],
      },
      {
        label: "",
        placeholder: "Miktar",
        type: "number",
        colMd: 12,
        id: "quantity",
      },
    ];
  }

  onAddNewItemClick() {}

  onChange = ({ rows }) => {
    this.setState({
      rows,
    });
  };

  /**
   *  kaydet butonuna tıklandığında çağrılacak
   */
  onSave = () => {
    let self = this;
    if (this.props.params && this.props.params.id) {
      const id = this.props.params && this.props.params.id;

      Api.put(
          "/transfer-warehouses/update",
          {
            header: { ...this.state.formData, id: id },
            rowChanges: this.state.rowChanges,
          },
          (response, err) => {
            console.log(response, err);
            if (!err) {
              toastr.success("Başarılı!", response.message);
              window.location.replace("/dashboard/product-service#transfer-warehouse");
            }
          }
      );
    } else {
      Api.post(
          "/transfer-warehouses",
          { header: this.state.formData },
          function (res, err) {
            if (res.status === "ok") {
              toastr.success("Başarılı!", res.message);
              window.location.replace("/dashboard/product-service#transfer-warehouse");
            }else{
              if(res.status === 'header_validation'){
                self.setState({formErrors: res.validation})
                toastr.error("Hata!", "Lütfen formu kontrol ediniz.");
              }else if(res.status === 'row_validation'){
                self.setState({errors: {rows: res.validation }})
                toastr.error("Hata!", "Lütfen formu kontrol ediniz.");
              }else{
                toastr.error("Hata!", res.message ?? "Bir hata oluştu! Lütfen bizimle iletişime geçin!");
              }
            }
          }
      );
    }
  };

  warehouseChange(type, value) {
    if (type === WAREHOUSE.IN) {
      this.setState({
        formData: {
          ...this.state.formData,
          enter: value,
        },
      });
      if (this.state.formData.exit === value) {
        this.setState.formData({
          formData: {
            ...this.state.formData,
            exit: 0,
          },
        });
      }
    }
    if (type === WAREHOUSE.OUT) {
      //When the warehouse is changed, the product list is updated depends by Warehouse's products
      let _this = this;
      this.fetchItems(value)
          .then((res) => {
            const items = res.products;
            _this.setState({
              productList: items,
            });
          })
          .then(() => {
            const productList = _this.state.productList;
            let tableData = _this.state.tableData;
            tableData.rows = tableData.rows.map(function (row, index) {
              row[0].data = productList;
              return row;
            });
            return tableData;
          })
          .then((td) => {
            _this.setState({
              tableData: td,
            });
          });

      this.setState({
        formData: {
          ...this.state.formData,
          exit: value,
        },
      });
    }
  }

  /**
   *
   * @param field
   * @param value
   */
  onFieldChange = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value,
      },
    });
  };

  onChangeRow(index, value) {
    var formData = this.state.formData;
    this.setState({
      rowChanges: {
        ...this.state.rowChanges,
        changes: {
          ...this.state.rowChanges.changes,
          [index]: formData.rows[index],
        },
      },
    });
    //console.log("onChangeRow", tableData.rows[index]);
  }

  componentWillMount() {
    Api.get("/warehouses/showAll", (response, err) => {
      if (response && response.items) {
        this.setState({
          warehouseList: response.items,
        });
      }
    });

    if (this.props.params && this.props.params.id) {
      const id = this.props.params && this.props.params.id;

      Api.get("/transfer-warehouses/show/" + id, (response, err) => {
        if (response && response.transfer) {
          this.setState({
            formData: {
              exit: response.transfer.warehouse_from,
              enter: response.transfer.warehouse_to,
              documentNo: response.transfer.document_no,
              date: response.transfer.shipment_date,
              note: response.transfer.note,
              rows: response.lines,
            },
          });

          let tableDataRows = [...this.state.tableData.rows];
          response.lines.map(() => {
            if (tableDataRows.length < response.lines.length) {
              tableDataRows.push(this.row);
            }
          });
          this.warehouseChange(WAREHOUSE.OUT, response.transfer.warehouse_to);
          this.setState({
            tableData: {
              ...this.state.tableData,
              rows: tableDataRows,
            },
          });
        }
      });
    }
  }

  onRowDelete(index, row) {
    this.setState({
      rowChanges: {
        ...this.state.rowChanges,
        deletedRows: [...this.state.rowChanges.deletedRows, row],
      },
    });
    console.log("deleted", this.state.deletedRows);
  }
  componentDidMount() {
    let _this = this;
    this.fetchItems()
        .then((res) => {
          const items = res.items;
          _this.setState({
            productList: items,
          });
        })
  }

  render() {
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/product-service")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <Portlet title={"Depolar Arası Transfer"}>
            <div
                className="col-md-12"
                style={{backgroundColor: "#fff", marginBottom: 15}}
            >
              <div className="col-md-6">
                <EkoFormSelect
                    isVertical
                    onAddNewItemClick={this.onAddNewItemClick}
                    id={WAREHOUSE.OUT}
                    defaultText={"Çıkış Deposu"}
                    errors={this.state.formErrors}
                    optionId={"id"}
                    searchable="true"
                    options={this.state.warehouseList}
                    onChange={this.warehouseChange.bind(this)}
                    optionValue={"name"}
                    value={this.state.formData.exit}
                    label={"Çıkış Deposu"}
                />
                <EkoFormInputText
                    formData={this.state.formData}
                    onChange={this.onFieldChange}
                    errors={this.state.formErrors}
                    id={"documentNo"}
                    type="number"
                    isVertical={true}
                    label={"Döküman No"}
                />
              </div>
              <div className="col-md-6">
                <EkoFormSelect
                    isVertical
                    onAddNewItemClick={this.onAddNewItemClick}
                    id={WAREHOUSE.IN}
                    defaultText={"Giriş Deposu"}
                    optionId={"id"}
                    errors={this.state.formErrors}
                    searchable="true"
                    value={this.state.formData.enter}
                    onChange={this.warehouseChange.bind(this)}
                    options={this.state.warehouseList.filter(
                        (warehouse) => warehouse.id !== this.state.formData.exit
                    )}
                    optionValue={"name"}
                    label={"Giriş Deposu"}
                />

                <EkoFormDate
                    id={"date"}
                    dateFormat="DD-MM-YYYY"
                    errors={this.state.formErrors}
                    onChange={this.onFieldChange}
                    value={this.state.formData.date}
                    isVertical
                    clearable
                    label={"Tarih"}
                />
              </div>
              <div className="col-md-12">
                <label className="control-label control-line">Not</label>
                <textarea
                    onChange={(data) => this.onFieldChange("note", data.target.value)}
                    name="note"
                    style={{
                      height: 80,
                    }}
                    errors={this.state.formErrors}
                    value={this.state.formData.note}
                    placeholder={"Not Giriniz Giriniz"}
                    className={"form-control adress-area address-editable"}
                ></textarea>
              </div>
            </div>

            <div style={{margin: 20}}>
              <EkoInputTable
                  onSave={this.onSave}
                  onCancel={this.props.router.goBack}
                  onChange={this.onChange}
                  onRowDelete={this.onRowDelete.bind(this)}
                  onChangeRow={this.onChangeRow.bind(this)}
                  errors={this.state.errors}
                  formData={this.state.formData}
                  buttonSaveLabel={"Transfer Et"}
                  data={this.state.tableData}
              />
            </div>
          </Portlet>
        </div>
    );
  }
}

export default TransferWarehousesAddUpdate;
