import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import Api from "../../util/Api";
import Style from "style-it";
import { CSS } from "../../css/tab_datatable";
import DataHelper from "../../services/DataHelper";

class OutstandingCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      loading: true
    };
  }

  fetchCollections() {
    Api.get("/customer/outStandingSalesInvoices", (response, error) => {
      console.log(response);

      this.setState({
        loading: false,
        collections: DataHelper.getOutstandingData(
          response.outStandingSalesInvoices
        )
      });
    });

    //this.props.fetchData("/collection/list", FETCH_COLLECTIONS);
  }

  componentWillMount() {
    this.fetchCollections();
  }

  openUpdate(selectedRow) {
    //this.props.router.push   ('/dashboard/collections/edit/' + selectedRow.id);
  }

  openInsert(menuItem) {
    // this.props.router.push   ('/dashboard/collections/add');
  }

  onDelete(selectedRow) {}

  get items() {
    return [
      {
        id: 1,
        link: "/dashboard/collections",
        title: "Tahsil Ettiklerim"
      },

      {
        id: 2,
        link: "/dashboard/collections/outstanding",
        title: "Alacaklarım"
      }
    ];
  }

  render() {
    console.log(this.state);
    const insertMenu = this.props.viewOptions
      ? this.props.viewOptions.collections.type
      : false;
    return (
      <div>
        {Style.it(
          CSS,
          <div>
            <EkoDataTable
              addUpdateShow={false}
              addButtonShow={false}
              showDelete={false}
              tabMenu={this.items}
              selectedTab={2}
              hideExportMenu
              dtConfigLocal={{
                date_filter_column: "dueDate",
                has_search: true
              }}
              headers={[
                {
                  id: 1,
                  header_name: "Kime",
                  column_name: "customerName",
                  status: 1
                },
                {
                  id: 2,
                  header_name: "Vade Tarihi",
                  column_name: "dueDate",
                  status: 1
                },
                {
                  id: 3,
                  header_name: "Tutar",
                  column_name: "amount",
                  status: 1
                },
                {
                  id: 4,
                  header_name: "Durum",
                  column_name: "dueDaysString",
                  status: 1
                }
              ]}
              title={"Beklenen Tahsilatlar"}
              openInsert={this.openInsert.bind(this)}
              openUpdate={this.openUpdate.bind(this)}
              onDelete={this.onDelete.bind(this)}
              data={this.state.collections}
              insertMenu={insertMenu}
              name={"outstanding_collection"}
            ></EkoDataTable>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // outstanding: state.fetch.outstanding,
    // outstanding_loading: state.fetch.outstanding_loading,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(OutstandingCollections);
