import React, {Component} from 'react';
import {EkoForm, EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import EkoSpinner from "../../components/elements/EkoSpinner";
import {browserHistory} from "react-router";
import {FiArrowUpCircle, FiArrowDownCircle, FiCheckSquare, FiAlertTriangle, FiEye, FiInfo} from 'react-icons/fi';
import InputMask from "react-input-mask";

class SmmmYmmInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adi_soyadi:"",
            unvan:"",
            tc:"",
            vkn:"",
            oda_sicil: "",
            telefon: "",
            email: "",
            fax: "",
            smm_invitation_accepted: 0,
            dataLoading: true,
            saving: false
        };
    }
    componentWillMount() {
        this.fetch();
    }
    fetch() {
        this.setState({dataLoading: true});
        Api.get("/company/loadMMInformation", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({
                adi_soyadi: getData.adi_soyadi,
                unvan: getData.unvan,
                tc: getData.tc,
                vkn: getData.vkn,
                oda_sicil: getData.oda_sicil,
                telefon: getData.telefon,
                email: getData.email,
                fax: getData.fax,
                smm_invitation_accepted: getData.smm_invitation_accepted
            });
            this.setState({dataLoading: false});
        });
    }
    onSave() {
        this.setState({saving: true});
        var params = {
            adi_soyadi: this.state.adi_soyadi,
            unvan: this.state.unvan,
            tc: this.state.tc,
            vkn: this.state.vkn,
            oda_sicil: this.state.oda_sicil,
            telefon: this.state.telefon,
            email: this.state.email,
            fax: this.state.fax,
        };
        Api.post("/company/saveMMInformation", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else if (response.result == 1) {
                toastr.success("Başarılı", "Bilgiler başarıyla güncellendi.");
            } else {
                toastr.error("Başarısız", "Veriler kaydedilemedi. Tüm alanları doldurduğunuzdan emin olunuz.");
            }
            this.setState({saving: false});
        });
    }
    sendSMMMInvitationToManageAccount() {
        var _self = this;
        if (_self.state.email != ""){
            Api.post("/sendSMMMInvitationToManageAccount", {email: _self.state.email, name: _self.state.adi_soyadi}, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else if (response.result == 1) {
                    toastr.success("Başarılı", "Davetiye gönderildi. Hesap sahibinin kabul etmesi bekleniyor.");
                } else if (response.result == 2) {
                    toastr.success("Başarılı", "Kullanıcının hesabı bulunmadığı için hesap oluşturması için davetiye gönderildi.");
                } else if (response.result == 3) {
                    toastr.info("Bilgilendirme", "Daha önce davetiye gönderilmiştir.");
                } else if (response.result == 4) {
                    toastr.error("Bilgilendirme", "Lütfen önce formu kaydediniz ve ardından davet gönderiniz.");
                } else {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu-2");
                }
            });
        }else{
            toastr.error("Hata!", "Lütfen e-posta adresini giriniz.");
        }
    }
    removeSMMMInvitationToManageAccount() {
        var _self = this;
        toastr.confirm('Yetkilendirme kaldırılacaktır. Onaylıyor musunuz?',
            {
                onOk: () => {
                    Api.put("/removeSMMMInvitationToManageAccount", {}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.result == "1") {
                                toastr.success("Başarılı", "Yetkilendirme kaldırıldı. Lütfen bekleyin, sayfa yenileniyor.");
                                window.location.reload(false);
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    render() {
        return (<>

        {this.state.dataLoading == true ? <EkoSpinner /> : 

            <EkoForm>
                <div className="row">
                    <div className="col-md-12 text-center"><h3 className="h3 text-center">SMMM / YMM Bilgileri</h3></div>
                    <div className="col-md-6">
                        <label htmlFor="name_surname" className="control-line control-label"><span>Adı Soyadı</span></label>
                        <div>
                            <input 
                            id="name_surname"
                            type="text"
                            className="form-control"
                            value={this.state.adi_soyadi}
                            onChange={evt => this.setState({adi_soyadi: evt.target.value})}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="unvan" className="control-line control-label"><span>Ünvan Seçiniz</span></label>
                        <select id="unvan" className="form-control" defaultValue={this.state.unvan} onChange={evt => this.setState({unvan: evt.target.value})}>
                            <option value="">Seçim yapınız</option>
                            <option value="SM">SM</option>
                            <option value="SMMM">SMMM</option>
                            <option value="YMM">YMM</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="tc" className="control-line control-label"><span>TC Kimlik Numarası</span></label>
                        <InputMask
                            className="form-control"
                            id="tc"
                            maskChar=""
                            mask="99999999999"
                            value={this.state.tc}
                            onChange={evt => this.setState({tc: evt.target.value})} 
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="vkn" className="control-line control-label"><span>Vergi Kimlik Numarası</span></label>
                        <InputMask
                            className="form-control"
                            id="vkn"
                            maskChar=""
                            mask="9999999999"
                            value={this.state.vkn}
                            onChange={evt => this.setState({vkn: evt.target.value})} 
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="oda_sicil" className="control-line control-label"><span>Oda Sicil Numarası</span></label>
                        <input 
                            id="oda_sicil"
                            type="text"
                            className="form-control"
                            value={this.state.oda_sicil}
                            onChange={evt => this.setState({oda_sicil: evt.target.value})}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="telefon" className="control-line control-label"><span>Telefon</span></label>
                        <InputMask
                            className="form-control"
                            maskChar=""
                            mask="(999) 999-99-99"
                            value={this.state.telefon}
                            onChange={evt => this.setState({telefon: evt.target.value})} 
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="eposta" className="control-line control-label"><span>E-posta Adresi</span></label>
                        <input 
                            id="eposta"
                            type="email"
                            className="form-control"
                            value={this.state.email}
                            onChange={evt => this.setState({email: evt.target.value})}
                        />
                        {this.state.smm_invitation_accepted == 0 ?
                            <>
                                <button type='button' className='btn btn-sm btn-info' onClick={this.sendSMMMInvitationToManageAccount.bind(this)} style={{marginTop: '5px'}}>DAVET GÖNDER</button>
                            </>
                            :
                            <></>
                        }
                        {this.state.smm_invitation_accepted == 1 ?
                            <>
                                <button type='button' className='btn btn-sm btn-danger' onClick={this.removeSMMMInvitationToManageAccount.bind(this)} style={{marginTop: '5px', marginRight: "10px"}}>HESAP ERİŞİM YETKİSİ KALDIR</button>
                                <span><i>Davet kabul edildi.</i></span>
                            </>
                            :
                            <></>
                        }
                        {this.state.smm_invitation_accepted == 2 ?
                            <>
                                <button type='button' className='btn btn-sm btn-info' style={{marginTop: '5px', marginRight: "10px"}} onClick={this.sendSMMMInvitationToManageAccount.bind(this)}>DAVET GÖNDER</button>
                                <span><i>Davet kabul edilmeyi bekliyor.</i></span>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="fax" className="control-line control-label"><span>Faks Numarası</span></label>
                        <InputMask
                            className="form-control"
                            id="fax"
                            maskChar=""
                            mask="(999) 999-99-99"
                            value={this.state.fax}
                            onChange={evt => this.setState({fax: evt.target.value})}
                        />
                    </div>
                </div>

                <div style={{"display": "flex", "justifyContent": "center", "marginTop": 20}}>
                    <EkoFormButton
                        label={"Kaydet"}
                        faClass="fa fa-plus"
                        isLoading={this.state.saving}
                        onClick={() => this.onSave()}
                    />
                </div>
            </EkoForm>
        }
        </>);
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(SmmmYmmInformation);