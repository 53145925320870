import React, {Component} from 'react'
import {ControlLabel} from 'react-bootstrap'
import PropTypes from 'prop-types'
import TooltipService from "../../../services/TooltipService";

export default class EkoFormCheckbox extends Component {
    constructor(props) {
        super(props)
    }

   /* shouldComponentUpdate(nextProps, nextState) {
        let error = this.getError(this.props)
        let value = this.getValue(this.props)


        let nextValue = this.getValue(nextProps)
        let nextError = this.getError(nextProps)


        return error !== nextError || value !== nextValue
    }*/

    getValue = (props) => {

        props = props || this.props;

        return props.formData && props.formData[this.props.id] ? props.formData[this.props.id] : (props.value ? props.value : false)

    }

    getError = (props) => {
        props = props || this.props;

        return props.errors && props.errors[this.props.id] ? props.errors[this.props.id] : props.error;
    }

    componentWillMount() {
        this.props.onMount(this.props.id)
    }

    onChange() {

        const defaultValue = this.getValue()
        if (defaultValue && this.props.onFalse) {
            this.props.onFalse()
        }


        this.props.onChange(this.props.id, !defaultValue)
    }

    renderCheckbox(tooltipId) {

        const defaultValue = this.getValue(this.props)

        return <input id={tooltipId} checked={defaultValue} onChange={this.onChange.bind(this)} type="checkbox"/>
    }

    render() {

        let tooltipId = this.props.tooltipId ? this.props.tooltipId : this.props.id;
        const label = TooltipService.getLabelWithTooltipCheckbox(tooltipId, this.props.label);
        let error = this.getError(this.props)
        return (
            <div style={this.props.containerStyle}>

                <label htmlFor={tooltipId} className={this.props.className}>
                    {this.renderCheckbox(tooltipId)} {label}
                    <span/>
                </label>
                <br/>
                {error
                    ? <div className="form-group has-error">
                        <ControlLabel className="has-error">
                            {error}
                        </ControlLabel>
                    </div>
                    : false}
            </div>
        )
    }
}


EkoFormCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    errors: PropTypes.object,
    error: PropTypes.string,
    value: PropTypes.string,
    formData: PropTypes.object,
    containerStyle: PropTypes.any
};

EkoFormCheckbox.defaultProps = {
    value: '',
    className: 'mt-checkbox',
    containerStyle: {}
};
