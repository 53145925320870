import React, { Component } from "react";
import Sugar from "sugar";
import { Button, DropdownButton, MenuItem } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import AddSalesInvoiceHeader from "./AddSalesInvoiceHeader";
import Util from "../../util/Util";
import moment from "moment";
import AddItem from "../Item/AddItem";
import AddOtherTax from "../../components/forms/AddOtherTax";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import UtilHelper from "../../util/UtilHelper";
import ModalIrsaliye from "../Irsaliye/ModalIrsaliye";
import ExportedModal from "./ExportedModal";
import ChangingModal from "./ChangingModal";
import {
  FETCH_BANK_ACCOUNTS_ALL,
  FETCH_EMPLOYEES_ALL,
} from "../../actions/types";
import { withRouter } from "react-router";
import {
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormSwitch,
} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";
import SelectItemModal from "../../components/elements/SelectItemModal";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddSalesInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countVat: 0,
      linedAdded: false,
      showAddItem: false,
      showAddTax: false,
      formData: props.formData,
      prevForm: props.formData,
      calculatedFormData: {},
      saving: false,
      taxForm: AddOtherTax.getDefaultForm(),
      ExportForm: ExportedModal.defaultData(),
      selectedLine: 0,
      selectedExportedLine: 0,
      errors: props.errors,
      items: {},
      method: "",
      sgk_fatura_buton: "SGK Faturası Bilgileri Ekle",
      istisna_fatura_buton: "KDV İstisna Kodu Ekle",
      bank_currency: "",
      saved: false,
      irsaliye: false,
      ExportedModal: false,
      ChangingModal: false,
      ChangingModalData: [],
      ChangingModalRowData: [],
      deleteRowIds: [],
      deleteTaxIds: [],
      openSgkModal: false,
      openIstisnaModal: false,
      openedIstisnaModal: true,
      sgk_dosya_no: "",
      sgk_donem: "",
      reason_id: 0,
      is_exported: true,
      is_exported2: true,
      irsaliyeRows: {
        rows: [{ irsaliye_no: "", irsaliye_date: "" }],
      },
      passModal:false,
      relatedTags: props.formData.invoice.relatedTags ?? [],
      allTags: [],
      tableData: {
        headers: [
          { label: "Ürün / Hizmet", width: "18%" },
          { label: "Depo", width: "10%", hide: !Util.companyModules(2) },
          { label: "Açıklama", width: "20%" },
          {
            label: "Miktar",
            width: "5%",
          },
          { label: "Birim Fiyatı", width: "8%" },
          { label: "Birim", width: "7%" },
          {
            label: "İsk. %",
            width: "5%",
          },
          { label: "İsk. Tutar", width: "7%" }, //{label: 'Tutar', width: '9%'},
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "6%" },
          {
            label: "Toplam Tutar",
            width: "10%",
          },
          { label: "Diğer Vergiler", width: "8%" },
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true,
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true,
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true,
          },
          {
            id: "taxes_sbjvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true,
          },
          {
            id: "taxes_excvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            visible: true,
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: false,
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: false,
          },
          {
            id: "receivable_amount_fc",
            label: "Ödenecek Tutar",
            className: "tplus-plus",
            visible: true,
          },
        ],
        customFooter: null,
        rows: this.defaultRows(),
      },
      itemModalValues: {
        showItemModal: false,
        refresh: false,
        isChecked: false,
      },
      warehouses: [],
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:1,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
  }

  defaultRows() {
    let rows = [
      [
        {
          type: "select",
          id: "item_id",
          optionValue: "codeName",
          selectedOptionValue: "item_code",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
          data: [],
        },
        {
          type: "select",
          id: "warehouse_id",
          optionValue: "name",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          data: [],
          hide: !Util.companyModules(2),
        },
        // { type: "text", id: "description", vertical: true },
        {
          type: "complete",
          id: "description",
          optionId: "description",
          data: [],
          vertical: true,
          optionValue: "description",
          searchValue: "item_name",
          selectValue: "id",
          onSelect: this.onSelectDescription.bind(this, 0),
        },
        {
          type: "money",
          id: "quantity",
          vertical: true,
          value: 1,
          precision: 6,
        },
        {
          type: "money",
          id: "unit_price_fc",
          vertical: true,
          value: 0,
          precision: 8,
          currency: true,
        },
        {
          type: "select",
          id: "unit_id",
          defaultText: "",
          optionValue: "description",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: [],
        },
        { type: "percent", id: "discount_rate", vertical: true, value: 0 },
        {
          type: "money",
          id: "discount_amount_fc",
          vertical: true,
        }, //  {type: 'money', id: 'net_amount_fc', vertical: true, disabled: true},
        {
          type: "select",
          id: "vat_rate_type_id",
          defaultText: "",
          optionValue: "name",
          optionId: "id",
          data: [],
        },

        { type: "money", id: "vat_amount_fc", vertical: true, disabled: true },
        {
          type: "money",
          id: "with_tax_amount_fc",
          vertical: true,
        },
        {
          type: "custom",
          id: "button",
          tdClass: "ticon2",
          props: { itemCount: 0, warning: false },
          onRender: this.invoiceLineButtonGroup.bind(this, 0),
        },
        {
          hidden: true,
          type: "clickarea",
          id: "other_tax",
          tdClass: "ticon2",
          faClass: "fa fa-plane",
          onClick: this.onOpenExportedModal.bind(this, 0),
        },
        {
          hidden: !Util.companyModules(2),
          type: "clickarea",
          id: "item_oem_number",
          tdClass: "ticon2",
          faClass: "fa fa-shopping-basket",
          onClick: this.onOpenChangingModal.bind(this, 0),
        },
      ],
    ];

    const documentType = this.props.documentType;
    if (documentType === 4) {
    rows = rows.map(function (row, index) {
        row[0].data = JSON.parse(localStorage.getItem("fixed_asset_list"));
        row[0].onAddNewItemClick = false;
        row[0].selectedOptionValue = "asset_name";
        row[0].optionValue = "asset_name";
        row[0].optionId = "id";
        row[0].searchFilter = "asset_name";
        row[0].defaultKey = "asset_name";
        row[0].id = "asset_id";
      return row;
    });
    }
    return rows;
  }

  onInvoiceLineButtonClick(id, index) {
    if (id === "add") {
      return this.onOtherTaxClick(index);
    } else if (id === "delete") {
      this.setState({ selectedLine: index }, function () {
        this.onTaxFormSave(false);
      });
    }
  }

  onSelectDescription(index, id, value) {
    const defaultRowData = {
      item_id: 0,
      asset_id: 0,
      item_code: "",
      istisna_kodu: 0,
      description: "te",
      quantity: "1",
      unit_price_fc: 0,
      discount_rate: 0,
      unit_id: 0,
      discount_amount_fc: "0,00",
      net_amount_fc: "0,00",
      vat_rate_type_id: 0,
      vat_amount_fc: "0,00",
      tags: [],
      with_tax_amount_fc: "0,00",
    };
    const self = this;
    const formData = { ...self.state.formData };
    let rows = [...formData.rows];
    rows[index] = defaultRowData;
    this.setState({ formData: { ...formData, rows } }, () => {
      self.onInvoiceLineChange(
        index,
        { ...defaultRowData, item_id: value },
        "item_id"
      );
    });
    console.log("onSelectDescription", index, id, value);
  }

  invoiceLineButtonGroup(index, props) {
    const title = props.itemCount + " Adet";
    const menuItems = [];
    let ret = "";

    if (props.itemCount > 0) {
      menuItems.push({
        id: "add",
        name: (
          <span>
            <i className="fa fa-edit" />
            Düzenle
          </span>
        ),
      });
      menuItems.push({
        id: "delete",
        name: (
          <span>
            <i className="fa fa-trash" />
            Temizle
          </span>
        ),
      });
      const menuItemView = menuItems.map((menuItem) => (
        <MenuItem
          eventKey={menuItem.id}
          key={menuItem.id}
          onSelect={this.onInvoiceLineButtonClick.bind(
            this,
            menuItem.id,
            index
          )}
        >
          {menuItem.name}
        </MenuItem>
      ));
      ret = (
        <DropdownButton
          id="drop_down"
          style={{ width: "100%" }}
          title={title}
          noCaret
          className="btn dropdown-toggle btn-sm"
        >
          {menuItemView}
        </DropdownButton>
      );
    } else {
      ret = (
        <Button
          className="btn"
          style={{ width: "100%" }}
          onClick={this.onInvoiceLineButtonClick.bind(this, "add", index)}
        >
          <i className="fa fa-plus" />
        </Button>
      );
    }

    return ret;
    /*if(warning === '') return ret
    else
    return
    <OverlayTrigger placement="top" overlay={warning}>
        {ret}
    </OverlayTrigger>*/
  }

  shouldComponentUpdate(nextProps, nextState) {
    //NOTE hersey gelince render etsin
    return (
      !Util.isUndefined(nextProps.vatRateTypes) &&
      !Util.isUndefined(nextProps.vatReductionTypeCodes) &&
      !Util.isUndefined(nextProps.units) &&
      !Util.isUndefined(nextProps.taxTypes)
    );
  }

  onOtherTaxClick(index) {
    const self = this;
    let taxForm = AddOtherTax.getDefaultForm();
    let otherTaxes = this.state.formData.rows[index].otherTaxes;
    if (otherTaxes) taxForm = { rows: [...otherTaxes] };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    taxForm.vat_amount_fc = calculatedFormData.rows[index].vat_amount_fc;
    taxForm.net_amount_fc = calculatedFormData.rows[index].net_amount_fc;
    self.setState({ selectedLine: index, taxForm: taxForm }, function () {
      self.setState({ showAddTax: true });
    });
  }

  onOpenExportedModal(index) {
    const self = this;
    var ExportForm = ExportedModal.defaultData();
    let rowData = this.state.formData.rows[index].exportedLines;
    ExportForm.item = rowData;
    if (ExportForm.item === undefined) {
      ExportForm.item = {
        delivery_type_id: "",
        transport_type_id: "",
        package_type_id: "",
        customs_tracking_no: "",
        container_no: "",
        number_of_containers: "",
        gtip: "",
      };
    }
    self.setState(
      { selectedExportedLine: index, ExportForm: ExportForm },
      function () {
        self.setState({ ExportedModal: true });
      }
    );
  }
  onOpenChangingModal(index) {
    const self = this;
    let rowData = this.state.formData.rows[index];
    let subItemData = this.state.formData.rows[index].subItems;
    if (rowData.item_id !== 0) {
      if (subItemData === undefined || subItemData.length === 0) {
        Api.get(
          "/subItem/showAllSalesInvoices/" +
            rowData.item_id +
            "/" +
            rowData.id ?? 0,
          function (res, err) {
            if (res.status === "fail") {
              self.setState({ ChangingModalData: [] });
              toastr.warning(res.message);
            } else if (res.status === "ok") {
              self.setState({
                selectedChangingLine: index,
                ChangingModalData: res.items,
                ChangingModal: true,
                ChangingModalRowData: rowData,
              });
            }
          }
        );
      } else {
        self.setState({
          selectedChangingLine: index,
          ChangingModalData: subItemData,
          ChangingModal: true,
          ChangingModalRowData: rowData,
        });
      }
    } else {
      toastr.warning("Lütfen önce ürün seçin.");
    }
  }
  onChangingModalSave(data) {
    let formData = this.state.formData;
    const index = this.state.selectedChangingLine;
    formData.rows[index].subItems = data;
    this.setState({
      ChangingModal: false,
      ChangingModalData: [],
      formData: formData,
    });
  }
  onExportFormSave(data) {
    let formData = this.state.formData;
    const index = this.state.selectedExportedLine;
    formData.rows[index].exportedLines = data.item;
    this.setState({
      ExportedModal: false,
      formData: formData,
    });
  }
  fetchWarehouses = async () => {
    let { data } = await Api.getAsync("/warehouses/showAll");
    return (data && data.items) || [];
  };
  fetchWarehousesToItem = async (itemId) => {
    let { data } = await Api.getAsync(
      "/warehouses/showAllWarehouseItems/" + itemId
    );
    return (data && data.items) || [];
  };
  fetchItems(setFirstItem) {
    const self = this;
    if (self.props.documentType === 4){
      let tableData = self.state.tableData;
      tableData.headers[0].label = "Demirbaş Seçin";
      tableData.rows.map((row) => {
        row[0].data = JSON.parse(localStorage.getItem("fixed_asset_list"));
        row[0].onAddNewItemClick = false;
        row[0].selectedOptionValue = "asset_name";
        row[0].optionValue = "asset_name";
        row[0].optionId = "id";
        row[0].searchFilter = "asset_name";
        row[0].defaultKey = "asset_name";
        row[0].id = "asset_id";

        return row;
      });
    }else{
      Api.get("/item/showAllShortVersion", function (response, err) {
        let items = response.items.map(function (item) {
          item.codeName = item.item_code + ":" + item.item_name;
          return item;
        });
        items = items.filter(function (item) {
          if (self.props.documentType === 1) return item.is_sales === 1;
          else return item.is_purchase === 1;
        });
        let stateItems = {};
        items.forEach(function (item, index) {
          stateItems[item.id] = item;
        });

        let tableData = self.state.tableData;
        tableData.rows = tableData.rows.map(function (row, index) {
          row[0]["data"] = items;
          return row;
        });

        const index = self.state.selectedLine || 0;
        tableData.rows[index][0]["data"] = items;
        tableData.rows[index][2]["data"] = items.map((t) => ({
          ...t,
          description:
              t[
                  self.props.documentType === 1
                      ? "sales_description"
                      : "purchase_description"
                  ],
        }));
        self.setState({ tableData: tableData, items: stateItems }, function () {
          if (setFirstItem) {
            let formData = self.state.formData;
            formData.rows[index].item_id = items[0].id;
            formData.rows[index].item_code = items[0].item_code;
            self.setState({ formData: formData }, function () {
              self.onInvoiceLineChange(index, formData.rows[index], "item_id");
            });
          }
        });
      });
    }

  }

  onAddNewItemClick(index) {
    this.setState({ selectedLine: index, showAddItem: true });
  }

  onItemSaved() {
    this.setState({ showAddItem: false });
    this.fetchItems(true);
  }

  componentWillMount() {
    var self = this;
    this.fetchItems(false);
    this.getNewTagModalList();
    if (this.state.warehouses.length === 0) {
      this.fetchWarehouses().then((warehouses) => {
        let tableData = this.state.tableData;
        tableData.rows = tableData.rows.map(function (row, index) {
          if (self.state.formData.rows[index].item_id) {
            self
              .fetchWarehousesToItem(self.state.formData.rows[index].item_id)
              .then((warehouses1) => {
                row[1]["data"] = warehouses1;
              });
          } else {
            row[1]["data"] = warehouses;
          }
          self.onInvoiceLineChange(
            index,
            self.state.formData.rows[index],
            "warehouse_id"
          );
          return row;
        });
        this.setState({
          warehouses,
          tableData,
        });
      });
    }
    try {
      this.prepareFormToPost(this.state.formData);
      let tableData = this.state.tableData;
      if (this.props.documentType === 2) {
        //tableData.headers[10].hide = true;
        this.setState({ tableData: tableData });
      }
    } catch (ex) {
      console.log(ex);
    }
    this.fetchAllTags();
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    this.props.fetchData("/employee/showAll", FETCH_EMPLOYEES_ALL);
  }

  componentWillReceiveProps(nextProps) {
    const self = this;

    if (nextProps.formData) {
      if (nextProps.formData.invoice.irsaliye_rows) {
        this.setState({
          irsaliyeRows: { rows: nextProps.formData.invoice.irsaliye_rows },
        });
      }
    }

    if (
      JSON.stringify(nextProps.formData) !== JSON.stringify(this.props.formData)
    ) {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            ...nextProps.formData,
            invoice: {
              ...this.state.formData.invoice,
              ...nextProps.formData.invoice,
            },
          },
        },
        function () {
          self.prepareFormToPost(this.state.formData);
          self.renewTableData();
        }
      );
    }
    if (
      nextProps.vatRateTypes &&
      nextProps.vatReductionTypeCodes &&
      nextProps.units
    ) {
      let tableData = this.state.tableData;
      if (nextProps.documentType === 2) {
        //tableData.headers[10].hide = true;
        //tableData.rows[0][10].hide = true;
      }
      tableData.rows[0][8]["data"] = nextProps.vatRateTypes;
      tableData.rows[0][5]["data"] = nextProps.units;
      if (this.props.router.params.id) {
        this.state.is_exported = this.state.formData.invoice.is_exported;
        this.toggle(this.state.formData.invoice.is_exported);

        this.state.is_exported2 = this.state.formData.invoice.is_exported2;
        this.toggle2(this.state.formData.invoice.is_exported2,false);
      }
      this.state.sgk_dosya_no = this.state.formData.invoice.sgk_file_no;
      this.state.sgk_donem = this.state.formData.invoice.sgk_period;
      var istisnaKodu = 0;
      this.state.formData.rows.forEach(function (row) {
        if (row.vat_rate_type_id == 1) {
          if (row.istisna_kodu !== null) {
            if (row.istisna_kodu !== undefined) {
              istisnaKodu = row.istisna_kodu.toString();
            }
          }
        }
      });
      this.setState({ tableData: tableData, reason_id: istisnaKodu });
    }
    if (nextProps.calculate && !this.props.calculate) {
      this.prepareFormToPost(nextProps.formData);
      this.setState({ calculate: false });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;
    if (
      formData &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        let row = this.defaultRows()[0];
        row[0].data = tableData.rows[0][0].data;
        row[8]["data"] = nextProps.vatRateTypes;
        row[5]["data"] = nextProps.units;
        tableData.rows.push(row); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = nextProps.errors;
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
      const self = this;
      this.setState({ tableData: tableData, errors: errors }, function () {
        self.renewTableData();
      });
    } else if (
      formData &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({
        errors: this.props.defaultErrors(),
        tableData: tableData,
      });
    }
  }

  prepareFormToPost = (formData, shouldUseNetAmount = true) => {
    let calculatedFormData = {};
    const self = this;
    if (formData.invoice) {
      calculatedFormData.invoice = { ...formData.invoice };
      calculatedFormData.invoice.relatedTags = this.state.relatedTags;

      calculatedFormData.invoice.document_type = this.props.documentType;
      // TODO: kontrol edilecek
      let desc1 = calculatedFormData.invoice.legal_no
        ? calculatedFormData.invoice.legal_serial +
          calculatedFormData.invoice.legal_no +
          " nolu "
        : "";
      let desc2 =
        this.props.documentType === 1 || this.props.documentType === 4 ? "Satış Faturası" : "İade Faturası";
      calculatedFormData.invoice.description = desc1 + desc2;
      if (this.props.documentType === 1 || this.props.documentType === 4) {
        calculatedFormData.invoice.customer_id = Util.parseInt(
          calculatedFormData.invoice.customer_id
        );
        calculatedFormData.invoice.supplier_id = null;
      } else {
        calculatedFormData.invoice.supplier_id = Util.parseInt(
          calculatedFormData.invoice.supplier_id
        );
        calculatedFormData.invoice.customer_id = null;
      }
      calculatedFormData.invoice.currency_rate = Util.convertMoneyToDecimal(
        calculatedFormData.invoice.currency_rate
      );
      calculatedFormData.invoice.date = calculatedFormData.invoice.date
        ? moment(calculatedFormData.invoice.date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.due_date = calculatedFormData.invoice.due_date
        ? moment(calculatedFormData.invoice.due_date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.shipping_date = calculatedFormData.invoice
        .shipping_date
        ? moment(calculatedFormData.invoice.shipping_date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.sgk_file_no = this.state.sgk_dosya_no;
      calculatedFormData.invoice.sgk_period = this.state.sgk_donem;
    }

    let footer = {
      price_amount: 0,
      price_amount_fc: 0,
      discount_amount: 0,
      discount_amount_fc: 0,
      net_amount: 0,
      net_amount_fc: 0,
      taxes_sbjvat_amount: 0,
      taxes_sbjvat_amount_fc: 0,
      vat_amount: 0,
      vat_amount_fc: 0,
      taxes_excvat_amount: 0,
      taxes_excvat_amount_fc: 0,
      total_amount: 0,
      total_amount_fc: 0,
      vat_reduction_amount: 0,
      vat_reduction_amount_fc: 0,
      stoppage_amount: 0,
      stoppage_amount_fc: 0,
      receivable_amount: 0,
      receivable_amount_fc: 0,
    };

    if (formData.rows) {
      const currencyRate = calculatedFormData.invoice.currency_rate || 1;
      calculatedFormData.rows = formData.rows.map((frow) => {
        let row = { ...frow };
        if (row.item_id === 0) row.item_id = null;
        if (row.asset_id === 0) row.asset_id = null;

        row.quantity = Util.convertMoneyToDecimal(row.quantity);

        row.discount_amount_fc = Util.convertMoneyToDecimal(
          row.discount_amount_fc
        );
        row.discount_amount = row.discount_amount_fc * currencyRate;

        row.unit_price_fc = UtilHelper.money.convertMoneyToDecimal8(
          row.unit_price_fc
        );
        row.unit_price = row.unit_price_fc * currencyRate;

        row.net_amount_fc = Util.convertMoneyToDecimal(row.net_amount_fc);
        row.net_amount = row.net_amount_fc * currencyRate;

        row.discount_rate = Util.convertPercentToNumber(row.discount_rate);

        row.vat_amount_fc = Util.convertMoneyToDecimal(row.vat_amount_fc);


        if (row.vat_rate_type_id && !row.vat_rate) {
          const vatRate = Sugar.Array.find(
            self.props.vatRateTypes,
            function (type) {
              return type.id === row.vat_rate_type_id;
            }
          );
          if (vatRate) row.vat_rate = vatRate.rate;
        } else row.vat_rate = row.vat_rate || 0;
        row.vat_amount = currencyRate * ((row.unit_price_fc*row.quantity-row.discount_amount_fc)) * row.vat_rate;
        //new
        // bu kod vergi oranı değişitiğinde sorun çıkartıyor

        if (shouldUseNetAmount === false) {
          row.with_tax_amount_fc = Util.convertMoneyToDecimal(
            row.with_tax_amount_fc
          );

          row.with_tax_amount = row.with_tax_amount_fc * currencyRate;

          row.net_amount_fc = row.with_tax_amount_fc / (1 + row.vat_rate);

          row.net_amount = row.net_amount_fc * currencyRate;
        } else {
          //  eğer vergi tipi değiştiyse hesaplamalar net_amount üzerinden yapılmalı
          row.with_tax_amount_fc =
              row.net_amount_fc + row.vat_amount_fc;
          row.with_tax_amount = row.net_amount * currencyRate + row.vat_amount;
        }

        //new end

        row.taxes_sbjvat_amount = 0;
        row.taxes_excvat_amount = 0;
        row.taxes_sbjvat_amount_fc = 0;
        row.taxes_excvat_amount_fc = 0;
        row.stoppage_amount_fc = 0;
        row.vat_reduction_amount_fc = 0;
        row.vat_reduction_amount = 0;

        if (row.otherTaxes) {
          let otherTaxes = [];
          row.otherTaxes.forEach(function (rowOtherTax) {
            let otherTax = { ...rowOtherTax };
            otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
              otherTax.tax_amount_fc
            );
            otherTax.tax_rate = Util.convertPercentToNumber(otherTax.tax_rate);

            if (otherTax.tax_type_code === "9015")
              otherTax.tax_amount_fc = otherTax.tax_rate * row.vat_amount_fc;
            else otherTax.tax_amount_fc = otherTax.tax_rate * row.net_amount_fc;

            otherTax.tax_amount = otherTax.tax_amount_fc * currencyRate;

            if (otherTax.tax_type_id && !otherTax.subject_to_vat) {
              otherTax.subject_to_vat = self.getTaxSubjectToVat(
                otherTax.tax_type_id
              );
              otherTax.reduction_type_id = self.getTaxReductionType(
                otherTax.tax_type_id
              );
            }
            if (otherTax.subject_to_vat === 1) {
              row.taxes_sbjvat_amount_fc += otherTax.tax_amount_fc;
              row.taxes_sbjvat_amount += otherTax.tax_amount;
              row.vat_amount = currencyRate * ((row.unit_price_fc*row.quantity-row.discount_amount_fc)+row.taxes_sbjvat_amount_fc) * row.vat_rate;
            } else if (
              !otherTax.vat_reduction_type_code &&
              otherTax.reduction_type_id === 0
            ) {
              row.taxes_excvat_amount_fc += otherTax.tax_amount_fc;
              row.taxes_excvat_amount += otherTax.tax_amount;
            }

            if (otherTax.reduction_type_id === 1)
              row.stoppage_amount_fc += otherTax.tax_amount_fc;

            if (otherTax.tax_type_code === "9015") {
              row.vat_reduction_amount_fc = Util.convertMoneyToDecimal(
                Util.convertDecimalToMoney(
                  otherTax.tax_rate * row.vat_amount_fc
                )
              );
              row.vat_reduction_amount = Util.convertMoneyToDecimal(
                  Util.convertDecimalToMoney(
                      otherTax.tax_rate * row.vat_amount
                  )
              );
              // otherTax.tax_rate * row.vat_amount_fc;
              //row.taxes_excvat_amount += row.vat_amount * otherTax.tax_rate;
            }
            if (otherTax.tax_type_code === "OZELMATRAH") {
              otherTax.tax_amount = Util.convertMoneyToDecimal(
                rowOtherTax.tax_amount_fc
              );
              otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
                rowOtherTax.tax_amount_fc
              );
              var taxVatRate =
                Util.convertMoneyToDecimal(
                  parseInt(rowOtherTax.tax_rate.replace("%", ""))
                ) / 100;
              footer.vat_amount +=
                Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                taxVatRate;
              footer.vat_amount_fc +=
                Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                taxVatRate;
            }
            otherTaxes.push(otherTax);
          });
          delete row.otherTaxes;
          row.otherTaxes = otherTaxes;
        } else row.otherTaxes = [];

        if (row.otherTaxes.length != 0) {
          if (row.otherTaxes[0].tax_type_code == "OZELMATRAH") {
            row.total_amount_fc = row.net_amount_fc;
            row.total_amount = row.net_amount;
          } else {
            row.total_amount_fc =
              row.net_amount_fc +
              row.vat_amount_fc +
              row.taxes_sbjvat_amount_fc +
              row.taxes_excvat_amount_fc;

            row.total_amount = row.net_amount+row.vat_amount+row.taxes_sbjvat_amount + row.taxes_excvat_amount;
          }
        } else {
          if(this.state.is_exported2===false){
            //@oguz ihraç kayıtlı seçilmişse kdv yi toplama ekleme
            row.total_amount_fc = row.net_amount_fc ;
            row.total_amount = row.total_amount_fc * currencyRate;
          }else{
            row.total_amount_fc =
                row.net_amount_fc +
                row.vat_amount_fc +
                row.taxes_sbjvat_amount_fc +
                row.taxes_excvat_amount_fc;
            row.total_amount = row.net_amount+row.vat_amount+row.taxes_sbjvat_amount??0 + row.taxes_excvat_amount??0;
          }
        }

        row.taxes_sbjvat_amount = row.taxes_sbjvat_amount_fc * currencyRate;
        //row.taxes_excvat_amount = row.taxes_excvat_amount_fc * currencyRate;
        row.stoppage_amount = row.stoppage_amount_fc * currencyRate;
        //row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;

        footer.price_amount +=  parseFloat(row.net_amount.toFixed(2)) + row.discount_amount;
        footer.price_amount_fc += row.net_amount_fc + row.discount_amount_fc;
        footer.net_amount += parseFloat(row.net_amount.toFixed(2));
        footer.net_amount_fc += row.net_amount_fc;
        footer.discount_amount += row.discount_amount;
        footer.discount_amount_fc += row.discount_amount_fc;
        if (row.otherTaxes.length != 0) {
          if (row.otherTaxes[0].tax_type_code != "OZELMATRAH") {
            footer.vat_amount += row.vat_amount;
            footer.vat_amount_fc += row.vat_amount_fc;
          } else {
            footer.vat_amount += 0;
            footer.vat_amount_fc += 0;
          }
        } else {
          footer.vat_amount += row.vat_amount;
          footer.vat_amount_fc += row.vat_amount_fc;
        }
        footer.taxes_sbjvat_amount += row.taxes_sbjvat_amount;
        footer.taxes_sbjvat_amount_fc += row.taxes_sbjvat_amount_fc;
        footer.taxes_excvat_amount += row.taxes_excvat_amount;
        footer.taxes_excvat_amount_fc += row.taxes_excvat_amount_fc;
        footer.total_amount += parseFloat(row.total_amount.toFixed(2));
        footer.total_amount_fc += row.total_amount_fc;
        footer.vat_reduction_amount += row.vat_reduction_amount;
        footer.vat_reduction_amount_fc += row.vat_reduction_amount_fc;
        footer.stoppage_amount += row.stoppage_amount;
        footer.stoppage_amount_fc += row.stoppage_amount_fc;

        return row;
      });
    }

    footer.receivable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.receivable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;
    footer.discount_rate =
      Math.round((footer.discount_amount_fc / footer.price_amount_fc) * 10000) /
      10000;
    footer.received_amount_fc = 0;
    footer.received_amount = 0;
    calculatedFormData.footer = footer;

    let tableData = this.state.tableData;

    Object.keys(footer).map((key) => {
      if (footer[key] > 0) {
        tableData.footerTable.map((tableEl) => {
          if (tableEl.id === key) {
            tableEl.visible = true;
          }

          return tableEl;
        });
      }

      return (tableData.footerData[key] = Util.convertDecimalToMoney(
        footer[key]
      ));
    });
    this.setState({ tableData: tableData });

    return calculatedFormData;
  };

  onChange(type, field, value) {
    let formData = { ...this.state.formData };

    const self = this;

    const prevForm = { ...this.state.formData };

    formData[type] = {
      ...formData[type],
      [field]: value,
    };

    let data = { formData: formData, prevForm };

    if (field === "warehouse_id") {
      formData.rows.forEach(function (row, index) {
        formData.rows[index]["warehouse_id"] = value;
        self.onInvoiceLineChange(index, formData.rows[index], "warehouse_id");
      });
    }
    if (field === "address" || field === "address2") {
      data.saved = false;
    }
    if (type === "sgk_dosya_no") {
      this.setState({ sgk_dosya_no: field });
    }
    if (type === "sgk_donem") {
      this.setState({ sgk_donem: field });
    }
    if (type === "reasons") {
      this.setState({ reason_id: field });
    }

    self.setState(data, function () {
      self.resetErrorForInvoice(field);
    });
  }

  onChangeSet = (type, data) => {
    if (typeof data.address !== "undefined") {
      data.saved = false;
    }

    let formData = {
      ...this.state.formData,
      [type]: {
        ...this.state.formData[type],
        ...data,
      },
    };

    this.setState({
      formData,
    });
  };

  onRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  bankCurrencyAndMethod = (data, data2) => {
    this.setState({ bank_currency: data, method: data2 });
  };

  handleSuccess(redirect = false) {
    const self = this;
    let formData = self.state.formData;
    toastr.success("Başarılı şekilde kaydedildi");
    self.setState({ saving: false, saved: true });
    //if (!self.props.router.params.id) check here if a strange bug happens
    if (!this.props.router.params.id) {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        let tableData = this.state.tableData;
        tableData = Object.assign(tableData, {
          footerData: {
            discount_amount: "0,00",
            discount_amount_fc: "0,00",
            discount_rate: "0,00",
            net_amount: "0,00",
            net_amount_fc: "0,00",
            price_amount: "0,00",
            price_amount_fc: "0,00",
            receivable_amount: "0,00",
            receivable_amount_fc: "0,00",
            received_amount: "0,00",
            received_amount_fc: "0,00",
            stoppage_amount: "0,00",
            stoppage_amount_fc: "0,00",
            taxes_excvat_amount: "0,00",
            taxes_excvat_amount_fc: "0,00",
            taxes_sbjvat_amount: "0,00",
            taxes_sbjvat_amount_fc: "0,00",
            total_amount: "0,00",
            total_amount_fc: "0,00",
            vat_amount: "0,00",
            vat_amount_fc: "0,00",
            vat_reduction_amount: "0,00",
            vat_reduction_amount_fc: "0,00",
          },
        });

        this.setState(
          {
            tableData: tableData,
            formData: self.props.defaultFormData(),
          },
          function () {
            self.fetchItems(false);
            self.renewTableData();
          }
        );
      }
    } else {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        if (formData.invoice.customer_id) {
          self.props.router.push("/dashboard/salesInvoice/add/toCustomer");
        } else {
          self.props.router.push("/dashboard/salesInvoice/add/toSupplier");
        }
      }

      if (self.props.onSave) {
        self.props.onSave();
      }
    }
  }

  collectionApiAdd(sales_invoice_id) {
    let formData = this.state.formData;

    if (formData.invoice.radioButtons === "paid") {
      var title = JSON.parse(localStorage.getItem("user"));

      let company_id = title["company_id"];

      let bank_currency = this.state.bank_currency;

      let receivable_amount =
        this.state.tableData.footerData.receivable_amount.split(".");
      let receivable_amount_fc =
        this.state.tableData.footerData.receivable_amount_fc.split(".");

      let new_receive_amount = "";
      for (let i = 0; i < receivable_amount.length; i++) {
        new_receive_amount += receivable_amount[i];
      }

      let new_receive_amount_fc = "";
      for (let i = 0; i < receivable_amount_fc.length; i++) {
        new_receive_amount_fc += receivable_amount_fc[i];
      }

      let collection = Object.assign(
        {},
        {
          company_id: company_id,
          date: this.state.formData.invoice.date1,
          amount:
            bank_currency === "TRY"
              ? new_receive_amount.replace(",", ".")
              : new_receive_amount_fc.replace(",", "."),
          currency: this.state.formData.invoice.currency,
          currency_rate: Util.convertMoneyToDecimal(
            this.state.formData.invoice.currency_rate
          ),
          amount_fc:
            bank_currency !== "TRY"
              ? new_receive_amount_fc.replace(",", ".")
              : new_receive_amount_fc.replace(",", "."),
          received_amount: 0,
          received_amount_fc: 0,
          description: "Fatura Tahsilatı",
          type: 1,
          type_source_id: this.state.formData.invoice.customer_id,
          method: this.state.method,
          method_source_id: this.state.formData.invoice.method_source_id,
          currency_rate_bank: Util.convertMoneyToDecimal(
            this.state.formData.invoice.currency_rate
          ),
          sales_invoice_id: sales_invoice_id,
        }
      );

      let func = "post";
      let url = "/collection";

      Api[func](url, collection, function (response, postErrors) {
        if (response) {
          console.log("işlem başarılı");
        } else {
          console.log("burada bir sorun var");
        }
      });
    }
  }
  shipmentApiAdd(shipment_id, sales_invoice_id) {
    if (shipment_id) {
      Api.get(
        "/salesShipment/addCallback/" + shipment_id + "/" + sales_invoice_id,
        function (res, error) {}
      );
    }
  }

  onSave(refresh, isChecked) {
    const redirect = arguments[0];
    const self = this;
    let formData = self.state.formData;

    if (formData.invoice.legal_serial) {
      formData.invoice.legal_serial =
        formData.invoice.legal_serial.toUpperCase();
    }
    if (!Util.companyModules(2)) {
      formData.invoice.stock_status = 0;
    }
    if (formData.invoice.sales_types === undefined) {
      formData.invoice.sales_types = this.props.documentType === 1 || this.props.documentType === 4 ? 0 : 3;
    }
    if (this.props.formData.id !== undefined) {
      delete formData.invoice.sales_types;
    }
    const baseCurrency = formData.invoice.currency;
    for (var fi in formData.rows) {
      const items = this.state.tableData.rows[fi][0].data;
      for (var i in items) {
        if (items[i].id === formData.rows[fi].item_id) {
          // tedarikçiye iade
          // if (this.props.documentType === 2) {
          //   if (items[i].currency_purchase !== baseCurrency) {
          //     toastr.error("Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır.");
          //     return;
          //   }
          // } else {
          //   if (items[i].currency_sales !== baseCurrency) {
          //     toastr.error("Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır.");
          //     return;
          //   }
          // }
        }
      }
    }

    self.setState({ saving: true });

    self.validateInvoiceLines(true, function (linesOk) {
      if (linesOk) {
        if (
            !self.state.passModal && (
          formData.rows.filter((r) => !r.item_id && !r.unSelected).length > 0 &&
          !self.props?.editId &&
          !formData.invoice.sales_types &&
          self.props.documentType !== 4 )
        ) {
          self.setState({
            itemModalValues: { showItemModal: true, refresh, isChecked },
          });
          return;
        }
        self.saveInvoice(function (invoiceOk) {
          if (invoiceOk) {
            let formData = self.state.formData;
            formData.rows = formData.rows.map(function (row) {
              row.sales_invoice_id = formData.invoice.id;
              return row;
            });

            self.collectionApiAdd(formData.rows[0].sales_invoice_id);
            const {
              router: {
                location: { query },
              },
            } = self.props;
            if (query.shipment) {
              self.shipmentApiAdd(
                query.shipment,
                formData.rows[0].sales_invoice_id
              );
            }

            self.saveInvoiceLines(function (invoiceLinesSaved) {
              let calculatedFormData = self.prepareFormToPost(
                self.state.formData
              );
              calculatedFormData.rows.forEach(function (row) {
                row.otherTaxes = row.otherTaxes.map(function (tax) {
                  tax.sales_invoice_line_id = row.id;
                  tax.sales_invoice_id = calculatedFormData.invoice.id;
                  return tax;
                });

                self.saveTaxLines(row.otherTaxes, function (taxLinesOk) {
                  if (taxLinesOk) {
                    if (isChecked === true) {
                      Api.get(
                        `/salesInvoice/next/${self.props?.editId}`,
                        function (res, error) {
                          if (res.item) {
                            const path = window.location.pathname;
                            const ex = path.split("/");
                            ex.pop();
                            ex.push(res.item.id);
                            self.setState({
                              saving: false,
                              saved: true,
                            });
                            window.location.href = ex.join("/");
                          } else {
                            self.handleSuccess(true);
                          }
                        }
                      );
                    } else {
                      self.handleSuccess(redirect);
                    }
                  }
                });
              });
            });
          } else {
            self.setState({ saving: false });
            console.log("invoice not validated");
          }
        }, isChecked);
      } else {
        self.setState({ saving: false });
        console.log("lines not validated");
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  renewTableData(data) {
    const self = this;
    let tableData = this.state.tableData;
    const formData = data || this.state.formData;
    //console.log('tableData.rows',tableData.rows);
    tableData.rows.forEach(function (row, index) {
      //tableData.rows[index][10].onClick = self.onOtherTaxClick.bind(self,index)
      const taxCount = formData.rows[index].otherTaxes
        ? formData.rows[index].otherTaxes.length
        : 0;
      tableData.rows[index][11].props = { itemCount: taxCount, warning: "" };
      tableData.rows[index][11].onRender = self.invoiceLineButtonGroup.bind(
        self,
        index
      );
      if (self.props.documentType !== 4) {
        tableData.rows[index][0].onAddNewItemClick = self.onAddNewItemClick.bind(
            self,
            index
        );
      }
      tableData.rows[index][2].onSelect = self.onSelectDescription.bind(
        self,
        index
      );
      tableData.rows[index][12].onClick = self.onOpenExportedModal.bind(
        self,
        index
      );
      tableData.rows[index][13].onClick = self.onOpenChangingModal.bind(
        self,
        index
      );
      tableData.rows[index][8]["data"] = self.props.vatRateTypes;
      tableData.rows[index][5]["data"] = self.props.units;

      if (formData.rows[index]["item_id"]) {
        tableData.rows[index][5].disabled = true;
      } else tableData.rows[index][5].disabled = false;
    });
    self.setState({ tableData: tableData });
  }

  onInvoiceLineRemoved(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = Sugar.Array.removeAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.invoice.id && row.id) {
      deleteRowIds.push(row.id);
    }

    this.prepareFormToPost(this.state.formData);
    this.renewTableData();
  }

  onInvoiceLineAdded() {
    var formData = this.state.formData;
    formData.rows[formData.rows.length - 1]["warehouse_id"] =
      formData.invoice.warehouse_id ?? 0;
    this.setState({
      formData: {
        ...formData,
        rows: [...formData.rows],
      },
    });
    this.renewTableData();
    this.validateInvoiceLines(false, null);
  }

  onInvoiceChange() {
    // todo
  }

  onTaxRowChange() {
    ///
  }

  onTaxChange() {
    //
  }

  onTaxFormSave(taxForm) {
    let formData = this.state.formData;
    let tableData = this.state.tableData;
    let deleteTaxIds = this.state.deleteTaxIds;
    const index = this.state.selectedLine;
    let row = formData.rows[index];
    if (!taxForm) {
      tableData.rows[index][11]["props"]["itemCount"] = 0;
      row.otherTaxes.map((item) => deleteTaxIds.push(item.id));
      row.otherTaxes = false;
    } else {
      row.otherTaxes = [...taxForm.rows];
      tableData.rows[index][11]["props"]["itemCount"] = row.otherTaxes.length;
      if (taxForm.deleteRowIds) {
        taxForm.deleteRowIds.forEach(function (id) {
          deleteTaxIds.push(id);
        });
      }
    }

    formData.rows[index] = row;
    const calculatedFormData = this.prepareFormToPost(formData);
    calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
      calculatedFormData.rows[index]
    );
    formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
      calculatedFormData.rows[index].vat_amount_fc
    );
    this.prepareFormToPost(formData);
    this.setState({
      showAddTax: false,
      formData: formData,
      tableData: tableData,
      deleteTaxIds: deleteTaxIds,
    });
  }

  getDiscountAmount(rowData) {
    return rowData.unit_price_fc * rowData.quantity * rowData.discount_rate;
  }

  getNetAmount(rowData) {
    return (
      rowData.unit_price_fc * rowData.quantity - rowData.discount_amount_fc
    );
  }

  getWithTaxAmount(rowData) {
    return this.getNetAmount(rowData) + this.getVatAmount(rowData);
  }

  calculateNetAmountByWithTax(rowData) {
    return rowData.with_tax_amount_fc / (1 + rowData.vat_rate);
  }

  calculateUnitPrice(rowData) {
    return (
      (this.calculateNetAmountByWithTax(rowData) + rowData.discount_amount_fc) /
      rowData.quantity
    );
  }

  getDiscountRate(rowData) {
    return (
      rowData.discount_amount_fc / (rowData.unit_price_fc * rowData.quantity)
    );
  }

  getVatAmount(rowData) {
    console.log(rowData);

    return (
      (rowData.taxes_sbjvat_amount_fc + rowData.net_amount_fc) *
      rowData.vat_rate
    );
  }

  resetErrorForInvoiceLine(index, field) {
    let errors = this.state.errors;
    let tableData = this.state.tableData;
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;
    if (field === "vat_amount_fc" || field === "net_amount_fc") {
      if (tableData.rows[index][11]["props"]["itemCount"] > 0)
        toastr.warning("İlişkili diğer vergilerini de kontrol ediniz",{ disableCloseButtonFocus: true});
    }
    this.setState({ errors: errors });
  }

  resetErrorForInvoice(field) {
    let errors = this.state.errors;
    errors.invoice[field] = false;

    this.setState({ errors: errors });
  }

  onInvoiceLineReallyChange(index, field, value, oldValue) {
    if (!this.state.formData.inXml) {
      this.resetErrorForInvoiceLine(index, field);
      if (field === "item_id") {
        this.resetErrorForInvoiceLine(index, "vat_rate_type_id");
        this.resetErrorForInvoiceLine(index, "unit_price_fc");
        this.resetErrorForInvoiceLine(index, "unit_id");
        this.resetErrorForInvoiceLine(index, "description");
      }

      if (
        field === "item_id" ||
        field === "unit_price_fc" ||
        field === "quantity" ||
        field === "discount_rate"
      ) {
        this.resetErrorForInvoiceLine(index, "discount_amount_fc");
        this.resetErrorForInvoiceLine(index, "net_amount_fc");
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }

      if (field === "discount_amount_fc") {
        this.resetErrorForInvoiceLine(index, "discount_rate");
        this.resetErrorForInvoiceLine(index, "net_amount_fc");
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }

      if (field === "vat_rate_type_id") {
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }
    }
  }

  onInvoiceLineChange(index, rowData, field, oldRowData) {
    const self = this;
    let formData = { ...this.state.formData };
    //  self.resetErrorForInvoiceLine(index,field)
    var emptyString = true;
    if (formData.rows[index].net_amount_fc != "0,00" && field === "item_id") {
      emptyString = false;
    }
    if (field === "item_id" && !formData.inXml && emptyString === true) {
      let items = this.state.items;
      let itemId = rowData.item_id;

      formData.rows[index] = { ...formData.rows[index] };
      /*if(items[itemId].currency_sales!==this.state.formData.invoice.currency){
      window.alert(" onInvoiceLineChange Seçmiş olduğunuz ürünün döviz tipi fatura tipi ile aynı olmak zorundadır");

      formData = {...this.state.prev}
      this.setState({formData})

      return;
      }*/
      formData.rows[index].description = items[itemId].item_name;
      formData.rows[index].item_code = items[itemId].item_code;
      if (self.props.documentType === 1 && items[itemId].sales_description)
        formData.rows[index].description +=
          " - " + items[itemId].sales_description;
      else if (
        self.props.documentType === 2 &&
        items[itemId].purchase_description
      )
        formData.rows[index].description +=
          " - " + items[itemId].purchase_description;
      if (self.props.documentType === 1) {
        formData.rows[index].unit_price_fc =
          UtilHelper.money.convertDecimalToMoney(
            items[itemId].sales_unit_price
          );
        formData.rows[index].vat_rate_type_id =
          items[itemId].sales_vat_rate_type_id;
      } else if (self.props.documentType === 2) {
        formData.rows[index].unit_price_fc =
          UtilHelper.money.convertDecimalToMoney(
            items[itemId].purchase_unit_price
          );
        formData.rows[index].vat_rate_type_id =
          items[itemId].purchase_vat_rate_type_id;
      }

      formData.rows[index].unit_id = items[itemId].unit_id;
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      let tableData = this.state.tableData;
      tableData.rows[index][5].disabled = true;
      this.setState({ tableData: tableData });
      self.onInvoiceLineChange(index, formData.rows[index], "vat_rate_type_id");
    }
    if (field === "item_id") {
      let items = this.state.items;
      var ExportForm = ExportedModal.defaultData();
      let itemId = formData.rows[index].item_id || rowData.item_id;
      let getItemData = items[itemId];
      ExportForm.item = {
        delivery_type_id: getItemData.delivery_type_id,
        transport_type_id: getItemData.transport_type_id,
        package_type_id: getItemData.package_type_id,
        customs_tracking_no: getItemData.customs_tracking_no,
        container_no: getItemData.container_no,
        number_of_containers: getItemData.number_of_containers,
        gtip: getItemData.gtip,
      };
      self.setState(
        { selectedExportedLine: index, ExportForm: ExportForm },
        function () {
          //self.setState({ ExportedModal: true }); @oguz item değişince export modalın açılması iptal edildi
          this.onExportFormSave(ExportForm);
        }
      );
      //
      this.fetchWarehousesToItem(itemId).then((warehouses) => {
        let tableData = this.state.tableData;
        tableData.rows[index][1]["data"] = warehouses;
        this.setState({ tableData });
      });
      //
      this.fetchItemTaxLines(itemId,index);
    }
    if (
      (emptyString === true && field === "item_id" && !formData.inXml) ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      this.setState({ countVat: this.state.countVat + 1 });
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].discount_amount_fc =
        this.getDiscountAmount(calculatedFormData.rows[index]);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].with_tax_amount_fc = this.getWithTaxAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].discount_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].with_tax_amount_fc
      );

      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "discount_amount_fc") {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);

      calculatedFormData.rows[index].discount_rate = this.getDiscountRate(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].with_tax_amount_fc = this.getWithTaxAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].discount_rate = Util.convertNumberToPercent(
        calculatedFormData.rows[index].discount_rate
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].with_tax_amount_fc
      );
    } else if (field === "vat_rate_type_id") {
      if (this.state.countVat > 0) {
        formData.rows[index][field] = rowData[field];
        const calculatedFormData = this.prepareFormToPost(formData);
        calculatedFormData.rows[index].discount_amount_fc =
          this.getDiscountAmount(calculatedFormData.rows[index]);
        calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
          calculatedFormData.rows[index]
        );
        calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
          calculatedFormData.rows[index]
        );
        calculatedFormData.rows[index].with_tax_amount_fc =
          this.getWithTaxAmount(calculatedFormData.rows[index]);

        formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].discount_amount_fc
        );
        formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].net_amount_fc
        );
        formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].with_tax_amount_fc
        );

        formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
          calculatedFormData.rows[index].vat_amount_fc
        );
      } else {
        formData.rows[index][field] = rowData[field];
        const vatRate = Sugar.Array.find(
          this.props.vatRateTypes,
          function (type) {
            return type.id === rowData[field];
          }
        );
        if (vatRate) {
          formData.rows[index].vat_rate = vatRate.rate;

          // hesaplar net_amount_fc üzerinden yapılnalı
          const calculatedFormDataq = this.prepareFormToPost(formData);

          calculatedFormDataq.rows[index].vat_amount_fc = this.getVatAmount(
            calculatedFormDataq.rows[index]
          );
          formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
            calculatedFormDataq.rows[index].vat_amount_fc
          );

          const newData = this.prepareFormToPost(formData);

          formData.rows[index].with_tax_amount_fc = Util.convertDecimalToMoney(
            newData.rows[index].with_tax_amount_fc
          );
        }
      }
    } else if (field === "with_tax_amount_fc") {
      formData.rows[index][field] = rowData[field];

      const calculatedFormData = this.prepareFormToPost(formData, false);

      calculatedFormData.rows[index].net_amount_fc =
        this.calculateNetAmountByWithTax(calculatedFormData.rows[index]);

      calculatedFormData.rows[index].unit_price_fc = this.calculateUnitPrice(
        calculatedFormData.rows[index]
      );

      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].unit_price_fc = Util.convertDecimalToMoney8x(
        calculatedFormData.rows[index].unit_price_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      if (!oldRowData.includes(',')) {
        oldRowData = oldRowData+",00";
      }
      if(formData.rows[index]['with_tax_amount_fc'] == oldRowData){
        self.onInvoiceLineChange(index, formData.rows[index], "quantity");
      }
    } else {
      formData.rows[index][field] = rowData[field];
    }
    if (field == "vat_rate_type_id") {
      if (rowData.vat_rate_type_id == 1 && this.state.reason_id == 0) {
        this.openIstisnaModal();
      }
    }
    var istisnaKontrol = false;
    this.state.formData.rows.forEach(function (row) {
      if (row.vat_rate_type_id == 1) {
        istisnaKontrol = true;
      }
    });
    if (istisnaKontrol == false) {
      this.setState({
        reason_id: 0,
        istisna_fatura_buton: "KDV İstisna Kodu Ekle",
      });
    }
    if(field==='unit_price_fc' && (rowData.unit_price_fc===oldRowData || rowData.unit_price_fc==='' || rowData.unit_price_fc===0 || rowData.unit_price_fc.match(/0/g).length>7)){
      let sayiStr = formData.rows[index]['unit_price_fc'].toString();
      sayiStr = sayiStr.replace(/0+$/, '');
      if(sayiStr.slice(-1)===',' || sayiStr.slice(-1)==='.'){
        sayiStr = sayiStr+'00';
      }
      formData.rows[index]['unit_price_fc']=sayiStr;
    }
    self.setState(
      {
        formData,
        linedAdded: true,
      },
      function () {
        self.prepareFormToPost(self.state.formData);
      }
    );
  }
  fetchItemTaxLines(id,index){
    let formData = { ...this.state.formData };
    Api.get("/item/getItemTaxLinesSalesPurchasePage/"+id, (res) => {
      if (res && res.rows.length>0) {
        formData.rows[index].otherTaxes = res.rows;
        this.setState({ formData });
        this.renewTableData(formData);
      }
    });
  };
  validateInvoiceLines(all, next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    const url = "/salesInvoiceLine/validation";
    let endpoints = [];
    calculatedFormData.rows.forEach(function (row) {
      endpoints.push({ endpoint: url, method: "post", params: row });
    });

    if (!all) {
      endpoints.pop(); //son eklenen rowu kontrol etme
    }

    Api.serialPutPost(endpoints, function (responses, errors) {
      if (errors.length === 0) {
        if (next !== null) next(true);
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function () {
          if (next !== null) next(false);
        });
      }
    });
  }

  deleteInvoiceLines(next) {
    const self = this;
    if (self.state.deleteRowIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteRowIds.forEach(function (id) {
        endpointWithParams.push({
          endpoint: "/salesInvoiceLine/" + id,
          method: "delete",
        });
      });

      Api.paralelAll(endpointWithParams, function (responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveInvoiceLines(next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);

    let formData = this.state.formData;
    let endpoints = [];
    var rowcount = 0;
    calculatedFormData.rows.forEach(function (row) {
      rowcount++;
      row.sortable_id = rowcount;
      row.istisna_kodu =
        self.state.reason_id == 0 ? null : self.state.reason_id;
      const method = row.id ? "put" : "post";
      const url = row.id ? "/salesInvoiceLine/" + row.id : "/salesInvoiceLine";
      endpoints.push({ endpoint: url, method: method, params: row });
    });

    Api.serialPutPost(endpoints, function (responses, errors) {
      if (errors.length === 0) {
        responses.forEach(function (response, index) {
          formData.rows[index].id = response.salesInvoiceLine.id;

          if (!!response.warnings) {
            response.warnings.forEach((warning) => {
              toastr.warning(warning);
            });
          }
        });
        self.setState({ formData: formData }, function () {
          if (next !== null) {
            self.deleteInvoiceLines(next);
          }
        });
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function () {
          if (next !== null) next(false);
        });

        if (errors.message) toastr.error(Util.getErrorAsStr(errors));
      }
    });
  }

  deleteTaxLines(next) {
    const self = this;
    if (self.state.deleteTaxIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteTaxIds.forEach(function (id) {
        endpointWithParams.push({
          endpoint: "/salesInvoiceTaxLine/" + id,
          method: "delete",
        });
      });

      Api.paralelAll(endpointWithParams, function (responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveTaxLines(otherTaxes, next) {
    const self = this;

    let endpoints = [];
    otherTaxes.forEach(function (tax) {
      // E-fatura gönderiminde bu alan frontend ile aynı değer olarak kayıt edilsin.
      tax.tax_amount = Util.convertMoneyToDecimal(
        Util.convertDecimalToMoney(tax.tax_amount)
      );
      tax.tax_amount_fc = Util.convertMoneyToDecimal(
        Util.convertDecimalToMoney(tax.tax_amount_fc)
      );
      const url = tax.id
        ? "/salesInvoiceTaxLine/" + tax.id
        : "/salesInvoiceTaxLine";
      const method = tax.id ? "put" : "post";
      endpoints.push({ endpoint: url, method: method, params: tax });
    });

    if (endpoints.length === 0) {
      self.deleteTaxLines(next);
      return next(true);
    }
    Api.serialPutPost(endpoints, function (responses, errors) {
      console.log(responses);
      if (errors.length === 0) {
        console.log(responses);
        self.deleteTaxLines(next);
      } else {
        errors.forEach(function (error) {
          toastr.error(Util.getErrorAsStr(error));
        });

        /*  let formErrors = self.state.errors
        formErrors.rows = errors
        self.setState({errors:formErrors},function(){
        if(next !==null) next(false)
        })*/
      }
    });
  }

  saveInvoice(next, isChecked) {
    const self = this;
    const formData = this.state.formData;

    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    let manualValidations = true;
    let formErrors = self.state.errors;

    const proformaId = formData.proformaId;

    const irsaliyeRows = this.state.irsaliyeRows.rows.map((row, index) => {
      return {
        ...row,
        irsaliye_date: Util.convertToDatabaseDate(row.irsaliye_date),
      };
    });

    if (this.irsaliye.checkErrors().length > 0) {
      toastr.error("Lütfen İrsaliye verinizi kontrol ediniz");
      self.setState({ saving: false });
      return;
    }

    if (calculatedFormData.footer.price_amount_fc === 0) {
      toastr.error("Toplam fatura tutarı sıfır olamaz");
      manualValidations = false;
    }

    if (!calculatedFormData.invoice.address) {
      formErrors.invoice.address = "Fatura adresi boş olamaz";
      manualValidations = false;
    }

    if (
      calculatedFormData.invoice.document_type === 1 &&
      !calculatedFormData.invoice.address2
    ) {
      formErrors.invoice.address2 = "Teslimat adresi boş olamaz";
      manualValidations = false;
    }

    if (this.state.formData.invoice.radioButtons === "paid") {
      if (this.state.formData.invoice.method_source_id === undefined) {
        toastr.error("İlişkili hesap boş olamaz");
        manualValidations = false;
      }
    }

    if (!manualValidations) return next(false);
    if(this.state.is_exported2===false){
      //@oguz ihraç kayıtlı seçilmişse kdv yi toplama ekleme
      formData.total_amount_fc = formData.net_amount_fc ;
      formData.total_amount = formData.net_amount;
      formData.receivable_amount_fc = formData.net_amount_fc ;
      formData.receivable_amount_fc = formData.net_amount;
    }

    let url = formData.invoice.id
      ? "/salesInvoice/" + formData.invoice.id
      : "/salesInvoice";

    const proformaData = proformaId ? { proforma_id: proformaId } : {};

    const method = formData.invoice.id ? "put" : "post";
    const postData = Object.assign(
      {},
      calculatedFormData.invoice,
      calculatedFormData.footer,
      proformaData,
      { irsaliye_rows: irsaliyeRows,newTagRows: this.state.newTagRows}
    );

    var rowAmountTotal = 0;
    calculatedFormData.rows.map(function (row) {
      rowAmountTotal += parseFloat(row.total_amount);
      return row;
    })
    console.log('fark',postData.total_amount - rowAmountTotal);
    if(postData.total_amount - rowAmountTotal>1){
        toastr.error("Beklenmeyen bir hata oluştu. Satır toplamları ile dip toplam tutmuyor. Tekrar kaydet tuşuna basınız.");
      return next(false);
    }

    delete postData["irsaliyeRows"];
    if (
      this.props.addDocument?.uri &&
      postData.document_url !== this.props.addDocument?.uri
    ) {
      postData.document_url = this.props.addDocument?.uri;
    }
    postData.is_checked = isChecked === true;
    postData.is_exported = this.state.is_exported === true ? 0 : 1;
    if(this.state.is_exported2 === false){
      postData.is_exported = 2;
    }
    if (formData.invoice.id) postData.updated_id = formData.invoice.id;
    Api[method](url, postData, function (response, errors) {
      if (errors) {
        if (errors.message) toastr.error(Util.getErrorAsStr(errors));

        formErrors.invoice = Object.assign({}, formErrors.invoice, errors);
        self.setState({ errors: formErrors }, function () {
          next(false);
        });
      } else if (!manualValidations) {
        self.setState({ errors: formErrors }, function () {
          next(false);
        });
        next(false);
      } else {
        if (!response.salesInvoice) {
          toastr.error("Muhtemel SQL Hatası");
          return next(false);
        }
        let formData = self.state.formData;
        formData.invoice.id = response.salesInvoice.id;
        self.setState({ formData: formData }, function () {
          next(true);
        });
      }
    });
  }

  getTaxSubjectToVat(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.subject_to_vat;
    }
    return 0;
  }

  getTaxReductionType(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.reduction_type;
    }
    return 0;
  }

  getReductionRate(reductionCode) {
    if (this.props.vatReductionTypeCodes) {
      const reduction = Sugar.Array.find(
        this.props.vatReductionTypeCodes,
        function (type) {
          return type.code === reductionCode;
        }
      );
      return Util.convertNumberToPercent(reduction.rate);
    }
    return Util.convertNumberToPercent(0);
  }

  saveExempt = () => {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 1;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(true);
    });
  };

  saveNormal(refresh) {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 0;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(refresh);
    });
  }

  save(refresh, isChecked) {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 0;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(refresh, isChecked);
    });
  }

  closeIrsaliye = () => {
    this.setState({
      irsaliye: !this.state.irsaliye,
    });
  };
  closeExportedModal = () => {
    this.setState({
      ExportedModal: !this.state.ExportedModal,
    });
  };
  closeChangingModal = () => {
    this.setState({
      ChangingModal: !this.state.ChangingModal,
    });
  };
  saveIrsaliye = (rows) => {
    this.setState({
      irsaliyeRows: rows,
    });
  };

  // Hello callback hell

  fetchAllTags = () => {
    Api.get("/tagType/showAll", (res, err) => {
      if (res.status === "ok") {
        let categoryId = res.tagTypes[0].id;
        Api.get("/tagTypeValue/showAll/" + categoryId, (res, err) => {
          if (res.status === "ok") {
            let tags = res.tagTypeValues.map((el) => {
              el.value = el.description;
              el.label = el.description;
              return el;
            });
            this.setState({ allTags: tags });
          } else console.log(err);
        });
      } else console.log(err);
    });
  };

  onHandleTags = (newValue, actionMeta) => {
    console.groupEnd();
    if (actionMeta.action === "create-option") {
      Api.get("/tagType/showAll", (res, err) => {
        if (res.status === "ok") {
          let categoryId = res.tagTypes[0].id;
          Api.post(
            "/tagTypeValue",
            {
              tag_type_id: categoryId,
              description: newValue[newValue.length - 1].value,
            },
            (response, errors) => {
              if (response.status === "ok") {
                this.setState({
                  allTags: [
                    ...this.state.allTags,
                    {
                      ...response.tagTypeValue,
                      label: response.tagTypeValue.description,
                      value: response.tagTypeValue.description,
                    },
                  ],
                  relatedTags: [
                    ...this.state.relatedTags,
                    response.tagTypeValue.id,
                  ],
                });
              } else console.log(errors);
            }
          );
        }
      });
    } else {
      // Backende normalde sadece id'yi gönderiyorum ama eğer yeni tag ilk defa oluşturulmuşsa backendden gelen id yi relatedTags bilmiyor.
      // O yüzden yeni tag oluşturulunca allTags içine atıp ordan da value ile filter yaparak Tags komponentinin bilmediği ID yi allTags içinden çekiyorum
      // SQL sorgusu gibi

      this.setState({
        relatedTags: newValue
          ? newValue
              .flatMap((val) =>
                this.state.allTags.filter((tag) => tag.value === val.value)
              )
              .map((el) => el.id)
          : [],
      });
    }
  };

  openIstisnaModal = () => {
    this.setState({ openIstisnaModal: true });
  };
  saveIstisnaModal = () => {
    this.state.istisna_fatura_buton = "KDV İstisna Kodu Ekle*";
    this.closeIstisnaModal();
  };

  closeIstisnaModal() {
    this.setState({ openIstisnaModal: false, openedIstisnaModal: false });
  }
  openSgkModal = () => {
    this.setState({ openSgkModal: true });
  };
  saveSgkModal = () => {
    this.state.sgk_fatura_buton = "SGK Faturası Bilgileri Ekle*";
    this.closeSgkModal();
  };

  closeSgkModal() {
    this.setState({ openSgkModal: false });
  }

  itemModalClose() {
    this.setState({
      saving: false,
      itemModalValues: { ...this.state.itemModalValues, showItemModal: false },
    });
  }
  toggle2(field,firstChange = true) {
    const self = this;
    if(firstChange === true){
      this.setState({ is_exported2: field,is_exported: !field }, function () {
        this.prepareFormToPost(this.state.formData);
      });
    }

  }
  toggle(field,firstChange = true) {
    const self = this;
    var formData = this.state.formData;
    let tableData = this.state.tableData;
    tableData.rows.forEach(function (row, index) {
      tableData.rows[index][12].hidden = field;
    });
    this.setState({ is_exported: field });
    if(field===false){
      this.setState({ is_exported2: true });
    }
    if (field === false) {
      formData.rows.forEach(function (row, index) {
        if (row.exportedLines.length == 0 && row.item_id != null) {
          let items = self.state.items;
          var ExportForm = ExportedModal.defaultData();
          let itemId = formData.rows[index].item_id;
          let getItemData = items[itemId];
          ExportForm.item = {
            delivery_type_id: getItemData.delivery_type_id,
            transport_type_id: getItemData.transport_type_id,
            package_type_id: getItemData.package_type_id,
            customs_tracking_no: getItemData.customs_tracking_no,
            container_no: getItemData.container_no,
            number_of_containers: getItemData.number_of_containers,
            gtip: getItemData.gtip,
          };
          formData.rows[index].exportedLines = ExportForm.item;
          self.setState({
            ExportedModal: false,
            formData: formData,
          });
        }
      });
    }
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/1/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  render() {
    var exemption_reasons = JSON.parse(
      localStorage.getItem("exemption_reasons")
    );
    let lineAdded = this.state.linedAdded;
    if (this.state.formData.rows && this.state.formData.rows[0].item_id > 0)
      lineAdded = true;

    let fromWhos = this.props.fromWhos.map(function (fromWho) {
      // fromWho.name = fromWho.firstname;
      // if (fromWho.lastname) fromWho.name += " " + fromWho.lastname;
      fromWho.name = fromWho.fullname;
      return fromWho;
    });

    let headerTitle = "SATIŞ FATURASI";
    if (this.props.documentType === 2) headerTitle = "TEDARİKÇİYE FATURA";
    if (this.props.documentType === 4) headerTitle = "DEMİRBAŞ FATURASI";

    const formData = { ...this.state.formData };

    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/salesInvoice")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <AddSalesInvoiceHeader
              editId={this.props?.editId}
              paramType={this.props.paramType}
              callBackXMLData={this.props.callBackXMLData}
              viewOptions={this.props.viewOptions}
              lineAdded={lineAdded}
              fromWhos={fromWhos}
              fromWhoId={
                  this.state.formData.invoice.customer_id ||
                  this.state.formData.invoice.supplier_id
              }
              documentType={this.props.documentType}
              currencies={this.props.currencies}
              formData={this.state.formData.invoice}
              calculate={this.props.calculate}
              errors={this.state.errors.invoice}
              onChange={this.onChange.bind(this)}
              bankAccounts={this.props.bankAccounts}
              employees={this.props.employees}
              onRefresh={this.onRefresh.bind(this)}
              saved={this.state.saved}
              onChangeSet={this.onChangeSet}
              addIrsaliye={this.closeIrsaliye}
              title={headerTitle}
              bankCurrencyAndMethod={this.bankCurrencyAndMethod}
              irsaliyeRows={this.state.irsaliyeRows.rows}
          >
            <EkoInputTableButtonGroup
                data={this.state.tableData}
                orderNumber={true}
                currency={this.state.formData.invoice.currency}
                formData={formData}
                errors={this.state.errors}
                onSave={(refresh, isChecked) => this.save(refresh, isChecked)}
                saving={this.state.saving}
                onRowAdd={this.onInvoiceLineAdded.bind(this)}
                defaultRow={this.props.defaultRowData}
                onCancel={this.onCancel.bind(this)}
                onChangeRow={this.onInvoiceLineChange.bind(this)}
                onRealChange={this.onInvoiceLineReallyChange.bind(this)}
                onChange={this.onInvoiceChange.bind(this)}
                onRowDelete={this.onInvoiceLineRemoved.bind(this)}
                formId={"add_sales_invoice"}
                defaultTagOptions={this.state.allTags}
                showTags={false}
                onHandleTags={this.onHandleTags}
                defaultTagValues={this.props.formData.invoice.tags}
                buttons={[
                  {
                    id: "saveExempt",
                    onClick: this.saveExempt,
                    labelText: "Proforma / Taslak Olarak Kaydet",
                  },

                  {
                    id: "saveNormal",
                    onClick: this.saveNormal.bind(this, false),
                    labelText: "Satış Faturası Kaydet ve Yeni Ekle",
                  },
                ]}
                isCheckButton={true}
                editId={this.props?.editId}
                updatedAt={
                  this.state?.formData?.invoice?.is_checked
                      ? this.state?.formData?.invoice?.updated_at
                      : null
                }
            />
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div style={{
                  top: 35,
                  opacity: 1,
                  position: "absolute",
                  height: "1px",
                  background: "#eeeeee",
                  width: "100%"
                }}></div>
                <a
                    onClick={this.openSgkModal}
                    style={{top: 65, opacity: 1, position: "absolute"}}
                >
                  {this.state.sgk_fatura_buton}
                </a>
                <a
                    onClick={this.openIstisnaModal}
                    style={{top: 90, opacity: 1, position: "absolute"}}
                >
                  {this.state.istisna_fatura_buton}
                </a>
                <a onClick={this.openNewTagModal} style={{top: 117, opacity: 1, position: "absolute"}}>Etiket Ekle</a>
                {this.props.documentType === 1 && (
                    <div
                        style={{
                          top: 145,
                          opacity: 1,
                          position: "absolute",
                          width: "900px",
                        }}
                    >
                      <EkoFormSwitch
                          label2="Normal Fatura"
                          label="E-İhracat Faturası"
                          textOff="Evet"
                          textOn="Evet"
                          md={12}
                          onChange={this.toggle.bind(this)}
                          value={this.state.is_exported}
                          leftBox={false}
                      />
                    </div>
                )}
                {this.props.documentType === 1 && (
                    <div
                        style={{
                          top: 185,
                          opacity: 1,
                          position: "absolute",
                          width: "900px",
                        }}
                    >
                      <EkoFormSwitch
                          label2="Normal Fatura"
                          label="İhraç Kayıtlı Fatura"
                          textOff="Evet"
                          textOn="Evet"
                          md={12}
                          onChange={this.toggle2.bind(this)}
                          value={this.state.is_exported2}
                          leftBox={false}
                      />
                    </div>
                )}
              </div>
            </div>
            <EkoModal
                showModal={this.state.openSgkModal}
                onHide={this.closeSgkModal.bind(this)}
                title={"SGK FATURASI BİLGİLERİ"}
            >
              <div className={"row"}>
                <label style={{"line-height": "45px"}}>Dosya No</label>
                <EkoFormInputText
                    onChange={this.onChange.bind(this)}
                    value={this.state.sgk_dosya_no}
                    colMd={12}
                    placeHolder="Dosya No Giriniz"
                    type="text"
                    id="sgk_dosya_no"
                />
                <div style={{height: "30px"}}></div>
                <EkoFormDate
                    onChange={this.onChange.bind(this)}
                    id="sgk_donem"
                    labelMd={4}
                    colMd={7}
                    dateFormat="DD-MM-YYYY"
                    isVertical={true}
                    label="Dönem"
                    value={this.state.sgk_donem}
                />
                <div style={{height: "30px"}}></div>
                <div style={{"text-align": "right"}}>
                  <EkoFormButton
                      label={"Kaydet"}
                      faClass="fa fa-plus"
                      buttonsType={"default"}
                      className="btn blue"
                      onClick={this.saveSgkModal}
                  />
                </div>
              </div>
            </EkoModal>
            <EkoModal
                showModal={
                    this.state.openIstisnaModal && this.state.openedIstisnaModal
                }
                onHide={this.closeIstisnaModal.bind(this)}
                title={"İSTİSNA KODU SEÇİNİZ"}
            >
              <p style={{color: "black", "text-align": "center"}}>
                İstisna faturası değilse seçmeden devam ediniz.
                <br/>
                <br/>
                Henüz belgelendirilmemiş bir kayıt ise lütfen kaydet butonunun{" "}
                <br/>
                yanındaki oka tıklayıp{" "}
                <span style={{color: "red"}}>Proforma / Taslak Olarak Kaydet</span>'e tıklayın.
              </p>
              <div className={"row"}>
                <EkoFormSelect
                    onChange={this.onChange.bind(this)}
                    label="KDV İstisna Kodu : "
                    lableMd={4}
                    options={exemption_reasons}
                    id="reasons"
                    optionId={"code"}
                    optionValue={"description"}
                    searchable={true}
                    value={this.state.reason_id}
                />
                <div style={{height: "30px"}}></div>
                <div style={{"text-align": "right"}}>
                  <EkoFormButton
                      label={"Kaydet"}
                      faClass="fa fa-plus"
                      buttonsType={"default"}
                      className="btn blue"
                      onClick={this.saveIstisnaModal}
                  />
                </div>
              </div>
            </EkoModal>
            <AddItem
                showModal={this.state.showAddItem}
                formData={false}
                onSave={this.onItemSaved.bind(this)}
                onCancel={() => {
                  this.setState({showAddItem: false});
                }}
                errors={false}
            />
            <AddOtherTax
                taxTypes={this.props.taxTypes}
                inXml={this.state.formData.inXml}
                vatReductionTypeCodes={this.props.vatReductionTypeCodes}
                onChangeRow={this.onTaxRowChange.bind(this)}
                onChange={this.onTaxChange.bind(this)}
                showModal={this.state.showAddTax}
                formData={this.state.taxForm}
                errors={{}}
                onSave={this.onTaxFormSave.bind(this)}
                onCancel={() => {
                  this.setState({showAddTax: false});
                }}
            />
          </AddSalesInvoiceHeader>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
          <ModalIrsaliye
              ref={(irsaliye) => (this.irsaliye = irsaliye)}
              irsaliyeRows={this.state.irsaliyeRows}
              saveIrsaliye={this.saveIrsaliye}
              showModal={this.state.irsaliye}
              closeModal={this.closeIrsaliye}
          />
          <ExportedModal
              formData={this.state.ExportForm}
              onSave={this.onExportFormSave.bind(this)}
              showModal={this.state.ExportedModal}
              closeModal={this.closeExportedModal}
          />
          <ChangingModal
              showModal={this.state.ChangingModal}
              closeModal={this.closeChangingModal}
              ChangingModalData={this.state.ChangingModalData}
              ChangingModalRowData={this.state.ChangingModalRowData}
              onSave={this.onChangingModalSave.bind(this)}
          />
          <SelectItemModal
              onSave={() => {
                this.onSave(
                    this.state.itemModalValues.refresh,
                    this.state.itemModalValues.isChecked
                );
                this.fetchItems();
              }}
              passModal={() => {
                this.setState({passModal:true})
              }}
              itemModalValues={this.state.itemModalValues}
              showModal={this.state.itemModalValues.showItemModal}
              onClose={this.itemModalClose.bind(this)}
              items={this.state.formData.rows}
              onChangeLines={(rows, callback) =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      rows: [...rows]
                    }
                  }, () => callback ? callback() : null)
              }
              isSales={this.props.documentType === 1 ? true : false}
          />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vatRateTypes: state.data.vatRateTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    units: state.data.units,
    taxTypes: state.data.taxTypes,
    currencies: state.data.currencies,
    bankAccounts: state.data.bankAccountsAll,
    employees: state.data.employeesAll,
    addDocument: state.page.addDocument,
  };
}

export default connect(mapStateToProps, actions)(withRouter(AddSalesInvoice));
