import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import EkoFormInput from '../../components/elements/form/EkoFormInputText'
import { event } from 'jquery'
import { empty } from 'rxjs/Observer'
class AddNewWage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.editData || this.defaultFormData(),
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            setSelectedFile: false,
            noBranch: 0
        }
    }

    defaultFormData() {
        return {
            "branch_id": "",
            "file_type": "",
            "file": [],
            "month": "",
            "year": "",
        }
    }
    componentWillMount() {
        let formData = this.props.editData || this.defaultFormData();
        let errors = {}
        this.setState({formData: formData, errors: errors});
        /*if (empty(this.props.branchesList)){
            this.setState({noBranch: 1});
            console.log("----no branch---");
        }*/
    }
    componentWillReceiveProps(nextProps) {
        let formData = nextProps.editData || this.defaultFormData();
        this.setState({formData: formData, showModal: nextProps.showModal});
    }
    onSave() {
        var self = this;
        let formData = this.state.formData;
        self.setState({saving: true});

        const newFormData = new FormData();
        newFormData.append('file', formData.file);
        newFormData.append('file_name', formData.file.name);
        newFormData.append('branch_id', formData.branch_id);
        newFormData.append('file_type', formData.file_type);
        newFormData.append('month', formData.month);
        newFormData.append('year', formData.year);
        
        if (formData.file_type > 0) {
            Api.post("/company/addNewWageSheet", newFormData, function (responses, postErrors) {
                if (postErrors) {
                    if (postErrors.message) {
                        self.setState({saving: false});
                        toastr.error("Başarısız", "Kayıt girişi işlemi sırasında hata oluştu.");
                    }
                }else{
                    if (responses.actionResult == "1") {
                        toastr.success("Başarılı", "İçe aktarım tamamlandı. Listeden göz at butonu üzerinden içe aktarılan verileri görebilirsiniz. Lütfen bekleyiniz, liste yenileniyor.");
                        self.setState({saving: false, showModal: false});
                        self.props.onSave(); // liste sayfasından modal'a bind 'lanan onSave
                        // yüklenen bordro detayına gönderelim
                        window.top.location = '/dashboard/wagesheet/'+responses.wageSheetId;
                    }else if (responses.actionResult == "3"){
                        self.setState({saving: false, showModal: false});
                        toastr.error("Başarısız", "Aynı dönemde başka kayıt bulunduğu için yükleme yapmadan önce aynı ay ve yıldaki kaydı silmelisiniz.");
                    }else{
                        self.setState({saving: false, showModal: false});
                        toastr.error("Başarısız", "Bir hata oluştu. Lütfen tekrar deneyiniz.");
                    }
                }
            })
        }else{
            toastr.error("Başarısız", "Lütfen tüm alanları doldurunuz.");
            self.setState({saving: false});
        }

    }
    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    setFieldChange(type, field, value) {
        let formData = this.state.formData;
        let errors = this.state.errors || {};
        formData[field] = value;
        let bigForm = this.state.formData;
        this.setState({ formData: bigForm, errors: errors });
        console.log(bigForm);
    }
    
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
          formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }
    
    handleFileSelection(event) {
        var _self = this;
        let selectedFile = {};
        if (event.target.files){
            selectedFile = event.target.files[0];
        }
        let formData = _self.state.formData;
        formData["file"] = selectedFile;
        let bigForm = _self.state.formData;
        _self.setState({formData: bigForm});
    }
    addNewBranch() {
        var _self = this;
        window.top.location = '/dashboard/companySettings#sgk_isyeri';
        return true;
    }
    render() {

        const inputProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }
        var apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);
        let branchesList = this.props.branchesList;
        let aktarimSablonuSelectBoxValues = [{"id": "1", "value": "Zirve"}, {"id": "2", "value": "Luca"}];
        let monthsList = [{"id": "1", "value": "Ocak"}, {"id": "2", "value": "Şubat"}, {"id": "3", "value": "Mart"}, {"id": "4", "value": "Nisan"}, {"id": "5", "value": "Mayıs"}, {"id": "6", "value": "Haziran"}, {"id": "7", "value": "Temmuz"}, {"id": "8", "value": "Ağustos"}, {"id": "9", "value": "Eylül"}, {"id": "10", "value": "Ekim"}, {"id": "11", "value": "Kasım"}, {"id": "12", "value": "Aralık"}];
        let yearsList = [{"id": "2022", "value": "2022"}, {"id": "2023", "value": "2023"}, {"id": "2024", "value": "2024"}, {"id": "2025", "value": "2025"}, {"id": "2026", "value": "2026"}, {"id": "2027", "value": "2027"}, {"id": "2028", "value": "2028"}, {"id": "2029", "value": "2029"}, {"id": "2030", "value": "2030"}];

        return <EkoModal
            showModal={this.state.showModal}
            title={this.state.formData.id ? "Güncelle" : "Yeni Ekle"}
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            onSave={() => {
                this.setState({formData: this.defaultFormData()})
                this.props.onSave();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_new_data"}>

                <div className="row">
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...inputProps}
                            id="branch_id"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"isyeri_adi"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={branchesList}
                            label="Şubenizi Seçiniz *"
                            onAddNewItemClick={this.addNewBranch.bind(this)}
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...inputProps}
                            id="file_type"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={aktarimSablonuSelectBoxValues}
                            label="Aktarım Şablonu *"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div class="alert alert-info text-center">
                        <p style={{fontWeight: "bold", marginBottom: "10px"}}>Diğer yazılımlardan alacağınız Excel dosya örnekleri</p>
                            <a href={apiUrl + "panel/bordro_zirve.xlsx"} className="btn btn-sm btn-default"
                               style={{textTransform: "inherit"}}>Zirve Örnek Dosya İndir</a>
                            <a href={apiUrl + "panel/bordro_luca.xlsx"} className="btn btn-sm btn-default"
                               style={{textTransform: "inherit"}}>Luca Örnek Dosya İndir</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label>Çıktı Aldığınız Excel Bordro Dosyası *</label>
                        <input type="file" name="selected_file" class="form-control" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.handleFileSelection.bind(this)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...inputProps}
                            id="month"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={monthsList}
                            label="Bordro - Ay Seçiniz *"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...inputProps}
                            id="year"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={yearsList}
                            label="Bordro - Yıl Seçiniz *"
                        />
                    </div>
                </div>


                <Modal.Footer>
                    <div className="row buttonProccess2">
                        <EkoFormButton label={this.state.formData.id ? "Güncelle" : "Kaydet & Yükle"}
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>

            </EkoForm>
            </EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(AddNewWage);
