import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import Style from "style-it";
import { VCSS } from "../../css/voucher.js";
import { FETCH_CUSTOMERS_ALL } from "../../actions/types";
import UtilHelper from "../../util/UtilHelper";
import AddFixedAssetSalesInvoice from "./AddFixedAssetSalesInvoice";

class FixedAssetSalesInvoiceAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      fromWhos: []
    };
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        item_id: 0,
        item_code: "",
        description: "",
        quantity: 1,
        unit_price_fc: 0.0,
        discount_rate: 0,
        unit_id: 0,
        discount_amount_fc: 0,
        net_amount_fc: 0,
        vat_rate_type_id: 0,
        vat_amount_fc: 0
      }
    );
  }

  defaultFormData() {
    return {
      invoice: {
        customer_id: 0
      },
      rows: [this.defaultRowData()],
      footer: {}
    };
  }

  defaultErrors() {
    return {
      rows: [
        {
          item_id: false,
          description: false,
          quantity: false,
          unit_price_fc: false,
          discount_rate: false,
          unit_id: false,
          discount_amount_fc: false,
          net_amount_fc: false,
          vat_rate_type_id: false,
          vat_amount_fc: false
        }
      ],
      invoice: {},
      footer: {}
    };
  }

  fetchFromWho() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
  }

  setFromWhos(nextProps) {
    if (nextProps.customersAll) {
      const fromWhos = nextProps.customersAll.filter(function(customer) {
        return customer.is_active === 1;
      });
      this.setState({ fromWhos: fromWhos });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customersAll) this.setFromWhos(nextProps);
  }

  prepareInvoiceLineForEdit(line, isCopied) {
    let row = {};
    if (typeof line.quantity !== 'number' && !Number.isNaN(line.quantity)) {
      line.quantity = parseFloat(line.quantity);
    }
    if (!Number.isNaN(line.quantity)) {
      line.quantity = line.quantity.toFixed(6);
      var parts = line.quantity.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      line.quantity = parts.join(",");
    }

    if (!isCopied) row.id = line.id;
    row.asset_type_id = line.asset_type_id;
    row.fixed_asset_id = line.fixed_asset_id;
    row.description = line.description;
    row.quantity = line.quantity;
    row.unit_id = line.unit_id;
    row.vat_rate_type_id = line.vat_rate_type_id;
    row.discount_amount_fc = Util.convertDecimalToMoney(
      line.discount_amount_fc
    );
    row.discount_rate = Util.convertNumberToPercent(line.discount_rate);
    row.unit_price_fc = UtilHelper.money.convertDecimalToMoney(
      line.unit_price_fc
    );
    row.net_amount_fc = Util.convertDecimalToMoney(line.net_amount_fc);
    row.vat_amount_fc = Util.convertDecimalToMoney(line.vat_amount_fc);

    return row;
  }

  prepareInvoiceForEdit(fixedAssetSalesInvoice) {
    let ret = {};

    ret.id = fixedAssetSalesInvoice.id;
    ret.customer_id = fixedAssetSalesInvoice.customer_id
      ? parseInt(fixedAssetSalesInvoice.customer_id, 10)
      : null;
    ret.currency = fixedAssetSalesInvoice.currency;
    ret.currency_rate = Util.convertDecimalToMoneyNew(fixedAssetSalesInvoice.currency_rate);
    ret.description = fixedAssetSalesInvoice.description;
    ret.legal_no = fixedAssetSalesInvoice.legal_no;
    ret.customer_name = fixedAssetSalesInvoice.customer_name || "";
    ret.date = Util.convertFromDatabaseDate(fixedAssetSalesInvoice.date);
    ret.due_date = fixedAssetSalesInvoice.due_date
      ? Util.convertFromDatabaseDate(fixedAssetSalesInvoice.due_date)
      : "";
    ret.updated_at = fixedAssetSalesInvoice.updated_at || null;

    return ret;
  }

  componentWillMount() {
    var self = this;
    this.fetchFromWho();

    if (this.props.params && this.props.params.id) {
      const invoiceId = this.props.params.id;
      let formData = this.state.formData;
      Api.paralel(
        ["/fixedAssetSalesInvoice/" + invoiceId, "/fixedAssetSalesInvoiceLine/showAll/" + invoiceId],
        function(responses, error) {
          const docUrl = responses[0].fixedAssetSalesInvoice?.document_url;
          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }
          formData.invoice = self.prepareInvoiceForEdit(responses[0].fixedAssetSalesInvoice);

          let lines = [];
          if (
            responses[1].fixed_asset_sales_invoice_lines &&
            responses[1].fixed_asset_sales_invoice_lines.length > 0
          ) {
            lines = responses[1].fixed_asset_sales_invoice_lines;

            formData.rows = lines.map(function(line) {
              return self.prepareInvoiceLineForEdit(line, false);
            });
            self.setState({ formData: formData });
          }
        }
      );
    }
  }

  onCancel() {
    this.props.router.push("/dashboard/fixedAssetSalesInvoice");
  }

  render() {
    const editId =
      this.props.params && this.props.params.id ? this.props.params.id : false;
    return Style.it(
      VCSS,
      <div>
        <AddFixedAssetSalesInvoice
          {...this.state}
          editId={editId}
          onRefresh={this.fetchFromWho.bind(this)}
          errors={this.defaultErrors()}
          defaultErrors={() => this.defaultErrors()}
          defaultRowData={this.defaultRowData()}
          formData={this.state.formData}
          defaultFormData={() => this.defaultFormData()}
          onCancel={this.onCancel.bind(this)}
          viewOptions={this.props.viewOptions}
          redirectToBase={this.onCancel.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customersAll: state.data.customersAll,
    viewOptions: state.data.viewOptions,
    pageLoading: state.page.loading
  };
}

export default connect(mapStateToProps, actions)(FixedAssetSalesInvoiceAddUpdate);
