import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import {FETCH_INVITATIONS, FETCH_ROLES} from '../../actions/types'

class AddCompanyUserRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {}
        }
    }

    defaultFormData() {
        return {
            companyUserRole: {}
        }
    }

    componentDidMount() {
        this.props.fetchData("/invitation/list", FETCH_INVITATIONS);
        this.props.fetchData("/role/showAll", FETCH_ROLES);
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}

        this.setState({formData: formData, errors: errors});
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        this.setState({formData: formData, errors: {}, showModal: nextProps.showModal}, this.setDescription);
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;
        if (field === 'role_id') this.setDescription();
        let bigForm = this.state.formData;
        bigForm[type] = formData;
        console.log(formData);
        this.setState({formData: bigForm, errors: errors});
    }


    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    onSave() {
        var self = this;
        let formData = this.state.formData;
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user.id);
        self.setState({saving: true});
        let validationRules = [];
        let postRules = [];

        HelperContainer.validateContainerData(self.props, validationRules, formData, self.state.errors, function (validated, errors, validationFields) {
            if (validated) {
                let func = "post";
                let url = "/invitation";
                if (formData.companyUserRole.id) {
                    func = "put";
                    url = "/invitation/" + formData.companyUserRole.id;
                    formData.companyUserRole.updated_id = formData.companyUserRole.id;
                }
                formData.companyUserRole.sender_id = user.id; // kullanıcı id si gelecek
                Api[func](url, formData.companyUserRole, function (response, postErrors) {
                    if (postErrors) {
                        errors.companyUserRole = postErrors;
                        self.setState({saving: false, errors: errors});
                    } else {
                        HelperContainer.postContainerData(self.props, validationFields, postRules, formData, self.state.errors, function (validated, postErrors) {
                            if (postErrors.hasOwnProperty("sysError")) {
                                toastr.error('Hata', 'Genel bir hata oluştu, lütfen daha sonra deneyiniz');
                            } else {
                                if (formData.companyUserRole.id) {
                                    toastr.success('Başarılı', 'Başarıyla Güncellendi');
                                    self.setState({saving: false, errors: postErrors});
                                } else {
                                    self.setState({
                                        saving: false,
                                        errors: postErrors,
                                        formData: self.defaultFormData()
                                    });
                                    toastr.success('Başarılı', 'Davetiye linki e posta ile gönderilmiştir');
                                }
                                self.props.onSave();
                            }
                        })
                    }
                })
            } else {
                if (errors.hasOwnProperty("sysError")) {
                    toastr.error('Hata', 'Genel bir hata oluştu, lütfen daha sonra deneyiniz');
                } else {
                    self.setState({saving: false, errors: errors});
                }
            }

        });

    }

    onCancel() {
        console.log("cancel called");


        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }


    setDescription() {
        const self = this;
        let roles = this.props.roles || [];
        roles = roles.filter(o => o.id === self.state.formData.companyUserRole.role_id || "")[0]
        if (roles) {
            self.setFieldChange('companyUserRole', 'description', roles.description);
        }
    }

    render() {
        const companyUserRoleProps = {
            onChange: this.setFieldChange.bind(this, "companyUserRole"),
            onMount: this.setFieldMount.bind(this, "companyUserRole"),
            formData: this.state.formData.companyUserRole,
            errors: this.state.errors.companyUserRole
        }
        return <EkoModal
            showModal={this.state.showModal}
            title={this.state.formData.companyUserRole.id ? "Güncelle" : "Yeni Davetiye"}
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_company_user_role_modal"}>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...companyUserRoleProps}
                                              label="Adı"
                                              disabled={this.state.formData.companyUserRole.id
                                              && this.state.formData.companyUserRole.status === 1}
                                              id="firstname"
                                              isVertical
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...companyUserRoleProps}
                                              label="Soyadı"
                                              disabled={this.state.formData.companyUserRole.id
                                              && this.state.formData.companyUserRole.status === 1}
                                              id="lastname"
                                              isVertical
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormInputText {...companyUserRoleProps}
                                              label="Email"
                                              disabled={this.state.formData.companyUserRole.id}
                                              id="email"
                                              isVertical
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <EkoFormSelect {...companyUserRoleProps}
                                           id="role_id"
                                           isVertical
                                           defaultText="Seçiniz"
                                           options={this.props.roles}
                                           label="Rol"/>
                        </div>
                        {this.state.formData.companyUserRole.role}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <EkoFormInputText {...companyUserRoleProps}
                                              label="Rol Açıklaması"
                                              id="description"
                                              type="textarea"
                                              isVertical
                                              readOnly
                            />
                        </div>
                    </div>
                </div>


                <Modal.Footer>
                    <div className="row buttonProccess2">
                        <EkoFormButton label={this.state.formData.companyUserRole.id ? "Güncelle" : "Davet Et"}
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>

            </EkoForm></EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        companyUserRoles: state.data.companyUserRoles,
        roles: state.data.roles,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddCompanyUserRole);
