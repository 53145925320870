import React, {Component} from 'react'
import {Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {array_fill} from "../../services/ArrayHelper";
import EkoFormInputText from "./form/EkoFormInputText";
import EkoFormSelect from './form/EkoFormSelect';

export default class EkoInputTabRow extends Component {
    parseInput = (input) => {
        let element = null;


        const inputProps = {...this.props.inputProps};

        if (inputProps.validated === undefined) {
            inputProps.validated = false;
        }

        if (input.visible && !input.visible(inputProps.formData, inputProps.validated)) {
            return element;
        }

        switch (input.type) {
            case 'text':
                element = <EkoFormInputText
                    label={input.label} id={input.name}
                    disabled={input.disabled || false}
                    tooltip={true}
                    placeHolder={input.placeholder || ''}
                    {...inputProps}
                    type={"text"}
                />;
                break;
            case 'number':
                element = <EkoFormInputText
                    label={input.label} id={input.name}
                    disabled={input.disabled || false}
                    tooltip={true}
                    placeHolder={input.placeholder || ''}
                    {...inputProps}
                    type={"number"}
                />;
                break;
            case 'select':


                element = <EkoFormSelect {...inputProps}
                                         id={input.name}
                                         value={input.defaultValue || 0}
                                         defaultText="Seçiniz"
                                         tooltip={true}
                                         disabled={input.disabled || false}
                                         options={input.values}
                                         style={input.style || {}}
                                         label={input.label}/>;
                break;

            case 'money':

                element = <EkoFormInputText
                    label={input.label} id={input.name}
                    disabled={input.disabled || false}
                    tooltip={true}
                    placeHolder={input.placeholder || ''}
                    {...inputProps}
                    type={"money"}
                />;

                break;

            default:
                element = <EkoFormInputText
                    label={input.label}
                    id={input.name}
                    disabled={input.disabled || false}
                    tooltip={true}
                    placeHolder={input.placeholder || ''}
                    {...inputProps}
                    type={"text"}
                />;
                break;
        }

        return element;
    };

    render() {

        let columnLength = 12;
        let columnCount = 1;
        let rows = this.props.row;


        if (rows.length > 1) {
            columnCount = rows.length;
            columnLength = 12 / columnCount;
        }


        let columns = Object.values(array_fill(0, columnCount, columnLength));


        return <div>
            {columns.map((t, i) => {
                let row = rows[i];


                return <Col key={i} md={columnLength}>
                    <div className="form-group">
                        {this.parseInput(row, i)}
                    </div>
                </Col>;
            })}
        </div>;
    }
}

EkoInputTabRow.propTypes = {
    row: PropTypes.array.isRequired,
    inputProps: PropTypes.object.isRequired
};