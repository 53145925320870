import React, { Component } from 'react'
import EkoModal from '../../components/elements/EkoModal'
import {EkoFormInputText, EkoFormButton, EkoFormSwitch} from '../../components/elements/EkoForm'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";

class AskCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            saving: false,
            savingModal: false,
            copied: false,
            active: false,
            linkName: "Kopyala",
            buttonDisabled: false,
            switchValueCommission:true,
            switchValueInstallment:true,
        }

    }

    
    closeModal() {
        this.setState({active: false, buttonDisabled: false, linkName: "Kopyala", saving: false, formData: {} });
        this.props.closeModal();
    }

    onMount() {
        
    }

    onClick() {
        const self = this;
        self.setState({active: true, buttonDisabled: true, linkName: "Kopyalandı"})
    }
    tgl(link, switchStatus) {
        this.setState({switchValueCommission: switchStatus});
        var link = link.split('/').pop();
        Api.get("/paynet/setPaynetTransaction/commission/"+link+"/"+switchStatus, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response.result;
                toastr.info("Başarılı!", "Komisyon Bilgileri Güncellendi");
            }
        });
    }
    tgl2(link, switchStatus) {
        this.setState({switchValueInstallment: switchStatus});
        var link = link.split('/').pop();
        Api.get("/paynet/setPaynetTransaction/installment/"+link+"/"+switchStatus, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response.result;
                toastr.info("Başarılı!", "Taksit Bilgileri Güncellendi");
            }
        });
    }
    render() {
        let paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
      

        return (
            <EkoModal
                showModal={this.props.showModal}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.savingModal}
                title={"Ödeme Linki Oluştur"}
            >
                {paynet_status === 0 ?
                    <div>
                        Paynet Üyesi Olmadığınız İçin Bu Hizmetten Yararlanamıyorsunuz. <br /> 
                        Kayıt Olmak İçin Aşağıdaki Linke Tıklayınız <br /><br />
                        <a href="https://basvuru.paynet.com.tr/basvuru-formu" className="enrollLink" target="_blank">Kayıt İşlemi İçin Tıklayınız</a>
                    </div>
                    : <div className="row">
                        <div className="col-md-12">
                            Ödeme Aracı Kurum Komisyonunu Kimin Ödeyeceğini Seçiniz
                            <EkoFormSwitch
                                leftBox={false}
                                md={12}
                                textOn="Komisyonu Ben Ödeyeceğim"
                                textOff="Karşı Taraf Ödeyecek"
                                onChange={(newSwitchStatus) => this.tgl(this.props.link, newSwitchStatus)}
                                value={this.state.switchValueCommission}
                            />
                        </div>
                        <div className="col-md-12" style={{"marginTop":"20px"}}>
                            Ödeme Aracı Kurum Bu Ödeme İçin Taksit Seçeneği Sunsun Mu?
                            <EkoFormSwitch
                                leftBox={false}
                                md={12}
                                textOn="Evet"
                                textOff="Hayır"
                                onChange={(newSwitchStatus) => this.tgl2(this.props.link, newSwitchStatus)}
                                value={this.state.switchValueInstallment}
                            />
                        </div>
                            <div className="col-md-8">
                                <EkoFormInputText
                                    id="paynet"
                                    onMount={this.onMount}
                                    disabled
                                    type="text"
                                    value={this.props.link}
                                    style={{ paddingLeft: "15px" }}
                                    label="Aşağıdaki Linki Kopyalayarak Müşterinize İletebilirsiniz"
                                    isVertical={true} />
                            </div>
                            <div className="col-md-4">
                                <CopyToClipboard text={this.props.link}
                                    onCopy={() => this.setState({copied: true})}>
                                    <EkoFormButton label={this.state.linkName} 
                                                   className={'btn blue pull-right'}
                                                   onClick={this.onClick.bind(this)}
                                                   disabled={this.state.buttonDisabled}
                                    />
                                </CopyToClipboard>                             
                            </div>

                    </div>

                }
            </EkoModal>
        )
    }
}

export default AskCollection;
