import {ROOT_URL, setHeaders} from '../util/Api';
import EkoTooltip from "../components/elements/form/EkoTooltip";

import React from 'react';
import axios from 'axios';

class LableTooltipSevice {
    constructor() {
        this.formId = null;
        this.fetching = null;
    }

    dataFetched() {
        return !!sessionStorage.getItem('tooltip_labels');
    }


    getLabelWithTooltip(inputId, label) {
        return this.formId !== null ? <EkoTooltip inputId={inputId} label={label}/> : <span>{label}</span>;
    }

    getLabelWithTooltipCheckbox(inputId, label) {

        return this.formId !== null ? <EkoTooltip inputId={inputId} label={label} checkbox={true}/> :
            <div>{label}</div>;
    }

    setformId(formId) {
        this.formId = formId;
    }

    /**
     *
     * @returns {AxiosPromise}
     */
    async fetchLabelsFromApi() {
        setHeaders();

        const {data: {data}} = await axios.get(ROOT_URL + '/tooltip/showAll')

        sessionStorage.setItem('tooltip_labels', JSON.stringify(data))
        return data;

    }

    async inputHasLabel(inputId) {
        let labels = await this.fetchLabels();

        return labels.hasOwnProperty(inputId) ? labels[inputId] : false;
    }

    async fetchLabels() {

        let labels = sessionStorage.getItem('tooltip_labels');

        if (!labels || typeof labels === 'undefined') {
            return this.fetchLabelsFromApi();
        } else {
            labels = JSON.parse(labels);

        }


        return labels.hasOwnProperty(this.formId) ? labels[this.formId] : false;
    }


}

const TooltipService = new LableTooltipSevice();

export default TooltipService;

