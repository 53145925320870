import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {EkoFormButton, EkoFormInputText, EkoFormSelect} from "../../components/elements/EkoForm";
import {connect} from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import Util from "../../util/Util";
class ExportedModal extends Component {
    static defaultData() {
        return {item:{
                "delivery_type_id": 0,
                "transport_type_id": 0,
                "package_type_id": 0,
                "customs_tracking_no": "",
                "container_no": "",
                "number_of_containers": 0,
                "gtip": "",
            }};
    }

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            formData:{},
            formFields: {},
        };
    }

    closeModal = () => {
        this.props.closeModal();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({formData: this.props.formData});
    }
    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;
        let bigForm = { ...this.state.formData, [type]: { ...formData } };
        this.setState({ formData: bigForm, errors: { ...errors } });
    }
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }
    onSave() {
        const self = this
        self.setState({saving: false});
        self.props.onSave(self.state.formData);

    }
    render() {
        const deliveryTypes = this.props.deliveryTypes;
        const transportTypes = this.props.transportTypes;
        const packageTypes = this.props.packageTypes;
        const itemProps = {
            onChange: this.setFieldChange.bind(this, "item"),
            onMount: this.setFieldMount.bind(this, "item"),
            formData: this.state.formData.item,
            errors: this.state.errors,
            buttons: this.state.radioButtons,
        };
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={"ihracat"}
                showModal={this.props.showModal}
                title={"İhracat Bilgileri Ekle"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <div className="row">
                    <div className="col-md-12">
                        <EkoFormInputText
                            {...itemProps}
                            label="GTIP No"
                            id="gtip"
                            isVertical
                            type="text"
                            maxLength={12}
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...itemProps}
                            searchable={true}
                            id="delivery_type_id"
                            isVertical
                            defaultText="SEÇİNİZ"
                            optionId="id"
                            options={deliveryTypes}
                            optionValue="description"
                            label="Teslim Şartı"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...itemProps}
                            searchable={true}
                            id="transport_type_id"
                            isVertical
                            defaultText="SEÇİNİZ"
                            optionId="id"
                            options={transportTypes}
                            optionValue="description"
                            label="Gönderim Şekli"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormSelect
                            {...itemProps}
                            searchable={true}
                            id="package_type_id"
                            isVertical
                            defaultText="SEÇİNİZ"
                            optionId="id"
                            options={packageTypes}
                            optionValue="description"
                            label="Kabın Cinsi"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormInputText
                            {...itemProps}
                            label="Gümrük Takip Numarası"
                            id="customs_tracking_no"
                            isVertical
                            type="text"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormInputText
                            {...itemProps}
                            label="Kab Numarası"
                            id="container_no"
                            isVertical
                            type="text"
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormInputText
                            {...itemProps}
                            label="Kab Adeti"
                            id="number_of_containers"
                            isVertical
                            type="text"
                        />
                    </div>
                    <div className="col-md-12">
                        <EkoFormButton
                            label={"Kaydet"}
                            faClass="fa fa-plus"
                            onClick={this.onSave.bind(this)}
                            isLoading={this.state.saving}
                        />
                    </div>
                </div>
            </EkoModal>
        );
    }
}
function mapStateToProps(state) {
    return {
        deliveryTypes: state.data.deliveryTypes,
        transportTypes: state.data.transportTypes,
        packageTypes: state.data.packageTypes,
    };
}
export default connect(mapStateToProps, actions)(ExportedModal);
