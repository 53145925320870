import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import EkoModal from "../elements/EkoModal";
import {EkoForm, EkoFormButton} from "../elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from 'react-redux-toastr'
import EkoFormDate from "../elements/form/EkoFormDate";

class AccountLockModal extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            errors: {},
            user: {}
        }
    }

    onSave = () => {
        const data = this.state.formData

        this.setState({
            saving: true
        })

        Api.post('/company/lock-before', data, (resp, err) => {
            const set = {
                saving: false,
                errors: {},
                formData: {}
            }


            if (!err) {
                this.props.toggle()

                toastr.success('Başarıyla Kaydedildi')

            } else {
                set.errors = resp
            }

            this.setState(set)

            this.props.saveLockBefore(data.lock_before)
        })
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.lockBefore && nextProps.lockBefore !== this.props.lockBefore) {
            this.setState({
                formData: {
                    lock_before: nextProps.lockBefore
                }
            })
        }
    }


    componentWillUpdate(nextProps, nextState) {
        if (nextProps.showModal === true && nextProps.showModal !== this.props.showModal) {
            const user = JSON.parse(localStorage.getItem('user'))

            if (user.lock_before) {
                this.setState({
                        formData: {
                            lock_before: user.lock_before
                        }
                    }
                )
            }
        }
    }


    onMount = () => {

    }

    onChange = (key, value) => {
        const data = {...this.state.formData}

        data[key] = value
        this.setState({
            formData: data
        })
    }

    render() {

        const props = {
            onChange: this.onChange,
            onMount: this.onMount,
            formData: this.state.formData,
            errors: this.state.errors
        }


        return (
            <EkoModal
                showModal={this.props.showModal}
                title={"Hesabı Kitle"}
                onHide={this.props.toggle}
                spinner={this.state.saving}>


                <EkoForm formId={"account_lock_form"}>
                    <div className="row">
                        <div className="form-group">
                            <EkoFormDate{...props}
                                        id="lock_before"
                                        dateFormat="DD-MM-YYYY"
                                        colMd={10}
                                        labelMd={2}
                                        clearable={true}
                                        label="Kilit Tarihi"/>

                            <div className="pull-right">
                                <EkoFormButton label={'Kaydet'} onClick={this.onSave}
                                               isLoading={this.state.saving}/>
                            </div>

                        </div>

                    </div>
                </EkoForm>
            </EkoModal>
        );
    }
}

AccountLockModal
    .propTypes = {
    showModal: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default AccountLockModal;