import React, { Component } from "react";
import {
  EkoForm,
  EkoFormInputText,
  EkoFormSelect
} from "../../components/elements/EkoForm";
// import AutoComplete from '../../components/elements/AutoComplete';
// import { Alert } from 'reactstrap';
// import MoneyText from '../../components/elements/MoneyText';
import * as actions from "../../actions";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import { connect } from "react-redux";
import Util from "../../util/Util";

class AddCompanyOtherInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      formFields: {},
      saving: false,
      successDisabled: false,
      spinner: false
    };
  }

  defaultFormData() {
    return {
      company: {}
    };
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.setState({ formData: formData, errors: errors });
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    this.setState({ formData: formData, errors: {} });
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    self.setState({ saving: true });
    let company = Object.assign({}, self.state.formData.company, {
      expiry_date: Util.convertFromDatabaseDate(
        self.state.formData.company.expiry_date
      )
    });

    let errors = self.state.errors;

    let func = "post";
    let url = "/company";

    if (company.id) {
      func = "put"; //NOTE edit işlemi
      // url = "/company/" + company.id;
    }
    Api[func](url, company, function(response, postErrors) {
      if (postErrors) {
        errors.company = postErrors;
        self.setState({ saving: false, errors: errors });
      } else {
        if (
          response &&
          response.status !== undefined &&
          response.status === "ok"
        ) {
          if (response.company.id) {
            toastr.success("Başarılı", "Başarıyla Güncellendi");
            self.setState({ saving: false, errors: {} });
            self.props.router.push("/dashboard/companyOtherInfo");
          } else {
            self.setState({
              saving: false,
              errors: {},
              formData: self.defaultFormData()
            });
            toastr.error("Hata!", response.message);
          }
        } else {
          self.setState({
            saving: false,
            errors: {},
            formData: self.defaultFormData()
          });
          toastr.error("Hata!", "Veri Kaydedilemedi");
        }

        self.props.onSave();
      }
    });
  }

  cancelOperation() {
    this.props.router.push("/dashboard");
  }

  render() {
    const companyProps = {
      onChange: this.setFieldChange.bind(this, "company"),
      onMount: this.setFieldMount.bind(this, "company"),
      formData: this.state.formData.company,
      errors: this.state.errors.company
    };

    const companyTypes = this.props.viewOptions
      ? this.props.viewOptions.companies.company_type
      : [];
    const bookkeepingTypes = this.props.viewOptions
      ? this.props.viewOptions.companies.bookkeeping_type
      : [];
    const handicapLevels = this.props.viewOptions
      ? this.props.viewOptions.companies.handicap_level
      : [];
    const yesOrNo = this.props.viewOptions
      ? this.props.viewOptions.general.yesOrNo
      : [];
    const officeOwnership = this.props.viewOptions
      ? this.props.viewOptions.companies.office_ownership
      : [];

    return (
      <div>
        <div className="portlet-body">
          <EkoForm formId={"add_company_other_info"}>
            <div className="form-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <EkoFormSelect
                      {...companyProps}
                      id="company_type"
                      optionValue="name"
                      defaultText="Seçiniz"
                      options={companyTypes}
                      label="Şirket Tipi"
                    />
                  </div>
                </div>
                {/*/span*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <EkoFormSelect
                      {...companyProps}
                      id="handicap_level"
                      optionValue="name"
                      defaultText="Seçiniz"
                      options={handicapLevels}
                      label="Kendinin/Bakmakla Yükümlü Olduğu Kişinin Özürlülük Derecesi"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormInputText
                    {...companyProps}
                    id="sgk_code"
                    label="SGK Şirket Sicil Kodu"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormInputText
                    {...companyProps}
                    id="location_code"
                    label="İşyeri Kodu"
                  />
                </div>
              </div>
              {/*/span*/}
            </div>
            {/*/row*/}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormInputText
                    {...companyProps}
                    id="notary_identity_number"
                    label="Matbaa/Noterin Vergi veya TC Kimlik Nosu"
                    mask="99999999999"
                    maskChar=""
                    type="mask"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormSelect
                    {...companyProps}
                    id="bookkeeping_type"
                    optionValue="name"
                    defaultText="Seçiniz"
                    options={bookkeepingTypes}
                    label="Defter Tutma Biçimi"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormSelect
                    {...companyProps}
                    id="is_homeoffice"
                    optionValue="name"
                    defaultText="Seçiniz"
                    options={yesOrNo}
                    label="Home Ofis mi?"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <EkoFormSelect
                    {...companyProps}
                    id="office_ownership"
                    optionValue="name"
                    defaultText="Seçiniz"
                    options={officeOwnership}
                    label="Ofis Mülkiyet Durumu"
                  />
                </div>
              </div>
            </div>
          </EkoForm>
        </div>
        <div className="form-actions">
          <div className="buttonProccess">
            <div className="btn-group" style={{ marginRight: "4px" }}>
              <button
                onClick={this.onSave.bind(this)}
                type="submit"
                className="btn btn-default blue"
              >
                {this.state.saving ? (
                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                ) : (
                  <span>
                    <i className="fa fa-plus"></i> Kaydet
                  </span>
                )}
              </button>
            </div>
            {/* /btn-group */}
            <button
              className="btn dark btn-outline"
              onClick={this.cancelOperation.bind(this)}
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(AddCompanyOtherInfo);
