import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import {EkoForm, EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import {toastr} from "react-redux-toastr";
import Style from "style-it";
import {VCSS} from "../../css/voucher";
import AddExpenseCategory from "./AddExpenseCategory";

class ExpenseCategoryAddUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: this.defaultFormData(),
            expenseName: ""
        }
    }

    defaultFormData() {
        return {
            expense: {
                description:""
            },
            items: {
                date: null,
                payment_method: 0,
                bank_account_id: 0,
                description: null
            },
            rows: [{
                nace_code: '',
                description: '',
                vat_exc: null,
                vat_rate: 0,
                vat_amount: null,
                stoppage_ratio:null,
                stoppage_code:null,
                amount: null,
            }],
            footer: {}
        }
    }

    componentWillMount() {
        if (this.props.params && this.props.params.id) {
            Api.get("/otherReceipt/getExpenseCategories/" + this.props.params.id, (response, error) => {
                this.setState({expenseName:response.expenseCategories.description});
            });
        }
    }

    onSave() {

    }

    onCancel() {
        this.props.router.push("/dashboard/purchases/expensecategory");
    }

    render() {
        return (
            <div className="portlet light ">
                <div className="portlet-title">
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase"> Şablon İşlemleri</span>
                    </div>
                </div>
                {
                    Style.it(
                        VCSS,
                        <div>
                            <AddExpenseCategory
                                onCancel={this.onCancel.bind(this)}
                                onSave={this.onCancel.bind(this)}
                                getDefaultFormData={this.defaultFormData}
                                formData={this.state.formData}
                                expenseId={this.props.params.id}
                            />
                        </div>
                    )
                }
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        expenseCategories: state.data.expenseCategories
    };
}

export default connect(mapStateToProps, actions)(ExpenseCategoryAddUpdate);
