import React, { Component } from "react";
import {
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Layout from "../../components/layouts/Auth";
import Api from "../../util/Api";
import NotFound from "../../components/dashboard/NotFound";
import Loading from "react-loading-spinner";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { browserHistory } from "react-router";

class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { invitation: {} },
      saving: false,
      show: 0,
      errors: {},
      formFields: {}
    };
  }

  componentWillMount() {
    const self = this;
    let code = this.props.params.code;
    if (this.props.authenticated) browserHistory.push("/dashboard");
    Api.post(
      "/auth/invitationCodeCheck",
      { invitation_code: code },
      (response, postErrors) => {
        if (response) {
          if (response.user) {
            // kullanıcı kayıtlı demek
            self.props.router.push("/login");
          } else {
            self.setFieldChange(
              "invitation",
              "email",
              response.invitation.email
            );
            self.setFieldChange("invitation", "invitation_code", code);
            self.setFieldChange("invitation", "company_type", 1); // validationdan geçmesi için baska işlevi yok
            self.setState({ show: 1 });
          }
        } else self.setState({ show: 2 });
      }
    );
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    toastr.removeByType("error");
    toastr.removeByType("success");
    self.setState({ saving: true });

    let user = self.state.formData.invitation;
    user.is_mobile = this.detectMob() ? 1 : 0;

    let errors = self.state.errors;
    Api.post("/user", user, (response, postErrors) => {
      if (postErrors) {
        errors.invitation = postErrors;
        self.setState({ saving: false, errors: errors });
      } else {
        if (this.detectMob())
          toastr.success(
            "üyeliğiniz başarıyla onaylanmıştır. Şifrenizle istediğiniz zaman giriş yapabilirsiniz.",
            { position: "top-left" }
          );
        else
          toastr.success(
            "üyeliğiniz başarıyla onaylanmıştır. Şifrenizle istediğiniz zaman giriş yapabilirsiniz.",
            { position: "bottom-right" }
          );
        self.props.loginUser(self.state.formData.invitation);
      }
    });
  }

  detectMob() {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  render() {
    const invitationProps = {
      onChange: this.setFieldChange.bind(this, "invitation"),
      onMount: this.setFieldMount.bind(this, "invitation"),
      formData: this.state.formData.invitation,
      errors: this.state.errors.invitation
    };

    return (
      <Loading
        isLoading={this.state.show === 0}
        loadingClassName="loading-spinner"
        spinner={EkoSpinner}
      >
        {this.state.show === 1 ? (
          <Layout type="register" containerClass=" container-register">
            <div className="input-icon">
              <i className="fa fa-envelope" />
              <EkoFormInputText
                {...invitationProps}
                id="email"
                disabled
                style={{ paddingLeft: "33px" }}
                label=""
                placeHolder="E-posta"
                isVertical={true}
              />
            </div>
            <div className="input-icon">
              <i className="fa fa-lock" />
              <EkoFormInputText
                {...invitationProps}
                id="password"
                style={{ paddingLeft: "33px" }}
                label=""
                type="password"
                placeHolder="Şifre"
                isVertical={true}
              />
            </div>
            <div className="input-icon">
              <i className="fa fa-lock" />
              <EkoFormInputText
                {...invitationProps}
                id="password_confirmation"
                style={{ paddingLeft: "33px" }}
                label=""
                type="password"
                placeHolder="Şifre Tekrar"
                isVertical={true}
              />
            </div>
            <p>
              Devam ettiğinizde{" "}
              <a
                href="https://www.ekohesap.com/kullanici-sozlesmesi"
                target="_blank"
              >
                Kullanıcı sözleşmesi
              </a>{" "}
              ve{" "}
              <a
                href="https://www.ekohesap.com/gizlilik-sozlesmesi"
                target="_blank"
              >
                Gizlilik sözlesmesi
              </a>
              'ni okumuş ve kabul etmiş sayılırsınız.
            </p>
            <div className="form-actions">
              <EkoFormButton
                label={"Üye Ol"}
                faClass="fa fa-plus"
                className={"g-recaptcha btn blue pull-right"}
                isLoading={this.state.saving}
                onClick={this.onSave.bind(this)}
              />
            </div>
            <br />
            <br />
            <br />
          </Layout>
        ) : (
          <NotFound />
        )}
      </Loading>
    );
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps, actions)(Invitation);
