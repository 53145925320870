import React, {Component} from 'react';
import EkoDataTable from "../../components/elements/EkoDataTable";
import {connect} from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import { toastr } from 'react-redux-toastr';

class RecipeList extends Component {
    constructor(props){
        super(props);
        this.state = {
            recipeList : [],
            loading: true
        }
    }

    componentWillMount() {
        Api.get('/recipe/showAll', (response, err) => {
            if(!err){
                this.setState({
                    recipeList: response.items,
                    loading: false
                })
            }
        })
    }

    openInsert() {
        window.location.replace("/dashboard/recipe/add");
    }

    openUpdate(row) {
        window.location.replace('/dashboard/recipe/edit/' + row.id);
    }

    onDelete({id}){
        Api.delete('/recipe/' + id, (response, errors) => {
            if (errors !== false) {
                toastr.error('Hata!', "Bir hata oluştu, lütfen daha sonra tekrar deneyin!");
            }else{
                if(response.status === "fail"){
                    toastr.error("Hata!", response.message);
                }else{
                    toastr.success('Başarılı!', response.message);
                    const recipeList = [...this.state.recipeList];
                    recipeList.map((item, index) => {
                        if(item.id === id){
                            recipeList.splice(index,1)
                        }
                        return item;
                    })
                    this.setState({
                        recipeList:recipeList
                    })

                }
            }
        })
    }

    render() {

        const insertMenu = this.props.viewOptions && this.props.viewOptions.transferWarehouses ? this.props.viewOptions.transferWarehouses.addRecordMenu : false;


        return (
            <div>
                <EkoDataTable
                    insertMenu={insertMenu}
                    name={"recipe"}
                    onSelectedRowChange={this.onSelectedRowChange}
                    openInsert={this.openInsert.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.state.recipeList}
                    loading={this.state.loading}
                    showDelete
                    title={"Reçete Listesi"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        data: state.data.planing ? state.data.planing.planing : undefined,
        viewOptions: state.data.viewOptions

    }
}

export default connect(mapStateToProps, actions)(RecipeList);

