import React from 'react';
import TinvocieTableTr from "./TinvocieTableTr";
import PropTypes from 'prop-types';
import {CSS} from '../../../css/tinvoice_table';
import Style from 'style-it'
import salaryService from "../../../services/SalaryService";

const TinvoiceTable = props => {

    return Style.it(CSS, <table id={"tinvoice-table"} className={"tinvoice table table-striped table-bordered table-hover"}>
        <thead>
        <tr>
            <th className="fcolumn" colSpan="5">{props.title}</th>
        </tr>
        </thead>

        <tbody>

        {salaryService.getTableData().map((row, index) => {
            return <TinvocieTableTr key={index}
                                    centered={(row[0] && row[0].centered) || false} columns={row}/>

        })}

        </tbody>
    </table>);
};

TinvoiceTable.propTypes = {
    title: PropTypes.string.isRequired
};

export default TinvoiceTable;