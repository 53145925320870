export const CSS = `.chrome {
    background: #ffffff;
    padding: 8px;
    color: #000;
    margin: 0 auto;
    border-bottom: 1px solid #f0f0f0;
}
.chrome-container{
    width: 1150px;
    margin: 0 auto;
	line-height:2
}
.chrome-container>img{
    width: 26px;
    margin-right: 10px;
    vertical-align: top;
}
.chrome-container>i{
    text-align: right;
    float: right;
    padding: 6px;
    font-size: 22px;
    color: #4a8bc2;
    cursor:pointer;
}`
