import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import {
  EkoFormButton,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { FETCH_REPORTS } from "../../actions/types";
import EkoSpinner from "../../components/elements/EkoSpinner";
import EkoModal from "../../components/elements/EkoModal";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      modal: false,
      fields: [],
      formData: {
        rows: [],
        report: {},
        footer: {}
      },
      errors: {
        rows: [], //
        report: {},
        footer: {}
      },
      formFields: {},
      tableData: {
        headers: [
          { label: "Ve/Veya", width: "20%" },
          { label: "Alan", width: "20%" },
          { label: "Kriter", width: "20%" },
          { label: "Değer", width: "40%" }
        ],
        footer: [],
        footerData: {},
        footerTable: [],
        customFooter: null,
        rows: []
      }
    };
  }

  defaultRows() {
    return [
      [
        {
          type: "select",
          id: "and_or",
          optionId: "id",
          disabled: true,
          optionValue: "name",
          defaultText: "SEÇ",
          data: [
            { id: 1, name: "Ve" },
            { id: 2, name: "Veya" }
          ]
        },
        {
          type: "select",
          id: "field",
          optionId: "column_name",
          optionValue: "column_view",
          defaultText: "SEÇ",
          data: []
        },
        {
          type: "select",
          id: "operator",
          optionId: "id",
          optionValue: "name",
          defaultText: "SEÇ",
          data: []
        },
        { type: "text", id: "value", vertical: true }
      ]
    ];
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.fields !== nextState.fields) {
      if (this.state.tableData.rows.length !== 0) {
        const formData = this.state.formData;
        let tableData = this.state.tableData;
        formData.rows[0].and_or = 1;
        tableData.rows[0][1].data = nextState.fields;
        this.setState({ tableData: tableData, formData: formData });
      }
    }
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = { ...this.state.formData };

    let selected = [];
    //let tableData = {...this.state.tableData};
    bigForm[type] = formData;

    if (field === "report_id") {
      selected = this.props.reports.filter(item => {
        return item.id === value;
      });

      if (selected && selected[0] && selected[0].report_name) {
        bigForm.reports = selected[0];
      }

      // tableData.rows = [...this.defaultRows()];
      // bigForm.rows = [this.defaultRowData()]
    }

    this.setState({ formData: bigForm, errors: errors }, () => {
      if (selected[0] && selected[0].report_name) {
        this.fetchFilters(selected[0]);
      }
    });
  }

  fetchFilters(report) {
    this.setState({ fields: report.report_filters });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        and_or: 0,
        field: 0,
        operator: 0,
        value: ""
      }
    );
  }

  componentWillMount() {
    this.props.fetchData("/report/showAll", FETCH_REPORTS);
    if (localStorage.getItem("fetch_reports"))
      localStorage.removeItem("fetch_reports");
  }

  onRowAdd() {
    const self = this;
    let tableData = this.state.tableData;
    const formData = this.state.formData;
    tableData.rows.forEach(function(row, index) {
      if (index !== 0) row[0].disabled = false;
      else formData.rows[0].and_or = 1;
      row[1].data = self.state.fields;
    });
    self.setState({ tableData: tableData, formData: formData });
  }

  onChangeRow(index, rowData, field) {
    let formData = this.state.formData;
    let tableData = this.state.tableData;
    let isAny = tableData.rows.filter((row, index) => {
      return row[4];
    });
    let value = rowData[field];
    if (field === "field") {
      let fieldSelect = formData["reports"]["report_filters"].filter(item => {
        return item.column_name === value;
      });
      let type = fieldSelect[0].field_type;
      let operations = this.operations()[type];
      let getOperation = operations ? operations : [];

      if (tableData.rows[index][4]) {
        delete tableData.rows[index][4];
        if (isAny.length < 2) {
          delete tableData.headers[4];
          tableData.headers[3].width = "40%";
        }
      }
      if (tableData.rows[index][3].disabled)
        tableData.rows[index][3].disabled = false;
      if (type === "date" && tableData.rows[index][3].type !== "date") {
        tableData.rows[index][3].type = "date";
        formData.rows[index]["value"] = "";
      } else if (
        type === "decimal" &&
        tableData.rows[index][3].type !== "money"
      ) {
        tableData.rows[index][3].type = "money";
        formData.rows[index]["value"] = "";
      } else if (
        (type !== "date" && tableData.rows[index][3].type === "date") ||
        (type !== "decimal" && tableData.rows[index][3].type === "money")
      ) {
        tableData.rows[index][3].type = "text";
        formData.rows[index]["value"] = "";
      }
      formData.rows[index]["operator"] = 0;
      tableData.rows[index][2].data = getOperation;
    }
    if (field === "operator") {
      if (value === "between") {
        tableData.headers[4] = { label: "Değer 2", width: "20%" };
        tableData.headers[3].width = "20%";
        tableData.rows[index][4] = Object.assign({}, tableData.rows[index][3], {
          id: "value2"
        });
      }
      if (value === "is null" || value === "is not null") {
        tableData.rows[index][3].disabled = true;
      }
      if (
        !(value === "is null" || value === "is not null") &&
        tableData.rows[index][3].disabled
      )
        tableData.rows[index][3].disabled = false;
      if (value !== "between" && tableData.rows[index][4]) {
        delete tableData.rows[index][4];
        if (isAny.length < 2) {
          delete tableData.headers[4];
          tableData.headers[3].width = "40%";
        }
      }
    }
    formData.rows[index][field] = value;
    this.setState({ formData: formData, tableData: tableData });
  }

  //
  resetErrorForTable(index, field) {
    let errors = this.state.errors;
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;
    this.setState({ errors: errors });
  }

  onRealChange(index, field) {
    // error temizle
    this.resetErrorForTable(index, field);
  }

  onRowDelete(index, row) {
    const self = this;
    let tableData = this.state.tableData;
    let errors = this.state.errors;
    const formData = this.state.formData;
    if (index === 0 && tableData.rows[0]) {
      tableData.rows[0][0].disabled = true;
      formData.rows[0].and_or = 1;
    }
    delete errors.rows[Object.keys(errors.rows).length - 1];
    self.setState({ tableData: tableData, formData: formData, errors: errors });
  }

  onChange() {}

  onValidation() {
    // error degerler boş ise error bas
    let tableData = this.state.tableData;
    let rows = this.state.formData.rows;
    let errors = this.state.errors;
    let error = false;
    rows.forEach((row, index) => {
      errors.rows[index] = {
        and_or: false,
        field: false,
        operator: false,
        value: false
      };
      if (index !== 0 && !row.and_or) {
        errors.rows[index]["and_or"] = ["Zorunlu alan"];
        error = true;
      }
      if (!row.field) {
        errors.rows[index]["field"] = ["Zorunlu alan"];
        error = true;
      }
      if (!row.operator) {
        errors.rows[index]["operator"] = ["Zorunlu alan"];
        error = true;
      }
      if (!tableData.rows[index][3].disabled && !row.value) {
        errors.rows[index]["value"] = ["Zorunlu alan"];
        error = true;
      }
      if (tableData.rows[index][4] && !row.value2) {
        errors.rows[index]["value2"] = ["Zorunlu alan"];
        error = true;
      }
    });
    return error ? errors : false;
  }

  onCreate() {
    if (!this.state.formData.reports) {
      let errors = this.state.errors;
      errors.report.report_id = ["Lütfen rapor seçiniz"];
      return this.setState({ errors: errors });
    }
    const self = this;
    self.setState({ saving: true });
    const has_dynamic_filter = this.state.formData.reports.has_dynamic_filter;
    const url = this.state.formData.reports.api_name;
    const name = this.state.formData.reports.report_name;
    let params = { filters: [], hasDynamic: false };
    if (has_dynamic_filter === 1) {
      let data = JSON.parse(JSON.stringify(self.state.formData)); // object.assign calışmadı ne hikmetse
      let rows = data.rows;
      let tableData = this.state.tableData;
      let errors = this.onValidation();

      if (errors) return self.setState({ errors: errors, saving: false });

      rows.forEach((row, index) => {
        if (
          tableData.rows[index][4] &&
          row.value2 &&
          tableData.rows[index][4].type === "money"
        ) {
          rows[index].value2 = Util.convertMoneyToDecimal(
            rows[index].value2 || "0,00"
          );
        }
        if (tableData.rows[index][3].type === "money") {
          rows[index].value = Util.convertMoneyToDecimal(
            rows[index].value || "0,00"
          );
        }
      });
      params = { filters: rows, hasDynamic: true };
    }

    Api.postArrayBuffer(url, params, (response, err) => {
      if (response) {
        // let decodedString = String.fromCharCode.apply(null, new Uint8Array(response));
        // if (decodedString.length < 5000) console.log(decodedString);// silinecek
        // else {
        let blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let downloadUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        moment.locale("tr");
        a.href = downloadUrl;
		if(name.includes("- Csv")){
			a.download = name + " " + moment().format("DD-MM-YYYY HH_mm_ss") + ".csv";
		}else{
			a.download = name + " " + moment().format("DD-MM-YYYY HH_mm_ss") + ".xlsx";
		}
        
        document.body.appendChild(a);
        a.click();
        // }
        self.setState({ saving: false });
      } else {
        let decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(err)
        );
         let obj = JSON.parse(decodedString);
         toastr.error("Hata", obj.message);
         self.setState({saving:false})
      }
    });
  }

  operations() {
    return {
      string: [
        { id: "%like", name: "sonu ... ile Bitenler" },
        { id: "like%", name: "başı ... İle Başlayanlar" },
        { id: "like", name: "İçerir" },
        { id: "not like", name: "İçermez" },
        { id: "=", name: "Eşittir" },
        { id: "!=", name: "Eşit Değil" },
        { id: "is null", name: "Boş Olan" },
        { id: "is not null", name: "Dolu Olan" }
      ],
      decimal: [
        { id: "between", name: "Arasında" },
        { id: ">", name: "Büyüktür" },
        { id: "<", name: "Küçüktür" },
        { id: ">=", name: "Büyük veya Eşit" },
        { id: "<=", name: "Küçük veya Eşit" },
        { id: "=", name: "Eşittir" },
        { id: "!=", name: "Eşit Değil" },
        { id: "is null", name: "Boş Olan" },
        { id: "is not null", name: "Dolu Olan" }
      ],
      date: [
        { id: "between", name: "Arasında" },
        { id: ">", name: "Büyüktür" },
        { id: "<", name: "Küçüktür" },
        { id: ">=", name: "Büyük veya Eşit" },
        { id: "<=", name: "Küçük veya Eşit" },
        { id: "=", name: "Eşittir" },
        { id: "!=", name: "Eşit Değil" },
        { id: "is null", name: "Boş Olan" },
        { id: "is not null", name: "Dolu Olan" }
      ]
    };
  }
  modal(value) {
    this.setState({ modal: value });
  }
  render() {
    if (!this.props.reports) return <EkoSpinner />;
    const reportProps = {
      onChange: this.setFieldChange.bind(this, "report"),
      onMount: this.setFieldMount.bind(this, "report"),
      formData: this.state.formData.report,
      errors: this.state.errors.report
    };
    const time = new Date();
    const reports = this.props.reports;
    const row = this.defaultRows()[0];
    const has_dynamic_filter =
      this.state.formData.reports &&
      this.state.formData.reports.has_dynamic_filter;
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {" "}
              Rapor Oluştur
            </span>
              </div>
            </div>
            <div className="portlet-body">
              <div className="row">
                <div className="col-md-4">
                  <EkoFormSelect
                      {...reportProps}
                      searchable={true}
                      id="report_id"
                      optionValue="report_name"
                      options={reports}
                      optionId="id"
                      label="Raporlar"
                      isVertical={true}
                  />
                </div>
                <div className="col-md-8" style={{"left": "530px"}}>
                  <EkoFormButton
                      isLoading={this.state.saving}
                      label="WEB RAPORLARINI GÖSTER"
                      faClass="fa fa-list"
                      onClick={this.modal.bind(this, true)}
                  />
                </div>
                {has_dynamic_filter === 1 ? (
                    <div className="col-md-10 col-md-push-1">
                      <br/>
                      <br/>
                      <EkoInputTableButtonGroup
                          data={this.state.tableData}
                          addBtnTitle={"Filtre Ekle"}
                          formData={this.state.formData}
                          errors={this.state.errors}
                          saving={this.state.saving}
                          onRowAdd={this.onRowAdd.bind(this)}
                          defaultRow={this.defaultRowData}
                          onChangeRow={this.onChangeRow.bind(this)}
                          onRealChange={this.onRealChange.bind(this)}
                          onChange={this.onChange.bind(this)}
                          onRowDelete={this.onRowDelete.bind(this)}
                          formId={"report_filter_column"}
                          allDelete={true}
                          row={row}
                      />
                    </div>
                ) : (
                    ""
                )}
                <br/>
                <br/>
              </div>
              <div>
                <EkoFormButton
                    isLoading={this.state.saving}
                    label="EXCEL OLUŞTUR"
                    faClass="fa fa-eye"
                    onClick={this.onCreate.bind(this)}
                />
              </div>
            </div>
            <EkoModal
                showModal={this.state.modal}
                title="Web Raporları"
                bsSize="xlg" // xlg
                onHide={() => {
                  this.setState({
                    modal: false
                  });
                  //this.onCancel();
                }}
                spinner={this.state.saving}
            >
              <iframe
                  style={{overflow: 'visible'}}
                  ref="iframe"
                  src={apiUrl + "rapor/index.php?time=" + time.getTime() + "&t=" + localStorage.getItem('token')}
                  scrolling="auto"
                  frameBorder="0"
                  width="100%"
                  height={800}
              />
            </EkoModal>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.data.reports
  };
}

export default connect(mapStateToProps, actions)(Reports);
