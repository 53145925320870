import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import axios from 'axios';
import {ROOT_URL, setHeaders} from '../util/Api';
import CompanyTypePermission from "./CompanyTypePermission";

let fetching = null;

const fetchSelfEmploymentData = () => {
    if (!sessionStorage.getItem('self_employment_default') && CompanyTypePermission.isSelfEmployment()) {

        setHeaders();
        fetching = axios.get(ROOT_URL + '/selfEmployment');
    }
};


const selfEmploymentDataService = () => {

    if (!sessionStorage.getItem('self_employment_default')) {
        Observable.interval(30000).merge(Observable.of(0))
            .mergeMap(() => {
                if (fetching) return Observable.fromPromise(fetching);
                else return [];
            })
            .map(result => result.data.data)
            .subscribe(data => {
                sessionStorage.setItem('self_employment_default', JSON.stringify(data));
            });
    }

};

const getSelfEmploymentData = () => {
    fetchSelfEmploymentData();
    selfEmploymentDataService();
};


export const parseSelfEmploymentData = () => {
    return JSON.parse(sessionStorage.getItem('self_employment_default'));
};

export default getSelfEmploymentData;