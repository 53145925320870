import React from "react";
import AddDocumentButton from "../forms/AddDocumentButton";
import { EkoFormDate, EkoFormInputText, EkoFormSelect } from "./EkoForm";

export default class EkoHeaderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      formFields: {},
      errors: {},
    };
  }

  defaultFormData() {
    return {
      items: {},
    };
  }

  componentDidMount() {
    this.props.setReset(this.reset);
  }

  reset = () => {
    console.log("RESET");
    this.setState({ formData: { items: {} }, errors: {} });
  };

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    this.setState({ formData: bigForm, errors: errors });
    if (this.props.onChangeFields) {
      this.props.onChangeFields(bigForm,type, field, value);
    }
    if (this.props.onChangeField) {
      this.props.onChangeField(field, value);
    }
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  getInputByType(field) {
    const inputProps = {
      onChange: this.setFieldChange.bind(this, "items"),
      onMount: this.setFieldMount.bind(this, "items"),
      formData: this.props.formData || this.state.formData.items,
      errors: this.props.errors || this.state.errors.items,
    };
    let rElement = null;
    switch (field.type) {
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={false}
            type="text"
            isVertical={true}
            label={field.title}
          />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={false}
            type="number"
            isVertical={true}
            label={field.title}
          />
        );
        break;
      case "money":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={false}
            type="money"
            isVertical={true}
            label={field.title}
          />
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            isVertical={true}
            clearable={field.clearable || true}
            label={field.title}
          />
        );
        break;
      case "select":
        rElement = (
          <EkoFormSelect
            {...inputProps}
            searchable={field.searchable || false}
            id={field.id}
            isVertical={true}
            defaultText={field.defaultText}
            optionId={field.optionId}
            options={field.data}
            optionValue={field.optionValue}
            label={field.title}
          />
        );
        break;
      default:
    }
    return rElement;
  }

  render() {
    const fields = this.props.fields;
    return (
      <div className="portlet light">
        <div
          className="portlet-title"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {this.props.title}
            </span>
          </div>
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              flex: "auto",
            }}
          >
            <AddDocumentButton
              style={{ margin: 0, transform: "translateY(-25%)" }}
            />
          </div>
        </div>
        {/* <div className="portlet-title">
                    <div className="caption font-dark">
						<span className="caption-subject bold uppercase">
							{this.props.title}
						</span>
                    </div>
                </div> */}
        <div className="portlet-body" id={this.props.formId || null}>
          <div className="alldiv">
            {fields.map((field, index) => (
              <div
                className={`col-md-${
                  field.col || parseInt(12 / fields.length, 10)
                }`}
                key={index}
              >
                {field.rows?.length ? (
                  <div className={`col-md-12 row`} key={index}>
                    {field.addComponentKey &&
                    this.props[field.addComponentKey] ? (
                      <div className="input-icon right row col-md-12">
                        <div className="col-md-8">
                          {this.getInputByType(field)}
                        </div>
                        {this.props[field.addComponentKey]}
                      </div>
                    ) : (
                      <div className="input-icon right">
                        <span className>{this.getInputByType(field)}</span>
                      </div>
                    )}
                    {field.rows.map((r) => (
                      <div className="right">
                        <span className>{this.getInputByType(r)}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="input-icon right">
                    <span className>{this.getInputByType(field)}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="clearfix" />
          <div style={{ marginTop: 90 }}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
