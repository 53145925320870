import React, {Component} from 'react'
import {Link} from 'react-router'
import ReduxToastr from 'react-redux-toastr'
import {CSS} from '../../css/login'
import Style from 'style-it'

export default class Auth extends Component {

    static defaultProps = {
        containerClass: "container",
    }

    render() {
        document.body.style.backgroundColor = "#f0f0f0";
        return (
            Style.it(
                CSS,
            <div>
                <div className="text-center ehlogo">
                    <Link to="/dashboard">
                        <img src="/app/images/logo-default.png" alt="logo" className="logo-default"/>
                    </Link>
                </div>
                <div className={this.props.containerClass}>
                    <div className={this.props.type}>
                        {this.props.children}
                    </div>
                </div>
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-left"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                />
            </div>
            )
        )
    }
}
