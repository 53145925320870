import React, { Component } from "react";
import EkoModal from "../elements/EkoModal";
import { EkoFormButton, EkoFormInputText } from "../elements/EkoForm";
import { Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "../elements/EkoSpinner";

class CopyTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { template: {} },
      properties: null,
      saving: false,
      errors: {},
      formFields: {}
    };
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    const self = this;
    if (nextProps.copyId) {
      Api.get("/template/" + nextProps.copyId, (response, err) => {
        if (response) {
          self.setState({ properties: response.template.properties });
        } else toastr.error("Hata", "Şablon Bulunamadı");
      });
    }
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  closeModal() {
    this.setState({
      saving: false,
      errors: {},
      formData: { template: {} },
      properties: null
    });
    this.props.closeModal();
  }

  onSave() {
    const self = this;
    let template = {
      name: this.state.formData.template.name,
      properties: this.state.properties
    };
    Api.post("/template", template, (response, error) => {
      if (response) {
        toastr.success("Başarılı", "Şablon Başarıyla Kopyalandı");
        self.props.afterSave();
        self.closeModal();
      } else {
        try {
          Object.values(error).map(item => {
            if (typeof item === "object") {
              toastr.error("Hata", item[0]);
            }
            return item;
          });
        } catch (e) {
          toastr.error(
            "Hata",
            "Sistem hatası lütfen daha sonra tekrar deneyiniz."
          );
        }
      }
    });
  }

  render() {
    const valueProps = {
      onChange: this.setFieldChange.bind(this, "template"),
      onMount: this.setFieldMount.bind(this, "template"),
      formData: this.state.formData.template,
      errors: this.state.errors.template
    };
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={"Şablonu Kopyala"}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <div className="row">
          {!this.state.properties ? (
            <div className={"col-md-push-1 col-md-10"}>
              <p>Lütfen bekleyiniz</p>
              <hr />
              <EkoSpinner />
            </div>
          ) : (
            <div className={"col-md-push-1 col-md-10"}>
              <div className="col-md-push-2 col-md-10">
                <EkoFormInputText
                  {...valueProps}
                  label="Şablon ismi"
                  id="name"
                />
              </div>
            </div>
          )}
        </div>
        {!this.state.properties ? null : (
          <Modal.Footer>
            <br />
            <EkoFormButton
              label={"Kopyala"}
              faClass="fa fa-copy"
              isLoading={this.state.saving}
              onClick={this.onSave.bind(this)}
            />
            <EkoFormButton
              label="İptal"
              disabled={this.state.saving}
              className="btn"
              onClick={this.closeModal.bind(this)}
            />
          </Modal.Footer>
        )}
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(CopyTemplateModal);
