import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddReceipt from "./AddReceipt";
import Api from "../../util/Api";
import Util from "../../util/Util";
import Style from "style-it";
import { VCSS } from "../../css/voucher.js";
import {
  FETCH_BANK_ACCOUNTS_ALL,
  FETCH_EMPLOYEES_ALL
} from "../../actions/types";

class ReceiptAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      hidden: false
    };
  }

  redirectToBase() {
    this.props.router.push("/dashboard/purchases/receipt");
  }

  // defaultFormData2() {
  //   return {
  //     receipt: {
  //       date: null,
  //       payment_method: 0,
  //       bank_account_id: 0,
  //       receipt_no: null
  //     },
  //     rows: [1,2,3,4].map(v => ({
  //         expense_category_id: 0,
  //         nace_code_id: 0,
  //         description: '',
  //         amount: null,
  //         vat_rate: v,
  //         vat_amount: null
  //     })),
  //     footer: {}
  //   };
  // }

  defaultFormData() {
    return {
      receipt: {
        date: null,
        payment_method: 0,
        bank_account_id: 0,
        receipt_no: null
      },
      rows: [{
          expense_category_id: 0,
          nace_code_id: 0,
          description: '',
          amount: null,
          vat_rate: 0,
          vat_amount: null
      }],
      footer: {}
    };
  }

  fetchBankAccounts() {
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    this.props.fetchData("/employee/showAllActive", FETCH_EMPLOYEES_ALL);
  }

  componentWillReceiveProps(nextProps) {
    const id = nextProps.router.params.id;
    let docUrl = null;
    let type = null;
    if (nextProps.params.id && nextProps.bankAccounts && nextProps.employees) {
      let formData = this.state.formData;
      const vatRates = this.props.vatRateTypes;
      const self = this;
      Api.paralel(
        [
          "/receipt/" + nextProps.params.id,
          "/receiptLine/showAll/" + nextProps.params.id
        ],
        function(responses, error) {
          formData.rows = responses[1].receiptLines;
          formData.receipt = responses[0].receipt;
          docUrl = responses[0].receipt?.document_url;
          if(docUrl && self.props.setAddDocument){
            type = docUrl.split('.').pop()
          }
          formData.receipt.amount = responses[0].receipt.total_amount;
          formData.footer.amount = Util.convertDecimalToMoney(
            formData.receipt.amount
          );
          formData.footer.vat_amount = Util.convertDecimalToMoney(
            formData.receipt.vat_amount
          );
          formData.footer.total_amount = Util.convertDecimalToMoney(
            formData.receipt.amount + formData.receipt.vat_amount
          );
          formData.receipt.date = Util.convertFromDatabaseDate(
            formData.receipt.date
          );
          if (
            formData.receipt.employee_id &&
            formData.receipt.payment_method === 4
          )
            formData.receipt.bank_account_id = formData.receipt.employee_id;
          formData.rows = formData.rows.map(function(row) {
            const rateV = vatRates.find(v => v.id === row.vat_rate_type_id)?.rate
            row.vat_rate = row.vat_rate_type_id;
            row.vat_amount = Util.convertDecimalToMoney(
              (row.total_amount / (1 + rateV)) *
              rateV
            );
            // row.vat_amount = Util.convertDecimalToMoney(row.vat_amount);
            row.amount = Util.convertDecimalToMoney(row.total_amount);
            return row;
          });

          self.setState({ formData: formData }, () => {
            if(docUrl && self.props.setAddDocument){
              self.props.setAddDocument({uri: docUrl, type});;
            }
          });
        }
      );
    } else {
      this.setState({ formData: this.defaultFormData() });
    }
  }

  redirectToAdd() {
    this.props.router.push("/dashboard/purchases/receipt/add");
  }

  onCancel() {
    this.props.router.push("/dashboard/purchases/receipt");
  }

  componentWillMount() {
    this.fetchBankAccounts();
  }

  recreate = () => {
    this.setState(
      {
        hidden: true
      },
      () => this.setState({ hidden: false })
    );
  };

  render() {
    if (this.props.bankAccounts && this.props.employees) {
      return Style.it(
        VCSS,
        <div>
          <AddReceipt
            onCancel={this.onCancel.bind(this)}
            onSave={this.onCancel.bind(this)}
            bankAccounts={this.props.bankAccounts}
            employees={this.props.employees}
            getDefaultFormData={this.defaultFormData}
            redirectToAdd={this.redirectToAdd.bind(this)}
            router={this.props.router}
            formData={this.state.formData}
            receiptId={this.props.router.params.id}
            redirectToBase={this.redirectToBase.bind(this)}
            viewOptions={this.props.viewOptions}
          />
        </div>
      );
    } else return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    bankAccounts: state.data.bankAccountsAll,
    employees: state.data.employeesAll,
    viewOptions: state.data.viewOptions,
    vatRateTypes: state.data.vatRateTypes,
  };
}

export default connect(mapStateToProps, actions)(ReceiptAddUpdate);
