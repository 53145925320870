import React from 'react';
import PropTypes from 'prop-types';


const TableColumnInput = (props) => {

    let element = null;

    switch (props.type) {
        case 'money':
            element = <input type="text" readOnly={true}  value={props.value} className={"form-control3 tright money"}/>;
            break;
        default:
            element = <input type="text" readOnly={true}  value={props.value} className={"form-control3 tright"}/>;

            break;
    }

    return element;
};

TableColumnInput.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
};

export default TableColumnInput;