import NotificationService from "./NotificationService";
import moment from 'moment';


const OneSignal = window.OneSignal || [];

const notification = new NotificationService();

function shouldAsk(user, lastAskTime) {
    if (user && user.is_subscribed === 1) {

        if (!lastAskTime) {
            return true;
        }

        const nextTime = moment(lastAskTime).add(4, "days");
        const now = moment();


        return now.isAfter(nextTime);

    }

    return false;
}

export function initOneSignal() {
    const user = JSON.parse(localStorage.getItem('user'));
    const lastAskTime = localStorage.getItem('last_ask_time');

    console.log(shouldAsk(user, lastAskTime), user, lastAskTime);

    if (shouldAsk(user, lastAskTime)) {

        let userData = {
            email: user.email,
            firstname: user.firstname || "",
            lastname: user.lastname || "",
            phone: user.phone || "",
            company_type: user.company_type,
            role_id: user.role_id,
            plan_id: user.plan_id
        };


        OneSignal.push(function () {
            localStorage.setItem('last_ask_time', moment().toISOString());

            OneSignal.init({
                appId: process.env.REACT_APP_ONESIGNAL_APP
            });


            OneSignal.on('subscriptionChange', function (isSubscribed) {
                if (isSubscribed) {
                    // The user is subscribed
                    //   Either the user subscribed for the first time
                    //   Or the user was subscribed -> unsubscribed -> subscribed
                    OneSignal.getUserId(function (userId) {
                        notification.saveId(userId).then((data) => {
                            OneSignal.sendTags(userData)
                        })
                    });
                }
            });
        });

    }
}

