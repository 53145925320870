import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import { EkoForm, EkoFormButton } from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import Api from "../../util/Api";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

class designerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { print: {} },
      saving: false,
      errors: {},
      formFields: {},
      uploadImage: false,
      imageUrl: null
    };
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.paperBackground) {
      this.setState({ imageUrl: nextProps.paperBackground });
    }
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  handleImageChange(e) {
    e.preventDefault();
    const self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    var form = new FormData();
    form.append("image", file);
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/gif" &&
      file.type !== "image/png"
    )
      return toastr.error("Hata", "Lütfen geçerli bir resim dosyası seçiniz!");
    reader.onloadend = () => {
      self.setState({ uploadImage: true });
      Api.post("/template/uploadImage", form, (response, err) => {
        if (response) {
          self.setState({ imageUrl: response.url, uploadImage: false });
        } else {
          toastr.error("Hata", err.message);
          self.setState({ uploadImage: false });
        }
      });
    };

    reader.readAsDataURL(file);
  }

  clearFile() {
    this.setState({ imageUrl: null });
  }

  onClick() {
    this.setState({
      saving: false,
      errors: {},
      formData: { print: {} },
      uploadImage: false,
      imageUrl: null
    });
    this.props.onPaperBackgroundChange(this.state.imageUrl);
    this.props.openOrCloseModal(false);
  }

  closeModal() {
    this.setState({
      saving: false,
      errors: {},
      formData: { print: {} },
      uploadImage: false,
      imageUrl: null
    });
    this.props.openOrCloseModal(false);
  }

  render() {
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={"Taranmış Zemin Görseli Yükle"}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <EkoForm>
          <div>
            <div className="row">
              <div className="col-md-3">
                <figure className="scanned-img-steps">
                  <img
                    src="/app/images/1.svg"
                    width={36}
                    alt="1. Faturanızı taratın"
                    className="center-block"
                  />
                </figure>
              </div>
              <div className="col-md-9">
                <h5>
                  <strong>1. Faturanızı taratın</strong>
                </h5>
                <p>
                  Matbu fatura veya irsaliyenizi bir tarayıcı ile JPG veya PNG
                  formatında tarayın.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <figure className="scanned-img-steps">
                  <img
                    src="/app/images/2.svg"
                    width={36}
                    alt="2. Görseli düzenleyin"
                    className="center-block"
                  />
                </figure>
              </div>
              <div className="col-md-9">
                <h5>
                  <strong>2. Görseli düzenleyin</strong>
                </h5>
                <p>
                  Yükleyeceğiniz görselin faturanın tam köşelerinde bittiğine ve
                  etrafında boşluk olmadığına emin olun.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <figure className="scanned-img-steps">
                  <img
                    src="/app/images/3.svg"
                    width={36}
                    alt="3. Görseli yükleyin"
                    className="center-block"
                  />
                </figure>
              </div>
              <div className="col-md-9">
                <h5>
                  <strong>3. Görseli yükleyin</strong>
                </h5>
                <p>YÜKLE butonuna basarak taradığınız görseli yükleyin.</p>
                {!this.state.imageUrl ? (
                  <label
                    className="button_style"
                    style={{ padding: "10px 15px" }}
                  >
                    <input
                      type="file"
                      required
                      style={{ position: "fixed", top: "-1000px" }}
                      onChange={e => this.handleImageChange(e)}
                      disabled={this.state.uploadImage}
                      ref="file"
                    />
                    {this.state.uploadImage ? (
                      <i className={"fa fa-spinner fa-pulse fa-1x fa-fw"} />
                    ) : (
                      <span>
                        <i className={"fa fa-upload"} /> YÜKLE
                      </span>
                    )}
                  </label>
                ) : (
                  ""
                )}
                {this.state.imageUrl ? (
                  <div className="uploaded_preview">
                    <a
                      onClick={this.clearFile.bind(this)}
                      className="del_button"
                    >
                      <i className="fa fa-trash" />
                    </a>
                    <img
                      src={this.state.imageUrl}
                      alt="Resim Bulunamadı!"
                      width={100}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Modal.Footer>
            <br />
            <EkoFormButton
              label={"Kaydet"}
              faClass="fa fa-plus"
              onClick={this.onClick.bind(this)}
            />
            <EkoFormButton
              label="İptal"
              className="btn"
              onClick={this.closeModal.bind(this)}
            />
          </Modal.Footer>
        </EkoForm>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(designerModal);
