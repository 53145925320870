import React, {useState, Component} from 'react';
import styled from 'styled-components';
import {IconContext} from 'react-icons';
import {
    FiArrowUpCircle,
    FiArrowDownCircle,
    FiCheckSquare,
    FiAlertTriangle,
    FiEye,
    FiInfo,
    FiUpload,
    FiRefreshCcw,
    FiTrash
} from 'react-icons/fi';
import {connect} from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {EkoFormDate} from "../../components/elements/EkoForm";

class DBSSalesSInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: {},
            errors: {},
            validationFields: [],
            dbssales: [],
            vat_period: [],
            gelirTuru: [],
            gelirTuruOpt: [],
            gelirKayitTuru: [],
            gelirKayitTuruOpt: [],
            gelirKayitAltTuru: [],
            gelirKayitAltTuruOpt: [],
            checkBox: [],
            dbsSendButtonDisableController:false,
            showSend:false,
            showNotSend:false,
            showError:false,
            showAll:true,
            rowCount:null,
            Data: [
                {
                    date: '-',
                    invoiceNumber: '-',
                    description: '-',
                    who: '-',
                    amount: '-',
                    dbsSendStatus: 0,
                    dbsErrorStatus: 0,
                    dbsUpdatedError: 0,
                    is_checked: 0,
                    einvoice_typeText:""
                }]
        };
    }

    dbsSelectChange = (val, type, count, invoiceLineId, invoiceId,index) => {
        this.setState({loading: true});
        if (type == 'gelirTuru') {
            this.state.gelirTuru[count].val = val;
        } else if (type == 'gelirKayitTuru') {
            this.state.gelirKayitTuru[count].val = val;
        } else if (type == 'gelirKayitAltTuru') {
            this.state.gelirKayitAltTuru[count].val = val;
        }

        var params = {
            type_id: 0, // 0:satış,1:alış
            type_name: "gelirFatura",
            invoice_id: invoiceId,
            invoice_line_id: invoiceLineId,
            code_type: type,
            val: val
        };
        Api.post("/DBS/dbsRowRecordAddOrUpdate", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                this.state.gelirTuru[count].val = response.dbsParameterSelectedVal.satisTuruKodu;
                this.state.gelirKayitTuru[count].val = response.dbsParameterSelectedVal.gelirKayitTuruKodu;
                this.state.gelirKayitAltTuru[count].val = response.dbsParameterSelectedVal.gelirKayitAltTuruKodu;

                this.state.gelirKayitTuruOpt[count].val = response.dbsParameter.gelirKayitTuruKodu;
                this.state.gelirKayitAltTuruOpt[count].val = response.dbsParameter.gelirKayitAltTuruKodu;
                toastr.success("Başarılı", "Başarıyla Güncellendi");
            }
        });
        this.setState({loading: false});
    };

    naceCodeChangeVal = (val,invoiceLineId,dataIndex,rowIndex) => {
        this.setState({loading: true});
        var params = {
            type_name: "gelirFatura",
            invoice_line_id: invoiceLineId,
            val: val
        };
        Api.post("/DBS/dbsNaceCodeUpdate", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                this.state.Data[dataIndex].rows[rowIndex].nace_code_id = val;
                toastr.success("Başarılı", "Başarıyla Güncellendi");
            }
        });
        this.setState({loading: false});
    };

    dbsCheckboxChange = (invoiceId) => {
        invoiceId = parseInt(invoiceId);
        this.state.checkBox.find((element, index) => {
            if (element.id === invoiceId) {
                if (this.state.checkBox[index].val == true) {
                    this.state.checkBox[index].val = false;
                } else if (this.state.checkBox[index].val == false) {
                    this.state.checkBox[index].val = true;
                }
            }
        })
    };
    addVatPeriod = (vat_period) => {
        var findItem = this.state.vat_period.find((x) => x.vat_period === vat_period);
        if (!findItem) this.state.vat_period.push({vat_period:vat_period});
    };
    fetchCollections() {
        this.loadData();
        this.loadData("","",1);//dbsteki farklılıkları kontrol etmek için 2. bir istek atıyoruz
    }

    loadData(start_date="",end_date="",proccess=0) {
        if(proccess===0){this.setState({loading: true});}
        var totalData = [];
        if(start_date==''){
            var url = "/DBS/sSales/list?proccess="+proccess;
        }else{
            var url = "/DBS/sSales/list/"+start_date+"/"+end_date+"?proccess="+proccess;
        }
        Api.get(url, (response, err) => {
            {
                response.dbssales.map((val, index) => {
                    var rowsData = [];
                    this.state.checkBox.push({id: val.id, val: false});
                    {
                        val.rows.map((valLines, indexLines) => {
                            this.addVatPeriod(val.vat_periods);
                            rowsData.push(
                                {
                                    id: valLines.id,
                                    item: valLines.item_code?valLines.item_code:valLines.description,
                                    description: valLines.description,
                                    quantity: valLines.quantity,
                                    unit_price: valLines.unit_price,
                                    unit_str: valLines.unit_str,
                                    kdv: valLines.vat_rate_type_rate,
                                    dbsParameter: valLines.dbsParameter,
                                    dbsParameterSelectedVal: valLines.dbsParameterSelectedVal,
                                    kdv_amount: valLines.vat_amount,
                                    total_amount: valLines.total_amount,
                                    rowError: valLines.rowError,
                                    rowErrorDescription: valLines.rowErrorDescription,
                                    nace_code_id: valLines.nace_code_id,
                                }
                            );
                        })
                    }
                    totalData.push(
                        {
                            id: val.id,
                            date: val.date,
                            invoiceNumber: `${val.legal_serial || ''}${val.legal_no || ''}${val.legal || ''}`,
                            description: val.description,
                            who: val.to_whom,
                            amount: val.receivable_amount_str,
                            vat_period: val.vat_periods,
                            dbsSendStatus: val.dbsSendStatus,
                            dbsErrorStatus: val.dbsErrorStatus,
                            dbsUpdatedError: val.dbsUpdatedError,
                            einvoice_typeText:val.einvoice_typeText,
                            is_checked: val.is_checked,
                            rows: rowsData
                        }
                    );
                })
            }
            if(proccess===0){
                this.setState({Data: totalData, loading: false,rowCount:totalData.length});
                this.render();
            }
        });

    }

    componentWillMount() {
        this.fetchCollections();
    }

    showHideDiv = (type) => {
        if(type=='send'){
            this.setState({showSend:true,showNotSend:false,showError:false,showAll:false});
        }
        else if(type=='notSend'){
            this.setState({showSend:false,showNotSend:true,showError:false,showAll:false});
        }
        else if(type=='error'){
            this.setState({showSend:false,showNotSend:false,showError:true,showAll:false});
        }
        else if(type=='all'){
            this.setState({showSend:false,showNotSend:false,showError:false,showAll:true});
        }
        this.render();
    }

    styledGetClass(dbsStatus){
        //notSend, send, error
        if(this.state.showAll==true){
            return "block";
        }else if(this.state.showSend==true && dbsStatus=='send'){
            return "block";
        }else if(this.state.showNotSend==true && dbsStatus=='notSend'){
            return "block";
        }else if(this.state.showError==true && dbsStatus=='error'){
            return "block";
        }else{
            return "none";
        }

    }

    openLink(selectedRow) {
        const newWindow = window.open("/dashboard/salesInvoice/edit/" + selectedRow, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    flushCheckbox = () => {
        this.state.checkBox.find((element, index) => {
            this.state.checkBox[index].val=false;
        });
    }
    dbsSend(){
        Api.get("/company/dbs-required-check", (data, errors) => {
            if(data.data.result!=0){
                toastr.error("Hata!",data.data.message);
            }else{
                this.setState({dbsSendButtonDisableController:true});
                var selectedInvoiceList = [];
                this.state.checkBox.map((listValue, index) => {
                    if(listValue.val === true && selectedInvoiceList.indexOf(listValue.id) === -1){
                        selectedInvoiceList.push(listValue.id);
                    }
                });
                if(selectedInvoiceList.length!=0){
                    var params = {
                        type_id: 0, // 0:satış,1:alış
                        type_name: "gelirFatura",
                        invoice_id: selectedInvoiceList,
                    };
                    Api.post("/DBS/send", params, (response, err) => {
                        if (err != false) {
                            this.setState({dbsSendButtonDisableController:false});
                            selectedInvoiceList = [];
                            this.flushCheckbox();
                            toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                        } else {
                            this.setState({dbsSendButtonDisableController:false});
                            selectedInvoiceList = [];
                            this.flushCheckbox();
                            toastr.success("Başarılı", "Kayıtlar Gönderildi.");
                            //setTimeout(window.location.reload(false), 3000);
                        }
                    });


                }else{
                    this.setState({dbsSendButtonDisableController:false});
                    toastr.error("Hata!", "En Az 1 Kayıt Seçmelisiniz");
                }
            }
        });
    }

    allCheckboxSelected(){
        this.flushCheckbox();
        var checkboxesFlush = document.querySelectorAll('.form-check-input');
        var is_true = false;
        for (var x = 0; x < checkboxesFlush.length; x++) {
            if(checkboxesFlush[x].checked==true){
                is_true = true;
            }
        }
        for (var i = 0; i < checkboxesFlush.length; i++) {
            checkboxesFlush[i].checked = false;
        }
        //
        if(is_true===false){
            var checkboxes = document.querySelectorAll('.inp-ssales');
            for (var i = 0; i < checkboxes.length; i++) {
                if(checkboxes[i].disabled==false){
                    checkboxes[i].checked = true;
                    this.dbsCheckboxChange(checkboxes[i].getAttribute('data-id'))
                }
            }
        }
    }
    rowRefresh(rowId){
        var params = {
            type_id: 0, // 0:satış,1:alış
            type_name: "gelirFatura",
            invoice_id: [rowId],
        };
        Api.post("/DBS/refreshRecord", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                toastr.success("Başarılı", "DBS Kaydı Güncellendi.");
                setTimeout(window.location.reload(false), 3000);
            }
        });
    }
    rowTrash(rowId){
        var params = {
            type_id: 0, // 0:satış,1:alış
            type_name: "gelirFatura",
            invoice_id: [rowId],
        };
        Api.post("/DBS/deleteRecord", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                toastr.success("Başarılı", "DBS Kaydı Silindi.");
                setTimeout(window.location.reload(false), 3000);
            }
        });
    }
    setFieldChange(type, field) {
        const self = this;
        if (type === "start_date") {
            let newDate1 = field;
            self.setState({ date1: newDate1 })
        } else if (type === "end_date") {
            let newDate2 = field;
            self.setState({ date2: newDate2 })
        }
    }
    onMount() {

    }
    getData() {
        if(this.state.date1==''){
            toastr.error("Hata!", "Başlangıç Tarihi Seçmediniz");
        }
        if(this.state.date2==''){
            toastr.error("Hata!", "Bitiş Tarihi Seçmediniz");
        }else{
            this.loadData(this.state.date1,this.state.date2);
        }
    }
    render() {
        const dateProps = {
            onChange: this.setFieldChange.bind(this),
            onMount: this.onMount.bind(this),
            formData: this.state.formData,
        }
        const AccordionSection = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
`;

        const Container = styled.div`
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  padding:5px;
`;

        const Wrap = styled.div`
  background: white;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border:1px gray solid;
  margin-top:1.5rem;
  box-shadow:0px 0px 3px 3px rgba(182,182,182,0.58);
  h1 {
    padding: 1rem;
    font-size: 1.4rem;
  }
  span {
    margin-right: 1.5rem;
  }
  .hidden{display:none !important}
  .showDiv{display:block !important}
`;

        const Dropdown = styled.div`
  background: #f4f4f4;
  color: black;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:0px 0px 3px 3px rgba(182,182,182,0.58);
  border-bottom:1px gray  solid;
  border-right:1px gray  solid;
  border-left:1px gray  solid;
  p {
    font-size: 2rem;
  }
  thead tr td{font-weight:bold}
  .black{color:black}
  .orange{color:orange}
  .green{color:green}
  .red{color:red}
`;


        const DBSSalesSInvoice = () => {
            const [clicked, setClicked] = useState(false);
            const toggle = index => {
                if (clicked === index) {
                    return setClicked(null);
                }
                setClicked(index);
            };
            if (this.state.Data.length!=null || this.state.Data.length != 0) {
                return (
                    this.state.loading == false ?
                        <div className={"row"} style={{marginTop:"20px"}}>
                            <div className={"col-md-4"}>
                                <button disabled={this.state.dbsSendButtonDisableController} className="btn btn-default blue btn-sm" onClick={this.dbsSend.bind(this)}>
                                    <i className="fa fa-paper-plane"/>&nbsp; DBS Gönder
                                </button>
                            </div>
                            <div className={"col-md-8 text-right"} style={{marginBottom:"40px"}}>
                                <button className={this.state.showAll==true?"btn btn-default red btn-sm":"btn btn-default white btn-sm"} onClick={() => this.showHideDiv("all")}>
                                    <i className="fa fa-paper-plane"/>&nbsp; Hepsi
                                </button>
                                <button className={this.state.showSend==true?"btn btn-default red btn-sm":"btn btn-default white btn-sm"} onClick={() => this.showHideDiv("send")}>
                                    <i className="fa fa-paper-plane"/>&nbsp; Gönderilmiş
                                </button>
                                <button className={this.state.showNotSend==true?"btn btn-default red btn-sm":"btn btn-default white btn-sm"} onClick={() => this.showHideDiv("notSend")}>
                                    <i className="fa fa-paper-plane"/>&nbsp; Gönderilmemiş
                                </button>
                                <button className={this.state.showError==true?"btn btn-default red btn-sm":"btn btn-default white btn-sm"} onClick={() => this.showHideDiv("error")}>
                                    <i className="fa fa-paper-plane"/>&nbsp; Hatalı
                                </button>
                            </div>
                            <div className={"col-md-7"}>
                                <button style={{"display":this.state.rowCount==0?"none":"block","marginTop":"60px"}} className="btn btn-primary blue btn-xs" onClick={this.allCheckboxSelected.bind(this)}>
                                    <i className="fa fa-check"/>
                                </button>
                            </div>
                            <div className={"col-md-2"}>
                                <EkoFormDate
                                    {...dateProps}
                                    id="start_date"
                                    dateFormat="DD-MM-YYYY"
                                    isVertical={true}
                                    label="Başlangıç Tarihi"
                                    value={this.state.date1}
                                />
                            </div>
                            <div className={"col-md-2"}>
                                <EkoFormDate
                                    {...dateProps}
                                    id="end_date"
                                    dateFormat="DD-MM-YYYY"
                                    isVertical={true}
                                    label="Bitiş Tarihi"
                                    value={this.state.date2}
                                />
                            </div>
                            <div className={"col-md-1"}>
                                <input value="Sorgula" type="button" className="btn btn-default blue btn-sm" onClick={this.getData.bind(this)} style={{"marginTop":"53px"}} />
                            </div>
                            <div className={"col-md-12"} >
                                <h3 style={{"textAlign":"center","display":this.state.rowCount==0?"block":"none"}}>Gösterilecek Veri Yok</h3>
                                <IconContext.Provider value={{color: 'gray', size: '25px'}}>
                                <AccordionSection>
                                    <Container>
                                        {this.state.vat_period.sort((a,b)=> a-b).map((vatItem, vatIndex) => {
                                            return(<div><h2 style={{"marginLeft":"15px","fontWeight":"bold"}}>{vatItem.vat_period}</h2>
                                        {this.state.Data.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => {
                                            var dbsSendStatusColor = "black";
                                            var dbsSendStatusText = "notSend";
                                            if (item.dbsSendStatus == 0) {
                                                dbsSendStatusColor = "#bfbfbf";
                                                dbsSendStatusText = "notSend";
                                            } else if (item.dbsSendStatus == 1) {
                                                dbsSendStatusColor = "green";
                                                dbsSendStatusText = "send";
                                            } else {
                                                dbsSendStatusColor = "red";
                                                dbsSendStatusText = "error";
                                            }
                                            var dbsControlStatusColor = "black";
                                            if (item.is_checked == 1) {
                                                dbsControlStatusColor = "#ff9d03";
                                            } else {
                                                dbsControlStatusColor = "#bfbfbf";
                                            }
                                            var dbsErrorStatusColor = "black";
                                            if (item.dbsErrorStatus == 0) {
                                                dbsErrorStatusColor = "#bfbfbf";
                                            } else {
                                                dbsErrorStatusColor = "red";
                                                dbsSendStatusText = "error";
                                            }
                                            return (
                                                vatItem.vat_period==item.vat_period?<>
                                                    <div className={"row"} style={{display: this.styledGetClass(dbsSendStatusText)}}>
                                                        <div style={{'textAlign': 'center','width':'2%','float':'left','marginLeft':"-27px","marginTop":"19px","display":item.dbsUpdatedError==0?"none":"block"}}><FiRefreshCcw onClick={() => this.rowRefresh(item.id)} title={"Kayıtlar Değiştirilmiş DBS'ye Yeniden Gönder"} color={"red"}/></div>
                                                        <div style={{'textAlign': 'center','width':'2%','float':'left','marginLeft':"-57px","marginTop":"19px","display":item.dbsSendStatus!=1?"none":"block"}}><FiTrash onClick={() => this.rowTrash(item.id)} title={"Kayıtları DBS'den Sil"} color={"red"}/></div>
                                                        <div style={{'textAlign': 'center','width':'2%','float':'left'}}>
                                                            <input style={{'marginTop': '25px'}} type="checkbox"
                                                                   className="form-check-input inp-ssales"
                                                                   data-id={item.id}
                                                                   onClick={() => this.dbsCheckboxChange(item.id)}
                                                                   disabled={item.dbsSendStatus==1?true:false}
                                                            />
                                                        </div>
                                                        <div style={{'width':'97%','float':'left'}}>
                                                            <Wrap onClick={() => toggle(index)} key={index}>
                                                                <table style={{"width": "100%"}}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td style={{"width": "10%"}}>{item.date}</td>
                                                                        <td style={{"width": "10%","color":item.invoiceNumber===''?"red":""}}>{item.invoiceNumber===''?"[FATURA NO GİRİLMEMİŞ]":item.invoiceNumber}</td>
                                                                        <td style={{"width": "20%"}}>{item.description}</td>
                                                                        <td style={{"width": "20%"}} title={item.who}>{item.who.length>=23?item.who.substring(0, 23)+"...":item.who}</td>
                                                                        <td style={{"width": "15%"}}>{item.amount}</td>
                                                                        <td style={{
                                                                            "width": "25%",
                                                                            "paddingTop": "5px"
                                                                        }}>
                                                                            <span title={"Kontrol Edilme Durumu"}><FiCheckSquare color={dbsControlStatusColor}/></span>
                                                                            <span
                                                                                title={"DBS Gönderim Durumu"}><FiUpload
                                                                                color={dbsSendStatusColor}/></span>
                                                                            <span title={"Hata Durumu"}><FiAlertTriangle
                                                                                color={dbsErrorStatusColor}/></span>
                                                                            <span onClick={() => this.openLink(item.id)} title={"Faturaya Git"}><FiEye
                                                                                color={"#4a8ac1"}/></span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                <span>{clicked === index ? <FiArrowUpCircle/> : <FiArrowDownCircle/>}</span>
                                                            </Wrap>
                                                            {clicked === index ? (
                                                                <Dropdown style={{"display": "inline-table"}}>
                                                                    <table style={{"width": "100%"}}>
                                                                        <thead>
                                                                        <tr>
                                                                            <td>DBS</td>
                                                                            <td>Ü/H</td>
                                                                            <td>Açklm</td>
                                                                            <td>Adet</td>
                                                                            <td>Brm Fyt</td>
                                                                            <td>Tutar</td>
                                                                            <td>KDV %</td>
                                                                            <td>KDV</td>
                                                                            <td>Toplam</td>
                                                                            <td>NACE <FiInfo color={"black"} size={14} title={"Faaliyet Kodu"}/></td>
                                                                        </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                        {item.rows.map((listValue, index) => {
                                                                            var errorClass = "";
                                                                            var errorText = "";
                                                                            if (listValue.rowError == 0) {
                                                                                errorText = "Gönderilmedi";
                                                                                errorClass = "orange";
                                                                            } else if (listValue.rowError == 1) {
                                                                                errorText = "Gönderildi";
                                                                                errorClass = "green";
                                                                            } else if (listValue.rowError == 2) {
                                                                                errorText = "Gönderildi, Hata Alındı";
                                                                                errorClass = "red";
                                                                            }
                                                                            var obj = this.state.gelirTuru.findIndex(o => o.id == listValue.id);
                                                                            var dataIndex = this.state.Data.findIndex(o => o.id == item.id);
                                                                            var rowIndex = this.state.Data[dataIndex].rows.findIndex(o => o.id == listValue.id);
                                                                            return (

                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {listValue.rowErrorDescription != '' ?
                                                                                            <FiInfo color={"red"}
                                                                                                    size={18}
                                                                                                    title={listValue.rowErrorDescription}/> : ""}
                                                                                        <span
                                                                                            className={errorClass}>{errorText}</span>
                                                                                    </td>
                                                                                    <td title={listValue.item}>
                                                                                        {listValue.item.length>=9?listValue.item.substring(0, 9)+"...":listValue.description}
                                                                                    </td>
                                                                                    <td title={listValue.description}>
                                                                                        {listValue.description.length>=9?listValue.description.substring(0, 9)+"...":listValue.description}
                                                                                    </td>
                                                                                    <td>{listValue.quantity}</td>
                                                                                    <td>{listValue.unit_price}</td>
                                                                                    <td>{listValue.unit_str}</td>
                                                                                    <td>{listValue.kdv}</td>
                                                                                    <td>{listValue.kdv_amount}</td>
                                                                                    <td>{listValue.total_amount}</td>
                                                                                    <td>
                                                                                        <select
                                                                                        style={{"width":"190px"}}
                                                                                        disabled={item.dbsSendStatus==1 || listValue.rowError==1?true:false}
                                                                                        className="form-control naceKodu"
                                                                                        value={this.state.Data[dataIndex].rows[rowIndex].nace_code_id}
                                                                                        onChange={(e) => this.naceCodeChangeVal(e.target.value, listValue.id,dataIndex,rowIndex)}
                                                                                    >
                                                                                        {JSON.parse(localStorage.getItem("nace_code_list")).map((e, key) => {
                                                                                            return <option
                                                                                                key={e.id}
                                                                                                value={e.id}>{e.description}</option>;
                                                                                        })}
                                                                                    </select>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </Dropdown>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                </>:""
                                            );
                                        })}
                                            </div>); })}
                                    </Container>
                                </AccordionSection>
                            </IconContext.Provider>
                            </div>


                        </div>
                        : <div style={{"textAlign": "center"}}>
                            <h2>Yükleniyor...</h2>
                            <img src="https://i0.wp.com/ekohesap.com/wp-content/uploads/2022/12/dbs-loading.gif"/>
                        </div>


                );
            }


        };

        return (
            <div>
                <div>
                    <DBSSalesSInvoice/>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        dbssales: state.data.dbssales
    };
}

export default connect(mapStateToProps, actions)(DBSSalesSInvoice);