import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddPolicy from "./AddPolicy";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import AddDocumentButton from "../../components/forms/AddDocumentButton";

class PolicyAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      fromWhoType: localStorage.getItem("new_policy_from_who_type"),
    };
  }

  redirectToBase() {
    this.props.router.push("/dashboard/purchases/insurancePolicy");
  }

  componentWillMount() {
    var self = this;
    if (this.props.params && this.props.params.id) {
      //NOTE @bocek come for edit
      Api.get(
        "/insurancePolicy/" + this.props.params.id,
        function (response, err) {
          //TODO errorlari da handle etmek lazim da neyse @bocek
          if (response.insurancePolicy) {
            const docUrl = response.insurancePolicy?.document_url;
            if (docUrl && self.props.setAddDocument) {
              const type = docUrl.split(".").pop();
              self.props.setAddDocument({ uri: docUrl, type });
            }
            let formData = { insurancePolicy: response.insurancePolicy };

            try {
              formData.insurancePolicy.start_date =
                Util.convertFromDatabaseDate(
                  formData.insurancePolicy.start_date
                );
              formData.insurancePolicy.end_date = Util.convertFromDatabaseDate(
                formData.insurancePolicy.end_date
              );
              formData.insurancePolicy.amount = Util.convertDecimalToMoney(
                formData.insurancePolicy.amount
              );
            } catch (ex) {
              console.log(ex);
            }
            self.setState({
              formData: formData,
              fromWhoType: formData.insurancePolicy.type,
            });
          } else {
            toastr.error("Hata", err.message);
            self.props.router.push("/dashboard/purchases/insurancePolicy");
          }
        }
      );
    }
  }

  render() {
    return (
        <div>
            <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
               onClick={Util.backButton.bind(this, "/dashboard/purchases/insurancePolicy")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
            <div className="portlet light ">
                <div
                    className="portlet-title"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase">Sigorta Ekle</span>
                    </div>
                    <div
                        style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            flex: "auto",
                        }}
                    >
                        <AddDocumentButton
                            style={{margin: 0, transform: "translateY(-25%)"}}
                        />
                    </div>
                </div>
                <div className="portlet-body">
                    <AddPolicy
                        editId={this.props.params.id}
                        formData={this.state.formData}
                        fromWhoType={this.state.fromWhoType}
                        redirectToBase={this.redirectToBase.bind(this)}
                    />
                </div>
            </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        otherReceipts: state.data.otherReceipts, //pek lazim olmadi gerci
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(PolicyAddUpdate);
