import React, { Component } from "react";
import Sugar from "sugar";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import AddOtherTax from "../../components/forms/AddOtherTax";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import UtilHelper from "../../util/UtilHelper";
import FixedAssetSalesInvoiceHeader from "./AddFixedAssetSalesInvoiceHeader";
import { withRouter } from "react-router";
import {Button} from "react-bootstrap";

class FixedAssetSalesInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixedAssets:[],
      formDataAsset: false,
      showAssetModal: false,
      selectedAssetLine: 0,
      showAddTax: false,
      formData: props.formData,
      calculatedFormData: {},
      saving: false,
      taxForm: AddOtherTax.getDefaultForm(),
      selectedLine: 0,
      errors: props.errors,
      saved: false,
      deleteRowIds: [],
      deleteTaxIds: [],
      tableData: {
        headers: [
          { label: "Demirbaş Seçin", width: "18%" },
          { label: "Açıklama", width: "20%" },
          { label: "Miktar", width: "5%" },
          { label: "Birim Fiyatı", width: "8%" },
          { label: "Birim", width: "7%" },
          { label: "İsk. %", width: "5%" },
          { label: "İsk. Tutar", width: "7%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "7%" }
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            visible: true
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: false
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: false
          },
          {
            id: "receivable_amount_fc",
            label: "Ödenecek Tutar",
            className: "tplus-plus",
            visible: true
          }
        ],
        customFooter: null,
        rows: this.defaultRows()
      }
    };
  }

  defaultRows() {
    let rows = [
      [
        {
          type: "select",
          id: "fixed_asset_id",
          optionId: "id",
          optionValue: "asset_name",
          defaultText: "SEÇ",
          searchable: true,
          data: []
        },
        { type: "text", id: "description", vertical: true },
        {
          type: "money",
          id: "quantity",
          vertical: true,
          value: 1,
          precision: 6
        },
        {
          type: "money",
          id: "unit_price_fc",
          vertical: true,
          value: 0,
          precision: 4,
          currency: true
        },
        {
          type: "select",
          id: "unit_id",
          defaultText: "",
          optionValue: "description",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: []
        },
        { type: "percent", id: "discount_rate", vertical: true, value: 0 },
        { type: "money", id: "discount_amount_fc", vertical: true },
        { type: "money", id: "net_amount_fc", vertical: true, disabled: true },
        {
          type: "select",
          id: "vat_rate_type_id",
          defaultText: "",
          optionValue: "name",
          optionId: "id",
          data: []
        },
        { type: "money", id: "vat_amount_fc", vertical: true, disabled: true }
      ]
    ];

    return rows;
  }
  shouldComponentUpdate(nextProps, nextState) {
    //NOTE hersey gelince render etsin
    return (
      !Util.isUndefined(nextProps.vatRateTypes) &&
      !Util.isUndefined(nextProps.vatReductionTypeCodes) &&
      !Util.isUndefined(nextProps.units) &&
      !Util.isUndefined(nextProps.taxTypes)
    );
  }


  componentWillMount() {
    try {
      this.prepareFormToPost(this.state.formData);
    } catch (ex) {
      console.log(ex);
    }
  }

  componentWillReceiveProps(nextProps) {
    const self = this;
    if (nextProps.formData) {
      this.setState({ formData: nextProps.formData }, function() {
        self.prepareFormToPost(this.state.formData);
        self.renewTableData();
      });
    }
    if (
      nextProps.vatRateTypes &&
      nextProps.vatReductionTypeCodes &&
      nextProps.units
    ) {
      let tableData = this.state.tableData;
      tableData.rows[0][0]["data"] = JSON.parse(localStorage.getItem("fixed_asset_list"));
      tableData.rows[0][8]["data"] = nextProps.vatRateTypes;
      tableData.rows[0][4]["data"] = nextProps.units;
      this.setState({ tableData: tableData });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;
    if (
      formData &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        let row = this.defaultRows()[0];
        row[0]["data"] = JSON.parse(localStorage.getItem("fixed_asset_list"));
        row[8]["data"] = nextProps.vatRateTypes;
        row[4]["data"] = nextProps.units;
        tableData.rows.push(row); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = nextProps.errors;
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
      const self = this;
      this.setState({ tableData: tableData, errors: errors }, function() {
        self.renewTableData();
      });
    } else if (
      formData &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({
        errors: this.props.defaultErrors(),
        tableData: tableData
      });
    }
  }

  prepareFormToPost = formData => {
    let calculatedFormData = {};
    const self = this;
    if (formData.invoice) {
      calculatedFormData.invoice = { ...formData.invoice };
      calculatedFormData.invoice.customer_id = Util.parseInt(
        calculatedFormData.invoice.customer_id
      );
      calculatedFormData.invoice.currency_rate = Util.convertMoneyToDecimal(
        calculatedFormData.invoice.currency_rate
      );
      calculatedFormData.invoice.date = calculatedFormData.invoice
        .date
        ? moment(calculatedFormData.invoice.date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.due_date = calculatedFormData.invoice.due_date
        ? moment(calculatedFormData.invoice.due_date).format("YYYY-MM-DD")
        : null;
    }

    let footer = {
      price_amount: 0,
      price_amount_fc: 0,
      discount_amount: 0,
      discount_amount_fc: 0,
      net_amount: 0,
      net_amount_fc: 0,
      vat_amount: 0,
      vat_amount_fc: 0,
      total_amount: 0,
      total_amount_fc: 0,
      vat_reduction_amount: 0,
      vat_reduction_amount_fc: 0,
      stoppage_amount: 0,
      stoppage_amount_fc: 0,
      receivable_amount: 0,
      receivable_amount_fc: 0
    };

    if (formData.rows) {
      const currencyRate = calculatedFormData.invoice.currency_rate || 1;

      calculatedFormData.rows = formData.rows.map(frow => {
        let row = { ...frow };

        row.quantity = Util.convertMoneyToDecimal(row.quantity);

        row.discount_amount_fc = Util.convertMoneyToDecimal(
          row.discount_amount_fc
        );
        row.discount_amount = row.discount_amount_fc * currencyRate;

        row.unit_price_fc = UtilHelper.money.convertMoneyToDecimal(
          row.unit_price_fc
        );
        row.unit_price = row.unit_price_fc * currencyRate;

        row.net_amount_fc = Util.convertMoneyToDecimal(row.net_amount_fc);
        row.net_amount = row.net_amount_fc * currencyRate;

        row.discount_rate = Util.convertPercentToNumber(row.discount_rate);

        row.vat_amount_fc = Util.convertMoneyToDecimal(row.vat_amount_fc);
        row.vat_amount = row.vat_amount_fc * currencyRate;

        if (row.vat_rate_type_id && !row.vat_rate) {
          const vatRate = Sugar.Array.find(self.props.vatRateTypes, function(
            type
          ) {
            return type.id === row.vat_rate_type_id;
          });
          if (vatRate) row.vat_rate = vatRate.rate;
        } else row.vat_rate = row.vat_rate || 0;
        row.stoppage_amount_fc = 0;
        row.vat_reduction_amount_fc = 0;

        row.otherTaxes = [];

        row.total_amount_fc = row.net_amount_fc + row.vat_amount_fc;
        row.total_amount = row.total_amount_fc * currencyRate;

        row.stoppage_amount = row.stoppage_amount_fc * currencyRate;
        row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;

        footer.price_amount += row.net_amount + row.discount_amount;
        footer.price_amount_fc += row.net_amount_fc + row.discount_amount_fc;
        footer.net_amount += row.net_amount;
        footer.net_amount_fc += row.net_amount_fc;
        footer.discount_amount += row.discount_amount;
        footer.discount_amount_fc += row.discount_amount_fc;
        footer.vat_amount += row.vat_amount;
        footer.vat_amount_fc += row.vat_amount_fc;
        footer.total_amount += row.total_amount;
        footer.total_amount_fc += row.total_amount_fc;
        footer.vat_reduction_amount += row.vat_reduction_amount;
        footer.vat_reduction_amount_fc += row.vat_reduction_amount_fc;
        footer.stoppage_amount += row.stoppage_amount;
        footer.stoppage_amount_fc += row.stoppage_amount_fc;

        //console.log(row);

        return row;
      });
    }

    footer.receivable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.receivable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;
    footer.discount_rate =
      Math.round((footer.discount_amount_fc / footer.price_amount_fc) * 10000) /
      10000;
    footer.received_amount_fc = 0;
    footer.received_amount = 0;
    calculatedFormData.footer = footer;

    let tableData = this.state.tableData;

    Object.keys(footer).map(key => {
      if (footer[key] > 0) {
        tableData.footerTable.map(tableEl => {
          if (tableEl.id === key) {
            tableEl.visible = true;
          }

          return tableEl;
        });
      }

      return (tableData.footerData[key] = Util.convertDecimalToMoney(
        footer[key]
      ));
    });
    this.setState({ tableData: tableData });

    return calculatedFormData;
  };

  onChange(type, field, value) {
    const self = this;
    let formData = this.state.formData;

    formData[type][field] = value;
    self.setState({ formData: formData }, function() {
      self.resetErrorForInvoice(field);
    });
  }

  onRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  handleSuccess(redirect = false) {
    const self = this;
    toastr.success("Başarılı şekilde kaydedildi");
    self.setState({ saving: false, saved: true });
    if (!self.props.router.params.id) {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        let tableData = this.state.tableData;
        tableData = Object.assign(tableData, {
          footerData: {
            discount_amount: "0,00",
            discount_amount_fc: "0,00",
            discount_rate: "0,00",
            net_amount: "0,00",
            net_amount_fc: "0,00",
            price_amount: "0,00",
            price_amount_fc: "0,00",
            receivable_amount: "0,00",
            receivable_amount_fc: "0,00",
            received_amount: "0,00",
            received_amount_fc: "0,00",
            stoppage_amount: "0,00",
            stoppage_amount_fc: "0,00",
            total_amount: "0,00",
            total_amount_fc: "0,00",
            vat_amount: "0,00",
            vat_amount_fc: "0,00",
            vat_reduction_amount: "0,00",
            vat_reduction_amount_fc: "0,00"
          }
        });

        this.setState(
          {
            tableData: tableData,
            formData: self.props.defaultFormData()
          },
          function() {
            self.renewTableData();
          }
        );
      }
    } else {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        this.props.router.push("/dashboard/fixedAssetSalesInvoice/add");
      }

      if (self.props.onSave) {
        self.props.onSave();
      }
    }
  }

  onSave(refresh, isChecked) {
    const redirect = arguments[0];
    const self = this;

    self.setState({ saving: true });

    self.validateInvoiceLines(true, function(linesOk) {
      if (linesOk) {
        self.saveInvoice(function(invoiceOk) {
          if (invoiceOk) {
            let formData = self.state.formData;
            formData.rows = formData.rows.map(function(row) {
              row.fixed_asset_sales_invoice_id = formData.invoice.id;
              return row;
            });
            self.saveInvoiceLines(function(invoiceLinesSaved) {
              if(isChecked === true){
                Api.get(`/fixedAssetSalesInvoice/next/${self.props?.editId}`,function (res, error) {
                  if(res.item) {
                    const path = window.location.pathname;
                    const ex = path.split('/');
                    ex.pop();
                    ex.push(res.item.id);
                    self.setState({
                      saving: false
                    });
                    window.location.href = ex.join('/');
                  } else {
                    self.handleSuccess(true);
                  }
                })
              } else {
                if (invoiceLinesSaved) {
                  self.handleSuccess(redirect);
                } else {
                  self.setState({
                    saving: false
                  });
                }
              }
            });
          } else {
            self.setState({ saving: false });
            console.log("invoice not validated");
          }
        }, isChecked);
      } else {
        self.setState({ saving: false });
        console.log("lines not validated");
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  renewTableData() {
    const self = this;
    let tableData = this.state.tableData;
    tableData.rows.forEach(function(row, index) {
      tableData.rows[index][0]["data"] = JSON.parse(localStorage.getItem("fixed_asset_list"))
      tableData.rows[index][8]["data"] = self.props.vatRateTypes;
      tableData.rows[index][4]["data"] = self.props.units;

    });
    self.setState({ tableData: tableData });
  }

  onInvoiceLineRemoved(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = Sugar.Array.removeAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.invoice.id && row.id) {
      deleteRowIds.push(row.id);
    }

    this.prepareFormToPost(this.state.formData);
    this.renewTableData();
  }

  onInvoiceLineAdded() {
    this.renewTableData();
    this.validateInvoiceLines(false, null);
  }

  getDiscountAmount(rowData) {
    return rowData.unit_price_fc * rowData.quantity * rowData.discount_rate;
  }

  getNetAmount(rowData) {
    return (
      rowData.unit_price_fc * rowData.quantity - rowData.discount_amount_fc
    );
  }

  getDiscountRate(rowData) {
    return (
      rowData.discount_amount_fc / (rowData.unit_price_fc * rowData.quantity)
    );
  }

  getVatAmount(rowData) {
    return rowData.net_amount_fc * rowData.vat_rate;
  }

  resetErrorForInvoiceLine(index, field) {
    let errors = this.state.errors;
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;

    this.setState({ errors: errors });
  }

  resetErrorForInvoice(field) {
    let errors = this.state.errors;
    errors.invoice[field] = false;

    this.setState({ errors: errors });
  }

  onInvoiceLineReallyChange(index, field, value, oldValue) {
    this.resetErrorForInvoiceLine(index, field);

    if (
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      this.resetErrorForInvoiceLine(index, "discount_amount_fc");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "discount_amount_fc") {
      this.resetErrorForInvoiceLine(index, "discount_rate");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "vat_rate_type_id") {
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }
  }

  onInvoiceLineChange(index, rowData, field) {
    const self = this;
    let formData = this.state.formData;

    if (field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[
        index
      ].discount_amount_fc = this.getDiscountAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].discount_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "discount_amount_fc") {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);

      calculatedFormData.rows[index].discount_rate = this.getDiscountRate(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].discount_rate = Util.convertNumberToPercent(
        calculatedFormData.rows[index].discount_rate
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "vat_rate_type_id") {
      formData.rows[index][field] = rowData[field];
      const vatRate = Sugar.Array.find(this.props.vatRateTypes, function(type) {
        return type.id === rowData[field];
      });
      if (vatRate) {
        formData.rows[index].vat_rate = vatRate.rate;
        const calculatedFormDataq = this.prepareFormToPost(formData);
        calculatedFormDataq.rows[index].vat_amount_fc = this.getVatAmount(
          calculatedFormDataq.rows[index]
        );
        formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
          calculatedFormDataq.rows[index].vat_amount_fc
        );
      }
    } else {
      formData.rows[index][field] = rowData[field];
    }

    self.setState({ formData: formData }, function() {
      self.prepareFormToPost(self.state.formData);
    });
  }

  validateInvoiceLines(all, next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    const url = "/fixedAssetSalesInvoice/validation";
    let endpoints = [];
    calculatedFormData.rows.forEach(function(row) {
      endpoints.push({ endpoint: url, method: "post", params: row });
    });

    if (!all) {
      endpoints.pop(); //son eklenen rowu kontrol etme
    }

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        if (next !== null) next(true);
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });
      }
    });
  }

  deleteInvoiceLines(next) {
    const self = this;
    if (self.state.deleteRowIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteRowIds.forEach(function(id) {
        endpointWithParams.push({
          endpoint: "/fixedAssetSalesInvoiceLine/" + id,
          method: "delete"
        });
      });

      Api.paralelAll(endpointWithParams, function(responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveInvoiceLines(next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);

    let formData = this.state.formData;
    let endpoints = [];
    var rowcount = 0 ;
    calculatedFormData.rows.forEach(function(row) {
      rowcount++;
      row.sortable_id = rowcount;
      const method = row.id ? "put" : "post";
      const url = row.id ? "/fixedAssetSalesInvoiceLine/" + row.id : "/fixedAssetSalesInvoiceLine";
      endpoints.push({ endpoint: url, method: method, params: row });
    });

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        responses.forEach(function(response, index) {
          if (!!response.warnings) {
            response.warnings.forEach(warning => {
              toastr.warning(warning);
            });
          }
        });
        self.setState({ formData: formData }, function() {
          if (next !== null) {
            self.deleteInvoiceLines(next);
          }
        });
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });

        if (errors.message) toastr.error(Util.getErrorAsStr(errors));
      }
    });
  }

  saveInvoice(next,isChecked) {
    const self = this;
    const formData = { ...this.state.formData };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    let manualValidations = true;
    let formErrors = self.state.errors;
    if (calculatedFormData.footer.price_amount_fc === 0) {
      toastr.error("Toplam fatura tutarı sıfır olamaz");
      manualValidations = false;
    }

    if (!manualValidations) return next(false);

    const url = formData.invoice.id
      ? "/fixedAssetSalesInvoice/" + formData.invoice.id
      : "/fixedAssetSalesInvoice";
    const method = formData.invoice.id ? "put" : "post";
    const postData = Object.assign(
      {},
      calculatedFormData.invoice,
      calculatedFormData.footer
    );

    if (postData.customer_id === 0) {
      postData.customer_id = null;
    }
    if(this.props.addDocument?.uri && postData.document_url !== this.props.addDocument?.uri){
      postData.document_url = this.props.addDocument?.uri;
    }
    postData.is_checked = isChecked === true;

    if (formData.invoice.id) postData.updated_id = formData.invoice.id;
    Api[method](url, postData, function(response, errors) {
      if (errors) {
        if (errors.message) toastr.error(Util.getErrorAsStr(errors));

        formErrors.invoice = Object.assign({}, formErrors.invoice, errors);
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
      } else if (!manualValidations) {
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
        next(false);
      } else {
        let formData = self.state.formData;
        formData.invoice.id = response.fixedAssetSalesInvoice.id;
        self.setState({ formData: formData }, function() {
          next(true);
        });
      }
    });
  }

  saveNormal(refresh, isChecked) {
    this.onSave(refresh, isChecked);
  }

  render() {
    let fromWhos = this.props.fromWhos.map(function(fromWho) {
      // fromWho.name = fromWho.firstname;
      // if (fromWho.lastname) fromWho.name += " " + fromWho.lastname;
      fromWho.name = fromWho.fullname;
      return fromWho;
    });

    let headerTitle = "Demirbaş Satış Faturası";

    return (
      <FixedAssetSalesInvoiceHeader
        fromWhos={fromWhos}
        fromWhoId={
          this.state.formData.invoice.customer_id
        }
        currencies={this.props.currencies}
        formData={this.state.formData.invoice}
        errors={this.state.errors.invoice}
        onChange={this.onChange.bind(this)}
        onRefresh={this.onRefresh.bind(this)}
        saved={this.state.saved}
        title={headerTitle}
      >
        <EkoInputTableButtonGroup
          data={this.state.tableData}
          currency={this.state.formData.invoice.currency}
          formData={this.state.formData}
          errors={this.state.errors}
          onSave={(refresh, isChecked) => this.saveNormal(refresh, isChecked)}
          saving={this.state.saving}
          buttonSaveLabel={"Kaydet"}
          onRowAdd={this.onInvoiceLineAdded.bind(this)}
          defaultRow={this.props.defaultRowData}
          onCancel={this.onCancel.bind(this)}
          onChangeRow={this.onInvoiceLineChange.bind(this)}
          onRealChange={this.onInvoiceLineReallyChange.bind(this)}
          onChange={() => null}
          onRowDelete={this.onInvoiceLineRemoved.bind(this)}
          formId={"add_fixed_asset_sales_invoice"}
          buttons={[]}
          isCheckButton={true}
          editId={this.props?.editId}
          updatedAt={this.state?.formData?.invoice?.is_checked ? this.state?.formData?.invoice?.updated_at : null}
        />
      </FixedAssetSalesInvoiceHeader>
    );
  }
}

function mapStateToProps(state) {
  return {
    vatRateTypes: state.data.vatRateTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    units: state.data.units,
    taxTypes: state.data.taxTypes,
    currencies: state.data.currencies,
    addDocument: state.page.addDocument
  };
}

export default connect(mapStateToProps, actions)(withRouter(FixedAssetSalesInvoice));
