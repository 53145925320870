import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddSoleProfSalesInvoice from "./AddSoleProfSalesInvoice";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";

class SoleProfSalesInvoiceAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      fromWhoType: localStorage.getItem("new_sole_invoice_from_who_type")
    };
  }

  componentWillMount() {
    var self = this;
    if (this.props.params && this.props.params.id) {
      //NOTE @bocek come for edit
      Api.get("/soleprofSalesInvoice/" + this.props.params.id, function(
        response,
        err
      ) {
        //TODO errorlari da handle etmek lazim da neyse @bocek
        if (response.soleprofSalesInvoice) {
          let formData = { soleInvoice: response.soleprofSalesInvoice };

          try {
            //
            formData.soleInvoice.date = Util.convertFromDatabaseDate(
              formData.soleInvoice.date
            );
            formData.soleInvoice.amount = Util.convertDecimalToMoney(
              formData.soleInvoice.amount
            );
            formData.soleInvoice.vat_amount = Util.convertDecimalToMoney(
              formData.soleInvoice.vat_amount
            );
            formData.soleInvoice.vat_reduction_amount = Util.convertDecimalToMoney(
              formData.soleInvoice.vat_reduction_amount
            );
            formData.soleInvoice.stoppage_amount = Util.convertDecimalToMoney(
              formData.soleInvoice.stoppage_amount
            );
            formData.soleInvoice.amount_calculation = Util.convertDecimalToMoney(
              formData.soleInvoice.amount_calculation
            );
          } catch (ex) {
            console.log(ex);
          }
          self.setState({
            formData: formData,
            fromWhoType: formData.soleInvoice.type
          });
        } else {
          toastr.error("Hata", err.message);
          self.props.router.push("/dashboard/soleprofsalesinvoice");
        }
      });
    }
  }

  redirectToBase() {
    this.props.router.push("/dashboard/soleprofsalesinvoice");
  }

  render() {
    return (
      <AddSoleProfSalesInvoice
        title="SERBEST MESLEK MAKBUZU"
        formData={this.state.formData}
        fromWhoType={this.state.fromWhoType}
        redirectToBase={this.redirectToBase.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    soleProfSalesInvoices: state.data.soleProfSalesInvoices, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(SoleProfSalesInvoiceAddUpdate);
