import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Col, ControlLabel, FormGroup, HelpBlock} from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const theme = {
    container:                'react-autosuggest__container',
    containerOpen:            'react-autosuggest__container--open',
    input:                    'react-autosuggest__input',
    inputOpen:                'react-autosuggest__input--open',
    inputFocused:             'react-autosuggest__input--focused',
    suggestionsContainer:     'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList:          'react-autosuggest__suggestions-list',
    suggestion:               'react-autosuggest__suggestion',
    suggestionFirst:          'react-autosuggest__suggestion--first',
    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
    sectionContainer:         'react-autosuggest__section-container',
    sectionContainerFirst:    'react-autosuggest__section-container--first',
    sectionTitle:             'react-autosuggest__section-title'
  };

class EkoFormAutosuggest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
            suggestions: props.optioons
        }
    };

    static propTypes = {
        optionValue: PropTypes.string,
        value: PropTypes.string,
        formData: PropTypes.object,
        id: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        dropdownStyle: PropTypes.object,
        multiSection: PropTypes.bool
        //  onSelectAutoComplete: PropTypes.function
    };

    static defaultProps = {
        options: [],
        optionValue: 'name',
        value: '',
        type: '',
        labelMd: 5,
        colMd: 6,
        disabled: false,
    }

    componentWillMount() {
        this.props.onMount(this.props.id);
    }

    onChange(event, { newValue, method }) {
        this.props.onChange(this.props.id, newValue);
    }

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
        if(this.props.onSuggestionSelected)
            this.props.onSuggestionSelected(suggestion)
    }

    renderSectionTitle(section) {
        return (
            <strong>{section.title}</strong>
        );
    }

    renderSuggestion = suggestion => {
        
        return <div>
            {suggestion[this.props.optionValue]}
        </div>
    }

    getSuggestions = ( value ) => {
        let {options, optionValue} = this.props;
        const escapedValue = escapeRegexCharacters(value.trim().toUpperCase());
  
        if (escapedValue === '') {
            return [];
        }
        if(this.props.multiSection){
            const regex = new RegExp('^' + escapedValue, 'i');

            return options
                .map(section => {
                    return {
                        title: section.title,
                        options: section.options.filter(option => regex.test(option[optionValue].toUpperCase().replace('İ','I')))
                    };
                })
                .filter(section => section.options.length > 0);
        } else {
            const escapedValue = escapeRegexCharacters(value.trim().toUpperCase());
            if (escapedValue === '') {
                return [];
            }
            const regex = new RegExp('^' + escapedValue, 'i');
            const filter = options.filter(option => regex.test(option[optionValue].toUpperCase().replace('İ','I')))
            return filter;
        }
    };

    getSectionSuggestions(section) {
        return section.options;
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    renderInput() {
        const {inputClass, optionValue} = this.props
        const {suggestions} = this.state;
        // const selectedItem = this.state.selectedItem
        // const defaultText = this.state.defaultText
        // this.props.formData[this.props.optionValue]
        const value = this.props.formData[this.props.id] || "";
        const inputProps = {
            placeholder: this.props.placeholder || "",
            value,
            onChange: this.onChange.bind(this)
          };
        return (
            <Autosuggest
                theme={{...theme, input: "form-control " +  inputClass || ""}}
                multiSection={this.props.multiSection ? this.props.multiSection : false}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={suggestion => suggestion[optionValue]}
                getSectionSuggestions={this.props.multiSection ? this.getSectionSuggestions : null}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                renderSectionTitle={this.props.multiSection ? this.renderSectionTitle : null}
                inputProps={inputProps}
            />
        )
    }

    render() {
        const key = this.props.alias_id || this.props.id;

        const error = (this.props.errors && this.props.errors[key] ) ?
            <ControlLabel>{this.props.errors[key]}</ControlLabel> : false
        let validationState = null;
        if (error) {
            validationState = "error";
        }
        const help = this.props.help ? <HelpBlock>{this.props.help}</HelpBlock> : ''

        return (
            !this.props.isVertical ?
                <FormGroup controlId={this.props.id} validationState={validationState}>
                    {this.props.label && <Col componentClass={ControlLabel} md={parseInt(this.props.labelMd,10)}>
                        {this.props.label}&nbsp; {this.props.required ? <span className="required"> * </span> : ''}
                    </Col>}
                    <Col md={parseInt(this.props.colMd,10)}>
                        {this.renderInput()}
                        {error ? error : help}
                    </Col>
                    {this.props.children}
                </FormGroup> :
                <FormGroup controlId={this.props.id} validationState={validationState}>
                    {this.props.label &&
                    <Col componentClass={ControlLabel} md={parseInt(this.props.labelMd,10) || 5} className="control-line">
                        {this.props.label}&nbsp; {this.props.required ? <span className="required"> * </span> : ''}
                    </Col>}
                    {this.renderInput()}
                    {error ? error : help}

                </FormGroup>
        )
    }
}

export default EkoFormAutosuggest;
