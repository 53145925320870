import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import AddItem from "./AddItem";
import HelperContainer from "../../util/HelperContainer";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import UtilHelper from "../../util/UtilHelper";
import AddChangingModal from "../../components/elements/AddChangingModal";
import Util from "../../util/Util";
import {Tab, Tabs} from "react-bootstrap";
import Warehouses from "../Warehouses/Warehouses";
import TransferWarehousesList from "../Transfer/TransferWarehousesList";
import ProductPlaningList from "../Plan/ProductPlaningList";
import RecipeList from "../Recipe/RecipeList";

class ProductService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showUpdateModal: false,
      showInsertModal: false,
      errors: {},
      validationFields: [],
      items: [],
      data: [],
      passiveButton: "Pasifleştir",
      activeButton: "Aktifleştir",
      showChangingModal: false,
      onCancel: false,
      loading: false,
      changingData: {},
      dataLoading: false
    };
  }

  fetchItems() {
    this.setState({ dataLoading: true });
    if (this.table) this.table.cleanSelect();
    Api.get("/item/list", (data, errors) => {
      if (errors) {
        this.setState({ dataLoading: false });
        return;
      }

      if (data.items) {
        let items = data.items

        this.setState({
          items,
          data: items,
          dataLoading: false
        });
      }
    });
  }

  componentWillMount() {
    this.fetchItems();
  }

  onCancel = () => {
    this.setState({ onCancel: true });
  };

  mapItems(items) {
    if (items && Array.isArray(items)) {
      items.map((item, i) => {
        item.purchase_unit_price = UtilHelper.money.convertDecimalToMoney8(
          item.purchase_unit_price
        );
        item.sales_unit_price = UtilHelper.money.convertDecimalToMoney8(
          item.sales_unit_price
        );
        item.sales_unit_quantity = Util.convertDecimalToMoney5(
          item.sales_unit_quantity
        );
        item.purchase_unit_quantity = Util.convertDecimalToMoney5(
          item.purchase_unit_quantity
        );
        item.quantity = Util.convertDecimalToMoney5(item.quantity);
        return item;
      });
      return items;
    }

    return [];
  }

  openChangingModal = selectedRow => {
    const self = this;
    console.log("selectedRowID ---> ", selectedRow.id);
    /*     Api.get("/subItem/showAll/" + selectedRow.id, function(res, err) {
          if (res.status === "fail") {
            toastr.warning("Dikkat", res.message);
          } else if (res.status === "ok") {
            self.setState({ changingItems: res.items });
          }
        }); */
    this.setState({ showChangingModal: true, changingData: selectedRow });
  };

  itemTransaction(selectedRow) {
    this.props.router.push("/dashboard/transaction/item/" + selectedRow.id);
  }

  passiveActive(state) {
    const self = this;
    Api.get("/item/" + state.selectedRow.id, function(responses) {
      let formData = {};
      formData.item = HelperContainer.filterObject(responses.item);
      if (responses.item) {
        let toggleName = "";
        if (formData.item.is_active === 1) {
          toggleName = " pasifleştirmek ";
        } else {
          toggleName = " aktifleştirmek ";
        }
        toastr.confirm(
          responses.item.item_name +
            " hesabını" +
            toggleName +
            "istediğinize emin misiniz?",
          {
            onOk: () => {
              let func = "put";
              let url = "/item/" + formData.item.id;

              if (formData.item.is_active === 1) {
                formData.item.is_active = 0;
              } else if (formData.item.is_active === 0) {
                formData.item.is_active = 1;
              }

              Api[func](url, formData.item, function(response, postErrors) {
                if (postErrors) {
                  if (postErrors.message) {
                    toastr.error("Başarısız", postErrors.message);
                  }
                } else {
                  if (formData.item.is_active === 1) {
                    let toggleName = "";
                    if (responses.item.is_active === 1) {
                      toggleName = " pasifleştirildi";
                    } else {
                      toggleName = " aktifleştirildi";
                    }

                    self.fetchItems();
                    toastr.success("Başarılı", "Hesap" + toggleName + "");
                  }
                }
              });
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
          }
        );
      }
    });
  }

  openUpdate(selectedRow) {
    var self = this;
    const id = selectedRow.id;
    Api.get("/item/" + id, function(response) {
      let formData = {};
      formData.item = HelperContainer.filterObject(response.item);

      formData.item.purchase_unit_price = UtilHelper.money.convertDecimalToMoney8(
        formData.item.purchase_unit_price
      );
      formData.item.sales_unit_price = UtilHelper.money.convertDecimalToMoney8(
        formData.item.sales_unit_price
      );

      self.setState({
        showInsertModal: true,
        errors: {},
        formData: formData
      });
    });
  }

  changingUpdateShow = () => {
    this.setState({ showModal: false });
  };

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/item/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchItems();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  onSave(formData, formFields) {
    this.fetchItems();
    this.setState({ showInsertModal: false, formData: false });
  }
  openInsert(menuItem) {
    if (menuItem === 1) {
      this.setState({ showInsertModal: true, formData: false });
    }else if (menuItem === 2) {
      this.props.router.push("/dashboard/BulkStockUpdate");
    }

  }
  render() {
    const hash = window.location.hash;
    var activeTab = "product_list"; // default tab
    if (hash && hash != '') {
      activeTab = hash.replace("#", "");
    }
    const insertMenu = [
      {id: 1, name: 'Ürün Ekle'},
      {id: 2, name: 'Toplu Stok Düzenle'}
    ];
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <div className="portlet light">
            <div className="portlet-body">
              <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example">
                <Tab eventKey="product_list" title="Ürün Listesi">
                  <div>
                    <AddItem
                        showModal={this.state.showInsertModal}
                        formData={this.state.formData}
                        onSave={this.onSave.bind(this)}
                        onCancel={() => {
                          this.setState({showInsertModal: false});
                        }}
                        errors={this.state.errors}
                    />

                    {/*
                        <AddChangingModal
                            showModal={this.state.showChangingModal}
                            closeModal={() => {
                              this.setState({ showChangingModal: false });
                            }}
                            changingSave={() => {
                              this.setState({ loading: true });
                            }}
                            changingData={this.state.changingData}
                        />
                        */}

                    <EkoDataTable
                        hiddenColumns={[
                          {column_name: "sub_items", isHidden: true, id: "sub_items"}
                        ]}
                        onRef={item => (this.table = item)}
                        passiveActive={this.passiveActive.bind(this)}
                        fetchData={this.fetchItems}
                        openUpdate={this.openUpdate.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        onFetch={this.fetchItems.bind(this)}
                        itemTransaction={this.itemTransaction.bind(this)}
                        openChangingModal={this.openChangingModal.bind(this)}
                        data={this.state.items}
                        name={"item"}
                        loading={this.state.dataLoading}
                        insertMenu={insertMenu}
                        openInsert={this.openInsert.bind(this)}
                    ></EkoDataTable>
                  </div>
                </Tab>
                {Util.companyModules(2) === true && (
                    <Tab eventKey="warehouses" title="Depolar">
                      <Warehouses/>
                    </Tab>
                )}
                {Util.companyModules(2) === true && (
                    <Tab eventKey="transfer-warehouse" title="Depolar Arası Transfer">
                      <TransferWarehousesList/>
                    </Tab>
                )}
                {Util.companyModules(2) === true && (
                    <Tab eventKey="production-planing" title="Üretim Planlama">
                      <ProductPlaningList/>
                    </Tab>
                )}
                {Util.companyModules(2) === true && (
                    <Tab eventKey="recipe" title="Reçete">
                      <RecipeList/>
                    </Tab>
                )}
              </Tabs>
            </div>
            <br/>
          </div>
        </div>

    );
  }
}


function mapStateToProps(state) {
  return {
    items: state.data.items
  };
}

export default connect(mapStateToProps, actions)(ProductService);
