import React, { Component } from "react";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import PropTypes from "prop-types";
import NumericInput from "react-numeric-input";
import EkoMoneyText from "../EkoMoneyText";
import EkoPercentText from "../EkoPercentText";
import InputMask from "react-input-mask";
import TooltipService from "../../../services/TooltipService";
import Util from "../../../util/Util";
import "./FormInput.css";

export default class EkoFormInput extends Component {
  constructor(props) {
    super(props);

    this.value = "";
    this.error = "";
    this.state = {
      saving: false
    };
  }

  componentWillMount() {
    this.props.onMount && this.props.onMount(this.props.id);
  }

  componentDidMount() {
    // decimal olan bir deger girerse para tutarına formatlansın
    const defaultValue = this.props.formData[this.props.id]
      ? this.props.formData[this.props.id]
      : this.props.value;
    if (
      this.props.type === "money" &&
      !(typeof defaultValue === "string" || defaultValue instanceof String)
    ) {
      this.props.onChange(
        this.props.id,
        Util.convertDecimalToMoney5(defaultValue)
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    const value = nextProps.formData[nextProps.id]
      ? nextProps.formData[nextProps.id]
      : nextProps.value;
    const error = nextProps.errors[nextProps.id]
      ? nextProps.errors[nextProps.id]
      : nextProps.error;

    return (
      error !== this.error ||
      this.value !== value ||
      this.props.childValue !== nextProps.childValue ||
      this.props.disabled !== nextProps.disabled ||
      this.props.readOnly !== nextProps.readOnly ||
      this.props.type !== nextProps.type ||
      this.props.currency !== nextProps.currency
    );
  }

  onChange(event) {
    if (!this.props.disabled) {
      switch (this.props.type) {
        case "numeric":
          if (event) event = event.toString();
          this.props.onChange(this.props.id, event); //event=value zaten
          break;
        case "number":
          if (event) event = event.toString();
          this.props.onChange(this.props.id, event); //event=value zaten
          break;
        case "money":
          this.props.onChange(this.props.id, event); //event=value zaten
          break;
        case "percent":
          this.props.onChange(this.props.id, event); //event=value zaten
          break;
        default:
          this.props.onChange(this.props.id, event.target.value);
      }
    }
  }

  queryVkn(props) {
    if (this.props.queryVkn) {
      this.props.queryVkn(props);
    }
  }

  getValue = () =>
    this.props.formData && this.props.formData[this.props.id]
      ? this.props.formData[this.props.id]
      : this.props.value;

  renderInput() {
    const defaultValue = this.getValue();

    this.value = defaultValue;

    let className = "form-control";
    if (this.props.className) className = this.props.className;
    switch (this.props.type) {
      case "numeric":
        return (
          <NumericInput
            className={className}
            style={false}
            value={defaultValue}
            placeholder={this.props.placeHolder}
            maxLength={this.props.maxLength || 2}
            onKeyPress={e => {
              this.props.onKeyPress && this.props.onKeyPress(e);
            }}
            onChange={this.onChange.bind(this)}
          />
        );
      case "number":
        return (
          <NumericInput
            disabled={this.props.disabled}
            className={className}
            value={defaultValue}
            precision={this.props.precision || 0}
            style={false}
            placeholder={this.props.placeHolder}
            maxLength={this.props.maxLength || 20}
            onChange={this.onChange.bind(this)}
          />
        );
      case "textarea":
        if (this.props.rows)
          return (
            <FormControl
              readOnly={this.props.readOnly}
              className={className}
              disabled={this.props.disabled}
              rows={this.props.rows}
              componentClass="textarea"
              value={defaultValue}
              onChange={this.onChange.bind(this)}
              placeholder={this.props.placeHolder}
              bsCustomClass={this.props.largeHeight && "textarea-lg"}
            />
          );
        else
          return (
            <FormControl
              readOnly={this.props.readOnly}
              className={className}
              disabled={this.props.disabled}
              componentClass="textarea"
              value={defaultValue}
              onChange={this.onChange.bind(this)}
              placeholder={this.props.placeHolder}
              bsCustomClass={this.props.largeHeight && "textarea-lg"}
            />
          );
      case "money":
        return (
          <EkoMoneyText
            value={defaultValue}
            justInput={this.props.justInput}
            precision={this.props.precision}
            thisClass={className}
            placeholder={this.props.placeHolder}
            disabled={this.props.disabled}
            currency={this.props.currency}
            onChange={this.onChange.bind(this)}
          />
        );
      case "percent":
        return (
          <EkoPercentText
            value={defaultValue}
            justInput={this.props.justInput}
            placeholder={this.props.placeHolder}
            thisClass={className}
            disabled={this.props.disabled}
            onChange={this.onChange.bind(this)}
          />
        );
      case "mask":
        return (
          <InputMask
            className={className}
            maskChar={this.props.maskChar}
            mask={this.props.mask}
            value={defaultValue}
            disabled={this.props.disabled}
            placeholder={this.props.placeHolder}
            style={this.props.style}
            onChange={this.onChange.bind(this)}
          />
        );
      default:
        return (
          <FormControl
            className={className}
            maxLength={this.props.maxLength || 5000}
            style={this.props.style}
            disabled={this.props.disabled}
            {...this.props.textProps}
            placeholder={this.props.placeHolder}
            value={defaultValue}
            type={this.props.type}
            onChange={this.onChange.bind(this)}
          />
        );
    }
  }

  renderInputNew() {
    const errorValue = this.props.errors[this.props.id]
      ? this.props.errors[this.props.id]
      : this.props.error;
    const error = errorValue ? (
      <ControlLabel>{errorValue}</ControlLabel>
    ) : (
      false
    );

    this.error = errorValue;

    let validationState = null;
    let tooltip = "";

    if (error) {
      validationState = "error";
      tooltip = (
        <Tooltip style={{ zIndex: 99999999 }} id="tooltip">
          {this.props.errors[this.props.id][0]}
        </Tooltip>
      );
    }
    const help = this.props.help ? (
      <HelpBlock>{this.props.help}</HelpBlock>
    ) : (
      ""
    );

    let classNameForTooltip = "";

    if ((this.props.tooltip && error) || this.props.iconClass)
      classNameForTooltip = "input-icon right";

    if (this.props.justInput) {
      return this.renderInput();
    }
    let tooltipId = this.props.tooltipId ? this.props.tooltipId : this.props.id;
    const label = TooltipService.getLabelWithTooltip(
      tooltipId,
      this.props.label
    );

    return !this.props.isVertical ? (
      <FormGroup
        onFocus={this.props.onFocus}
        controlId={this.props.id}
        validationState={validationState}
      >
        {this.props.label && (
          <Col
            componentClass={ControlLabel}
            md={parseInt(this.props.labelMd, 10)}
          >
            {label}&nbsp;{" "}
            {this.props.required ? <span className="required"> * </span> : ""}
          </Col>
        )}
        <Col md={parseInt(this.props.colMd, 10)}>
          <div className={classNameForTooltip}>
            {error && this.props.tooltip ? (
              <OverlayTrigger placement="left" overlay={tooltip}>
                <i className="fas fa-exclamation-triangle tooltips" />
              </OverlayTrigger>
            ) : (
              ""
            )}
            {this.renderInput()}
            {this.props.childrenShow ? this.props.children : ""}
            {error && !this.props.tooltip ? error : help}
          </div>
        </Col>
        {this.props.childrenShow ? "" : this.props.children}
      </FormGroup>
    ) : (
      <FormGroup
        onFocus={this.props.onFocus}
        controlId={this.props.id}
        validationState={validationState}
      >
        {this.props.label && (
          <Col componentClass={ControlLabel} className="control-line">
            {label}&nbsp;{" "}
            {this.props.required ? <span className="required"> * </span> : ""}
          </Col>
        )}
        <div className={classNameForTooltip}>
          {error && this.props.tooltip ? (
            <OverlayTrigger placement="left" overlay={tooltip}>
              <i className="fas fa-exclamation-triangle tooltips"></i>
            </OverlayTrigger>
          ) : (
            ""
          )}
          {this.props.iconClass ? <i className={this.props.iconClass}></i> : ""}
          {this.renderInput()}
          {error && !this.props.tooltip ? error : help}
        </div>
      </FormGroup>
    );
  }

  render() {
    return this.renderInputNew();
  }
}

EkoFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  tooltipId: PropTypes.string,
  placeHolder: PropTypes.string,
  style: PropTypes.object,
  textProps: PropTypes.object,
  className: PropTypes.string,
  isVertical: PropTypes.bool,

  // inputun tipini tanımmlar
  type: PropTypes.string
};

EkoFormInput.defaultProps = {
  value: "",
  style: {},
  error: "",
  errors: {},
  formData: {},
  type: "",
  placeHolder: "",
  labelMd: 5,
  textProps: {},
  colMd: 6,
  disabled: false
};
