import React from 'react';
import TooltipService from '../../services/TooltipService';
import PropTypes from 'prop-types';

type Props = {
    formId: string | number,
    horiontal: boolean

}

type State = {
    loading: boolean
}
export default class EkoFormTooltip extends React.Component <Props, State>{
    props: Props;
    state: State;

    constructor(props) {
        super(props);


        this.state  = {
            loading : false
        }
    }

    onSubmit(e) {
        e.preventDefault();
    }


   /* shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        return this.state.loading !== nextState.loading  ||
        this.props.hortizonal !== nextProps.horizontal ||
            this.props.children !== nextProps.children;
    }*/

    componentWillMount() {
        TooltipService.setformId(this.props.formId || null);

        if (!TooltipService.dataFetched()) {
            this.setState({
                loading: true
            });
            TooltipService.fetchLabelsFromApi();
        }
    }

    render() {
        return <form id={this.props.formId || null} onSubmit={this.props.onSubmit || this.onSubmit.bind(this)}
                     className={this.props.hortizonal ? 'form-horizontal' : ''}>
            {this.props.children}
        </form>;
    }

}

EkoFormTooltip.propTypes = {
    hortizonal: PropTypes.bool,
    formId: PropTypes.string,
    onSubmit: PropTypes.func
};
