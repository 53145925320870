import React, { Component } from "react";
import {
  EkoFormButton,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
  FETCH_STATEMENT_DUES,
  FETCH_STATEMENT_TYPES
} from "../../actions/types";
import { toastr } from "react-redux-toastr";

class Statements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      errors: {},
      formFields: {},
      selected: {}
    };
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.fetchStatementValues();
    this.setState({ formData: formData, errors: errors });
  }

  defaultFormData() {
    return {
      statement: {}
    };
  }

  fetchStatementValues() {
    this.props.fetchData("/data/statementTypes", FETCH_STATEMENT_TYPES);
    this.props.fetchData("/data/statementDues", FETCH_STATEMENT_DUES);
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    if (field === "statement_due") {
      let selected = this.props.statementDues.filter(item => {
        return item.id === value;
      });
      if (selected[0].calculation_api_name) {
        bigForm[type]["selectedDue"] = selected[0];
      }
    }
    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const type_id = this.state.formData.statement.statement_type_id;
    let selectedType = this.props.statementTypes.filter(
      item => item.id === type_id
    )[0];

    const self = this;
    let selectedDue = self.state.formData.statement.selectedDue;

    if (selectedDue) {
      localStorage.setItem(
        "statement_preview_url",
        selectedDue.calculation_api_name + selectedDue.vat_period
      );
      localStorage.setItem("statement_preview_title", selectedDue.description);
      localStorage.setItem("statement_selected", JSON.stringify(selectedDue));
      localStorage.setItem(
        "statement_selected_type",
        JSON.stringify(selectedType)
      );
      this.props.router.push(selectedType.url);
    } else toastr.error("Beyan dönemi seçilmelidir!");
  }

  render() {
    const statementProps = {
      onChange: this.setFieldChange.bind(this, "statement"),
      onMount: this.setFieldMount.bind(this, "statement"),
      formData: this.state.formData.statement,
      errors: this.state.errors.statement
    };
    const statement = this.state.formData.statement || {};

    let statementDues = this.props.statementDues || [];
    const statementTypes = this.props.statementTypes || [];
    statementDues = statementDues.filter(
      d => statement && d.statement_type_id === statement.statement_type_id
    );
    return (
      <div>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption font-dark">
              <span className="caption-subject bold uppercase">Yeni Beyan</span>
            </div>
          </div>
          <div className="portlet-body">
            <div className="alldiv">
              <div className="col-md-3">
                <EkoFormSelect
                  {...statementProps}
                  id="statement_type_id"
                  clearable={true}
                  optionValue="name"
                  options={statementTypes}
                  optionId="id"
                  label="Beyan Türü"
                  isVertical={true}
                />
              </div>
              <div className="col-md-3">
                <EkoFormSelect
                  {...statementProps}
                  id="statement_due"
                  clearable={true}
                  optionValue="description"
                  options={statementDues || []}
                  optionId="id"
                  label="Beyan Dönemi"
                  isVertical={true}
                />
              </div>
              <div className="col-md-3">
                <div style={{ marginTop: "52" }}>
                  <EkoFormButton
                    label="ÖNİZLEME OLUŞTUR"
                    faClass="fa fa-eye"
                    onClick={this.onSave.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions,
    statementDues: state.data.statementDues,
    statementTypes: state.data.statementTypes
  };
}

export default connect(mapStateToProps, actions)(Statements);
