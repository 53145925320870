export const CSS = `nav {
    list-style-type: none;
    font-size: 13px;
    position: relative;
    margin-top: -11px;
    display: inline-block;    color: #6b6b6b;
    }

    nav a:hover {
      text-decoration:none
    }
    nav li:hover {
        background: #fff;
        border-bottom: 3px solid #000000;
        color: #000000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }.active{
    	color: #4a8bc2;
    	border-bottom: 3px solid #4a8bc2;
    }

    		nav li{
    		    border-bottom: 3px solid transparent;


    			display: inline-block;
    			line-height: 3.1;
    			padding: 4px 10px;

    		}
    		.nav-active{
    			    border-bottom: 3px solid #4a8bc2;
        background: #f7f7f7;
    		}
    		.actions3{
    			    float: right;
        display: inline-block;
    		}
    		.err{
    			height: 52px;
    		margin-bottom: 20px;
    		border-bottom:1px solid #eee
      }`
