import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {EkoFormButton, EkoFormInputText, EkoFormSelect} from "../../components/elements/EkoForm";
import {connect} from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import Util from "../../util/Util";
import EkoSpinner from "../../components/elements/EkoSpinner";
class ChangingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subItems: [],
            loading: false,
            ChangingModalRowData:[]
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({subItems:nextProps.ChangingModalData,ChangingModalRowData:nextProps.ChangingModalRowData});
    }
    closeModal = () => {
        this.props.closeModal();
    };
    onSave() {
        const self = this
        self.setState({saving: false});
        self.props.onSave(self.state.subItems);

    }
    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={"changingmodal"}
                showModal={this.props.showModal}
                title={"Alt Ürün İşlemleri"}
                onHide={this.closeModal.bind(this)}
            >
                <div className="row justify-content-center">
                    <div className="col-md-12 mx-auto justify-content-center">
                        <h4>
                            Alt Ürün Listesi
                        </h4>
                        <br />
                        {this.state.loading ? (
                            <EkoSpinner />
                        ) : (
                            <div>
                                {this.state.subItems.length > 0 ? (
                                    this.state.subItems.map((element, i) => {
                                        let subItem = { ...this.state.subItems[i] };
                                        let newArray = [...this.state.subItems];
                                        newArray[i] = subItem;
                                        if (element.name) {
                                            return (
                                                <div
                                                    key={element.id}
                                                    className="alert"
                                                    style={{
                                                        border: "1px solid #c2cad8",
                                                        marginTop: -13,
                                                        borderRadius: "4px"
                                                    }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        <div className="col-md-9">
                                                            <div style={{paddingTop: "8px"}}>
                                                                <span>{i + 1}</span> - {subItem.name} - Stok Miktarı: {subItem.quantity??0}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input
                                                                type="text"
                                                                value={this.state.subItems.find((x) => x.id === subItem.id).selectedQuantity ?? 0}
                                                                className="form-control"
                                                                onChange={e => {
                                                                    const updatedSubItems = this.state.subItems.map(subItemx => {
                                                                        if (subItem.id === subItemx.id)  {
                                                                            return {
                                                                                ...subItemx,
                                                                                selectedQuantity: e.target.value
                                                                            };
                                                                        }
                                                                        return subItemx;
                                                                    });

                                                                    this.setState({
                                                                        subItems: updatedSubItems
                                                                    }, () => {
                                                                        var total = 0;
                                                                        this.state.subItems.map(subItemx => {
                                                                            total += parseFloat(subItemx.selectedQuantity??0);
                                                                        });
                                                                        if(total>Util.convertMoneyToDecimal(this.state.ChangingModalRowData.quantity??0)){
                                                                            toastr.error("Dikkat", "Toplam miktar, satırdaki miktardan az");

                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                                ) : (
                                    <div className="alert alert-danger">
                                        {" "}
                                        Ürüne ait bir alt ürün bulunamadı.
                                    </div>
                                )}

                                {this.state.subItems.length > 0 ? (
                                    <button
                                    className="btn btn-primary"
                                    onClick={this.onSave.bind(this)}
                                >
                                    <i className="fa fa-add">&nbsp;</i>
                                    Kaydet
                                </button>
                                ):""}

                            </div>
                        )}
                    </div>
                </div>
            </EkoModal>
        );
    }
}
function mapStateToProps(state) {
    return {
        deliveryTypes: state.data.deliveryTypes,
        transportTypes: state.data.transportTypes,
        packageTypes: state.data.packageTypes,
    };
}
export default connect(mapStateToProps, actions)(ChangingModal);
