import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import Style from "style-it";
import { VCSS } from "../../css/voucher.js";
import { FETCH_CUSTOMERS_ALL, FETCH_SUPPLIERS_ALL } from "../../actions/types";
import UtilHelper from "../../util/UtilHelper";
import AddProforma from "./AddProforma";

class ProformaAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      fromWhos: []
    };
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        item_id: 0,
        item_code: "",
        description: "",
        quantity: 1,
        unit_price_fc: 0.0,
        discount_rate: 0,
        unit_id: 0,
        discount_amount_fc: 0,
        net_amount_fc: 0,
        vat_rate_type_id: 0,
        vat_amount_fc: 0
      }
    );
  }

  defaultFormData() {
    return {
      invoice: {
        customer_id: 0
      },
      rows: [this.defaultRowData()],
      footer: {}
    };
  }

  defaultErrors() {
    return {
      rows: [
        {
          item_id: false,
          description: false,
          quantity: false,
          unit_price_fc: false,
          discount_rate: false,
          unit_id: false,
          discount_amount_fc: false,
          net_amount_fc: false,
          vat_rate_type_id: false,
          vat_amount_fc: false,
          otherTaxes: false
        }
      ],
      invoice: {},
      footer: {}
    };
  }

  fetchFromWho() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
  }

  setFromWhos(nextProps) {
    if (nextProps.customersAll) {
      const fromWhos = nextProps.customersAll.filter(function(customer) {
        return customer.is_active === 1;
      });
      this.setState({ fromWhos: fromWhos });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customersAll) this.setFromWhos(nextProps);
  }

  prepareInvoiceLineForEdit(line, otherTaxes, isCopied) {
    let row = {};

    if (!isCopied) row.id = line.id;
    row.proforma_id = line.proforma_id;
    row.item_id = line.item_id;
    row.item_code = line.item_code;
    row.description = line.description;
    row.quantity = Util.convertDecimalToMoney7(line.quantity);
    row.unit_id = line.unit_id;
    row.vat_rate_type_id = line.vat_rate_type_id;
    row.discount_amount_fc = Util.convertDecimalToMoney(
      line.discount_amount_fc
    );
    row.discount_rate = Util.convertNumberToPercent(line.discount_rate);
    row.unit_price_fc = UtilHelper.money.convertDecimalToMoney(
      line.unit_price_fc
    );
    row.net_amount_fc = Util.convertDecimalToMoney(line.net_amount_fc);
    row.with_tax_amount_fc = UtilHelper.money.convertDecimalToMoney4(
        parseFloat(line.net_amount_fc) + parseFloat(line.vat_amount_fc)
    );
    row.vat_amount_fc = Util.convertDecimalToMoney(line.vat_amount_fc);
    row.otherTaxes = false;
    if (otherTaxes && otherTaxes.length > 0) {
      row.otherTaxes = [];
      otherTaxes.forEach(function(otherTax) {
        let tax = {};
        if (!isCopied) {
          tax.id = otherTax.id;
          tax.proforma_invoice_id = otherTax.proforma_invoice_id;
          tax.proforma_invoice_line_id = otherTax.proforma_invoice_line_id;
        }
        tax.tax_type_code = otherTax.tax_type_code;
        tax.tax_type_id = otherTax.tax_type_id;
        tax.vat_reduction_type_code = otherTax.vat_reduction_type_code;
        tax.tax_amount_fc = Util.convertDecimalToMoney(otherTax.tax_amount_fc);
        tax.tax_rate = Util.convertNumberToPercent(otherTax.tax_rate);
        row.otherTaxes.push(tax);
      });
    }
    return row;
  }

  prepareInvoiceForEdit(proforma) {
    let ret = {};

    ret.id = proforma.id;
    ret.customer_id = proforma.customer_id
      ? parseInt(proforma.customer_id, 10)
      : null;
    ret.currency = proforma.currency;
    ret.currency_rate = Util.convertDecimalToMoneyNew(proforma.currency_rate);
    ret.description = proforma.description;
    ret.customer_name = proforma.customer_name || "";
    ret.note = proforma.note;
    ret.proforma_num = proforma.proforma_num;
    ret.proforma_date = Util.convertFromDatabaseDate(proforma.proforma_date);
    ret.due_date = proforma.due_date
      ? Util.convertFromDatabaseDate(proforma.due_date)
      : "";
    ret.updated_at = proforma.updated_at || null;
    ret.is_checked = proforma.is_checked || false;

    return ret;
  }
  fetchSalesReturnAndPrepareData = id => {
    const formData = { ...this.state.formData };
    var self = this;
    Api.paralel(
        ["/purchaseInvoice/" + id, "/purchaseInvoiceLine/showAll/" + id],
        (responses, error) => {
          if (responses[0].purchaseInvoice) {
            formData.invoice = this.prepareSalesReturn(responses[0].purchaseInvoice);
          }
          if (responses[1].purchaseInvoiceLines) {
            const lines = responses[1].purchaseInvoiceLines;
            var taxApis = [];
            lines.forEach(function(line) {
              taxApis.push("/purchaseInvoiceTaxLine/showAll/" + line.id);
            });
            formData.rows = lines.map(line => {
              return this.prepareInvoiceLineForEdit(line, line.otherTaxes, true);
            });
          }
          this.setState({
            formData: formData
          });
        }
    );
  };
  prepareSalesReturn = item => {
    let ret = {};
    ret.currency_rate = Util.convertDecimalToString(item.currency_rate, 4);
    ret.currency = item.currency;
    ret.supplier_id = item.supplier_id
        ? parseInt(item.supplier_id, 10)
        : null;
    ret.description = item.description;
    return ret;
  };
  componentWillMount() {
    var self = this;
    const {
      router: {
        location: { query }
      }
    } = this.props;
    if (query.sales_return) {
      this.fetchSalesReturnAndPrepareData(query.sales_return);
    }
    this.fetchFromWho();

    if (this.props.params && this.props.params.id) {
      const invoiceId = this.props.params.id;
      let formData = this.state.formData;
      Api.paralel(
        ["/proforma/" + invoiceId, "/proformaLine/showAll/" + invoiceId],
        function(responses, error) {
          const docUrl = responses[0].proforma?.document_url;
          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }
          formData.invoice = self.prepareInvoiceForEdit(responses[0].proforma);

          let lines = [];
          let taxApis = [];
          if (
            responses[1].proformaLines &&
            responses[1].proformaLines.length > 0
          ) {
            lines = responses[1].proformaLines;

            lines.forEach(function(line) {
              taxApis.push("/proformaTaxLine/showAll/" + line.id);
            });

            if (taxApis.length > 0) {
              Api.serialGet(taxApis, function(taxResponses, taxErrors) {
                console.log(taxResponses);
                formData.rows = lines.map(function(line, index) {
                  return self.prepareInvoiceLineForEdit(
                      line,
                      taxResponses[index].proformaInvoiceTaxLines
                  );
                });

                self.setState(
                    { formData: formData },
                    function() {
                      self.fetchFromWho();
                      self.setFromWhos(self.props);
                    }
                );
              });
            } else {
              formData.rows = lines.map(function(line) {
                return self.prepareInvoiceLineForEdit(line, false);
              });
              self.setState(
                  { formData: formData },
                  function() {
                    self.fetchFromWho();
                    self.setFromWhos(self.props);
                  }
              );
            }
          } else {
            self.setState(
                { formData: formData },
                function() {
                  self.fetchFromWho();
                  self.setFromWhos(self.props);
                }
            );
          }
        }
      );
    }
  }

  onCancel() {
    this.props.router.push("/dashboard/salesInvoice/proformas");
  }

  render() {
    const editId =
      this.props.params && this.props.params.id ? this.props.params.id : false;
    return Style.it(
      VCSS,
      <div>
        <AddProforma
          {...this.state}
          editId={editId}
          onRefresh={this.fetchFromWho.bind(this)}
          errors={this.defaultErrors()}
          defaultErrors={() => this.defaultErrors()}
          defaultRowData={this.defaultRowData()}
          formData={this.state.formData}
          defaultFormData={() => this.defaultFormData()}
          onCancel={this.onCancel.bind(this)}
          viewOptions={this.props.viewOptions}
          redirectToBase={this.onCancel.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customersAll: state.data.customersAll,
    viewOptions: state.data.viewOptions,
    pageLoading: state.page.loading
  };
}

export default connect(mapStateToProps, actions)(ProformaAddUpdate);
