import { PERMISSIONS } from './PermittedPages'

const PermittedHeader = {
    isPermitted(pageId, html) {
        const selectedPage = PERMISSIONS.filter(function (permission) {
            return permission[0] === pageId
        })[0]

        if (selectedPage == null) return ''

        let planIds, companyTypes, roleIds = []
        if (selectedPage != null) {
            planIds = selectedPage[2].split(',');
            companyTypes = selectedPage[3].split(',');
            roleIds = selectedPage[4].split(',');

        }

        const user = JSON.parse(localStorage.getItem('user'));
        let permitted1, permitted2, permitted3 = false;
        if (user) {
            if (user.plan_id && planIds.indexOf(user.plan_id + "") > -1) {
                permitted1 = true;
            }

            if (user.company_type && companyTypes.indexOf(user.company_type) > -1)
                permitted2 = true;

            if (user.role_id && roleIds.indexOf(user.role_id + "") > -1)
                permitted3 = true;
        }
        console.log("permitted header ---->", permitted1, permitted2, permitted1);
        if (permitted1 && permitted2 && permitted3) return html
        else return ''
    }
}

export default PermittedHeader
