import React, {PureComponent} from 'react'
import Style from 'style-it'
import {CSS} from '../../css/chrome_recommendation.js'
import bowser from 'bowser'

export default class ChromeRecommendation extends PureComponent {


    state = {
        show: false
    }

    close() {
        localStorage.setItem('chromeRecommendation', 'CLOSED');

        this.setState({
            show: false
        })
    }

    componentDidMount() {
        const chromeRecommendation = localStorage.getItem('chromeRecommendation');


        if (chromeRecommendation !== 'CLOSED' && chromeRecommendation === null) {
            const browser = bowser.getParser(window.navigator.userAgent);

            const satistifies = !browser.satisfies({
                // declare browsers per OS
                windows: {
                    "internet explorer": ">10",
                },
                macos: {
                    safari: ">10.1"
                },

                // per platform (mobile, desktop or tablet)
                mobile: {
                    safari: '>9',
                    'android browser': '>3.10'
                },

                // or in general
                chrome: ">50",
                firefox: ">31",
                opera: ">31"

            });

            this.setState({
                show: satistifies
            })
        }
    }


    render() {



        return (
            Style.it(
                CSS,
                <div>
                    {
                        this.state.show ?
                            <div className="chrome">
                                <div className="chrome-container">
                                    <img
                                        alt={"Eko Hesap'ı daha hızlı kullanabilmek için tarayıcınızı son veriyona güncelleyin!"}
                                        src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Google_Chrome-128.png"/>Eko
                                    Hesap'ı daha hızlı kullanabilmek için tayacınızı son versiyona yükseltin ya da <a
                                    href="https://www.google.com/intl/tr/chrome/browser/desktop/index.html">Google
                                    Chrome'u yükleyin.</a>
                                    <i className="fas fa-times" aria-hidden="true" onClick={this.close.bind(this)}/>
                                </div>
                            </div>
                            : ''
                    }
                </div>
            )
        )
    }
}
