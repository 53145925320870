import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormCheckbox,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormSwitch,
  EkoFormDate, EkoFormAutoComplete,
} from "../../components/elements/EkoForm";
import { Modal, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import NewTagDocumentModal from "../Company/newTagDocument";
import axios from "axios";
import {ROOT_URL} from "../../actions";

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData || this.defaultFormData(),
      showModal: false,
      detailIsOpened: false,
      gercekTuzel: true,
      counties: [],
      saving: false,
      savingVkn: false,
      errors: {},
      activeTab: "address",
      formFields: {},
      vknLoad: false,
      country:[],
      copy_record:false,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:15,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      customerAccountCodeList:JSON.parse(localStorage.getItem("account_code_list")).data,
    };
    //  this.onCancel = this.onCancel.bind(this);
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = (formData) => {
    formData = formData || this.state.formData;
    if (formData.customer.id) {
      Api.get("/company/newTagRecordDocumentList/15/" + formData.customer.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }else{
          this.resetNewTagModalList();
        }
      });
    }else{
      this.resetNewTagModalList();
    }
  }
  resetNewTagModalList = () => {
    this.setState(prevState => ({
      newTagRows: {
        ...prevState.newTagRows,
        rows: [
          { id:null,new_tag_type_id:15,new_tag_id: "",record_id:null, rate: "%100,00" }
        ],
      },
    }));
  }
  defaultFormData() {
    return {
      customer: {
        delivery_same_as_invoice_address: true,
        currency: "TRY",
        opening_date: moment().format("YYYY-MM-DD"),
        amount_type:0,
        company_type:1
      },
      contact: {},
      address: {},
      deliveryAddress: {},
    };
  }

  static getDefaultForm() {
    return {
      customer: { delivery_same_as_invoice_address: true,currency: "TRY" },
      contact: {},
      address: {},
      deliveryAddress: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    this.getNewTagModalList(formData);
    this.saveCustomerAccountList();
    let errors = nextProps.errors || {};
    this.setState({
      formData: formData,
      errors: errors,
      showModal: nextProps.showModal,
      country:JSON.parse(localStorage.getItem("country_list"))
    });
    this.decideTab(nextProps.detailIsOpened, nextProps.gercekTuzel);
  }

  decideTab(isOpened, tuzel) {
    let activeTab = "address";
    let detailIsOpened = isOpened || this.state.detailIsOpened;
    let gercekTuzel = tuzel || this.state.gercekTuzel;

    if (this.props.activeTab && Sugar.Object.isEmpty(this.state.errors)) {
      activeTab = this.props.activeTab;
      detailIsOpened = true;
    } else {
      if (!Sugar.Object.isEmpty(this.state.errors.address)) {
        activeTab = "address";
        detailIsOpened = true;
      } else if (!Sugar.Object.isEmpty(this.state.errors.deliveryAddress)) {
        activeTab = "deliveryAddress";
        detailIsOpened = true;
      } else if (!Sugar.Object.isEmpty(this.state.errors.contact)) {
        activeTab = "contact";
        detailIsOpened = true;
      }
    }
    this.setState({
      activeTab: activeTab,
      detailIsOpened: detailIsOpened,
      gercekTuzel,
    });
  }

  toggleDetail(state) {
    this.setState({ detailIsOpened: state });
  }
  toggleDetail2(state) {
    if (state) {
    }
    this.setState({ gercekTuzel: state });
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    if (field === "country_id") {
      if (formData[field] !== value) {
        formData["city_id"] = null;
      }
      if (value !== 239) {
        formData["city_id"] = 82;
        formData["county_id"] = 999;
      }
    }
    if (field === "city_id") {
      if (formData[field] !== value) {
        formData["county_id"] = null;
      }
      if (value === 82) {
        formData["county_id"] = 999;
      }
    }
    if (field === "opening_balance") {
      if(formData['amount_type']==0){
        //borcum da alacağım da yok
        value = Util.convertDecimalToMoney(0);
      }else if(formData['amount_type']==1){
        //borcum var
        if(value>0){
          value = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(value)));
        }
      }else if(formData['amount_type']==2){
        //alacağım var
        if(value<0) {
          value = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(value)));
        }
      }
    }
    if (field === "balance") {
      if(formData['amount_type']==0){
        //borcum da alacağım da yok
        value = Util.convertDecimalToMoney(0);
      }else if(formData['amount_type']==1){
        //borcum var
        if(Util.convertMoneyToDecimal(value)>0) {
          value = -1 * value;
        }
      }else if(formData['amount_type']==2){
        //alacağım var
        if(Util.convertMoneyToDecimal(value)<0) {
          value = -1 * value;
        }
      }
    }
    if (field === "amount_type") {
      if (formData.id) {
        if(value==0){
          //borcum da alacağım da yok
          formData['opening_balance'] = Util.convertDecimalToMoney(0);
        }else if(value==1){
          //borcum var
          if(Util.convertMoneyToDecimal(formData['opening_balance'])>0) {
            formData['opening_balance'] = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(formData['opening_balance'])));
          }
        }else if(value==2){
          //alacağım var
          if(Util.convertMoneyToDecimal(formData['opening_balance'])<0) {
            formData['opening_balance'] = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(formData['opening_balance'])));
          }
        }
      }else{
        if(value==0){
          //borcum da alacağım da yok
          formData['balance'] = Util.convertDecimalToMoney(0);
        }else if(value==1){
          //borcum var
          if(Util.convertMoneyToDecimal(formData['balance'])>0) {
            formData['balance'] = Util.convertDecimalToMoney(-Math.abs(Util.convertMoneyToDecimal(formData['balance'])));
          }
        }else if(value==2){
          //alacağım var
          if(Util.convertMoneyToDecimal(formData['balance'])<0) {
            formData['balance'] = Util.convertDecimalToMoney(Math.abs(Util.convertMoneyToDecimal(formData['balance'])));
          }
        }
      }
    }
    if (field === "company_type") {
      if (value === 2) {
        formData['tax_identity_number'] = "11111111111";
      }else if (value === 3) {
        formData['tax_identity_number'] = "2222222222";
      }
      if (value === 0) {
        this.state.gercekTuzel = false;
      } else {
        this.state.gercekTuzel = true;
      }
    }
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = {
      ...this.state.formData,
      [type]: {
        ...formData,
      },
    };

    if (field === "tax_identity_number") {
      let customer_tax_identity_number =
        this.state.formData.customer.tax_identity_number;

      let tax_identity_number_length = customer_tax_identity_number.length;

      if (tax_identity_number_length >= 10) {
        Api.get(
          "/check-customer/" + customer_tax_identity_number,
          function (response) {
            if (response.status === "fail") {
              toastr.warning(response.message);
            }
          }
        );
      }
    }

    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }
  cleanString(str) {
    if(str !==undefined){
      str = str.replace(/&/g, '');
      str = str.replace(/%/g, '');
    }
    return str;
  }
  onSave() {
    var self = this;
    let formData = this.state.formData;
    let get;
    if (this.state.formData?.customer?.account_code !== undefined) {
      if (formData.customer?.id === undefined) {
        get = self.state.customerAccountCodeList.filter(o => o['account_code'] === this.state.formData.customer.account_code)[0];
        if (get!==undefined) {
            toastr.error("Hata", "Bu Cari Hesap Kodu zaten kullanılmaktadır.");
            return false;
        }
      }
    }
    if (this.state.formData.contact.phone) {
      let phone = Util.deleteAllExceptNumbers(
        this.state.formData.contact.phone || ""
      );
      let contact = Object.assign({}, self.state.formData.contact, {
        phone: phone,
      });
      formData.contact = contact;
    }
    self.setState({ saving: true });
    let validationRules = [];
    let postRules = [];
    //NOTE @bocek hangi form kisimlairni, hangi validationa gonderecegiz tanimliyoruz,
    //buradaki keyler AddCustomer formundaki formData ile aynı olmasi gerek
    if (formData.address.address || formData.address.city_id)
      validationRules.push({
        key: "address",
        url: "/customerAddress/validation",
      });
    validationRules.push({
      key: "contact",
      url: "/customerContact/validation",
    });
    if (formData.address.is_abroad) {
      formData.address.city_id = null;
      formData.address.county_id = null;
      formData.address.country_id = null;
      formData.address.is_abroad = 1;
    } else formData.address.is_abroad = 0;

    if (!formData.customer.delivery_same_as_invoice_address) {
      validationRules.push({
        key: "deliveryAddress",
        url: "/customerAddress/validation",
      });
      postRules.push({ key: "deliveryAddress", url: "/customerAddress" });
      if (Util.isEmpty(formData.deliveryAddress)) {
        formData.deliveryAddress = {
          address: null,
          city_id: null,
          county_id: null,
          country_id: null,
        };
      }
      if (formData.deliveryAddress.is_abroad) {
        formData.deliveryAddress.city_id = null;
        formData.deliveryAddress.county_id = null;
        formData.deliveryAddress.country_id = null;
        formData.deliveryAddress.is_abroad = 1;
      } else formData.deliveryAddress.is_abroad = 0;
    }
    formData.customer.newTagRows=this.state.newTagRows;

    //pustRules.push({key:"customer",url:"/customer"});
    if (formData.address.address || formData.address.city_id)
      postRules.push({
        key: "address",
        url: "/customerAddress",
      });
    postRules.push({ key: "contact", url: "/customerContact" });

    // axios.all(['/x/valida','yvadalida']).
    HelperContainer.validateContainerData(
      self.props,
      validationRules,
      formData,
      self.state.errors,
      function (validated, errors, validationFields) {
        if (validated) {
          console.log("validated response");
          formData.customer.balance = Util.convertMoneyToDecimal(
            formData.customer.balance || "0,00"
          );
          let func = "post";
          let url = "/customer";
          if (formData.customer.id) {
            func = "put";
            url = "/customer/" + formData.customer.id;
            formData.customer.opening_balance = Util.convertMoneyToDecimal(
              formData.customer.opening_balance || "0,00"
            );
          } else {
            formData.customer.opening_balance = formData.customer.balance;
            delete formData.customer.balance;
          }

          formData.customer.firstname = self.cleanString(formData.customer.firstname);
          formData.customer.lastname = self.cleanString(formData.customer.lastname);
          Api[func](url, formData.customer, function (response, postErrors) {
            if (postErrors) {
              if (postErrors.message) {
                toastr.error("Hata", postErrors.message);
                errors.customer = {};
              } else {
                errors.customer = postErrors;
              }
              errors.address = {};
              errors.deliveryAddress = {};
              self.setState({ saving: false, errors: errors });
              self.decideTab();
            } else {
              formData.address["customer_id"] = response.customer.id;
              formData.contact["customer_id"] = response.customer.id;
              formData.contact["is_main_contact"] = 1;
              formData.address["address_type"] = 2; // fatura adres
              if (!formData.customer.delivery_same_as_invoice_address) {
                formData.deliveryAddress["address_type"] = 1;
                formData.deliveryAddress["customer_id"] = response.customer.id;
              }
              HelperContainer.postContainerData(
                self.props,
                validationFields,
                postRules,
                formData,
                self.state.errors,
                function (validated, postErrors) {
                  if (postErrors.hasOwnProperty("sysError")) {
                    toastr.error(
                      "Hata",
                      "Genel bir hata oluştu, lütfen daha sonra deneyiniz"
                    );
                  } else {
                    if(!self.state.formData.customer.id){
                      if(self.state.copy_record===true){
                        var postRulesCopy = postRules;
                        postRulesCopy.map((element,index) => {
                          if(postRulesCopy[index].url=='/customerContact'){
                            postRulesCopy[index].url = '/supplierContact';
                          }
                          if(postRulesCopy[index].url=='/customer'){
                            postRulesCopy[index].url = '/supplier';
                          }
                          if(postRulesCopy[index].url=='/customerAddress'){
                            postRulesCopy[index].url = '/supplierAddress';
                          }
                        });
                        formData.customer.opening_balance = 0;
                        formData.customer.amount_type = 0;
                        Api[func]("/supplier", formData.customer, function (response, postErrors) {
                          formData.address["supplier_id"] = response.supplier.id;
                          formData.contact["supplier_id"] = response.supplier.id;
                          formData.contact["is_main_contact"] = 1;
                          formData.address["address_type"] = 2; // fatura adres
                          if (!formData.customer.delivery_same_as_invoice_address) {
                            formData.deliveryAddress["address_type"] = 1;
                            formData.deliveryAddress["supplier_id"] = response.supplier.id;
                          }
                          HelperContainer.postContainerData(
                              self.props,
                              validationFields,
                              postRulesCopy,
                              formData,
                              self.state.errors,
                              function (validated, postErrors) {}
                          );
                        })
                      }
                    }
                    self.setState({
                      saving: false,
                      errors: postErrors,
                      formData: self.defaultFormData(),
                    });
                    self.decideTab();
                    toastr.success("Başarılı", "Başarıyla Kaydedildi");
                    self.saveCustomerAccountList();
                    self.props.onSave(response.customer.id);
                  }
                }
              );
            }
          });
        } else {
          if (errors.hasOwnProperty("sysError")) {
            toastr.error(
              "Hata",
              "Genel bir hata oluştu, lütfen daha sonra deneyiniz"
            );
          } else {
            self.setState({ saving: false, errors: errors });
            self.decideTab();
          }
          //toastr.error("Lütfen Kontrol Ediniz","",{component:<EkoToastrError errors={errors}></EkoToastrError>});
          //  self.setState({saving:false,errors:errors});
        }
      }
    );
  }

  onCancel() {
    this.resetNewTagModalList();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  newItem(val) {
    console.log(val);
  }

  handleTabSelect(key) {
    this.setState({ activeTab: key });
  }

  clearDeliveryAddress() {
    var formData = { ...this.state.formData, deliveryAddress: {} };
    this.setState({ formData: formData });
  }

  abroadChange(field, e) {
    const deliverySameAsInvoiceAddress =
      this.state.formData.customer.delivery_same_as_invoice_address;
    const isDeliveryAddressReadOnly =
      deliverySameAsInvoiceAddress === 1 ||
      deliverySameAsInvoiceAddress === true;
    if (field === "address") {
      this.setFieldChange(field, "is_abroad", e.target.checked);
      if (isDeliveryAddressReadOnly)
        this.setFieldChange("deliveryAddress", "is_abroad", e.target.checked);
    } else this.setFieldChange(field, "is_abroad", e.target.checked);
  }
  saveCustomerAccountList(){
    axios.get(ROOT_URL + "/customer/allCustomerAccountCode", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("account_code_list", '');
      localStorage.setItem("account_code_list", data);
      this.setState({ customerAccountCodeList: response.data.data });
    });
  }
  copyRecord(e) {
    this.setState({copy_record:e.target.checked});
  }
  // queryVkn() {

  //     const self = this;
  //     self.setState({savingVkn: true});

  //     let formData = this.state.formData;
  //     let no = this.state.formData.customer.tax_identity_number;
  //     let noApi = {no: this.state.formData.customer.tax_identity_number};

  //     if(!no || no.length <= 9) {
  //         toastr.error("Lütfen sorgulama için 10 veya 11 karakter giriniz")
  //     } else {
  //         Api.post('/notary/query', noApi, function(response, error) {
  //             if(response[0]) {
  //                 if(no.length === 11) {
  //                     let address = response[0].GercekSahisMukellefMerkezBilgiSorguResult.IkametgahAdresi;
  //                     formData.customer.firstname = response[0].company_info.firstname;
  //                     formData.customer.lastname = response[0].company_info.lastname;
  //                     formData.address.address = address.MahalleSemt + " " + address.CaddeSokak + " " + address.KapiNO + "/" + address.DaireNO + " - " + address.IlAdi;
  //                     formData.address.city_id = parseInt(response[0].company_info.Address.city_id);
  //                     formData.address.county_id = response[0].company_info.Address.county_id;
  //                     formData.customer.no = response[0].GercekSahisMukellefMerkezBilgiSorguResult.SorguKimlikNO;
  //                 } else if(no.length === 10) {
  //                     formData.customer.firstname = response[0].company_info.firstname;
  //                     formData.customer.lastname = response[0].company_info.lastname;
  //                     formData.address.address = response[0].company_info.Address.address;
  //                     formData.address.city_id = parseInt(response[0].company_info.Address.city_id);
  //                     formData.address.county_id = response[0].company_info.Address.county_id;
  //                     formData.customer.no = response[0].TuzelSahisMukellefMerkezBilgiSorguResult.SorguKimlikNO;
  //                 } else {
  //                     toastr.error("Bir hata oluştu")
  //                 }

  //             } else if(response.status === "fail") {
  //                 console.log("işlem başarısız oldu", this.state.saving)
  //             } else {
  //                 toastr.error("Beklenmedik bir hata oluştu")
  //             }
  //         })
  //     }
  // }

  onVknSearch() {
    const self = this;
    let formData = self.state.formData;
    self.setState({ vknLoad: true });
    Api.post(
      "/customer/searchVkn",
      { identity: formData.customer?.tax_identity_number },
      function (response, error) {
        self.setState({ vknLoad: false });
        if (error) {
          self.setState({
            errors: {
              ...self.state.errors,
              customer: {
                ...self.state.errors.customer,
                tax_identity_number:
                  "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
              },
            },
          });
        } else {
          const info = response.data?.info || {};
          const taxOffice = response.data?.taxOffice || {};
          const c = formData.customer;
          const gercekTuzel = info.gercekTuzel;
          if(gercekTuzel){
            //gerçek kişi
            self.state.formData.customer.company_type = 1;
          }else{
            //aş ltd
            self.state.formData.customer.company_type = 0;
          }
          if (info?.companyName) {
            self.setState({
              errors: {
                ...self.state.errors,
                customer: {
                  ...self.state.errors.customer,
                  tax_identity_number: null,
                },
              },
            });
            self.setState({
              detailIsOpened: true,
              gercekTuzel,
              formData: {
                ...formData,
                customer: {
                  ...formData.customer,
                  firstname: info?.firstname || c.firstname,
                  lastname: info?.lastname || c.lastname || "",
                  tax_office_code: taxOffice?.code || c.tax_office_code,
                },
                address: {
                  ...formData.address,
                  address: info.address || formData.address.address,
                  city_id: info.city_id || formData.address.city_id,
                  county_id: info.county_id || formData.address.county_id,
                  country_id: info.country_id || formData.address.country_id,
                },
              },
            });
          } else {
            self.setState({
              errors: {
                ...self.state.errors,
                customer: {
                  ...self.state.errors.customer,
                  tax_identity_number:
                    "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                },
              },
            });
          }
        }
      }
    );
  }

  render() {
    const company_type_list = [
      { id: 0, name: "A.Ş. / L.T.D." },
      { id: 1, name: "Gerçek Kişi" },
      { id: 2, name: "Nihai Alıcı" },
      { id: 3, name: "Yurtdışı Alıcı" },
    ];
    const deliverySameAsInvoiceAddress =
      this.state.formData.customer.delivery_same_as_invoice_address;
    const isDeliveryAddressReadOnly =
      deliverySameAsInvoiceAddress === 1 ||
      deliverySameAsInvoiceAddress === true;
    const deliveryAddress = this.state.formData.deliveryAddress
      ? this.state.formData.deliveryAddress
      : {
          address: null,
          country_id: null,
          city_id: null,
          county_id: null,
        };
    const activeTab = this.state.activeTab;
    const customerProps = {
      onChange: this.setFieldChange.bind(this, "customer"),
      onMount: this.setFieldMount.bind(this, "customer"),
      formData: this.state.formData.customer,
      errors: this.state.errors.customer,
    };

    const addressProps = {
      onChange: this.setFieldChange.bind(this, "address"),
      onMount: this.setFieldMount.bind(this, "address"),
      formData: this.state.formData.address,
      errors: this.state.errors.address,
    };

    const deliveryAddressProps = {
      onChange: this.setFieldChange.bind(this, "deliveryAddress"),
      onMount: this.setFieldMount.bind(this, "deliveryAddress"),
      formData: isDeliveryAddressReadOnly
        ? this.state.formData.address
        : deliveryAddress,
      errors: this.state.errors.deliveryAddress,
    };

    const contactProps = {
      onChange: this.setFieldChange.bind(this, "contact"),
      onMount: this.setFieldMount.bind(this, "contact"),
      formData: this.state.formData.contact,
      errors: this.state.errors.contact,
    };
    const otherReceiptAutoCompleteProps = {
      ...customerProps,
      //onSelect: this.setFieldChangeForAutoComplete.bind(this)
    };

    const taxOffices = this.props.taxOffices
      ? this.props.taxOffices.map(function (obj) {
          if (obj && obj.city) {
            obj.full_name = obj.name + " - " + obj.city.name;
          }
          return obj;
        })
      : [];
    if (this.state.formData.customer.id) {
      if (this.state.formData.customer.company_type === 0) {
        this.state.gercekTuzel = false;
      } else {
        this.state.gercekTuzel = true;
      }
    }
    var selectedCountry = this.state.formData.address && this.state.formData.address.country_id;
    selectedCountry = selectedCountry?selectedCountry:239;
    const selectedCity = this.state.formData.address && this.state.formData.address.city_id;

    const cities = this.props.cities
      ? this.props.cities.filter(function (obj) {
          return obj.country_id === selectedCountry || obj.id === 82;
        })
      : [];
    const counties = this.props.counties
      ? this.props.counties.filter(function (obj) {
          return obj.city_id === selectedCity || obj.id === 999;
        })
      : [];

    const selectedCountryDelivery =
      this.state.formData.deliveryAddress &&
      this.state.formData.deliveryAddress.country_id;

    const selectedCityDelivery =
        this.state.formData.deliveryAddress &&
        this.state.formData.deliveryAddress.city_id;

    let cityDelivery = this.props.cities
      ? this.props.cities.filter(function (obj) {
          return obj.country_id === selectedCountryDelivery || obj.id === 82;
        })
      : [];

    let countiesDelivery = this.props.counties
        ? this.props.counties.filter(function (obj) {
          return obj.city_id === selectedCityDelivery || obj.id === 999;
        })
        : [];

    if (this.state.formData.customer.delivery_same_as_invoice_address) {
      cityDelivery = cities;
      countiesDelivery = counties;
    }

    const displayDetail = this.state.detailIsOpened ? "" : "none";
    const gercekTuzelDetail = this.state.gercekTuzel == true ? "" : "none";
    var amount_type_select = JSON.parse('[ { "id": 0, "name": "Alacağım, Borcum Yok" },{ "id": 1, "name": "Borcum Var" },{ "id": 2, "name": "Alacağım Var" } ]');
    return (
      <div>
        <EkoModal
            showModal={this.state.showModal}
            title={
              this.state.formData.customer && this.state.formData.customer.id
                  ? "Müşteri Güncelle"
                  : "Müşteri Ekle"
            }
            onHide={() => {
              this.setState({
                showModal: false,
                formData: this.defaultFormData(),
                errors: {},
              });
              this.onCancel();
            }}
            spinner={this.state.saving}
        >
          <EkoForm formId={"add_customer_modal"}>
            <div className="row">
              <div className="col-md-10">
                <EkoFormInputText
                    {...customerProps}
                    labelMd={6}
                    colMd={6}
                    mask="99999999999"
                    maskChar=""
                    type="mask"
                    // type="addon"
                    label="Vergi No / TC Kimlik No"
                    id="tax_identity_number"
                    // load={this.state.savingVkn}
                    // queryVkn={this.queryVkn.bind(this)}
                />
              </div>
              <div className="col-md-2">
                <EkoFormButton
                    label="Sorgula"
                    // faClass="fa fa-plus"
                    isLoading={this.state.vknLoad}
                    onClick={this.onVknSearch.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5" style={{"textAlign":"right"}}>Şirket Tipi</div>
              <div className="col-md-6">
                <EkoFormSelect
                    {...customerProps}
                    id="company_type"
                    isVertical
                    optionValue={"name"}
                    optionId={"id"}
                    defaultText="Seçiniz"
                    options={company_type_list}
                    searchable={true}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <EkoFormInputText
                {...customerProps} //formData.customer.firrsname =
                label="Adı / Firma Ünvanı"
                id="firstname" //errors.customer.firstname = bla bla hatasi
                required={true}
            />
            <div style={{ display: gercekTuzelDetail }}>
              <EkoFormInputText
                  {...customerProps}
                  label="Soyadı / Firma Ünvanı Devamı"
                  id="lastname"
              />
            </div>
            <EkoFormAutoComplete
                {...otherReceiptAutoCompleteProps}
                label="Cari Hesap Kodu"
                options={this.state.customerAccountCodeList}
                optionValue="account_code"
                id="account_code"
            />
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7">{!this.state.formData.customer.id ? (<div><input
                  className="form-check-input"
                  onChange={this.copyRecord.bind(this)}
                  type="checkbox"
              />&nbsp;Müşteri Kartını Tedarikçi Olarak da Aç</div>):("")}</div>
            </div>
            <div className="row buttonProccess2">
              <div className="col-md-3">
                <EkoFormDate
                    {...customerProps}
                    labelMd={8}
                    colMd={4}
                    id="opening_date"
                    dateFormat="DD-MM-YYYY"
                    isVertical={true}
                    clearable={false}
                    label="Bakiye Tarihi"
                />
              </div>
              <div className="col-md-4">
                <EkoFormSelect
                    {...customerProps}
                    id="amount_type"
                    optionValue={"name"}
                    optionId={"id"}
                    defaultText="Seçiniz"
                    options={amount_type_select}
                    label="Bakiye Tipi"
                    isVertical
                />
              </div>
              <div className="col-md-3">
                {this.state.formData.customer.id ? (
                    <EkoFormInputText
                        labelMd={8}
                        colMd={4}
                        {...customerProps}
                        label={
                          this.state.formData.customer.id
                              ? "Açılış Bakiyesi"
                              : "Açılış Bakiyesi"
                        }
                        id="opening_balance"
                        type="money"
                        placeHolder="0,00"
                        disabled={this.state.formData.customer && this.state.formData.customer.amount_type==0?true:false}
                        isVertical
                    />
                ) : (
                    <EkoFormInputText
                        {...customerProps}
                        labelMd={8}
                        colMd={4}
                        label={
                          this.state.formData.customer.id
                              ? "Bakiyesi"
                              : "Açılış Bakiyesi"
                        }
                        id="balance"
                        type="money"
                        placeHolder="0,00"
                        disabled={this.state.formData.customer && this.state.formData.customer.amount_type==0?true:false}
                        isVertical
                    />
                )}
              </div>
              <div className="col-md-2">
                <EkoFormSelect
                    {...customerProps}
                    id="currency"
                    optionValue={"code"}
                    optionId={"code"}
                    defaultText="Seçiniz"
                    options={this.props.currencies}
                    label="Döviz Tipi"
                    disabled={this.state.formData.customer && this.state.formData.customer.id?true:false}
                    isVertical
                />
              </div>
            </div>
            <EkoFormSwitch
                onChange={this.toggleDetail.bind(this)}
                value={this.state.detailIsOpened}
                label="Gelişmiş Seçenekler"
                leftBox={false}
            />
            <div style={{ display: displayDetail }}>
              <div className="portlet-body" style={{ marginTop: 20 }}>
                <Tab.Container
                    id="detailTab"
                    onSelect={this.handleTabSelect}
                    activeKey={activeTab}
                >
                  <Row className="clearfix">
                    <Nav bsStyle="tabs">
                      <NavItem
                          eventKey="address"
                          onClick={() => this.handleTabSelect("address")}
                      >
                        Fatura Adresi Bilgileri
                      </NavItem>
                      <NavItem
                          eventKey="deliveryAddress"
                          onClick={() => this.handleTabSelect("deliveryAddress")}
                      >
                        Teslimat Adresi Bilgileri
                      </NavItem>
                      <NavItem
                          eventKey="customer"
                          onClick={() => this.handleTabSelect("customer")}
                      >
                        Vade Bilgileri
                      </NavItem>
                      <NavItem
                          eventKey="contact"
                          onClick={() => this.handleTabSelect("contact")}
                      >
                        İletişim
                      </NavItem>
                    </Nav>
                    <Tab.Content animation>
                      <Tab.Pane eventKey="address">
                        <EkoFormSelect
                            {...customerProps}
                            searchable={true}
                            id="tax_office_code"
                            clearable={true}
                            optionValue="full_name"
                            options={taxOffices}
                            optionId="code"
                            label="Vergi Dairesi Adı"
                        />
                        <EkoFormInputText
                            {...addressProps}
                            label="Adres" //formData.address.address
                            id="address"
                            type="textarea"
                            childrenShow
                            childValue={
                                this.state.formData.address &&
                                this.state.formData.address.is_abroad
                            }
                        >
                          {
                            /*
                            <label
                            className="form-check-label border"
                            style={
                              this.state.formData.address &&
                              this.state.formData.address.is_abroad
                                ? {
                                    display: "block",
                                    borderColor: "#ccc",
                                    padding: ".5em",
                                    borderRadius: "0 0 3px 3px",
                                    fontWeight: "bold",
                                    fontSize: "small",
                                  }
                                : {
                                    display: "block",
                                    borderColor: "#ccc",
                                    padding: ".5em",
                                    borderRadius: "0 0 3px 3px",
                                    fontSize: "small",
                                  }
                            }
                          >
                            <input
                              className="form-check-input"
                              checked={
                                this.state.formData.address &&
                                this.state.formData.address.is_abroad
                              }
                              onChange={this.abroadChange.bind(this, "address")}
                              type="checkbox"
                            />
                            &nbsp;Adresi yurtdışında
                          </label>
                            */
                          }
                        </EkoFormInputText>
                        {this.state.formData.address &&
                        this.state.formData.address.is_abroad ? (
                            ""
                        ) : (
                            <span>
                          <EkoFormSelect
                              {...addressProps}
                              searchable={true}
                              id="country_id"
                              clearable={true}
                              optionValue="name"
                              defaultText="Seçiniz"
                              options={this.state.country}
                              value={typeof this.state.formData.address.country_id === 'undefined' ? 239 : this.state.formData.address.country_id}
                              label="Ülke"
                          />
                          <div style={{"display":typeof this.state.formData.address.country_id === 'undefined' || this.state.formData.address.country_id===239?"":"none"}}>
                            <EkoFormSelect
                                {...addressProps}
                                searchable={true}
                                id="city_id" //formData.address.city_id
                                clearable={true}
                                optionValue="name"
                                defaultText="Seçiniz"
                                options={cities}
                                label="İl"
                            />
                          <EkoFormSelect
                              {...addressProps}
                              searchable={true}
                              id="county_id"
                              clearable={true}
                              optionValue="name"
                              defaultText="Seçiniz"
                              options={counties}
                              label="İlçe"
                          />
                          </div>
                          <div style={{"display":typeof this.state.formData.address.country_id !== 'undefined' && this.state.formData.address.country_id!==239?"":"none"}}>
                           <EkoFormInputText
                               {...addressProps}
                               type="text"
                               label="İl / Eyalet"
                               id="city_name"
                           />
                            <EkoFormInputText
                                {...addressProps}
                                type="text"
                                label="İlçe / Şehir"
                                id="county_name"
                            />
                          </div>
                        </span>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="deliveryAddress">
                        <div
                            className="row btn-group form-control"
                            style={{ border: "0" }}
                        >
                          <EkoFormCheckbox
                              {...customerProps}
                              id="delivery_same_as_invoice_address"
                              onFalse={this.clearDeliveryAddress.bind(this)}
                              label="Adres bilgileri teslimat ile aynı mı?"
                          ></EkoFormCheckbox>
                        </div>
                        <EkoFormInputText
                            {...deliveryAddressProps}
                            label="Adres" //formData.address.address
                            id="address"
                            tooltipId="deliveryAddress"
                            type="textarea"
                            childrenShow
                            childValue={
                                this.state.formData.deliveryAddress &&
                                this.state.formData.deliveryAddress.is_abroad
                            }
                            disabled={isDeliveryAddressReadOnly}
                        >
                          {
                            /*
                            <label
                            className="form-check-label border"
                            style={
                              this.state.formData.deliveryAddress &&
                              this.state.formData.deliveryAddress.is_abroad
                                ? {
                                    display: "block",
                                    borderColor: "#ccc",
                                    padding: ".5em",
                                    borderRadius: "0 0 3px 3px",
                                    fontWeight: "bold",
                                    fontSize: "small",
                                  }
                                : {
                                    display: "block",
                                    borderColor: "#ccc",
                                    padding: ".5em",
                                    borderRadius: "0 0 3px 3px",
                                    fontSize: "small",
                                  }
                            }
                          >
                            <input
                              className="form-check-input"
                              checked={
                                this.state.formData.deliveryAddress &&
                                this.state.formData.deliveryAddress.is_abroad
                              }
                              disabled={isDeliveryAddressReadOnly}
                              onChange={this.abroadChange.bind(
                                this,
                                "deliveryAddress"
                              )}
                              type="checkbox"
                            />
                            &nbsp;Adresi yurtdışında
                          </label>
                            */
                          }
                        </EkoFormInputText>
                        {this.state.formData.deliveryAddress &&
                        this.state.formData.deliveryAddress.is_abroad ? (
                            ""
                        ) : (
                            <span>
                          <EkoFormSelect
                              {...deliveryAddressProps}
                              searchable={true}
                              id="country_id"
                              clearable={true}
                              optionValue="name"
                              defaultText="Seçiniz"
                              options={this.state.country}
                              value={typeof this.state.formData.deliveryAddress === 'undefined' || typeof this.state.formData.deliveryAddress.country_id === 'undefined' ? 239 : this.state.formData.deliveryAddress.country_id}
                              label="Ülke"
                          />
                          <div style={{"display":typeof this.state.formData.deliveryAddress === 'undefined' || typeof this.state.formData.deliveryAddress.country_id === 'undefined' || this.state.formData.deliveryAddress.country_id===239?"":"none"}}>
                          <EkoFormSelect
                              {...deliveryAddressProps}
                              searchable={true}
                              id="city_id" //formData.address.city_id
                              clearable={true}
                              optionValue="name"
                              tooltipId="deliveryCity"
                              defaultText="Seçiniz"
                              options={cityDelivery}
                              label="İl"
                              readOnly={isDeliveryAddressReadOnly}
                          />
                          <EkoFormSelect
                              {...deliveryAddressProps}
                              searchable={true}
                              id="county_id"
                              clearable={true}
                              optionValue="name"
                              tooltipId="deliveryCounty"
                              defaultText="Seçiniz"
                              options={countiesDelivery}
                              label="İlçe"
                              readOnly={isDeliveryAddressReadOnly}
                          />
                          </div>
                          <div style={{"display":typeof this.state.formData.deliveryAddress !== 'undefined' && typeof this.state.formData.deliveryAddress.country_id !== 'undefined' && this.state.formData.deliveryAddress.country_id!==239?"":"none"}}>
                           <EkoFormInputText
                               {...deliveryAddressProps}
                               type="text"
                               label="İl / Eyalet"
                               id="city_name"
                           />
                            <EkoFormInputText
                                {...deliveryAddressProps}
                                type="text"
                                label="İlçe / Şehir"
                                id="county_name"
                            />
                          </div>
                        </span>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="customer">
                        <EkoFormSelect
                            {...customerProps}
                            searchable={false}
                            id="due_date_calculation_type"
                            clearable={true}
                            defaultText="Seçiniz"
                            options={
                              this.props.viewOptions?.customers
                                  ?.due_date_calculation_type
                            }
                            label="Vade Hesaplama Metodu"
                        />
                        <EkoFormInputText
                            {...customerProps}
                            type="number"
                            label="Vade Günü"
                            id="due_days"
                        />
                        <EkoFormInputText
                            {...customerProps}
                            type="text"
                            label="IBAN"
                            id="iban"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact">
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Ad"
                            tooltipId="contactFirstname"
                            id="firstname"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Soyad"
                            tooltipId="contactLastname"
                            id="lastname"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            label="Telefon"
                            id="phone"
                            mask="(999) 999 99 9999999"
                            maskChar=""
                            type="mask"
                        />
                        <EkoFormInputText
                            {...contactProps}
                            type="text"
                            label="Email"
                            id="email"
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </div>
            </div>
            <Modal.Footer>


              <div>
                <div className="col-md-12">
                  <EkoFormButton
                      label="Kaydet"
                      faClass="fa fa-plus"
                      isLoading={this.state.saving}
                      onClick={this.onSave.bind(this)}
                  />
                  <EkoFormButton
                      label="İptal"
                      className="btn"
                      onClick={this.onCancel.bind(this)}
                  />
                </div>
              </div>
              <div style={{"backgroundColor":"#efefef",height:"1px","marginTop":"50px","marginBottom":"10px"}}></div>
              <div style={{"textAlign": "left"}}>
                <a onClick={this.openNewTagModal}>Etiket Ekle</a>
              </div>
            </Modal.Footer>
          </EkoForm>
        </EkoModal>
        <NewTagDocumentModal
            newTagRows={this.state.newTagRows}
            saveNewTag={this.saveNewTag}
            showNewTagModal={this.state.showNewTagModal}
            closeNewTagModal={this.closeNewTagModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    taxOffices: state.data.taxOffices,
    cities: state.data.cities,
    counties: state.data.counties,
    currencies: state.data.currencies,
    viewOptions: state.data.viewOptions,
  };
}

export default connect(mapStateToProps, actions)(AddCustomer);
