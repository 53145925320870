import React from 'react';
import SalaryPreview from './SalaryPreview';
import * as actions from '../../actions'
import {connect} from 'react-redux';

class SalaryPreviewPage extends React.Component {


    getTableData(year, month) {

        return [
            [
                {
                    title: 'Ünvanı',
                    width: '30%',
                },
                {
                    name: 'company_name',
                    width: '20%',
                },
                {
                    title: 'Vergi Dairesi/Vergi No',
                    width: '30%',
                },

                {
                    name: 'tax_office_name',
                    width: '20%', colSpan: 2

                }
            ],
            [
                {
                    title: 'Adres',
                    width: '30%',
                },
                {
                    name: 'address',
                    width: '20%',
                },
                {
                    title: 'Sgk İşyeri No',
                    width: '30%',
                },
                {
                    name: 'sgk_code',
                    width: '20%', colSpan: 2
                },
            ],
            [
                {
                    title: 'Personel Bilgileri',
                    centered: true,
                    colSpan: 5
                }
            ],
            [
                {
                    title: 'Adı Soyadı',
                    width: '30%',
                },
                {
                    name: 'employee_name',
                    width: '20%',
                },
                {
                    title: 'İşe başlama tarihi',
                    width: '30%',

                },
                {
                    name: 'employment_date',
                    width: '20%', colSpan: 2
                },
            ],
            [
                {
                    title: 'Ünvanı',
                    width: '30%',
                },
                {
                    name: 'unvan',
                    width: '20%',
                },
                {
                    title: 'İşten Çıkış Tarihi',
                    width: '30%',

                },
                {
                    name: 'termination_date',
                    width: '20%', colSpan: 2
                },
            ],
            [
                {
                    title: 'Sgk Statüsü',
                    width: '30%',

                },
                {
                    name: 'sgk_statüs',
                    width: '20%',
                },
                {
                    title: 'Sgk Sicil No',
                    width: '30%',

                },
                {
                    name: 'sgk_sicil_no',
                    width: '20%', colSpan: 2
                },
            ],
            [
                {
                    title: 'Kanun No',
                    width: '30%',

                },
                {
                    name: 'kanun_no',
                    width: '20%',
                },
                {
                    title: 'Sakatlık Derecesi',
                    width: '30%',

                },
                {
                    name: 'sgk_sicil_no',
                    width: '20%', colSpan: 2
                },
            ],
            [
                {
                    title: 'Net Ücreti TL',
                    width: '30%',

                },
                {
                    name: 'net_ucret',
                    width: '20%',

                },
                {
                    title: 'TC KIMLIK NO',
                    width: '30%',

                },
                {
                    name: 'identity_number',
                    width: '20%', colSpan: 2
                },
            ],

            [

                {
                    title: ' HAKEDİŞE İLİŞKİN AÇIKLAMA ',
                    centered: true,
                    width: '30%',
                },
                {
                    title: ' GÜN ',
                    width: '20%'
                },
                {
                    title: ' KESİNTİYE İLİŞKİN AÇIKLAMA ',
                    width: '30%',

                },
                {
                    title: 'TUTARI',
                    width: '20%',
                    colSpan: 2,

                },
            ],

            [
                {
                    title: 'Normal Çalışma',
                    width: '30%',

                },
                {
                    name: 'days_working',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' SGK Matrahı',
                    width: '30%',

                },
                {
                    name: 'sgk_income',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Hafta Tatili',
                    width: '30%'
                },
                {
                    name: 'days_off',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' SGK İşçi Kesintisi',
                    width: '30%',

                },
                {
                    name: 'sgk_employee',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Genel Tatil',
                    width: '30%'
                },
                {
                    name: 'days_holiday',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' İşsizlik Sigortası İşçi Kesintisi',
                    width: '30%',

                },
                {
                    name: 'unemployment_employee',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Ücretli İzin',
                    width: '30%'
                },
                {
                    name: 'vacation_paid',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                }

            ],

            [
                {
                    title: 'Yıllık İzin',
                    width: '30%'
                },
                {
                    name: 'vacation_yearly',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Özel İndirim',
                    width: '30%',

                },
                {
                    name: 'income_tax_scale_subvention',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'İstiharat',
                    width: '30%'
                },
                {
                    name: 'vacation_sihhi',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Bu ayki Gelir Vergisi Matrahı ',
                    width: '30%',

                },
                {
                    name: 'income_tax_basis',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Ücretsiz İzin',
                    width: '30%'
                },
                {
                    name: 'vacation_unpaid',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Devreden Gelir Vergisi Matrahı ',
                    width: '30%',

                },
                {
                    name: 'income_cumulative_tax_basis',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],
            [
                {
                    title: 'Mazaretsiz',
                    width: '30%'
                },
                {
                    name: 'vacation_no_reason',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Gelir Vergisi ',
                    width: '30%',

                },
                {
                    name: 'income_tax',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Ücret Günü',
                    width: '30%'
                },
                {
                    name: 'days_paid',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

                {
                    title: ' Özel Kesinti ',
                    width: '30%',

                },
                {
                    name: 'deduction',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'Toplam Çalışma',
                    width: '30%'
                },
                {
                    name: 'days_total_working',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
            ],

            [
                {
                    title: 'MESAİ KAZANÇLAR TOPLAMI',
                    width: '30%'
                },
                {
                    name: 'gross_income',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Damga Vergisi ',
                    width: '30%',

                },
                {
                    name: 'stamp_tax',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'FAZLA MESAI(1)',
                    width: '30%'
                },
                {
                    name: 'overtime_weekday',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' YASAL KESİNTİLER TOPLAMI ',
                    width: '30%',

                },
                {
                    name: 'yasal_kesintiler',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'FAZLA MESAI(1.5)',
                    width: '30%'
                },
                {
                    name: 'overtime_sunday',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' Asgari Geçim İndirimi ',
                    width: '30%',

                },
                {
                    name: 'mlc_amount',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'FAZLA MESAI(2)',
                    width: '30%'
                },
                {
                    name: 'overtime_holiday',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                {
                    title: ' NET ÖDENECEK ',
                    width: '30%',

                },
                {
                    name: 'net_amount',
                    width: '20%', colSpan: 2,
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },

            ],

            [
                {
                    title: 'FAZLA MESAI KAZANÇLAR TOPLAMI',
                    width: '30%'
                },
                {
                    name: 'overtime_income',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
                //

                {
                    rowSpan: 2,
                    colSpan: 2,
                    width: '50%',
                    title: <div style={{textAlign: 'center'}}>
                        {year || ""} {month ? month.name : ""} Ayına ilişkin tüm hakedişimi aldım. Sözkonusu döneme
                        ilişkin hakedişime ilişkin
                        İşverenimi ibra ederim.
                        ...../..../201...
                    </div>
                    //

                }

            ],

            [
                {
                    title: 'İkramiye',
                    width: '30%'
                },
                {
                    name: 'financial_giveaway',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
            ],

            [
                {
                    title: 'SOSYAL YARDIMLAR TOPLAMI',
                    width: '30%'
                },
                {
                    name: 'sosyal_yardimlar',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0,
                },

                {
                    rowSpan: 2,
                    width: '30%',
                    title: <div style={{textAlign: 'center'}}>
                        IMZA
                    </div>
                },
                {
                    rowSpan: 2,
                    colSpan: 2,
                    width: '20%',
                }
            ],


            [
                {
                    title: 'TÜM KAZANÇLAR TOPLAMI',
                    width: '30%'
                },
                {
                    name: 'total_income',
                    width: '20%',
                    input: {
                        type: 'number'
                    },
                    defaultValue: 0
                },
            ],
        ];
    };

    render() {

        return <div>
            <SalaryPreview router={this.props.router}
                           tableData={this.getTableData.bind(this)}/>
        </div>

        // reolad
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, actions)(SalaryPreviewPage);
