import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";
import Api from '../../util/Api'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";


class EmployeeWageSheet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            records: [],
            formData: {},
            page_loading: true,
            wageDetails: {}
        }

    }
    componentWillMount() {
        this.fetchRecordedData();
    }
    componentDidMount() {

    }
    fetchRecordedData() {
        var self = this;
        this.setState({page_loading: true, showInsertModal: false, formData: false, records: []});
        Api.get("/company/getEmployeeWageSheets/"+self.props.params.id, (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({records: getData, page_loading: false });
        });
    }


    render() {
        return (<>
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase">
                            BORDRO LİSTESİ
                        </span>
                    </div>
                    <div style={{float: 'right'}}>
                        <button onClick={() => { this.props.router.push('/dashboard/employee') } } className='btn btn-sm blue'>Çalışan Listesi</button>
                    </div>
                </div>
                <div className="portlet-body">
                    {this.state.page_loading == true ? <EkoSpinner /> :
                        <>

                            <table className='table table-striped'>
                                <thead>
                                <tr>
                                    <th>Adı Soyadı</th>
                                    <th>Dönem</th>
                                    <th>Toplam Kazanç</th>
                                    <th>Net Tutar</th>
                                    <th width="100">İşlem</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.records.map((record) =>
                                        <tr key={record.wage_sheets_id} id={'row_' + record.id}>
                                            <td>{record.name_surname}</td>
                                            <td>{record.month}/{record.year}</td>
                                            <td>{record.total_income} ₺</td>
                                            <td>{record.net_pay} ₺</td>
                                            <td>
                                                <button onClick={() => { this.props.router.push('/dashboard/wagesheet-details/'+record.id) } } className='btn btn-sm btn-primary'><i className='fa fa-eye'></i></button>
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    this.state.records.length == 0 ? <><tr key="0"><td colSpan={5} style={{textAlign: "center"}}>Kayıt bulunamadı</td></tr></> : ""
                                }
                                </tbody>
                            </table>

                        </>
                    }
                </div>
            </div>

        </>)
    }
}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(EmployeeWageSheet);
