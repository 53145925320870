import React, { useEffect, useState } from "react";
import { EkoFormButton } from "../elements/EkoForm";
import Resizer from "react-image-file-resizer";
import EkoImageUpload from "../elements/form/EkoImageUpload";
import { connect, useDispatch, useSelector } from "react-redux";
import { setAddDocument } from "../../actions";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";

function UploadXMLButton(props) {
  const { style, callBack, paramType } = props;
  const [loading, setLoading] = useState(false)

  const fileUploadHandler = (image) => {
    if (image) {
      setLoading(true);
      const fd = new FormData();
      fd.append("xmlFile", image);
      fd.append("type", paramType);
      if (image.size < 3000000) {
        Api.post("/eInvoice/uploadXmlToData", fd, (response, error) => {
          if (response) {
            setLoading(false);
            if (response.status === "fail") {
              toastr.error(response.message);
            } else {
              callBack && callBack(response.data);
              toastr.success("Yükleme Başarılı");
            }
          } else {
            if (error.status === "fail") {
              toastr.error(error.message);
            }
            setLoading(false);
          }
        });
      } else {
        toastr.error("Resim boyutu fazla büyük");
      }
      
    } else {
      setLoading(false);
      setAddDocument({ uri: null, type: null });
    }
  };

  return (
    <>
      <EkoImageUpload
        closeSmallImage
        onlyAccepts={["text/xml"]}
        selectedImage={null}
        style={style}
        loading={loading}
        onChange={(image) => fileUploadHandler(image)}
        maxSize={3000000}
        buttonText="XML Yükle"
        width={180}
        height={180}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(UploadXMLButton);
