import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Api from "../../util/Api";
class SalesShipmentResponseEnvelopeModal extends Component {
    get row() {
        return [
            {
                label: "",
                placeholder: "Ürün",
                type: "text",
                colMd: 12,
                id: "ItemName",
                disabled: true,
            },
            {
                label: "",
                placeholder: "Miktar",
                type: "money",
                colMd: 12,
                id: "Quantity",
                precision: 4,
                disabled: true,
                vertical: true
            },
            {
                label: "",
                placeholder: "Kabul Miktar",
                type: "money",
                colMd: 12,
                id: "ReceivedQuantity",
                precision: 4,
                vertical: true
            },
            {
                label: "",
                placeholder: "Red Miktar",
                type: "money",
                colMd: 12,
                id: "RejectedQuantity",
                precision: 4,
                vertical: true
            },
            {
                label: "",
                placeholder: "Red Sebebi",
                type: "text",
                colMd: 12,
                id: "RejectReason"
            },
            {
                label: "",
                placeholder: "Eksik Miktar",
                type: "money",
                colMd: 12,
                id: "ShortQuantity",
                precision: 4,
                vertical: true
            },
            {
                label: "",
                placeholder: "Fazla Miktar",
                type: "money",
                colMd: 12,
                id: "OversupplyQuantity",
                precision: 4,
                vertical: true
            },
        ];
    }

    get defaultData() {
        return {
            rows: [this.row],
            data:[],
            errors: {
                rows: []
            },
            saving: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.defaultData;
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data:this.props.responseModalRows
        });
    }
    checkValid = data => {
        return !!(data && data !== "");
    };

    onSave = () => {
        this.sendAppResp(this.props.selectedRowId,this.props.selectedRowUuid, "PartialAnswered");
    };


    closeModal = () => {
        if (this.props.closeResponseModal) {
            this.props.closeResponseModal();
        }
    };

    onChange = data => {
        /*this.setState(
            {
                data:data
            }
        );*/
    };

    onChangeRow(index, value,input) {
        var data = this.state.data;
        var row = data.rows[index];
        if(input==='ReceivedQuantity') {
            row.RejectedQuantity = Util.convertMoneyToDecimal(row.Quantity) - Util.convertMoneyToDecimal(row.ReceivedQuantity);
            if(row.RejectedQuantity===0){ row.RejectedQuantity="0,00"}
        }
        this.setState(
            {
                data:data
            }
        );
    }

    sendAppResp(id,uuid, status) {
        const integrator = JSON.parse(localStorage.getItem("integrator"));
        const self = this;
        toastr.confirm(`Faturayı yanıtlamak istediğinize emin misiniz?`, {
            onOk: () => {
                self.setState({ saving: true });
                if (integrator === 3) {
                    Api.post(
                        "/nesv2/send-app-response-irsaliye",
                        {
                            id: id,
                            uuid: uuid,
                            responseCode: status,
                            row:this.state.data.rows
                        },
                        (response, err) => {
                            if (response) {
                                if(response.status=='fail'){
                                    toastr.error(response.message);
                                }else{
                                    toastr.success(response.message);
                                }
                                self.props.closeResponseModal();
                            } else toastr.error("Hata", err.message);
                            self.setState({ saving: false });
                        }
                    );
                }else if (integrator === 1) {
                    Api.post(
                        "/send-app-response-shipment",
                        {
                            id: id,
                            uuid: uuid,
                            responseCode: status,
                            row:this.state.data.rows
                        },
                        (response, err) => {
                            if (response) {
                                if(response.status=='fail'){
                                    toastr.error(response.message);
                                }else{
                                    toastr.success(response.message);
                                }
                                self.props.closeResponseModal();
                            } else toastr.error("Hata", err.message);
                            self.setState({ saving: false });
                        }
                    );
                }else{
                    toastr.error("Hata", "Sistemsel Bir Sorun Oluştu. KOD:IRS01");
                }
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });
    }

    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showModal}
                title={"İrsaliye Yanıtla"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <EkoInputTable
                    onSave={this.onSave}
                    deletable={false}
                    hideAddButton={false}
                    className={"modalTableFix"}
                    onCancel={this.closeModal}
                    onChange={this.onChange}
                    onChangeRow={this.onChangeRow.bind(this)}
                    errors={this.state.errors}
                    formData={this.props.responseModalRows}
                    data={{
                        headers: [
                            { label: "Ürün" },
                            { label: "Miktar" },
                            { label: "Kabul Miktar" },
                            { label: "Red Miktar" },
                            { label: "Red Sebebi" },
                            { label: "Eksik Miktar" },
                            { label: "Fazla Miktar" },
                        ],
                        rows: this.props.responseModalRows.rows.map(() => this.row)
                    }}
                />
            </EkoModal>
        );
    }
}

export default SalesShipmentResponseEnvelopeModal;
