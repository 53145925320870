import React, {Component} from 'react'
import {
    EkoFormButton,
    EkoFormCheckbox,
    EkoFormDate,
    EkoFormInputText,
    EkoFormSelect
} from '../../components/elements/EkoForm'
import {toastr} from 'react-redux-toastr'
import {browserHistory, Link} from 'react-router'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Layout from '../../components/layouts/Auth'
import Api from '../../util/Api'
import EkoModal from "../../components/elements/EkoModal";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {register: {}},
            saving: false,
            errors: {},
            formFields: {},
            disabledSignup: false,
            agreeChecked: false,
            membershipType: 0,
            selectedMembershipType: 0,
            selectedEmail: null,
            openWarningModal: false,
        }
    }


    componentWillMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const membership_type = urlParams.get('type');
        this.setState({membershipType: membership_type});
        if (this.props.authenticated) browserHistory.push('/dashboard');
        this.props.fetchViewOptions(false);
        let theUrl = window.location;
        var cleanParams = new URLSearchParams((this.props.location.search).replace("?", ""));
        if (cleanParams.get("type") == "9"){
            this.setState({selectedMembershipType: 9});
        }
        if (cleanParams.get("email") != ""){
            this.setState({selectedEmail: cleanParams.get("email")});
            let user = this.state.formData.register;
            user.email = cleanParams.get("email");
        }
    }

    componentWillReceiveProps(nextProps) {
        let errors = nextProps.errorMessage || {};
        let saving = false;
        if (nextProps.errorMessage && !nextProps.errorMessage.message) errors = {login: nextProps.errorMessage};
        if (nextProps.errorMessage && nextProps.errorMessage.message) toastr.error('Hata', nextProps.errorMessage.message);
        this.setState({errors: errors, saving: saving});
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];

        if(field=='company_type' && value==9){
            this.openWarningModal();
        }
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        //formData.company_type === 6 ? this.setState({disabledSignup: true}) : this.setState({disabledSignup: false})

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    openAlert = () => {
        window.open('https://itunes.apple.com/us/app/ekohesap/id1437652631?mt=8', '_blank')
    }
    alertWarning(e) {
        this.setState({agreeChecked: e.target.checked});
    }
    onSave() {
        const self = this;
        if (self.state.agreeChecked === false){
            toastr.error("Bilgilendirme", "Kayıt olabilmek için şartları kabul etmelisiniz.", { position: "top-right" });
            return false;
        }
        toastr.removeByType('error')
        toastr.removeByType('success')
        self.setState({saving: true});

        let user = self.state.formData.register;
        user.is_mobile = (this.detectMob()) ? 1 : 0;

        let errors = self.state.errors;
        Api.post('/user', user, (response, postErrors) => {
            if (postErrors) {
                errors.register = postErrors;
                self.setState({saving: false, errors: errors});
            } else {
                window.fbq('track', 'Lead');
                self.setState({saving: false, errors: {}});
                self.props.loginUser(user, '/dashboard');
                // if (this.detectMob()) {
                //     toastr.success('Başarılı', 'E-posta adresinize gönderdiğimiz mail ile üyeliğinizi tamamlayabilirsiniz.', {position: 'top-left'});
                //     browserHistory.push('/login')
                // }
                // else {
                //     browserHistory.push({
                //         pathname: '/login',
                //         search: '?register=success'
                //     });
                // }
            }
        })
    }

    detectMob() {
        return navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
    }
    closeWarningModal() {
        this.setState({ openWarningModal: false });
    }
    openWarningModal = () => {
        this.setState({ openWarningModal: true });
        // open Swal
        swal({
            title: "DİKKAT",
            text: "Serbest Muhasebeci Mali Müşavir Değilseniz Lütfen Diğer Tiplerden Birini Seçiniz. Bu Şirket Tipi Sadece Serbest Muhasebeci Mali Müşavirler İçindir.",
            showConfirmButton: true,
            confirmButtonText: "Tamam",
            confirmButtonColor: "darkgray",
            showCancelButton: false,
            cancelButtonText: "İptal",
            closeOnConfirm: true,
            closeOnCancel: true,
            html: true,
            type: "info",
            customClass: 'sweet-alert',
        }, function(isConfirm) {
            if (isConfirm) {

            }
        });
    };
    render() {
        let companyTypes = this.props.viewOptions ? this.props.viewOptions.companies.company_type : [];
        const registerProps = {
            onChange: this.setFieldChange.bind(this, "register"),
            onMount: this.setFieldMount.bind(this, "register"),
            formData: this.state.formData.register,
            errors: this.state.errors.register
        }
        return (

            <Layout type="register" containerClass=" container-register">
                <div className="rcenter">
                    <h3 className="form-title blue">14 Gün Ücretsiz Dene</h3>
                    <p>Tüm özellikleri kullanabileceksiniz!</p><br />
                </div>
                <div className="input-icon">
                    <i className="fa fa-envelope"/>
                    {this.state.selectedEmail == null && (
                        <EkoFormInputText {...registerProps}
                                          id="email"
                                          style={{paddingLeft: "33px"}}
                                          label=""
                                          placeHolder="E-posta"
                                          isVertical={true}/>
                    )}
                    {this.state.selectedEmail != null && (
                        <EkoFormInputText {...registerProps}
                                          id="email"
                                          style={{paddingLeft: "33px"}}
                                          label=""
                                          placeHolder="E-posta"
                                          value={this.state.selectedEmail}
                                          disabled={true}
                                          isVertical={true}/>
                    )}
                </div>
                <div className="input-icon">
                    <i className="fa fa-lock"/>
                    <EkoFormInputText {...registerProps}
                                      id="password"
                                      style={{paddingLeft: "33px"}}
                                      label=""
                                      type="password"
                                      placeHolder="Şifre"
                                      isVertical={true}/>
                </div>
                {this.state.selectedMembershipType == 9 && (
                    <EkoFormSelect {...registerProps}
                                   id="company_type"
                                   isVertical={true}
                                   defaultText="ŞİRKET TİPİ SEÇ"
                                   options={[{id: 9, name: "Serbest Muhasebeci Mali Müşavir"}]}
                                   label=""
                    />
                )}
                {this.state.selectedMembershipType == 0 && (
                    <EkoFormSelect {...registerProps}
                                   id="company_type"
                                   isVertical={true}
                                   defaultText="ŞİRKET TİPİ SEÇ"
                                   options={companyTypes}
                                   label=""
                    />
                )}
                <div>
                    <label htmlFor="agree" className="mt-checkbox">
                        <input id="agree" type="checkbox" value="1" onChange={this.alertWarning.bind(this)}/>
                        <div>
                            <a href='https://www.ekohesap.com/kullanici-sozlesmesi' target='_blank'>Kullanıcı
                                sözleşmesi</a>, <a href='https://www.ekohesap.com/kvkk/' target='_blank'>KVKK</a> ve <a
                            href='https://www.ekohesap.com/gizlilik-sozlesmesi' target='_blank'>Gizlilik sözleşmesi</a>'ni
                            okudum, kabul ediyorum.
                        </div>
                        <span></span>
                    </label>
                </div>
                <br/>
                <div className="form-actions">
                    <EkoFormButton label={'Üye Ol'}
                                   disabled={this.state.disabledSignup}
                                   className={'btn btn-default blue col-md-12'}
                                   faClass="fa fa-plus"
                                   isLoading={this.state.saving}
                                   onClick={this.onSave.bind(this)}
                    />
                </div>
                <br/>
                <br/>
                <br/>
                <div className="text-center"><p>
                    <Link to="/login" id="register-btn"> Hesabınız varsa lütfen tıklayınız</Link>
                </p>
                </div>

            </Layout>
        )
    }

}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        errorMessage: state.auth.error,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(Signup);