import React from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoForm,
  EkoFormButton,
  EkoFormCheckbox,
  EkoFormInputText,
  EkoFormRadioButton,
  EkoFormSelect,
} from "../../components/elements/EkoForm";
import { Modal, Tab, Row, Nav, NavItem } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import UtilHelper from "../../util/UtilHelper";
import AddChangingModal from "../../components/elements/AddChangingModal";
import Util from "../../util/Util";
import NewTagDocumentModal from "../Company/newTagDocument";
import AddOtherTax from "../../components/forms/AddOtherTax";
class AddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        item: {},
      },
      showModal: false,
      saving: false,
      errors: {},
      formFields: {},
      changingData: {},
      radioButtons: [
        {
          label: "Ürün",
          id: "type",
          radio_id: "urun",
          value: 1,
        },
        {
          label: "Hizmet",
          id: "type",
          radio_id: "hizmet",
          value: 2,
        },
      ],
      moneyField: 0,
      moneySalesField: 0,
      activeTab: "main",
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:20,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      taxForm: AddOtherTax.getDefaultForm(),
      deleteTaxIds: [],
    };
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = (formData) => {
    formData = formData || this.state.formData;
    if (formData.item.id) {
      Api.get("/company/newTagRecordDocumentList/20/" + formData.item.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }else{
          this.resetNewTagModalList();
        }
      });
    }else{
      this.resetNewTagModalList();
    }
  }
  resetNewTagModalList = () => {
    this.setState(prevState => ({
      newTagRows: {
        ...prevState.newTagRows,
        rows: [
          { id:null,new_tag_type_id:20,new_tag_id: "",record_id:null, rate: "%100,00" }
        ],
      },
    }));
  }
  fetchCode = () => {
    Api.get("/item/code", (res) => {
      if (res && res.code) {
        this.setFieldChange("item", "item_code", res.code);
      }
    });
  };
  fetchItemTaxLines = (id) => {
    Api.get("/item/getItemTaxLines/"+id, (res) => {
      if (res && res.rows.length>0) {
        this.setState({
          taxForm: res,
        });
      }else{
        this.setState({
          taxForm: AddOtherTax.getDefaultForm(),
        });
      }
    });
  };

  componentDidMount() {
    this.fetchCode();
  }

  defaultFormData() {
    return {
      item: {
        type: 1,
        purchase_unit_price: 0,
        sales_unit_price: 0,
        purchase_vat_rate_type_id: 6,
        sales_vat_rate_type_id: 6,
        opening_quantity: 0,
        currency_purchase: "TRY",
        currency_sales: "TRY",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData
      ? nextProps.formData
      : this.defaultFormData();
    this.getNewTagModalList(formData);
    // eğer modal yeniden açılıyorsa verileri yeniden al
    if (nextProps.showModal !== this.props.showModal && !formData.item.id) {
      this.fetchCode();
    }
    if (formData.item.id) {
      this.fetchItemTaxLines(formData.item.id);
    }

    let radio = this.state.radioButtons[0];
    let radio2 = this.state.radioButtons[1];
    if (formData.item.type === 1) {
      radio["checked"] = true;
      radio2["checked"] = false;
    } else if (formData.item.type === 2) {
      radio["checked"] = false;
      radio2["checked"] = true;
    } else {
      formData.item.type = 1;
      radio["checked"] = true;
      radio2["checked"] = false;
    }

    let setFormData = formData.item.id
      ? {
          ...formData.item,
          purchase_unit_price_2: formData.item.purchase_unit_price
            ? this.getValue(
                "addVat",
                formData.item.purchase_unit_price,
                formData.item.purchase_vat_rate_type_id
              )
            : undefined,
            sales_unit_price_2: formData.item.sales_unit_price
            ? this.getValue(
                "addVat",
                formData.item.sales_unit_price,
                formData.item.sales_vat_rate_type_id
              )
            : undefined,
        }
      : { ...this.defaultFormData().item };

    this.setState({
      formData: {
        item: setFormData,
      },
      errors: {},
      showModal: nextProps.showModal,
    });
  }

  onFalse() {
    switch (this.id) {
      case "is_purchase":
        this.formData.purchase_description = "";
        this.formData.purchase_unit_price = "0,00";
        // this.formData.purchase_vat_rate_type_id = null
        this.formData.is_stock = 0;
        break;
      case "is_sales":
        this.formData.sales_description = "";
        this.formData.sales_unit_price = "0,00";
        //  this.formData.sales_vat_rate_type_id = null
        break;
      case "is_stock":
        this.formData.quantity = 0;
        this.formData.opening_quantity = 0;
        break;
      default:
        break;
    }
  }

  onSave() {
    const formData = this.state.formData;
    let self = this;
    let func = "post";
    let url = "/item";
    if (formData.item && formData.item.id) {
      func = "put";
      url = "/item/" + formData.item.id;

      formData.item.purchase_unit_price =
        UtilHelper.money.convertMoneyToDecimal8(
          formData.item.purchase_unit_price
        );
      formData.item.purchase_unit_price_2 =
        UtilHelper.money.convertMoneyToDecimal8(
          formData.item.purchase_unit_price_2
        );
      formData.item.sales_unit_price_2 =
        UtilHelper.money.convertMoneyToDecimal8(
          formData.item.sales_unit_price_2
        );
      formData.item.quantity = UtilHelper.money.convertMoneyToDecimal4(
        formData.item.quantity
      );
      formData.item.sales_unit_price = UtilHelper.money.convertMoneyToDecimal8(
        formData.item.sales_unit_price
      );
    } else {
      formData.item.purchase_unit_price = formData.item.purchase_unit_price
        ? UtilHelper.money.convertMoneyToDecimal8(
            formData.item.purchase_unit_price
          )
        : "";
      formData.item.quantity = formData.item.quantity
        ? UtilHelper.money.convertMoneyToDecimal4(formData.item.quantity)
        : "";
      formData.item.sales_unit_price = formData.item.sales_unit_price
        ? UtilHelper.money.convertMoneyToDecimal8(
            formData.item.sales_unit_price
          )
        : "";
    }
    if (formData.item.is_stock) {
      formData.item.is_stock = 1;
    } else {
      formData.item.is_stock = 0;
      formData.item.quantity = 0;
    }

    if (formData.item.purchase_unit_price === "") {
      formData.item.purchase_unit_price = 0;
    }

    if (formData.item.quantity === "") {
      formData.item.quantity = 0;
    }

    if (formData.item.sales_unit_price === "") {
      formData.item.sales_unit_price = 0;
    }

    // KDV sıfırlanıyordu kaldırdım

    /*         if (!formData.item.is_purchase)
      delete formData.item.purchase_vat_rate_type_id;
    if (!formData.item.is_sales) delete formData.item.sales_vat_rate_type_id; */

    formData.item.opening_quantity = formData.item.quantity;
    formData.item.newTagRows=this.state.newTagRows;

    self.setState({ saving: true });

    Api[func](url, formData.item, (response, postErrors) => {
      if (postErrors) {
        formData.item.purchase_unit_price = formData.item.purchase_unit_price
          ? UtilHelper.money.convertDecimalToMoney8(
              formData.item.purchase_unit_price
            )
          : "";
        formData.item.quantity = formData.item.quantity
          ? UtilHelper.money.convertDecimalToMoney4(formData.item.quantity)
          : "";
        formData.item.sales_unit_price = formData.item.sales_unit_price
          ? UtilHelper.money.convertDecimalToMoney8(
              formData.item.sales_unit_price
            )
          : "";
        self.setState({
          saving: false,
          errors: postErrors,
        });
      } else {
        if (!!response.warnings) {
          response.warnings.forEach(warning => {
            toastr.warning(warning);
          });
        }
        toastr.success("Başarılı", "Başarıyla Kaydedildi");
        self.setState({
          saving: false,
          formData: {
            item: { ...self.defaultFormData() },
          },
        });
        self.props.onSave();
      }
    });
  }

  onCancel() {
    this.resetNewTagModalList();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  getVatRatio(id) {
    console.log("id", id);
    if (id === 1) return 0;
    if (id === 2) return 0.01;
    if (id === 3) return 0.08;
    if (id === 4) return 0.18;
    if (id === 5) return 0.1;
    if (id === 6) return 0.2;
  }

  getValue(fieldType, input, vatId) {
    let vatRatio = this.getVatRatio(vatId);
    let valueWithVat = (
      UtilHelper.money.convertMoneyToDecimal4(input) *
      (1 + vatRatio)
    ).toFixed(4);

    let valueWithoutVat = (
      UtilHelper.money.convertMoneyToDecimal4(input) /
      (1 + vatRatio)
    ).toFixed(4);

    let value = fieldType === "addVat" ? valueWithVat : valueWithoutVat;

    return UtilHelper.money.convertDecimalToMoney4(value);
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];

    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    if (field === "is_stock" && value) {
      this.setFieldChange(type, "is_purchase", value);
      this.setFieldChange(type, "is_sales", value);
    }

    if (field === "is_stock" && !value) {
      this.setFieldChange(type, "is_purchase", value);
      this.setFieldChange(type, "is_sales", value);
    }

    if (
      field === "purchase_unit_price" ||
      field === "purchase_vat_rate_type_id"
    ) {
      if (this.state.moneyField === 1) {
        this.setFieldChange(
          type,
          "purchase_unit_price_2",
          this.getValue(
            "addVat",
            this.state.formData.item.purchase_unit_price,
            this.state.formData.item.purchase_vat_rate_type_id
          )
        );
      }
    }

    if (
      field === "purchase_unit_price_2" ||
      field === "purchase_vat_rate_type_id"
    ) {
      if (this.state.moneyField === 2) {
        this.setFieldChange(
          type,
          "purchase_unit_price",
          this.getValue(
            "subVat",
            this.state.formData.item.purchase_unit_price_2,
            this.state.formData.item.purchase_vat_rate_type_id
          )
        );
      }
    }

    if (field === "sales_unit_price" || field === "sales_vat_rate_type_id") {
      if (this.state.moneySalesField === 1) {
        this.setFieldChange(
          type,
          "sales_unit_price_2",
          this.getValue(
            "addVat",
            this.state.formData.item.sales_unit_price,
            this.state.formData.item.sales_vat_rate_type_id
          )
        );
      }
    }

    if (field === "sales_unit_price_2" || field === "sales_vat_rate_type_id") {
      if (this.state.moneySalesField === 2) {
        this.setFieldChange(
          type,
          "sales_unit_price",
          this.getValue(
            "subVat",
            this.state.formData.item.sales_unit_price_2,
            this.state.formData.item.sales_vat_rate_type_id
          )
        );
      }
    }

    let bigForm = { ...this.state.formData, [type]: { ...formData } };

    this.setState({ formData: bigForm, errors: { ...errors } });
  }
  handleTabSelect(key) {
    this.setState({ activeTab: key });
  }
  onTaxRowChange() {
    ///
  }

  onTaxChange() {
    //
  }
  onTaxFormSave(taxForm) {
    if(taxForm){
      Api.post(
          "/item/itemTaxLines",
          {
            taxForm: taxForm,
            item_id: this.state.formData.item.id
          },
          (response, errors) => {
            toastr.success("Başarılı", "Başarıyla Kaydedildi");
          }
      );
    }
  }
  render() {
    const activeTab = this.state.activeTab;
    const itemProps = {
      onChange: this.setFieldChange.bind(this, "item"),
      onMount: this.setFieldMount.bind(this, "item"),
      formData: this.state.formData.item,
      errors: this.state.errors,
      buttons: this.state.radioButtons,
    };

    const item = this.state.formData.item;
    const units = this.props.units;
    const deliveryTypes = this.props.deliveryTypes;
    const transportTypes = this.props.transportTypes;
    const packageTypes = this.props.packageTypes;
    const vatRateTypes = this.props.vatRateTypes;

    const product_accounting_type = [
      { id: 0, name: "Ticari Mal" },
      { id: 1, name: "Mamül" },
      { id: 2, name: "Yarı Mamül" },
      { id: 3, name: "Hammadde" },
    ];

    const isUpdates = [
      { id: 0, name: "Güncellenmesin" },
      { id: 1, name: "Güncellensin" },
    ];

    return (
      <div>
        <EkoModal
            showModal={this.state.showModal}
            title="Ürün/Hizmet Ekle"
            onHide={() => {
              this.setState({
                showModal: false,
                formData: this.defaultFormData(),
                errors: {},
              });
              this.onCancel();
            }}
            spinner={this.state.saving}
        >
          <Modal.Header>
            <EkoFormRadioButton {...itemProps} onFalse={this.onFalse} />
          </Modal.Header>
          <Modal.Body>
            <div className="portlet-body" style={{ marginTop: 20 }}>
              <Tab.Container
                  id="tab"
                  onSelect={this.handleTabSelect}
                  activeKey={activeTab}
              >
                <Row className="clearfix">
                  <Nav bsStyle="tabs">
                    <NavItem
                        eventKey="main"
                        onClick={() => this.handleTabSelect("main")}
                    >
                      Ürün Bilgileri
                    </NavItem>
                    {Util.companyModules(2) === true && (
                        <NavItem
                            disabled={
                                (item && item.type === 2) || item?.type === null
                            }
                            eventKey="changing"
                            onClick={() => this.handleTabSelect("changing")}
                        >
                          Ürün Varyantları
                        </NavItem>
                    )}
                    <NavItem
                        disabled={(item && item.type === 2) || item?.type === null}
                        eventKey="exported"
                        onClick={() => this.handleTabSelect("exported")}
                    >
                      İhracat Bilgileri
                    </NavItem>
                    <NavItem
                        disabled={(item && item.type === 2) || item?.type === null}
                        eventKey="vat_tab"
                        onClick={() => this.handleTabSelect("vat_tab")}
                    >
                      Vergi Bilgileri
                    </NavItem>
                  </Nav>
                  <Tab.Content animation>
                    <Tab.Pane eventKey="main">
                      <EkoForm formId={"add_item_modal"}>
                        <div>
                          <div className="col-md-6">
                            <EkoFormInputText
                                {...itemProps}
                                label="Ürün/Hizmet Adı"
                                id="item_name"
                                isVertical
                                type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <EkoFormInputText
                                {...itemProps}
                                label="Ürün/Hizmet Kodu"
                                id="item_code"
                                isVertical
                                type="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <EkoFormSelect
                                {...itemProps}
                                searchable={true}
                                id="unit_id"
                                isVertical
                                defaultText="SEÇİNİZ"
                                optionId="id"
                                options={units}
                                optionValue="description"
                                label="Birim"
                            />
                          </div>
                          <div
                              className="col-md-6"
                              style={{
                                display:
                                    (item && item.type === 2) ||
                                    (item && item.type === null)
                                        ? "none"
                                        : "",
                              }}
                          >
                            <EkoFormSelect
                                {...itemProps}
                                searchable={true}
                                id="product_accounting_type"
                                isVertical
                                defaultText="Ticari Mal"
                                optionId="id"
                                options={product_accounting_type}
                                optionValue="name"
                                label="Ürün Tipi"
                            />
                          </div>
                          <div
                              className={
                                (item && item.type === 2) ||
                                (item && item.type === null)
                                    ? "mrhide"
                                    : ""
                              }
                              id="stokgenel"
                          >
                            <div className="col-md-12">
                              <hr className="hre" />
                              <EkoFormCheckbox
                                  {...itemProps}
                                  id="is_stock"
                                  onFalse={this.onFalse}
                                  label="Bu ürün için stok takibi yapacağım"
                              />
                            </div>
                            <div
                                className={
                                  this.state.formData.item &&
                                  !this.state.formData.item.is_stock
                                      ? "mrhide"
                                      : ""
                                }
                                id="divstoktakip"
                            >
                              <div className="col-md-4">
                                <EkoFormInputText
                                    {...itemProps}
                                    label={
                                      item && item.id
                                          ? "Mevcut Stok Miktarı"
                                          : "Açılış Stok Miktarı"
                                    }
                                    id="quantity"
                                    isVertical
                                    type="money"
                                    precision={5}
                                    disabled={item && item.id ? true : false}
                                />
                              </div>
                              {
                                item && item.id ?
                                    <div className="col-md-8"><br/><br /><span style={{color: "red"}}>
                                        Bu alanı güncellemek için EKLE butonundan<br />"Toplu Stok Düzenle"ye tıklayınız.
                                  </span>
                                    </div>
                                    :""}
                              <div className="clearfix" />
                            </div>
                          </div>
                          <div className>
                            <div className="col-md-12">
                              <hr className="hre" />
                              <EkoFormCheckbox
                                  {...itemProps}
                                  id="is_purchase"
                                  onFalse={this.onFalse}
                                  label={
                                    this.state.formData.item &&
                                    this.state.formData.item.type === 1
                                        ? "Bu ürünü satın alıyorum"
                                        : "Bu hizmeti satın alıyorum"
                                  }
                              />
                            </div>
                          </div>

                          <div
                              className={
                                this.state.formData.item &&
                                !this.state.formData.item.is_purchase
                                    ? "mrhide"
                                    : ""
                              }
                              id="divsatinalma"
                          >
                            <div className="">
                              <div className="row">
                                <div className="col-md-7">
                                  <EkoFormSelect
                                      {...itemProps}
                                      id="is_update_purchase_unit_price"
                                      isVertical
                                      defaultText="Güncellenmesin"
                                      optionId="id"
                                      options={isUpdates}
                                      optionValue="name"
                                      label="Son Faturadan Birim Fiyat"
                                  />
                                </div>
                                <div
                                    className={`col-md-5 ${
                                        this.state.formData.item &&
                                        !this.state.formData.item.is_purchase
                                            ? "mrhide"
                                            : ""
                                    }`}
                                >
                                  <EkoFormInputText
                                      {...itemProps}
                                      label="Fatura Açıklaması"
                                      id="purchase_description"
                                      isVertical
                                      type="text"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2">
                                  <EkoFormSelect
                                      {...itemProps}
                                      id="currency_purchase"
                                      isVertical={true}
                                      optionValue={"code"}
                                      optionId={"code"}
                                      defaultText="Seçiniz"
                                      options={this.props.currencies}
                                      label="Döviz Tipi"
                                  />
                                </div>
                                <div className="col-md-2">
                                  <EkoFormSelect
                                      {...itemProps}
                                      searchable={false}
                                      id="purchase_vat_rate_type_id"
                                      isVertical
                                      defaultText="SEÇİNİZ"
                                      optionId="id"
                                      options={vatRateTypes}
                                      optionValue="name"
                                      label="KDV Oranı"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <EkoFormInputText
                                      {...itemProps}
                                      precision={8}
                                      label="Alış Birim Fiyatı"
                                      id="purchase_unit_price"
                                      isVertical
                                      type="money"
                                      onFocus={() =>
                                          this.setState({ moneyField: 1 })
                                      }
                                  />
                                </div>
                                <div className="col-md-4">
                                  <EkoFormInputText
                                      {...itemProps}
                                      labelStyle={{ height: 54 }}
                                      labelClass="control-label control-line height-54"
                                      precision={8}
                                      label="Alış Birim Fiyatı(KDVli)"
                                      id="purchase_unit_price_2"
                                      isVertical
                                      type="money"
                                      onFocus={() =>
                                          this.setState({ moneyField: 2 })
                                      }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div
                className={
                  this.state.formData.item &&
                  !this.state.formData.item.is_purchase
                    ? "mrhide"
                    : ""
                }
              >
                <div className="col-md-12">
                  <EkoFormInputText
                    {...itemProps}
                    label="Fatura Açıklaması"
                    id="purchase_description"
                    isVertical
                    type="text"
                  />
                </div>
                <div className="clearfix" />
              </div> */}

                          <div className>
                            <div className="col-md-12">
                              <hr className="hre" />
                              <EkoFormCheckbox
                                  {...itemProps}
                                  id="is_sales"
                                  onFalse={this.onFalse}
                                  label={
                                    this.state.formData.item &&
                                    this.state.formData.item.type === 1
                                        ? "Bu ürünü satıyorum"
                                        : "Bu hizmeti satıyorum"
                                  }
                              />
                            </div>
                          </div>
                          <div
                              className={
                                this.state.formData.item &&
                                !this.state.formData.item.is_sales
                                    ? "mrhide"
                                    : ""
                              }
                              id="divsatma"bo
                          >
                            <div className="row">
                              <div className="col-md-7">
                                <EkoFormSelect
                                    {...itemProps}
                                    id="is_update_sales_unit_price"
                                    isVertical
                                    defaultText="Güncellenmesin"
                                    optionId="id"
                                    options={isUpdates}
                                    optionValue="name"
                                    label="Son Faturadan Birim Fiyat"
                                />
                              </div>
                              <div
                                  className={`col-md-5 ${
                                      this.state.formData.item &&
                                      !this.state.formData.item.is_sales
                                          ? "mrhide"
                                          : ""
                                  }`}
                              >
                                <EkoFormInputText
                                    {...itemProps}
                                    label="Faturaya Yazılacak Açıklama"
                                    id="sales_description"
                                    isVertical
                                    type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                <EkoFormSelect
                                    {...itemProps}
                                    id="currency_sales"
                                    isVertical={true}
                                    optionValue={"code"}
                                    optionId={"code"}
                                    defaultText="Seçiniz"
                                    options={this.props.currencies}
                                    label="Döviz Tipi"
                                />
                              </div>
                              <div className="col-md-2">
                                <EkoFormSelect
                                    {...itemProps}
                                    searchable={false}
                                    id="sales_vat_rate_type_id"
                                    isVertical
                                    defaultText="SEÇİNİZ"
                                    optionId="id"
                                    options={vatRateTypes}
                                    optionValue="name"
                                    label="KDV Oranı"
                                />
                              </div>
                              <div className="col-md-4">
                                <EkoFormInputText
                                    {...itemProps}
                                    label="Satış Birim Fiyatı"
                                    id="sales_unit_price"
                                    precision={8}
                                    isVertical
                                    type="money"
                                    onFocus={() =>
                                        this.setState({ moneySalesField: 1 })
                                    }
                                />
                              </div>
                              <div className="col-md-4">
                                <EkoFormInputText
                                    {...itemProps}
                                    labelStyle={{ height: 54 }}
                                    label="Satış Birim Fiyatı(KDVli)"
                                    id="sales_unit_price_2"
                                    precision={8}
                                    isVertical
                                    type="money"
                                    onFocus={() =>
                                        this.setState({ moneySalesField: 2 })
                                    }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div
                className={
                  this.state.formData.item && !this.state.formData.item.is_sales
                    ? "mrhide"
                    : ""
                }
              >
                <div className="col-md-12">
                  <EkoFormInputText
                    {...itemProps}
                    label="Faturaya Yazılacak Açıklama"
                    id="sales_description"
                    isVertical
                    type="text"
                  />
                </div>
              </div> */}
                          <div></div>
                          <div className="clearfix" />
                        </div>
                      </EkoForm>
                    </Tab.Pane>
                    <Tab.Pane eventKey="changing">
                      <AddChangingModal
                          changingSave={() => {
                            this.setState({ loading: true });
                          }}
                          changingData={item}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="exported">
                      {this.state.formData.item.id ? (
                          <div className="row">
                            <div className="col-md-12">
                              <EkoFormInputText
                                  {...itemProps}
                                  label="GTIP No"
                                  id="gtip"
                                  isVertical
                                  type="text"
                                  maxLength={12}
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormSelect
                                  {...itemProps}
                                  searchable={true}
                                  id="delivery_type_id"
                                  isVertical
                                  defaultText="SEÇİNİZ"
                                  optionId="id"
                                  options={deliveryTypes}
                                  optionValue="description"
                                  label="Teslim Şartı"
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormSelect
                                  {...itemProps}
                                  searchable={true}
                                  id="transport_type_id"
                                  isVertical
                                  defaultText="SEÇİNİZ"
                                  optionId="id"
                                  options={transportTypes}
                                  optionValue="description"
                                  label="Gönderim Şekli"
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormSelect
                                  {...itemProps}
                                  searchable={true}
                                  id="package_type_id"
                                  isVertical
                                  defaultText="SEÇİNİZ"
                                  optionId="id"
                                  options={packageTypes}
                                  optionValue="description"
                                  label="Kabın Cinsi"
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormInputText
                                  {...itemProps}
                                  label="Gümrük Takip Numarası"
                                  id="customs_tracking_no"
                                  isVertical
                                  type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormInputText
                                  {...itemProps}
                                  label="Kab Numarası"
                                  id="container_no"
                                  isVertical
                                  type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <EkoFormInputText
                                  {...itemProps}
                                  label="Kab Adeti"
                                  id="number_of_containers"
                                  isVertical
                                  type="text"
                              />
                            </div>
                          </div>
                      ) : (
                          <div className="alert alert-danger">
                            İhracat bilgilerini eklemek için önce Ürün Bilgileri
                            sekmesini girip KAYDET'e tıklamalısınız. Sonra ilgili
                            ürünü seçip İşlemler tuşunda GÜNCELLE diyerek ihracat
                            bilgilerini girebilirsiniz.
                          </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="vat_tab">
                      {this.state.formData.item.id ? (
                          <div className="row">
                            <AddOtherTax
                                taxTypes={this.props.taxTypes}
                                //inXml={this.state.formData.inXml}
                                vatReductionTypeCodes={this.props.vatReductionTypeCodes}
                                onChangeRow={this.onTaxRowChange.bind(this)}
                                onChange={this.onTaxChange.bind(this)}
                                isNotModal={true}
                                formData={this.state.taxForm}
                                errors={{}}
                                onSave={this.onTaxFormSave.bind(this)}
                                onCancel={() => {}}
                            />
                          </div>
                      ) : (
                          <div className="alert alert-danger">
                            Vergi bilgilerini eklemek için önce Ürün Bilgileri
                            sekmesini girip KAYDET'e tıklamalısınız. Sonra ilgili
                            ürünü seçip İşlemler tuşunda GÜNCELLE diyerek vergi
                            bilgilerini girebilirsiniz.
                          </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{"display":this.state.activeTab==="vat_tab"?"none":""}}>
              <EkoFormButton
                  label={item && item.id ? "Güncelle" : "Kaydet"}
                  faClass="fa fa-plus"
                  onClick={this.onSave.bind(this)}
                  isLoading={this.state.saving}
              />
              <EkoFormButton
                  label="İptal"
                  className="btn"
                  onClick={this.onCancel.bind(this)}
              />
            </div>
            <hr/>
            <div style={{"textAlign": "left"}}>
              <a onClick={this.openNewTagModal}>Etiket Ekle</a>
            </div>
          </Modal.Footer>
        </EkoModal>
        <NewTagDocumentModal
            newTagRows={this.state.newTagRows}
            saveNewTag={this.saveNewTag}
            showNewTagModal={this.state.showNewTagModal}
            closeNewTagModal={this.closeNewTagModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    units: state.data.units,
    currencies: state.data.currencies,
    vatRateTypes: state.data.vatRateTypes,
    deliveryTypes: state.data.deliveryTypes,
    transportTypes: state.data.transportTypes,
    packageTypes: state.data.packageTypes,
    taxTypes: state.data.taxTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
  };
}

export default connect(mapStateToProps, actions)(AddItem);
