import React,{Component} from 'react';
import {EkoForm, EkoFormButton, EkoFormInputText} from "../../components/elements/EkoForm";
import {browserHistory} from "react-router";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {FETCH_COMPANY} from "../../actions/types";


class CargoInformations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {},
            saving: false,
            errors: {},
            saved: false,
            formFields: {},
            isUpdate : false,
            isDisabled : false
        };
    }

    defaultFormData() {
        return {
            company: {}
        };
    }

    componentWillMount() {
        let formData = this.defaultFormData();
        let errors = {};
        Api.get('/einformation/addDocumentReference',(res,err)=>{
            if (res.status === 'error'){
                this.setState({isDisabled:true})
                toastr.error(res.message)
            }

            if (res.status === 'ok'){
                this.setState({
                    formData : {
                        company : res.data
                    }
                })
            } else {
                this.setState({ formData: formData, errors: errors });
            }
        })
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        this.setState({ formData: bigForm, errors: errors });
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }

    onSave() {

        this.setState({saving: true})

        Api.put('/einformation/addDocumentReference',{...this.state.formData.company},(res,err)=>{
            if (res){
                console.log('PUTRESPONSE',res)
                if(res.status === 'ok'){
                    this.setState({saving:false})
                    toastr.success(res.message)
                }
            }

            if (err){
                console.log('PUTERROR',err)
                this.setState({saving:false})
                toastr.error(err.message)
            }
        })
    }


    render() {
        console.log("formdata", this.state.formData);
        if (!this.props.company) return <EkoSpinner/>;
        document.body.className += " page-header-menu-fixed";

        const generalProps = {
            onChange: this.setFieldChange.bind(this, "company"),
            onMount: this.setFieldMount.bind(this, "company"),
            formData: this.state.formData.company,
            errors: this.state.errors.company,
            disabled : this.state.isDisabled
        };

        return (<>
            <EkoForm>
                <div className="row w-100">
                    <h2 className="h2 text-center">Web Sitesi Bilgileri</h2>
                    <div className="col-md-12">

                        <EkoFormInputText
                            {...generalProps}
                            label={"Web Sitesi"}
                            placeHolder={"www.ekohesap.com"}
                            id={"website"}
                            isVertical={true}/>

                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-6" style={{"border-right": "3px solid #ddd","padding":"20px"}}>
                        <h2 className="h2 text-center">Kargo Bilgileri</h2>
                        <EkoFormInputText
                            {...generalProps}
                            label={"Kargo Firmasının adı"}
                            id={"carrier_name"}
                            isVertical={true}/>

                        <EkoFormInputText
                            {...generalProps}
                            label={"Kargo Firmasının VKN'si"}
                            id={"carrier_tax_identity"}
                            isVertical={true}/>

                    </div>
                    <div className="col-md-6" style={{"padding":"20px"}}>
                        <h2 className="h2 text-center">Kurye Bilgileri</h2>

                        <EkoFormInputText
                            {...generalProps}
                            label={"Kurye Adı Soyadı"}
                            id={"messenger_name"}
                            isVertical={true}/>

                        <EkoFormInputText
                            {...generalProps}
                            label={"Kurye TC kimlik Numarası"}
                            id={"messenger_tax_identity"}
                            isVertical={true}/>

                    </div>
                </div>

                <div style={{"display": "flex", "justifyContent": "center", "marginTop": 20}}>
                    <EkoFormButton
                        label={"Kaydet"}
                        faClass="fa fa-plus"
                        isLoading={this.state.saving}
                        onClick={() => this.onSave()}
                    />
                    <EkoFormButton
                        label="İptal"
                        className="btn"
                        onClick={() => browserHistory.goBack()}
                    />
                </div>
            </EkoForm>

        </>);
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(CargoInformations);