import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Style from "style-it";

export default class EkoModal extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(props) {
    if (
      props.showModal !== this.props.showModal &&
      props.showModal &&
      this.props.refreshData
    ) {
      this.props.refreshData();
    }
  }

  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.string,
    dialogClassName: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    onClickSave: PropTypes.func,
    spinner: PropTypes.bool.isRequired,
    modalBodyStyle: PropTypes.object,
    headerStyle: PropTypes.object
  };

  static defaultProps = {
    modalBodyStyle: {}
  };

  hide() {
    if (!this.props.onHideDisable) {
      this.props.onHide();
    }
  }

  render() {
    let children = this.props.children;
    if (this.props.extraCss) {
      children = Style.it(
        this.props.extraCss,
        <div>{this.props.children}</div>
      );
    }

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onHide}
        bsSize={this.props.bsSize}
        dialogClassName={this.props.dialogClassName}
        backdrop="static"
        backdropStyle={this.props.backdropStyle}
      >
        <div className="modal-header" style={this.props.headerStyle || {}}>
          {!this.props.disableCloseButton && (
            <button
              type="button"
              onClick={this.hide.bind(this)}
              className="close2"
            >
              <span className="sr-only">Close</span>
            </button>
          )}
          <h4 className="modal-title" style={this.props.titleStyle || {}}>
            {this.props.title}
          </h4>
        </div>
        <Modal.Body
          style={{
            ...this.props.modalBodyStyle
          }}
        >
          {children}
        </Modal.Body>
      </Modal>
    );
  }
}
