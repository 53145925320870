import React, {Component} from 'react'
import {
    EkoForm,
    EkoFormButton,
    EkoFormDate,
    EkoFormInputText, EkoFormRadioButton,
    EkoFormSelect
} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import moment from 'moment'
import Util from '../../util/Util';

class AddDepreciation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            asset_list: []
        }
    }

    defaultFormData() {
        return {
            asset: {},
        }
    }

    getAssetList() {
        const self = this;
        Api.get(`/fixedAsset/list`, (response) => {
            if (response.status === 'ok') {
                let asset_list = response.fixedAssets.map((data) => {
                    return {id: data.id, name: data.asset_name}
                })
                self.setState({asset_list: asset_list})
            }
        });
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}
        this.setState({formData: formData, errors: errors});
        this.getAssetList();
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        this.setState({formData: formData, errors: {}, showModal: nextProps.showModal});
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        if (type === 'asset' && field === 'fixed_asset_type_id') {
            let selected = this.props.fixedAssetTypes.filter((data) => {
                return data.id === value
            });
            if (value !== 2) {
                bigForm.asset.otv_amount = "0,00";
                bigForm.asset.expense_vat = "";
                bigForm.asset.expense_otv = "";
            }
            bigForm.asset.depreciation_term = selected[0].depreciation_term ? selected[0].depreciation_term.toString() : "0";

        }
        this.setState({formData: bigForm, errors: errors});
    }


    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    onSave() {
        const self = this;
        self.setState({saving: true});

        let accumulated_depreciation = Util.convertMoneyToDecimal(this.state.formData.asset.accumulated_depreciation || '0,00')
        let amount = Util.convertMoneyToDecimal(this.state.formData.asset.amount || '0,00')
        let cost_price = Util.convertMoneyToDecimal(this.state.formData.asset.cost_price || '0,00')
        let decreasing_cost_price = Util.convertMoneyToDecimal(this.state.formData.asset.decreasing_cost_price || '0,00')
        let depreciation_rate = Util.convertMoneyToDecimal(this.state.formData.asset.depreciation_rate || '0,00')

        let assets = Object.assign({}, self.state.formData.asset, {
            accumulated_depreciation: accumulated_depreciation,
            amount: amount,
            cost_price: cost_price,
            decreasing_cost_price: decreasing_cost_price,
            depreciation_rate: depreciation_rate,
        });

        let errors = self.state.errors;

        let func = "post";
        let url = "/fixedAsset/addFixedAssetDepreciation";

        if (assets.id) {
            func = "put"; //NOTE edit işlemi
            url = "/fixedAsset/updateFixedAssetDepreciation/" + assets.id;
        }

        Api["post"]("/fixedAsset/depreciationValidation", assets, function (response, postErrors) {
            if (postErrors) {
                errors.asset = postErrors.message;
                self.setState({saving: false, errors: errors});
            } else {
                Api[func](url, assets, function (response, postErrors) {
                    if (postErrors) {
                        errors.asset = postErrors;
                        self.setState({saving: false, errors: errors});
                    } else {
                        if (assets.id) {
                            toastr.success('Başarılı', 'Başarıyla Güncellendi');
                            self.setState({saving: false, errors: {}});
                        }
                        else {
                            self.setState({saving: false, errors: {}, formData: self.defaultFormData()});
                            toastr.success('Başarılı', 'Başarıyla Kaydedildi');
                        }
                        self.props.onSave();
                    }

                });
            }

        });
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        let assetType = [{"id": 0, "name": "Ana Demirbaş"}, {"id": 1, "name": "Kayıtlı Bir Demirbaşın Parçası"}];
        if(this.props.to_invoice !==undefined){
            assetType = assetType.filter(item => item.id !== 1);
            const newAsset = {"id": 3, "name": "Bu Faturada Eklenen Demirbaşın Parçası"};
            assetType.push(newAsset);
        }
        let btn = this.state.formData.asset.id ? "Güncelle" : "Kaydet";
        let title = this.state.formData.asset.id ? "Amortisman Güncelle" : "Amortisman Ekle";
        const assetProps = {
            onChange: this.setFieldChange.bind(this, "asset"),
            onMount: this.setFieldMount.bind(this, "asset"),
            formData: this.state.formData.asset,
            errors: this.state.errors.asset
        }
        return <EkoModal
            showModal={this.state.showModal}
            title={title}
            dialogClassName="asset-modal"
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_asset_modal"}>
                <div className="row">
                    <div className="col-md-4">
                        <EkoFormSelect {...assetProps}
                                       vertical={true}
                                       labelMd={5}
                                       colMd={7}
                                       id="asset_id"
                                       optionId={"id"}
                                       optionValue={"name"}
                                       defaultText="Seçiniz"
                                       options={this.state.asset_list}
                                       searchable={true}
                                       label="Demirbaş Listesi"/>
                    </div>
                    <div className="col-md-4">
                        <EkoFormInputText {...assetProps}
                                          vertical={true}
                                          labelMd={2}
                                          colMd={10}
                                          label="Tarih"
                                          id="date"
                                          type="date"/>
                    </div>
                    <div className="col-md-4">
                        <EkoFormInputText {...assetProps}
                                          vertical={true}
                                          labelMd={2}
                                          colMd={10}
                                          label="Tutar"
                                          type="money"
                                          id="amount"/>
                    </div>
                </div>
                <Modal.Footer>
                    <div className="row buttonProccess2">
                        <EkoFormButton label={btn}
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>
            </EkoForm>
        </EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        fixedAssetTypes: state.data.fixedAssetTypes,
        vatRateTypes: state.data.vatRateTypes,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddDepreciation);
