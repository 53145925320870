import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import Api from '../../util/Api'

class IVDConfiscation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {},
            errors: {},
            user: {},
            data: [] //table Item
        }
    }
    fetch() {
        Api.get("/EArchiveInteraktifVD/IVDConfiscation", (response, err) => {
            this.setState({data:response.list});
        });
    }
    componentWillMount() {
        this.fetch();
    }
    render() {
        return (
            <div>
                <EkoDataTable
                    hideSelectButtons
                    addButtonShow={false}
                    data={this.state.data}
                    name={"ivd_confiscation"}>
                </EkoDataTable>
            </div>
        )
    }
}
function mapStateToProps(state) {

}
export default connect(mapStateToProps, actions)(IVDConfiscation);