import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Loading from "react-loading-spinner";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Api from "../../util/Api";
import NotFound from "../../components/dashboard/NotFound";

class Activation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }

  componentWillMount() {
    const self = this;
    let code = this.props.params.code;
    Api.post(
      "/auth/codeCheck",
      { activation_code: code, is_create: true },
      (response, postErrors) => {
        if (response) {
          toastr.success("Başarılı", response.message);
        }
        self.props.router.push("/login");
      }
    );
  }

  render() {
    return (
      <Loading
        isLoading={this.state.show}
        loadingClassName="loading-spinner"
        spinner={EkoSpinner}
      >
        <NotFound />
      </Loading>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(Activation);
