import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'
import HelperContainer from '../../util/HelperContainer'
import EkoSpinner from "../../components/elements/EkoSpinner";
import Api from '../../util/Api'
import {EkoForm, EkoFormButton} from "../../components/elements/EkoForm";


class WageSheetDetailsEmployee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            records: {},
            branch: {},
            page_loading: true
        }

    }
    componentWillMount() {
        this.fetchRecordedData();
    }
    componentDidMount() {
        
    }
    fetchRecordedData() {
        var self = this;
        this.setState({page_loading: true, records: []});
        Api.get("/company/getCompanyWageDetailsEmployee/"+self.props.params.id, (response, errors) => {
            if (errors) {
                return;
            }
            this.setState({records: response.data, branch: response.branch, page_loading: false });
        });
    }
    

    render() {
        return (<>
            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase">
                            {this.state.records.name_surname} {this.state.records.vat_period}
                        </span>
                    </div>
                    <div className="actions">
                        <div className="btn-group btn-group-devided" data-toggle="buttons">
                            <button type="button" id="print" onClick={() => {
                                window.print()
                            }} className="btn blue btn-sm"><i className="fa fa-print"></i>
                                Yazdır
                            </button>
                            <button onClick={() => { this.props.router.push('/dashboard/wagesheet/' + this.state.records.wage_sheets_id) } } className='btn btn-sm blue'>Bordro Personel Listesi</button>
                            <button onClick={() => { this.props.router.push('/dashboard/employee') } } className='btn btn-sm blue'>Çalışan Listesi</button>
                        </div>
                        
                    </div>
                </div>
                <div className="portlet-body">
                    {this.state.page_loading == true ? <EkoSpinner /> : 
                        <>
                            <table className='tinvoice table table-striped table-bordered table-hover'>
                                <tbody>
                                    <tr>
                                        <th colSpan={4} style={{textAlign: 'center'}}>FİRMA BİLGİLERİ</th>
                                    </tr>
                                    <tr>
                                        <th>Ünvanı</th>
                                        <td>{this.state.branch.isyeri_adi}</td>
                                        <th>Şube Kodu</th>
                                        <td>{this.state.branch.isyeri_sube_kodu}</td>
                                    </tr>
                                    <tr>
                                        <th>Ticaret Sicil No</th>
                                        <td>{this.state.branch.ticaret_sicil_no}</td>
                                        <th>SGK İşyeri No</th>
                                        <td>{this.state.branch.sicil_sektor_kodu}{this.state.branch.sicil_isyeri_kodu}{this.state.branch.sicil_yeni_unite}{this.state.branch.sicil_eski_unite}{this.state.branch.sicil_isyeri}{this.state.branch.sicil_il_kodu}{this.state.branch.sicil_ilce_kodu}{this.state.branch.sicil_kontrol_no}{this.state.branch.sicil_araci_no}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={4} style={{textAlign: 'center'}}>ÇALIŞAN BİLGİLERİ</th>
                                    </tr>
                                    <tr>
                                        <th>Adı Soyadı</th>
                                        <td>{this.state.records.name_surname}</td>
                                        <th>TC Kimlik</th>
                                        <td>{this.state.records.tc_number}</td>
                                    </tr>
                                    <tr>
                                        <th>E-posta</th>
                                        <td>{this.state.records.email}</td>
                                        <th>Cinsiyet</th>
                                        <td>{this.state.records.gender}</td>
                                    </tr>
                                    <tr>
                                        <th>SSK Sicil No</th>
                                        <td>{this.state.records.sgk_numer}</td>
                                        <th>Ücret Şekli</th>
                                        <td>{this.state.records.period}</td>
                                    </tr>
                                    <tr>
                                        <th>İşe Giriş Tarihi</th>
                                        <td>{this.state.records.employment_date}</td>
                                        <th>İşten Çıkış Tarihi</th>
                                        <td>{this.state.records.termination_date}</td>
                                    </tr>
                                    <tr>
                                        <th>Sigortalı MES Kodu</th>
                                        <td>{this.state.records.speciality}</td>
                                        <th>Ücret</th>
                                        <td>{this.state.records.salary}</td>
                                    </tr>
                                    <tr>
                                        <th>Çocuk Sayısı</th>
                                        <td>{this.state.records.number_of_children}</td>
                                        <th>Tabi Olduğu Kanun</th>
                                        <td>{this.state.records.subject_to_law}</td>
                                    </tr>
                                    <tr>
                                        <th>İşe Giriş Dev Matrahı</th>
                                        <td>{this.state.records.employment_tax_base}</td>
                                        <th>7103 Gelir Vergisi İstisnası</th>
                                        <td>{this.state.records['7103_income_tax_exemption']}</td>
                                    </tr>
                                    <tr>
                                        <th>7103 Gün</th>
                                        <td>{this.state.records['7103_day']}</td>
                                        <th>6322 Gelir Vergisi İstisnası</th>
                                        <td>{this.state.records['6322_income_tax_exemption']}</td>
                                    </tr>
                                    <tr>
                                        <th>Görevi</th>
                                        <td>{this.state.records['duty']}</td>
                                        <th>BES 10</th>
                                        <td>{this.state.records['bes_10']}</td>
                                    </tr>
                                    <tr>
                                        <th>Sağlık Sigortas Primi Tutarı</th>
                                        <td>{this.state.records['health_insurance']}</td>
                                        <th>Hayat Sigortası Primi Tut15</th>
                                        <td>{this.state.records['life_insurance_15']}</td>
                                    </tr>
                                    <tr>
                                        <th>Tahakkuk Şekli</th>
                                        <td>{this.state.records['salary_type']}</td>
                                        <th>Belge Türü</th>
                                        <td>{this.state.records['declaration_type']}</td>
                                    </tr>
                                    <tr>
                                        <th>Kanun No</th>
                                        <td>{this.state.records['law_number']}</td>
                                        <th>Kanun No 2</th>
                                        <td>{this.state.records['law_number_2']}</td>
                                    </tr>
                                    <tr>
                                        <th>Devir V Matrahı</th>
                                        <td>{this.state.records['transferred_tax_base']}</td>
                                        <th>Çalışma Günü</th>
                                        <td>{this.state.records['days_working']}</td>
                                    </tr>
                                    <tr>
                                        <th>Ulusal Bayram</th>
                                        <td>{this.state.records['days_holiday']}</td>
                                        <th>Hafta Sonu</th>
                                        <td>{this.state.records['days_off']}</td>
                                    </tr>
                                    <tr>
                                        <th>Sıhhi</th>
                                        <td>{this.state.records['vacation_sıhhi']}</td>
                                        <th>Yıllık</th>
                                        <td>{this.state.records['vacation_yearly']}</td>
                                    </tr>
                                    <tr>
                                        <th>Ücretli</th>
                                        <td>{this.state.records['vacation_paid']}</td>
                                        <th>Ücretsiz</th>
                                        <td>{this.state.records['vacation_unpaid']}</td>
                                    </tr>
                                    <tr>
                                        <th>Mazeretsiz</th>
                                        <td>{this.state.records['vacation_no_reason']}</td>
                                        <th>Prim Günü</th>
                                        <td>{this.state.records['days_paid']}</td>
                                    </tr>
                                    <tr>
                                        <th>Eksik Prim Gün Nedeni</th>
                                        <td>{this.state.records['off_day_reason']}</td>
                                        <th>1FzlMs</th>
                                        <td>{this.state.records['owertime_weekday']}</td>
                                    </tr>
                                    <tr>
                                        <th>2FzlMs</th>
                                        <td>{this.state.records['overtime_Sunday']}</td>
                                        <th>3FzlMs</th>
                                        <td>{this.state.records['overtime_holiday']}</td>
                                    </tr>
                                    <tr>
                                        <th>Aylık Brüt Ücret</th>
                                        <td>{this.state.records['gross_income']}</td>
                                        <th>Fazla Mesai Toplamı</th>
                                        <td>{this.state.records['overtime_income']}</td>
                                    </tr>
                                    <tr>
                                        <th>Sair Ödeme Toplamı</th>
                                        <td>{this.state.records['other_payments']}</td>
                                        <th>Brüt Toplam</th>
                                        <td>{this.state.records['total_income']}</td>
                                    </tr>
                                    <tr>
                                        <th>Gelir Vergisi Matrahı</th>
                                        <td>{this.state.records['income_tax_base']}</td>
                                        <th>Sigorta Matrahı</th>
                                        <td>{this.state.records['sgk_income']}</td>
                                    </tr>
                                    <tr>
                                        <th>Vergi İndirimi</th>
                                        <td>{this.state.records['income_tax_basis_subvention']}</td>
                                        <th>Gelir Vergisi</th>
                                        <td>{this.state.records['income_tax']}</td>
                                    </tr>
                                    <tr>
                                        <th>AÜ Ver. İstis.</th>
                                        <td>{this.state.records['Au_tax_exemption']}</td>
                                        <th>Gelir Ver.İstis.</th>
                                        <td>{this.state.records['tax_exemption']}</td>
                                    </tr>
                                    <tr>
                                        <th>AGİ</th>
                                        <td>{this.state.records['mlc_amount']}</td>
                                        <th>Sigorta Kesintisi</th>
                                        <td>{this.state.records['sgk_employee']}</td>
                                    </tr>
                                    <tr>
                                        <th>İşs.Sig.Kes.işçi</th>
                                        <td>{this.state.records['unemployment_employee']}</td>
                                        <th>Damga Vergisi</th>
                                        <td>{this.state.records['stamp_tax']}</td>
                                    </tr>
                                    <tr>
                                        <th>Damga Vergisi İst.</th>
                                        <td>{this.state.records['Au_stamp_tax_exemption']}</td>
                                        <th></th>
                                        <td>{this.state.records['vacation_yearly']}</td>
                                    </tr>
                                    <tr>
                                        <th>Kesintiler Toplamı</th>
                                        <td>{this.state.records['deductions_total']}</td>
                                        <th>Net Ücret</th>
                                        <td>{this.state.records['net_pay']}</td>
                                    </tr>
                                    <tr>
                                        <th>İşv.Sgk Hissesi</th>
                                        <td>{this.state.records['sgk_employer']}</td>
                                        <th>İşs.Sig.Kes.işv</th>
                                        <td>{this.state.records['unemployment_employer']}</td>
                                    </tr>
                                    <tr>
                                        <th>İşten Çıkış Nedeni</th>
                                        <td>{this.state.records['dismissal_reason']}</td>
                                        <th>7103 DV.Teş</th>
                                        <td>{this.state.records['7103_stamp_tax_incentive']}</td>
                                    </tr>
                                    <tr>
                                        <th>Kalan GV</th>
                                        <td>{this.state.records['residua_income_tax']}</td>
                                        <th>Eksik Gün</th>
                                        <td>{this.state.records['missing_day']}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            </div>
            
        </>)
    }
}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(WageSheetDetailsEmployee);
