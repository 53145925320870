import React, { Component } from "react";
import {
  EkoForm,
  EkoFormAutoComplete,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormAutosuggest
} from "../../components/elements/EkoForm";
import Util from "../../util/Util";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import {
  FETCH_SOLE_INVOICE_AUTOCOMPLETE,
  FETCH_CUSTOMERS_ALL
} from "../../actions/types";
import EkoFormButtonGroup from "../../components/elements/form/EkoFormButtonGroup";
import AddCustomer from "../Customer/AddCustomer";
import moment from "moment";

class AddSoleProfSalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      formFields: {},
      saving: false,
      successDisabled: false,
      spinner: false,
      showCustomerModal: false
    };
  }

  defaultFormData() {
    return {
      soleInvoice: {
        vat_rate_type_id: 4,
        stoppage_type_id: 4,
        vat_reduction_type_id: 1,
        calculation_type_id: 1
      }
    };
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.setState({ formData: formData, errors: errors });
  }

  componentDidMount() {
    this.props.fetchData(
      "/soleprofSalesInvoice/autoComplete",
      FETCH_SOLE_INVOICE_AUTOCOMPLETE
    );
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
  }

  componentWillReceiveProps(nextProps) {
    const soleInvoice =
      nextProps && nextProps.soleInvoice ? nextProps.soleInvoice : {};

    // TODO: kontrol edilecek
    const spinnerStatus =
      (soleInvoice && soleInvoice.error && !Util.isEmpty(soleInvoice.error)) ||
      (soleInvoice.success && !Util.isEmpty(soleInvoice.success));
    if (spinnerStatus) {
      this.setState({ spinner: false });
    }
    let formData = nextProps.formData || this.defaultFormData();
    formData.soleInvoice.name = formData.soleInvoice.customer_name;
    if (!formData.soleInvoice.vat_reduction_type_code)
      delete formData.soleInvoice.vat_reduction_type_code;
    this.setState({ formData: formData, errors: {} });
  }

  getStoppageAmountAccordingToChangedAmount(formData, amount) {
    let stoppageTypes = this.props.stoppageTypes;
    const stoppageType = stoppageTypes.filter(
      s => s.id === formData.stoppage_type_id
    )[0];
    let stoppageAmount = (amount * stoppageType.rate).toFixed(2);
    return Util.convertDecimalToMoney(stoppageAmount);
  }

  getVatAmountAccordingToChangedAmount(formData, amount) {
    let vatRateTypes = this.props.vatRateTypes;
    const vatRateType = vatRateTypes.filter(
      s => s.id === formData.vat_rate_type_id
    )[0];
    let vatAmount = (amount * vatRateType.rate).toFixed(2);
    return Util.convertDecimalToMoney(vatAmount);
  }

  getVatReductionAmountAccordingToChangedAmount(formData, amount) {
    let vatRateTypes = this.props.vatRateTypes;
    const vatRateType = vatRateTypes.filter(
      s => s.id === formData.vat_rate_type_id
    )[0];
    let vatAmount = (amount * vatRateType.rate).toFixed(2);
    let vatReductionTypes = this.props.vatReductionTypes;
    const vatReductionType = vatReductionTypes.filter(
      s => s.id === formData.vat_reduction_type_id
    )[0];
    let vatReductionAmount = (vatAmount * vatReductionType.rate).toFixed(2);
    return Util.convertDecimalToMoney(vatReductionAmount);
  }

  onSave() {
    const redirect = arguments[0];
    const self = this;
    self.setState({ saving: true });

    let amount = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.amount || "0,00"
    );
    let stoppage_amount = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.stoppage_amount || "0,00"
    );
    let vat_amount = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.vat_amount || "0,00"
    );
    let amount_calculation = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.amount_calculation || "0,00"
    );
    let vat_reduction_amount = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.vat_reduction_amount || "0,00"
    );
    let currency_rate = Util.convertMoneyToDecimal(
      this.state.formData.soleInvoice.currency_rate || "0,00"
    );
    let date = Util.convertToDatabaseDate(this.state.formData.soleInvoice.date);
    let customer_name = this.state.formData.soleInvoice.name;

    let soleInvoice = Object.assign({}, self.state.formData.soleInvoice, {
      currency_rate: currency_rate,
      amount: amount,
      date: date,
      stoppage_amount: stoppage_amount,
      vat_amount: vat_amount,
      vat_reduction_amount: vat_reduction_amount,
      amount_calculation: amount_calculation,
      customer_name: customer_name
    });

    if (!soleInvoice.customer_id) soleInvoice.customer_id = null;
    let errors = self.state.errors;

    let func = "post";
    let url = "/soleprofSalesInvoice";

    if (soleInvoice.id) {
      func = "put"; //NOTE edit işlemi
      url = "/soleprofSalesInvoice/" + soleInvoice.id;
    }
    Api[func](url, soleInvoice, function(response, postErrors) {
      if (postErrors) {
        errors.soleInvoice = postErrors;
        self.setState({ saving: false, errors: errors });
      } else {
        self.props.fetchData(
          "/soleprofSalesInvoice/autoComplete",
          FETCH_SOLE_INVOICE_AUTOCOMPLETE
        );

        if (self.props.onSave) {
          self.props.onSave();
        }

        if (soleInvoice.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");

          if (redirect && redirect === true) {
            self.props.redirectToBase();
          } else {
            self.props.router.push("/dashboard/soleprofsalesinvoice/add");
          }
        } else {
          toastr.success("Başarılı", "Başarıyla Kaydedildi");
          if (redirect && redirect === true) {
            self.props.redirectToBase();
          } else {
            self.setState({
              saving: false,
              errors: {},
              formData: self.defaultFormData()
            });
          }
        }
      }
    });
  }

  onClickSave() {
    let amount = Util.convertMoneyToDecimal(this.state.soleInvoice.amount);
    let stoppageAmount = Util.convertMoneyToDecimal(
      this.state.soleInvoice.stoppage_amount
    );
    let vatAmount = Util.convertMoneyToDecimal(
      this.state.soleInvoice.vat_amount
    );
    let vatReductionAmount = Util.convertMoneyToDecimal(
      this.state.soleInvoice.vat_reduction_amount
    );
    let formattedDate = Util.formatDateAsYearMonthDay(
      this.state.soleInvoice.date
    );

    const soleInvoice = Object.assign({}, this.state.soleInvoice, {
      amount: parseFloat(amount),
      stoppage_amount: stoppageAmount,
      vat_amount: vatAmount,
      vat_reduction_amount: vatReductionAmount,
      date: formattedDate
    });

    if (soleInvoice.tax_identity_number === "") {
      delete soleInvoice["tax_identity_number"];
    }

    if (soleInvoice.vat_reduction_type_code === "") {
      delete soleInvoice["vat_reduction_type_code"];
    }

    this.props.actions.sendSoleInvoice(soleInvoice);
    this.setState({ successDisabled: true, spinner: true });
  }

  getCalculatedTotalAmount() {
    const soleInvoice = this.state.formData["soleInvoice"];
    let amount = soleInvoice.amount
      ? Util.convertMoneyToDecimal(soleInvoice.amount)
      : 0;
    let stoppageAmount = soleInvoice.stoppage_amount
      ? Util.convertMoneyToDecimal(soleInvoice.stoppage_amount)
      : 0;
    let vatAmount = soleInvoice.vat_amount
      ? Util.convertMoneyToDecimal(soleInvoice.vat_amount)
      : 0;
    let vatReductionAmount = soleInvoice.vat_reduction_amount
      ? Util.convertMoneyToDecimal(soleInvoice.vat_reduction_amount)
      : 0;

    let totalAmount =
      parseFloat(amount) -
      parseFloat(stoppageAmount) +
      parseFloat(vatAmount) -
      parseFloat(vatReductionAmount);
    totalAmount =
      totalAmount && new Intl.NumberFormat("tr-TR").format(totalAmount);
    return totalAmount;
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;

    if (type == "soleInvoice" && field == "customer_id") {
      let customer = this.props.customers.filter(item => item.id == value);
      customer = customer[0] ? customer[0] : null;
      if (customer) {
        let lastname = customer.lastname ? " - " + customer.lastname : "";
        formData.customer_name = (customer.firstname + " " + lastname).trim();
        formData.name = (customer.firstname + " " + lastname).trim();
        formData.tax_identity_number = customer.tax_identity_number;
        let taxOffice = customer.tax_office_code
          ? this.props.taxOffices.filter(
              tax => tax.code == customer.tax_office_code
            )
          : [];
        formData.tax_office =
          taxOffice.length > 0 ? taxOffice[0].name.trim() : "";
        this.getAddressAndContact(value, type);
      } else {
        formData.customer_name = "";
        formData.name = "";
        formData.tax_identity_number = "";
        formData.address = "";
        formData.city = "";
        formData.county = "";
        formData.tax_office = "";
      }
      this.calculateDueDate(formData);
    }

    if (type == "soleInvoice" && field == "date") {
      this.calculateDueDate(formData);
    }

    if (
      field == "vat_reduction_type_id" ||
      field == "amount" ||
      field == "stoppage_type_id" ||
      field == "vat_rate_type_id" ||
      field == "calculation_type_id" ||
      field == "amount_calculation"
    ) {
      this.calculationAmount(type, field, formData, value);
    }

    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    this.setState({ formData: bigForm, errors: errors });
  }

  calculateDueDate(formData) {
    const self = this;
    formData = formData ? formData : self.state.formData.soleInvoice;
    let fromWhoId = formData.customer_id;
    if (!fromWhoId) {
      self.setFieldChange("soleInvoice", "due_date", formData.date);
    } else {
      Api.get(
        "/calculation/invoiceDueDate?id=" +
          fromWhoId +
          "&date=" +
          moment(formData.date).format("YYYY-MM-DD") +
          "&type=customer",
        function(response, err) {
          if (!err) {
            const date = moment(response.response, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            );
            self.setFieldChange("soleInvoice", "due_date", date);
          }
        }
      );
    }
  }

  getAddressAndContact(id, type) {
    Api.paralel(
      ["/customerContact/showAll/" + id, "/customerAddress/showAll/" + id],
      responses => {
        console.log(responses, "res");
        let bigForm = this.state.formData;
        const address =
          responses &&
          responses[1] &&
          responses[1].customerAddresses &&
          responses[1].customerAddresses.length > 0
            ? responses[1].customerAddresses.filter(function(a) {
                return a.address_type === 2;
              })
            : [];
        if (address.length > 0) {
          bigForm[type] = {
            ...bigForm[type],
            address: address[0].address,
            city: address[0].city && address[0].city.name,
            county: address[0].county && address[0].county.name
          };
        } else {
          bigForm[type] = {
            ...bigForm[type],
            address: "",
            city: "",
            county: ""
          };
        }
        this.setState({ formData: bigForm });
      }
    );
  }

  setFieldChangeForAutoComplete(type, field, value) {
    let soleProfSalesInvoices = this.props.soleProfSalesInvoices;
    soleProfSalesInvoices = soleProfSalesInvoices.filter(
      o => o[field] === value
    )[0];
    let formData = this.state.formData[type];
    if (soleProfSalesInvoices) {
      formData.name = soleProfSalesInvoices.name;
      formData.tax_identity_number = soleProfSalesInvoices.tax_identity_number;
    }
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  calculationAmount(type, field, formData, value) {
    if (field == "vat_reduction_type_id") {
      const vat_amount = formData.vat_amount
        ? Util.convertMoneyToDecimal(formData.vat_amount)
        : 0;
      const vat_reduction = this.props.vatReductionTypes.filter(
        v => v.id === value
      )[0];
      let vatReductionAmount = (vat_reduction.rate * vat_amount).toFixed(2);
      formData.vat_reduction_amount = Util.convertDecimalToMoney(
        vatReductionAmount
      );

      if (value === 1) {
        delete formData.vat_reduction_type_code;
      }
    }

    if (field == "amount") {
      let copiedAmount = value;

      if (value === "") {
        copiedAmount = 0;
      }
      copiedAmount = Util.convertMoneyToDecimal(copiedAmount);

      const stoppageAmount = this.getStoppageAmountAccordingToChangedAmount(
        formData,
        copiedAmount
      );
      const vatAmount = this.getVatAmountAccordingToChangedAmount(
        formData,
        copiedAmount
      );
      const vatReductionAmount = this.getVatReductionAmountAccordingToChangedAmount(
        formData,
        copiedAmount
      );
      formData.amount = Util.convertDecimalToMoney(copiedAmount);
      formData.stoppage_amount = stoppageAmount;
      formData.vat_amount = vatAmount;
      formData.vat_reduction_amount = vatReductionAmount;
    }

    if (field == "stoppage_type_id") {
      const stoppage_type = this.props.stoppageTypes.filter(
        s => s.id === value
      )[0];
      let stoppageAmount = formData.amount
        ? Util.convertMoneyToDecimal(formData.amount) * stoppage_type.rate
        : 0;
      formData.stoppage_amount = Util.convertDecimalToMoney(stoppageAmount);
    }
    if (field == "vat_rate_type_id") {
      const vat_type = this.props.vatRateTypes.filter(v => v.id === value)[0];
      let vatAmount = formData.amount
        ? Util.convertMoneyToDecimal(formData.amount) * vat_type.rate
        : 0;
      formData.vat_amount = Util.convertDecimalToMoney(vatAmount);
    }

    if (field == "amount_calculation" || field == "calculation_type_id") {
      let calculationType = formData.calculation_type_id;
      const amountCalculation =
        Util.convertMoneyToDecimal(formData.amount_calculation) || 0;
      if (calculationType == 1)
        this.setFieldChange(
          type,
          "amount",
          Util.convertDecimalToMoney(amountCalculation)
        );
      else if (calculationType == 2) {
        const stoppage_type = this.props.stoppageTypes.filter(
          s => s.id === formData.stoppage_type_id
        )[0];
        console.log(
          amountCalculation,
          stoppage_type.rate,
          amountCalculation / (1 - stoppage_type.rate),
          "aaa"
        );
        this.setFieldChange(
          type,
          "amount",
          Util.convertDecimalToMoney(
            amountCalculation / (1 - stoppage_type.rate)
          )
        );
      } else {
        const stoppage_type = this.props.stoppageTypes.filter(
          s => s.id === formData.stoppage_type_id
        )[0];
        const vat_reduction = this.props.vatReductionTypes.filter(
          v => v.id === formData.vat_reduction_type_id
        )[0];
        const vat_type = this.props.vatRateTypes.filter(
          v => v.id === formData.vat_rate_type_id
        )[0];
        this.setFieldChange(
          type,
          "amount",
          Util.convertDecimalToMoney(
            amountCalculation /
              (1 -
                stoppage_type.rate +
                vat_type.rate * (1 - vat_reduction.rate))
          )
        );
      }
    }

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    this.setState({ formData: bigForm });
  }

  cancelOperation() {
    this.props.router.push("/dashboard/soleprofsalesinvoice");
  }

  taxOfficeAddNewColumn() {
    let taxOffices = this.props.taxOffices || [];
    taxOffices.map(function(item) {
      if (item && item.city) {
        item.value = item.name.trim() + " - " + item.city.name.trim();
        item.name = item.name.trim();
      }

      return item;
    });
    return taxOffices;
  }

  countiesGroup() {
    const { cities, counties } = this.props;

    const countiesGroup = cities.map(city => {
      return {
        title: city.name,
        options: counties.filter(county => county.city_id == city.id)
      };
    });

    return countiesGroup;
  }

  onCustomerSave() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.setState({ showCustomerModal: false, savingCustomer: true });
  }

  render() {
    let taxOffices = this.taxOfficeAddNewColumn();
    const soleInvoiceProps = {
      onChange: this.setFieldChange.bind(this, "soleInvoice"),
      onMount: this.setFieldMount.bind(this, "soleInvoice"),
      formData: this.state.formData.soleInvoice,
      errors: this.state.errors.soleInvoice
    };

    const soleInvoiceAutoCompleteProps = {
      ...soleInvoiceProps,
      onSelect: this.setFieldChangeForAutoComplete.bind(this, "soleInvoice")
    };

    // const isCreditCardOptions = this.props.viewOptions ? this.props.viewOptions.soleprofSalesInvoice.is_credit_card : [];
    let vatReductionTypeCodes = this.props.vatReductionTypeCodes;

    let stoppageTypes = this.props.stoppageTypes;
    let vatReductionTypes = this.props.vatReductionTypes;

    if (this.state.formData.soleInvoice.vat_reduction_type_id) {
      const vatTypeId = parseInt(
        this.state.formData.soleInvoice.vat_reduction_type_id,
        10
      );
      vatReductionTypeCodes = vatReductionTypeCodes.filter(
        reduction => reduction.vat_reduction_type_id === vatTypeId
      );
    }

    let vatRateTypes = this.props.vatRateTypes;
    const totalAmount = this.getCalculatedTotalAmount();
    const countiesGroup = this.countiesGroup();
    let customers =
      (this.props.customers &&
        this.props.customers.map(customer => {
          let lastname = customer.lastname ? " - " + customer.lastname : "";
          return { ...customer, title: customer.firstname + " " + lastname };
        })) ||
      [];
    const calculationTypes =
      (this.props.viewOptions &&
        this.props.viewOptions.soleprofSalesInvoice &&
        this.props.viewOptions.soleprofSalesInvoice
          .soleProfCalculationMetods) ||
      [];
    console.log(this.props.viewOptions);
    // let formattedDate = Util.formatDateAsYearMonthDay(this.state.soleInvoice.date)
    return (
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {this.props.title}
            </span>
          </div>
        </div>

        <div className="portlet-body">
          <EkoForm formId={"add_sale_invoice"}>
            <AddCustomer
              showModal={this.state.showCustomerModal}
              formData={false}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({ showCustomerModal: false });
              }}
              errors={{}}
            />
            <div className="row">
              <div className="col-md-6">
                <EkoFormSelect
                  {...soleInvoiceProps}
                  colMd="9"
                  labelMd="3"
                  searchable={true}
                  id="customer_id"
                  onAddNewItemClick={() =>
                    this.setState({ showCustomerModal: true })
                  }
                  clearable={true}
                  optionValue="title"
                  defaultText="Seçiniz"
                  options={customers}
                  label="Müşteri"
                />
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <EkoFormInputText
                  {...soleInvoiceProps}
                  colMd="9"
                  labelMd="3"
                  label="Seri/Sıra No"
                  id="document_number"
                />
              </div>
            </div>
            {/*/span*/}
            {/*/row*/}
            <div className="row">
              <div className="col-md-6">
                <EkoFormAutoComplete
                  colMd="9"
                  labelMd="3"
                  {...soleInvoiceAutoCompleteProps}
                  label="Müşteri Firma Ünvanı / Adı Soyadı"
                  options={this.props.soleProfSalesInvoices}
                  alias_id={"customer_name"}
                  optionValue="name"
                  id="name"
                />
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <EkoFormDate
                      colMd="6"
                      labelMd="6"
                      {...soleInvoiceProps}
                      id="date"
                      dateFormat="DD-MM-YYYY"
                      clearable={false}
                      label="Tarih"
                    />
                  </div>
                  <div className="col-md-6">
                    <EkoFormDate
                      colMd="6"
                      labelMd="6"
                      {...soleInvoiceProps}
                      id="due_date"
                      dateFormat="DD-MM-YYYY"
                      clearable={false}
                      label="Vade Tarihi"
                    />
                    {/* <EkoFormSelect {...soleInvoiceProps}
                                                    colMd="6"
                                                    labelMd="6"
                                                    id="is_credit_card"
                                                    optionValue="name"
                                                    defaultText="Seçiniz"
                                                    options={isCreditCardOptions}
                                                    label="Tahsilat Metodu"
                                        /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <EkoFormSelect
                      {...soleInvoiceProps}
                      colMd="6"
                      labelMd="6"
                      id="calculation_type_id"
                      defaultText="Seçiniz"
                      options={calculationTypes}
                      label="Hesaplama Tipi"
                    />
                  </div>
                  <div className="col-md-6">
                    <EkoFormInputText
                      {...soleInvoiceProps}
                      colMd="6"
                      labelMd="6"
                      label="Tutar"
                      id="amount_calculation"
                      type="money"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/*/row*/}

            {/*/row*/}
            <div className="row">
              <div className="col-md-6">
                <EkoFormAutoComplete
                  colMd="9"
                  labelMd="3"
                  {...soleInvoiceAutoCompleteProps}
                  label="Vergi No/TC Kimlik No"
                  options={this.props.soleProfSalesInvoices}
                  optionValue="tax_identity_number"
                  id="tax_identity_number"
                />
                <EkoFormInputText
                  {...soleInvoiceProps}
                  colMd="9"
                  labelMd="3"
                  rows="2"
                  label="Adres"
                  id="address"
                  type="textarea"
                />
                <EkoFormAutosuggest
                  colMd="9"
                  labelMd="3"
                  multiSection={true}
                  {...soleInvoiceProps}
                  label="İlçe"
                  onSuggestionSelected={suggestion => {
                    const city = this.props.cities.filter(
                      city => city.id == suggestion.city_id
                    );
                    if (city && city[0])
                      this.setFieldChange("soleInvoice", "city", city[0].name);
                  }}
                  options={countiesGroup || []}
                  optionValue="name"
                  id="county"
                />
                <EkoFormAutosuggest
                  colMd="9"
                  labelMd="3"
                  {...soleInvoiceProps}
                  label="İl"
                  options={this.props.cities || []}
                  optionValue="name"
                  id="city"
                />
                <EkoFormAutosuggest
                  colMd="9"
                  labelMd="3"
                  {...soleInvoiceProps}
                  label="Vergi Dairesi"
                  options={taxOffices || []}
                  id="tax_office"
                />
                <EkoFormInputText
                  {...soleInvoiceProps}
                  colMd="9"
                  labelMd="3"
                  rows="2"
                  label="Açıklama"
                  id="description"
                  type="textarea"
                />

                {this.state.formData.soleInvoice &&
                this.state.formData.soleInvoice.vat_reduction_type_id !== 1 ? (
                  <EkoFormSelect
                    {...soleInvoiceProps}
                    colMd="9"
                    labelMd="3"
                    id="vat_reduction_type_code"
                    optionValue="description"
                    optionId="code"
                    clearable={true}
                    defaultText="Seçiniz"
                    options={vatReductionTypeCodes}
                    label="KDV Tevkifat İşlem Türü"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <div>
                  <div className="col-md-12">
                    <table
                      id="user"
                      className="table table-bordered table-striped"
                    >
                      <tbody>
                        <tr>
                          <td colSpan={2}>Brüt Ücret</td>
                          <td style={{ width: "30%" }}>
                            <span className="text-muted">
                              <EkoFormInputText
                                {...soleInvoiceProps}
                                disabled
                                id="amount"
                                label=""
                                isVertical
                                type="money"
                              />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Stopaj oranı</td>
                          <td>
                            <div className="col-md-12">
                              <EkoFormSelect
                                {...soleInvoiceProps}
                                id="stoppage_type_id"
                                optionValue="name"
                                defaultText="Seçiniz"
                                options={stoppageTypes}
                                colMd={12}
                              />
                            </div>
                          </td>
                          <td>
                            <EkoFormInputText
                              {...soleInvoiceProps}
                              id="stoppage_amount"
                              label=""
                              isVertical
                              type="money"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>KDV Oranı</td>
                          <td>
                            <div className="col-md-12">
                              <EkoFormSelect
                                {...soleInvoiceProps}
                                id="vat_rate_type_id"
                                optionValue="name"
                                defaultText="Seçiniz"
                                options={vatRateTypes}
                                colMd={12}
                              />
                            </div>
                          </td>
                          <td>
                            <EkoFormInputText
                              {...soleInvoiceProps}
                              id="vat_amount"
                              label=""
                              isVertical
                              type="money"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>KDV Tevkifat Oranı</td>
                          <td>
                            <div className="col-md-12">
                              <EkoFormSelect
                                {...soleInvoiceProps}
                                id="vat_reduction_type_id"
                                optionValue="name"
                                defaultText="Seçiniz"
                                options={vatReductionTypes}
                                colMd={12}
                              />
                            </div>
                          </td>
                          <td>
                            <EkoFormInputText
                              {...soleInvoiceProps}
                              id="vat_reduction_amount"
                              label=""
                              isVertical
                              type="money"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>Tahsil Edilen</td>
                          <td>
                            <span className="bold">{totalAmount + " TL"}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/*/row*/}
            {/*/row*/}
          </EkoForm>
        </div>
        <div className="form-actions">
          <div className="buttonProccess" style={{ marginTop: 7 }}>
            <div className="btn-group">
              <EkoFormButtonGroup
                label={this.props.buttonSaveLabel || "Kaydet"}
                faClass="fa fa-plus"
                className="btn blue mt-ladda-btn ladda-button"
                isLoading={this.state.saving}
                buttonsType={"default"}
                onClick={this.onSave.bind(this, true)}
                buttons={[
                  {
                    onClick: this.onSave.bind(this, false),
                    labelText: "Kaydet ve Yeni Ekle"
                  }
                ]}
              />{" "}
              &nbsp;
            </div>
            {/* /btn-group */}
            <button
              className="btn dark btn-outline"
              onClick={this.cancelOperation.bind(this)}
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stoppageTypes: state.data.stoppageTypes,
    vatRateTypes: state.data.vatRateTypes,
    soleProfSalesInvoices: state.data.soleProfSalesInvoices,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    viewOptions: state.data.viewOptions,
    customers: state.data.customersAll,
    cities: state.data.cities,
    counties: state.data.counties,
    taxOffices: state.data.taxOffices,
    vatReductionTypes: state.data.vatReductionTypes
  };
}

export default connect(mapStateToProps, actions)(AddSoleProfSalesInvoice);
