import React, {Component} from 'react'
import {EkoFormButton, EkoFormInputText} from '../../components/elements/EkoForm'
import {toastr} from 'react-redux-toastr'
import {browserHistory} from 'react-router'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Layout from '../../components/layouts/Auth'
import Api from '../../util/Api'

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {forgot: {}},
            saving: false,
            errors: {},
            formFields: {},
        }
    }

    componentWillMount() {
        if (this.props.authenticated) {
            browserHistory.push('/dashboard');
        }
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    onSave() {
        const self = this;
        self.setState({saving: true});
        toastr.removeByType('error')
        toastr.removeByType('success')
        let errors = self.state.errors;
        Api.post('/auth/reset', self.state.formData.forgot, (response, postErrors) => {
            if (postErrors) {
                errors.forgot = postErrors;
                self.setState({saving: false, errors: errors});
            } else {
                toastr.success('Başarılı', response.message)
                self.setState({saving: false, errors: {}, formData: {forgot: {}}});
            }
        })
    }

    render() {
        const forgotProps = {
            onChange: this.setFieldChange.bind(this, "forgot"),
            onMount: this.setFieldMount.bind(this, "forgot"),
            formData: this.state.formData.forgot,
            errors: this.state.errors.forgot
        }

        return (
            <Layout type="register" containerClass=" container-register">
                <div className="rcenter">
                    <p>Şifrenizi mi unuttunuz? E-postanızı yazın, şifrenizi sıfırlamanız için link gönderelim..</p>
                </div>
                <div className="input-icon">
                    <i className="fa fa-envelope"/>
                    <EkoFormInputText {...forgotProps}
                                      id="email"
                                      style={{paddingLeft: "33px"}}
                                      label=""
                                      placeHolder="E-posta"
                                      isVertical={true}/>
                </div>
                <div className="form-actions">
                    <EkoFormButton label={'Gönder'}
                                   className={'g-recaptcha btn blue pull-right'}
                                   isLoading={this.state.saving}
                                   onClick={this.onSave.bind(this)}
                    />
                </div>
                <br/>
                <br/>
                <br/>
            </Layout>
        )
    }

}

function mapStateToProps(state) {
    return {authenticated: state.auth.authenticated};
}

export default connect(mapStateToProps, actions)(Forgot);