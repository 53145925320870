import React from 'react';
import Api from '../../util/Api';


class HocPermission extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
             visible : false 
        }
    }

    componentDidMount() {
        Api.checkPermission(this.props.pkey,(res,err)=>{
            if(res.permission){
                this.setState({visible:true})
            }
        })
    }


    render(){
        const {visible} = this.state
        return  visible ? this.props.children : null
    }
    
}

export default HocPermission