import React, { Component } from "react";
import { EkoFormInputText } from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import EkoSpinner from "../../components/elements/EkoSpinner";
import * as actions from "../../actions";
import { connect } from "react-redux";
import CreateXml from "../../components/dashboard/CreateXml";

class StatementPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      errors: {},
      formFields: {},
      name: "",
      show: false,
      due: 0,
      company: {}
    };
  }

  componentWillMount() {
    const self = this;
    const due = JSON.parse(localStorage.getItem("statement_selected") || "{}");
    let url = localStorage.getItem("statement_preview_url");

    let title = localStorage.getItem("statement_preview_title");
    if (!url || !title) this.props.router.push("/dashboard/statements");
    else {
      Api.get("/company", (response, error) => {
        if (response) {
          let lastname = response.company.lastname
            ? " " + response.company.lastname
            : "";
          let firstname = response.company.firstname
            ? response.company.firstname
            : "";
          let name = firstname + lastname;

          self.setState({
            company: {
              firstname: firstname,
              lastname: lastname,
              email: response.company.email,
              phone: response.company.phone,
              tax_identity_number: response.company.tax_identity_number,
              sgk_code: response.company.sgk_code,
              tax_office_code: response.company.tax_office_code
            }
          });

          Api.get("/" + url, (response, error) => {
            if (error) this.props.router.push("/dashboard/statements");
            else {
              let result = response.KDV;
              Object.keys(result).forEach(key => {
                if (typeof result[key] === "number") {
                  // gelen değerleri formatlıyorum numberlari stringe ceviriyorum
                  if (
                    Number(result[key]) === result[key] &&
                    result[key] % 1 === 0
                  )
                    result[key] += ",00";
                  else
                    result[key] = result[key].toString().replace(/[#.]/g, ",");
                } else if (typeof result[key] === "object") {
                  Object.keys(result[key]).forEach(key2 => {
                    if (result[key][key2].length !== 0) {
                      result[key][key2].forEach((item, i) => {
                        Object.keys(result[key][key2][i]).forEach(key3 => {
                          let val = result[key][key2][i][key3];
                          if (typeof val === "number") {
                            // gelen değerleri formatlıyorum numberlari stringe ceviriyorum
                            if (Number(val) === val && val % 1 === 0)
                              result[key][key2][i][key3] += ",00";
                            else
                              result[key][key2][i][
                                key3
                              ] = val.toString().replace(/[#.]/g, ",");
                          }
                        });
                      });
                    }
                  });
                }
              });
              result["tecilKDV"] = "0,00"; // default gelenler
              result["iadeKDV"] = "0,00";
              result["matrahaDahilOlmayan"] = "0,00";
              self.setState({
                show: true,
                formData: { preview: result },
                name: name
              });
            }
          });
        }
      });
      let formData = this.props.formData || this.defaultFormData();
      let errors = {};
      this.setState({ formData: formData, errors: errors, due: due.id || 0 });
    }
  }

  defaultFormData() {
    return {
      preview: {}
    };
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {}

  get inputs() {
    return [
      { name: "company", value: this.state.company || {} },
      { name: "due", value: this.state.due },
      {
        name: "data",
        value: {
          KDV: this.state.formData.preview || {}
        }
      }
    ];
  }

  render() {
    const previewProps = {
      onChange: this.setFieldChange.bind(this, "preview"),
      onMount: this.setFieldMount.bind(this, "preview"),
      formData: this.state.formData.preview,
      errors: this.state.errors.preview
    };
    if (!this.state.show) return <EkoSpinner />;
    let preview = this.state.formData.preview || [];
    let tevkifatUygulanmayanlar =
      preview.tevkifatUygulanmayanlar &&
      preview.tevkifatUygulanmayanlar.tevkifatUygulanmayan
        ? preview.tevkifatUygulanmayanlar.tevkifatUygulanmayan
        : [];
    let kismiTevkifatUygulananlar =
      preview.kismiTevkifatUygulananlar &&
      preview.kismiTevkifatUygulananlar.kismiTevkifatUygulanan
        ? preview.kismiTevkifatUygulananlar.kismiTevkifatUygulanan
        : [];
    let indirilecekKDVODler =
      preview.indirilecekKDVODler &&
      preview.indirilecekKDVODler.indirilecekKDVOD
        ? preview.indirilecekKDVODler.indirilecekKDVOD
        : [];
    let indirimler =
      preview.indirimler && preview.indirimler.indirim
        ? preview.indirimler.indirim
        : [];
    let digerIslemler =
      preview.digerIslemler && preview.digerIslemler.digerIslem
        ? preview.digerIslemler.digerIslem
        : [];

    return (
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="col-md-3 pull-left">
            <h4 className="font-dark">
              <span
                className="caption-subject bold"
                style={{ textTransform: "uppercase" }}
              >
                {localStorage.getItem("statement_preview_title")}
              </span>
            </h4>
          </div>

          <div className="col-md-3 pull-right">
            <CreateXml
              action={
                process.env.REACT_APP_API_URL + "/xml/CompanyFilingSummary"
              }
              loading={this.state.show}
              inputs={this.inputs}
            />
          </div>
        </div>
        <div className="col-md-1" />
        <div className="portlet-title col-md-10">
          <div style={{ width: "100%" }} className="caption font-dark">
            <div className="col-md-6">
              <span
                className="caption-subject bold uppercase"
                style={{ fontSize: "16px !important" }}
              >
                {this.state.name.substr(0, 50)}
              </span>
            </div>
            <div className="col-md-6">
              <span className={"pull-right"}>
                Ödenmesi Gereken KDV:{" "}
                {this.state.formData.preview.odenmesiGerekenKDV}
              </span>
            </div>
          </div>

          {/*<div className="actions"><span*/}
          {/*className="bold">Vergilendirme Dönemi: 201704&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
          {/*<div className="btn-group btn-group-devided" data-toggle="buttons">*/}
          {/*<button type="button" className="btn red bg-red-thunderbird btn-sm"><i*/}
          {/*className="fa fa-file"/> PAKET İNDİR*/}
          {/*</button>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
        <div className="portlet-body">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <table className="beyanname table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    className="fcolumn bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={3}
                  >
                    TEVKİFAT UYGULANMAYAN İŞLEMLER
                  </th>
                </tr>
                <tr className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    Matrah
                  </th>
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    KDV Oranı
                  </th>
                  <th
                    width="30%"
                    className="fcolumn bg-red-thunderbird bg-font-red-thunderbird"
                  >
                    Vergi
                  </th>
                </tr>
              </thead>
              <tbody>
                {tevkifatUygulanmayanlar.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th>{item.matrah}</th>
                      <th>{item.oran}</th>
                      <th>{item.vergi}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Vergi Toplamı</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="vergiToplami"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    className="fcolumn bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={5}
                  >
                    KISMİ TEVKİFAT UYGULANAN İŞLEMLER
                  </th>
                </tr>
                <tr className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    İşlem Türü
                  </th>
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    Matrah
                  </th>
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    KDV Oranı
                  </th>
                  <th className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                    Tevkifat Oranı
                  </th>
                  <th
                    width="30%"
                    className="fcolumn bg-red-thunderbird bg-font-red-thunderbird"
                  >
                    Vergi
                  </th>
                </tr>
              </thead>
              <tbody>
                {kismiTevkifatUygulananlar.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th>{item.kismiTevkifatUygulananIslemTuru}</th>
                      <th>{item.matrah}</th>
                      <th>{item.oran}</th>
                      <th>{item.tevkifatOrani}</th>
                      <th>{item.vergi}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Vergi Toplamı</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="kismiVergiToplami"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    className="bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={3}
                  >
                    DİĞER İŞLEMLER
                  </th>
                </tr>
                <tr className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                  <th>İşlem Türü</th>
                  <th>Matrah</th>
                  <th width="30%">Vergi</th>
                </tr>
              </thead>
              <tbody>
                {digerIslemler.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th>{item.islemTuru}</th>
                      <th>{item.matrah}</th>
                      <th>{item.vergi}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Vergi Toplamı</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="digerVergiToplami"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Toplam Matrah</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="toplamMatrah"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">Hesaplanan Katma Değer Vergisi</th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="hesaplananKDV"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">
                    Daha Önce İndirim Konusu Yapılan KDV'nin İlavesi
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="ilaveEdilecekKDV"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">Toplam Katma Değer Vergisi</th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="toplamKDV"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    className="bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={3}
                  >
                    İNDİRİMLER
                  </th>
                </tr>
                <tr className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                  <th colSpan="2">İndirim Türü</th>
                  <th width="30%">Vergi</th>
                </tr>
              </thead>
              <tbody>
                {indirimler.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th colSpan="2">{item.indirimTuru}</th>
                      <th>{item.vergi}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">İndirimler Toplamı</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="indirimlerToplami"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    className="bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={3}
                  >
                    BU DÖNEME AİT İNDİRİLECEK KDV TUTARININ ORANLARA GÖRE
                    DAĞILIMI
                  </th>
                </tr>
                <tr className="fcolumn bg-red-thunderbird bg-font-red-thunderbird">
                  <th>KDV Oranı</th>
                  <th>Alınan Mal ve Hizmete Ait Bedel</th>
                  <th width="30%">KDV Tutarı</th>
                </tr>
              </thead>
              <tbody>
                {indirilecekKDVODler.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th>{item.oran}</th>
                      <th>{item.bedel}</th>
                      <th>{item.KDVTutari}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Toplam</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="indirilecekKDVODToplami"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="beyanname table">
              <tbody>
                <tr>
                  <th className="left">Tecil Edilecek Katma Değer Vergisi</th>
                  <th width="20%">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="tecilKDV"
                    />
                  </th>
                  {/*0 gelecek*/}
                </tr>
                <tr>
                  <th className="left">Ödenmesi Gereken Katma Değer Vergisi</th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="odenmesiGerekenKDV"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">
                    İade Edilmesi Gereken Katma Değer Vergisi
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="iadeKDV"
                    />
                  </th>
                  {/*0 gelecek*/}
                </tr>
                <tr>
                  <th className="left">
                    Sonraki Döneme Devreden Katma Değer Vergisi
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="sonrakiDonemeDevredenKDV"
                    />
                  </th>
                </tr>
                <tr>
                  <th
                    className="bg-grey-cascade bg-font-grey-cascade text-center"
                    colSpan={2}
                  >
                    Diğer Bilgiler
                  </th>
                </tr>
                <tr>
                  <th className="left">
                    Özel Matrah Şekline Tabi İşlemlerde Matraha Dahil Olmayan
                    Bedel
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="matrahaDahilOlmayan"
                    />
                  </th>
                  {/*0 gelecek*/}
                </tr>
                <tr>
                  <th className="left">
                    Teslim ve Hizmetlerin Karşılığını Teşkil Eden Bedel (Aylık)
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="teslimVeHizmetleriTeskilEdenBedelAylik"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">
                    Teslim ve Hizmetlerin Karşılığını Teşkil Eden Bedel
                    (Kümülatif)
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="teslimVeHizmetleriTeskilEdenBedelKumulatif"
                    />
                  </th>
                </tr>
                <tr>
                  <th className="left">
                    Kredi Kartı İle Tahsil Edilen Teslim ve Hizmetlerin KDV
                    Dahil Karşılığını Teşkil Eden Bedel
                  </th>
                  <th className="right">
                    <EkoFormInputText
                      {...previewProps}
                      type="money"
                      className="form-control3"
                      label=""
                      colMd={12}
                      id="krediKarti"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions,
    statementDues: state.data.statementDues,
    statementTypes: state.data.statementTypes
  };
}

export default connect(mapStateToProps, actions)(StatementPreview);
