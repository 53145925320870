import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import PropTypes from "prop-types";
import Loading from "react-loading-spinner";
import EkoSpinner from "../../components/elements/EkoSpinner";
import "./Promotion.css";
import moment from "moment";
import { withRouter } from "react-router";
import {FETCH_VIDEO_CONFIGS, FETCH_VIDEO_DATAS} from "../../actions/types";

class PromotionPhoneModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { promotion: {} },
      saving: false,
      errors: {},
      formFields: {},
      step: 0,
      second: 120,
      imageLoaded: undefined,
      dealerId:0
    };
  }

  componentWillMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user.company_id);
    Api.get("/eInformation/show/"+user.company_id.toString(), (response, err) => {
      if (response) {
        if(!response.eInformation.dealer){
          this.setState({dealerId:0});
        }else{
          this.setState({dealerId:response.eInformation.dealer});
        }

      }
    });
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  closeModal() {
    this.setState({ saving: false, formData: { promotion: {} } });
    this.props.closeModal();
  }

  onSave() {
    const self = this;
    let params = {
      code: this.state.formData.promotion.code,
      phone: this.state.formData.promotion.phone.replace(/[#() -]/g, "")
    };

    if (!this.state.formData.promotion.code) {
      let errors = { promotion: { code: ["Kod alanı boş bırakılamaz."] } };
      self.setState({ errors: errors });
    } else {
      Api.post("/promotion/applyCode", params, (response, err) => {
        if (response && response.status === "ok") {
          this.closeModal();
          toastr.success(
            "Başarılı",
            "Promosyon kodunuz hesabınıza başarıyla eklendi"
          );

          let user = JSON.parse(localStorage.getItem("user"));
          user = { ...user, ...response.user };

          localStorage.setItem("user", JSON.stringify(user));

          setTimeout(function() {
            window.location = "/dashboard?isRedirected=true";
          }, 1500);
        } else toastr.error("Hata", response.message || "İşlem başarısız!");
      });
    }
  }

  renderStep = () => {
    const promotionProps = {
      onChange: this.setFieldChange.bind(this, "promotion"),
      onMount: this.setFieldMount.bind(this, "promotion"),
      formData: this.state.formData.promotion,
      errors: this.state.errors.promotion
    };

    if (this.state.step === 0) {
      return this.renderAlertContent();
    }

    if (this.state.step === 1) {
      return this.renderPhoneContent(promotionProps);
    }

    if (this.state.step === 2) {
      return this.renderCodeContent(promotionProps);
    }
  };

  sendCode() {
    const phone = this.state.formData.promotion.phone
      ? this.state.formData.promotion.phone.replace(/[#() -]/g, "")
      : "";

    this.setState(
      {
        saving: true
      },
      () => {
        Api.post("/promotion/sendCode", { phone: phone }, (response, err) => {
          if (!err) {
            if (response.status === "ok") {
              this.setState(
                oldState => ({
                  step: 2,
                  saving: false,
                  second: oldState.second + 0.0000001,
                  imageLoaded: undefined
                }),
                () => {
                  toastr.success(
                    response.message || "SMS Başarıyla Gönderildi"
                  );
                }
              );
            } else {
              this.setState(
                {
                  saving: false
                },
                () => {
                  if (response.closeModal && response.closeModal === true) {
                    toastr.error(response.message);

                    this.closeModal();
                  } else {
                    toastr.warning(response.message);
                    this.setState({
                      imageLoaded: true
                    });
                  }
                }
              );

              //
            }
          } else {
            const errors = { promotion: err };

            this.setState({
              saving: false,
              errors: errors
            });
          }
        });
      }
    );
  }

  markAsRejected = () => {
    Api.get("/promotion/markAsRejected", (response, err) => {
      // todo: kontroller yapılacak
    });

    this.closeModal();
  };

  onFreeTrial = () => {
    Api.post(
      "/companyTrialFromMobile",
      {
        expiry_date: moment()
          .add(14, "days")
          .format("YYYY-MM-DD"),
        plan_id: 4
      },
      (res, err) => {
        if (res.status === "ok") {
          const user = JSON.parse(localStorage.getItem("user"));
          user.plan_id = 4;
          localStorage.setItem("user", JSON.stringify(user));
          this.props.router.push("/dashboard");
        } else {
          console.log(err);
        }
      }
    );
  };

  renderAlertContent = () => {
    if(this.state.dealerId.length>1){
      return (
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-ms-12" style={{fontWeight:'bold'}}>
              <div style={{height:75}}></div>
              <p style={{fontSize:19}}>e-Fatura gönderirken cari hesaplarınızı da tutmaya devam etmek istiyorsanız<br />lütfen bayiniz ile iletişime geçiniz.</p>
              <div style={{height:220}}></div>
            </div>
          </div>
      );
    }else{
    return (
      <div className="row" style={{ textAlign: "center" }}>
        <div className="arrow">
          <div className="arrow__body"></div>
        </div>
        <div className="col-sm-12" style={{ borderRight: "1px solid black" }}>
          <p style={{ fontSize: "18px" }}>14 gün ücretsiz dene</p>
          <h2>Özellikler</h2>
          <p>Alacak Takibi</p>
          <p>Ödeme Takibi</p>
          <p>Stok Takibi</p>
          <p>Kredi Takibi</p>
          <p>Çek/Senet Takibi</p>

          <p style={{ color: "blue", fontWeight: "bold" }}>Avantajlar</p>
          <p>
            <s>Sanal Pos</s>
          </p>
          <p>
            <s>App de 3 ay premium üyelik</s>
          </p>
          <p>
            <s>3 ay boyunca sınırsız destek</s>
          </p>
          <EkoFormButton
            className={"btn btn-warning"}
            onClick={this.onFreeTrial}
            label={"Seç"}
            style={{
              backgroundColor: "transparent",
              borderColor: "black",
              color: "black"
            }}
          />
        </div>
        <div className="col-sm-6" style={{display:"none"}}>
          <p
            style={{
              fontSize: "18px",
              border: "2px darkblue solid",
              borderRadius: "4px"
            }}
          >
            <s>269TL</s> yerine 3 ay boyunca sadece 33TL
          </p>
          <h2>Özellikler</h2>
          <p>Alacak Takibi</p>
          <p>Ödeme Takibi</p>
          <p>Stok Takibi</p>
          <p>Kredi Takibi</p>
          <p>Çek/Senet Takibi</p>

          <p style={{ color: "blue", fontWeight: "bold" }}>Avantajlar</p>
          <p>Sanal Pos</p>
          <p>App de 3 ay premium üyelik</p>
          <p>3 ay boyunca sınırsız destek</p>
          <EkoFormButton
            label={"3 Ay Kullan"}
            onClick={() => {
              window.location.href = "/";
            }}
            className="btn btn-warning"
            style={{
              backgroundColor: "transparent",
              borderColor: "orange",
              color: "orange"
            }}
          />
        </div>
        <div style={{ fontSize: 1 }}> .</div>

        <div style={{ paddingTop: "20px", color: "darkred" }}>
          <p>
            <b>ÖNEMLİ: Bu teklif tekrar karşınıza çıkmayacaktır.</b>
          </p>
          <p>
            <b>
              {" "}
              ** İşinize yaramadığını düşünürseniz 90 gün içinde %100 paranızı
              iade alabilirsiniz
            </b>
          </p>
        </div>
      </div>
    );
    }
  };

  renderCodeContent = promotionProps => {
    return (
      <EkoForm formId={"promototion_code_form"}>
        <div className={"row"}>
          <div
            className="col-md-12 col-sm-12"
            style={{
              height: "100%"
            }}
          >
            <div
              className="pull-left col-md-4"
              style={{
                marginLeft: 60
              }}
            >
              <img
                src="app/images/aktivasyon_kodu.png"
                width={280}
                height={360}
                alt=""
              />
            </div>
            <div
              className="col-md-5"
              style={{
                marginTop: 100,
                marginLeft: 20
              }}
            >
              <div
                style={{
                  fontSize: 30,
                  textAlign: "center"
                }}
              >
                <span>Telefonunuza gelen</span>
                <br />

                <span
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  Aktivasyon Kodunu
                </span>
                <br />

                <span>&nbsp; giriniz!</span>
              </div>
              <div
                style={{
                  marginLeft: 45,
                  marginTop: 15
                }}
              >
                <EkoFormInputText
                  {...promotionProps}
                  id={"code"}
                  placeHolder={"Aktivasyon Kodu"}
                  type={"text"}
                  textProps={{
                    onKeyPress: this.enterPressed(this.onSave.bind(this))
                  }}
                  style={{ height: "40px", width: 250 }}
                  tooltip={true}
                  label={""}
                  //  isVertical={true}
                />

                <div
                  className="pull-right"
                  style={{
                    marginRight: 25
                  }}
                >
                  <EkoFormButton
                    isLoading={this.state.saving}
                    label={"Yeniden Gönder"}
                    className={"btn btn-borderless"}
                    onClick={() => {
                      this.setState(
                        {
                          step: 1,
                          imageLoaded: false
                        },
                        this.sendCode.bind(this)
                      );
                    }}
                  />
                  <EkoFormButton
                    isLoading={this.state.saving}
                    label={"Uzat"}
                    style={{
                      width: 100
                    }}
                    onClick={this.onSave.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </EkoForm>
    );
  };

  renderPhoneContent = promotionProps => {
    return (
      <EkoForm formId={"promototion_phone_form"}>
        <div className={"row"}>
          <div
            className="col-md-12 col-sm-12"
            style={{
              height: "100%"
            }}
          >
            <div
              className="pull-left col-md-4"
              style={{
                marginLeft: 60
              }}
            >
              <img
                src="app/images/adam-sizeozel.png"
                width={280}
                height={360}
                alt=""
              />
            </div>
            <div
              className="col-md-5"
              style={{
                marginTop: 85,
                marginLeft: 20
              }}
            >
              <div
                style={{
                  fontSize: 30,
                  textAlign: "center"
                }}
              >
                <span
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  Uzatma
                </span>
                <span>
                  &nbsp;onay kodunun gönderileceği telefon numarasını lütfen
                  giriniz.
                </span>
              </div>
              <div
                style={{
                  marginLeft: 45,
                  marginTop: 15
                }}
              >
                <EkoFormInputText
                  {...promotionProps}
                  id={"phone"}
                  type="mask"
                  tooltip={true}
                  isVertical={true}
                  style={{ height: "40px", width: 250 }}
                  mask="(999) 999-99-99"
                  iconClass={"icon-screen-smartphone"}
                  placeHolder={"Telefon numarası"}
                  label=""
                />

                <div
                  className="pull-right"
                  style={{
                    marginRight: 25
                  }}
                >
                  <EkoFormButton
                    label={"Gönder"}
                    isLoading={this.state.saving}
                    onClick={this.sendCode.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </EkoForm>
    );
  };

  getTitle = () => {
    if (this.state.step === 0) {
      return "Deneme Süreniz Bitmiştir!";
    }

    if (this.state.step === 1) {
      return "Tek Seferlik Teklif";
    }

    return "Aktivasyon Kodunu Giriniz";
  };

  enterPressed = action => {
    return event => {
      var code = event.keyCode || event.which;

      if (code === 13) {
        //13 is the enter keycode
        event.preventDefault();
        //Do stuff in here
        action();
      }
    };
  };

  render() {
    return (
      <EkoModal
        headerStyle={{
          textAlign: "right"
        }}
        titleStyle={{
          color: "darkred",
          fontWeight: "700",
          fontSize: 23,
          marginRight: 35,
          textAlign:this.state.dealerId.length>1?"center":""

        }}
        bsSize={"large"}
        showModal={this.props.showModal}
        title={this.state.dealerId.length>1?"14 gün kullanım süreniz dolmuştur.":"Tek Seferlik Teklif"}
        backdropStyle={{
          backgroundImage: 'url("/app/images/subscribe2.png")',
          backgroundRepeat: "no-repeat",
          opacity: 1,
          backgroundPosition: "center",
          backgroundSize: "stretch"
        }}
        onHide={this.markAsRejected.bind(this)}
        spinner={this.state.saving}
        disableCloseButton
      >
        {this.state.imageLoaded === false ? (
          <Loading
            isLoading={true}
            loadingClassName="loading-spinner"
            spinner={EkoSpinner}
          />
        ) : (
          this.renderStep()
        )}
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

PromotionPhoneModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(PromotionPhoneModal));
