import React, {Component} from 'react';
import AddPayroll from "./AddPayroll";
import * as actions from '../../actions';
import {connect} from 'react-redux';
import moment from 'moment'

class AddOrUpdatePayroll extends Component {

    constructor(props) {
        super(props);

        this.getFistTabRows.bind(this);
        this.getSecondTabRows.bind(this);
        this.getThirdTabRows.bind(this);
        this.getDefaultTabs.bind(this);

    }

    getDefaultFormData = () => {
        const nowDate = moment().subtract(1, 'month');
        const nowYear = nowDate.year();
        const nowMonth = nowDate.month() + 1;


        return {
            month: nowMonth,
            year: nowYear,
            is_sgk_discount_applied: 0,
        };

    };

    getFistTabRows() {

        const nowDate = new Date();
        const nowYear = nowDate.getUTCFullYear() - 1;
        let nowMonth = nowDate.getUTCMonth();

        const salary_type = this.props.viewOptions && this.props.viewOptions.employees
            ? this.props.viewOptions.employees.salary_type : [];


        const years = this.props.viewOptions && this.props.viewOptions.employee_salaries
            ? this.props.viewOptions.employee_salaries.year : [];

        const months = this.props.viewOptions.general.months;

        const yesOrNo = this.props.viewOptions ? this.props.viewOptions.general.yesOrNo : [
            {
                name: 'Birini Seçiniz',
                id: null,
            },

            {
                name: 'Evet',
                id: 1,
            },
            {
                name: 'Hayır',
                id: 2
            }
        ];

        return {
            columns: [
                [
                    [

                        {
                            name: 'month',
                            label: 'Ay',
                            type: 'select',
                            defaultValue: nowMonth,
                            values: months,
                        }
                    ],

                    [
                        {
                            name: 'year',
                            label: 'Yıl',
                            type: 'select',
                            values: years,
                            defaultValue: nowYear,
                        }

                    ],
                    [
                        {
                            name: 'is_sgk_discount_applied',
                            label: 'Sigorta İndirimi Uygula',
                            type: 'select',
                            values: yesOrNo
                        }
                    ],

                    [
                        {
                            name: 'income_cumulative_tax',
                            label: 'Kumulatif Gelir Vergisi',
                            type: 'money',
                            visible: (formData, validated) => validated,
                            placeholder: "0",
                        }
                    ],

                    [
                        {
                            name: 'income_cumulative_matrah',
                            label: 'Kumulatif Gelir Matrahı',
                            type: 'money',
                            visible: (formData, validated) => validated,
                            placeholder: "0",

                        }
                    ]

                ],

                [
                    [
                        {
                            name: 'is_min_salary',
                            label: 'Asgeri Ücret Mi?',
                            type: 'select',
                            defaultValue: 1,
                            disabled: true,
                            values: yesOrNo,
                        }
                    ],
                    [
                        {
                            name: 'mlc_in_net',
                            label: 'Net Ücrete AGI dahil mi?',
                            type: 'select',
                            defaultValue: 1,
                            disabled: true,
                            values: yesOrNo,

                        }
                    ],
                    [
                        {
                            name: 'salary_type',
                            label: 'Ücret Tipi',
                            type: 'select',
                            values: salary_type,
                            disabled: true,
                        }
                    ],
                    [
                        {
                            name: 'salary',
                            label: 'Maaş',
                            type: 'text',
                            disabled: true,
                            defaultValue: 0,
                        }
                    ],
                ],
            ]
        };

    }

    getThirdTabRows() {
        return [

            [
                {
                    name: 'financial_giveaway',
                    label: 'İkramiye',
                    type: 'money',
                    placeholder: "0"
                }
            ],
            [
                {
                    name: 'insurance_by_employee',
                    label: 'Özel Sigortalar(Çalışan)',
                    type: 'money',
                    placeholder: "0"

                }
            ],
            [
                {
                    name: 'insurance_by_employer',
                    label: 'Özel Sigortalar(İşveren)',
                    type: 'money',
                    placeholder: "0"

                }
            ],
            [
                {
                    name: 'additional_subj_sgk',
                    label: 'Sgk Artiran Diğer Gelirler',
                    type: 'money',
                    placeholder: "0"
                }
            ],

            [
                {
                    name: 'additional_excl_sgk',
                    label: 'Sgk Artirmayan Diğer Gelirler',
                    type: 'money',
                    placeholder: "0"

                }
            ],

            [
                {
                    name: 'deduction',
                    label: 'Özel Kesinti',
                    type: 'money',
                    placeholder: "0"
                }
            ]
        ];
    }

    getSecondTabRows() {

        const offDayReasons = this.props.viewOptions && this.props.viewOptions.employee_salaries ?
            this.props.viewOptions.employee_salaries.off_day_reason : [];

        return {
            columns: [
                [
                    [
                        {
                            name: 'vacation_paid',
                            label: 'Ücretli İzin',
                            type: 'number',
                            placeholder: "0",
                        }
                    ],

                    [
                        {
                            name: 'vacation_unpaid',
                            label: 'Ücretsiz İzin',
                            type: 'number',
                            placeholder: "0",
                        }
                    ],

                    [
                        {
                            name: 'vacation_sihhi',
                            label: 'İstirahat',
                            type: 'number',
                            placeholder: "0",
                        }
                    ],

                    [
                        {
                            name: 'vacation_yearly',
                            label: 'Yıllık İzin',
                            type: 'number',
                            placeholder: "0",
                        }
                    ],

                    [
                        {
                            name: 'off_day_reason',
                            label: 'Eksik Gün Nedeni',
                            type: 'select',
                            values: offDayReasons,
                            visible: (formData) => {

                                return formData.vacation_unpaid > 0 || formData.vacation_sihhi > 0 || formData.vacation_no_reason > 0;
                            }
                        }
                    ]
                ],
                [
                    [
                        {
                            name: 'vacation_no_reason',
                            label: 'Mazaretsiz İzin',
                            type: 'number',
                            placeholder: "0",
                            defaultValue: 0,
                        }
                    ],

                    [
                        {
                            name: 'overtime_weekday',
                            label: 'Fazla Mesai Hafta İçi',
                            type: 'number',
                            placeholder: "0",
                            defaultValue: 0
                        }
                    ],

                    [
                        {
                            name: 'overtime_sunday',
                            label: 'Fazla Mesai Pazar',
                            type: 'number',
                            placeholder: "0",
                            defaultValue: 0
                        }
                    ],

                    [
                        {
                            name: 'overtime_holiday',
                            label: 'Fazla Mesai Resmi Tatil',
                            type: 'number',
                            placeholder: "0",
                            defaultValue: 0,
                        }
                    ]
                ]
            ]
        };
    }

    getDefaultTabs() {
        return [
            {
                title: 'Maaş bilgileri',
                rows: this.getFistTabRows(),
                md: 6
            },
            {
                title: 'Süre Bilgileri',
                rows: this.getSecondTabRows(),
            },
            {
                title: 'Diğer Gelirler',
                rows: this.getThirdTabRows(),
                md: 6
            }
        ]
    }

    render() {
        return (
            <div className="portlet light ">
                <div className="portlet-title">
                    <div className="caption font-dark">
                        <span className="caption-subject bold uppercase">Bordro</span>
                    </div>


                </div>

                <AddPayroll router={this.props.router} tabRows={this.getDefaultTabs()}
                            getDefaultFormData={this.getDefaultFormData}
                            onCancel={() => {
                                this.props.router.push('/dashboard/employee')
                            }}/>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddOrUpdatePayroll);