import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import {FETCH_EXPENSE_CATEGORIES, FETCH_EXPENSE_TEMPLATES} from "../../actions/types";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import Style from "style-it";
import { CSS } from "../../css/tab_datatable";

class ExpenseCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsertModal: false,
            expenseCategories: []
        };
    }
    fetchTemplates() {
        this.props.fetchData("/data/expenseCategories/1", FETCH_EXPENSE_CATEGORIES);
    }

    componentWillMount() {
        this.fetchTemplates();
    }

    openUpdate(selectedRow) {
        this.props.router.push("/dashboard/purchases/expensecategory/edit/" + selectedRow.id);
    }

    openInsert(menuItem) {
        this.props.router.push("/dashboard/purchases/expensecategory/add");
    }

    onDelete(selectedRow) {
        toastr.error("Hata", "Silme işlemi devredışı bırakıldı");
        /*const self = this;
        Api.delete("/payment/" + selectedRow.id, function(response, err) {
            if (response) {
                self.fetchTemplates();

            } else toastr.error("Hata", err.message);
        });*/
    }

    render() {

        return (
            <div>
                {Style.it(
                    CSS,
                    <div>
                        <EkoDataTable
                            selectedTab={1}
                            openInsert={this.openInsert.bind(this)}
                            openUpdate={this.openUpdate.bind(this)}
                            onDelete={this.onDelete.bind(this)}
                            data={this.props.expenseCategories}
                            name={"expensetemplate"}
                        ></EkoDataTable>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        expenseCategories: state.data.expenseCategories
    };
}

export default connect(mapStateToProps, actions)(ExpenseCategory);
