import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoSpinner from "../../components/elements/EkoSpinner";
import EkoMoneyText from "../../components/elements/EkoMoneyText";
import AddLoan from "./AddLoan";
import { toastr } from "react-redux-toastr";
import HelperContainer from "../../util/HelperContainer";
import moment from "moment";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
//import EkoToastrError from '../../components/elements/EkoToastrError'
import { FETCH_LOANS } from "../../actions/types";
import { browserHistory } from "react-router";
import LoansManagementModal from "./LoansManagementModal";

class Loans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showInsertModal: false,
      errors: {},
      validationFields: [],
      loans: [],
      updateBankBalance: 0,
      updateLoanBalance: 0,
      balance: "",
      balanceLoading: false,
      showLoansModal: false,
      loanId: 0,
    };
  }

  fetchLoans() {
    this.props.fetchData("/loan/list", FETCH_LOANS);
  }

  componentWillMount() {
    this.fetchLoans();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loans) {
      this.setState({ loans: nextProps.loans });
    }
  }

  openUpdate(id) {
    var self = this;
    let formData = {};
    Api.get("/loan/" + id, function(response) {
      formData = {};
      if (response.loan) {
        formData.loan = HelperContainer.filterObject(response.loan);
        try {
          formData.loan.amount_fc = Util.convertDecimalToMoney(
            formData.loan.amount
          );
          formData.loan.interest_rate_total =
            "%" +
            Util.convertDecimalToMoney(
              (formData.loan.interest_rate_total || 0) * 100
            );
          formData.loan.interest_rate_monthly =
              "%" +
              Util.convertDecimalToMoney(
                  (formData.loan.interest_rate_monthly || 0) * 100
              );
          formData.loan.currency_rate = formData.loan.currency_rate
            .toString()
            .replace(/[#.]/g, ",");
          formData.loan.start_date = moment(
            formData.loan.start_date,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
          formData.loan.name = formData.loan.description;

          formData.loan.bank_account_type = 5;
        } catch (ex) {
          console.log(ex);
        }
        self.setState({
          showInsertModal: true,
          showLoansModal: false,
          errors: {},
          formData: formData
        });
      }
    });
  }

  onDelete(id) {
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        Api.delete("/loan/" + id, function(response, err) {
          if (response) {
            self.fetchLoans();
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  balanceChange(value) {
    this.setState({ balance: value });
  }

  updateBalance(type, btntype, id) {
    const self = this;
    let updateBlance = btntype !== 0 ? 0 : id;
    if (btntype === 0) {
      self.setState({
        blanceLoading: true,
        balance: "",
        updateLoanBalance: updateBlance,
        updateBankBalance: 0
      });
      let formData = {};
      Api.get("/loan/" + id, function(response) {
        formData = {};
        if (response.loan) {
          formData.loan = HelperContainer.filterObject(response.loan);

          self.setState({
            formData: formData,
            blanceLoading: false,
            balance: Util.convertDecimalToMoney(formData.loan.balance)
          });
        }
      });
    } else if (btntype === 1) {
      // kaydet
      self.setState({ blanceLoading: true });
      let loan = this.state.formData.loan;
      if (!this.state.balance) {
        self.setState({ blanceLoading: false });
        return toastr.warning("Uyarı", "Bakiye alanını boş bıraktınız");
      }
      loan.balance = Util.convertMoneyToDecimal(this.state.balance);
      loan.start_date = Util.convertFromDatabaseDate(loan.start_date);
      let url =
        type === "bank" ? "/bankAccount/" + loan.id : "/loan/" + loan.id;

      Api.put(url, loan, function(response, postErrors) {
        if (postErrors) toastr.error("Hata", "Bakiye Güncellenemedi");
        else {
          self.fetchLoans();
          toastr.success("Başarılı", "Bakiye Başarıyla Güncellendi");
          self.setState({
            blanceLoading: false,
            updateBankBalance: 0,
            updateLoanBalance: 0,
            balance: ""
          });
        }
      });
    } else {
      this.setState({
        updateBankBalance: updateBlance,
        updateLoanBalance: updateBlance,
        balance: ""
      });
    }
  }

  openInsert(menuItem) {
    this.setState({ showInsertModal: true, formData: false, showLoansModal: false });
  }

  onSave(formData, formFields) {
    this.fetchLoans();
    this.setState({ showInsertModal: false, formData: false, showLoansModal: false });
  }
  openLoansManagement(loanId) {
    let self = this;
    self.setState({showLoansModal: true, loanId: loanId});
  }

  render() {
    if (!this.props.loans) return <EkoSpinner />;
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <LoansManagementModal
              showModal={this.state.showLoansModal}
              passData={{"token": localStorage.getItem("token")}}
              loanId={this.state.loanId}
              onCancel={() => {
                this.setState({showLoansModal: false});
              }}
              errors={this.state.errors}
          />
          <AddLoan
              showModal={this.state.showInsertModal}
              formData={this.state.formData}
              onSave={this.onSave.bind(this)}
              onCancel={() => {
                this.setState({showInsertModal: false});
              }}
              errors={this.state.errors}
          />
          <div className="portlet light">
            <div className="portlet-title2">
              <div className="caption2 font-dark">
                <span className="caption-subject bold uppercase">KREDİLER</span>
              </div>

              {/*<div className="actions2">*/}
              {/*<div className="btn-group btn-group-devided" data-toggle="buttons">*/}
              {/*<button type="button" id="print" className="btn blue btn-sm">Hesaplar Arası Virman*/}
              {/*</button>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="row">
            {this.state.loans.map((data, key) => {
              let bankType = "fa fa-handshake-o";
              return (
                  <div key={key} className="col-md-4 col-sm-4">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div
                            style={{
                              width: "230px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis"
                            }}
                            className="caption"
                        >
                      <span
                          title={data.name}
                          className="caption-subject font-blue-sharp bold uppercase"
                      >
                        {data.name}
                      </span>
                        </div>
                        <div className="actions">
                          <DropdownButton
                              id="drop_down"
                              title="İşlemler"
                              bsStyle="primary"
                              noCaret
                              className="btn blue btn-outline btn-sm"
                          >
                            <MenuItem
                                eventKey="1"
                                onSelect={() => {
                                  this.openUpdate(data.id);
                                }}
                            >
                              Düzenle
                            </MenuItem>
                            <MenuItem
                                eventKey="1"
                                onClick={this.openLoansManagement.bind(this, data.id)}
                            >
                              Ödeme Planı
                            </MenuItem>
                            <MenuItem
                                eventKey="1"
                                onSelect={() => {
                                  browserHistory.push(
                                      "/dashboard/paymentPlans/" + data.id
                                  );
                                }}
                            >
                              Ödeme Hareketleri
                            </MenuItem>
                            {/*<MenuItem eventKey="2">Hesap Hareketleri</MenuItem>*/}
                            <MenuItem
                                eventKey="3"
                                onSelect={() => {
                                  this.onDelete(data.id);
                                }}
                            >
                              Sil
                            </MenuItem>
                          </DropdownButton>
                        </div>
                      </div>
                      <strong>{data.bank_account_type_str}</strong>
                      <br/>
                      <br/>
                      <div className="col-md-10 col-sm-3 col-xs-6">
                        <div className="font-grey-mint font-sm">
                          {" "}
                          Kalan Borç&nbsp;
                          {/*   {
                                                this.state.blanceLoading && this.state.updateLoanBalance === data.id ?
                                                    <i className="fa fa-spinner fa-pulse fa-1x fa-fw"/>
                                                    :
                                                    <span>
                                                        <a onClick={() => this.updateBalance('loan', this.state.updateLoanBalance === data.id ? 1 : 0, data.id)}>
                                                        <strong>{this.state.updateLoanBalance === data.id ? 'Kaydet' : 'Güncelle'}</strong>
                                                        </a>
                                                        &nbsp;{this.state.updateLoanBalance === data.id ?
                                                        <a onClick={() => this.updateBalance('loan', 2, data.id)}><strong>{'İptal'}</strong></a> : ""}
                                                    </span>
                                            }*/}
                        </div>
                        {this.state.updateLoanBalance === data.id &&
                        !this.state.blanceLoading ? (
                            <div className="col-md-8">
                              <EkoMoneyText
                                  thisClass={"form-control"}
                                  value={this.state.balance}
                                  type={"text"}
                                  onChange={this.balanceChange.bind(this)}
                              />
                            </div>
                        ) : (
                            <div
                                style={
                                  parseInt(data.balance, 10) >= 0
                                      ? {color: "#5c9bd1"}
                                      : {color: "red"}
                                }
                                className="uppercase font-hg"
                            >
                              {" "}
                              {data.balance_str}{" "}
                            </div>
                        )}
                      </div>
                      <div className="bank-type">
                        <i className={bankType} aria-hidden="true"/>
                      </div>
                      <div className="clearfix"/>
                    </div>
                  </div>
              );
            })}
            <div className="col-md-4 col-sm-4">
              <div className="portlet light">
                <p className="text-center text-margin">
                  <a
                      className="btn blue btn-outline"
                      data-toggle="modal"
                      onClick={this.openInsert.bind(this)}
                  >
                    <i className="fa fa-plus" aria-hidden="true"/> Ekle
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loans: state.data.loans
  };
}

export default connect(mapStateToProps, actions)(Loans);
