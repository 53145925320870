import React, {Component} from 'react'
import EkoModal from '../../components/elements/EkoModal'
import Util from '../../util/Util';

class ModalTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            formData: {},
            saving: false,
            savingModal: false,
            errors: {},
            formFields: {},
            amount: 0,

        }
    }

    closeModal() {
        this.setState({saving: false,formData:{}});
        this.props.closeModal();
    }

    render() {
        let objects = this.props.fetchApi;
        let sales = this.props.salesApi;
        let kdv = 0;
        
        return (
            <EkoModal
                showModal={this.props.showModal}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.savingModal}
                dialogClassName="transaction-modal"
                title={"Fatura Detayı"}
            >

                <table className="table">

                    <thead>
                        <tr className="transactionOptions">
                            <th style={{width: "12%"}}>Ürün / Hizmet</th>
                            <th style={{width: "30%"}}>Açıklama</th>
                            <th style={{width: "6%"}}>Miktar</th>
                            <th style={{width: "9%"}}>Birim Fyt</th>
                            <th style={{width: "10%"}}>Birim</th>
                            <th style={{width: "7%"}}>İsk. %</th>
                            <th style={{width: "7%"}}>İsk. Tutar</th>
                            <th style={{width: "8%"}}>KDV %</th>
                            <th style={{width: "9%"}}>KDV</th>
                            <th style={{width: "14%"}}>Toplam Tutar</th>
                        </tr>
                    </thead>

                    <tbody>

                        {Object.values(objects).map((item, i)=>{

                            if(item.vat_rate_type_id === 1)
                                kdv = "KDVsiz";
                            else if(item.vat_rate_type_id === 2)
                                kdv = "1%";
                            else if(item.vat_rate_type_id === 3)
                                kdv = "8%";
                            else if(item.vat_rate_type_id === 4)
                                kdv = "18%";
                            else if(item.vat_rate_type_id === 5)
                                kdv = "10%";
                            else {
                                kdv = "20%";
                            }
                            return(
                                <tr key={i} className="transactionOptions">
                                    <td>{item.item_code}</td>
                                    <td>{item.description}</td>
                                    <td>{Util.convertDecimalToMoney6(item.quantity)}</td>
                                    <td>{Util.convertDecimalToMoney5(item.unit_price_fc)}</td>
                                    <td>{item.unit_str}</td>
                                    <td>{item.discount_rate}</td>
                                    <td>{item.discount_amount_fc}</td>
                                    <td>{kdv}</td>
                                    <td>{Util.convertDecimalToMoney(item.vat_amount_fc)}</td>
                                    <td>{Util.convertDecimalToMoney(item.total_amount_fc)}</td>
                                </tr>
                            )
                            // <tr>
                            //     <td>{item.id}</td>
                            // </tr>
                        })} 
                       
                    </tbody>
                      
                </table>

                <div className="modalTotal">

                    <table className="tplus">

                        <tbody>

                            <tr>

                                <td>Tutar:</td>
                                <td>{Util.convertDecimalToMoney(sales.price_amount_fc)} {sales.currency === 'EUR' ? "€" : sales.currency === 'USD' ? "$" : sales.currency === 'GBP' ? "£" : sales.currency === 'CHF' ? "CHF" : "₺"}</td>

                            </tr>

                            <tr>

                                <td>İskonto:</td>
                                <td>{Util.convertDecimalToMoney(sales.discount_amount_fc)} {sales.currency === 'EUR' ? "€" : sales.currency === 'USD' ? "$" : sales.currency === 'GBP' ? "£" : sales.currency === 'CHF' ? "CHF" : "₺"}</td>

                            </tr>

                            <tr>

                                <td>Fatura Matrahı:</td>
                                <td>{Util.convertDecimalToMoney(sales.net_amount_fc)} {sales.currency === 'EUR' ? "€" : sales.currency === 'USD' ? "$" : sales.currency === 'GBP' ? "£" : sales.currency === 'CHF' ? "CHF" : "₺"}</td>

                            </tr>

                            <tr>

                                <td>KDV Toplamı:</td>
                                <td>{Util.convertDecimalToMoney(sales.vat_amount_fc)} {sales.currency === 'EUR' ? "€" : sales.currency === 'USD' ? "$" : sales.currency === 'GBP' ? "£" : sales.currency === 'CHF' ? "CHF" : "₺"}</td>

                            </tr>

                            <tr>

                                <td>Genel Toplam:</td>
                                <td>{Util.convertDecimalToMoney(sales.total_amount_fc)} {sales.currency === 'EUR' ? "€" : sales.currency === 'USD' ? "$" : sales.currency === 'GBP' ? "£" : sales.currency === 'CHF' ? "CHF" : "₺"}</td>

                            </tr>

                        </tbody>

                    </table>
                
                </div>

            </EkoModal>
        )
    }
}

export default ModalTransaction;
