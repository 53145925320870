import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import { Modal } from "react-bootstrap";
import { EkoFormButton, EkoForm } from "../elements/EkoForm";
import { Link } from "react-router";
import { toastr } from "react-redux-toastr";

class EInvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      requiredFieldsResponse: {
        status: "",
        message: "",
        requiredInformations: []
      }
    };
  }

  closeModal() {
    this.props.closeModal(false);
  }

  componentDidMount() {
    Api.post("/company/checkCommercialInfo", {}, (res, err) => {
      this.setState({ requiredFieldsResponse: { ...res } });
    });
  }

  renderGoToCompanyInfo() {
    const requiredFields = {
      firstname: "Adı/Firma Ünvanı",
      tax_office_code: "Vergi Dairesi Adı",
      tax_identity_number: "Vergi/TC No ",
      address: "Açık Adres ",
      city_id: "İl",
      county_id: "İlçe"
    };

    // Iterate through keys of requiredFields and filter which doesnt exist in the state.
    const requiredFieldsTranslated = Object.keys(requiredFields)
      .map((key, index) =>
        this.state.requiredFieldsResponse &&
        this.state.requiredFieldsResponse.requiredInformations &&
        this.state.requiredFieldsResponse.requiredInformations.includes(key)
          ? requiredFields[key]
          : null
      )
      .filter(el => !!el);

    return (
      <EkoModal
        showModal={this.props.showModal}
        title="Eksik Bilgi"
        onHide={this.closeModal.bind(this)}
        spinner={false}
      >
        <div className="row">
          <div className={"col-md-12"}>
            <EkoForm>
              {this.state.requiredFieldsResponse.message}
              <p>
                e-Fatura/e-Arşiv gönderebilmeniz için; <br />
                <br />
                <b style={{ color: "red" }}>
                  {requiredFieldsTranslated.join(", ")}
                </b>
                <br />
                <br /> bilgilerinizin dolu olması gerekmektedir. Lütfen Ticari
                Bilgiler sayfasında gerekli alanları doldurunuz.
              </p>
            </EkoForm>
          </div>
        </div>
        <Modal.Footer>
          <br />
          <Link to="/dashboard/companyTradeInfo">
            <EkoFormButton
              label={"Ticari Bilgiler Sayfasına Git"}
              faClass="fa fa-arrow-right"
            />
          </Link>
        </Modal.Footer>
      </EkoModal>
    );
  }

  previewDownload = (previewId) => {
    // this.setState({loading:true})

    window.open(
      process.env.REACT_APP_API_URL +
        "/eInvoicePreviewExport/" +
        previewId +
        "?token=" +
        localStorage.getItem("token"),
      "_blank"
    );

  }

  render() {
    return this.state.requiredFieldsResponse.status === "ok" ? (
      <EkoModal
        dialogClassName={"modal-lg"}
        showModal={this.props.showModal}
        title={"E-Fatura Önizleme"}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.loading}
      >
        <embed
          style={{ width: "100%" }}
          src={
            process.env.REACT_APP_API_URL +
            "/e-invoice-preview?sales_invoice_id=" +
            this.props.previewID
          }
          height="700"
        />

        <Modal.Footer>
            <EkoFormButton
                isLoading={this.state.loading}
                label={"Yazdır"}
                onClick={()=>{this.previewDownload(this.props.previewID)}}
                faClass="fa fa-print"
            />
        </Modal.Footer>
      </EkoModal>
    ) : (
      this.renderGoToCompanyInfo()
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(EInvoicePreview);
