import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import EmployeeList from "./EmployeeList";
import WagesList from "./WagesList";
import PersonnelSalaryAccrual from "../../components/dashboard/personnelSalaryAccrual";
import Util from "../../util/Util";
const EmployeesList = () => {
    const [employeeListLoaded, setEmployeeListLoaded] = useState(false);
    useEffect(() => {
        // Set the employeeListLoaded state to true once the EmployeeList component is loaded
        setEmployeeListLoaded(true);
    }, []);
  return (
      <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light">
              <div className="portlet-title">

                  <div className="row">
                      <div className="col-md-6">
                          <div className="caption font-dark">
                  <span className="caption-subject bold uppercase">
            PERSONEL ve BORDRO YÖNETİMİ
          </span>
                          </div>
                      </div>
                      <div className="col-md-6 text-right">
                          <a href={"/dashboard/PersonnelSalaryAccrual"} className="btn btn-default blue"> Maaş
                              Tahakkuku</a>
                      </div>
                  </div>
              </div>
              <div className="portlet-body">
                  <Tabs defaultActiveKey="employees" id="uncontrolled-tab-example">
                      <Tab eventKey="employees" title="Personel Yönetimi">
                          <EmployeeList/>
                      </Tab>
                      {employeeListLoaded && (
                          <Tab eventKey="wages" title="Bordro Yönetimi">
                              <WagesList/>
                          </Tab>
                      )}
                  </Tabs>
              </div>
              <br/>
          </div>
      </div>
  );
};
export default EmployeesList;