import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import {EkoFormButton, EkoFormDate, EkoFormInputText} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";
import AddOtherTax from "../../components/forms/AddOtherTax";

class IVDTaxPlate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            iframeUrl: "",
        }
    }

    view = () => {
        Api.get("/EArchiveInteraktifVD/IVDTaxPlate", (response, err) => {
            this.setState({iframeUrl:response,openModal:true})
        });
    };
    closeModal() {
        this.setState({openModal: false});
    }
    render() {
        return (
            <div>
                <button onClick={this.view} className="btn success">E-VERGİ LEVHASI İNDİR</button>
                <EkoModal
                    hideSelectButtons
                    showModal={this.state.openModal}
                    onHide={this.closeModal.bind(this)}
                >
                    <div>
                        <iframe style={{"width":"100%","height":"500px","border":"0px"}} src={this.state.iframeUrl}></iframe>
                    </div>
                </EkoModal>
            </div>
        )
    }
}
function mapStateToProps(state) {

}
export default connect(mapStateToProps, actions)(IVDTaxPlate);