import React, { Component } from 'react'
import EkoModal from '../../components/elements/EkoModal'
import { EkoFormRadioButton, EkoFormButton, EkoFormDate } from '../../components/elements/EkoForm'
import Api from '../../util/Api'
import {toastr} from 'react-redux-toastr'

class TradeReconciliationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            formData: { customer: {is_type: 0 }},
            date: {},
            downloadLoading: false,
            uploadLoading: false,
            date1: '',
            date2: '',
            downloadExcel: false,
            detailIsOpened: false,
            formFields: {},
            showModal: false,
            frameUrl: '',
            data: [],
            step:0
        }
    }
    onMount() {
        this.fetch(this.props.importId,this.props.type)
    }

    fetch(id,type) {
        var url = "/shareCustomerTransaction/get/"+id+"/"+type;
        Api.get(url, (response, err) => {
            if (err !== false) {

                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                console.log('response.data',response.data);
                this.setState({data:response.data });
            }
        });
    }
    closeModal() {
        this.props.closeModal();
        this.setState({ step: 0, frameUrl: ""})
    }


    onsave() {
        if(
            this.state.date1 !== '' &&
            this.state.date2 !== ''
        ){
            var date1 = this.state.date1;
            var date2 = this.state.date2;
            var user = JSON.parse(localStorage.getItem('user'));

            var url = "/shareCustomerTransaction/"+user.company_id+"/"+this.props.importId+"/"+this.props.type+"/"+date1+"/"+date2;
            Api.get(url, (response, err) => {
                if (err !== false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    toastr.success("Başarılı!", "Mutabakat İşlemi Oluşturuldu");
                    this.fetch(this.props.importId,this.props.type);
                }
            });
            }else{
                    toastr.error("Hata!", "Tarih Seçiniz");
                }
        }

    back() {
        this.setState({ step: 0, frameUrl: ""})
    }
    setFieldChange(type, field, formattedValue) {
        const self = this;
        if (type === "customer" && field === "start_date") {
            let newDate1 = formattedValue;
            self.setState({ date1: newDate1 })
        } else if (type === "customer" && field === "end_date") {
            let newDate2 = formattedValue;
            self.setState({ date2: newDate2 })
        }

    }
    view(link){
        let url = process.env.REACT_APP_API_URL.substr(
            0,
            process.env.REACT_APP_API_URL.length - 3
        );
        this.setState({ step: 1, frameUrl: url+"shareCustomerTransaction/"+link+"/99"})
    }
    send(link){
        var url = "/shareCustomerTransaction/sendMail/"+link;
        Api.get(url, (response, err) => {
            if (err !== false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            }else if (response.message && response.message !== undefined) {
                toastr.error("Hata!", response.message);
            } else {
                toastr.success("Başarılı!", "Mutabakat Epostası Gönderildi");
            }
        });
    }
    render() {

        const detailProps = {
            onChange: this.setFieldChange.bind(this, "customer"),
            onMount: this.onMount.bind(this),
            formData: this.state.formData.customer,
        }

        return (
            <div>
                <EkoModal
                    showModal={this.props.showModal}
                    title={"Mutabakat İşlemleri"}
                    bsSize="xlg"
                    onHide={this.closeModal.bind(this)}
                    spinner={this.state.saving}>
                    {this.state.step === 0 ? <div>
                            <div className="row" style={{"paddingLeft": "10%", "paddingBottom": "25px"}}>
                                <p style={{"margin":"0px","padding":"0px"}}>Mutabakat evrakı müşterinize / tedarikçinize eposta ile gönderilecektir.</p>
                                <p style={{"color":"red","margin":"0px","padding":"0px"}}>Müşterilerinizi spam klasörlerini kontrol etme konusunda uyarınız.</p>
                                <div className="col-md-2" style={{marginBottom: 20}}>
                                    <EkoFormDate
                                        {...detailProps}
                                        id="start_date"
                                        dateFormat="DD-MM-YYYY"
                                        isVertical={true}
                                        label="Başlangıç Tarihi"
                                        value={this.state.date1}
                                    />
                                </div>

                                <div className="col-md-2" style={{marginBottom: 20}}>
                                    <EkoFormDate
                                        {...detailProps}
                                        id="end_date"
                                        dateFormat="DD-MM-YYYY"
                                        isVertical={true}
                                        label="Bitiş Tarihi"
                                        value={this.state.date2}
                                    />
                                </div>

                                <div className="col-md-2" style={{marginBottom: 20, marginTop: 50}}>
                                    <EkoFormButton
                                        label={'Oluştur'}
                                        faClass="fa fa-eye"
                                        onClick={this.onsave.bind(this)}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <table className="table">
                                        <tr>
                                            <th>Başlangıç Tarihi</th>
                                            <th>Bitiş Tarihi</th>
                                            <th>Sizin Onay Tarihiniz</th>
                                            <th>Karşı Tarafın Onay Tarihi</th>
                                            <th>Göster / Eposta Gönder</th>
                                        </tr>
                                        {this.state.data.map((val, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{"width": "33%"}}>{val.start_date}</td>
                                                    <td style={{"width": "33%"}}>{val.end_date}</td>
                                                    <td style={{"width": "33%"}}>{val.sirket_mutabik_tarihi}</td>
                                                    <td style={{"width": "33%"}}>{val.musteri_mutabik_tarihi}</td>
                                                    <td style={{"width": "33%"}}>
                                                        <button onClick={this.view.bind(this, val.link)}
                                                                className="btn success"><i className="fa fa-eye"></i>
                                                        </button>
                                                        <button onClick={this.send.bind(this, val.link)}
                                                                className="btn success"><i className="fa fa-envelope"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </div>

                            </div>
                        </div> :
                        <div><a onClick={this.back.bind(this)}
                                style={{"marginLeft": "10px"}}><i
                            className="fa fa-arrow-left" style={{"marginRight":"10px"}}></i>Geri Dön</a>
                            <iframe
                                title={"iFrame"}
                                style={{overflow: "visible"}}
                                ref="iframe"
                                src={this.state.frameUrl}
                                scrolling="auto"
                                frameBorder="0"
                                width="100%"
                                height={700}
                            />
                        </div>}
                </EkoModal>
            </div>
        )
    }
}

export default TradeReconciliationModal;
