/* CoreByte - LOAD Required components */
import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormCheckbox,
    EkoFormInputText,
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormDate
  } from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
    FETCH_DECLARATION_TYPES
} from "../../actions/types";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import HelperContainer from "../../util/HelperContainer";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { empty } from "rxjs/Observer";
import styled from 'styled-components';
import {IconContext} from 'react-icons';
import {FiEye, FiDownloadCloud, FiTrash} from 'react-icons/fi';
import DeclarationsIframe from "./DeclarationsIframe";
import { Link } from "react-bootstrap/lib/Navbar";
import { push } from "react-router-redux";
import $ from 'jquery';

class DeclarationsList extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            formData: this.defaultFormData(),
            showInsertModal: false,
            errors: {},
            loading: true,
            rowsData: [],
            rowsDataFormatted: [],
            selectedFileB: null,
            tempFileB: null,
            fileUploading: null
        };
    }
    defaultFormData() {
      return {
          declaration: {
            type_id: 0,
            vat_period: "",
            type_cycle: 0
          }
        };
    }
    fileSelectedHandlerB = event => {
        let self = this;
        let file = event.target.files[0];
        let fieldId = event.target.id;
        
        let tmpFileObject = URL.createObjectURL(event.target.files[0]);
        this.setState({
            selectedFileB: file,
            tempFileB: tmpFileObject,
            fileUploading: true
        });
        const fd = new FormData();
        fd.append(fieldId, file);
        Api.post("/uploadFileBeyanname", fd, (response, error) => {
            //console.log(response);
            if (response) {
                if (response.actionResult.length == 0) {
                    toastr.error("Dosya yükleme sırasında hata oluştu. Lütfen dosyayı tekrar seçiniz.");
                }else{
                    toastr.success("Dosyanız kayıt altına alındı.");
                    this.getAllSavedBeyanname();
                }
            } else {
                if (error.status === "fail") {
                  let errors = Object.values(error);
                  errors.map(res => {
                    if (typeof res === "object") toastr.error(res[0]);
                });
                }
            }
            this.setState({
                fileUploading: null
            });
        });
        
    }
    getAllSavedBeyanname(){
        const mainThis = this;
        var allData = [];
        this.setState({loading: true});
        Api.get('/getAllSavedBeyannameList?', function(r, err) {
            
            mainThis.setState({rowsDataFormatted: (JSON.parse(r.formattedData))});
            mainThis.setState({loading: false});
        });
        mainThis.render();
    }
    componentWillMount(){
        this.getAllSavedBeyanname();
    }
    componentDidMount() {
        $(document).on("click","button", function (e) {
            
        });
    }
    openBeyanname(itemArray) {
        let theToken = localStorage.getItem("token");
        let tmpFormData = {
            declaration: {"type_id": itemArray.declaration_type_id, "type_cycle": itemArray.type_cycle, "vat_period": itemArray.vat_period+"_"+itemArray.vat_period_end, "token": theToken, "shid": itemArray.company_share_holder_id}
        }
        this.setState({formData: tmpFormData, showInsertModal: true});
    }
    openLink(link) {
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    deleteExtraFiles(itemId, field){
        var self = this;
        toastr.confirm('Yüklediğiniz dosyayı silmek istediğinize emin misiniz?', 
            {
                onOk: () => {
                    Api.put("/deleteBeyannameExtraFile", {id: itemId, fieldName: field}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == 1) {
                                toastr.success("Başarılı", "Dosya silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                self.getAllSavedBeyanname();
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    passiveActive(itemObject) {
        var self = this;
        toastr.confirm('Silmek istediğinize emin misiniz?', 
            {
                onOk: () => {
                    Api.put("/deleteBeyannameRecord", {id: itemObject.id}, function (responses, postErrors) {
                        if (postErrors) {
                            if (postErrors.message) {
                                toastr.error("Başarısız", "Kayıt güncelleme işlemi sırasında hata oluştu.");
                            }
                        }else{
                            if (responses.actionResult == 1) {
                                toastr.success("Başarılı", "Kayıt başarıyla silindi. Lütfen bekleyiniz, liste yenileniyor.");
                                self.getAllSavedBeyanname();
                            }
                        }
                    })
                },
                onCancel: () => {
                },
                okText: "Evet",
                cancelText: "Hayır"
            }
        );
    }
    vatPeriodText(k) {
        k = k.replace("-", "");
        let month = k.slice(-2);
        let year = k.slice(0,4);
        switch(month){
            case "01":
                return year + " Ocak";
            case "02":
                return year + " Şubat";
            case "03":
                return year + " Mart";
            case "04":
                return year + " Nisan";
            case "05":
                return year + " Mayıs";
            case "06":
                return year + " Haziran";
            case "07":
                return year + " Temmuz";
            case "08":
                return year + " Ağustos";
            case "09":
                return year + " Eylül";
            case "10":
                return year + " Ekim";
            case "11":
                return year + " Kasım";
            case "12":
                return year + " Aralık";
        }
    }
    vatPeriodTextJustMonth(k) {
        k = k.replace("-", "");
        let month = k.slice(-2);
        let year = k.slice(0,4);
        switch(month){
            case "01":
                return "Ocak";
            case "02":
                return "Şubat";
            case "03":
                return "Mart";
            case "04":
                return "Nisan";
            case "05":
                return "Mayıs";
            case "06":
                return "Haziran";
            case "07":
                return "Temmuz";
            case "08":
                return "Ağustos";
            case "09":
                return "Eylül";
            case "10":
                return "Ekim";
            case "11":
                return "Kasım";
            case "12":
                return "Aralık";
        }
    }
   
    render(){
        const kendisi = this;
        let beyanShortText = "";
        var apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);

        const Wrap = styled.div`
        background: white;
        color: gray;
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        border:1px gray solid;
        margin-top:1.5rem;
        box-shadow:0px 0px 3px 3px rgba(182,182,182,0.58);
        h1 {
            padding: 1rem;
            font-size: 1.4rem;
        }
        span {
            margin-right: 1.5rem;
        }
        .hidden{display:none !important}
        .showDiv{display:block !important}
        `;
        const betweenTableStyles = {
            "margin-top": '30px'
        }
        const Container = styled.div`
            box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
            
        `;
        
        if (kendisi.state.rowsDataFormatted.length!=null || kendisi.state.rowsDataFormatted.length != 0) {
            return (
                <div className="portlet light">
                    <div className="portlet-title">
                        <div className="caption font-dark">
                            <span className="caption-subject uppercase">
                                <h4>Kayıt Altına Alınan Beyannameler</h4>
                            </span>
                        </div>
                    </div>
                    <div className="portlet-body">

                        <DeclarationsIframe
                            showModal={kendisi.state.showInsertModal}
                            formData={kendisi.state.formData}
                            onCancel={() => {
                                kendisi.setState({showInsertModal: false});
                            }}
                            errors={kendisi.state.errors}
                        />
                        {
                        kendisi.state.loading == false ? 
                            Object.entries(kendisi.state.rowsDataFormatted).map((arrayLines, arrayLineKey) => {
                                return(
                                    arrayLines.map((vatPeriod, index2) => {
                                        return (
                                            <div className={"row"} key={index2}>
                                                <div style={{'width':'99%','float':'left'}}>
                                                    {Array.isArray(vatPeriod) === false ? (
                                                        <div className="{row}">
                                                            <h3>{kendisi.vatPeriodText(vatPeriod)}</h3>
                                                        </div>
                                                        ) : 
                                                        vatPeriod.map((item, index) => {
                                                            if (item.declaration_type_id == 1){ // KDV1 ise
                                                                beyanShortText = "<strong>Kümülatif Matrah:</strong> "+item.kumulatif_matrah+", <strong>KK Tahsilatı:</strong> "+item.kredi_karti_bedel+", <strong>Sonraki Dön. Dev. KDV:</strong> "+item.sonraki_doneme_devreden_kdv+",<br/><strong>İade Edilebilir KDV:</strong> "+item.iade_edilebilir_kdv_toplami+", <strong>Ödenecek KDV:</strong> "+item.odenmesi_gereken_kdv+"";
                                                            }else if (item.declaration_type_id == 2){ // KDV2 ise
                                                                beyanShortText = "<strong>Kümülatif Matrah:</strong> "+item.kdv2_kdv_matrahi+", <strong>Ödenecek KDV:</strong> "+item.kdv2_kdv+"";
                                                            }else if (item.declaration_type_id == 3){ // Geçici Vergi ise
                                                                beyanShortText = "<strong>Zarar:</strong> "+item.gv_zarar+", <strong>Kar:</strong> "+item.gv_kar+", <strong>Ödenecek Geçici Vergi:</strong> "+item.gv_kdv;
                                                            }
                                                            return (<>
                                                                    <div className={"row"} key={index}>
                                                                        <div style={{'width':'99%','float':'left'}}>
                                                                            <Wrap key={index}>
                                                                                <table style={{"width": "100%"}}>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td style={{"width": "20%", "textAlign": "left", "paddingLeft": "5px", "paddingTop": "8px", "paddingBottom": "8px"}}>
                                                                                            <span style={{"fontWeight": "bold"}}>{item.type_name}</span>
                                                                                            <br/>
                                                                                            <div>{item.vat_period != item.vat_period_end ? kendisi.vatPeriodTextJustMonth(item.vat_period) + " - " + kendisi.vatPeriodTextJustMonth(item.vat_period_end) : ""}</div>
                                                                                            <div>{item.company_share_holder_id > 0 ? item.share_holder_name : ""}</div>
                                                                                            <span style={{"fontSize": "10px" }}>{item.xml_is_downloaded == 1 ? <span style={{color: "green"}}>XML İndirildi</span> : <span style={{color: "red"}}>XML İndirilmedi</span>}</span>
                                                                                        </td>
                                                                                        <td style={{"width": "55%", "textAlign": "left", "paddingTop": "8px", "paddingBottom": "8px"}}>

                                                                                            <div dangerouslySetInnerHTML={{__html: beyanShortText}}></div>
                
                                                                                        </td>
                                                                                        <td style={{"width": "15%", "paddingTop": "8px", "paddingBottom": "8px"}}>
                                                                                            
                                                                                           {item.file_beyanname != "" ? (
                                                                                                <>
                                                                                                <a className="btn btn-sm blue md-skip" href={apiUrl + "/panel/download.php?isRemote=1&folder=&file=" + item.file_beyanname} 
                                                                                                    target={"_blank"} title="Dosyayı İndir">
                                                                                                    <i className="fa fa-download"/> Beyanname
                                                                                                </a> 
                                                                                                <button className="btn btn-sm btn-danger md-skip" style={{"marginLeft": "2px"}} 
                                                                                                    title="Beyanname dosyasını sil. Yeniden yükleme opsiyonu açılacaktır."
                                                                                                    onClick={this.deleteExtraFiles.bind(this, item.id, "beyanname")}
                                                                                                    ><i className="fa fa-trash"/>
                                                                                                </button>
                                                                                                </>
                                                                                           ) : "" }
                                                                                            <label className="btn btn-default white btn-sm" style={{"marginBottom": "5px", "display": (item.file_beyanname != "" ? "none" : "")}} id={"beyanname_label_"+item.id}>
                                                                                                <input
                                                                                                type="file"
                                                                                                id={"beyanname_"+item.id}
                                                                                                style={{ position: "fixed", top: "-1000px" }}
                                                                                                accept="application/pdf"
                                                                                                onChange={this.fileSelectedHandlerB}
                                                                                                ref={ref => (this.fileInput = ref)}
                                                                                                />
                                                                                                {this.state.fileUploading === null ? (
                                                                                                    <>
                                                                                                    <i className="fa fa-upload"/> Beyanname
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <EkoSpinner/>
                                                                                                )}
                                                                                            </label>
                                                                                            <br/>
                                                                                            {item.file_tahakkuk != "" ? (
                                                                                                <>
                                                                                                <a className="btn btn-sm blue md-skip" style={{"marginTop": "3px"}} href={apiUrl + "/panel/download.php?isRemote=1&folder=&file=" + item.file_tahakkuk} target={"_blank"} title="Dosyayı İndir">
                                                                                                    <i className="fa fa-download"/> Tahakkuk
                                                                                                </a>
                                                                                                <button className="btn btn-sm btn-danger md-skip" style={{"marginLeft": "2px"}} 
                                                                                                    title="Tahakkuk dosyasını sil. Yeniden yükleme opsiyonu açılacaktır."
                                                                                                    onClick={this.deleteExtraFiles.bind(this, item.id, "tahakkuk")}
                                                                                                    ><i className="fa fa-trash"/>
                                                                                                </button>
                                                                                                </>
                                                                                            ) : "" }
                                                                                            <label className="btn btn-default white btn-sm" style={{"display": (item.file_tahakkuk != "" ? "none" : "")}} id={"tahakkuk_label_"+item.id}>
                                                                                             <input
                                                                                                type="file"
                                                                                                id={"tahakkuk_"+item.id}
                                                                                                style={{ position: "fixed", top: "-1000px" }}
                                                                                                accept="application/pdf"
                                                                                                onChange={this.fileSelectedHandlerB}
                                                                                                ref={ref => (this.fileInput = ref)}
                                                                                                />
                                                                                                {this.state.fileUploading === null ? (
                                                                                                    <>
                                                                                                    <i className="fa fa-upload"/> Tahakkuk
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <EkoSpinner/>
                                                                                                )}
                                                                                            </label>

                                                                                        </td>
                                                                                        <td style={{"width": "10%", "paddingTop": "5px", "textAlign": "right", "paddingTop": "8px", "paddingBottom": "8px"}}>
                                                                                            {item.xml_file != null ? (
                                                                                                <a href={apiUrl + "/panel/download.php?isRemote=1&folder=&file=" + item.xml_file} target={"_blank"} title="XML İndir" style={{"marginRight": "5px"}}><FiDownloadCloud color={"#4a8bc2"} size="20"/></a>
                                                                                            ) : (
                                                                                                <a title="XML İndir" style={{"marginRight": "5px"}}><FiDownloadCloud color={"#a9a9a9"} size="20"/></a>
                                                                                            ) 
                                                                                            }
                                                                                            
                                                                                            <a onClick={kendisi.openBeyanname.bind(this, item)} title="Görüntüle / Düzenle" style={{"marginRight": "5px"}}><FiEye color={"#4a8bc2"} size="20"/></a>
                                                                                            
                                                                                            <a onClick={this.passiveActive.bind(this, item)} title="Sil" style={{"marginRight": "5px"}}><FiTrash color={"#e90000"} size="20" /></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </Wrap>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                    
                                                </div>
                                            </div>
                                        );
                                    })
                                )
                            })
                        : 
                            <div style={{"textAlign": "center"}}>
                                    <EkoSpinner />
                                <h3>Yükleniyor. Lütfen bekleyiniz.</h3>
                            </div>
                        }
                        

                    </div>
                    <br/>
                </div>
            );
        }
        
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, actions)(DeclarationsList);
