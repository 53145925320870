import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {CSS} from '../../css/table'
import Style from 'style-it'
import $ from 'jquery'

export default class DashboardTable extends PureComponent {

    componentDidMount(){
        this.JQueryFunc();
    }

    JQueryFunc(){
        $('.column100').on('mouseover',function(){
            var table1 = $(this).parent().parent().parent();
            var table2 = $(this).parent().parent();
            var verTable = $(table1).data('vertable')+"";
            var column = $(this).data('column') + "";

            $(table2).find("."+column).addClass('hov-column-'+ verTable);
            $(table1).find(".row100.head ."+column).addClass('hov-column-head-'+ verTable);
        });

        $('.column100').on('mouseout',function(){
            var table1 = $(this).parent().parent().parent();
            var table2 = $(this).parent().parent();
            var verTable = $(table1).data('vertable')+"";
            var column = $(this).data('column') + "";

            $(table2).find("."+column).removeClass('hov-column-'+ verTable);
            $(table1).find(".row100.head ."+column).removeClass('hov-column-head-'+ verTable);
        });
    }

    prepareData(){
        const matrix = this.props.contents;
        if (this.props.reduce)
            return this.props.contents.reduce(($, row) => row.map((_, i) => [...($[i] || []), row[i]]), [])
        else return matrix;
    }

    getAmount(amount){

        if (this.props.absolute) {
            return  Math.abs(amount)
        }

        return amount
    }


    getHeader = (item) => {
        if (typeof item === "string") {
            return item;
        }


        // todo:
    }


    render() {
       const contents = this.props.contents;
        return (
            Style.it(
                CSS,<div style={{vendorAnimationDuration: "10s"}} className={contents ? 'tableEko ver1 m-b-110' : 'tableEko ver1 m-b-110 loading'}>
                    <table style={{minHeight: "275px"}} data-vertable="ver1">
                        <thead>
                        <tr className="row100 head">
                            {
                                this.props.headers.map((item,i) => {
                                    if (item === null)
                                        return <th key={i} className={'column100 column'+(i+1)} data-column={'column'+(i+1)}/>
                                    else
                                        return<th key={i} className={'column100 column'+(i+1)} data-column={'column'+(i+1)}>{this.getHeader(item)}</th>
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            contents ?
                            this.prepareData().map((items,i) => {
                                return <tr key={i} className="row100">
                                    {
                                        items.map((item,i2) => {
                                            let amount = i2 !== 0 ? this.getAmount(item).toFixed(2).toString().split('.') : null;
                                            return <td key={i2} className={'column100 column'+(i2+1)} data-column={'column'+(i2+1)}>
                                                {
                                                    i2 === 0 ?
                                                        item :
                                                        <span style={{fontSize: 15}}
                                                        className="classPre">&nbsp;{amount[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                        <small>,{amount[1] || "00"}
                                                            <i className="fa fa-try"/></small></span>
                                                }
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                                :
                                null
                        }
                        </tbody>
                    </table>
                </div>
            )
        );
    }
}

DashboardTable.propTypes = {
    headers: PropTypes.array.isRequired,
    absolute: PropTypes.bool,
    reduce: PropTypes.bool,
    contents: PropTypes.array,
};

DashboardTable.defaultProps = {
    absolute: false,
    reduce: false,
}