import React from "react";
import EkoFormButton from "./EkoFormButton";

const defAccepts = ["image/png", "image/jpeg", "image/jpg"];

const EkoImageUpload = (props) => {
    const { addAccepts, onlyAccepts } = props;
    const accepts = onlyAccepts ? onlyAccepts : [...defAccepts, ...(addAccepts || [])];

    function makeid(length) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    let id = makeid(5);

    return (
        <div style={props.style}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {props.selectedImage && props.selectedImage.size > props.maxSize && (
                    <div className="alert alert-danger" role="alert">
                        Seçili dosyanın boyutu fazla büyük
                    </div>
                )}
                {!props.closeSmallImage ? (
                    <div className="row">
                        <div className="col-md-4">
                            {props.selectedImage ? (
                                <img
                                    alt="logo"
                                    style={{
                                        padding: 8,
                                        border: "3px solid #9c9a9a",
                                        borderRadius: 20,
                                    }}
                                    src={
                                        props.selectedImage instanceof File ||
                                        props.selectedImage instanceof Blob
                                            ? URL.createObjectURL(props.selectedImage)
                                            : props.selectedImage
                                    }
                                    width={props.width}
                                    height={props.height}
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="180"
                                    height="180"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-5v-1h5v1zm-18 0l4-5.96 2.48 1.96 2.52-4 1.853 2.964c-1.271 1.303-1.977 3.089-1.827 5.036h-9.026zm10.82 4h-14.82v-18h22v7.501c-.623-.261-1.297-.422-2-.476v-5.025h-18v14h11.502c.312.749.765 1.424 1.318 2zm-9.32-11c-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5z" />
                                </svg>
                            )}
                        </div>
                    </div>
                ) : null}
                <div style={{ marginTop: 30 }}>
                    {!props.selectedImage ? (
                        props.loading ? (
                            <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                        ) : (
                            <>
                                <label className="btn btn-default btn-file" for={id}>
                                    <i className="far fa-file-image"></i> {props.buttonText}
                                </label>
                                <input
                                    type="file"
                                    id={id}
                                    onChange={(e) => props.onChange(e.target.files[0])}
                                    accept={accepts.join(",")}
                                    style={{ display: "none" }}
                                />
                            </>
                        )
                    ) : (
                        <EkoFormButton
                            className="btn btn-danger"
                            label={props.buttonRemoveText || "Dosyayı Kaldır"}
                            isLoading={props.loading}
                            faClass="fas fa-trash"
                            onClick={(e) => props.onChange("")}
                            style={{ marginLeft: 10 }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EkoImageUpload;
