import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { FETCH_SOLE_PROF_SALES_INVOICES } from "../../actions/types";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";

class SoleProfSalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      soleProfSalesInvoices: []
    };
  }

  fetchSoleProfSalesInvoices() {
    this.props.fetchData(
      "/soleprofSalesInvoice/list",
      FETCH_SOLE_PROF_SALES_INVOICES
    );
  }

  componentWillMount() {
    this.setState({
      soleProfSalesInvoices: []
    });
    this.fetchSoleProfSalesInvoices();
  }

  openUpdate(selectedRow) {
    this.props.router.push(
      "/dashboard/soleprofsalesinvoice/edit/" + selectedRow.id
    );
  }

  openInsert(menuItem) {
    localStorage.setItem("new_payment_from_who_type", menuItem);
    this.props.router.push("/dashboard/soleprofsalesinvoice/add");
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/soleprofSalesInvoice/" + selectedRow.id, function(
      response,
      err
    ) {
      if (response) {
        self.fetchSoleProfSalesInvoices();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  render() {
    return (
      <div>
        <EkoDataTable
          openInsert={this.openInsert.bind(this)}
          openUpdate={this.openUpdate.bind(this)}
          onDelete={this.onDelete.bind(this)}
          data={this.props.soleProfSalesInvoices}
          title="serbest meslek makbuzu"
          name={"soleprofSalesInvoice"}
        ></EkoDataTable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let soleProfSalesInvoices;
  /*
    if (state.data.soleProfSalesInvoices && state.data.soleProfSalesInvoices[0]) {
        if (state.data.soleProfSalesInvoices[0].tax_identity_number) {
             soleProfSalesInvoices = [];
        }else{
            soleProfSalesInvoices = state.data.soleProfSalesInvoices;
        }
    }else{
        soleProfSalesInvoices = [];
    } */

  soleProfSalesInvoices = state.data.soleProfSalesInvoices;

  return {
    soleProfSalesInvoices: soleProfSalesInvoices,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(SoleProfSalesInvoice);
