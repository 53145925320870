import React, {Component} from "react";
import {
    EkoForm,
    EkoFormAutoComplete, EkoFormButton,
    EkoFormDate,
    EkoFormInputText, EkoFormRadioButton,
    EkoFormSelect, EkoFormSwitch
} from "../../components/elements/EkoForm";
import Util from "../../util/Util";
import * as actions from "../../actions";
import {toastr} from "react-redux-toastr";
import Api from "../../util/Api";
import {connect} from "react-redux";
import Sugar from 'sugar'
import {
    FETCH_BANK_ACCOUNTS, FETCH_BANK_ACCOUNTS_ALL, FETCH_CUSTOMERS_ALL, FETCH_EMPLOYEES_ALL,
    FETCH_EXPENSE_CATEGORIES,
    FETCH_EXPENSE_TEMPLATES,
    FETCH_OTHER_RECEIPT_AUTOCOMPLETE, FETCH_SUPPLIERS_ALL
} from "../../actions/types";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import AddDocumentButton from "../../components/forms/AddDocumentButton";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddOtherReceipts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            naceCodeList: [],
            expenseName: "",
            formData: {},
            saving: false,
            errors: this.defaultErrors(),
            deleteRowIds: [],
            documentType: [],
            rowLoad: false,
            serial_order_choose: 1,
            vknLoad: false,
            headerFields: [
                {title: 'Kategori Adı', type: 'text', id: 'description', clearable: true},
            ],
            tableData: {
                headers: [
                    {label: 'Gider Kategorisi', width: '10%'},
                    {label: 'Faaliyet Adı', width: '10%', hidden: this.naceCodeHidden(),},
                    {label: 'Açıklama', width: '10%'},
                    {label: "Brüt Tutar", width: '10%'},
                    {label: 'KDV Oranı', width: '10%'},
                    {label: 'KDV Tutarı', width: '10%'},
                    {label: 'Stopaj Oranı', width: '10%'},
                    {label: 'Stopaj Kodu', width: '10%'},
                    {label: 'Stopaj Tutarı', width: '10%'},
                    {label: 'Net Ödenen', width: '10%'},
                ],
                footer: [
                    {
                        label: 'Toplam KDV',
                        type: 'money',
                        id: 'vat_amount',
                        disabled: true
                    },
                    {
                        label: 'Toplam Stopaj',
                        type: 'money',
                        id: 'stoppage_amount',
                        disabled: true
                    },
                    {
                        label: 'Toplam Ödenen',
                        type: 'money',
                        id: 'total_amount',
                        disabled: true
                    }
                ],
                customFooter: null,
                rows: this.defaultRows()
            },
            vatRates: [],
            formFields: {},
            successDisabled: false,
            spinner: false,
            tracking: true,
            fromWhoMenu: [],
            methods: [],
            method: "",
            check1: false,
            check2: "",
            radioButtons: [
                {
                    label: "Ödenecek",
                    id: "is_net",
                    radio_id: "not_paid",
                    value: 1,
                    checked: true
                },
                {
                    label: "Ödendi",
                    id: "is_net",
                    radio_id: "paid",
                    value: 0,
                    checked: false
                }
            ],
            is_checked: "not_paid",
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:11,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        };
    }

    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({
            newTagRows: rows,
        });
    };
    getNewTagModalList = () => {
        if (this.props && this.props.editId) {
            Api.get("/company/newTagRecordDocumentList/11/" + this.props.editId, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }
            });
        }
    }
    defaultFormData() {
        return {
            otherReceipt: {vat_rate_type_id: 0, stoppage_type_id: 0, stoppage_amount: 0, tracking: true},

            expense: {
                description: ""
            },
            items: {
                date: null,
                payment_method: 0,
                bank_account_id: 0,
                description: null
            },
            rows: [{}],
            footer: [
                {
                    label: 'Toplam KDV',
                    type: 'money',
                    id: 'vat_amount',
                    disabled: true
                },
                {
                    label: 'Toplam Stopaj',
                    type: 'money',
                    id: 'stoppage_amount',
                    disabled: true
                },
                {
                    label: 'Toplam Ödenen',
                    type: 'money',
                    id: 'total_amount',
                    disabled: true
                }
            ],

        };
    }

    decideFromWhoMenu(nextProps) {

        let methods = this.props.viewOptions
            ? this.props.viewOptions.other_receipts.payment_method
            : [];
        let formData = nextProps.formData || this.state.formData;
        //NOTE @bocek tum istedigim veriler gelmeden set işlemi yapıp boşuna render ettirmeyelim
        if (
            Util.isUndefined(nextProps.suppliers) ||
            Util.isUndefined(nextProps.employees) ||
            Util.isUndefined(nextProps.customers)
        )
            return;

        let menu = [];
        let fromWhoType = 2;
        if (nextProps.fromWhoType) {
            //NOTE view optionsdan string geliyor niyeyse
            fromWhoType = parseInt(nextProps.fromWhoType, 10);
        }
        switch (fromWhoType) {
            case 6:
            case 8:
                methods = methods.filter(item => {
                    return item.id !== 4;
                });
                if (nextProps.customers) {
                    menu = nextProps.customers;
                }
                break;
            case 1:
            case 2:
                if (nextProps.suppliers) {
                    menu = nextProps.suppliers;
                }
                break;
            case 3:
            case 5:
            case 7:
            case 9:
            case 4:
                methods = methods.filter(item => {
                    return item.id !== 4;
                });
                if (nextProps.employees) {
                    menu = nextProps.employees.filter(item => {
                        return !item.termination_date;
                    });
                }
                break;
            default:
        }

        let fromWhoMenu = [];
        menu.forEach(function (item) {
            // if (item.is_active && item.is_active === 1) {
            let lastname = item.lastname ? " - " + item.lastname : "";
            item.title = item.firstname + " " + lastname;
            fromWhoMenu.push(item);
            // }
        });

        if (
            this.state.fromWhoType === nextProps.fromWhoType &&
            this.state.fromWhoMenu
        ) {
            //menu zaten set edilmse ve degisen birsey yoksa birsey yapma
            if (!nextProps.formData) {
                //eger formData falan da gelmemişse editten
            }
        }
        if (
            (this.state.savingSupplier ||
                this.state.savingCustomer ||
                this.state.savingEmployee) &&
            fromWhoMenu.length > 0
        ) {
            formData = this.state.formData;
            formData.payment = Object.assign({}, formData.payment, {
                type_source_id: fromWhoMenu[0].id
            });
            this.setState({
                fromWhoMenu: fromWhoMenu,
                formData: formData,
                savingSupplier: false,
                methods: methods
            });
        } else
            this.setState({
                fromWhoMenu: fromWhoMenu,
                formData: formData,
                methods: methods
            });
    }

    naceCodeHidden() {
        try {
            this.setState({naceCodeList: JSON.parse(localStorage.getItem("nace_code_list"))});
            if (JSON.parse(localStorage.getItem("nace_code_list")).length <= 1) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.log('catch error', error);
            axios.get(ROOT_URL + "/company/naceCodeListSelect", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
            ).then(response => {
                var data = JSON.stringify(response.data.naceCode, null, 2);
                localStorage.setItem("nace_code_list", '');
                localStorage.setItem("nace_code_list", data);
            });
            window.setTimeout(function () {
                window.location.href = "/dashboard";
            }, 3000);

        }
    }

    componentWillMount() {
        this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
        this.props.fetchData("/employee/showAll", FETCH_EMPLOYEES_ALL);
        this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
        this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
        let formData = this.props.formData || this.defaultFormData();
        this.getNewTagModalList();
        if (this.props && this.props.expenseId) {
            Api.get("/otherReceipt/getExpenseCategories/" + this.props.expenseId, (response, error) => {
                response.rows.map((r, i) => {
                    formData.rows = [...formData.rows, {
                        id: r['id'],
                        nace_code: r['nace_code'],
                        expense_category_id_select: r['expense_category_id_select'],
                        description: r['description'],
                        vat_exc: r['vat_exc'],
                        vat_rate: Number(r['vat_rate']),
                        vat_amount: r['vat_amount'],
                        stoppage_ratio: r['stoppage_ratio'],
                        stoppage_code: r['stoppage_code'],
                        stoppage_amount: r['stoppage_amount'],
                        amount: r['amount'],
                    }];
                });
                this.setState({formData: formData, expenseName: response.expenseCategories.description});
            });
        }else{
            var json = JSON.parse(localStorage.getItem("nace_code_list"));
            if (json.length > 1) {
                let result = json.find(obj => obj.varsayilan === 1);
                formData.rows[0].nace_code = result.id;
            }
        }
        const documentType = JSON.parse(localStorage.getItem("getDBSExpenseDocumentType"));
        this.setState({documentType:documentType.documentType});

        let errors = this.defaultErrors();
        if (formData.rows.length > errors.rows.length) {
            const defaultErrors = this.defaultErrors();
            while (errors.rows.length < formData.rows.length)
                errors.rows.push(defaultErrors.rows[0])
        }
        this.assignExpenseAndVat();

        this.setState({formData: formData, errors: errors});

    }


    componentDidMount() {
        this.props.fetchData(
            "/data/expenseCategories/" + 1,
            FETCH_EXPENSE_CATEGORIES
        );
        this.props.fetchData(
            "/data/expenseTemplates/" + 1,
            FETCH_EXPENSE_TEMPLATES
        );
        this.props.fetchData(
            "/otherReceipt/autoComplete",
            FETCH_OTHER_RECEIPT_AUTOCOMPLETE
        );
        this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS);
    }

    componentWillReceiveProps(nextProps) {
        this.decideFromWhoMenu(nextProps);
        let formData = nextProps.formData || this.defaultFormData();
        if (nextProps.viewOptions && nextProps.viewOptions.other_receipts.fieldCompanyTypes) {
            this.fieldCompanyTypes = nextProps.viewOptions.other_receipts.fieldCompanyTypes;
        }


        if (formData.otherReceipt.id && this.state.rowLoad === false) {
            formData.rows = [{}];

        }
        this.loadData(formData);
        var selectedExpenseDocumentType = 1;
        if (this.state.formData.otherReceipt.document_number !== undefined) {
            selectedExpenseDocumentType = formData.otherReceipt.document_type;
        }
        const documentType = JSON.parse(localStorage.getItem("getDBSExpenseDocumentType"));
        const get = Sugar.Array.find(documentType.documentType, function (type) {
            return type.id === selectedExpenseDocumentType
        })
        if (formData.otherReceipt.id && this.state.rowLoad === false) {
            console.log('formDatax',formData);
            this.setState({tracking: formData.otherReceipt.tracking===0?false:true});
            if(formData.otherReceipt.payment_status === 0){
                this.setState({is_checked: 'not_paid'});
            }else{
                this.setState({is_checked: 'paid'});
            }
            if (get.code !== undefined) {
                Api.get('/DBS/purchaseExpenseCategoriesSelect/list/1/' + get.code, resp => {
                    let tableData = this.state.tableData;
                    if (resp.purchaseExpenseCategories) {
                        tableData.rows.forEach(function(row, index) {
                            tableData.rows[index][0].data = "";
                            tableData.rows[index][0].data = resp.purchaseExpenseCategories;
                        })
                    }
                    this.setState({tableData: tableData})
                });
            }
        }else{
            let tableData = this.state.tableData;
            tableData.rows.forEach(function(row, index) {
                tableData.rows[index][0].data = "";
                tableData.rows[index][0].data = [{"id":"dokuman_tipi_seciniz","key_words":"Doküman Tipini Seçiniz. Doküman Tipi Yoksa \"Henüz Belgelendirilmemiş\" Seçiniz","description":"Doküman Tipini Seçiniz. Doküman Tipi Yoksa \"Henüz Belgelendirilmemiş\" Seçiniz","default":0,"disabled":true}];
            });
            this.setState({tableData: tableData});
        }
        this.setState({serial_order_choose: get.serial_order_choose});

        if (formData.otherReceipt.stoppage_amount) {
            formData.otherReceipt.stoppage_amount = formData.otherReceipt.stoppage_amount
                .toString()
                .replace(".", ",");
        }
        this.state.tableData.rows[0][1].data = JSON.parse(localStorage.getItem("nace_code_list"));
        if (formData.otherReceipt.payment_status == 0) {
            this.state.radioButtons[0].checked = true;
            this.state.radioButtons[1].checked = false;
            this.setState({check1: false, check2: true, is_checked: 'not_paid'});
        } else if (formData.otherReceipt.payment_status == 1) {
            this.state.radioButtons[0].checked = true;
            this.state.radioButtons[1].checked = true;
            this.setState({check1: true, check2: false, is_checked: 'paid'});
        } else {
            this.state.radioButtons[0].checked = true;
            this.state.radioButtons[1].checked = false;
            this.setState({check1: false, check2: true, is_checked: 'not_paid'});
        }
        // this.setState({formData: formData, tracking: formData.otherReceipt.tracking});

    }

    isPermitted(fieldName) {
        if (!this.fieldCompanyTypes) {
            return false;
        }

        let finded = this.fieldCompanyTypes.filter(type => type.id === fieldName);

        if (finded.length === 0) {
            return false;
        }

        finded = finded[0].name;

        return CompanyTypePermission.isPermitted(finded);
    }

    //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada customer.firstname)
    // type=> address, field=>city_id gibi
    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        // eğer ödeme şekli değiştirildiyse kart tipini boşalt.
        if (field === "payment_method") {
            formData["bank_account_id"] = null;
        }

        if (value === "not_paid") {
            this.setState({check1: false, check2: true, is_checked: "not_paid"});
        }

        if (value === "paid") {
            this.setState({
                check1: true,
                check2: false,
                is_checked: "paid"
            });
        }

        if (field === "document_type") {
            const get = Sugar.Array.find(this.state.documentType, function (type) {
                return type.id === value
            });
            if (get.code !== undefined) {
                Api.get('/DBS/purchaseExpenseCategoriesSelect/list/1/' + get.code, resp => {
                    let tableData = this.state.tableData;
                    if (resp.purchaseExpenseCategories) {
                        tableData.rows.forEach(function(row, index) {
                            tableData.rows[index][0].data = "";
                            tableData.rows[index][0].data = resp.purchaseExpenseCategories;
                        })
                    }
                    this.setState({tableData: tableData})
                });
            }
            this.setState({ serial_order_choose: get.serial_order_choose });
        }
        if (field === "expense_category_id") {
            this.state.formData.footer.amount = 0;
            this.state.formData.footer.vat_amount = 0;
            this.state.formData.footer.total_amount = 0;
            this.state.formData.footer.stoppage_amount = 0;
            this.state.formData.rows = [];
            Api.get("/otherReceipt/getExpenseCategories/" + value, (response, error) => {
                response.rows.map((r, i) => {
                    this.state.formData.rows = [...this.state.formData.rows, {
                        id: r['id'],
                        nace_code: r['nace_code'],
                        expense_category_id_select: r['expense_category_id_select'],
                        description: r['description'],
                        vat_exc: r['vat_exc'],
                        vat_rate: Number(r['vat_rate']),
                        vat_amount: r['vat_amount'],
                        stoppage_ratio: r['stoppage_ratio'],
                        stoppage_code: r['stoppage_code'],
                        stoppage_amount: r['stoppage_amount'],
                        amount: r['amount'],
                    }];

                    if (r['amount'])
                        this.state.formData.footer.amount += Util.convertMoneyToDecimal(r['amount']);
                    if (r['stoppage_amount'])
                        this.state.formData.footer.stoppage_amount += Util.convertMoneyToDecimal(r['stoppage_amount']);
                    if (r['vat_rate'] && r['vat_amount'])
                        this.state.formData.footer.vat_amount += Util.convertMoneyToDecimal(r['vat_amount']);
                    if (this.state.formData.footer.amount > 0) {
                        this.state.formData.footer.total_amount = Util.convertDecimalToMoney(this.state.formData.footer.amount)
                    }
                    if (this.state.formData.footer.vat_amount > 0) {
                        this.state.formData.footer.vat_amount = Util.convertDecimalToMoney(this.state.formData.footer.vat_amount)
                    }


                    this.setState({formData: this.state.formData});
                });
                this.assignExpenseAndVat();

            });
        }

        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;
        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldChangeForAutoComplete(type, field, value) {
        let otherReceiptAutoComplete = this.props.otherReceiptAutoComplete;
        otherReceiptAutoComplete = otherReceiptAutoComplete.filter(
            o => o[field] === value
        )[0];
        let formData = this.state.formData[type];
        if (otherReceiptAutoComplete) {
            formData.address = otherReceiptAutoComplete.address;
            formData.firstname = otherReceiptAutoComplete.firstname;
            formData.lastname = otherReceiptAutoComplete.lastname;
            formData.tax_identity_number =
                otherReceiptAutoComplete.tax_identity_number;
        }
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
    //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
    //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    setFieldChangeForAmount(type, field, value) {
        let formData = this.state.formData[type];
        let copiedAmount = value;
        if (value === formData.amount) {
            return;
        }
        if (value === "") {
            copiedAmount = 0;
        }
        copiedAmount = Util.convertMoneyToDecimal(copiedAmount);

        const stoppageAmount = this.getStoppageAmountAccordingToChangedAmount(
            formData,
            copiedAmount
        );
        const vatAmount = this.getVatAmountAccordingToChangedAmount(
            formData,
            copiedAmount
        );
        formData.amount = copiedAmount;
        formData.stoppage_amount = stoppageAmount;
        formData.vat_amount = vatAmount;

        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldChangeForStoppageSelect(type, field, value) {
        let formData = this.state.formData[type];
        const stoppage_type = this.props.stoppageTypes.filter(
            s => s.id === value
        )[0];
        let stoppageAmount =
            Util.convertMoneyToDecimal(formData.amount) * stoppage_type.rate;
        formData.stoppage_amount = Util.convertDecimalToMoney(stoppageAmount);

        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    setFieldChangeForVatSelect(type, field, value) {
        let formData = this.state.formData[type];
        const vat_type = this.props.vatRateTypes.filter(v => v.id === value)[0];
        let vatAmount = Util.convertMoneyToDecimal(formData.amount) * vat_type.rate;
        formData.vat_amount = Util.convertDecimalToMoney(vatAmount);

        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm, errors: errors});
    }

    getStoppageAmountAccordingToChangedAmount(formData, amount) {
        let stoppageTypes = this.props.stoppageTypes;
        const stoppageType = stoppageTypes.filter(
            s => s.id === formData.stoppage_type_id
        )[0];
        let stoppageAmount = (amount * stoppageType.rate).toFixed(2);

        return Util.convertDecimalToMoney(stoppageAmount); //Util.formatNumberAccordingToThousandsOfSteps(stoppageAmount.replace('.',','))
    }

    getVatAmountAccordingToChangedAmount(formData, amount) {
        let vatRateTypes = this.props.vatRateTypes;
        const vatRateType = vatRateTypes.filter(
            s => s.id === formData.vat_rate_type_id
        )[0];
        let vatAmount = (amount * vatRateType.rate).toFixed(2);
        return Util.convertDecimalToMoney(vatAmount); //Util.formatNumberAccordingToThousandsOfSteps(vatAmount.replace('.',','))
    }

    redirectToBase() {
        window.location.replace("/dashboard/purchases/otherReceipt");
    }
    onValidation() {
        // error degerler boş ise error bas
        let tableData = this.state.tableData;
        let rows = this.state.formData.rows;
        let errors = this.state.errors;
        let error = false;
        rows.forEach((row, index) => {
            //console.log('row',row);
            errors.rows[index] = {
                and_or: false,
                field: false,
                operator: false,
                value: false
            };
            if (!row.expense_category_id_select) {
                errors.rows[index]["expense_category_id_select"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.description) {
                errors.rows[index]["description"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.amount) {
                errors.rows[index]["amount"] = ["Zorunlu alan"];
                error = true;
            }
            if (row.amount && row.amount === "0,00") {
                errors.rows[index]["amount"] = ["Sıfırdan Büyük Olmalı"];
                error = true;
            }
            if (!row.vat_amount) {
                errors.rows[index]["vat_amount"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.vat_exc) {
                errors.rows[index]["vat_exc"] = ["Zorunlu alan"];
                error = true;
            }
        });
        return error ? errors : false;
    }
    onSave(refresh, isChecked) {
        let validationControl = this.onValidation();

        if (validationControl){
            return this.setState({ saving: false })
        }


        const redirectToBase = arguments[0];
        const self = this;
        self.setState({saving: true});
        let amount = this.state.formData.footer.amount;
        let stoppage_amount_total = Util.convertMoneyToDecimal(this.state.formData.footer.stoppage_amount);
        let vat_amount_total = Util.convertMoneyToDecimal(this.state.formData.footer.vat_amount);
        let currency_rate = Util.convertMoneyToDecimal(
            this.state.formData.otherReceipt.currency_rate || "0,00"
        );
        let date = Util.convertToDatabaseDate(
            this.state.formData.otherReceipt.date
        );
        let due_date = Util.convertToDatabaseDate(
            this.state.formData.otherReceipt.due_date
        );
        let payment_date = Util.convertToDatabaseDate(
            this.state.formData.otherReceipt.payment_date
        );
        var dnum = "";

        var otherReceiptx = this.state.formData.otherReceipt;
        if (otherReceiptx && otherReceiptx.document_number !== undefined && otherReceiptx.document_number !== null) {
            dnum = otherReceiptx.document_number;

            if (otherReceiptx.legal_serial !== undefined && otherReceiptx.legal_serial !== null) {
                otherReceiptx.legal_serial = otherReceiptx.legal_serial.toString().toUpperCase();
            }

            if (otherReceiptx.legal_no !== undefined && otherReceiptx.legal_no !== null) {
                otherReceiptx.legal_no = otherReceiptx.legal_no.toString().toUpperCase();
            }

            if (this.state.serial_order_choose == 0) {
                dnum = otherReceiptx.legal_serial + "" + otherReceiptx.legal_no;
            }
        }else{
            dnum = otherReceiptx.legal_serial + "" + otherReceiptx.legal_no;
        }

        dnum = dnum.toString().toUpperCase();
        let otherReceipt = Object.assign({}, self.state.formData.otherReceipt, {
            deleteRowIds:this.state.deleteRowIds,
            serial_order_choose:this.state.serial_order_choose,
            document_number:dnum,
            currency_rate: currency_rate,
            amount: amount,
            date: date,
            due_date: due_date,
            payment_date: this.state.tracking == true ? payment_date : date,
            stoppage_amount: stoppage_amount_total,
            vat_amount: vat_amount_total,
            payment_status: this.state.is_checked === 'not_paid' && payment_date==='' ? 0 : 1,
            rows: this.state.formData.rows,
            supplier_id: this.state.formData.otherReceipt.supplier_id,
            tracking: this.state.tracking == true ? 1 : 0,
            expense_category_id: parseInt(
                this.state.formData.otherReceipt.expense_category_id,
                10
            ),
            newTagRows:this.state.newTagRows
        });

        if (
            !this.isPermitted("payment_method") &&
            !this.isPermitted("bank_account_id")
        ) {
            otherReceipt.payment_method = null;
            otherReceipt.bank_account_id = null;
        }

        let errors = self.state.errors;

        let func = "post";
        let url = "/otherReceipt";

        if (otherReceipt.id) {
            func = "put"; //NOTE edit işlemi
            url = "/otherReceipt/" + otherReceipt.id;
        }
        if(this.props.addDocument?.uri && otherReceipt.document_url !== this.props.addDocument?.uri){
            otherReceipt.document_url = this.props.addDocument?.uri;
        }
        otherReceipt.is_checked = isChecked === true;

        Api[func](url, otherReceipt, function (response, postErrors) {
            if (postErrors) {
                errors.otherReceipt = postErrors;

                if (postErrors.message) {
                    toastr.error(postErrors.message);
                }

                self.setState({saving: false, errors: errors});
            } else {
                self.props.fetchData(
                    "/otherReceipt/autoComplete",
                    FETCH_OTHER_RECEIPT_AUTOCOMPLETE
                );

                if (otherReceipt.id) {
                    toastr.success("Başarılı", "Başarıyla Güncellendi");
                    if(isChecked === true){
                        Api.get(`/otherReceipt/next/${self.props?.editId}`,function (res, error) {
                            if(res.item) {
                                const path = window.location.pathname;
                                const ex = path.split('/');
                                ex.pop();
                                ex.push(res.item.id);
                                self.setState({
                                    saving: false,
                                    errors: {},
                                    formData: self.defaultFormData()
                                });
                                self.props.router.push(ex.join('/'));
                            } else {
                                self.redirectToBase();
                            }
                        })
                    } else {
                        self.redirectToBase();
                    }
                } else {
                    toastr.success("Başarılı", "Başarıyla Kaydedildi");

                    if (redirectToBase && redirectToBase === true) {
                        self.redirectToBase();
                    } else {
                        self.setState({
                            saving: false,
                            errors: {},
                            formData: self.defaultFormData()
                        });
                    }
                }
            }
        });
    }

    getBankAccountsAccordingToPaymentMethod() {
        const relatedCardId = this.state.formData.otherReceipt.payment_method
            ? this.state.formData.otherReceipt.payment_method
            : "";
        const bankAccounts = this.props.bankAccounts ? this.props.bankAccounts : [];
        return bankAccounts.filter(
            bank =>
                bank.bank_account_type === relatedCardId && bank.currency === "TRY"
        );
    }

    cancelOperation() {
        this.props.router.push("/dashboard/purchases/otherReceipt");
    }

    newTypeSource() {
        switch (parseInt(this.props.fromWhoType, 10)) {
            case 6:
            case 8:
                this.setState({showCustomerModal: true});
                break;
            case 3:
            case 5:
            case 7:
            case 9:
            case 4:
                this.setState({showEmployeeModal: true});
                break;
            case 1:
            case 2:
                this.setState({showSupplierModal: true});
                break;
            default:
                window.alert("Bu tipin ekleme modal sayfası henuz yapılmamış");
        }
    }


    defaultErrors() {
        return {
            rows: [{
                nace_code: false,
                expense_category_id_select: false,
                description: false,
                vat_exc: false,
                vat_rate: false,
                vat_amount: false,
                stoppage_ratio: false,
                stoppage_code: false,
                stoppage_amount: false,
                amount: false,
            }],
            footer: {}
        }
    }

    defaultRows() {
        return [
            [
                {
                    type: 'select',
                    id: 'expense_category_id_select',
                    optionValue: 'description',
                    defaultKey: 'diğer',
                    searchFilter: 'key_words',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    searchable: true,
                    data: []
                },
                {
                    type: 'select',
                    id: 'nace_code',
                    hidden: this.naceCodeHidden(),
                    optionValue: 'description',
                    defaultKey: 'diğer',
                    searchFilter: 'key_words',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    searchable: true,
                    data: []
                },
                {type: 'text', id: 'description', vertical: true},
                {type: 'money', id: 'vat_exc', vertical: true},
                {type: 'select', id: 'vat_rate', defaultText: 'SEÇ', optionValue: 'name', optionId: 'id', data: []},
                {type: 'money', id: 'vat_amount', vertical: true},
                {type: 'money', id: 'stoppage_ratio', vertical: true},
                {
                    type: 'select',
                    id: 'stoppage_code',
                    optionValue: 'name',
                    defaultKey: 'diğer',
                    searchFilter: 'name',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    searchable: true,
                    data: []
                },
                {type: 'money', id: 'stoppage_amount', vertical: true},
                {type: 'money', id: 'amount', vertical: true},
            ]
        ]
    }

    assignExpenseAndVat = (next) => {

        let vatRates = {}
        Api.paralel(['/data/vatRateTypes'], resp => {
            let tableData = this.state.tableData;
            if (resp[0]) {
                tableData.rows[0][4].data = resp[0].vatRateTypes
                resp[0].vatRateTypes.forEach(function (vr) {
                    vatRates[vr.id] = vr;
                })
            }
            this.setState({tableData: tableData, vatRates: vatRates}, () => next ? next() : null)
        });

        let tableData = this.state.tableData;
        tableData.rows[0][1].data = JSON.parse(localStorage.getItem("nace_code_list"));
        var jsonParse = JSON.parse(localStorage.getItem("stoppage_code"));
        jsonParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0,});
        tableData.rows[0][7].data = jsonParse;
        this.setState({tableData: tableData});
    }

    onCancel() {

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onRowDelete(index, row) {
        let formData = this.state.formData
        let errors = this.state.errors
        let deleteRowIds = this.state.deleteRowIds
        errors.rows = Sugar.Array.removeAt(errors.rows, index)
        this.setState({errors: errors})
        if (row.id) {
            deleteRowIds.push(row.id)
            /*  Api.delete('/receiptLine/'+row.id,function(response,err){
             toastr.success('Fiş satırı silindi');
             });*/
        }
    }

    onReceiptChange(formData) {
        if (this.state.saving) return;
        let headerFields = this.state.headerFields;
        let stateFormData = {...this.state.formData};
        let errors = this.state.errors;
        if (formData.items) {
            stateFormData.receipt = Object.assign(stateFormData.receipt, formData.items)
        }

        if (formData.rows) {
            stateFormData.rows = formData.rows
            stateFormData = this.calculateTotal(stateFormData);
            if (formData.rows.length > errors.rows.length) {
                const defaultErrors = this.defaultErrors();
                while (errors.rows.length < formData.rows.length)
                    errors.rows.push(defaultErrors.rows[0])
            }
        }

        const paymentMethod = stateFormData.receipt.payment_method;
        if (this.props.bankAccounts && paymentMethod) {
            let bankAccounts = this.props.bankAccounts.filter(b => (b.bank_account_type === paymentMethod && b.is_active === 1 && b.currency === 'TRY'))
            if (paymentMethod === 4) {
                if (stateFormData.receipt.employee_id) {
                    stateFormData.receipt.bank_account_id = stateFormData.receipt.employee_id
                }
                bankAccounts = this.props.employees.filter(b => b.termination_date === null)
                bankAccounts = bankAccounts.map(function (employee) {
                    return {name: employee.firstname + " " + employee.lastname, id: employee.id};
                });
            }
            headerFields[3]['data'] = bankAccounts
            this.setState({headerFields: headerFields, formData: stateFormData, errors: errors});
        } else {
            this.setState({formData: stateFormData, errors: errors});
        }

    }

    loadChangeTotalCalculate(formData) {
        if (this.state.saving) return;
        let headerFields = this.state.headerFields;
        let stateFormData = {...this.state.formData};
        let errors = this.state.errors;
        if (formData.items) {
            stateFormData.receipt = Object.assign(stateFormData.receipt, formData.items)
        }

        if (formData.rows) {
            stateFormData.rows = formData.rows
            stateFormData = this.calculateTotal(stateFormData);
            if (formData.rows.length > errors.rows.length) {
                const defaultErrors = this.defaultErrors();
                while (errors.rows.length < formData.rows.length)
                    errors.rows.push(defaultErrors.rows[0])
            }
        }
        this.setState({formData: stateFormData, errors: errors});

    }

    calculateTotal(stateFormData) {
        stateFormData.footer.amount = 0;
        stateFormData.footer.vat_amount = 0;
        stateFormData.footer.total_amount = 0;
        stateFormData.footer.stoppage_amount = 0;
        stateFormData.rows.forEach(function (row) {
            if (row.amount)
                stateFormData.footer.amount += Util.convertMoneyToDecimal(row.amount);
            if (row.stoppage_amount)
                stateFormData.footer.stoppage_amount += Util.convertMoneyToDecimal(row.stoppage_amount);
            if (row.vat_rate && row.vat_amount)
                stateFormData.footer.vat_amount += Util.convertMoneyToDecimal(row.vat_amount);
        });
        if (stateFormData.footer.amount > 0) {
            stateFormData.footer.total_amount = Util.convertDecimalToMoney(stateFormData.footer.amount)
            //stateFormData.footer.amount = Util.convertDecimalToMoney(stateFormData.footer.amount)
        }
        if (stateFormData.footer.vat_amount > 0) {
            //stateFormData.footer.total_amount += stateFormData.footer.vat_amount
            stateFormData.footer.vat_amount = Util.convertDecimalToMoney(stateFormData.footer.vat_amount)
        }
        if (stateFormData.footer.stoppage_amount > 0) {
            stateFormData.footer.stoppage_amount = Util.convertDecimalToMoney(stateFormData.footer.stoppage_amount)
        }
        return stateFormData;
    }

    addRow = () => {
        // do nothing
    }

    onReceptLineChange(index, formData, field) {
        let stateFormData = this.state.formData;
        stateFormData.rows[index] = formData;
        const vatId = stateFormData.rows[index].vat_rate;
        let amount = stateFormData.rows[index].amount;
        let vat_amount = stateFormData.rows[index].vat_amount;
        let stoppage_amount = stateFormData.rows[index].stoppage_amount;
        let vat_exc = stateFormData.rows[index].vat_exc;
        let stoppage_ratio = stateFormData.rows[index].stoppage_ratio;
        let errors = this.state.errors

        if (errors.rows[index]) {
            errors.rows[index][field] = false
        }

        if ((field === 'vat_exc' || field === 'vat_rate') && vat_exc && vatId) {
            vat_exc = Util.convertMoneyToDecimal(vat_exc);
            stateFormData.rows[index].vat_amount = Util.convertDecimalToMoney((vat_exc * this.state.vatRates[vatId].rate));
            errors.rows[index].vat_amount = false
        }

        if ((field === 'vat_exc' || field === 'stoppage_ratio') && vat_exc && stoppage_ratio) {
            var stoppage_ratiox = stoppage_ratio.replace("%", '');
            stoppage_ratiox = parseInt(stoppage_ratiox);
            vat_exc = Util.convertMoneyToDecimal(vat_exc);
            if (stoppage_ratio != '%') {
                stateFormData.rows[index].stoppage_amount = Util.convertDecimalToMoney((vat_exc * stoppage_ratiox) / 100);
            } else {
                stateFormData.rows[index].stoppage_amount = Util.convertDecimalToMoney(0);
            }
            errors.rows[index].stoppage_amount = false
        }

        if (this.state.formData.rows[index].vat_exc && this.state.formData.rows[index].vat_amount) {
            stateFormData.rows[index].amount = Util.convertDecimalToMoney((Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_exc) + Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_amount)));
            errors.rows[index].amount = false
        }

        if (vat_exc && vat_amount && stoppage_amount) {
            stateFormData.rows[index].amount = Util.convertDecimalToMoney((Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_exc) + Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_amount)) - Util.convertMoneyToDecimal(this.state.formData.rows[index].stoppage_amount));
            errors.rows[index].amount = false
        }


        stateFormData = this.calculateTotal(stateFormData)

        this.setState({formData: stateFormData, errors: errors});
    }

    filterOnlyPermittedFields() {

        const filtered = this.state.headerFields.filter((field) => {
            return this.isPermittedx(field.id);
        });

        if (CompanyTypePermission.isSelfEmployment()) {
            filtered.push({});
        }

        return filtered;
    }

    isPermittedx(fieldName) {
        let fieldCompanyTypes;

        if (this.props.viewOptions && this.props.viewOptions.receipts.fieldCompanyTypes) {
            fieldCompanyTypes = this.props.viewOptions.receipts.fieldCompanyTypes;
        }

        if (fieldCompanyTypes === undefined) {
            return false;
        }


        let finded = fieldCompanyTypes.filter((type) => type.id === fieldName);

        if (this.permitted[fieldName] !== undefined) {
            return this.permitted[fieldName];
        }

        if (finded.length === 0) {
            this.permitted[fieldName] = true;
            return true;
        }

        finded = finded[0].name;

        const returnValue = CompanyTypePermission.isPermitted(finded);

        this.permitted[fieldName] = returnValue;

        return returnValue;
    }

    setFieldChangex(type, field, value) {
        this.setState({
            expenseName: field
        });
    }


    componentWillUpdate(nextProps, nextState) {
        let tableData = nextState.tableData;
        let formData = nextState.formData;
        let errors = nextState.errors
        if (formData && formData.rows && (formData.rows.length > tableData.rows.length || formData.rows.length > errors.rows.length)) {
            while (formData.rows.length > tableData.rows.length) {
                tableData.rows.push(tableData.rows[0]); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
            }

            const defaultErrors = this.defaultErrors();
            while (errors.rows.length < formData.rows.length)
                errors.rows.push(defaultErrors.rows[0])

            this.setState({tableData: tableData, errors: errors})
        } else if (formData && formData.rows && formData.rows.length === 1 && (tableData.rows.length > 1 || tableData.rows.length > 1)) {
            //edit den add safasina geclince
            let tableData = this.state.tableData;
            tableData.rows = this.defaultRows();
            this.setState({errors: this.defaultErrors(), tableData: tableData});
        }
    }

    loadData(formData) {
        if (formData.otherReceipt.id) {
            if (formData.rows && this.state.rowLoad === false) {
                this.setState({rowLoad: true});
                Api.get("/otherReceipt/getLines/" + formData.otherReceipt.id, (response, error) => {
                    //console.log('response',response);
                    formData.rows = [];
                    response.rows.map((r, i) => {
                        formData.rows = [...formData.rows, {
                            id: r['id'],
                            nace_code: r['nace_code'],
                            expense_category_id_select: r['expense_category_id_select'],
                            description: r['description'],
                            vat_exc: r['vat_exc'],
                            vat_rate: Number(r['vat_rate']),
                            vat_amount: r['vat_amount'],
                            stoppage_ratio: r['stoppage_ratio'],
                            stoppage_code: r['stoppage_code'],
                            stoppage_amount: r['stoppage_amount'],
                            amount: r['amount'],
                        }];
                    });
                    this.setState({formData: formData});
                    this.loadChangeTotalCalculate(formData);
                });
            }
        }
    }


    toggle(field) {
        this.state.formData.otherReceipt.supplier_id = "";
        this.state.formData.otherReceipt.address = "";
        this.state.formData.otherReceipt.firstname = "";
        this.state.formData.otherReceipt.lastname = "";
        this.state.formData.otherReceipt.tax_identity_number = "";
        this.setState({tracking: field});
        this.setState({check1: true, check2: false, is_checked: 'paid'});
    }
    onVknSearch() {
        const self = this;
        let formData = self.state.formData;
        self.setState({ vknLoad: true });
        Api.post(
            "/customer/searchVkn",
            { identity: formData.otherReceipt?.tax_identity_number },
            function (response, error) {
                self.setState({ vknLoad: false });
                if (error) {
                    self.setState({
                        errors: {
                            ...self.state.errors,
                            otherReceipt: {
                                ...self.state.errors.otherReceipt,
                                tax_identity_number:
                                    "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                            },
                        },
                    });
                } else {
                    const info = response.data?.info || {};
                    const taxOffice = response.data?.taxOffice || {};
                    const c = formData.otherReceipt;
                    if (info?.companyName) {
                        self.setState({
                            errors: {
                                ...self.state.errors,
                                otherReceipt: {
                                    ...self.state.errors.otherReceipt,
                                    tax_identity_number: null,
                                },
                            },
                        });
                        self.setState({
                            formData: {
                                ...formData,
                                otherReceipt: {
                                    ...formData.otherReceipt,
                                    firstname: info?.firstname || c.firstname,
                                    lastname: info?.lastname || c.lastname || "",
                                    tax_office_code: taxOffice?.code || c.tax_office_code,
                                },
                            },
                        });
                    } else {
                        self.setState({
                            errors: {
                                ...self.state.errors,
                                otherReceipt: {
                                    ...self.state.errors.otherReceipt,
                                    tax_identity_number:
                                        "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                                },
                            },
                        });
                    }
                }
            }
        );
    }
    render() {
        const user = JSON.parse(localStorage.getItem("user"));
        let formData = {...this.state.formData};
        if ("expense_category_id" in formData.otherReceipt) {
            formData.otherReceipt.expense_category_id = parseInt(
                formData.otherReceipt.expense_category_id,
                10
            );
        }

        if ("expense_templates_id" in formData.otherReceipt) {
            formData.otherReceipt.expense_templates_id = parseInt(
                formData.otherReceipt.expense_templates_id,
                10
            );
        }

        const otherReceiptProps = {
            onChange: this.setFieldChange.bind(this, "otherReceipt"),
            onMount: this.setFieldMount.bind(this, "otherReceipt"),
            formData: formData.otherReceipt,
            errors: this.state.errors.otherReceipt
        };

        const otherReceiptAutoCompleteProps = {
            ...otherReceiptProps,
            onSelect: this.setFieldChangeForAutoComplete.bind(this, "otherReceipt")
        };
        const otherReceiptAmountProps = {
            ...otherReceiptProps,
            onChange: this.setFieldChangeForAmount.bind(this, "otherReceipt")
        };
        const otherReceiptStoppageProps = {
            ...otherReceiptProps,
            onChange: this.setFieldChangeForStoppageSelect.bind(this, "otherReceipt")
        };
        const otherReceiptVatProps = {
            ...otherReceiptProps,
            onChange: this.setFieldChangeForVatSelect.bind(this, "otherReceipt")
        };

        let stoppageTypes = this.props.stoppageTypes;
        let vatRateTypes = this.props.vatRateTypes;
        let expenseCategories = this.props.expenseCategories;
        let documentType = this.state.documentType;
        let expenseTemplates = this.props.expenseTemplates;

        const bankAccounts = this.getBankAccountsAccordingToPaymentMethod();
        const paymentMethods = this.props.viewOptions
            ? this.props.viewOptions.other_receipts.payment_method
            : [];
        const taxOffices = this.props.taxOffices?  this.props.taxOffices.map(function(obj) {
            if (obj && obj.city) {
                obj.full_name = obj.name + " - " + obj.city.name;
            }
            return obj;
        }) :[];
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/purchases/otherReceipt")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                <div className="portlet light">
                    <div className="portlet-title"
                         style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {this.props.title}
            </span>
                        </div>
                        <div style={{justifyContent: 'flex-end', display: 'flex', flex: 'auto'}}>
                            <AddDocumentButton style={{margin: 0, transform: 'translateY(-25%)'}}/>
                        </div>
                    </div>

                    <div className="portlet-body">
                        <EkoForm formId={"add_other_receipt"}>
                            <div className="row">
                                {user.company_id == 1 || user.company_id == 2 ? (
                                    <div className="col-md-12"><a href={"/dashboard/purchases/expensecategory"}
                                                                  style={{marginLeft: 10, marginBottom: 10}}
                                                                  className="btn dark btn-outline"
                                    >+ Gider Kategorisi Ekle</a></div>
                                ) : (
                                    ""
                                )}
                                <div className="col-md-6">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <EkoFormSelect
                                                {...otherReceiptProps}
                                                searchable={true}
                                                id="expense_category_id"
                                                defaultKey="diğer"
                                                optionValue="description"
                                                searchFilter={"key_words"}
                                                defaultText="Seçiniz"
                                                options={expenseCategories}
                                                label="Gider Kategorisi"
                                                isVertical
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <EkoFormSelect
                                                {...otherReceiptProps}
                                                id="document_type"
                                                optionValue="name"
                                                defaultText="Seçiniz"
                                                options={documentType}
                                                label="Doküman Tipi"
                                                isVertical
                                            />
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <div
                                                style={this.state.formData.otherReceipt.id && this.state.tracking === true ? styles.input_visible : styles.input_hidden}>
                                                <EkoFormSelect
                                                    {...otherReceiptProps}
                                                    searchable={true}
                                                    disabled={true}
                                                    id="supplier_id"
                                                    defaultText="Kişi Seçiniz"
                                                    isVertical={true}
                                                    options={this.state.fromWhoMenu}
                                                    optionValue="title"
                                                    width={"300px"}
                                                    //onAddNewItemClick={this.newTypeSource.bind(this)}
                                                    label="Kimden"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row"
                                         style={!this.state.formData.otherReceipt.id ? styles.input_visible : styles.input_hidden}>
                                        <div className="col-md-6">
                                            <div style={{"height": "20px"}}></div>
                                            Cari Takibi Yapacağım
                                            <div style={{"height": "15px"}}></div>
                                            <EkoFormSwitch
                                                textOn={"Evet"}
                                                textOff={"Hayır"}
                                                md={12}
                                                onChange={this.toggle.bind(this)}
                                                value={this.state.tracking}
                                                leftBox={false}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div
                                                style={this.state.tracking === false ? styles.input_hidden : styles.input_visible}>
                                                <EkoFormSelect
                                                    {...otherReceiptProps}
                                                    searchable={true}
                                                    id="supplier_id"
                                                    defaultText="Kişi Seçiniz"
                                                    isVertical={true}
                                                    options={this.state.fromWhoMenu}
                                                    optionValue="title"
                                                    width={"300px"}
                                                    //onAddNewItemClick={this.newTypeSource.bind(this)}
                                                    label="Kimden"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div
                                        style={this.state.tracking === false ? styles.input_visible : styles.input_hidden}>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <EkoFormAutoComplete
                                                    colMd={8}
                                                    labelMd={4}
                                                    {...otherReceiptAutoCompleteProps}
                                                    label="Vergi No/TC Kimlik No"
                                                    options={this.props.otherReceiptAutoComplete}
                                                    optionValue="tax_identity_number"
                                                    id="tax_identity_number"
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <EkoFormButton
                                                    label="Sorgula"
                                                    // faClass="fa fa-plus"
                                                    isLoading={this.state.vknLoad}
                                                    onClick={this.onVknSearch.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <EkoFormSelect
                                            {...otherReceiptAutoCompleteProps}
                                            searchable={true}
                                            id="tax_office_code"
                                            clearable={true}
                                            optionValue="full_name"
                                            options={taxOffices}
                                            optionId="code"
                                            label="Vergi Dairesi Adı"
                                            colMd={9}
                                            labelMd={3}
                                        />
                                        <EkoFormAutoComplete
                                            colMd={9}
                                            labelMd={3}
                                            {...otherReceiptAutoCompleteProps}
                                            label="Adı/Firma Ünvanı"
                                            options={this.props.otherReceiptAutoComplete}
                                            optionValue="firstname"
                                            id="firstname"
                                        />

                                        <EkoFormAutoComplete
                                            colMd={9}
                                            labelMd={3}
                                            {...otherReceiptAutoCompleteProps}
                                            label="Soyadı/Firma Ünvanı"
                                            options={this.props.otherReceiptAutoComplete}
                                            optionValue="lastname"
                                            id="lastname"
                                        />

                                        <EkoFormInputText
                                            {...otherReceiptProps}
                                            colMd={9}
                                            labelMd={3}
                                            rows={5}
                                            label="Adres"
                                            id="address"
                                            type="textarea"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6"
                                             style={{"display": this.state.serial_order_choose == 1 ? "contents" : "none"}}>
                                            <EkoFormInputText
                                                {...otherReceiptProps}
                                                colMd={10}
                                                labelMd={2}
                                                label="Seri/Sıra No"
                                                id="document_number"
                                            />
                                        </div>
                                        <div className="col-md-6"
                                             style={{"display": this.state.serial_order_choose == 0 ? "contents" : "none"}}>
                                            <EkoFormInputText
                                                {...otherReceiptProps}
                                                colMd={10}
                                                labelMd={2}
                                                label="Seri No"
                                                id="legal_serial"
                                            />
                                            <EkoFormInputText
                                                {...otherReceiptProps}
                                                colMd={10}
                                                labelMd={2}
                                                label="Sıra No"
                                                id="legal_no"
                                            />
                                        </div>
                                    </div>
                                    <EkoFormDate
                                        colMd={10}
                                        labelMd={2}
                                        {...otherReceiptProps}
                                        id="date"
                                        dateFormat="DD-MM-YYYY"
                                        clearable={false}
                                        label="Belge Tarihi"
                                    />
                                    <div style={this.state.check1 == true ? styles.input_hidden : styles.input_visible}>
                                        <EkoFormDate
                                            colMd={10}
                                            labelMd={2}
                                            {...otherReceiptProps}
                                            id="due_date"
                                            dateFormat="DD-MM-YYYY"
                                            clearable={false}
                                            label="Vade Tarihi"
                                        />
                                    </div>

                                    <EkoFormInputText
                                        {...otherReceiptProps}
                                        colMd={10}
                                        labelMd={2}
                                        rows={5}
                                        label="Açıklama"
                                        id="description"
                                        type="textarea"
                                    />

                                    <div className="row  text-right"
                                         style={!this.state.formData.otherReceipt?.id ? styles.input_visible : styles.input_hidden}>
                                        <div
                                            style={this.state.tracking == false ? styles.input_hidden : styles.input_visible}>
                                            <EkoFormRadioButton
                                                {...otherReceiptProps}
                                                id="radioButtons"
                                                buttons={this.state.radioButtons}
                                                onFalse={this.onFalse}
                                            />
                                        </div>
                                    </div>


                                </div>


                            </div>
                            <div className="row"
                                 style={this.state.check2 === true && !formData.otherReceipt.id ? styles.input_hidden : styles.input_visible}>
                                <div className="col-md-6">&nbsp;</div>
                                <div className="col-md-6">
                                    <div className="col-md-4">
                                        <EkoFormDate
                                            {...otherReceiptProps}
                                            id={"payment_date"}
                                            dateFormat="DD-MM-YYYY"
                                            isVertical={true}
                                            calendarPlacement={"top"}
                                            clearable
                                            label={"Ödeme Tarihi"}
                                            //disabled={formData.otherReceipt.id}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        {this.isPermitted("payment_method") ? (
                                            <EkoFormSelect
                                                {...otherReceiptProps}
                                                id="payment_method"
                                                optionValue="name"
                                                defaultText="Seçiniz"
                                                options={paymentMethods}
                                                label="Ödeme Şekli"
                                                //disabled={formData.otherReceipt.id}
                                                isVertical
                                            />
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        {this.isPermitted("bank_account_id") ? (
                                            <EkoFormSelect
                                                {...otherReceiptProps}
                                                id="bank_account_id"
                                                optionValue="name"
                                                defaultText="Seçiniz"
                                                options={bankAccounts}
                                                label="İlişkili Hesap"
                                                //disabled={formData.otherReceipt.id}
                                                isVertical
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: "40px"}}></div>
                            <EkoInputTableButtonGroup data={this.state.tableData}
                                                      formData={this.state.formData}
                                                      errors={this.state.errors}
                                                      formId={"add_expense_category"}
                                                      onRowAdd={this.addRow}
                                                      onChangeRow={this.onReceptLineChange.bind(this)}
                                                      onChange={() => null}
                                                      onRowDelete={this.onRowDelete.bind(this)}
                                                      saving={this.state.saving}
                                                      onSave={(refresh, isChecked) => this.onSave(refresh, isChecked)}
                                                      onCancel={this.cancelOperation.bind(this)}
                                                      buttons={[
                                                          {
                                                              onClick: this.onSave.bind(this, false),
                                                              labelText: "Kaydet ve Yeni Ekle"
                                                          }
                                                      ]}
                                                      isCheckButton={true}
                                                      editId={this.props?.editId}
                                                      updatedAt={this.state?.formData?.otherReceipt?.is_checked ? this.state?.formData?.otherReceipt?.updated_at : null}
                            />
                            <div className="clearfix"/>
                        </EkoForm>
                        <hr/>
                        <a onClick={this.openNewTagModal}>Etiket Ekle</a>
                        <NewTagDocumentModal
                            newTagRows={this.state.newTagRows}
                            saveNewTag={this.saveNewTag}
                            showNewTagModal={this.state.showNewTagModal}
                            closeNewTagModal={this.closeNewTagModal}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    input_hidden: {
        display: "none"
    },
    input_visible: {
        display: "block"
    },
};

function mapStateToProps(state) {
    return {
        expenseCategories: state.data.expenseCategories,
        expenseTemplates: state.data.expenseTemplates,
        otherReceiptAutoComplete: state.data.otherReceiptAutoComplete,
        stoppageTypes: state.data.stoppageTypes,
        vatRateTypes: state.data.vatRateTypes,
        viewOptions: state.data.viewOptions,
        bankAccounts: state.data.bankAccounts,
        customers: state.data.customersAll,
        suppliers: state.data.suppliersAll,
        employees: state.data.employeesAll,
        addDocument: state.page.addDocument,
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties
    };
}

export default connect(mapStateToProps, actions)(AddOtherReceipts);
