import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import authReducer from './reducer_auth'
import fetchReducer from './reducer_fetch'
//import postReducer from './reducer_post'
//import validateReducer from './reducer_validate'
import {reducer as toastr} from 'react-redux-toastr'
import pageReducer from './reducer_page';
import {routerReducer as routing} from "react-router-redux"

const rootReducer = combineReducers({
  form,
  routing,
  toastr,
  auth: authReducer,
  data : fetchReducer,
  //post : postReducer,
//  validate : validateReducer,
  page : pageReducer,
});

export default rootReducer;
