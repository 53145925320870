import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import PurchaseExpenseCategory from "./PurchaseExpenseCategory";
import NaceCode from "../../screens/Company/NaceCode";
import SmmmYmmInformation from "../../screens/Company/SmmmYmmInformation";
import DeclarationPeriodYears from "../../screens/Company/DeclarationPeriodYears";
import NewTag from "../../screens/Company/NewTag";
import Util from "../../util/Util";
const AccountingSettings = () => {
    const hash = window.location.hash;
    var activeTab = "gider_kagegori"; // default tab
    if (hash && hash != '') {
        activeTab = hash.replace("#", "");
    }
    return (
        <div>
            <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
               onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

            <div className="portlet light">
                <div className="portlet-title">
                    <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            Muhasebe Ayarları
          </span>
                    </div>
                </div>
                <div className="portlet-body">
                    <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example">

                        <Tab eventKey="gider_kagegori" title="Gider Kategorileri">
                            <PurchaseExpenseCategory/>
                        </Tab>
                        <Tab eventKey="nace_code" title="NACE Kodları">
                            <NaceCode/>
                        </Tab>
                        <Tab eventKey="new_tag" title="Etiket">
                            <NewTag/>
                        </Tab>
                        <Tab eventKey="smmm_ymm" title="SMMM / YMM Bilgileri">
                            <SmmmYmmInformation/>
                        </Tab>
                        <Tab eventKey="declaration_periods" title="Beyanname Dönem Bilgileri">
                            <DeclarationPeriodYears/>
                        </Tab>
                    </Tabs>
                </div>
                <br/>
            </div>
        </div>
    );
};

export default AccountingSettings;
