import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import moment from "moment";
class ModalIrsaliye extends Component {
  get row() {
    return [
      {
        label: "",
        placeholder: "İrsaliye No",
        type: "text",
        colMd: 12,
        id: "irsaliye_no"
      },

      {
        label: "",
        placeholder: "İrsaliye Tarihi",
        type: "date",
        id: "irsaliye_date"
      }
    ];
  }

  get defaultData() {
    return {
      rows: [this.row],
      errors: {
        rows: []
      },
      saving: false
    };
  }

  constructor(props) {
    super(props);

    this.state = this.defaultData;
  }

  checkValid = data => {
    return !!(data && data !== "");
  };

  onSave = () => {
    const errors = this.checkErrors();

    this.setState({ errors: { rows: errors } });

    if (errors.length === 0) {
      this.props.closeModal();
    }
  };

  checkErrors = () => {
    const rows = this.props.irsaliyeRows.rows;

    const errors = [];

    rows.map((row, index) => {
      row = {
        ...row,
        irsaliye_date: Util.convertToDatabaseDate(row.irsaliye_date)
      };

      if (
        (row.irsaliye_date === "" || row.irsaliye_date === null) &&
        (row.irsaliye_no === "" || row.irsaliye_no === null)
      ) {
        return row;
      }

      if (this.checkValid(row.irsaliye_no) === false) {
        errors[index] = {
          irsaliye_no: ["Lütfen  İrsaliye Numarası giriniz"]
        };
      }

      // if (isNaN(row.irsaliye_no)) {
      //   errors[index] = {
      //     irsaliye_no: ["Lütfen Geçerli İrsaliye Numarası giriniz"]
      //   };
      // }

      if (this.checkValid(row.irsaliye_date) === false) {
        errors[index] = {
          irsaliye_date: ["Lütfen Geçerli bir İrsaliye Tarihi giriniz"]
        };
      }

      if (row.irsaliye_date > moment().format("YYYY-MM-DD")) {
        errors[index] = {
          irsaliye_date: ["İrsaliye Tarihi ileri bir tarih olamaz."]
        };
      }

      return row;
    });

    return errors;
  };

  closeModal = () => {
    const errors = this.checkErrors();

    this.setState({ errors: { rows: errors } });

    if (errors.length > 0) {
      toastr.warning("Hatalı bir veri girdiniz, lütfen kontrol ediniz");

      return;
    }

    //this.props.saveIrsaliye(this.defaultData.rows)
    if (this.props.closeModal && errors.length === 0) {
      this.props.closeModal();
    }
  };

  closeModal2 = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  onChange = data => {
    this.props.saveIrsaliye(data);
  };

  render() {
    return (
      <EkoModal
        bsSize={"large"}
        dialogClassName={"irsali"}
        showModal={this.props.showModal}
        title={"İrsaliye Bilgileri Ekle"}
        onHide={this.closeModal2.bind(this)}
        spinner={this.state.saving}
      >
        <EkoInputTable
          onSave={this.onSave}
          onCancel={this.closeModal2}
          onChange={this.onChange}
          errors={this.state.errors}
          formData={this.props.irsaliyeRows}
          data={{
            headers: [{ label: "İrsaliye No" }, { label: "İrsaliye Tarihi" }],

            rows: this.props.irsaliyeRows.rows.map(() => this.row)
          }}
        />
      </EkoModal>
    );
  }
}

export default ModalIrsaliye;
