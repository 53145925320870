import React, {Component} from 'react'
import {Col, ControlLabel, FormGroup} from 'react-bootstrap'
import PropTypes from 'prop-types';
import Switch from 'react-bootstrap-switch'

export default class EkoFormSwitch extends Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps: Readonly): boolean {
        return this.isOn !== !!nextProps.value || this.props.textOn !== nextProps.textOn || this.props.textOff !== nextProps.textOff
    }

    isOn = () => !!this.props.value

    static propTypes = {
        value: PropTypes.bool,
        textOn: PropTypes.string,
        textOff: PropTypes.string,
        leftBox: PropTypes.bool,
    }

    static defaultProps = {
        value: false,
        textOff: 'Kapalı',
        textOn: 'Açık',
        leftBox:true
    }

    handleSwitch(elem, state) {
        this.props.onChange(state);
    }

    onSwitchChange() {
        this.props.onChange(!this.isOn());
    }

    render() {
        return (
            <FormGroup>
                {this.props.leftBox==true?<Col componentClass={ControlLabel} md={5}>
                </Col>:""}
                <Col md={6}>
                    <a onClick={() => this.onSwitchChange()} className="sbold"
                       style={{marginRight: '5px'}}>{this.props.label2}</a>
                    <Switch bsSize={'small'} labelWidth={20} onText={this.props.textOn} offText={this.props.textOff}
                            value={this.isOn()} onChange={(el, state) => this.handleSwitch(el, state)}/>
                    <a onClick={() => this.onSwitchChange()} className="sbold"
                       style={{marginLeft: '5px'}}>{this.props.label}</a>
                </Col>
            </FormGroup>
        )
    }
}
