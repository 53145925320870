import React, { Component } from "react";
import Sugar from "sugar";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import AddItem from "../Item/AddItem";
import AddOtherTax from "../../components/forms/AddOtherTax";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import UtilHelper from "../../util/UtilHelper";
import AddSoleprofv2InvoiceHeader from "./AddSoleprofv2InvoiceHeader";
import { withRouter } from "react-router";
import {EkoFormButton, EkoFormSelect} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";

class AddSoleprofv2Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddItem: false,
      showAddTax: false,
      formData: props.formData,
      calculatedFormData: {},
      saving: false,
      taxForm: AddOtherTax.getDefaultForm(),
      selectedLine: 0,
      errors: props.errors,
      items: {},
      istisna_fatura_buton: "İstisna Kodu Ekle",
      openIstisnaModal: false,
      reason_id: 0,
      saved: false,
      deleteRowIds: [],
      deleteTaxIds: [],
      vatReductionTypeCodeList: [],
      tableData: {
        headers: [
          { label: "Ürün / Hizmet", width: "9%" },
          { label: "Açıklama", width: "9%" },
          { label: "Birim Fiyatı", width: "9%" },
          { label: "Stopaj Kodu", width: "9%" },
          { label: "Stopaj Oranı", width: "9%" },
          { label: "Birim", width: "9%" },
          { label: "Tevkifat Kodu", width: "9%" },
          { label: "Tevkifat Tutarı", width: "9%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "9%" },
          { label: "KDV", width: "9%" }
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            visible: true
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: true
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: true
          },
          {
            id: "receivable_amount_fc",
            label: "Ödenecek Tutar",
            className: "tplus-plus",
            visible: true
          }
        ],
        customFooter: null,
        rows: this.defaultRows()
      }
    };
  }

  defaultRows() {
    let rows = [
      [
        {
          type: "select",
          id: "item_id",
          optionValue: "codeName",
          selectedOptionValue: "item_code",
          optionId: "id",
          defaultText: "SEÇ",
          searchable: true,
          onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
          data: []
        },
        { type: "text", id: "description", vertical: true },
        {
          type: "money",
          id: "unit_price_fc",
          vertical: true,
          value: 0,
          precision: 4,
          currency: true
        },
        {
          type: "select",
          id: "stoppage_code_id",
          defaultText: "",
          optionValue: "name",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: []
        },
        {
          type: "money",
          id: "stoppage_ratio",
          vertical: true,
          value: 0,
          precision: 4,
          currency: false
        },
        {
          type: "select",
          id: "unit_id",
          defaultText: "",
          optionValue: "description",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: []
        },
        {
          type: "select",
          id: "vat_reduction_type_id",
          defaultText: "",
          optionValue: "description",
          disabled: false,
          optionId: "id",
          searchable: true,
          data: []
        },
        { type: "money", id: "vat_reduction_amount_fc", vertical: true, disabled: true },
        { type: "money", id: "net_amount_fc", vertical: true, disabled: true },
        {
          type: "select",
          id: "vat_rate_type_id",
          defaultText: "",
          optionValue: "name",
          optionId: "id",
          data: []
        },
        { type: "money", id: "vat_amount_fc", vertical: true, disabled: true }
        //{ type: 'clickarea',id:'other_tax',tdClass:'ticon2',faClass: 'fa fa-plus',onClick:this.onOtherTaxClick.bind(this,0)}
      ]
    ];

    return rows;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !Util.isUndefined(nextProps.vatRateTypes) &&
      !Util.isUndefined(nextProps.vatReductionTypeCodes) &&
      !Util.isUndefined(nextProps.units) &&
      !Util.isUndefined(nextProps.taxTypes)
    );
  }

  fetchItems(setFirstItem) {
    const self = this;
    Api.get("/item/showAll", function(response, err) {
      let items = response.items.map(function(item) {
        item.codeName = item.item_code + ":" + item.item_name;
        return item;
      });
      items = items.filter(function(item) {
        return item.is_sales === 1;
      });
      let stateItems = {};
      items.forEach(function(item, index) {
        stateItems[item.id] = item;
      });

      let tableData = self.state.tableData;
      tableData.rows = tableData.rows.map(function(row, index) {
        row[0]["data"] = items;
        return row;
      });

      const index = self.state.selectedLine || 0;
      tableData.rows[index][0]["data"] = items;
      self.setState({ tableData: tableData, items: stateItems }, function() {
        if (setFirstItem) {
          let formData = self.state.formData;
          formData.rows[index].item_id = items[0].id;
          formData.rows[index].item_code = items[0].item_code;
          self.setState({ formData: formData }, function() {
            self.onInvoiceLineChange(index, formData.rows[index], "item_id");
          });
        }
      });
    });
  }

  onAddNewItemClick(index) {
    this.setState({ selectedLine: index, showAddItem: true });
  }

  onItemSaved() {
    this.setState({ showAddItem: false });
    this.fetchItems(true);
  }

  componentWillMount() {
    this.fetchItems(false);
    try {
      this.prepareFormToPost(this.state.formData);
    } catch (ex) {
      console.log(ex);
    }
  }

  componentWillReceiveProps(nextProps) {
    const self = this;
    var stoppageJsonParse = JSON.parse(localStorage.getItem("stoppage_code"));
    if(stoppageJsonParse.findIndex(o => o.id == 0)){
      stoppageJsonParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0, rate: 0,});
    }
    var vatReductionTypeCodeParse = nextProps.vatReductionTypeCodes;
    if(vatReductionTypeCodeParse.findIndex(o => o.id == 0)){
      vatReductionTypeCodeParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0, rate: 0,});
    }
    vatReductionTypeCodeParse.forEach(function(x) {
      if(x.description.search(":")==-1){
        x.description=x.code+": "+x.description;
      }
    });

    this.setState({ vatReductionTypeCodeList: vatReductionTypeCodeParse });
    if (nextProps.formData) {
      this.setState({ formData: nextProps.formData }, function() {
        self.prepareFormToPost(this.state.formData);
        self.renewTableData();
      });
    }
    if (
      nextProps.vatRateTypes &&
      nextProps.vatReductionTypeCodes &&
      nextProps.units
    ) {
      let tableData = this.state.tableData;
      tableData.rows[0][9]["data"] = nextProps.vatRateTypes;
      tableData.rows[0][6]["data"] = this.state.vatReductionTypeCodeList;
      tableData.rows[0][3]["data"] = stoppageJsonParse;
      tableData.rows[0][5]["data"] = nextProps.units;
      var istisnaKodu = 0;
      this.state.formData.rows.forEach(function (row) {
        if (row.vat_rate_type_id == 1) {
          if (row.istisna_kodu !== null) {
            istisnaKodu = row.istisna_kodu.toString();
          }
        }
      });
      this.setState({ tableData: tableData, reason_id: istisnaKodu });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;
    var stoppageJsonParse = JSON.parse(localStorage.getItem("stoppage_code"));
    if(stoppageJsonParse.findIndex(o => o.id == 0)){
      stoppageJsonParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0, rate: 0,});
    }
    if (
      formData &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        let row = this.defaultRows()[0];
        row[0].data = tableData.rows[0][0].data;
        row[9]["data"] = nextProps.vatRateTypes;
        row[6]["data"] = this.state.vatReductionTypeCodeList;
        row[3]["data"] = stoppageJsonParse;
        row[5]["data"] = nextProps.units;
        tableData.rows.push(row); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = nextProps.errors;
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
      const self = this;
      this.setState({ tableData: tableData, errors: errors }, function() {
        self.renewTableData();
      });
    } else if (
      formData &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({
        errors: this.props.defaultErrors(),
        tableData: tableData
      });
    }
  }

  prepareFormToPost = formData => {
    let calculatedFormData = {};
    const self = this;
    if (formData.invoice) {
      calculatedFormData.invoice = { ...formData.invoice };

      calculatedFormData.invoice.customer_id = Util.parseInt(
        calculatedFormData.invoice.customer_id
      );
      calculatedFormData.invoice.currency_rate = Util.convertMoneyToDecimal(
        calculatedFormData.invoice.currency_rate
      );
      calculatedFormData.invoice.date = calculatedFormData.invoice
        .date
        ? moment(calculatedFormData.invoice.date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.due_date = calculatedFormData.invoice.due_date
        ? moment(calculatedFormData.invoice.due_date).format("YYYY-MM-DD")
        : null;
    }

    let footer = {
      price_amount: 0,
      price_amount_fc: 0,
      discount_amount: 0,
      discount_amount_fc: 0,
      net_amount: 0,
      net_amount_fc: 0,
      vat_amount: 0,
      vat_amount_fc: 0,
      total_amount: 0,
      total_amount_fc: 0,
      vat_reduction_amount: 0,
      vat_reduction_amount_fc: 0,
      stoppage_amount: 0,
      stoppage_amount_fc: 0,
      receivable_amount: 0,
      receivable_amount_fc: 0
    };

    if (formData.rows) {
      const currencyRate = calculatedFormData.invoice.currency_rate || 1;

      calculatedFormData.rows = formData.rows.map(frow => {
        let row = { ...frow };
        if (row.item_id === 0) row.item_id = null;

        row.quantity = Util.convertMoneyToDecimal(row.quantity);

        row.discount_amount_fc = Util.convertMoneyToDecimal(
          row.discount_amount_fc
        );
        row.discount_amount = row.discount_amount_fc * currencyRate;

        row.unit_price_fc = UtilHelper.money.convertMoneyToDecimal(
          row.unit_price_fc
        );
        row.unit_price = row.unit_price_fc * currencyRate;

        row.net_amount_fc = Util.convertMoneyToDecimal(row.net_amount_fc);
        row.net_amount = row.net_amount_fc * currencyRate;
        row.vat_reduction_amount_fc = Util.convertMoneyToDecimal(row.vat_reduction_amount_fc);
        row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;

        row.discount_rate = Util.convertPercentToNumber(row.discount_rate);

        row.vat_amount_fc = Util.convertMoneyToDecimal(row.vat_amount_fc);
        row.vat_amount = row.vat_amount_fc * currencyRate;

        if (row.vat_rate_type_id && !row.vat_rate) {
          const vatRate = Sugar.Array.find(self.props.vatRateTypes, function(
            type
          ) {
            return type.id === row.vat_rate_type_id;
          });
          if (vatRate) row.vat_rate = vatRate.rate;
        } else row.vat_rate = row.vat_rate || 0;
        row.stoppage_amount_fc = 0;
        //row.vat_reduction_amount_fc = 0;
        row.stoppage_amount_fc = (row.net_amount_fc * Util.convertMoneyToDecimal(row.stoppage_ratio)) / 100;


        row.otherTaxes = [];

        row.total_amount_fc = row.net_amount_fc + row.vat_amount_fc;
        row.total_amount = row.total_amount_fc * currencyRate;

        row.stoppage_amount = row.stoppage_amount_fc * currencyRate;
        row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;
        row.vat_reduction_amount_fc = row.vat_reduction_amount_fc;

        footer.price_amount += row.net_amount + row.discount_amount;
        footer.price_amount_fc += row.net_amount_fc + row.discount_amount_fc;
        footer.net_amount += row.net_amount;
        footer.net_amount_fc += row.net_amount_fc;
        footer.discount_amount += row.discount_amount;
        footer.discount_amount_fc += row.discount_amount_fc;
        footer.vat_amount += row.vat_amount;
        footer.vat_amount_fc += row.vat_amount_fc;
        footer.total_amount += row.total_amount;
        footer.total_amount_fc += row.total_amount_fc;
        footer.vat_reduction_amount += row.vat_reduction_amount;
        footer.vat_reduction_amount_fc += row.vat_reduction_amount_fc;
        footer.stoppage_amount += row.stoppage_amount;
        footer.stoppage_amount_fc += row.stoppage_amount_fc;

        console.log(row);

        return row;
      });
    }
    footer.net_amount = (footer.total_amount-footer.vat_reduction_amount) - footer.stoppage_amount;
    footer.net_amount_fc = (footer.total_amount_fc-footer.vat_reduction_amount_fc) - footer.stoppage_amount_fc;
    footer.receivable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.receivable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;
    footer.received_amount_fc = 0;
    footer.received_amount = 0;
    calculatedFormData.footer = footer;

    let tableData = this.state.tableData;

    Object.keys(footer).map(key => {
      if (footer[key] > 0) {
        tableData.footerTable.map(tableEl => {
          if (tableEl.id === key) {
            tableEl.visible = true;
          }

          return tableEl;
        });
      }

      return (tableData.footerData[key] = Util.convertDecimalToMoney(
        footer[key]
      ));
    });
    this.setState({ tableData: tableData });

    return calculatedFormData;
  };

  onChange(type, field, value) {
    const self = this;
    let formData = this.state.formData;

    formData[type] = {
      ...formData[type],
      [field]: value,
    };
    if (type === "reasons") {
      self.setState({ reason_id: field });
    }
    self.setState({ formData: formData }, function() {
      self.resetErrorForInvoice(field);
    });
  }

  onRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  handleSuccess(redirect = false) {
    const self = this;
    toastr.success("Başarılı şekilde kaydedildi");
    self.setState({ saving: false, saved: true });
    if (!self.props.router.params.id) {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        let tableData = this.state.tableData;
        tableData = Object.assign(tableData, {
          footerData: {
            discount_amount: "0,00",
            discount_amount_fc: "0,00",
            discount_rate: "0,00",
            net_amount: "0,00",
            net_amount_fc: "0,00",
            price_amount: "0,00",
            price_amount_fc: "0,00",
            receivable_amount: "0,00",
            receivable_amount_fc: "0,00",
            received_amount: "0,00",
            received_amount_fc: "0,00",
            stoppage_amount: "0,00",
            stoppage_amount_fc: "0,00",
            total_amount: "0,00",
            total_amount_fc: "0,00",
            vat_amount: "0,00",
            vat_amount_fc: "0,00",
            vat_reduction_amount: "0,00",
            vat_reduction_amount_fc: "0,00"
          }
        });

        this.setState(
          {
            tableData: tableData,
            formData: self.props.defaultFormData()
          },
          function() {
            self.fetchItems(false);
            self.renewTableData();
          }
        );
      }
    } else {
      if (redirect === true) {
        this.props.redirectToBase();
      } else {
        this.props.router.push("/dashboard/salesInvoice/soleprofv2/add");
      }

      if (self.props.onSave) {
        self.props.onSave();
      }
    }
  }

  onSave(refresh, isChecked) {
    const redirect = arguments[0];
    const self = this;


    let formData = self.state.formData;

    const baseCurrency = formData.invoice.currency;
    for (var fi in formData.rows) {
      const items = this.state.tableData.rows[fi][0].data;
      for (var i in items) {
        if (items[i].id === formData.rows[fi].item_id) {
          // tedarikçiye iade
          // if (this.props.documentType === 2) {
          //   if (items[i].currency_purchase !== baseCurrency) {
          //     toastr.error(
          //         "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
          //     );
          //     return;
          //   }
          // } else {
          //   if (items[i].currency_sales !== baseCurrency) {
          //     toastr.error(
          //         "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
          //     );
          //     return;
          //   }
          // }
        }
      }
    }



    self.setState({ saving: true });

    self.validateInvoiceLines(true, function(linesOk) {
      if (linesOk) {
        self.saveInvoice(function(invoiceOk) {
          if (invoiceOk) {
            let formData = self.state.formData;
            formData.rows = formData.rows.map(function(row) {
              row.soleprofv2_id = formData.invoice.id;
              return row;
            });
            self.saveInvoiceLines(function(invoiceLinesSaved) {
              if(isChecked === true){
                Api.get(`/soleprofv2/next/${self.props?.editId}`,function (res, error) {
                  if(res.item) {
                    const path = window.location.pathname;
                    const ex = path.split('/');
                    ex.pop();
                    ex.push(res.item.id);
                    self.setState({
                      saving: false
                    });
                    window.location.href = ex.join('/');
                  } else {
                    self.handleSuccess(true);
                  }
                })
              } else {
                if (invoiceLinesSaved) {
                  self.handleSuccess(redirect);
                } else {
                  self.setState({
                    saving: false
                  });
                }
              }
            });
          } else {
            self.setState({ saving: false });
            console.log("invoice not validated");
          }
        }, isChecked);
      } else {
        self.setState({ saving: false });
        console.log("lines not validated");
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  renewTableData() {
    const self = this;
    let tableData = this.state.tableData;
    const formData = this.state.formData;
    var stoppageJsonParse = JSON.parse(localStorage.getItem("stoppage_code"));
    if(stoppageJsonParse.findIndex(o => o.id == 0)){
      stoppageJsonParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0, rate: 0,});
    }
    tableData.rows.forEach(function(row, index) {
      //tableData.rows[index][10].onClick = self.onOtherTaxClick.bind(self,index)
      tableData.rows[index][0].onAddNewItemClick = self.onAddNewItemClick.bind(
        self,
        index
      );

      tableData.rows[index][9]["data"] = self.props.vatRateTypes;
      tableData.rows[index][6]["data"] = self.state.vatReductionTypeCodeList;
      tableData.rows[index][3]["data"] = stoppageJsonParse;
      tableData.rows[index][5]["data"] = self.props.units;

    });
    self.setState({ tableData: tableData });
  }

  onInvoiceLineRemoved(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = Sugar.Array.removeAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.invoice.id && row.id) {
      deleteRowIds.push(row.id);
    }

    this.prepareFormToPost(this.state.formData);
    this.renewTableData();
  }

  onInvoiceLineAdded() {
    this.renewTableData();
    this.validateInvoiceLines(false, null);
  }

  getDiscountAmount(rowData) {
    return rowData.unit_price_fc * rowData.quantity * rowData.discount_rate;
  }

  getNetAmount(rowData) {
    return (
      rowData.unit_price_fc * rowData.quantity - rowData.discount_amount_fc
    );
  }

  getDiscountRate(rowData) {
    return (
      rowData.discount_amount_fc / (rowData.unit_price_fc * rowData.quantity)
    );
  }

  getVatAmount(rowData) {
    return rowData.net_amount_fc * rowData.vat_rate;
  }

  getReductionAmount(rowData) {
    if(rowData.vat_reduction_type_id!=0){
      let find = JSON.parse(localStorage.getItem("fetch_vat_reduction_type_code")).data.find((element) => {
        return element.id === rowData.vat_reduction_type_id;
      });
      return Util.convertDecimalToMoney(rowData.vat_amount_fc*find.rate);
    }else{
      return 0;
    }
  }

  resetErrorForInvoiceLine(index, field) {
    let errors = this.state.errors;
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;

    this.setState({ errors: errors });
  }

  resetErrorForInvoice(field) {
    let errors = this.state.errors;
    errors.invoice[field] = false;

    this.setState({ errors: errors });
  }

  onInvoiceLineReallyChange(index, field, value, oldValue) {
    this.resetErrorForInvoiceLine(index, field);
    if (field === "item_id") {
      this.resetErrorForInvoiceLine(index, "vat_rate_type_id");
      this.resetErrorForInvoiceLine(index, "unit_price_fc");
      this.resetErrorForInvoiceLine(index, "unit_id");
      this.resetErrorForInvoiceLine(index, "description");
    }

    if (
      field === "item_id" ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      this.resetErrorForInvoiceLine(index, "discount_amount_fc");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "discount_amount_fc") {
      this.resetErrorForInvoiceLine(index, "discount_rate");
      this.resetErrorForInvoiceLine(index, "net_amount_fc");
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }

    if (field === "vat_rate_type_id") {
      this.resetErrorForInvoiceLine(index, "vat_amount_fc");
    }
  }

  onInvoiceLineChange(index, rowData, field) {
    const self = this;
    let formData = this.state.formData;
    //  self.resetErrorForInvoiceLine(index,field)
    if (field === "item_id") {
      let items = this.state.items;
      let itemId = rowData.item_id;
      formData.rows[index].description = items[itemId].item_name;
      formData.rows[index].item_code = items[itemId].item_code;
      formData.rows[index].description +=
        " - " + (items[itemId].sales_description || "");
      formData.rows[
        index
      ].unit_price_fc = UtilHelper.money.convertDecimalToMoney4(
        items[itemId].sales_unit_price
      );
      formData.rows[index].vat_rate_type_id =
        items[itemId].sales_vat_rate_type_id;
      formData.rows[index].unit_id = items[itemId].unit_id;
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      let tableData = this.state.tableData;
      this.setState({ tableData: tableData });
      self.onInvoiceLineChange(index, formData.rows[index], "vat_rate_type_id");
    }

    if (
      field === "item_id" ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[
        index
      ].discount_amount_fc = this.getDiscountAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].discount_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "vat_reduction_type_id") {
      const calculatedFormData = this.prepareFormToPost(formData);
      formData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
    } else if (field === "discount_amount_fc") {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);

      calculatedFormData.rows[index].discount_rate = this.getDiscountRate(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].discount_rate = Util.convertNumberToPercent(
        calculatedFormData.rows[index].discount_rate
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormData.rows[index]);
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "vat_rate_type_id") {
      formData.rows[index][field] = rowData[field];
      const vatRate = Sugar.Array.find(this.props.vatRateTypes, function(type) {
        return type.id === rowData[field];
      });
      if (vatRate) {
        formData.rows[index].vat_rate = vatRate.rate;
        const calculatedFormDataq = this.prepareFormToPost(formData);
        calculatedFormDataq.rows[index].vat_amount_fc = this.getVatAmount(
          calculatedFormDataq.rows[index]
        );
        formData.rows[index].vat_reduction_amount_fc = this.getReductionAmount(calculatedFormDataq.rows[index]);
        formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
          calculatedFormDataq.rows[index].vat_amount_fc
        );
      }
    } else {
      formData.rows[index][field] = rowData[field];
    }
    if (field == "vat_rate_type_id") {
      if (rowData.vat_rate_type_id == 1 && this.state.reason_id == 0) {
        this.openIstisnaModal();
      }
    }
    var istisnaKontrol = false;
    this.state.formData.rows.forEach(function (row) {
      if (row.vat_rate_type_id == 1) {
        istisnaKontrol = true;
      }
    });
    if (istisnaKontrol == false) {
      this.setState({
        reason_id: 0,
        istisna_fatura_buton: "İstisna Kodu Ekle",
      });
    }
    self.setState({ formData: formData }, function() {
      self.prepareFormToPost(self.state.formData);
    });
  }

  validateInvoiceLines(all, next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    const url = "/soleprofv2/validation";
    let endpoints = [];
    calculatedFormData.rows.forEach(function(row) {
      endpoints.push({ endpoint: url, method: "post", params: row });
    });

    if (!all) {
      endpoints.pop(); //son eklenen rowu kontrol etme
    }

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        if (next !== null) next(true);
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });
      }
    });
  }

  deleteInvoiceLines(next) {
    const self = this;
    if (self.state.deleteRowIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteRowIds.forEach(function(id) {
        endpointWithParams.push({
          endpoint: "/soleprofv2Line/" + id,
          method: "delete"
        });
      });

      Api.paralelAll(endpointWithParams, function(responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveInvoiceLines(next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);

    let formData = this.state.formData;
    let endpoints = [];
    calculatedFormData.rows.forEach(function(row) {
      row.istisna_kodu = self.state.reason_id == 0 ? null : self.state.reason_id;
      var w = self.state.vatReductionTypeCodeList.findIndex(o => o.id == row.vat_reduction_type_id);
      row.vat_reduction_type_code = self.state.vatReductionTypeCodeList[w]['code'];
      row.vat_reduction_type_ratio = self.state.vatReductionTypeCodeList[w]['rate'];
      const method = row.id ? "put" : "post";
      const url = row.id ? "/soleprofv2Line/" + row.id : "/soleprofv2Line";
      endpoints.push({ endpoint: url, method: method, params: row });
    });

    Api.serialPutPost(endpoints, function(responses, errors) {
      if (errors.length === 0) {
        responses.forEach(function(response, index) {
          formData.rows[index].id = response.soleprofv2Line.id;

          if (!!response.warnings) {
            response.warnings.forEach(warning => {
              toastr.warning(warning);
            });
          }
        });
        self.setState({ formData: formData }, function() {
          if (next !== null) {
            self.deleteInvoiceLines(next);
          }
        });
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function() {
          if (next !== null) next(false);
        });

        if (errors.message) toastr.error(Util.getErrorAsStr(errors));
      }
    });
  }

  saveInvoice(next,isChecked) {
    const self = this;
    const formData = { ...this.state.formData };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    let manualValidations = true;
    let formErrors = self.state.errors;
    if (calculatedFormData.footer.price_amount_fc === 0) {
      toastr.error("Toplam fatura tutarı sıfır olamaz");
      manualValidations = false;
    }

    if (!manualValidations) return next(false);

    const url = formData.invoice.id
      ? "/soleprofv2/" + formData.invoice.id
      : "/soleprofv2";
    const method = formData.invoice.id ? "put" : "post";
    const postData = Object.assign(
      {},
      calculatedFormData.invoice,
      calculatedFormData.footer
    );

    if (postData.customer_id === 0) {
      postData.customer_id = null;
    }
    if(this.props.addDocument?.uri && postData.document_url !== this.props.addDocument?.uri){
      postData.document_url = this.props.addDocument?.uri; 
    }
    postData.is_checked = isChecked === true;
    
    if (formData.invoice.id) postData.updated_id = formData.invoice.id;
    Api[method](url, postData, function(response, errors) {
      if (errors) {
        if (errors.message) toastr.error(Util.getErrorAsStr(errors));

        formErrors.invoice = Object.assign({}, formErrors.invoice, errors);
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
      } else if (!manualValidations) {
        self.setState({ errors: formErrors }, function() {
          next(false);
        });
        next(false);
      } else {
        if (!response.soleprofv2) {
          toastr.error("Muhtemel SQL Hatası");
          return next(false);
        }
        let formData = self.state.formData;
        formData.invoice.id = response.soleprofv2.id;
        self.setState({ formData: formData }, function() {
          next(true);
        });
      }
    });
  }

  saveNormal(refresh, isChecked) {
    this.onSave(refresh, isChecked);
  }
  saveExempt = () => {
    const formData = { ...this.state.formData };
    if (formData.invoice) {
      const invoice = { ...formData.invoice };
      invoice.is_exempt = 1;
      formData.invoice = invoice;
    }
    this.setState({ formData }, () => {
      this.onSave(true);
    });
  };
  openIstisnaModal = () => {
    this.setState({ openIstisnaModal: true });
  };
  saveIstisnaModal = () => {
    this.state.istisna_fatura_buton = "İstisna Kodu Ekle*";
    this.closeIstisnaModal();
  };

  closeIstisnaModal() {
    this.setState({ openIstisnaModal: false });
  }
  render() {
    var exemption_reasons = JSON.parse(
        localStorage.getItem("exemption_reasons")
    );
    let fromWhos = this.props.fromWhos.map(function(fromWho) {
      // fromWho.name = fromWho.firstname;
      // if (fromWho.lastname) fromWho.name += " " + fromWho.lastname;
      fromWho.name = fromWho.fullname;
      return fromWho;
    });

    let headerTitle = "SERBEST MESLEK MAKBUZU";

    return (
      <AddSoleprofv2InvoiceHeader
        fromWhos={fromWhos}
        fromWhoId={
          this.state.formData.invoice.customer_id ||
          this.state.formData.invoice.supplier_id
        }
        currencies={this.props.currencies}
        formData={this.state.formData.invoice}
        errors={this.state.errors.invoice}
        onChange={this.onChange.bind(this)}
        onRefresh={this.onRefresh.bind(this)}
        saved={this.state.saved}
        title={headerTitle}
      >
        <EkoInputTableButtonGroup
          data={this.state.tableData}
          currency={this.state.formData.invoice.currency}
          formData={this.state.formData}
          errors={this.state.errors}
          onSave={(refresh, isChecked) => this.saveNormal(refresh, isChecked)}
          saving={this.state.saving}
          buttonSaveLabel={"Kaydet"}
          onRowAdd={this.onInvoiceLineAdded.bind(this)}
          defaultRow={this.props.defaultRowData}
          onCancel={this.onCancel.bind(this)}
          onChangeRow={this.onInvoiceLineChange.bind(this)}
          onRealChange={this.onInvoiceLineReallyChange.bind(this)}
          onChange={() => null}
          onRowDelete={this.onInvoiceLineRemoved.bind(this)}
          formId={"add_soleprofv2"}
          buttons={[
            {
              id: "saveExempt",
              onClick: this.saveExempt,
              labelText: "Proforma / Taslak Olarak Kaydet",
            }
          ]}
          isCheckButton={true}
          editId={this.props?.editId}
          updatedAt={this.state?.formData?.invoice?.is_checked ? this.state?.formData?.invoice?.updated_at : null}
        />
        <div className={"row"}>
          <div className={"col-md-12"}>
            <a
                onClick={this.openIstisnaModal}
                style={{ top: -15, opacity: 1, position: "absolute" }}
            >
              {this.state.istisna_fatura_buton}

            </a>
          </div>
        </div>
        <EkoModal
            showModal={this.state.openIstisnaModal}
            onHide={this.closeIstisnaModal.bind(this)}
            title={"İSTİSNA KODU SEÇİNİZ"}
        >
          <p style={{ color: "red", "text-align": "center" }}>
            İstisna Faturası Değilse Seçmeden Devam Ediniz.
          </p>
          <div className={"row"}>
            <EkoFormSelect
                onChange={this.onChange.bind(this)}
                label="KDV İstisna Kodu : "
                lableMd={4}
                options={exemption_reasons}
                id="reasons"
                optionId={"code"}
                optionValue={"description"}
                searchable={true}
                value={this.state.reason_id}
            />
            <div style={{ height: "30px" }}></div>
            <div style={{ "text-align": "right" }}>
              <EkoFormButton
                  label={"Kaydet"}
                  faClass="fa fa-plus"
                  buttonsType={"default"}
                  className="btn blue"
                  onClick={this.saveIstisnaModal}
              />
            </div>
          </div>
        </EkoModal>
        <AddItem
          showModal={this.state.showAddItem}
          formData={false}
          onSave={this.onItemSaved.bind(this)}
          onCancel={() => {
            this.setState({ showAddItem: false });
          }}
          errors={false}
        />
      </AddSoleprofv2InvoiceHeader>
    );
  }
}

function mapStateToProps(state) {
  return {
    vatRateTypes: state.data.vatRateTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    units: state.data.units,
    taxTypes: state.data.taxTypes,
    currencies: state.data.currencies,
    addDocument: state.page.addDocument
  };
}

export default connect(mapStateToProps, actions)(withRouter(AddSoleprofv2Invoice));
