import React, { Component } from "react";
import EkoModal from "../elements/EkoModal";
import {
  EkoFormButton,
  EkoFormInputText,
  EkoFormSelect
} from "../elements/EkoForm";
import { Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import { FETCH_TEMPLATES } from "../../actions/types";

class PrintModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { print: {} },
      saving: false,
      errors: {},
      formFields: {},
      status: 0,
      pdfUrl: null,
      title: "Şablon Seç"
    };
  }

  componentWillMount() {
    this.props.fetchData("/template/showAll", FETCH_TEMPLATES);
  }

  componentWillReceiveProps(nextProps) {
    let print = {};
    let formData = this.state.formData;
    if (nextProps.legalNo && nextProps.legalSerial) {
      print = Object.assign({}, formData.print, {
        legal_no: nextProps.legalNo,
        legal_serial: nextProps.legalSerial
      });
    } else if (nextProps.lastPrintNo && nextProps.lastPrintSerial) {
      print = Object.assign({}, formData.print, {
        legal_no: parseInt(nextProps.lastPrintNo, 10) + 1,
        legal_serial: nextProps.lastPrintSerial
      });
    }
    if (nextProps.templates) {
      if (nextProps.templates.length !== 0 && !formData.template_id) {
        let properties = JSON.parse(nextProps.templates[0].properties);
        print.is_note = false;
        print.template_id = nextProps.templates[0].id;
        properties.canvasElements.forEach(item => {
          if (item.variableName === "specialNote") print.is_note = true;
        });
      }
    }
    this.setState({ formData: { print: print } });
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    if (field === "template_id") {
      let selected = this.props.templates.filter(item => item.id === value);
      let properties = JSON.parse(selected[0].properties);
      formData.is_note = false;
      properties.canvasElements.forEach(item => {
        if (item.variableName === "specialNote") formData.is_note = true;
      });
    }
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  // onPrint() {
  //     const self = this;
  //     self.setState({saving: true});
  //     let params = {
  //         object_id: this.props.objectId,
  //         template_id: this.state.formData.print.template_id,
  //         specialNote: this.state.formData.print.specialNote
  //     }
  //     let errors = self.state.errors;
  //     Api.postArrayBuffer('/template/print', params, (response, err) => {
  //         if (response) {
  //             var file = new Blob([response], {type: 'application/pdf'});
  //             var fileURL = URL.createObjectURL(file);
  //             self.setState({saving: false, errors: {}, pdfUrl: fileURL});
  //         } else {
  //             let decodedString = String.fromCharCode.apply(null, new Uint8Array(err));
  //             errors.print = JSON.parse(decodedString);
  //             self.setState({saving: false, errors: errors});
  //         }
  //     })
  // }

  closeModal() {
    this.setState({
      status: 0,
      saving: false,
      errors: {},
      formData: { print: {} },
      title: "Şablon Seç"
    });
    this.props.closeModal();
  }

  isPrintSave() {
    const self = this;
    self.setState({ saving: true });
    let params = {
      updated_id: this.props.objectId,
      legal_serial: this.state.formData.print.legal_serial,
      legal_no: this.state.formData.print.legal_no
    };
    let errors = self.state.errors;
    Api.post("/salesInvoice/isPrintUpdate", params, (response, err) => {
      if (response) {
        toastr.success("Başarılı", "Fatura yazıldı olarak işaretlendi");
        self.props.fetchRefresh();
        self.closeModal();
      } else {
        if (err.message) toastr.error("Hata", err.message);
        else errors.print = err;
        self.setState({ saving: false, errors: errors });
      }
    });
  }

  go() {
    const self = this;
    let status = 2;
    if (this.state.status === 0 && this.state.formData.print.is_note) {
      status = 1;
      this.setState({ status: status, title: "Faturanız Yazdırmaya Hazır" });
    } else if (this.state.status === 2)
      setTimeout(() => {
        status = 3;
        self.setState({
          status: status,
          title: "Faturanızı Yazdırıldı Olarak İşaretleyin"
        });
      }, 750);
    else {
      this.setState({ status: status });
    }
  }

  renderContent() {
    const printProps = {
      onChange: this.setFieldChange.bind(this, "print"),
      onMount: this.setFieldMount.bind(this, "print"),
      formData: this.state.formData.print,
      errors: this.state.errors.print
    };
    if (this.state.status === 1)
      return (
        <div style={{ textAlign: "center" }}>
          <p className="bg-warning text-white">
            Yazdırma şablonunuzda yazdırmaya özel not alanı bulunmaktadır,
            sadece bu yazdırmada kullanılacak notunuzu aşağıdaki alana
            yazabilirsiniz.
          </p>
          <EkoFormInputText
            {...printProps}
            id={"specialNote"}
            isVertical={true}
            label=""
            type="textarea"
          />
        </div>
      );
    else if (this.state.status === 2)
      return (
        <div>
          <div
            style={{ textAlign: "center" }}
            className="bg-warning text-white"
          >
            <span>
              <i className="fa fa-exclamation-triangle" /> Dikkat! Dosyayı
              yazdırırken Sayfaya Sığdır (Fit to Page) seçeneğinin seçili
              olmadığından emin olun.
            </span>
          </div>
          <br />
          1. Fatura basımında kullanılacak bilgileri "FATURAYI İNDİR" butonu ile
          açın. <br />
          2. Bu dosyanın içeriğinin doğru olduğunu kontrol edin. <br />
          3. Bu dosyayı yazıcı içerisine yerleştirdiğiniz boş fatura üzerine
          yazdırın. <br />
          4. Daha sonra seri numarasını kaydederek faturayı yazdırıldı olarak
          işaretleyin. <br />
        </div>
      );
    else if (this.state.status === 3)
      return (
        <div>
          <div
            style={{ textAlign: "center" }}
            className="bg-warning text-white"
          >
            <span>
              <i className="fa fa-exclamation-triangle" /> Yazdırdığınız fatura
              numarası ile sistemdeki fatura numarasının eşitliğini kontrol
              edin. Son yazdırdığınız fatura numarası{" "}
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase"
                }}
              >
                {this.props.lastPrintSerial + this.props.lastPrintNo}
              </span>
            </span>
          </div>
          <br />
          <div>
            <EkoFormInputText
              {...printProps}
              id={"legal_serial"}
              type="mask"
              colMd={2}
              labelMd={3}
              tooltip={true}
              mask="aaaaaa"
              maskChar=""
              label="Seri/Sıra No"
            />
            <EkoFormInputText
              {...printProps}
              id={"legal_no"}
              type="mask"
              mask="9999999999999999"
              tooltip={true}
              maskChar=""
              colMd={4}
              labelMd={1}
              label="/"
            />
          </div>
          <br />
          <br />
          <div className="row">
            <hr />
            Yazdırma işlemini tamamladıktan sonra faturayı yazdırıldı olarak
            işaretleyin. Bu sayede fatura numaralarının otomatik olarak takip
            edilmesini sağlayabilirsiniz.
          </div>
        </div>
      );
    else
      return (
        <EkoFormSelect
          {...printProps}
          id="template_id"
          isVertical={true}
          defaultText="Seçiniz"
          options={this.props.templates}
          label="Şablonlar"
        />
      );
  }

  renderButtons() {
    if (this.state.status === 1)
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label={"Devam Et"}
            disabled={
              !(this.props.templates && this.props.templates.length !== 0)
            }
            faClass="fa fa-arrow-right"
            onClick={this.go.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
    else if (this.state.status === 2)
      return (
        <div className="row buttonProccess2">
          <form
            target="_blank"
            action={process.env.REACT_APP_API_URL + "/template/print"}
            method="POST"
            encType="application/json"
          >
            <input type="hidden" name="object_id" value={this.props.objectId} />
            <input
              type="hidden"
              name="template_id"
              value={this.state.formData.print.template_id}
            />
            <input
              type="hidden"
              name="specialNote"
              value={this.state.formData.print.specialNote}
            />
            <button
              className="btn btn-default blue"
              disabled={
                !(this.props.templates && this.props.templates.length !== 0)
              }
              onClick={this.go.bind(this)}
            >
              {" "}
              FATURAYI İNDİR
            </button>
            <EkoFormButton
              label="İptal"
              className="btn"
              onClick={e => {
                e.preventDefault();
                this.closeModal();
              }}
            />
          </form>
        </div>
      );
    else if (this.state.status === 3)
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label="Yazdırıldı Olarak İşaretle"
            isLoading={this.state.saving}
            onClick={this.isPrintSave.bind(this)}
          />
          <EkoFormButton
            label="İşaretlemeden Kapat"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
    else
      return (
        <div className="row buttonProccess2">
          <EkoFormButton
            label={"Yeni Şablon"}
            faClass="fa fa-plus"
            onClick={() => {
              this.props.router.push("/dashboard/template/new");
            }}
          />
          <EkoFormButton
            label={"Devam Et"}
            faClass="fa fa-arrow-right"
            disabled={
              !(this.props.templates && this.props.templates.length !== 0)
            }
            onClick={this.go.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={this.closeModal.bind(this)}
          />
        </div>
      );
  }

  render() {
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={this.state.title}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        {this.props.templates && this.props.templates.length !== 0 ? (
          <div className="row">
            <div
              className={
                this.state.status !== 0
                  ? "col-md-push-1 col-md-10"
                  : "col-md-push-3 col-md-6"
              }
            >
              {this.renderContent()}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <span>
              {" "}
              Eklemiş olduğunuz şablon bulunmamaktadır. Şablon eklemek için{" "}
              <Link to="/dashboard/template/new">tıklayınız</Link>
            </span>
          </div>
        )}
        <Modal.Footer>
          <br />
          {this.renderButtons()}
        </Modal.Footer>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    templates: state.data.templates
  };
}

export default connect(mapStateToProps, actions)(PrintModal);
