import React, {Component} from 'react'
import PropTypes from 'prop-types'
import EkoModal from '../elements/EkoModal'
import EkoSpinner from '../elements/EkoSpinner'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import {FETCH_VIDEO_CONFIGS} from '../../actions/types'

class   EkoVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            show: true,
        }
    }

    static propTypes = {
        path: PropTypes.string.isRequired,
    }

    componentWillMount(){
        let config = this.props.config ? this.props.config : false;
        if (config){
            let show = typeof config[this.props.path] !== "undefined" ? config[this.props.path] : true;
            this.setState({show: show})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.path !== nextProps.path || this.props.config !== nextProps.config){
            let config = nextProps.config ? nextProps.config : false;
            if (config){
                let show = typeof config[nextProps.path] !== "undefined" ? config[nextProps.path] : true;
                this.setState({show: show})
            }
        }
    }

    shouldComponentUpdate(nextProps,nextState) {
        return this.props.path !== nextProps.path
            || this.state.show !== nextState.show
            || this.state.showModal !== nextState.showModal
            || this.props.videos !== nextProps.videos;
    }

    onClose(){
        let config = this.props.config ;
        config[this.props.path] = false;
        localStorage.setItem("videos_config",JSON.stringify(config))
        this.props.updateFetchedData(FETCH_VIDEO_CONFIGS,JSON.parse(localStorage.getItem("videos_config")))
        this.setState({show: false})
    }

    render() {
        if (!this.props.videos) return <EkoSpinner/>
        const data = this.props.videos[this.props.path]
        if (!data || !this.state.show) return null
        return <div className="alert alert-block alert-started fade in">
            <button type="button" onClick={this.onClose.bind(this)} className="close" data-dismiss="alert"/>
            <img src={data.cover || 'http://placehold.it/300x169'} alt="Resim Bulunamadı!" className="place-start" onClick={() => {
                this.setState({showModal: true})
            }}/>
            <div style={{marginLeft: "26%"}}>
                {
                    data.title ?
                        <h4 className="alert-heading">{data.title}</h4>
                        : ""
                }
                <p dangerouslySetInnerHTML={{__html: data.description}}/>
            </div>
            <div className="clearfix"/>
            <EkoModal
                showModal={this.state.showModal}
                title={'Kullanım Kılavuzu'}
                onHide={() => {
                    this.setState({showModal: false})
                }}
                spinner={false}>
                <iframe width="560" height="315" src={data.url} frameBorder="0"
                        allowFullScreen></iframe>
            </EkoModal>
        </div>

    }

}

function mapStateToProps(state) {
    return {
        videos: state.data.videos,
        config: state.data.videoConfigs,
    }
}

export default connect(mapStateToProps, actions)(EkoVideo)
