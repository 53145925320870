import React from 'react';
import PropTypes from 'prop-types';
import TableColumnInput from "./TableColumnInput";
import salaryService from "../../../services/SalaryService";

class TinvocieTableTr extends React.Component {

    renderColumnBody = (column) => {

        let value =  salaryService.getValue(column.name);


        if (!value) {

            value = column.defaultValue !== undefined ? column.defaultValue.toString() : "";
        }

        if (column.input) {


            return <TableColumnInput type={column.input.type} value={value} name={column.name}/>
        }


        if (column.title) {
            return column.title;
        }


        // eğer içerik bir input ya da başlık  boş döndürüyoruz
        return value || null;
    };

    render() {

        let style = this.props.centered ? {textAlign:'center'} : {};

        return <tr>
            {this.props.columns.map((column, index) => {

                let otherProps = {};

                if (column.width) {
                    otherProps['width'] = column.width
                }

                if (column.colSpan) {
                    otherProps.colSpan = column.colSpan;
                }

                if (column.rowSpan) {
                    otherProps.rowSpan = column.rowSpan;
                }


                return <th style={style} key={index} className={column.className} {...otherProps}>
                    {this.renderColumnBody(column)}
                </th>;
            })}
        </tr>
    }

}

TinvocieTableTr.propTypes = {
    centered: PropTypes.bool,
    columns: PropTypes.array.isRequired
};

TinvocieTableTr.defaultProps = {
    centered: false
};

export default TinvocieTableTr;