import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import AddPurchaseInvoice from "./AddPurchaseInvoice";
import Api from "../../util/Api";
import Util from "../../util/Util";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { FETCH_CUSTOMERS_ALL, FETCH_SUPPLIERS_ALL } from "../../actions/types";
import UtilHelper from "../../util/UtilHelper";
import { toastr } from "react-redux-toastr";
import moment from "moment";

class SalesInvoiceAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.defaultFormData(),
      errors: {},
      documentType: false,
      isGetInUpdate: false,
      fromWhos: [],
      isGetIn: false,
      calculate: false,
      otherAmount: null
    };
  }

  defaultRowData() {
    return Object.assign(
      {},
      {
        item_id: 0,
        fixed_asset_type_id: 0,
        item_code: "",
        description: "",
        quantity: 1,
        unit_price_fc: 0.0,
        discount_rate: 0,
        unit_id: 0,
        warehouse_id: 0,
        nace_code_id: 0,
        discount_amount_fc: 0,
        expense_category_id: this.props.route.type=='expenseInvoice'?0:null,
        net_amount_fc: 0,
        vat_rate_type_id: 0,
        vat_amount_fc: 0
      }
    );
  }

  defaultFormData() {
    return {
      invoice: {},
      rows: [this.defaultRowData()],
      footer: {}
    };
  }

  defaultErrors() {
    return {
      rows: [
        {
          item_id: false,
          fixed_asset_type_id: false,
          description: false,
          quantity: false,
          unit_price_fc: false,
          discount_rate: false,
          unit_id: false,
          warehouse_id: false,
          discount_amount_fc: false,
          net_amount_fc: false,
          vat_rate_type_id: false,
          vat_amount_fc: false,
          otherTaxes: false
        }
      ],
      invoice: {},
      footer: {}
    };
  }

  fetchFromWho() {
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
  }

  setFromWhos(nextProps) {
    switch (this.state.documentType) {
      case 3:
        if (nextProps.customersAll) {
          const fromWhos = nextProps.customersAll.filter(function(customer) {
            return customer.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      case 4:
        if (nextProps.suppliersAll) {
          const fromWhos = nextProps.suppliersAll.filter(function(supplier) {
            return supplier.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      case 1:
      case 2:
        if (nextProps.suppliersAll) {
          const fromWhos = nextProps.suppliersAll.filter(function(supplier) {
            return supplier.is_active === 1;
          });
          this.setState({ fromWhos: fromWhos });
        }
        break;
      default:
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customersAll && nextProps.suppliersAll)
      this.setFromWhos(nextProps);
  }

  prepareInvoiceLineForEdit(line,otherTaxes) {
    let row = {};

    row.id = line.id;
    row.sales_invoice_id = line.sales_invoice_id;
    row.otherTaxess = line.otherTaxess;
    row.item_id = line.item_id;
    row.fixed_asset_type_id = line.fixed_asset_type_id;
    row.expense_category_id = line.expense_category_id;
    row.nace_code_id = line.nace_code_id;
    row.item_code = line.item_code;
    row.description = line.description;
    row.quantity = Util.convertDecimalToMoney7(line.quantity);
    row.unit_id = line.unit_id;
    row.warehouse_id = line.warehouse_id;
    row.vat_rate_type_id = line.vat_rate_type_id;
    row.discount_amount_fc = Util.convertDecimalToMoney(
      line.discount_amount_fc
    );
    row.discount_rate = Util.convertNumberToPercent(line.discount_rate);
    row.unit_price_fc = Util.convertDecimalToMoney7(
      line.unit_price_fc, 7
    );

    row.net_amount_fc = Util.convertDecimalToMoney(line.net_amount_fc);
    row.vat_amount_fc = Util.convertDecimalToMoney(line.vat_amount_fc);
    row.otherTaxes = false;
    if (otherTaxes && otherTaxes.length > 0) {
      row.otherTaxes = [];
      otherTaxes.forEach(function(otherTax) {
        let tax = {};
          tax.id = otherTax.id;
          tax.sales_invoice_id = otherTax.sales_invoice_id;
          tax.sales_invoice_line_id = otherTax.sales_invoice_line_id;
        tax.tax_type_code = otherTax.tax_type_code;
        tax.tax_type_id = otherTax.tax_type_id;
        tax.vat_reduction_type_code = otherTax.vat_reduction_type_code;
        tax.tax_amount_fc = Util.convertDecimalToMoney(otherTax.tax_amount_fc);
        tax.tax_rate = Util.convertNumberToPercent(otherTax.tax_rate);
        row.otherTaxes.push(tax);
      });
    }
    return row;
  }

  prepareInvoiceForEdit(invoice) {
    let ret = {};

    ret.id = invoice.id ? invoice.id : null;
    ret.currency = invoice.currency;
    ret.document_type_tag = invoice.document_type_tag;

    ret.legal_no = invoice.legal_no;
    ret.legal_serial = invoice.legal_serial;
    ret.legal_no2 = invoice.legal_no2;
    ret.document_type = invoice.document_type;
    ret.description = invoice.description ? invoice.description : null;
    ret.warehouse_id = invoice.warehouse_id;
    ret.stock_status = invoice.stock_status;
    ret.htmlFile = invoice.htmlFile;
    ret.customer_id = invoice.customer_id
      ? parseInt(invoice.customer_id, 10)
      : null;
    ret.supplier_id = invoice.supplier_id
      ? parseInt(invoice.supplier_id, 10)
      : null;
    ret.date = Util.convertFromDatabaseDate(invoice.date);
    ret.due_date = Util.convertFromDatabaseDate(invoice.due_date);
    ret.shipping_date = Util.convertFromDatabaseDate(invoice.shipping_date);
    ret.currency_rate = invoice.currency_rate
      ? Util.convertDecimalToString(invoice.currency_rate, 4)
      : null;
    ret.supplierData = invoice.supplierData || {};
    ret.customerData = invoice.customerData || {};
    ret.updated_at = invoice.updated_at || null;
    ret.is_checked = invoice.is_checked || false;
    return ret;
  }

  getIn() {
    const self = this;
    let uuid = this.props.params.uuid;
    let type = parseInt(this.props.params.type, 10);
    let isDetail = this.props.route.isDetail;
    let formData = this.state.formData;
    Api.post(
      "/eInvoice/getIn",
      { type: type, uuid: uuid, is_detail: isDetail },
      (response, error) => {
        if (response) {
          formData.invoice = self.prepareInvoiceForEdit(response.invoice);
          if(formData.invoice.legal_no.substring(0, 3)==='GIB' || formData.invoice.htmlFile?.length>0){
            formData.invoice.document_type_tag=2;
          }else{
            formData.invoice.document_type_tag=3;
          }
          let documentType = formData.invoice.document_type;
          let lines = [];
          if (response.lines && response.lines.length > 0) {
            lines = response.lines;
            formData.rows = lines.map(function(line) {
              return self.prepareInvoiceLineForEdit(line, false);
            });
            if(response.invoice?.pdfFile){
              const ty = response.invoice.pdfFile.split('.').pop()
              self.props.setAddDocument({uri: response.invoice.pdfFile, type: ty});
            }
            if(response.invoice?.htmlFile){
              const ty = response.invoice.htmlFile.split('.').pop()
              self.props.setAddDocument({uri: response.invoice.htmlFile, type: 'pdf'});
            }
            if (!formData.invoice.currency_rate) {
              Api.getCurrencyRate(
                formData.invoice.currency,
                moment(formData.invoice.date).format("YYYY-MM-DD"),
                function(rate, err) {
                  if (rate) {
                    formData.invoice.currency_rate = Util.convertDecimalToString(
                      rate,
                      4
                    );
                  }
                  self.setState(
                    {
                      formData: formData,
                      documentType: documentType,
                      isGetIn: true,
                      otherAmount: response.otherAmount
                    },
                    function() {
                      self.fetchFromWho();
                      self.setFromWhos(self.props);
                      setTimeout(() => {
                        self.setState({isGetInUpdate: true})
                      }, 700);
                    }
                  );
                }
              );
            } else
              self.setState(
                {
                  formData: formData,
                  documentType: documentType,
                  isGetIn: true,
                  otherAmount: response.otherAmount
                },
                function() {
                  self.fetchFromWho();
                  self.setFromWhos(self.props);
                  setTimeout(() => {
                    self.setState({isGetInUpdate: true})
                  }, 700);
                }
              );
          }
        } else {
          toastr.error("Hata", error.message);
          self.props.router.push("/dashboard/purchases/purchaseInvoice");
        }
      }
    );
  }

  callBackXMLData = (data) => {
    const self = this;
    const invoice = this.prepareInvoiceForEdit(data.invoice);
    invoice.document_type_tag = data.invoice?.profileId === 'EARSIVFATURA' ? 2 : 3;
    if(this.props.params?.id){
      invoice.id = this.props.params.id
    }
    const rows = data.rows.map(r => self.prepareInvoiceLineForEdit(r, r.otherTaxes || false))
    const d = {...this.state.formData, invoice, rows, inXml: true};

    this.setState({formData: d, otherAmount: data.invoice?.otherAmount})
    setTimeout(() => {
      this.setState({calculate: true});
    }, 500);
  }

  componentWillMount() {
    var self = this;

    if (this.props.params && this.props.params.id) {
      const invoiceId = this.props.params.id;
      let formData = this.state.formData;
      Api.paralel(
        [
          "/purchaseInvoice/" + invoiceId,
          "/purchaseInvoiceLine/showAll/" + invoiceId
        ],
        function(responses, error) {
          const docUrl = responses[0].purchaseInvoice?.document_url;
          if(docUrl && self.props.setAddDocument){
            const type = docUrl.split('.').pop()
            self.props.setAddDocument({uri: docUrl, type});
          }
          formData.invoice = self.prepareInvoiceForEdit(
            responses[0].purchaseInvoice
          );
          let documentType = formData.invoice.document_type;
          let otherAmount = responses[0].purchaseInvoice.taxes_excvat_amount
            ? responses[0].purchaseInvoice.taxes_excvat_amount
            : null;
          otherAmount = responses[0].purchaseInvoice.stoppage_amount
            ? responses[0].purchaseInvoice.stoppage_amount * -1
            : otherAmount;
          let taxApis = [];
          let lines = [];
          if (
            responses[1].purchaseInvoiceLines &&
            responses[1].purchaseInvoiceLines.length > 0
          ) {
            lines = responses[1].purchaseInvoiceLines;

            lines.forEach(function(line) {
              taxApis.push("/purchaseInvoiceTaxLine/showAll/" + line.id);
            });
            ////TAX START
            if (taxApis.length > 0) {
              Api.serialGet(taxApis, function(taxResponses, taxErrors) {
                console.log(taxResponses);
                formData.rows = lines.map(function(line, index) {
                  return self.prepareInvoiceLineForEdit(
                      line,
                      taxResponses[index].purchaseInvoiceTaxLines
                  );
                });

                self.setState(
                    { formData: formData, documentType: documentType },
                    function() {
                      self.fetchFromWho();
                      self.setFromWhos(self.props);
                    }
                );
              });
            } else {
              formData.rows = lines.map(function(line) {
                return self.prepareInvoiceLineForEdit(line, false);
              });
              self.setState(
                  { formData: formData, documentType: documentType },
                  function() {
                    self.fetchFromWho();
                    self.setFromWhos(self.props);
                  }
              );
            }
            ////TAX END
            formData.rows = lines.map(function(line) {
              return self.prepareInvoiceLineForEdit(line, false);
            });
            self.setState(
              {
                formData: formData,
                documentType: documentType,
                otherAmount: otherAmount
              },
              function() {
                self.fetchFromWho();
                self.setFromWhos(self.props);
              }
            );
          } else {
            self.setState(
              {
                formData: formData,
                documentType: documentType,
                otherAmount: otherAmount
              },
              function() {
                self.fetchFromWho();
                self.setFromWhos(self.props);
              }
            );
          }
        }
      );
    } else if (this.props.route && this.props.route.type) {
      let documentType = this.state.documentType;
      switch (this.props.route.type) {
        case "productServiceInvoice":
          documentType = 1;
          break;
        case "expenseInvoice":
          documentType = 2;
          break;
        case "customerReturnInvoice":
          documentType = 3;
          break;
        case "toFixedAssetInvoice":
          documentType = 4;
          break;
        default:
          break;
      }
      self.setState({ documentType: documentType }, function() {
        self.fetchFromWho();
        self.setFromWhos(self.props);
      });
    } else if (this.props.params && this.props.params.uuid) {
      let type = parseInt(this.props.params.type, 10);
      if (type === 1 || type === 2 || type === 3 || type === 4) this.getIn();
      else this.props.router.push("/dashboard/purchases/purchaseInvoice");
    }
  }

  onCancel() {
    this.props.router.push("/dashboard/purchases/purchaseInvoice");
  }

  render() {
    if (!this.state.documentType) return <EkoSpinner />;
    const editId =
      this.props.params && this.props.params.id ? this.props.params.id : false;
    return (
      <AddPurchaseInvoice
        {...this.state}
        editId={editId}
        paramType={this.props?.route?.type}
        callBackXMLData={this.callBackXMLData}
        calculate={this.state.calculate}
        onRefresh={this.fetchFromWho.bind(this)}
        errors={this.defaultErrors()}
        refreshPage={() => {
          this.setState({
            formData: this.defaultFormData()
          });
        }}
        uuid={this.props.params.uuid ? this.props.params.uuid : null}
        defaultErrors={() => this.defaultErrors()}
        redirectToBase={this.onCancel.bind(this)}
        defaultRowData={() => this.defaultRowData()}
        formData={this.state.formData}
        defaultFormData={() => this.defaultFormData()}
        onCancel={this.onCancel.bind(this)}
        isGetIn={this.props.params && this.props.params.uuid}
        isGetInUpdate={this.state.isGetInUpdate}
        viewOptions={this.props.viewOptions}
        otherAmount={this.state.otherAmount}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customersAll: state.data.customersAll,
    suppliersAll: state.data.suppliersAll,
    viewOptions: state.data.viewOptions,
    pageLoading: state.page.loading
  };
}

export default connect(mapStateToProps, actions)(SalesInvoiceAddUpdate);
