import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import UpdateCompany from "./UpdateCompany";
import EInvoiceSettings from "./EInvoiceSettings";
import CargoInformation from "./CargoInformation";
import OfferTemplate from "./OfferTemplate";
import GovermentLoginInformation from "./GovermentLoginInformation";
import TaxPayerShareHolderInformation from "./TaxPayerShareHolderInformation";
import SGKCompanyBranches from "./SGKCompanyBranches";
import Util from "../../util/Util";

const CompanySettings = () => {
    const hash = window.location.hash;
    var activeTab = "ticari"; // default tab
    if (hash && hash != '') {
        activeTab = hash.replace("#", "");
    }
    const user = JSON.parse(localStorage.getItem('user'));
  return (
      <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <div className="portlet light">
              <div className="portlet-title">
                  <div className="caption font-dark">
          <span className="caption-subject bold uppercase">
            Şirket Bilgileri
          </span>
                  </div>
              </div>
              <div className="portlet-body">
                  <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example">

                      <Tab eventKey="ticari" title="Ticari Bilgiler">
                          <UpdateCompany/>
                      </Tab>

                      <Tab eventKey="teklif" title="Teklif Şablonu">
                          <OfferTemplate/>
                      </Tab>

                      {
                          user.plan_id !== "3" ? <Tab eventKey="efatura" title="E-fatura Bilgileri">
                              <EInvoiceSettings/>
                          </Tab> : ""
                      }

                      <Tab eventKey="cargo" title="   E-arşiv Fatura Ayarları">
                          <CargoInformation/>
                      </Tab>

                      <Tab eventKey="kurum_giris_bilgileri" title="Kurum Giriş Bilgileri">
                          <GovermentLoginInformation/>
                      </Tab>

                      <Tab eventKey="ortak_bilgileri" title="Mükellef / Ortak Bilgileri">
                          <TaxPayerShareHolderInformation/>
                      </Tab>

                      <Tab eventKey="sgk_isyeri" title="SGK İş Yeri Bilgileri">
                          <SGKCompanyBranches/>
                      </Tab>

                  </Tabs>
              </div>
              <br/>
          </div>
      </div>
  );
};

export default CompanySettings;
