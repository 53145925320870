import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import Portlet from "../../components/elements/Portlet";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import Api from "../../util/Api";
import UtilHelper from "../../util/UtilHelper";
import {FETCH_OTHER_SALES} from "../../actions/types";
import Util from "../../util/Util";
import {toastr} from 'react-redux-toastr'
import AddDocumentButton from '../../components/forms/AddDocumentButton';
import NewTagDocumentModal from "../Company/newTagDocument";
class OtherSales extends PureComponent {

    get defaultRows() {
        var BankAccounts = [];
        JSON.parse(localStorage.getItem("bank_accounts")).find(function (element) {
            if(element.bank_account_type == 2){
                BankAccounts.push(element);
            }
        });
        return [
            [
                {
                    title: 'İlişkili Hesap',
                    type: 'select',
                    id: 'bank_accounts_id',
                    default_text: 'SEÇİNİZ',
                    option_value: 'name',
                    data: BankAccounts,
                    searchable: true,
                    option_id: 'id'
                },
                {type: 'date', id: 'document_date', vertical: true},
                {type: 'text', id: 'serial_number', vertical: true},
                {
                    title: 'Gelir Kayıt Alt Türü',
                    type: 'select',
                    id: 'gelir_kayit_alt_turu_id',
                    option_value: 'gelir_kayit_alt_turu_id',
                    default_text: 'SEÇİNİZ',
                    option_id: 'id',
                    searchable: true,
                    data: [
                        {
                            name: 'DİĞER GELİR',
                            id: 99001
                        },
                        {
                            name: 'KOMİSYON GELİRİ',
                            id: 138
                        },

                        {
                            name: 'FAİZ GELİRİ',
                            id: 14,
                        },
                        {
                            name: 'KUR FARKI GELİRİ',
                            id: 15
                        },
                        {
                            name: 'YANSITMA GELİRİ',
                            id: 151
                        },
                        {
                            name: 'KOSGEB DESTEKLERİ',
                            id: 6
                        },
                        {
                            name: 'ARGE DESTEKLERİ',
                            id: 8
                        }
                    ]
                },
                {type: 'text', id: 'description', vertical: true},
                {type: 'money', id: 'amount', vertical: true, placeholder: "0"},

            ]
        ];
    }

    get tableData() {
        return {
            headers: [
                {label: 'İlişkili Hesap', width: '20%'},
                {label: 'Belge Tarihi', width: '15%'},
                {label: 'Seri No', width: '10%'},
                {label: 'Gelir Kayıt Alt Türü', width: '20%'},
                {label: 'Açıklama', width: '20%'},
                {label: 'Tutar', width: '10%'},
            ],

            footer: [],
            footerData: {},
            footerTable: [],
            customFooter: null,
            rows: this.defaultRows
        }
    }

    get firstRow() {
        return Object.assign({}, {
            document_date: '',
        });
    }

    get fields() {
        return [
            'order_number',
            'amount'
        ]
    }

    prepepareLines = (lines) => {
        lines = lines.map(line => {
            const money = UtilHelper.money.mapFieldsToDecimal(this.fields, line);
            const record_date = Util.convertToDatabaseDate(line.record_date);
            return Object.assign({}, line, money, {record_date});
        });


        return lines;
    }


    constructor(props) {
        super(props);

        this.state = this.defaultState;
    }


    get defaultState() {
        return Object.assign({}, {
            tableData: this.tableData,
            errors: {
                rows: [{
                    bank_accounts_id: false,
                    document_date: false,
                    gelir_kayit_alt_turu_id: false,
                    amount: false
                }]
            },
            formData: {
                rows: [
                    this.firstRow
                ],

                footer: {}
            },
            saving: false,
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:8,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        });
    }

    componentWillMount() {
        this.getNewTagModalList();
        if (this.props.router.params.id) {
            this.props.fetchData('/otherSales/' + this.props.router.params.id, FETCH_OTHER_SALES);
        }
    }


    prepareLineForShow = (line) => {
        return Object.assign(
            {},
            line,
            UtilHelper.money.mapDecimalToMoney(
                this.fields,
                line,
            ),
            {record_date: Util.convertFromDatabaseDate(line.record_date)}
        )
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.othersales) {
            this.onLineChange(0, this.prepareLineForShow(nextProps.othersales));
        }
        if (nextProps.othersales && nextProps.othersales.document_url && nextProps.othersales.document_url !== this.props.addDocument?.uri) {
            const docUrl = nextProps.othersales.document_url;
            if(docUrl && this.props.setAddDocument){
                const type = docUrl.split('.').pop()
                this.props.setAddDocument({uri: docUrl, type});
            }
            // this.onLineChange(0, this.prepareLineForShow(nextProps.othersales));
        }
    }

    validateAndSaveLines = (redirect, isChecked) => {
        const self = this;
        let formData = this.state.formData;
        let isAmount = false;
        let postData = formData.rows?.map(p => {
            let nP  = {...p};
            nP.document_url = self.props.addDocument?.uri;
            nP.is_checked = isChecked === true; 
            nP.newTagRows=self.state.newTagRows;
            if(nP.amount === '0,00'){
                isAmount = true;
            } 
            return nP;
        });
        if(isAmount){
            toastr.error('Tutar eklenmesi zorunludur!');
            self.setState({saving: false});
            return;
        }

        Api.post("/otherSales/add", postData, (response, err) => {
            if (err !== false) {
                console.log('response',response,err);
                if(err.message){
                    toastr.error("Hata!", err.message);
                }else{
                    toastr.error("Hata!", "Kayıtlar Eklenemedi");
                }

            } else {
                toastr.success("Başarılı", "Başarıyla Eklendi");
                if(isChecked === true){
                    Api.get(`/otherSales/next/${self.props.router?.params?.id}`,function (res, error) {
                      if(res.item) {
                        const path = window.location.pathname;
                        const ex = path.split('/');
                        ex.pop();
                        ex.push(res.item.id);
                        window.location.href = ex.join('/');
                      } else {
                        self.props.router.push('/dashboard/salesInvoice/otherSales');
                      }
                    })
                  } else {
                    this.props.router.push('/dashboard/salesInvoice/otherSales');
                  }
            }
        });


    }
    onSave(refresh, isChecked) {
        const redirectToBase = arguments[0];
        this.setState({
            saving: true
        }, () => this.validateAndSaveLines(redirectToBase, isChecked));

    }
    checkReq(){
        let errors = this.state.errors;
        let error = false;
        this.state.formData.rows.forEach((row, index) => {
            errors.rows[index] = {
                and_or: false,
                field: false,
                operator: false,
                value: false
            };
            if (!row.document_date) {
                errors.rows[index]["document_date"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.amount) {
                errors.rows[index]["amount"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.gelir_kayit_alt_turu_id) {
                errors.rows[index]["gelir_kayit_alt_turu_id"] = ["Zorunlu alan"];
                error = true;
            }
            if (!row.bank_accounts_id) {
                errors.rows[index]["bank_accounts_id"] = ["Zorunlu alan"];
                error = true;
            }
        });
        return false;
    }

    onLineChange(index, rowData, field) {
        const formData = {...this.state.formData};
        formData.rows[index] = rowData;
        this.setState({
            formData: formData
        });
        this.checkReq();
    }

    onLineDelete(index, row) {


        const formData = {...this.state.formData};

        if (formData.rows[index]) {
            delete formData.rows[index];
        }

        this.setState({
            formData
        });
    }

    onCancel() {
        this.props.router.push('/dashboard/salesInvoice/otherSales')

    }

    onLineAdd() {
        this.validateLines();


    }

    validateLines = (all = false, next = null) => {
        let endpoints = []
        let formData = this.state.formData;
        const self = this;
        const url = "/otherSales/validateLine";
        const data = this.prepepareLines(formData.rows);

        data.forEach(row => {
            endpoints.push({endpoint: url, method: 'post', params: row})
        });


        if (!all) {
            endpoints.pop() //son eklenen rowu kontrol etme
        }

        Api.serialPutPost(endpoints, function (responses, errors) {
            if (errors.length === 0) {
                self.setState({errors: {rows: []}});
                if (next !== null) next(true)
            } else {
                self.setState({errors: {rows: errors}}, function () {
                    if (next !== null) next(false)
                })
            }
        })

    }
    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({
            newTagRows: rows,
        });
    };
    getNewTagModalList = () => {
        if (this.props.params && this.props.params.id) {
            Api.get("/company/newTagRecordDocumentList/8/" + this.props.params.id, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }
            });
        }
    }
    render() {

        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/salesInvoice/otherSales")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <Portlet title={"Diğer Gelirler"}
                         addDocument={<AddDocumentButton style={{margin: 0, transform: 'translateY(-50%)'}}/>}>
                    <EkoInputTableButtonGroup
                        data={this.state.tableData}
                        errors={this.state.errors}
                        formData={this.state.formData}
                        defaultRow={this.defaultRows}
                        saving={this.state.saving}
                        onRowAdd={this.props.router.params.id ? false : this.onLineAdd.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                        onChangeRow={this.onLineChange.bind(this)}
                        onRowDelete={this.onLineDelete.bind(this)}
                        formId={"add_zreport_form"}
                        onSave={(refresh, isChecked) => this.onSave(refresh, isChecked)}
                        buttons={[
                            {
                                onClick: this.onSave.bind(this, false),
                                labelText: 'Kaydet ve Yeni Ekle'
                            },
                        ]}
                        isCheckButton={true}
                        editId={this.props.router.params.id}
                        updatedAt={this.state?.formData?.rows[0]?.id && this.state?.formData?.rows[0]?.is_checked ? this.state?.formData?.rows[0]?.updated_at : null}
                    />
                    <a onClick={this.openNewTagModal} style={{top: 25, position: "relative"}}>Etiket Ekle</a>
                    <NewTagDocumentModal
                        newTagRows={this.state.newTagRows}
                        saveNewTag={this.saveNewTag}
                        showNewTagModal={this.state.showNewTagModal}
                        closeNewTagModal={this.closeNewTagModal}
                    />
                </Portlet>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        othersales: state.data.othersales,
        addDocument: state.page.addDocument
    };
};

export default connect(mapStateToProps, actions)(OtherSales);