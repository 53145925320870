/* CoreByte - LOAD Required components */
import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormSelect,
} from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
    FETCH_DECLARATION_TYPES
} from "../../actions/types";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import DeclarationsIframe from "./DeclarationsIframe";

import HelperContainer from "../../util/HelperContainer";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { empty } from "rxjs/Observer";

class Declarations extends Component {

    constructor(props){
        super(props);
        this.state = {
            formData: {},
            errors: {},
            formFields: {},
            selected: {},
            showInsertModal: false,
            declaration_cycles: [],
            vat_periods: {
                1: [
                    {"id": 202412+"_"+202412, "name": "2024 - Aralık"},
                    {"id": 202411+"_"+202411, "name": "2024 - Kasım"},
                    {"id": 202410+"_"+202410, "name": "2024 - Ekim"},
                    {"id": 202409+"_"+202409, "name": "2024 - Eylül"},
                    {"id": 202408+"_"+202408, "name": "2024 - Ağustos"},
                    {"id": 202407+"_"+202407, "name": "2024 - Temmuz"},
                    {"id": 202406+"_"+202406, "name": "2024 - Haziran"},
                    {"id": 202405+"_"+202405, "name": "2024 - Mayıs"},
                    {"id": 202404+"_"+202404, "name": "2024 - Nisan"},
                    {"id": 202403+"_"+202403, "name": "2024 - Mart"},
                    {"id": 202402+"_"+202402, "name": "2024 - Şubat"},
                    {"id": 202401+"_"+202401, "name": "2024 - Ocak"},
                    {"id": 202312+"_"+202312, "name": "2023 - Aralık"},
                    {"id": 202311+"_"+202311, "name": "2023 - Kasım"},
                    {"id": 202310+"_"+202310, "name": "2023 - Ekim"},
                    {"id": 202309+"_"+202309, "name": "2023 - Eylül"},
                    {"id": 202308+"_"+202308, "name": "2023 - Ağustos"},
                    {"id": 202307+"_"+202307, "name": "2023 - Temmuz"},
                    {"id": 202306+"_"+202306, "name": "2023 - Haziran"},
                    {"id": 202305+"_"+202305, "name": "2023 - Mayıs"},
                    {"id": 202304+"_"+202304, "name": "2023 - Nisan"},
                    {"id": 202303+"_"+202303, "name": "2023 - Mart"},
                    {"id": 202302+"_"+202302, "name": "2023 - Şubat"},
                    {"id": 202301+"_"+202301, "name": "2023 - Ocak"},
                ],
                2:  [
                    {"id": 202401+"_"+202403, "name": "2024 - Ocak-Şubat-Mart"},
                    {"id": 202404+"_"+202406, "name": "2024 - Nisan-Mayıs-Haziran"},
                    {"id": 202407+"_"+202409, "name": "2024 - Temmuz-Ağustos-Eylül"},
                    {"id": 202410+"_"+202412, "name": "2024 - Ekim-Kasım-Aralık"},
                    {"id": 202301+"_"+202303, "name": "2023 - Ocak-Şubat-Mart"},
                    {"id": 202304+"_"+202306, "name": "2023 - Nisan-Mayıs-Haziran"},
                    {"id": 202307+"_"+202309, "name": "2023 - Temmuz-Ağustos-Eylül"},
                    {"id": 202310+"_"+202312, "name": "2023 - Ekim-Kasım-Aralık"},
                ],
                3: [
                    {"id": 202401+"_"+202412, "name": "2024 - Ocak-Aralık"},
                    {"id": 202301+"_"+202312, "name": "2023 - Ocak-Aralık"},
                ],
                4: [
                    {"id": 202401+"_"+202403, "name": "2024 - 1. Dönem"},
                    {"id": 202404+"_"+202406, "name": "2024 - 2. Dönem"},
                    {"id": 202407+"_"+202409, "name": "2024 - 3. Dönem"},
                    {"id": 202410+"_"+202412, "name": "2024 - 4. Dönem"},
                    {"id": 202301+"_"+202303, "name": "2023 - 1. Dönem"},
                    {"id": 202304+"_"+202306, "name": "2023 - 2. Dönem"},
                    {"id": 202307+"_"+202309, "name": "2023 - 3. Dönem"},
                    {"id": 202310+"_"+202312, "name": "2023 - 4. Dönem"},
                ],
                5: [
                    {"id": 202401+"_"+202403, "name": "2024 - 1. Dönem"},
                    {"id": 202404+"_"+202406, "name": "2024 - 2. Dönem"},
                    {"id": 202407+"_"+202409, "name": "2024 - 3. Dönem"},
                    {"id": 202301+"_"+202303, "name": "2023 - 1. Dönem"},
                    {"id": 202304+"_"+202306, "name": "2023 - 2. Dönem"},
                    {"id": 202307+"_"+202309, "name": "2023 - 3. Dönem"},
                ]
            },
            current_vat_period: 1
        };
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {};
        this.props.fetchCachedDataAsKey(
            "/data/declaration_types",
            FETCH_DECLARATION_TYPES,
            "declaration_types",
            "1:day"
        );
        this.setState({ formData: formData, errors: errors });
    }
    componentDidMount(){
        const self = this;
        this.props.changePageTitle("Beyanname Yönetimi");
    }
    defaultFormData() {
        let serviceType = process.env.SERVICE_TYPE;
        let theToken = localStorage.getItem("token");
        /*let theToken = "306ef5328dfccfe4bf885344c615f3c1";
        if (serviceType == "PROD") {
            theToken = localStorage.getItem("token");
        }*/

        return {
            declaration: {"type_id": "", "type_cycle": "", "vat_period":"", "token": theToken}
        };
    }
    fieldOnChange(type, field, value) {
        //console.log("cb - fieldOnChange");
        //console.log(type, field, value);

        // Get:Set all inputs default values after onchange event
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        let selectedItemCycle, getDeclarationTypeItems = [];
        let tmp_declaration_cycles = [];
        if (field == "type_id") { // Beyanname tipi seçiliyorsa
            getDeclarationTypeItems = JSON.parse(localStorage.getItem('fetch_declaration_types'));
            getDeclarationTypeItems.data.forEach(function(val,key) {
                if (val.id == value){
                    selectedItemCycle = val.type_cycles.split(',');
                    selectedItemCycle.forEach(element => {

                        if (element == 1){ // Aylık
                            tmp_declaration_cycles.push({
                                "id": element,
                                "name": "Aylık"
                            });
                        }
                        if (element == 2){ // Çeyrek
                            tmp_declaration_cycles.push({
                                "id": element,
                                "name": "Çeyrek"
                            });
                        }
                        if (element == 3){ // Yıllık
                            tmp_declaration_cycles.push({
                                "id": element,
                                "name": "Yıllık"
                            });
                        }
                        if (element == 4){ // Normal Dönem
                            tmp_declaration_cycles.push({
                                "id": element,
                                "name": "Normal Dönem"
                            });
                        }
                        if (element == 5){ // Normal Dönem
                            tmp_declaration_cycles.push({
                                "id": element,
                                "name": "Normal Dönem"
                            });
                        }
                    });
                }
            });
            this.setState({ declaration_cycles: tmp_declaration_cycles });
        }else if (field == "type_cycle") { // dönem tipi seçiliyorsa
            this.setState({ current_vat_period: value });
        }
        this.setState({ formData: bigForm, errors: errors, showInsertModal: false });
    }
    fieldOnMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else{
            formFields[type].push(field);
        }
        this.setState({ formFields: formFields });
    }
    onSave(){
        let i = this.state.formData.declaration.type_id;
        let c = this.state.formData.declaration.type_cycle;
        let p = this.state.formData.declaration.vat_period;
        if (c > 0 && p > 0 && i > 0 ){
            toastr.success("Lütfen bekleyin, veri giriş ekranına yönlendiriliyorsunuz.");
            browserHistory.push("/dashboard/declarations/"+i+"/"+c+"/"+p+"");
        }else{
            toastr.error("Tüm alanların seçimi zorunludur.");
        }
    }
    onSaveOpenModal(){
        let self = this;
        self.setState({showInsertModal: true});
    }

    render(){
        const declarationsProps = {
            onChange: this.fieldOnChange.bind(this, "declaration"),
            onMount: this.fieldOnMount.bind(this, "declaration"),
            formData: this.state.formData.declaration,
            errors: this.state.errors.declaration
        };
        const declaration_types = this.props.declaration_types || [];


        return(
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}  onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                <div className="portlet light">
                    <div className="portlet-title">
                        <div className="caption font-dark"><span className="caption-subject bold uppercase">Beyanname Oluştur &amp; Görüntüle</span>
                        </div>
                    </div>
                    <div className="portlet-body">
                        <EkoForm hortizonal={false} formId="add-new-declarations">
                            <div className="row">
                                <div className="col-md-4 col-xs-12">

                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="type_id"
                                        clearable={true}
                                        options={declaration_types}
                                        optionValue="type_name"
                                        optionId="id"
                                        label="Beyanname Tipi"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />

                                </div>
                                <div className="col-md-4 col-xs-12">

                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="type_cycle"
                                        clearable={true}
                                        options={this.state.declaration_cycles}
                                        optionValue="name"
                                        optionId="id"
                                        label="Dönem Tipi"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />


                                </div>
                                <div className="col-md-4 col-xs-12">

                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="vat_period"
                                        clearable={true}
                                        options={this.state.vat_periods[this.state.current_vat_period]}
                                        optionValue="name"
                                        optionId="id"
                                        label="Dönem Seçiniz"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />


                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12 col-xs-12 text-center mt-4">
                                    <DeclarationsIframe
                                        showModal={this.state.showInsertModal}
                                        formData={this.state.formData}
                                        onCancel={() => {
                                            this.setState({showInsertModal: false});
                                        }}
                                        errors={this.state.errors}
                                    />

                                    <EkoFormButton
                                        label="Görüntüle &amp; Oluştur"
                                        faClass="fa fa-eye"
                                        onClick={this.onSaveOpenModal.bind(this)}
                                    />
                                </div>
                            </div>
                        </EkoForm>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
        declaration_types: state.data.declaration_types,
        declaration_cycles: state.data.declaration_cycles
    };
}

export default connect(mapStateToProps, actions)(Declarations);
