import React from "react";
import moment from "moment";

const Footer = props => {
  return (
    <div className="page-wrapper-row">
      <div className="page-wrapper-bottom">
        {/* BEGIN INNER FOOTER */}
        <div className="page-footer">
          <div className="container" style={{ textAlign: "center" }}>
            Ekohesap Bilgi Teknolojileri AŞ © 2017 - {moment().year()}
          </div>
        </div>
        <div className="scroll-to-top">
          <i className="icon-arrow-up" />
        </div>
        {/* END INNER FOOTER */}
      </div>
    </div>
  );
};

export default Footer;
