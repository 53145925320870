import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddCompanyOtherInfo from "./AddCompanyOtherInfo";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import EkoSpinner from "../../components/elements/EkoSpinner";

class CompanyOtherInfoUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {}
    };
  }

  componentWillMount() {
    var self = this;
    Api.get("/company/", function(response, err) {
      //TODO errorlari da handle etmek lazim da neyse @bocek
      if (response.company) {
        let formData = { company: response.company };
        self.setState({
          formData: formData,
          fromWhoType: formData.company.type
        });
      } else {
        toastr.error("Hata", err.message);
        self.props.router.push("/dashboard/companyOtherInfo");
      }
    });
  }

  render() {
    if (!this.state.formData) return <EkoSpinner />;
    return (
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              Şirket Ayarları
            </span>
          </div>
        </div>
        <div className="portlet-body">
          <AddCompanyOtherInfo
            formData={this.state.formData}
            fromWhoType={this.state.fromWhoType}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    otherReceipts: state.data.otherReceipts, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(CompanyOtherInfoUpdate);
