import React, { Component } from "react";
import EkoFormButtonGroup from "./EkoFormButtonGroup";
import EkoFormButton from "./EkoFormButton";
import PropTypes from "prop-types";

const EkoFormSaveButtonGroup = props => {
  return (
    <div className="buttonProccess actionButtons">
      <div>
        {props.onSaveAndAddNew ? (
          <EkoFormButtonGroup
            label={props.saveLabel}
            faClass="fa fa-plus"
            className="btn blue mt-ladda-btn ladda-button"
            isLoading={props.saving}
            onClick={props.onSave}
            buttons={[
              {
                onClick: props.onSaveAndAddNew,
                labelText: "Kaydet ve Yeni Ekle"
              }
            ]}
          />
        ) : (
          <EkoFormButton
            label={props.saveLabel || "Kaydet"}
            faClass="fa fa-plus"
            className="btn blue mt-ladda-btn ladda-button"
            isLoading={props.saving}
            onClick={props.onSave}
          />
        )}
        &nbsp;
        <EkoFormButton
          label={props.cancelLabel}
          className="btn dark btn-outline"
          onClick={() => {
            props.onCancel();
          }}
        />
      </div>
    </div>
  );
};

EkoFormSaveButtonGroup.propTypes = {
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  saving: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onSaveAndAddNew: PropTypes.func,
  onCancel: PropTypes.func.isRequired
};

EkoFormSaveButtonGroup.defaultProps = {
  saveLabel: "Kaydet",
  cancelLabel: "İptal",
  saving: false
};

export default EkoFormSaveButtonGroup;
