import Util from "../Util";

class Money {

    mapToFixMoney(datas, [money_field, money_str]) {
        return datas.map(data => {

            const money = parseInt(data[money_field], 10);


            if (money > 0) {
                data[money_str] = data[money_str] + " Alacak";
            } else if (money < 0) {
                data[money_str] = data[money_str].replace("-", "") + " Borç";
            }

            return data;
        })
    }

    mapFieldsToDecimal(fields, formData) {
        let data = {};

        fields.forEach((field) => {

            if (formData[field]) {
                data[field] = this.convertMoneyToDecimal(formData[field])
            }
        });

        return data;
    }

    mapDecimalToMoney(fields, formData) {
        let data = {};

        fields.forEach((field) => {

            if (formData[field]) {
                data[field] = this.convertDecimalToMoney(formData[field])
            }
        });

        return data;
    }


    convertMoneyToDecimal(money) {
        if (isNaN(parseFloat(money)))
            money = "0,00"
        if (Number.isInteger(money) || !isNaN(parseFloat(money))) {
            money += ''
        }
        let amount = Util.replaceAll(money, '.', '')
        return parseFloat(amount.replace(',', '.'))
    }

    convertMoneyToDecimal4(money) {
        if (isNaN(parseFloat(money)))
            money = "0,0000"
        if (Number.isInteger(money) || !isNaN(parseFloat(money))) {
            money += ''
        }
        let amount = Util.replaceAll(money, '.', '')
        return parseFloat(amount.replace(',', '.'))
    }

    convertMoneyToDecimal8(money) {
        if (isNaN(parseFloat(money)))
            money = "0,00000000"
        if (Number.isInteger(money) || !isNaN(parseFloat(money))) {
            money += ''
        }
        let amount = Util.replaceAll(money, '.', '')
        return parseFloat(amount.replace(',', '.'))
    }

    convertDecimalToMoney(money) {
        if (!Number.isInteger(money)) {
            money = parseFloat(money)
        }
        return money.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    }

    cleanMoney(money) {
        if (!money) {
            return ""
        }

        if (money === "0000") {
            return "00";
        }

        let split = money.split("");

        if (split[3] === "0") {
            split = split.splice(0, 3);
        }

        if (split[2] === "0") {
            split = split.splice(0, 2);
        }


        return split.join("");
    }

    convertDecimalToMoney4(money) {

        if (!Number.isInteger(money)) {
            money = parseFloat(money)
        }

        //this.cleanMoney("0560");

        let moneyNew = money.toFixed(4).toString().split('.');

        const moneyPrepared = moneyNew[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")

        return moneyPrepared + ',' + this.cleanMoney(moneyNew[1]);

    }
    convertDecimalToMoney8(money) {

        if (!Number.isInteger(money)) {
            money = parseFloat(money)
        }

        //this.cleanMoney("0560");

        let moneyNew = money.toFixed(8).toString().split('.');

        const moneyPrepared = moneyNew[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")

        return moneyPrepared + ',' + this.cleanMoney(moneyNew[1]);

    }
}

export default Money;