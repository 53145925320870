import React, {Component} from 'react'
import {
    EkoForm,
    EkoFormButton,
    EkoFormDate,
    EkoFormInputText, EkoFormRadioButton,
    EkoFormSelect
} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import moment from 'moment'
import Util from '../../util/Util';
import NewTagDocumentModal from "../Company/newTagDocument";
class AddAsset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            asset_list: [],
            line_row_list: [],
            depreciation_method_disable:false,
            depreciation_frequency_disable:false,
            depreciation_term_disable:false,
            is_depreciated_disable:false,
            accumulated_depreciation_disable:false,
            warningNote:false,
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:21,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        }
    }
    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({
            newTagRows: rows,
        });
    };
    getNewTagModalList = (formData) => {
        formData = formData || this.state.formData;
        if (formData.asset.id) {
            Api.get("/company/newTagRecordDocumentList/21/" + formData.asset.id, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }else{
                    this.resetNewTagModalList();
                }
            });
        }else{
            this.resetNewTagModalList();
        }
    }
    resetNewTagModalList = () => {
        this.setState(prevState => ({
            newTagRows: {
                ...prevState.newTagRows,
                rows: [
                    { id:null,new_tag_type_id:21,new_tag_id: "",record_id:null, rate: "%100,00" }
                ],
            },
        }));
    }
    defaultFormData() {
        return {
            asset: {},
        }
    }

    getAssetList() {
        const self = this;
        Api.get(`/fixedAsset/list`, (response) => {
            if (response.status === 'ok') {
                let asset_list = response.fixedAssets.map((data) => {
                    return {
                        id: data.id,
                        name: data.asset_name,
                        depreciation_term: data.depreciation_term,
                        is_depreciated: data.is_depreciated,
                        depreciation_frequency: data.depreciation_frequency,
                        depreciation_method: data.depreciation_method,
                        accumulated_depreciation: data.accumulated_depreciation,
                    }
                })
                self.setState({asset_list: asset_list})
            }
        });
    }

    getLineRowList() {
        if(this.props.line_row_list !==undefined){
            const lineListWithOrder = this.props.line_row_list.map((item, index) => ({
                ...item,
                order: index + 1,
                description: item.description!==''?item.description+' (Satır '+ (index + 1)+")":' (Satır '+ (index + 1)+")"
            }));
            this.setState({line_row_list: lineListWithOrder});

        }
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {}
        this.setState({formData: formData, errors: errors});
        this.getAssetList();
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData || this.defaultFormData();
        this.setState({formData: formData, errors: {}, showModal: nextProps.showModal});
        this.getLineRowList();
        this.getAssetList();
        this.getNewTagModalList(formData);
    }

    setVatAmount() {
        const self = this;
        if ((this.state.formData.asset.purchase_price
                && this.state.formData.asset.purchase_price !== "0,00")
            && (this.state.formData.asset.selected_vat_rate
                || this.state.formData.asset.selected_vat_rate === 0)) {
            let amount = Util.convertMoneyToDecimal(this.state.formData.asset.purchase_price);
            let rate = this.state.formData.asset.selected_vat_rate
            Api.get(`/calculation/VatAmount?amount=${amount}&rate=${rate}`, (response) => {
                if (response.status === 'ok') {
                    let vatAmount = response.response.toString().replace(/[#.]/g, ',');
                    if (vatAmount.search(",") === -1) vatAmount += ",00";
                    let formData = this.state.formData;
                    formData.asset = Object.assign({}, formData.asset, {
                        vat_amount: vatAmount
                    })
                    self.setState({formData: formData})
                }
            });
        }

    }

    setEndDate() {
        const self = this;
        if (this.state.formData.asset.depreciation_term && this.state.formData.asset.purchase_date) {
            let date = Util.convertToDatabaseDate(this.state.formData.asset.purchase_date);
            let term = this.state.formData.asset.depreciation_term
            Api.get(`/calculation/FaDepreciationEndDate?date=${date}&year=${term}`, (response) => {
                if (response.status === 'ok') {
                    let depreciationEndDate = response.response.date ? moment(response.response.date, "YYYY-MM-DD").format("DD-MM-YYYY") : "";
                    let formData = this.state.formData;
                    formData.asset = Object.assign({}, formData.asset, {
                        end_date: depreciationEndDate
                    })
                    self.setState({formData: formData})
                }
            });
        }
    }

    setFieldChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type])
            errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        if (type === 'asset' && field === 'fixed_asset_type_id') {
            let selected = this.props.fixedAssetTypes.filter((data) => {
                return data.id === value
            });
            if (value !== 2) {
                bigForm.asset.otv_amount = "0,00";
                bigForm.asset.expense_vat = "";
                bigForm.asset.expense_otv = "";
            }
            if(bigForm.asset.asset_type === 0){
                bigForm.asset.depreciation_term = selected[0].depreciation_term ? selected[0].depreciation_term.toString() : "0";
            }
            this.setEndDate()
        }
        if (type === 'asset' && (field === 'depreciation_term' || field === 'purchase_date')) {
            this.setEndDate()
        }
        if (type === 'asset' && field === 'purchase_price') {
            this.setVatAmount()
        }
        if (type === 'asset' && field === 'vat_rate_type_id') {
            let selected = this.props.vatRateTypes.filter((data) => {
                return data.id === value
            });
            bigForm.asset.selected_vat_rate = selected[0].rate || 0;
            this.setVatAmount()
        }
        if (type === 'asset' && field === 'expense_otv') {
            if (value === 1) {
                bigForm.asset.expense_vat = 0;
            }
        }
        if (type === 'asset' && field === 'expense_vat') {
            if (value === 1) {
                bigForm.asset.expense_otv = 0;
            }
        }

        if (type === 'asset' && field === 'asset_type') {
            if (value === 0) {
                bigForm.asset.asset_main_id = 0;
                this.setState({
                    depreciation_term_disable:false,
                    is_depreciated_disable:false,
                    depreciation_frequency_disable:false,
                    depreciation_method_disable:false,
                    accumulated_depreciation_disable:false,
                    warningNote:false
                });
            }
        }

        if (type === 'asset' && field === 'asset_main_id') {
            if (value !== 0) {
                var findIAsset = this.state.asset_list.find((x) => x.id === value);
                bigForm.asset.depreciation_term = findIAsset.depreciation_term;
                bigForm.asset.is_depreciated = findIAsset.is_depreciated;
                bigForm.asset.depreciation_frequency = findIAsset.depreciation_frequency;
                bigForm.asset.depreciation_method = findIAsset.depreciation_method;
                bigForm.asset.accumulated_depreciation = findIAsset.accumulated_depreciation;
                this.setState({
                    depreciation_term_disable:true,
                    is_depreciated_disable:true,
                    depreciation_frequency_disable:true,
                    depreciation_method_disable:true,
                    accumulated_depreciation_disable:true,
                    warningNote:true
                });
            }
        }

        if (type === 'asset' && field === 'line_row_id') {
            if (value !== 0) {
                var findIAsset = this.state.line_row_list[value-1];
                if(findIAsset!==undefined){
                    findIAsset = findIAsset.asset;
                    bigForm.asset.depreciation_term = findIAsset.depreciation_term;
                    bigForm.asset.is_depreciated = findIAsset.is_depreciated;
                    bigForm.asset.depreciation_frequency = findIAsset.depreciation_frequency;
                    bigForm.asset.depreciation_method = findIAsset.depreciation_method;
                    bigForm.asset.accumulated_depreciation = findIAsset.accumulated_depreciation;
                    this.setState({
                        depreciation_term_disable:true,
                        is_depreciated_disable:true,
                        depreciation_frequency_disable:true,
                        depreciation_method_disable:true,
                        accumulated_depreciation_disable:true,
                        warningNote:true
                    });
                }
            }
        }

        if (type === 'asset' && field === 'fixed_asset_type_id') {
            if (value === 2 || value === 13) {
                bigForm.asset.depreciation_method = 3;
                this.setState({depreciation_method_disable:true})
            }else{
                this.setState({depreciation_method_disable:false})
            }
        }

        if (type === 'asset' && field === 'is_depreciated') {
            if (value === 0) {
                bigForm.asset.depreciation_method = 1;
                bigForm.asset.depreciation_frequency = 1;
                this.setState({depreciation_method_disable:true,depreciation_frequency_disable:true})
            }else{
                this.setState({depreciation_method_disable:false,depreciation_frequency_disable:false})
            }
        }

        this.setState({formData: bigForm, errors: errors});
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }


    onSave() {
        const self = this;
        self.setState({saving: true});

        let purchase_price = Util.convertMoneyToDecimal(this.state.formData.asset.purchase_price || '0,00')
        let asset_interest_in_first_year = Util.convertMoneyToDecimal(this.state.formData.asset.asset_interest_in_first_year || '0,00')
        let vat_amount = Util.convertMoneyToDecimal(this.state.formData.asset.vat_amount || '0,00')
        let otv_amount = Util.convertMoneyToDecimal(this.state.formData.asset.otv_amount || '0,00')
        let accumulated_depreciation = Util.convertMoneyToDecimal(this.state.formData.asset.accumulated_depreciation || '0,00')
        let purchase_date = Util.convertToDatabaseDate(this.state.formData.asset.purchase_date);

        let assets = Object.assign({}, self.state.formData.asset, {
            purchase_price: purchase_price,
            asset_interest_in_first_year: asset_interest_in_first_year,
            vat_amount: vat_amount,
            otv_amount: otv_amount,
            accumulated_depreciation: accumulated_depreciation,
            purchase_date: purchase_date,
            newTagRows:this.state.newTagRows
        });

        let errors = self.state.errors;

        let func = "post";
        let url = "/fixedAsset";

        if (assets.id) {
            func = "put"; //NOTE edit işlemi
            url = "/fixedAsset/" + assets.id;
        }
        if(assets.asset_type===1 && (assets.asset_main_id===undefined || assets.asset_main_id===0)){
            toastr.error('Hata', 'Demirbaş Seçiniz');
            self.setState({saving: false, errors: errors});
            return false;
        }
        if(this.props.to_invoice !==undefined){

            Api["post"]("/fixedAsset/validation", assets, function (response, postErrors) {
                if (postErrors) {
                    errors.asset = postErrors.message;
                    self.setState({saving: false, errors: errors});
                } else {
                    self.setState({saving: false});
                    self.props.onSave(assets);
                }

            });
        }else{
        Api[func](url, assets, function (response, postErrors) {
            if (postErrors) {
                errors.asset = postErrors;
                self.setState({saving: false, errors: errors});
                toastr.danger('Hata', postErrors.message);
            } else {
                if (assets.id) {
                    toastr.success('Başarılı', 'Başarıyla Güncellendi');
                    self.setState({saving: false, errors: {}});
                }
                else {
                    self.setState({saving: false, errors: {}, formData: self.defaultFormData()});
                    toastr.success('Başarılı', 'Başarıyla Kaydedildi');
                }
                self.props.onSave();
            }

        });
    }
    }

    onCancel() {
        this.resetNewTagModalList();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        let yesOrNo = this.props.viewOptions && this.props.viewOptions.general && this.props.viewOptions.general.yesOrNo
        let depreciationMethods = this.props.viewOptions && this.props.viewOptions.fixed_assets && this.props.viewOptions.fixed_assets.depreciation_method
        let frequencies = this.props.viewOptions && this.props.viewOptions.fixed_assets && this.props.viewOptions.fixed_assets.depreciation_frequency
        let fixedAssetTypes = this.props.fixedAssetTypes
        let assetType = [{"id": 0, "name": "Ana Demirbaş"}, {"id": 1, "name": "Kayıtlı Bir Demirbaşın Parçası"}];
        if(this.props.to_invoice !==undefined){
            assetType = assetType.filter(item => item.id !== 1);
            const newAsset = {"id": 3, "name": "Bu Faturada Eklenen Demirbaşın Parçası"};
            assetType.push(newAsset);
        }
        let vatRateTypes = this.props.vatRateTypes
        let btn = this.state.formData.asset.id ? "Güncelle" : "Kaydet";
        let title = this.state.formData.asset.id ? "Demirbaş Güncelle" : "Demirbaş Ekle";
        const assetProps = {
            onChange: this.setFieldChange.bind(this, "asset"),
            onMount: this.setFieldMount.bind(this, "asset"),
            formData: this.state.formData.asset,
            errors: this.state.errors.asset
        }
        return <div>
            <EkoModal
                showModal={this.state.showModal}
                title={title}
                dialogClassName="asset-modal"
                onHide={() => {
                    this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                    this.onCancel();
                }}
                spinner={this.state.saving}>
                <EkoForm formId={"add_asset_modal"}>
                    <div className="row">
                        <div className="col-md-6">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.formData.asset.id !== undefined}
                                           id="asset_type"
                                           defaultText="Seçiniz"
                                           options={assetType}
                                           label="Demirbaş Kayıt Tipi"/>
                        </div>
                        { this.state.formData.asset.asset_type === 1 ?
                            <div className="col-md-6">
                                <EkoFormSelect {...assetProps}
                                               disabled={this.state.formData.asset.id !== undefined}
                                               id="asset_main_id"
                                               optionId={"id"}
                                               optionValue={"name"}
                                               defaultText="Seçiniz"
                                               options={this.state.asset_list}
                                               searchable={true}
                                               label="Demirbaş Listesi"/>
                            </div>
                            :""}
                        { this.state.formData.asset.asset_type === 3 ?
                            <div className="col-md-6">
                                <EkoFormSelect {...assetProps}
                                               disabled={this.state.formData.asset.id !== undefined}
                                               id="line_row_id"
                                               optionId={"order"}
                                               optionValue={"description"}
                                               defaultText="Seçiniz"
                                               options={this.state.line_row_list}
                                               searchable={true}
                                               label="Satır Listesi"/>
                            </div>
                            :""}
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.formData.asset.id !== undefined}
                                           id="fixed_asset_type_id"
                                           defaultText="Seçiniz"
                                           options={fixedAssetTypes}
                                           width={'300px'}
                                           searchable={true}
                                           label="Demirbaş Tipi"/>
                        </div>
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              disabled={this.state.formData.asset.id !== undefined}
                                              label="Alış Bedeli"
                                              id="purchase_price"
                                              type="money"/>
                        </div>
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              disabled={this.state.formData.asset.id !== undefined || this.state.depreciation_term_disable}
                                              label="Amortisman Süresi (Yıl)"
                                              id="depreciation_term"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <EkoFormDate
                                {...assetProps}
                                disabled={this.state.formData.asset.id !== undefined}
                                id="purchase_date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                label="Alış Tarihi"
                            />
                        </div>
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.formData.asset.id !== undefined}
                                           id="vat_rate_type_id"
                                           defaultText="Seçiniz"
                                           options={vatRateTypes}
                                           label="KDV Oranı"/>
                        </div>
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              disabled={true}
                                              label="Amortisman Bitiş Tarihi"
                                              id="end_date"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              label="Demirbaş Kodu"
                                              id="asset_code"/>
                        </div>
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              disabled={this.state.formData.asset.id !== undefined}
                                              label="KDV Tutarı"
                                              id="vat_amount"
                                              type="money"/>
                        </div>
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.formData.asset.id !== undefined}
                                           id="is_depreciated"
                                           defaultText="Seçiniz"
                                           options={yesOrNo}
                                           label="Amortisman Ayır"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              label="Açıklama"
                                              id="asset_name"/>
                        </div>
                        <div className="col-md-4">
                            {
                                this.state.formData.asset.fixed_asset_type_id === 2 ?
                                    <EkoFormInputText {...assetProps}
                                                      disabled={this.state.formData.asset.id !== undefined}
                                                      label="ÖTV Tutarı"
                                                      id="otv_amount"
                                                      type="money"/>
                                    : ""
                            }
                        </div>
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           id="depreciation_frequency"
                                           disabled={this.state.depreciation_frequency_disable || this.state.formData.asset.id !== undefined}
                                           defaultText="Seçiniz"
                                           options={frequencies}
                                           label="Amortisman Ayırma Frekansı"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.formData.asset.id !== undefined}
                                           id="is_loaned"
                                           defaultText="Seçiniz"
                                           options={yesOrNo}
                                           label="Kredili Mi?"/>
                        </div>
                        <div className="col-md-4">
                            {
                                this.state.formData.asset.fixed_asset_type_id === 2 ?
                                    <EkoFormSelect {...assetProps}
                                                   disabled={this.state.formData.asset.id !== undefined}
                                                   id="expense_vat"
                                                   defaultText="Seçiniz"
                                                   options={yesOrNo}
                                                   label="KDV ve ÖTV Giderleştir"/>
                                    : ""
                            }
                        </div>
                        <div className="col-md-4">
                            <EkoFormSelect {...assetProps}
                                           disabled={this.state.depreciation_method_disable || this.state.formData.asset.id !== undefined}
                                           id="depreciation_method"
                                           defaultText="Seçiniz"
                                           options={depreciationMethods}
                                           label="Amortisman Metodu"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            { this.state.formData.asset.is_loaned === 1 ?<EkoFormInputText {...assetProps}
                                                                                           disabled={this.state.formData.asset.id !== undefined}
                                                                                           label="İlk Yılki Demirbaş Faizi"
                                                                                           id="asset_interest_in_first_year"
                                                                                           type="money"/>:""}
                        </div>
                        <div className="col-md-4">
                            {
                                this.state.formData.asset.fixed_asset_type_id === 2 ?
                                    <EkoFormSelect {...assetProps}
                                                   disabled={this.state.formData.asset.id !== undefined}
                                                   id="expense_otv"
                                                   defaultText="Seçiniz"
                                                   options={yesOrNo}
                                                   label="KDV ve ÖTV Maliyete Ekle"/>
                                    : ""
                            }
                        </div>
                        <div className="col-md-4">
                            <EkoFormInputText {...assetProps}
                                              disabled={this.state.formData.asset.id !== undefined || this.state.accumulated_depreciation_disable}
                                              label="Birikmiş Amortisman Tutarı"
                                              id="accumulated_depreciation"
                                              type="money"/>
                        </div>
                        <div className="col-md-4">
                            &nbsp;
                        </div>
                        <p style={{"color":"red","display":this.state.warningNote===true?"inline-block":"none"}}>
                            NOT: Kayıtlı Bir Demirbaşın Parçası olarak seçtiğiniz demirbaş Ana Demirbaşın alt demirbaşı olup ana demirbaşın amortisman ayırma kriterileri ve ana demirbaşın kalan süresi ekonomik ömür olarak kullanılmaktadır.
                        </p>
                    </div>
                    <Modal.Footer>
                        <div className="row buttonProccess2">
                            <EkoFormButton label={btn}
                                           faClass="fa fa-plus"
                                           isLoading={this.state.saving}
                                           onClick={this.onSave.bind(this)}
                            />
                            <EkoFormButton label="İptal" className="btn"
                                           onClick={this.onCancel.bind(this)}
                            />
                        </div>
                        <hr/>
                        <div style={{"textAlign": "left"}}>
                            <a onClick={this.openNewTagModal}>Etiket Ekle</a>
                        </div>
                    </Modal.Footer>
                </EkoForm>
            </EkoModal>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
        </div>
    }

}

function mapStateToProps(state) {
    return {
        fixedAssetTypes: state.data.fixedAssetTypes,
        vatRateTypes: state.data.vatRateTypes,
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(AddAsset);
