import React, { Component } from "react";
import { EkoFormButton, EkoFormSwitch } from "../elements/EkoForm";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import EkoSpinner from "../elements/EkoSpinner";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";
import EkoInputTable from "../../components/elements/EkoInputTable";
import EkoModal from "../elements/EkoModal";
import MarketplaceNew from "./MarketplaceNew";
import { EkoFormInputText } from "../elements/EkoForm";

class Marketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      errors: {},
      page_loading: false,
      records: {},
    };
  }

  componentWillMount() {
    this.setState({ page_loading: true });
    Api.get("/marketplace", (res, err) => {
      if (err) {
        toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
        this.setState({ page_loading: false });
        return;
      }
      this.setState({ records: res.data, page_loading: false });
    });
  }

  componentWillReceiveProps(nextProps) {}
  onSave() {}

  openInsert() {
    this.setState({ showInsertModal: true });
  }

  hideModalHandler(addUpdate = false) {
    if (addUpdate) {
      this.componentWillMount();
    }
    this.setState({ showInsertModal: false });
  }

  trendyolForm(fields) {
    return (
      <>
        <EkoFormInputText
          {...fields}
          label={"Mağaza Adı"}
          id={"store_name"}
          name={"store_name"}
        />
        <EkoFormInputText
          {...fields}
          label={"Satıcı ID (Cari ID)"}
          id={"supplier_id"}
          name={"supplier_id"}
        />
        <EkoFormInputText
          {...fields}
          label={"API Key"}
          id={"apikey"}
          name={"apikey"}
        />
        <EkoFormInputText
          {...fields}
          label={"API Secret"}
          id={"apisecret"}
          name={"apisecret"}
        />
      </>
    );
  }

  deleteButtonHandler(id) {
    toastr.confirm(
      "Silmek istediğinize emin misiniz?",
      {
        onOk: () => {
          Api.delete(`/marketplace/${id}`, (res, err) => {
            if (err) {
              toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
              return;
            }
            toastr.success("Başarılı", "Kayıt başarıyla silindi");

            this.componentWillMount();
          });
        },
        onCancel: () => {},
        okText: "Evet",
        cancelText: "Hayır",
      },
      "warning"
    );
  }

  render() {
    return (
      <>
        {this.state.page_loading === true ? (
          <EkoSpinner />
        ) : (
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption font-dark">
                <span className="caption-subject bold uppercase">
                  Pazaryeri Entegrasyonları
                </span>
              </div>
              <div className="pull-right">
                <EkoFormButton
                  onClick={this.openInsert.bind(this)}
                  label={"Yeni"}
                />
              </div>
            </div>

            <div className="portlet-body">
              <div className={"row"}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Pazaryeri</th>
                      <th>Mağaza Adı</th>
                      <th>Satıcı ID</th>
                      <th>İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.records.length === 0 ? (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          Kayıtlı pazaryeri hesabınız bulunamadı. Lütfen sağ üst
                          köşeden yeni bir pazaryeri hesabı entegre edin.
                        </td>
                      </tr>
                    ) : (
                      this.state.records.map((record) => {
                        return (
                          <tr key={record.id}>
                            <td>{record.marketplace}</td>
                            <td>{record.store_name}</td>
                            <td>{record.supplier_id}</td>
                            <td>
                              <EkoFormButton
                                onClick={this.openInsert.bind(this)}
                                label={"Düzenle"}
                              />
                              <EkoFormButton
                                onClick={this.deleteButtonHandler.bind(
                                  this,
                                  record.id
                                )}
                                label={"Sil"}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <MarketplaceNew
              title={"Yeni Pazaryeri Ekle"}
              showModal={this.state.showInsertModal}
              formElements={this.trendyolForm.bind(this)}
              onHide={this.hideModalHandler.bind(this)}
            />
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions,
  };
}

export default connect(mapStateToProps, actions)(Marketplace);
