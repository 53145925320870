import React from "react";
import Designer from "./designer";
import { CSS } from "../css/template";
import Style from "style-it";
import Api from "../util/Api";
import EkoSpinner from "../components/elements/EkoSpinner";
import { toastr } from "react-redux-toastr";
import { hot } from "react-hot-loader";

import Config from "./config";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { config: null, name: null };
  }

  componentWillMount() {
    const self = this;
    if (this.props.params && this.props.params.id) {
      if (this.props.params.id === "new") this.setState({ config: Config });
      else {
        Api.get("/template/" + this.props.params.id, (response, err) => {
          if (response) {
            self.setState({
              config: JSON.parse(response.template.properties),
              name: response.template.name
            });
          } else self.props.router.push("/dashboard/templates");
        });
      }
    }
  }

  save = state => {
    const self = this;
    let newState = Object.assign({}, state);
    let properties = JSON.stringify(newState, null, 2);
    let name = newState.templateName;
    let template = { name: name, properties: properties };

    const id = this.props.params.id || "new";

    let url = id !== "new" ? "/template/" + id : "/template";
    let method = id !== "new" ? "put" : "post";
    let message =
      id !== "new" ? "Başarıyla Güncellendi" : "Başarıyla Kayıt edildi";
    if (id !== "new") template.updated_id = id;
    if (id !== "new") template.updated_id = id;
    Api[method](url, template, (response, error) => {
      if (response) {
        toastr.success("Başarılı", message);
        self.props.router.push("/dashboard/templates");
      } else {
        try {
          Object.values(error).map(item => {
            if (typeof item === "object") {
              toastr.error("Hata", item[0]);
            }
            return item;
          });
        } catch (e) {
          toastr.error(
            "Hata",
            "Sistem hatası lütfen daha sonra tekrar deneyiniz."
          );
        }
      }
    });
  };

  render() {
    if (!this.state.config) return <EkoSpinner />;
    return Style.it(
      CSS,
      <Designer
        config={this.state.config}
        name={this.state.name}
        onSave={this.save}
      />
    );
  }
}

export default hot(module)(App);
