import React from 'react';


const DashboardLayout = ({children}) => {
     return (
        <div className="page-wrapper-row full-height">
            <div className="page-wrapper-middle">
                <div className="page-content">
                    <div className="container">
                        <div className="page-content-inner">
                            <div className="profile-content col-md-5">
                                <div className="row mred dashboard-layout">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;