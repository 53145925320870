import React from 'react';
import ReactDOM from 'react-dom';
import Application from './app'


window.Raven.config(process.env.REACT_APP_SENTRY_DNS || "https://a43d87b981cd44deac06679bcdaea357@sentry.io/290690", {
    autoBreadcrumbs: {
        console: false
    }
}).install();
(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','763223703ID');
if (typeof window.userGuiding.identify !== "undefined") {
if (localStorage.getItem("user") !== null) {
    var user = JSON.parse(localStorage.getItem("user"));
    console.log('userGuiding OK!');
    window.userGuiding.identify(user.company_id, {
        email: user.email,
        name: user.firstname + " " + user.lastname
    })
}
}
ReactDOM.render(<Application/>, document.getElementById('root'));