import React, { useState, useEffect, useRef } from "react";
import {
  EkoFormInputText,
  EkoFormButton,
  EkoFormSelect, EkoFormSwitch,
} from "../../components/elements/EkoForm";
import Select from "react-select";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router";
import "./Icon.css";
import EkoImageUpload from "../../components/elements/form/EkoImageUpload";
import Resizer from "react-image-file-resizer";

const EInvoiceSettings = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [integrator, setIntegrator] = useState("");
  const [senderID, setSenderID] = useState("");
  const [receiverID, setReceiverID] = useState("");
  const [senderIDDespatch, setSenderIDDespatch] = useState("");
  const [receiverIDDespatch, setReceiverIDDespatch] = useState("");
  const [id, setId] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [dealer, setDealer] = useState("");
  const [logo, setLogo] = useState("");
  const [signatureView, setSignatureView] = useState("");
  const [note, setNote] = useState("");
  const [EinvoiceDefault, setEinvoiceDefault] = useState("");
  const [EinvoiceOther, setEinvoiceOther] = useState("");
  const [EarchiveDefault, setEarchiveDefault] = useState("");
  const [EarchiveOther, setEarchiveOther] = useState("");
  const [EsmmDefault, setEsmmDefault] = useState("");
  const [EsmmOther, setEsmmOther] = useState("");
  const [kamu_extra, setKamuExtra] = useState("");
  const [eftmuk,setEftmukToggle] = useState(true);
  const [apiKey, setApiKey] = useState("");

  const fileUploadHandler = (image, setFn, maxWidth, maxHeight) => {
    console.log("image", image);
    if (image) {
      Resizer.imageFileResizer(
        image,
        maxWidth,
        maxHeight,
        "PNG",
        80,
        0,
        (uri) => {
          console.log("imageFn", uri);
          const fd = new FormData();
          fd.append("image", uri);

          if (uri.size < 30000) {
            Api.post("/eInformation/upload", fd, (response, error) => {
              if (response) {
                if (response.status === "fail") {
                  toastr.error(response.message);
                } else {
                  setFn(response.imageUrl);
                  toastr.success("Yükleme Başarılı");
                }
              } else {
                if (error.status === "fail") {
                  toastr.error(error.message);
                }
              }
            });
          } else {
            toastr.error("Resim boyutu fazla büyük");
          }
        },
        "blob"
      );
    } else setFn(null);
  };

  useEffect(() => {
    let companyId = JSON.parse(localStorage.getItem("user")).company_id;

    Api.get("/eInformation/show/" + companyId, (response, err) => {
      if (response) {
        var kamu_extraParse = JSON.parse(response.eInformation.kamu_extra);
        if(response.eInformation.series===''){
          var seriesParse = { "e_invoice" : { "default" : "", "other" : "" },"e_archive" : { "default" : "", "other" : "" },"e_smm" : { "default" : "", "other" : "" }};
        }else{
          var seriesParse = JSON.parse(response.eInformation.series);
          if (seriesParse && seriesParse.e_smm !== undefined) {
            seriesParse.e_smm = { "default" : "", "other" : "" }
          }
        }
        setUsername(response.eInformation.username);
        setPassword(response.eInformation.password);
        setIntegrator(response.eInformation.integrator);
        setId(response.eInformation.id);
        setSenderID(response.eInformation.sender_identifier);
        setReceiverID(response.eInformation.receiver_identifier);
        setSenderIDDespatch(response.eInformation.sender_identifier_despatch);
        setReceiverIDDespatch(response.eInformation.receiver_identifier_despatch);
        setDealer(response.eInformation.dealer);
        setLogo(response.eInformation.logo);
        setNote(response.eInformation.general_note);
        setEinvoiceDefault(seriesParse.e_invoice.default);
        setEinvoiceOther(seriesParse.e_invoice.other);
        setEarchiveDefault(seriesParse.e_archive.default);
        setEarchiveOther(seriesParse.e_archive.other);
        setEsmmDefault(seriesParse.e_smm.default);
        setEsmmOther(seriesParse.e_smm.other);
        setKamuExtra(kamu_extraParse['iban']);
        setSignatureView(response.eInformation.signature_view);
        setApiKey(response.eInformation.integrator_apikey);
      } else console.log(err);
    });
  }, []);

  /*   const options = [
    { value: 1, label: "Foriba" },
    { value: 2, label: "NES" }
  ]; */
  const options = [
    { id: 1, name: "Foriba" },
    { id: 2, name: "NES" },
    { id: 3, name: "NES V2" },
  ];

  const onSave = () => {
    setErrors("");

    if(eftmuk==false){
      let idForEndpoint = id ?? "";
      Api["post"](
          "/eInformation/dealerUpdate/" + idForEndpoint,
          {dealer},
          (response, err) => {
            setLoading(false);
            if (response) {
              setId(response.eInformation.id);
              toastr.success("Başarıyla kaydedildi");
            }
          }
      );
    }

    let method = id ? "put" : "post";
    let idForEndpoint = id ?? "";
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    Api[method](
      "/eInformation/" + idForEndpoint,
      {
        username,
        password,
        integrator,
        dealer,
        sender_identifier: senderID,
        receiver_identifier: receiverID,
        sender_identifier_despatch: senderIDDespatch,
        receiver_identifier_despatch: receiverIDDespatch,
        general_note: note,
        kamu_extra: '{"iban":"'+kamu_extra+'"}',
        series: '{ "e_invoice" : { "default" : "'+EinvoiceDefault+'", "other" : "'+EinvoiceOther+'" },"e_archive" : { "default" : "'+EarchiveDefault+'", "other" : "'+EarchiveOther+'" },"e_smm" : { "default" : "'+EsmmDefault+'", "other" : "'+EsmmOther+'" }}',
        logo,
        signature_view: signatureView,
        integrator_apikey: apiKey,
        company_type_name: user.company_type == 9 || user.company_type == 6?"smm":"diger"
      },
      (response, err) => {
        console.log(response, "responseFromEInvoice");
        setLoading(false);

        if (response) {
          setId(response.eInformation.id);
          toastr.success("Başarıyla kaydedildi");
        } else {
          if(eftmuk==true){
            setErrors(err);
          }
          if (err.message) toastr.error(err.message);
        }
      }
    );
  };

  const onSelectChange = (selectAction) => {
    setIntegrator(selectAction.value);
  };

  const eftmukToggle = (state) => {
    setEftmukToggle(state)
  }
  
  let user = JSON.parse(localStorage.getItem("user"));
  let companyType = user.company_type;

  if (companyType === "9" || companyType === "6") {
    var smm = true;
  } else {
    var smm = false;
  }
  const handleChange = (value) => {
    // Regular expression to allow only letters and numbers
    const regex = /^[A-Z0-9]*$/;

    // Check if value matches the regex and has a length of 3 or less
    if (regex.test(value)) {
      return value;
    }else{
        toastr.error("Seri numarası sadece büyük harf ve rakam içerebilir.");
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-4 ">
          <EkoFormSelect
              id="integrator"
              label="Entegratör"
              value={integrator}
              isVertical={true}
              onChange={(_, y) => setIntegrator(y)}
              defaultText="Seçiniz"
              errors={errors}
              options={options}
          />
          {integrator !== 3 &&
              <>
                <div style={{ marginTop: 20 }} className="input-icon-vertical">
                  <i className="fa fa-envelope" />
                  <EkoFormInputText
                      id="username"
                      onChange={(_, value) => setUsername(value)}
                      style={{ paddingLeft: "33px" }}
                      label="Kullanıcı Adı"
                      placeHolder="E-posta"
                      value={username}
                      isVertical
                      errors={errors}
                  />
                </div>
                <div className="input-icon-vertical">
                  <i className="fa fa-lock" />
                  <EkoFormInputText
                      id="password"
                      onChange={(_, value) => setPassword(value)}
                      style={{ paddingLeft: "33px" }}
                      label="Şifre"
                      type="password"
                      placeHolder="Şifre"
                      value={password}
                      errors={errors}
                      isVertical
                  />
                </div>
              </>
          }
          {integrator === 3 &&
              <>
                <div style={{ marginTop: 20 }} className="input-icon-vertical">
                  <i className="fa fa-envelope" />
                  <EkoFormInputText
                      id="apiKey"
                      onChange={(_, value) => setApiKey(value)}
                      style={{ paddingLeft: "33px" }}
                      label="API Key"
                      placeHolder="API Key"
                      value={apiKey}
                      isVertical
                      errors={errors}
                  />
                </div>
              </>
          }

          {/*           <div>
            <p style={{ marginTop: 25 }}>Entegratör</p>
            <Select
              placeholder="Entegratör Seçin"
              onChange={onSelectChange}
              options={options}
              value={
                integrator
                  ? options.filter(el => el.value === integrator)
                  : options[0]
              }
            />
          </div> */}
          <div>
            <EkoFormInputText
              id="general_note"
              type="textarea"
              onChange={(_, value) => setNote(value)}
              label="Not"
              placeHolder="Not"
              value={note}
              errors={errors}
              isVertical
              largeHeight
            />
            {integrator !== 1?<div className="row">
              <div className="col-md-6">
                <EkoFormInputText
                    id="e_invoice_default"
                    onChange={(_, value) => setEinvoiceDefault(handleChange(value))}
                    label="E-Fatura Seri"
                    maxLength={3}
                    placeHolder="E-Fatura Seri"
                    value={EinvoiceDefault}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>
              <div className="col-md-6">
                <EkoFormInputText
                    id="e_invoice_other"
                    onChange={(_, value) => setEinvoiceOther(handleChange(value))}
                    label="E-Fatura GDF Seri"
                    maxLength={3}
                    placeHolder="E-Fatura GDF Seri"
                    value={EinvoiceOther}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>
              <div className="col-md-6">
                <EkoFormInputText
                    id="e_archive_default"
                    onChange={(_, value) => setEarchiveDefault(handleChange(value))}
                    label="E-Arşiv Seri"
                    maxLength={3}
                    placeHolder="E-Arşiv Seri"
                    value={EarchiveDefault}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>
              <div className="col-md-6">
                <EkoFormInputText
                    id="e_archive_other"
                    onChange={(_, value) => setEarchiveOther(handleChange(value))}
                    label="E-Arşiv GDF Seri"
                    maxLength={3}
                    placeHolder="E-Arşiv GDF Seri"
                    value={EarchiveOther}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>
              {smm===true?<div className="col-md-6">
                <EkoFormInputText
                    id="e_smm_default"
                    onChange={(_, value) => setEsmmDefault(value)}
                    label="E-SMM Seri"
                    placeHolder="E-SMM Seri"
                    value={EsmmDefault}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>:''}
              {smm===true?<div className="col-md-6">
                <EkoFormInputText
                    id="e_smm_other"
                    onChange={(_, value) => setEsmmOther(value)}
                    label="E-SMM GDF Seri"
                    placeHolder="E-SMM GDF Seri"
                    value={EsmmOther}
                    errors={errors}
                    isVertical
                    largeHeight
                />
              </div>:''}
            </div>:""}
          </div>
        </div>
        <div className="col-md-4 ">
          <div style={{marginTop: 20}} className="input-icon-vertical">
            <i className="fa fa-arrow-up"/>
            <EkoFormInputText
                id="sender_identifier"
                onChange={(_, value) => setSenderID(value)}
                style={{paddingLeft: "33px"}}
                label="e-Fatura Gönderici Kimliği"
                placeHolder="E-posta"
                value={senderID}
                errors={errors}
                isVertical
            />
          </div>
          <div className="input-icon-vertical">
            <i className="fa fa-arrow-down"/>
            <EkoFormInputText
                id="receiver_identifier"
                onChange={(_, value) => setReceiverID(value)}
                style={{paddingLeft: "33px"}}
                label="e-Fatura Alıcı Kimliği"
                placeHolder="Şifre"
                value={receiverID}
                errors={errors}
                isVertical
            />
          </div>

          <div style={{marginTop: 20}} className="input-icon-vertical">
            <i className="fa fa-arrow-up"/>
            <EkoFormInputText
                id="sender_identifier_despatch"
                onChange={(_, value) => setSenderIDDespatch(value)}
                style={{paddingLeft: "33px"}}
                label="İrsaliye Gönderici Kimliği"
                placeHolder="E-posta"
                value={senderIDDespatch}
                errors={errors}
                isVertical
            />
          </div>
          <div className="input-icon-vertical">
            <i className="fa fa-arrow-down"/>
            <EkoFormInputText
                id="receiver_identifier_despatch"
                onChange={(_, value) => setReceiverIDDespatch(value)}
                style={{paddingLeft: "33px"}}
                label="İrsaliye Alıcı Kimliği"
                placeHolder="Şifre"
                value={receiverIDDespatch}
                errors={errors}
                isVertical
            />
          </div>

          <div className="input-icon-vertical">
            <i className="fa fa-briefcase"/>
            <EkoFormInputText
                id="dealer"
                onChange={(_, value) => setDealer(value)}
                style={{paddingLeft: "33px"}}
                label="Bayi Kodu"
                placeHolder="Bayi Kodu"
                errors={errors}
                value={dealer}
                isVertical
            />
          </div>
          <div><EkoFormSwitch
              textOn={"E-Fatura Mükellefiyim"}
              textOff={"E-Fatura Mükellefi Değilim"}
              md={12}
              onChange={eftmukToggle}
              value={eftmuk}
          /></div>

          <div>
            <EkoFormInputText
                id="kamu_extra"
                onChange={(_, value) => setKamuExtra(value)}
                label="Kamu İçin Kesilen Faturalara Eklenen Iban"
                placeHolder="TR660000000000000000000000"
                value={kamu_extra}
                errors={errors}
                isVertical
                largeHeight
            />
          </div>
        </div>
        <div className="col-md-4 ">
          <EkoImageUpload
              style={{marginBottom: 20}}
              selectedImage={logo}
              onChange={(image) =>
                  fileUploadHandler(
                      image,
                      setLogo,
                      integrator === 1 ? 250 : 215,
                      150
                  )
              }
              maxSize={256000}
              buttonText="Logo Yükle"
              width={180}
              height={180}
          />
          <div
              style={{
                borderTop: "2px solid black",
                marginBottom: 20,
                borderRadius: "20px",
              }}
          >
            {" "}
          </div>
          <EkoImageUpload
              selectedImage={signatureView}
              onChange={(image) =>
                  fileUploadHandler(image, setSignatureView, 150, 90)
              }
              maxSize={256000}
              buttonText="İmza Görseli Yükle"
            width={180}
            height={180}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <EkoFormButton
          label={"Kaydet"}
          faClass="fa fa-plus"
          isLoading={loading}
          onClick={onSave}
        />
        <EkoFormButton
          label="İptal"
          className="btn"
          onClick={props.router.goBack}
        />
      </div>
    </>
  );
};

export default withRouter(EInvoiceSettings);
