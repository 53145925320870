import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";

class PromotionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { promotion: {} },
      saving: false,
      errors: {},
      formFields: {}
    };
  }

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  closeModal() {
    this.setState({ saving: false, formData: { promotion: {} } });
    this.props.closeModal();
  }

  onSave() {
    const self = this;
    let params = {
      code: this.state.formData.promotion.code
    };

    if (!this.state.formData.promotion.code) {
      let errors = { promotion: { code: ["Kod alanı boş bırakılamaz."] } };
      self.setState({ errors: errors });
    } else {
      Api.post("/addPromotionCode", params, (response, err) => {
        if (response) {
          self.closeModal();
          toastr.success(
            "Başarılı",
            "Promosyon kodunuz hesabınıza başarıyla eklendi"
          );
        } else toastr.error("Hata", err.message);
      });
    }
  }

  render() {
    const promotionProps = {
      onChange: this.setFieldChange.bind(this, "promotion"),
      onMount: this.setFieldMount.bind(this, "promotion"),
      formData: this.state.formData.promotion,
      errors: this.state.errors.promotion
    };
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={"Promosyon Kodunu Gir"}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.saving}
      >
        <EkoForm>
          <div>
            <div className="row text-center">
              <br />
              <span>
                12 haneli promosyon kodunu girip uygula butonuna basınız
              </span>
              <br />
              <br />
              <div className="col-md-8 col-md-push-2">
                <EkoFormInputText
                  {...promotionProps}
                  id={"code"}
                  type="mask"
                  tooltip={true}
                  isVertical={true}
                  maskChar=""
                  mask="************"
                  label=""
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <br />
            <EkoFormButton label={"UYGULA"} onClick={this.onSave.bind(this)} />
            <EkoFormButton
              label={this.props.buttonName ? this.props.buttonName : "İPTAL"}
              className="btn"
              onClick={this.closeModal.bind(this)}
            />
          </Modal.Footer>
        </EkoForm>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(PromotionModal);
