import {
  CHANGE_PAGE_TITLE,
  PAGE_LOADING,
  ADD_DOCUMENT,
  UPLOADED_DOCUMENT
} from "../actions/types";

export default function (
  state = { addDocument: { uri: null, type: null } },
  action
) {
  switch (action.type) {
    case CHANGE_PAGE_TITLE:
      return { ...state, title: action.payload };
    case ADD_DOCUMENT:
      return { ...state, addDocument: action.payload };
    case UPLOADED_DOCUMENT:
      return { ...state, docUploaded: action.payload };
    case PAGE_LOADING:
      return { ...state, pageLoading: action.payload };
    default:
      return state;
  }
}
