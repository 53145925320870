import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import {
  FETCH_INSURANCE_POLICIES,
  FETCH_OTHER_RECEIPTS,
  FETCH_PURCHASE_INVOICES,
  FETCH_RECEIPTS
} from "../../actions/types";
import Style from "style-it";
import { CSS } from "../../css/tab_datatable.js";
import PermittedHeader from "../../services/PermittedHeader";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import PaginatedDataTable from "../../components/elements/PaginatedDataTable";
import PurchasesModal from "./PurchasesModal";
import HelperContainer from "../../util/HelperContainer";
import Util from "../../util/Util";
//import Channel from "../../services/Pusher";

class Purchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModalPurchase: false,
      collections: [],
      selectedTab: 0,
      tabApi: "purchaseInvoice",
      tabName: "purchaseInvoice",
      purchaseApi: {},
      purchaseDetailApi: {},
      dataLoading: false,
      lastSearch: {},
    };
  }

  fetchData(
    tabId,
    sizePerPage = 10,
    page = 1,
    order = [],
    date = [],
    search = ""
  ) {

    this.setState({
      lastSearch: [...arguments]
    }, () => {
      tabId = tabId.toString();
      switch (tabId) {
        case "9":
          this.props.fetchData(
              Api.generateUrl(
                   "/purchaseInvoice/list",
                  //search === "" ? "/purchaseInvoice/list" : "/search/purchaseInvoice",
                  sizePerPage,
                  page,
                  order,
                  date,
                  search
              ),
              FETCH_PURCHASE_INVOICES
          );
          break;
        case "12":
          this.props.fetchData(
              Api.generateUrl("/receipt/list", sizePerPage, page, order, date),
              FETCH_RECEIPTS
          );
          break;
        case "13":
          this.props.fetchData(
              Api.generateUrl("/otherReceipt/list", sizePerPage, page, order, date),
              FETCH_OTHER_RECEIPTS
          );
          break;
        case "14":
          this.props.fetchData(
              Api.generateUrl(
                  "/insurancePolicy/list",
                  sizePerPage,
                  page,
                  order,
                  date
              ),
              FETCH_INSURANCE_POLICIES
          );
          break;
        default:
          this.props.fetchData(
              Api.generateUrl(
                  "/purchaseInvoice/list",
                  sizePerPage,
                  page,
                  order,
                  date
              ),
              FETCH_PURCHASE_INVOICES
          );
          this.props.fetchData(
              Api.generateUrl("/receipt/list", sizePerPage, page, order, date),
              FETCH_RECEIPTS
          );
          this.props.fetchData(
              Api.generateUrl("/otherReceipt/list", sizePerPage, page, order, date),
              FETCH_OTHER_RECEIPTS
          );
          this.props.fetchData(
              Api.generateUrl(
                  "/insurancePolicy/list",
                  sizePerPage,
                  page,
                  order,
                  date
              ),
              FETCH_INSURANCE_POLICIES
          );
      }
    })

  }

  componentWillMount() {
    this.fetchData("100");


    /*Channel.listen('.purchase-invoice-no-added',  (data) => {

        this.fetchData(...this.state.lastSearch)
    })*/
  }

  componentWillReceiveProps(nextProps) {
    const selectedTab = nextProps.route.name;
    let tabId = 9;
    let tabName,
      tabApi = "";

    switch (selectedTab) {
      case "purchaseInvoice":
        tabName = tabApi = "purchaseInvoice";
        tabId = 9;
        break;
      case "receipt":
        tabName = "receipts";
        tabApi = "receipt";
        tabId = 12;
        break;
      case "otherReceipt":
        tabName = "otherReceipts";
        tabApi = "otherReceipt";
        tabId = 13;
        break;
      case "insurancePolicy":
        tabName = tabApi = "insurancePolicy";
        tabId = 14;
        break;
      default:
    }

    this.setState({
      data: nextProps[tabApi],
      selectedTab: tabId,
      tabApi: tabApi,
      tabName: tabName
    });
  }

  openUpdate(selectedRow) {
    this.props.router.push(
      "/dashboard/purchases/" + this.state.tabApi + "/edit/" + selectedRow.id
    );
  }

  openDetail(selectedRow) {
    this.goToPurchaseApi(selectedRow);
  }

  openInsert(menuItem) {
    const pages = [
      { id: 1, path: "productServiceInvoice" },
      { id: 2, path: "expenseInvoice" },
      { id: 3, path: "customerReturnInvoice" },
      { id: 4, path: "receipt" },
      { id: 5, path: "otherReceipt" },
      { id: 6, path: "insurancePolicy" },
      { id: 7, path: "purchaseReceipt" }
    ];
    const page = pages.filter(p => p.id === menuItem)[0];
    if (menuItem > 3)
      this.props.router.push("/dashboard/purchases/" + page.path + "/add");
    else
      this.props.router.push(
        "/dashboard/purchases/purchaseInvoice/add/" + page.path
      );
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/" + this.state.tabApi + "/" + selectedRow.id, function(
      response,
      err
    ) {
      if (response) {
        toastr.success("Başarılı", "Başarıyla Silindi");
        self.fetchData(self.state.selectedTab);
      } else toastr.error("Hata", err.message);
    });
  }

  isPermitted(pageId, html) {
    return PermittedHeader.isPermitted(pageId, html);
  }

  filterMenu(insertMenu) {
    if (
      this.props.viewOptions &&
      this.props.viewOptions.purchases.addRecordMenuCompanyTypes &&
      insertMenu
    ) {
      const types = this.props.viewOptions.purchases.addRecordMenuCompanyTypes;

      insertMenu = insertMenu.filter(menu => {
        const type = types.filter(type => type.id === menu.id)[0];

        return CompanyTypePermission.isPermitted(type.name);
      });
    }

    return insertMenu;
  }

  addPayment(selectedRow) {
    localStorage.setItem("new_payment_from_who_type", 1);
    localStorage.setItem("new_payment_amount_fc", selectedRow.payable_amount);
    localStorage.setItem("selected_list_purchases_invoice_id", selectedRow.id);
    localStorage.setItem("selected_list_payable_amount_fc", selectedRow.payable_amount_fc);
    localStorage.setItem(
      "new_customer_id_take_collection",
      selectedRow.supplier_id
    );
    this.props.router.push("/dashboard/payments/add");
  }

  goToPurchaseApi(selectedRow) {
    const self = this;
    const id = selectedRow.id;

    let formData = {};
    Api.get("/purchaseInvoiceLine/showAll/" + id, function(responses) {
      formData = {};
      if (responses.purchaseInvoiceLines) {
        formData.purchases = HelperContainer.filterObject(
          responses.purchaseInvoiceLines
        );

        let values = formData.purchases;

        self.setState({ purchaseApi: values, showModalPurchase: true });
      }
    });

    Api.get("/purchaseInvoice/" + id, function(responses) {
      formData = {};
      if (responses.purchaseInvoice) {
        formData.purchase = HelperContainer.filterObject(
          responses.purchaseInvoice
        );

        let purchaseDetail = formData.purchase;

        self.setState({ purchaseDetailApi: purchaseDetail });
      }
    });
  }

  sideLink() {
    if (this.state.tabName === "purchaseInvoice") {
      const all = this.props.purchaseInvoiceAll;
      if (all) {
          return (
            <Link
              to="/dashboard/purchases/eInBoxes"
              className="btn btn-default blue"
            >
              <i className="fa fa-file" />
              {(all.countTicari+all.countTemel+all.countInteraktifVDEarchive) +
                " e-Fatura / e-Arşiv Fatura Bulunmaktadır. "}
            </Link>
          );
      } else return null;
    } else return null;
  }

  fetch = (sizePerPage = 10, page = 1, order = [], date = [], search = "") => {
    this.setState({ dataLoading: true });
    this.fetchData(
      this.state.selectedTab,
      sizePerPage,
      page,
      order,
      date,
      search
    );
    this.setState({ dataLoading: false });
  };

  render() {
    const tabMenu = [];
    if (this.isPermitted("18", true) === true)
      tabMenu.push({
        id: 9,
        title: "FATURA",
        link: "/dashboard/purchases/purchaseInvoice"
      });
    if (this.isPermitted("42", true) === true)
      tabMenu.push({
        id: 12,
        title: "FİŞ",
        link: "/dashboard/purchases/receipt"
      });
    if (this.isPermitted("41", true) === true)
      tabMenu.push({
        id: 14,
        title: "POLİÇE",
        link: "/dashboard/purchases/insurancePolicy"
      });
    if (this.isPermitted("40", true) === true)
      tabMenu.push({
        id: 13,
        title: "DİĞER GİDERLER",
        link: "/dashboard/purchases/otherReceipt"
      });
    if (this.isPermitted("64", true) === true){
      if(Util.companyModules(2)===true){
      tabMenu.push({
        id: 15,
        title: "ALIŞ İRSALİYESİ",
        link: "/dashboard/purchases/purchaseReceipt"
      });
  }
  }
    var insertMenux = this.props.viewOptions
      ? this.props.viewOptions.purchases.addRecordMenu
      : false;
    if(insertMenux!=false){
      var user_json = JSON.parse(localStorage.getItem("user"));
      if(user_json.company_type == 9 || user_json.company_type == 6){
        var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 3);
        insertMenu = this.filterMenu(insertMenu);
      }else{
        insertMenu = this.filterMenu(insertMenux);
      }
    }
    if(Util.companyModules(2)!==true){
      //@oguz stok modulu satın alınmadıysa sevk irsaliyesini insertMenu dizisinden bul ve sil
      if (insertMenu && Array.isArray(insertMenu)) {
        insertMenu = insertMenu.filter(obj => obj.id !== 7);
      }
    }
    const allName = this.state.tabApi + "All";

    const allData = this.props[allName] || {};

    return Style.it(
      CSS,
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          {this.state.selectedTab === 9 ? (
              <PaginatedDataTable
                  title="ALIŞLAR"
                  selectedTab={this.state.selectedTab}
                  sideLink={this.sideLink()}
                  tabMenu={tabMenu}
                  // createCustomRowButtons={this.state.tabName === "purchaseInvoice" ? this.createCustomRowButtons.bind(this) : () => null}
                  openInsert={this.openInsert.bind(this)}
                  openDetail={this.openDetail.bind(this)}
                  openUpdate={this.openUpdate.bind(this)}
                  onDelete={this.onDelete.bind(this)}
                  data={this.props[this.state.tabApi]}
                  insertMenu={insertMenu}
                  addCollectionn={this.addPayment.bind(this)}
                  name={this.state.tabName}
                  fetchData={this.fetch}
                  pageCount={allData.last_page}
                  currentPage={allData.current_page}
                  totalCount={allData.total}
                  loading={this.state.dataLoading}
              />
          ) : (
              <EkoDataTable
                  title="ALIŞLAR"
                  selectedTab={this.state.selectedTab}
                  sideLink={this.sideLink()}
                  tabMenu={tabMenu}
                  // createCustomRowButtons={this.state.tabName === "purchaseInvoice" ? this.createCustomRowButtons.bind(this) : () => null}
                  openInsert={this.openInsert.bind(this)}
                  openDetail={this.openDetail.bind(this)}
                  openUpdate={this.openUpdate.bind(this)}
                  onDelete={this.onDelete.bind(this)}
                  data={this.props[this.state.tabApi]}
                  insertMenu={insertMenu}
                  name={this.state.tabName}
                  loading={this.state.dataLoading}
              />
          )}

          <PurchasesModal
              showModal={this.state.showModalPurchase}
              purchaseSend={this.state.purchaseApi}
              purchaseDetailApi={this.state.purchaseDetailApi}
              closeModal={() => {
                this.setState({showModalPurchase: false});
              }}
          />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    receipt: state.data.receipts && state.data.receipts.receipts,
    receiptAll: state.data.receipts,
    otherReceipt:
      state.data.otherReceipts && state.data.otherReceipts.otherReceipts,
    otherReceiptAll: state.data.otherReceipts,
    purchaseInvoice:
      state.data.purchaseInvoices &&
      state.data.purchaseInvoices.purchaseInvoices,
    purchaseInvoiceAll: state.data.purchaseInvoices,
    insurancePolicy:
      state.data.insurancePolicies &&
      state.data.insurancePolicies.insurancePolicies,
    insurancePolicyAll: state.data.insurancePolicies,
    viewOptions: state.data.viewOptions,
    dtConfig: state.data.dtConfig
  };
}

export default connect(mapStateToProps, actions)(Purchases);
