import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'

class AddSGKCompanyBranch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.editData || this.defaultFormData(),
            showModal: false,
            saving: false,
            errors: {},
            formFields: {},
            tradeRegisterOffices: {}            

        }
    }

    defaultFormData() {
        return {
            
        }
    }
    componentWillMount() {
        let formData = this.props.editData || this.defaultFormData();
        let errors = {}
        this.setState({formData: formData, errors: errors});

        this.getTicaretSicilMudurlukleri();
    }
    componentWillReceiveProps(nextProps) {
        let formData = nextProps.editData || this.defaultFormData();
        this.setState({formData: formData, showModal: nextProps.showModal});
    }
    onSave() {
        var self = this;
        let formData = this.state.formData;
        self.setState({saving: true});

        
        if (formData.isyeri_adi != "") {
            Api.put("/company/insertCompanySgkBranch", {formData}, function (responses, postErrors) {
                if (postErrors) {
                    if (postErrors.message) {
                        self.setState({saving: false});
                        toastr.error("Başarısız", "Kayıt girişi işlemi sırasında hata oluştu.");
                    }
                }else{
                    if (responses.actionResult == "1") {
                        toastr.success("Başarılı", "Kayıt eklendi. Lütfen bekleyiniz, liste yenileniyor.");
                        self.setState({saving: false, showModal: false});
                        if (self.props.onSave) {
                            self.props.onSave();
                        }
                    }
                }
            })
        }else{
            toastr.error("Başarısız", "Lütfen tüm alanları doldurunuz.");
            self.setState({saving: false});
        }

    }
    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    setFieldChange(type, field, value) {
        let formData = this.state.formData;
        let errors = this.state.errors || {};
        formData[field] = value;
        let bigForm = this.state.formData;
        this.setState({ formData: bigForm, errors: errors });
    }
    
    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
          formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }
    getTicaretSicilMudurlukleri() {
        Api.get("/getTradeRegistrationOffices", (response, errors) => {
            if (errors) {
                return;
            }
            let getData = response.data;
            this.setState({tradeRegisterOffices: getData });
        });
    }

    render() {

        const formProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }

        let ticaretSicilMudurlukleri = this.state.tradeRegisterOffices;
        
        let isyeriTurleri = [{"id": "merkez", "value": "MERKEZ"}, {"id": "mukellefiyyetsiz", "value": "MÜKELLEFİYYETSİZ ŞUBE"}];
        let kayitDurumlari = [{"id": "1", "value": "AKTİF"}, {"id": "0", "value": "PASİF"}];
        let isyeriMulkiyetTurleri = [{"id": "kira", "value": "KİRA"}, {"id": "malsahibi", "value": "MÜLK SAHİBİ"}, {"id": "diger", "value": "DİĞER"}];


        return <EkoModal 
            bsSize="xlg" 
            showModal={this.state.showModal}
            title={this.state.formData.id ? "Kayıt Güncelle" : "Yeni Kayıt Ekle"}
            onHide={() => {
                this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                this.onCancel();
            }}
            onSave={() => {
                this.props.onSave();
            }}
            spinner={this.state.saving}>
            <EkoForm formId={"add_company_sgk_branches"}>

                <div className="row" style={{"padding": "10px"}}>
                    <div className="col-md-12 col-xs-12">
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Sektör Kodu</th>
                                    <th>İşyeri Kodu</th>
                                    <th>Yeni Ünite</th>
                                    <th>Eski Ünite</th>
                                    <th>İşyeri Sicil No</th>
                                    <th>İl Kodu</th>
                                    <th>İlçe Kodu</th>
                                    <th>Kontrol No</th>
                                    <th>Aracı No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><EkoFormInputText {...formProps} id="sicil_sektor_kodu" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_isyeri_kodu" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_yeni_unite" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_eski_unite" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_isyeri" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_il_kodu" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_ilce_kodu" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_kontrol_no" type="text" isVertical={true} /></td>
                                    <td><EkoFormInputText {...formProps} id="sicil_araci_no" type="text" isVertical={true} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row" style={{"padding": "10px"}}>
                <div className="col-md-4">
                        <EkoFormSelect
                            {...formProps}
                            id="is_active"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={kayitDurumlari}
                            label="Kayıt Durumu"
                        />
                    </div>
                    <div className="col-md-4">
                        <EkoFormSelect
                            {...formProps}
                            id="isyeri_turu"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={isyeriTurleri}
                            label="İşyeri Türü"
                        />
                    </div>
                    <div className="col-md-4">
                            <EkoFormInputText {...formProps}
                                label="İşyeri Şube Kodu"
                                id="isyeri_sube_kodu"
                                type="text"
                                isVertical={true}
                            />
                    </div>
                </div>
                <div className='row' style={{"padding": "10px"}}>
                    <div className="col-md-4">
                            <EkoFormInputText {...formProps}
                                label="Ticaret Sicil Numarası"
                                id="ticaret_sicil_no"
                                isVertical={true}
                            />
                    </div>
                    <div className="col-md-4">
                        <EkoFormSelect
                            {...formProps}
                            id="ticaret_sicil_mudurlugu"
                            searchable={true}
                            optionId={"code"}
                            optionValue={"name"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={ticaretSicilMudurlukleri}
                            label="Ticaret Sicil Müdürlüğü"
                        />
                    </div>
                    <div className="col-md-4">
                            <EkoFormInputText {...formProps}
                                label="İşyeri/Şube Nace Kodu"
                                id="isyeri_nace_kodu"
                                isVertical={true}
                            />
                    </div>
                </div>
                <div className='row' style={{"padding": "10px"}}>
                    <div className="col-md-4">
                            <EkoFormInputText {...formProps}
                                label="Ticaret Şube Adı"
                                id="isyeri_adi"
                                isVertical={true}
                            />
                    </div>
                    <div className="col-md-4">
                        <EkoFormInputText {...formProps}
                                label="İşyeri Şube Adres No"
                                id="isyeri_adres_no"
                                isVertical={true}
                            />
                    </div>
                    <div className="col-md-4">
                        <EkoFormSelect
                            {...formProps}
                            id="isyeri_mulkiyet"
                            searchable={true}
                            optionId={"id"}
                            optionValue={"value"}
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={isyeriMulkiyetTurleri}
                            label="İşyeri Türü"
                        />
                    </div>
                </div>

                <Modal.Footer>
                    <div className="row buttonProccess2" style={{"padding": "10px"}}>
                        <EkoFormButton label={this.state.formData.id ? "Güncelle" : "Yeni Ekle"}
                            faClass="fa fa-plus"
                            isLoading={this.state.saving}
                            onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                            onClick={this.onCancel.bind(this)}
                        />
                    </div>
                </Modal.Footer>

            </EkoForm></EkoModal>
    }

}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(AddSGKCompanyBranch);
