import React from 'react';
import {Link} from 'react-router'
import PropTypes from 'prop-types';

const PortletBox = (props) => {

    const icon = props.icon || "";
    let effect = props.effect ? props.effect : 'effect2';
    return <div className="portlet">
        <div className="box row25 rowtext">
            <div className={"effect-hover " + effect}>
                <div className="dtext"><i
                    className={"i-" + props.color+ " " + icon}/>
                    <div className={"rowtitle i-" + props.color}>{props.title}</div>
                    <div className="detay">
                        {props.items.map((item, index) => {
                            return <Link key={index}
                                to={item.to}><span
                                className="effect-info">{item.title}</span></Link>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>

};

PortletBox.propTypes = {
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    effect: PropTypes.string,
    icon: PropTypes.string
};


export default PortletBox;