import React, {Component} from 'react'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import HelperContainer from '../../util/HelperContainer'
import Api from '../../util/Api'
import UpdateCompany from './updateCompany'
import {FETCH_COMPANY_ALL} from '../../actions/types'
import AddCompanyUserRole from "../Company/AddCompanyUserRole";
import Util from "../../util/Util";
class UserList extends Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showModal: false,
            errors: {},
            user: {},
            validationFields: [],
            companies: [] //table Item
        }

    }


    fetchCompanies = () => {
        this.setState({ dataLoading: true });
        if (this.table) this.table.cleanSelect();
        Api.get("/company/listAll", (res, err) => {
            if (res.status === "ok") {
                this.setState({
                    companies: res.companies,
                    sums: 1,
                    dataLoading: false
                });
            }
        });
    };

    componentWillMount() {
        this.fetchCompanies();
        this.setState({
            user: JSON.parse(localStorage.getItem('user'))
        })
        var user = JSON.parse(localStorage.getItem('user'));
        if(user.company_id!=2){
            window.location.href = "/";
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.companies) {
            this.setState({companies: nextProps.companies});
        }

    }


    openUpdate(selectedRow) {
        var self = this;
        const id = selectedRow.id;
        Api.get('/company/' + id,
            function (response) {
                let formData = {}
                formData.companies = HelperContainer.filterObject(response.companies);
                self.setState({
                    showModal: true,
                    errors: {},
                    formData: formData
                });
            })
    }



    onSave(formData, formFields) {
        this.fetchCompanies();
        this.setState({showModal: false, formData: false});
    }


    render() {
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <UpdateCompany
                    showModal={this.state.showModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showModal: false})
                    }}
                    errors={this.state.errors}
                />
                <EkoDataTable
                    addButtonShow={false}
                    openUpdate={this.openUpdate.bind(this)}
                    onFetch={this.fetchCompanies}
                    data={this.state.companies}
                    name={"companies"}>
                </EkoDataTable>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        companies: state.data.companies

    };
}

export default connect(mapStateToProps, actions)(UserList);
