import React, {Component} from 'react';
import {FETCH_EMPLOYEE, FETCH_COMPANY, FETCH_EMPLOYEE_SALARY} from "../../actions/types";
import * as actions from '../../actions'
import {connect} from 'react-redux';
import TinvoiceTable from "../../components/elements/TinvoiceTable/TinvoiceTable";
import PropTypes from 'prop-types';
import salaryService from "../../services/SalaryService";


class SalaryPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            salary: {},
            loading: true,
            loadingPdf: false,
            formData: {}
        };
    }


    componentWillReceiveProps(nextProps){

      if (!this.props.employee  && nextProps.employee) {
        const employee = nextProps.employee.employee;

        let formData = {
            ...this.state.formData,
            employment_date: employee.employment_date,
            termination_date: employee.termination_date,
            identity_number: employee.identity_number,
            employee_name: employee.firstname + " " + employee.lastname,
            employee_name_pdf: employee.firstname + "_" + employee.lastname,
        };

        this.setState({
            formData: formData,
        });
      }


      if (!this.props.company &&  nextProps.company ) {
        const company = nextProps.company;

        const identity_number = company.tax_identity_number !== null ? "/" + company.tax_identity_number : "";
        const formData = {
            ...this.state.formData,
            address: company.address,
            company_name: company.firstname + company.lastname,
            tax_office_name: company.tax_office_name + identity_number,
            sgk_code: company.sgk_code,
        };

        this.setState({
            formData: formData
        });
      }

      if (!this.props.salary && nextProps.salary) {
        const salary = nextProps.salary;


        const formData = {
            ...this.state.formData, ...salary,
            sosyal_yardimlar: salary.additional_subj_sgk + salary.additional_excl_sgk,
            yasal_kesintiler: salary.sgk_employee + salary.unemployment_employee + salary.stamp_tax + salary.income_tax + salary.deduction
        };

        this.setState({
            formData: formData,
            loading: false,
            salary: salary
        });
      }

    }

    componentWillMount() {
        const id = this.props.router.params.id;

        this.props.fetchData('/employee/' + this.props.router.params.employee_id, FETCH_EMPLOYEE);
        this.props.fetchData('/company', FETCH_COMPANY);
        this.props.fetchData('/employeeSalary/' + id, FETCH_EMPLOYEE_SALARY);
    }


    render() {
        const months = this.props.viewOptions.general.months;

        let title = this.state.loading ? 'Yükleniyor...' : months[this.state.salary.month - 1].name + ' / ' + this.state.salary.year + ' Bordro Ön İzleme';
        let tableData = this.props.tableData(this.state.salary.year, months[this.state.salary.month - 1]);

        salaryService.setSalaryData(this.state.formData);
        salaryService.setTableData(tableData);

        return <div className="portlet light ">
            <div className="portlet-title">
                <div className="caption font-dark">
                    <span className="caption-subject bold uppercase">{title}</span>
                </div>
                <div className="actions">
                    <div className="btn-group btn-group-devided" data-toggle="buttons">
                        <button type="button" id="print" onClick={() => {
                            window.print()
                        }} className="btn blue btn-sm"><i className="fa fa-print"></i>
                            Yazdır
                        </button>
                        <form style={{"display": "inline-block"}} method={"get"} target={"_blank"} action={process.env.REACT_APP_API_URL + '/employeeSalary/pdf/' + this.props.router.params.id}>
                            <button type="submit" className="btn blue btn-sm">
                                {this.state.loadingPdf ? <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i> :
                                    <span> <i className="fa fa-file"></i> PDF olarakKaydet</span>}

                            </button>
                        </form>

                    </div>
                </div>
            </div>
            <div className="portlet-body">
                {<TinvoiceTable title={"Firma Bilgiler"}/>}
            </div>
        </div>;
    }
}

SalaryPreview.propTypes = {
    router: PropTypes.object.isRequired,
    tableData: PropTypes.func.isRequired
};

function mapStateToProps(state) {

    return {
        viewOptions: state.data.viewOptions,
        salary: state.data.employeeSalary,
        company: state.data.company,
        employee: state.data.employee
    };
}

export default connect(mapStateToProps, actions)(SalaryPreview);
