import React from 'react';
import DashboardLayout from "../../components/elements/DashboardLayout";
import PortletBox from "../../components/elements/PortletBox";
import {connect} from 'react-redux'
import * as actions from '../../actions'

class SelfEmployment extends React.Component {

    getPortlets = () => {
        return [
            {
                title: 'Makbuz Kes',
                color: 'color1',
                icon: 'icon-docs',
                effect: 'effect1',
                items: [
                    {
                        title: 'Serbest Meslek Makbuzu',
                        to: '/dashboard/soleprofsalesinvoice/add'
                    }
                ]
            },

            {
                icon: 'glyphicon glyphicon-minus-sign',
                title: 'Gider Ekle',
                color: 'color2',
                effect: 'effect2',
                items: [
                    {
                        title: 'Gider Faturası',
                        to: '/dashboard/purchases/purchaseInvoice/add/expenseInvoice'
                    },
                    {
                        title: 'Fiş',
                        to: '/dashboard/purchases/receipt/add'
                    },
                    {
                        title: 'Diğer Giderler',
                        to: '/dashboard/purchases/otherReceipt/add',
                    },
                    {
                        title: 'Sigorta Poliçesi',
                        to: '/dashboard/purchases/insurancePolicy/add'
                    }
                ]
            }

        ];
    };


    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        const items = this.getPortlets();
        return <DashboardLayout>
                {items.map((item, index) => {
                    return <div key={index} className="col-md-6">
                        <PortletBox {...item}/>
                    </div>;
                })}
            </DashboardLayout>;
    }
}

function mapStateToProps(state) {
    return {
        pageLoading: state.page.pageLoading,
        pageTitle: state.page.title,
    };
}

export default connect(mapStateToProps, actions)(SelfEmployment);


