import React, { Component } from "react";
import {
  Col,
  ControlLabel,
  FormGroup,
  HelpBlock,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";

import EkoDatePicker from "../EkoDatePicker";
import TooltipService from "../../../services/TooltipService";

export default class EkoFormDate extends Component {
  componentWillMount() {
    if (this.props.onMount) {
      this.props.onMount(this.props.id);
    }
  }

  /*   shouldComponentUpdate(nextProps, nextState) {
    const nextValue =
      nextProps.formData && nextProps.formData[nextProps.id]
        ? nextProps.formData[nextProps.id]
        : nextProps.value;
    const nextError =
      nextProps.errors && nextProps.errors[nextProps.id]
        ? nextProps.errors[nextProps.id]
        : nextProps.error;

    const value = this.getValue();
    const error = this.getError();

    console.log("nextValue", nextValue, "value", value);

    return (
      value !== nextValue ||
      error !== nextError ||
      this.props.disabled !== nextProps.disabled
    );
  } */

  getValue = () => {
    return this.props.formData && this.props.formData[this.props.id]
      ? this.props.formData[this.props.id]
      : this.props.value;
  };
  getError = () =>
    this.props.errors && this.props.errors[this.props.id]
      ? this.props.errors[this.props.id]
      : this.props.error;

  onDateChange(dateField, value, formattedValue) {
    let date = formattedValue
      ? moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    this.props.onChange(dateField, date);
  }

  renderInput() {
    const error =
      this.props.errors && this.props.errors[this.props.id] ? (
        <ControlLabel>{this.props.errors[this.props.id]}</ControlLabel>
      ) : (
        false
      );
    let tooltip = "";
    if (error) {
      tooltip = (
        <Tooltip style={{ zIndex: 99999999 }} id="tooltip">
          {this.props.errors[this.props.id][0]}
        </Tooltip>
      );
    }
    var defaultValue =
      this.getValue() === "0000-00-00" ? "" : this.getValue();
    if((this.props.id == 'date' || this.props.id == 'date1' || this.props.id == 'time') && defaultValue==''){
      const today = new Date();
      defaultValue = moment(today).format("YYYY-MM-DD");
      var defaultValue2 = moment(today).format("DD-MM-YYYY");
      this.onDateChange(this.props.id,defaultValue,defaultValue2);
    }
    const showCalendarIcon = !defaultValue;

    return (
      <div className="input-icon right">
        {error && this.props.tooltip ? (
          <OverlayTrigger placement="left" overlay={tooltip}>
            <i
              style={{ right: "35px" }}
              className="fas fa-exclamation-triangle tooltips"
            />
          </OverlayTrigger>
        ) : (
          ""
        )}
        <EkoDatePicker
          onChange={this.onDateChange.bind(this)}
          name={this.props.id}
          disabled={this.props.disabled}
          dateFormat="DD-MM-YYYY"
          calendarPlacement={this.props.calendarPlacement}
          className="form-control form-control-inline date-picker"
          value={defaultValue === "0000-00-00" ? "" : defaultValue}
          showClearButton={!!(!showCalendarIcon && this.props.clearable)}
        />
        {/*{!this.props.clearable && <div className="form-control-focus"/>}*/}
        {showCalendarIcon ? (
          <i style={{ zIndex: "999" }} className="icon-calendar" />
        ) : null}
        {/*{!this.props.clearable  ? <i style={{zIndex: "999"}} className="icon-calendar"/> : null}*/}
      </div>
    );
  }

  render() {
    const error =
      this.props.errors && this.props.errors[this.props.id] ? (
        <ControlLabel>{this.props.errors[this.props.id]}</ControlLabel>
      ) : (
        false
      );
    let validationState = null;
    if (error) {
      validationState = "error";
    }
    const help = this.props.help ? (
      <HelpBlock>{this.props.help}</HelpBlock>
    ) : (
      ""
    );
    return !this.props.isVertical ? (
      <FormGroup controlId={this.props.id} validationState={validationState}>
        <Col
          componentClass={ControlLabel}
          md={parseInt(this.props.labelMd, 10)}
        >
          {TooltipService.getLabelWithTooltip(this.props.id, this.props.label)}
          &nbsp;{" "}
          {this.props.required ? <span className="required"> * </span> : ""}
        </Col>
        <Col md={parseInt(this.props.colMd, 10)}>
          {this.renderInput()}
          {error ? error : help}
        </Col>
      </FormGroup>
    ) : (
      <FormGroup controlId={this.props.id} validationState={validationState}>
        {this.props.label && (
          <Col componentClass={ControlLabel} className="control-line">
            {TooltipService.getLabelWithTooltip(
              this.props.id,
              this.props.label
            )}
            &nbsp;{" "}
            {this.props.required ? <span className="required"> * </span> : ""}
          </Col>
        )}
        {this.renderInput()}
        {error && !this.props.tooltip ? error : help}
      </FormGroup>
    );
  }
}

EkoFormDate.propTypes = {
  //label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errors: PropTypes.object,
  error: PropTypes.string,
  value: PropTypes.string,
  formData: PropTypes.object,
  clearable: PropTypes.bool
};

EkoFormDate.defaultProps = {
  value: "",
  type: "",
  labelMd: 5,
  colMd: 6,
  disabled: false,
  clearable: true
};
