export const AUTH_YES = "auth_yes";
export const AUTH_NO = "auth_no";
export const ERROR_AUTH = "error_auth";
export const AUTH_LOGOUT = "auth_logout";

export const CHANGE_PAGE_TITLE = "change_page_title";
export const PAGE_LOADING = "page_loading";
export const ADD_DOCUMENT = "add_document";
export const UPLOADED_DOCUMENT = "upload_document";

export const FETCH_DT_CONFIGS = "fetch_dt_configs";
export const FETCH_VIEW_OPTIONS = "fetch_view_options";
export const FETCH_TAX_OFFICES = "fetch_tax_offices";
export const FETCH_CITIES = "fetch_cities";
export const FETCH_COUNTIES = "fetch_counties";
export const FETCH_CURRENCIES = "fetch_currencies";
export const FETCH_PAYROLL_STATUSES = "fetch_payroll_statuses";
export const FETCH_VAT_RATE_TYPES = "fetch_vat_rate_types";
export const FETCH_ASSET_TYPES = "fetch_asset_types";
export const FETCH_EMPLOYEE = "fetch_employee";
export const FETCH_OUTSTANDING_COLLECTIONS = "fetch_outstanding_collections";

export const FETCH_COMPANY = "fetch_company";
export const FETCH_COMPANY_ALL = "fetch_company_all";
export const FETCH_COLLECTIONS = "fetch_collections";
export const FETCH_PAYMENTS = "fetch_payments";
export const FETCH_SUPPLIERS = "fetch_suppliers";
export const FETCH_CHEQUES = "fetch_cheques";
export const FETCH_ASSETS = "fetch_assets";
export const FETCH_LOANS = "fetch_banks";
export const FETCH_SUPPLIERS_ALL = "fetch_suppliers_all"; //tekli olanlar /list all olanlar /showAll apisindne gelenler
export const FETCH_CUSTOMERS_ALL = "fetch_customers_all";
export const FETCH_EMPLOYEES_ALL = "fetch_employees_all";
export const FETCH_BANK_ACCOUNTS = "fetch_bank_accounts";
export const FETCH_BANK_ACCOUNTS_ALL = "fetch_bank_accounts_all";
export const FETCH_CHEQUES_ALL = "fetch_cheques_all";
export const FETCH_CUSTOMERS = "fetch_customers";
export const FETCH_OTHER_RECEIPT_AUTOCOMPLETE =
  "fetch_other_receipt_autocomplete";
export const FETCH_EMPLOYEES = "fetch_employees";
export const FETCH_ITEMS = "fetch_items";
export const FETCH_ITEMS_ALL = "fetch_items_all";
export const FETCH_EXPENSE_CATEGORIES = "fetch_expense_categories";
export const FETCH_EXPENSE_TEMPLATES = "fetch_expense_templates";
export const FETCH_STOPPAGE_TYPES = "fetch_stoppage_types";
export const FETCH_SOLE_INVOICE_AUTOCOMPLETE =
  "fetch_sole_invoice_autocomplete";
export const FETCH_VAT_REDUCTION_TYPES = "fetch_vat_reduction_types";
export const FETCH_VAT_REDUCTION_TYPE_CODES = "fetch_vat_reduction_type_code";
export const FETCH_PURCHASE_INVOICES = "fetch_purchase_invoices";
export const FETCH_RECEIPTS = "fetch_receipts";
export const FETCH_INSURANCE_POLICIES = "fetch_insurance_policies";
export const FETCH_OTHER_RECEIPTS = "fetch_other_receipts";
export const FETCH_SOLE_PROF_SALES_INVOICES = "fetch_sole_prof_sales_invoices";
export const FETCH_PROJECTS = "fetch_projects";
export const FETCH_TRANSFERS = "fetch_transfers";
export const FETCH_DBS_PURCHASES = "fetch_dbs_purchases";
export const FETCH_DBS_SALES = "fetch_dbs_sales";
export const FETCH_DBS_Z_SALES = "fetch_dbs_z_sales";
export const FETCH_CUSTOMER_TRANSACTION = "fetch_customer_transaction";
export const FETCH_SUPPLIER_TRANSACTION = "fetch_supplier_transaction";
export const FETCH_EMPLOYEE_TRANSACTION = "fetch_employee_transaction";
export const FETCH_BANK_TRANSACTION = "fetch_bank_transaction";
export const FETCH_INVITATIONS = "fetch_invitations";
export const FETCH_SALES_INVOICE = "fetch_sales_invoice";
export const FETCH_SALARY_PREVIEW = "fetch_salary_preview";
export const FETCH_ROLES = "fetch_roles";
export const FETCH_EMPLOYEE_SALARY = "fetch_employee_salary";
export const FETCH_EMPLOYEE_SALARIES = "fetch_employee_salaries";
export const FETCH_UNITS = "fetch_units";
export const FETCH_TAX_TYPES = "fetch_tax_types";
export const FETCH_DELIVERY_TYPES = "fetch_delivery_types";
export const FETCH_TRANSPORT_TYPES = "fetch_transport_types";
export const FETCH_PACKAGE_TYPES = "fetch_package_types";
export const FETCH_USER = "fetch_user";
export const FETCH_PIE_CHART_VALUES = "fetch_pie_chart_values";
export const FETCH_SALES_CHART_VALUES = "fetch_sales_chart_values";
export const FETCH_PURCHASE_CHART_VALUES = "fetch_purchase_chart_values";
export const FETCH_STATEMENT_TYPES = "fetch_statement_types";
export const FETCH_STATEMENT_DUES = "fetch_statement_dues";
export const FETCH_PLANS = "fetch_plans";
export const FETCH_PLAN_CHECK = "fetch_plan_check";
export const FETCH_TEMPLATES = "fetch_templates";
export const FETCH_VIDEO_DATAS = "fetch_video_data";
export const FETCH_VIDEO_CONFIGS = "fetch_video_configs";
export const FETCH_REPORTS = "fetch_reports";
export const FETCH_ZREPORTS = "fetch_zreports";
export const FETCH_OTHER_SALES_LIST = "fetch_other_sales_list";
export const FETCH_PROFORMAS = "fetch_proformas";
export const FETCH_FIXED_ASSET = "fetch_fixed_asset";
export const FETCH_SOLEPROFV2 = "fetch_soleprofv2s";
export const FETCH_WAREHOUSE_TRANSFERS = "fetch_warehouse_transfers";
export const FETCH_WAREHOUSE_TRANSACTIONS = "fetch_warehouse_transactions";
export const FETCH_ZREPORT = "fetch_zreport";
export const FETCH_OTHER_SALES = "fetch_other_sales";
export const FETCH_E_IN_BOXES = "fetch_e_in_boxes";
export const FETCH_TABLE_DATA = "fetch_table_data";
export const FETCH_ITEM_TRANSACTION = "fetch_item_transaction";
export const FETCH_DECLARATION_TYPES = "fetch_declaration_types";