import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import AddPayment from "./AddPayment";
import Api from "../../util/Api";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import {FETCH_CUSTOMERS_ALL, FETCH_EMPLOYEES_ALL, FETCH_SUPPLIERS_ALL} from "../../actions/types";

class PaymentAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: false,
      errors: {},
      fromWhoType: localStorage.getItem("new_payment_from_who_type")
    };
  }

  componentWillMount() {
    this.props.fetchData("/supplier/showAll", FETCH_SUPPLIERS_ALL);
    this.props.fetchData("/employee/showAllActive", FETCH_EMPLOYEES_ALL);
    this.props.fetchData("/customer/showAll", FETCH_CUSTOMERS_ALL);
    var self = this;
    if (this.props.params && this.props.params.id) {
      Api.get("/payment/" + this.props.params.id, function(response, err) {
        if (response.payment) {
          let formData = { payment: response.payment };
          Api.get("/bankTransaction/collectionIdToBankTransaction/" + formData.payment.id, function(response2, err) {
            try {
              formData.payment.customer_currency = formData.payment.currency;
              formData.payment.customer_rate = Util.convertDecimalToString(formData.payment.currency_rate, 4);
              if (response2.currency_rate_bank === null){
                formData.payment.currency_rate = "1,0000";
                //response2.currency_rate_bank = formData.collection.currency_rate;
              }else {
                formData.payment.currency_rate = response2.currency_rate_bank;
                formData.payment.currency = response2.currency;
              }
              formData.payment.date = Util.convertFromDatabaseDate(
                  formData.payment.date
              );
              formData.payment.amount_fc = Util.convertDecimalToMoney(
                  formData.payment.amount_fc
              );

              if (formData.payment.customer_currency === formData.payment.currency){
                formData.payment.amount = formData.payment.amount_fc;
              }
              else if (formData.payment.customer_currency !== formData.payment.currency && formData.payment.currency !== 'TRY'){
                formData.payment.amount = Util.convertDecimalToMoney(response2.amount * -1);
              }
              else {
                formData.payment.amount = Util.convertDecimalToMoney(formData.payment.amount);
              }
              formData.payment.currency_rate = formData.payment.currency_rate
                  .toString()
                  .replace(/[#.]/g, ",");
              formData.payment.customer_rate = formData.payment.customer_rate
                  .toString()
                  .replace(/[#.]/g, ",");
            } catch (ex) {
              console.log(ex);
            }
            self.setState({
              formData: formData,
              fromWhoType: formData.payment.type
            });
          });
        } else {
          toastr.error("Hata", err.message);
          self.props.router.push("/dashboard/payments");
        }
      });
    }
  }

  getTitle() {
    let viewOptions = this.props.viewOptions
        ? this.props.viewOptions.payments.type
        : [];
    let selected = viewOptions.filter(
        item => item.id === parseInt(this.state.fromWhoType, 10)
    );
    return selected.length === 0 ? "" : " - " + selected[0].name;
  }

  redirectToBase() {
    this.props.router.push("/dashboard/payments");
  }

  render() {
    let title = this.getTitle();
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/payments")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption font-dark">
                <span className="caption-subject bold uppercase">ÖDEME{title}</span>
              </div>
            </div>
            <div className="portlet-body">
              <AddPayment
                  formData={this.state.formData}
                  fromWhoType={this.state.fromWhoType}
                  redirectToBase={this.redirectToBase.bind(this)}
              />
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payments: state.data.payments, //pek lazim olmadi gerci
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(PaymentAddUpdate);
