/*
    COREBYTE TEKNOLOJİ YAZILIM DANIŞMANLIK TİC. LTD. ŞTİ.
    www.corebyte.tech - 0850 360 6789 - info@corebyte.tech
    ObifinBankApiAdd.js - 10.05.2023
*/
import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import EkoModal from '../../components/elements/EkoModal'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import EkoSpinner from "../../components/elements/EkoSpinner";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";

class ObifinBankApiAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: this.defaultFormData(),
            showModal: false,
            saving: false,
            loading: true,
            errors: {},
            formFields: {},
            editId: null,
            banks_list: [],
            // Tüm bankaların ortak fieldları buraya tanımlanacak
            all_bank_form_fields: [
                {
                    "FirmaAnahtar": false,
                    "KullaniciAdi": false,
                    "Sifre": false,
                    "KurumKodu": false,
                    "MusteriNo": false,
                    "Url": false,
                    "FirmaKodu": false,
                    "BankaApiAdi": false,
                    "FirmaAdi": false,
                    "ServiceID": false,
                    "KurumKullanici": false,
                }
            ],
            bank_is_active: false,
        }
    }

    defaultFormData() {
        // Tüm bankaların ortak fieldları buraya tanımlanacak
        return {
            "bank_id": "",
            "FirmaAnahtar": "",
            "KullaniciAdi": "",
            "Sifre": "",
            "KurumKodu": "",
            "MusteriNo": "",
            "Url": "",
            "FirmaKodu": "",
            "BankaApiAdi": "",
            "FirmaAdi": "",
            "ServiceID": "",
            "KurumKullanici": "",
        }
    }
    componentWillMount() {
        let _self = this;

    }
    componentWillReceiveProps(nextProps) {
        let _self = this;
        let formData = this.defaultFormData();
        let errors = {}
        if (nextProps.showModal == true){
            _self.setState({showModal: true, editId: nextProps.editId});
            formData.bank_id = nextProps.editId;
            _self.setFieldChange("bank_id", "bank_id", nextProps.editId);

            _self.updateAllBankFieldsToFalse();
            _self.getBanksList();
            _self.setState({formData: formData, errors: errors, bank_is_active: false});

        }else {
            _self.setState({showModal: false, editId: null});
            _self.setFieldChange("bank_id", "bank_id", null);
        }
    }
    getBanksList() {
        let _self = this;
        let params = {};
        Api.post("/getObifinBanks", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                let getData = response.result;
                _self.setState({banks_list: getData });
                _self.createBankNeededFormFields(_self.state.editId);
            }
        });
    }
    onSave() {
        let self = this;
        let formData = self.state.formData;
        self.setState({saving: true});
        Api.post("/activateObifinBankApi", formData, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                if (response.result == 1) {
                    self.setState({saving: false, showModal: false});
                    //toastr.success("Başarılı!", "Banka API eklendi ve aktif edildi.");
                    swal({
                        title: "Banka API Bilgileriniz Kaydedildi",
                        text: "Hesaplar menüsü altındaki Kasa/Banka sayfasına gidip var olan banka hesabınızı şuan aktif ettiğiniz API'ye bağlayabilirsiniz ya da yeni Kasa/Banka hesabı ekleyebilirsiniz."+
                            "<br/><br/>Yeni Kasa/Banka eklerken veya var olan Kasa/Banka kayıtlarınızı güncellerken <span style='font-weight: bold;'>Gelişmiş Seçenekler</span> altından IBAN bilgilerinizi tanımlayabilirsiniz."+
                            "<br/><br/><a class='btn btn-primary blue' href='/dashboard/banks'>KASA / BANKA Sayfasına Gitmek İçin Tıklayın</a><br/><br/><a href='"+(process.env.REACT_APP_API_URL).replace('/public/api', '/public').replace('api.ekohesap.com/api', 'api.ekohesap.com')+"/panel/download.php?folder=documents/open-banking-application-forms&file=Banka_Entegrasyon_Dokumani.pdf' class='btn btn-primary blue'>Banka Entegrasyon Klavuzunu İndir</a>",
                        showConfirmButton: true,
                        confirmButtonText: "Tamam",
                        confirmButtonColor: "darkgray",
                        showCancelButton: false,
                        cancelButtonText: "İptal",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        html: true,
                        type: "success",
                        customClass: 'sweet-alert',
                    }, function(isConfirm) {
                        if (isConfirm) {
                            //window.location.href = "/dashboard/obifin";
                        }
                    });
                    if (self.props.onSave) {
                        self.props.onSave();
                    }
                }
                else if (response.result == 2) {
                    self.setState({saving: false});
                    toastr.error("Hata!", "Kayıt sırasında hata oluştu: " + response.hata);
                    return false;
                }
                else if (response.result == 3) {
                    self.setState({saving: false});
                    toastr.error("Hata!", "Açık bankacılık webservis bilgileriniz eksik.");
                    return false;
                }
                else {
                    self.setState({saving: false});
                    toastr.error("Hata!", "Kayıt sırasında bir hata oluştu");
                    return false;
                }
            }
        });

    }
    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    setFieldChange(type, field, value) {
        let _self = this;
        let formData = _self.state.formData;
        let errors = _self.state.errors || {};
        formData[field] = value;
        let bigForm = _self.state.formData;
        _self.setState({ formData: bigForm, errors: errors});

    }
    updateAllBankFieldsToFalse = () => {
        const updatedFormFields = this.state.all_bank_form_fields.map(field => {
            const updatedField = { ...field };
            Object.keys(updatedField).forEach(key => {
                updatedField[key] = false;
            });
            return updatedField;
        });
        this.setState({ all_bank_form_fields: updatedFormFields });
    }
    createBankNeededFormFields(bank_id) {
        let _self = this;
        // search in banks_list for bank_id and get "inputs" attribute
        let banks_list = _self.state.banks_list;
        let bank_inputs = [];
        let bank_is_active = false;
        let bank_api_credentials = {};
        for (let i = 0; i < banks_list.length; i++) {
            if (banks_list[i].id == bank_id) {
                bank_inputs = banks_list[i].inputs;
                bank_is_active = banks_list[i].is_active;
                bank_api_credentials = JSON.parse(banks_list[i].bank_api_credentials);
                // if bank_api_credentials is not empty, then we are editing
                if (bank_api_credentials != null) {
                    // get bank_api_credentials elements and set values using setFieldChange
                    Object.keys(bank_api_credentials).forEach(key => {
                        _self.setFieldChange(key, key, bank_api_credentials[key]);
                    });
                }
                break;
            }
        }
        _self.setState({ bank_is_active: bank_is_active });
        _self.updateAllBankFieldsToFalse();
        // now explode commas in bank_inputs string and for each name append to id width "form-fields" div
        const updatedBankFormFields = [..._self.state.all_bank_form_fields];
        let bank_inputs_array = bank_inputs.split(",");
        for (let i = 0; i < bank_inputs_array.length; i++) {
            // search in all_bank_form_fields and set it to true
            for (let j = 0; j < _self.state.all_bank_form_fields.length; j++) {
                if (_self.state.all_bank_form_fields[j][bank_inputs_array[i]] != undefined) {
                    updatedBankFormFields[j][bank_inputs_array[i]] = true;
                }
            }
        }
        _self.setState({ all_bank_form_fields: updatedBankFormFields, loading: false });
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }

    render() {

        const inputProps = {
            onChange: this.setFieldChange.bind(this, "data"),
            onMount: this.setFieldMount.bind(this, "data"),
            formData: this.state.formData,
            errors: this.state.errors
        }

        return (

            <>
                <EkoModal
                    showModal={this.state.showModal}
                    title={this.state.formData.id ? "Güncelle" : "Banka Aktif Et"}
                    onHide={() => {
                        this.setState({showModal: false, formData: this.defaultFormData(), errors: {}})
                        this.onCancel(); // ObifinBankApis.js deki ObifinBankApiAdd componentindeki onCancel fonksiyonunu çalıştırır.
                    }}
                    spinner={this.state.saving}>

                    {(this.state.loading == true) ? <EkoSpinner /> :
                        <EkoForm formId={"add_new_data"}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={"alert alert-info"}>
                                        <strong>Önemli Bilgiler:</strong><br/>
                                        - Banka API bilgilerini bankanızdan talep ederek alabilirsiniz.<br/>
                                        - Her banka için sadece 1 adet API tanımlama yapılması yeterlidir.<br/>
                                        - API tanımlarını yaptıktan sonra banka hesaplarınızı tanımlayabileceksiniz.
                                    </div>
                                </div>
                                {this.state.bank_is_active === false && (
                                    <div className="col-md-12 text-center">
                                        <label className={"text-danger"}><strong>Şu an PASİF konumda olan banka API'si kaydettiğinizde AKTİF edilecektir.</strong></label>
                                    </div>
                                )}
                            </div>
                            <div className="row" id={"form-fields"}>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].BankaApiAdi ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="BankaApiAdi"
                                        style={{width: "100%"}}
                                        label="Banka API Adı"
                                        placeHolder="Bu işleme bir isim veriniz"
                                        isVertical={true}
                                        value={this.state.formData.BankaApiAdi}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].FirmaAdi ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="FirmaAdi"
                                        style={{width: "100%"}}
                                        label="Firma Adı"
                                        placeHolder="Firma adını giriniz"
                                        isVertical={true}
                                        value={this.state.formData.FirmaAdi}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].FirmaAnahtar ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="FirmaAnahtar"
                                        style={{width: "100%"}}
                                        label="Firma anahtarı"
                                        placeHolder=""
                                        isVertical={true}
                                        value={this.state.formData.FirmaAnahtar}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].KullaniciAdi ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="KullaniciAdi"
                                        style={{width: "100%"}}
                                        label="Kullanıcı Adı"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].KurumKodu ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="KurumKodu"
                                        style={{width: "100%"}}
                                        label="Kurum kodu"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].Sifre ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="Sifre"
                                        style={{width: "100%"}}
                                        label="Şifre"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].ServiceID ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="ServiceID"
                                        style={{width: "100%"}}
                                        label="Service ID"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].MusteriNo ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="MusteriNo"
                                        style={{width: "100%"}}
                                        label="Müşteri numarası"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].Url ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="Url"
                                        style={{width: "100%"}}
                                        label="Url adresi"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].FirmaKodu ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="FirmaKodu"
                                        style={{width: "100%"}}
                                        label="Firma kodu"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                                <div
                                    className={`col-md-12 col-xs-12 ${!this.state.all_bank_form_fields[0].KurumKullanici ? "hidden" : ""}`}>
                                    <EkoFormInputText
                                        {...inputProps}
                                        id="KurumKullanici"
                                        style={{width: "100%"}}
                                        label="Kurum Kullanıcı"
                                        placeHolder=""
                                        isVertical={true}
                                    />
                                </div>
                            </div>

                            <Modal.Footer>
                                <div className="row buttonProccess2">
                                    <EkoFormButton label={this.state.formData.id ? "Güncelle" : "Kaydet & Ekle"}
                                                   faClass="fa fa-plus"
                                                   isLoading={this.state.saving}
                                                   onClick={this.onSave.bind(this)}
                                    />
                                    <EkoFormButton label="İptal" className="btn"
                                                   onClick={this.onCancel.bind(this)}
                                    />
                                </div>
                            </Modal.Footer>

                        </EkoForm>
                    }

                </EkoModal>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        formData: state.data.formData,
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(ObifinBankApiAdd);
