import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { EkoForm, EkoFormButton, EkoFormSelect } from "../elements/EkoForm";
import { toastr } from "react-redux-toastr";
import EkoModal from "../elements/EkoModal";
import Api from "../../util/Api";

class MarketplaceNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      saving: false,
      formData: {
        marketplace: "Trendyol",
        supplier_id: "",
        apikey: "",
        apisecret: "",
        store_name: "Benim Mağazam",
      },
    };
    //console.log(this.props, "props");
  }

  get defaultFormData() {
    return {
      marketplace: "Trendyol",
      supplier_id: "",
      apikey: "",
      apisecret: "",
      store_name: "Benim Mağazam",
    };
  }

  componentWillMount() {}

  /**
   * 
   * @param string field 
   * @param string value 
   */
  fieldOnChange(field, value) {
    let formData = { ...this.state.formData };
    formData[field] = value;
    this.setState({ formData });
  }
  fieldOnMount(field, value) {}
  onSave() {
    this.setState({ saving: true });

    Api.post("/marketplace", this.state.formData, (res, err) => {
      if (err) {
        toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
        this.setState({ saving: false });
        return;
      }
      toastr.success("Başarılı", "Kayıt başarıyla eklendi");
      this.setState({ saving: false });
      this.props.onHide(true);
    });
  }

  /**
   * Cancel button handler
   */
  cancel(){
    this.setState({formData:this.defaultFormData})
    this.props.onHide();
  }

  render() {
    const fieldProps = {
      onChange: this.fieldOnChange.bind(this),
      onMount: this.fieldOnMount.bind(this),
      formData: this.state.formData,
    };
    return (
      <>
        <EkoModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          title={this.props.title}
        >
          <EkoForm>
            <div className="row">
              <EkoFormSelect
                {...fieldProps}
                label={"Pazaryeri"}
                name={"marketplace"}
                id={"marketplace"}
                options={[
                  { id: "Trendyol", name: "Trendyol" },
                  { id: "Hepsiburada", name: "Hepsiburada" },
                  { id: "Ciceksepeti", name: "Ciceksepeti" },
                  { id: "N11", name: "N11" },
                ]}
              />
              {this.props.formElements(fieldProps)}
            </div>
            <Modal.Footer>
              <div className="row buttonProccess2">
                <EkoFormButton
                  label={this.state.formData.id ? "Güncelle" : "Kaydet & Ekle"}
                  faClass="fa fa-plus"
                  isLoading={this.state.saving}
                  onClick={this.onSave.bind(this)}
                />
                <EkoFormButton
                  label="İptal"
                  className="btn"
                  onClick={this.cancel.bind(this)}
                />
              </div>
            </Modal.Footer>
          </EkoForm>
        </EkoModal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    viewOptions: state.data.viewOptions,
  };
}

export default connect(mapStateToProps, actions)(MarketplaceNew);
