import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormRadioButton,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import EkoModal from "../../components/elements/EkoModal";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import moment from "moment";
import Util from "../../util/Util";
import NewTagDocumentModal from "../Company/newTagDocument";

import {
  FETCH_BANK_ACCOUNTS_ALL,
  FETCH_CUSTOMERS_ALL,
  FETCH_SUPPLIERS_ALL
} from "../../actions/types";

class AddCheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      showModal: false,
      saving: false,
      errors: {},
      formFields: {},
      supplierAndCustomer: [],
      radioButtons: [
        {
          label: "Çek",
          id: "type",
          radio_id: 0,
          value: 0
        },
        {
          label: "Senet",
          id: "type",
          radio_id: 1,
          value: 1
        }
      ],
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:14,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
    //  this.onCancel = this.onCancel.bind(this);
  }

  defaultFormData() {
    return {
      cheque: { type: 0, currency: "TRY", currency_rate: 1, number_of_bill: 1 }
    };
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = (formData) => {
    formData = formData || this.state.formData;
    if (formData.cheque.id) {
      Api.get("/company/newTagRecordDocumentList/14/" + formData.cheque.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }else{
          this.resetNewTagModalList();
        }
      });
    }else{
        this.resetNewTagModalList();
    }
  }
  resetNewTagModalList = () => {
    this.setState(prevState => ({
      newTagRows: {
        ...prevState.newTagRows,
        rows: [
          { id:null,new_tag_type_id:14,new_tag_id: "",record_id:null, rate: "%100,00" }
        ],
      },
    }));
  }
  static defaultProps = {
    isGiro: false,
    isOut: 1
  };

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.props.fetchData("/supplier/showAllCustom", FETCH_SUPPLIERS_ALL);
    this.props.fetchData("/customer/showAllCustom", FETCH_CUSTOMERS_ALL);
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);

    this.setState({ formData: formData, errors: errors });
  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    let radio = this.state.radioButtons[0];
    let radio2 = this.state.radioButtons[1];
    if (formData.cheque?.type === 0) {
      radio["checked"] = true;
      radio2["checked"] = false;
    } else if (formData.cheque?.type === 1) {
      radio["checked"] = false;
      radio2["checked"] = true;
    }
    if (nextProps.formData) {
      this.getNewTagModalList(nextProps.formData);
      let type = 0; // optionId biz verdiğimizden güncellemede gelen verilere göre vermeliyiz
      if (formData.cheque?.from_id) {
        type = formData.cheque?.from_type === 0 ? "customer" : "supplier";
        let selected = this.state.supplierAndCustomer.filter(item => {
          return item.id === formData.cheque?.from_id && item.type === type;
        });
        formData.cheque.from = selected[0].optionId;
      }
      if (formData.cheque?.to_id) {
        type = formData.cheque?.to_type !== 0 ? "customer" : "supplier";
        let selected2 = this.state.supplierAndCustomer.filter(item => {
          return item.id === formData.cheque?.to_id && item.type === type;
        });
        formData.cheque.to = selected2[0].optionId;
      }
    }else{
      this.getNewTagModalList(false);
    }
    let supplierAndCustomer = this.state.supplierAndCustomer;
    if (
      nextProps.customers &&
      nextProps.suppliers &&
      this.state.supplierAndCustomer.length === 0
    ) {
      supplierAndCustomer = this.customerAndSupplier(nextProps);
    }
    this.setState({
      formData: formData,
      errors: {},
      showModal: nextProps.showModal,
      supplierAndCustomer: supplierAndCustomer
    });
  }

  setCurrencyRate() {
    const self = this;
    if (this.state.formData.cheque?.currency) {
      let currency = this.state.formData.cheque?.currency;
      Api.getCurrencyRate(currency, null, (rate, err) => {
        if (rate) {
          let currency_rate = Util.convertDecimalToString(rate, 4);
          let formData = this.state.formData;
          formData.cheque = Object.assign({}, formData.cheque, {
            currency_rate: currency_rate
          });
          self.setState({ formData: formData });
        }
      });
    } else {
    }
  }

  customerAndSupplier(nextProps) {
    let customerAll = nextProps.customers || [];
    let supplierAll = nextProps.suppliers || [];
    let length = customerAll.length;

    customerAll.map((item, i) => {
      let lastname = item.lastname ? " - " + item.lastname : "";
      item.name = item.firstname + lastname;
      item.optionId = i + 1;
      item.type = "customer";

      return item;
    });
    supplierAll.map((item, i) => {
      let lastname = item.lastname ? " - " + item.lastname : "";
      item.name = item.firstname + lastname;
      item.optionId = length + i + 10;
      item.type = "supplier";

      return item;
    });
    return customerAll.concat(supplierAll);
  }

  //NOTE @bocek herhangi bir forma ait alt formlardan birine ait field degiince ( address.city_id yada supplier.firstname)
  // type=> address, field=>city_id gibi
  setFieldChange(type, field, value) {
    if (type === "cheque" && field === "currency_rate") {
      let clean = value.replace(/[^0-9,]/g, "").replace(/(,.*?),(.*,)?/, "$1");
      if (clean !== value) value = clean;
    }
    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    if (type === "cheque" && field === "currency") {
      this.setCurrencyRate();
    }
    if (field === "to") {
      let selected = this.state.supplierAndCustomer.filter(item => {
        return item.optionId === value;
      });
      if (selected && selected[0].type) {
        formData.to_type = selected[0].type === "supplier" ? 0 : 1;
        formData.to_id = selected[0].id;
      }
    }
    if (field === "from") {
      let selected2 = this.state.supplierAndCustomer.filter(item => {
        return item.optionId === value;
      });
      if (selected2 && selected2[0].type) {
        formData.from_type = selected2[0].type === "customer" ? 0 : 1;
        formData.from_id = selected2[0].id;
      }
    }
    this.setState({ formData: bigForm, errors: errors });
  }

  //NOTE @bocek update ederken hangi fieldlari gonderecegimi bilmem icin bir field kendini
  //mount edince onlari formFields diye birseyde topluyorum, elle de yazılabilir formFields
  //ben ugrasmak istemedim :) type=>contact, field=>firstname gibi
  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  multiSave = async cheque => {
    const count = cheque.number_of_bill - 1;
    let date = cheque.due_date;
    let legalNo = parseInt(cheque.legal_no, 10);

    for (let i = 0; i < count; i++) {
      legalNo += 1;
      date = moment(date)
        .add(1, "M")
        .format("YYYY-MM-DD");
      const sendData = {
        ...cheque,
        due_date: date,
        legal_no: legalNo
      };

      await Api.asyncPost("/cheque", sendData, function(response, error) {
        if (response) {
          setTimeout(() => {
            this.setState({
              saving: false,
              errors: {},
              formData: this.defaultFormData()
            });
          }, 1000);
        }
      });
    }
  };

  onSave() {
    const self = this;
    self.setState({ saving: true });

    let commission = Util.convertMoneyToDecimal(
      this.state.formData.cheque?.commission || "0,00"
    );
    let amount = Util.convertMoneyToDecimal(
      this.state.formData.cheque?.amount || "0,00"
    );
    let currency_rate = Util.convertMoneyToDecimal(
      this.state.formData.cheque?.currency_rate || "0,00"
    );

    let cheques = Object.assign({}, self.state.formData.cheque, {
      commission: commission,
      currency_rate: currency_rate,
      amount: amount,
      is_collection: self.props.isCollection,
      newTagRows:this.state.newTagRows
    });

    let errors = self.state.errors;

    let func = "post";
    let url = "/cheque";

    if (cheques.id) {
      func = "put"; //NOTE edit işlemi
      url = "/cheque/" + cheques.id;
    }else{
      cheques.in_out = this.props.isOut;
    }
    Api[func](url, cheques, function(response, postErrors) {
      if (postErrors) {
        errors.cheque = postErrors;
        if (postErrors.from_id) errors.cheque.from = postErrors.from_id;
        if (postErrors.to_id) errors.cheque.to = postErrors.to_id;
        self.setState({ saving: false, errors: errors });
      } else {
        if (cheques.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");
          self.setState({ saving: false, errors: {} });
          self.props.onSave();
        } else {
          setTimeout(() => {
            self.setState({
              saving: false,
              errors: {},
              formData: self.defaultFormData()
            });
            toastr.success("Başarılı", "Başarıyla Kaydedildi");
            self.props.onSave();
          }, 1800);
        }
      }
    });

    setTimeout(() => {
      if (this.state.formData.cheque?.type === 1) {
        if (
          this.state.formData.cheque?.due_date !== undefined &&
          this.state.formData.cheque?.legal_no !== undefined &&
          this.state.formData.cheque?.status_cid !== undefined &&
          this.state.formData.cheque?.status_cid !== undefined &&
          this.state.formData.cheque?.amount !== undefined &&
          (this.state.formData.cheque?.from_id !== undefined ||
            this.state.formData.cheque?.to_id !== undefined)
        ) {
          this.multiSave(cheques);
        }
      }
    }, 350);
  }

  onCancel() {
    this.resetNewTagModalList();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  getBtnText() {
    if (!this.state.formData.cheque?.id) return "Kaydet";
    else {
      if (this.props.isGiro) return "Cirola";
      else if (this.props.isCollection) {
        if (this.state.formData.cheque?.from_id) return "Tahsil Et";
        else return "Ödeme Yap";
      } else return "Güncelle";
    }
  }

  getTitle() {
    if (!this.state.formData.cheque?.id) return "Yeni Çek/Senet Ekle";
    else {
      if (this.props.isGiro) return "Cirola";
      else if (this.props.isCollection === 1) {
        if (this.state.formData.cheque?.from_id) return "Tahsil Et";
        else return "Ödeme Yap";
      } else return this.state.formData.cheque.type===0?"Çek Güncelle":"Senet Güncelle";
    }
  }

  statuses() {
    let statuses =
      this.props.viewOptions &&
      this.props.viewOptions.cheques &&
      this.props.viewOptions.cheques.status
        ? this.props.viewOptions.cheques.status
        : [];
    if (
      !this.props.isGiro &&
      this.props.isCollection !== 1 &&
      this.state.formData.cheque?.is_collection !== 1 &&
      !(this.state.formData.cheque?.from_id && this.state.formData.cheque?.to_id)
    ) {
      statuses = statuses.filter(item => {
        return item.id !== 2 && item.id !== 8 && item.id !== 9;
      });
    }


    if (this.state.formData.cheque.type===0 && this.state.formData.cheque?.from_id) {
      //gelen çek
      statuses = statuses.filter(item => {
        return item.id !== 2 && item.id !== 3 && item.id !== 8 && item.id !== 9;
      });
    }

    if (this.state.formData.cheque.type===0 && !this.state.formData.cheque?.from_id) {
      //gelen çek
      statuses = statuses.filter(item => {
        return item.id !== 1 && item.id !== 2 && item.id !== 5 && item.id !== 6 && item.id !== 7 && item.id !== 8 && item.id !== 9;
      });
    }


    return statuses;
  }

  bankAccounts() {
    const self = this;
    let bankAccounts = this.props.bankAccounts || [];

    bankAccounts = bankAccounts.filter(item => {
      return (
        item.currency === self.state.formData.cheque?.currency &&
        (parseInt(item.bank_account_type, 10) === 1 ||
          parseInt(item.bank_account_type, 10) === 2)
      );
    });

    return bankAccounts;
  }

  render() {
    let bankAccounts = this.bankAccounts();
    let statuses = this.statuses();
    let customerAndSupplier = this.state.supplierAndCustomer;
    let btn = this.getBtnText();
    let title = this.getTitle();
    const chequeProps = {
      onChange: this.setFieldChange.bind(this, "cheque"),
      onMount: this.setFieldMount.bind(this, "cheque"),
      formData: this.state.formData.cheque,
      errors: this.state.errors.cheque,
      buttons: this.state.radioButtons
    };
    return (
      <div>
        <EkoModal
            showModal={this.state.showModal}
            title={title}
            onHide={() => {
              this.setState({
                showModal: false,
                formData: this.defaultFormData(),
                errors: {}
              });
              this.onCancel();
            }}
            spinner={this.state.saving}
        >
          <div style={{"display":this.state.formData.cheque?.id?"none":""}}><Modal.Header><EkoFormRadioButton {...chequeProps} id="type" /></Modal.Header></div>
          <EkoForm formId={"add_cheque_modal"}>
            <div className="row">
              {this.props.isOut !== 2 ? (
                  <div className="col-md-6">
                    <EkoFormSelect
                        {...chequeProps}
                        disabled={
                            this.state.formData.cheque?.is_collection === 1 ||
                            this.props.isGiro ||
                            this.props.isCollection === 1 ||
                            (this.state.formData.cheque?.id &&
                                this.state.formData.cheque?.from_id &&
                                this.state.formData.cheque?.to_id) ||
                            this.state.formData.cheque?.id
                        }
                        id="from"
                        searchable={true}
                        optionId={"optionId"}
                        isVertical={true}
                        defaultText="Seçiniz"
                        options={customerAndSupplier}
                        label="Kimden"
                    />
                  </div>
              ) : (
                  ""
              )}
              {this.props.isOut !== 1 ? (
                  <div className="col-md-6">
                    <EkoFormSelect
                        {...chequeProps}
                        disabled={
                            this.state.formData.cheque?.is_collection === 1 ||
                            this.props.isCollection === 1 ||
                            (this.state.formData.cheque?.id &&
                                this.state.formData.cheque?.from_id &&
                                this.state.formData.cheque?.to_id &&
                                !this.props.isGiro) ||
                            (!this.props.isGiro && this.state.formData.cheque?.id)
                        }
                        id="to"
                        searchable={true}
                        optionId={"optionId"}
                        isVertical={true}
                        defaultText="Seçiniz"
                        options={customerAndSupplier}
                        label="Kime"
                    />
                  </div>
              ) : (
                  ""
              )}
            </div>
            <div className={"addOptions"}>
              <div className="row">
                <div className="col-md-3">
                  <EkoFormDate
                      {...chequeProps}
                      // disabled={this.props.isGiro}
                      id="transaction_date"
                      dateFormat="DD-MM-YYYY"
                      isVertical={true}
                      clearable={false}
                      label="Tarih"
                  />
                </div>
                <div className="col-md-3">
                  <EkoFormDate
                      {...chequeProps}
                      disabled={this.props.isGiro}
                      id="due_date"
                      dateFormat="DD-MM-YYYY"
                      isVertical={true}
                      clearable={false}
                      label="Vade Tarihi"
                  />
                </div>
                <div className="col-md-3">
                  <EkoFormSelect
                      {...chequeProps}
                      disabled={
                          this.state.formData.cheque?.is_collection === 1 ||
                          this.props.isGiro ||
                          this.props.isCollection === 1 ||
                          (this.state.formData.cheque?.id &&
                              this.state.formData.cheque?.from_id &&
                              this.state.formData.cheque?.to_id)
                      }
                      id="currency"
                      isVertical={true}
                      optionValue={"code"}
                      optionId={"code"}
                      defaultText="Seçiniz"
                      options={this.props.currencies}
                      label="Döviz Tipi"
                  />
                </div>
                <div className="col-md-3">
                  <EkoFormInputText
                      {...chequeProps}
                      disabled={
                          this.props.isGiro ||
                          this.state.formData.cheque?.currency === "TRY" ||
                          this.state.formData.cheque?.is_collection === 1 ||
                          (this.state.formData.cheque?.id &&
                              this.state.formData.cheque?.from_id &&
                              this.state.formData.cheque?.to_id) ||
                          this.props.isCollection === 1
                      }
                      label="Kur"
                      id="currency_rate"
                      isVertical={true}
                  />
                </div>
              </div>
              <div className="row">
                <div style={{ marginTop: "-10px" }} className="col-md-3">
                  <EkoFormInputText
                      {...chequeProps}
                      disabled={
                          (this.state.formData.cheque?.id) || this.state.formData.cheque?.is_collection === 1 ||
                          this.props.isGiro ||
                          this.props.isCollection === 1 ||
                          (this.state.formData.cheque?.id &&
                              this.state.formData.cheque?.from_id &&
                              this.state.formData.cheque?.to_id)
                      }
                      label="Tutar"
                      id="amount"
                      isVertical={true}
                      type="money"
                  />
                </div>
                <div style={{ marginTop: "-10px" }} className="col-md-3">
                  <EkoFormSelect
                      {...chequeProps}
                      id="status_cid"
                      disabled={
                          this.state.formData.cheque?.is_collection === 1 ||
                          this.props.isGiro ||
                          this.props.isCollection === 1 ||
                          (this.state.formData.cheque?.id &&
                              this.state.formData.cheque?.from_id &&
                              this.state.formData.cheque?.to_id)
                      }
                      isVertical={true}
                      defaultText="Seçiniz"
                      options={statuses}
                      label="Statüsü"
                  />
                </div>
                <div style={{ marginTop: "-10px" }} className="col-md-6">
                  <EkoFormInputText
                      {...chequeProps}
                      label="Açıklama"
                      disabled={
                          this.state.formData.cheque?.is_collection === 1 ||
                          this.props.isCollection === 1 ||
                          (this.state.formData.cheque?.id &&
                              this.state.formData.cheque?.from_id &&
                              this.state.formData.cheque?.to_id &&
                              !this.props.isGiro)
                      }
                      id="description"
                      isVertical={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <EkoFormInputText
                    {...chequeProps}
                    disabled={
                        this.state.formData.cheque?.is_collection === 1 ||
                        this.props.isGiro ||
                        this.props.isCollection === 1 ||
                        (this.state.formData.cheque?.id &&
                            this.state.formData.cheque?.from_id &&
                            this.state.formData.cheque?.to_id)
                    }
                    label="Evrak Numarası"
                    id="legal_no"
                    maskChar=""
                    isVertical={true}
                />
              </div>
              <div className="col-md-3">
                <EkoFormInputText
                    {...chequeProps}
                    disabled={
                        this.state.formData.cheque?.is_collection === 1 ||
                        this.props.isGiro ||
                        (this.state.formData.cheque?.id &&
                            this.state.formData.cheque?.from_id &&
                            this.state.formData.cheque?.to_id)
                    }
                    label="Komisyon Tutarı"
                    id="commission"
                    type="money"
                    isVertical={true}
                />
              </div>
              <div className="col-md-6">
                <EkoFormSelect
                    {...chequeProps}
                    disabled={
                        this.state.formData.cheque?.is_collection === 1 ||
                        this.props.isGiro ||
                        (this.state.formData.cheque?.id &&
                            this.state.formData.cheque?.from_id &&
                            this.state.formData.cheque?.to_id)
                    }
                    id="bank_account_id"
                    isVertical={true}
                    defaultText="Seçiniz"
                    options={bankAccounts}
                    label="Banka Seçimi"
                />
              </div>
            </div>
            {this.state.formData.cheque?.type === 1 ? (
                <div className="row">
                  <div className="col-md-3" style={{ marginTop: "-10px" }}>
                    <EkoFormInputText
                        {...chequeProps}
                        label="Adet"
                        type="mask"
                        mask="99999999999"
                        maskChar=""
                        defaultText="1"
                        id="number_of_bill"
                        isVertical={true}
                    />
                  </div>
                </div>
            ) : (
                ""
            )}
            <Modal.Footer>
              <div className="row buttonProccess2">
                <EkoFormButton
                    label={btn}
                    isLoading={this.state.saving}
                    onClick={this.onSave.bind(this)}
                />
                <EkoFormButton
                    label="İptal"
                    className="btn"
                    onClick={this.onCancel.bind(this)}
                />
              </div>
              <hr/>
              <div style={{"textAlign":"left"}}>
                <a onClick={this.openNewTagModal}>Etiket Ekle</a>
              </div>
            </Modal.Footer>
          </EkoForm>
        </EkoModal>
        <NewTagDocumentModal
            newTagRows={this.state.newTagRows}
            saveNewTag={this.saveNewTag}
            showNewTagModal={this.state.showNewTagModal}
            closeNewTagModal={this.closeNewTagModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.data.customersAll,
    suppliers: state.data.suppliersAll,
    bankAccounts: state.data.bankAccountsAll,
    currencies: state.data.currencies,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(AddCheque);
