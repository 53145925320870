export const values = {
    company_name: "Müşteri Ünvanı LTD. ŞTİ.",
    address: "Müşteri Adresi bir mah. no:1 daire:1 ilçe,il",
    email: "ornek@ekohesap.com",
    phone: "05555555555",
    shipping_hour: "13:30",
    tax_office_name: "Vergi Dairesi",
    tax_identity_number: "1234567890",
    currency_rate: "5,80",
    irsaliye_no: "12345",
    irsaliye_date: "01-01-2017",
    name_and_identity: "Vergi Dairesi 1234567890",
    legal: "S123456",
    shipping_date: "01-01-2017",
    date: "01-01-2017",
    due_date: "01-01-2017",
    price_amount: "100,000",
    discount_amount: "10,000",
    net_amount: "10,000",
    total_amount: "100,000",
    vat_reduction_amount: "1,000",
    receivable_amount: "1,000",
    balance: "1,000",
    stoppage_amount: "1,000",
    kdv_detail: "%18  35 <br/>%8  35 <br/>",
    write_total: "Yüz Bin",
    vat_amount: "1,000",// freeText
    specialNote: "Ozel Notlarınızı bu alana yazdırabilirsiniz.",
    hizmet1: [
        {
            item_name: "Hizmet veya ürün tanimi",
            description: "Hizmet veya ürün açıklaması",
            item_code: "#",
            quantity: "100",
            unit: "Birim",
            net_amount: "4,000",
            unit_price: "4,000",
            discount_amount: "2,000",
            vat_amount: "200",
            vat_amount_percent: "%18",
            total_amount: "1,500",
        }, {
            item_name: "Hizmet veya ürün tanimi",
            description: "Hizmet veya ürün açıklaması",
            item_code: "#",
            quantity: "100",
            unit: "Birim",
            net_amount: "4,000",
            unit_price: "4,000",
            discount_amount: "2,000",
            vat_amount: "200",
            vat_amount_percent: "%18",
            total_amount: "1,500",
        }, {
            item_name: "Hizmet veya ürün tanimi",
            description: "Hizmet veya ürün açıklaması",
            item_code: "#",
            quantity: "100",
            unit: "Birim",
            net_amount: "4,000",
            unit_price: "4,000",
            discount_amount: "2,000",
            vat_amount_percent: "%18",
            vat_amount: "200",
            total_amount: "1,500",
        }, {
            item_name: "Hizmet veya ürün tanimi",
            description: "Hizmet veya ürün açıklaması",
            item_code: "#",
            quantity: "100",
            unit: "Birim",
            net_amount: "4,000",
            unit_price: "4,000",
            discount_amount: "2,000",
            vat_amount: "200",
            vat_amount_percent: "%18",
            total_amount: "1,500",
        },
    ],
};

export default values;