import React, { useEffect, useState } from "react";
import { EkoFormButton } from "../elements/EkoForm";
import Resizer from "react-image-file-resizer";
import EkoImageUpload from "../elements/form/EkoImageUpload";
import { connect, useDispatch, useSelector } from "react-redux";
import { setAddDocument, setUploadedDocument } from "../../actions";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";

function AddDocumentButton(props) {
  const { addDocument, style, setAddDocument, setUploadedDocument } = props;
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setAddDocument({ uri: null, type: null });
    }
  },[setAddDocument])

  const createbloburl = (file, type) => {
    var blob = new Blob([file], {
      type: type || "application/*",
    });

    file = window.URL.createObjectURL(blob);

    return file;
  };

  const fileUploadHandler = (image) => {
    if (image) {
      setLoading(true);
      // const fileURL = createbloburl(image, image.type);
      // console.log("type", image.type);
      // setAddDocument({ uri: fileURL, type: image.type });
      // setLoading(false);

      const fd = new FormData();
      fd.append("image", image);
      fd.append("isDocument", true);
      if (image.size < 3000000) {
        Api.post("/eInformation/upload", fd, (response, error) => {
          if (response) {
            setLoading(false);
            if (response.status === "fail") {
              toastr.error(response.message);
            } else {
              const docUrl = response.imageUrl;
              const type = docUrl.split('.').pop();
              setAddDocument({ uri: docUrl, type });
              setTimeout(() => {
                setUploadedDocument(true);
              }, 300);
              setTimeout(() => {
                setUploadedDocument(false);
              }, 600);
              toastr.success("Yükleme Başarılı");
            }
          } else {
            if (error.status === "fail") {
              toastr.error(error.message);
            }
            setLoading(false);
          }
        });
      } else {
        toastr.error("Resim boyutu fazla büyük");
      }
      
    } else {
      setLoading(false);
      setAddDocument({ uri: null, type: null });
    }
  };
  return (
    <>
      <EkoImageUpload
        closeSmallImage
        addAccepts={["application/pdf"]}
        selectedImage={addDocument.uri}
        style={style}
        loading={loading}
        onChange={(image) => fileUploadHandler(image)}
        maxSize={3000000}
        buttonText="PDF/İmaj Yükle"
        width={180}
        height={180}
      />
    </>
  );
}

function mapStateToProps(state) {
  return { 
    addDocument: state.page.addDocument
   };
}

export default connect(mapStateToProps, {setAddDocument, setUploadedDocument})(AddDocumentButton);
