import React, {Component} from 'react';
import {EkoForm, EkoFormButton, EkoFormDate, EkoFormInputText} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as actions from "../../actions";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import EkoModal from "../../components/elements/EkoModal";


class NewTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            name: "",
            openModal: false,
            modalName: "",
            modalId: "",
        };
    }

    list() {
        Api.get("/company/newTagList", (data, errors) => {
            if (errors) {
                this.setState({dataLoading: false});
                return;
            }
            if (data) {
                this.setState({list: data.list});
            }
        });
    }

    set(){
        axios.get(ROOT_URL + "/company/newTagList", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(response => {
            var data = JSON.stringify(response.data.list, null, 2);
            localStorage.setItem("new_tag_list", '');
            localStorage.setItem("new_tag_list", data);
        });
    }

    componentWillMount() {
        this.list();
    }

    add() {
        if (this.state.name !== '') {
            var params = {
                name: this.state.name,
            };
            Api.post("/company/newTagAdd", params, (response, err) => {
                if (err !== false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                } else {
                    this.setState({name:""});
                    this.list();
                    this.render();
                    this.set();
                    toastr.success("Başarılı", "Etiket Başarıyla Eklendi.");
                }
            });
        }else {
            toastr.error("Hata", "Etiket Adı Boş Geçilemez");
        }
    }

    onDelete(id) {
        var params = {
            id: id
        };
        toastr.confirm(`Etiketi silmek istediğinize eminmisiniz? Fatura vb. ekranlarda seçtiğiniz etiket bağlantıları da silinecektir.`, {
            onOk: () => {
                Api.post("/company/newTagDelete", params, (response, err) => {
                    if (err !== false) {
                        toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                    } else {
                        this.list();
                        this.render();
                        this.set();
                        toastr.success("Başarılı", "Etiket Başarıyla Silindi.");
                    }
                });
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
        });

    }

    openModal = (id) => {
        Api.get("/company/newTagGet/"+id, (response, err) => {
            if (err !== false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                this.setState({ modalId:id,openModal: true,modalName:response.data.name});
            }
        });
    };
    saveModal = () => {
        var params = {
            id: this.state.modalId,
            name: this.state.modalName
        };
        Api.post("/company/newTagUpdate", params, (response, err) => {
            if (err !== false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                this.list();
                this.render();
                this.set();
                toastr.success("Başarılı", "Etiket Başarıyla Güncellendi.");
            }
        })
        this.closeModal();
    };

    closeModal() {
        this.setState({ openModal: false });
    }

    onChange(type, field, value) {
        this.setState({ modalName:field});
    }
    render() {
        return (<>
            <EkoForm>
                <div className="row w-100">
                    <div className="col-md-12">
                        <h2 className="h2 left">Etiket İşlemleri</h2>
                        <div className={"row"}>
                            <div className={"col-md-6"}><input
                                placeholder={"Etiket Adını Giriniz..."}
                                type="text"
                                className="form-control"
                                value={this.state.name}
                                onChange={evt => this.setState({name: evt.target.value})}
                                ref={ref => (this.fileInput = ref)}
                            /></div>
                            <div className={"col-md-2"}><EkoFormButton
                                label={"Ekle"}
                                faClass="fa fa-plus"
                                onClick={() => this.add()}
                            /></div>
                        </div>
                        <hr/>

                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr className="fcolumn">
                                <th className="fcolumn">Etiket Adı</th>
                                <th className="fcolumn">İşlem</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.list.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>
                                            <EkoFormButton
                                                label={"Düzenle"}
                                                faClass="fa fa-edit"
                                                className={"btn btn-blue"}
                                                onClick={() => this.openModal(item.id)}
                                            />
                                            <EkoFormButton
                                                label={"Sil"}
                                                faClass="fa fa-trash"
                                                className={"btn btn-danger"}
                                                onClick={() => this.onDelete(item.id)}
                                            />
                                        </td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <EkoModal
                    showModal={this.state.openModal}
                    onHide={this.closeModal.bind(this)}
                    title={"ETİKET GÜNCELLE"}
                >
                    <div className={"row"}>
                        <label style={{ "line-height": "45px" }}>Etiket Adı</label>
                        <EkoFormInputText
                            onChange={this.onChange.bind(this)}
                            value={this.state.modalName}
                            colMd={12}
                            placeHolder="Etiket Adı Giriniz"
                            type="text"
                            id="modalName"
                        />
                        <div style={{ height: "30px" }}></div>
                        <div style={{ "text-align": "right" }}>
                            <EkoFormButton
                                label={"Kaydet"}
                                faClass="fa fa-plus"
                                buttonsType={"default"}
                                className="btn blue"
                                onClick={this.saveModal}
                            />
                        </div>
                    </div>
                </EkoModal>
            </EkoForm>

        </>);
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(NewTag);