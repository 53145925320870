import React from "react";
import { Link } from "react-router";
import { FETCH_VIDEO_CONFIGS } from "../../actions/types";
import * as actions from "../../actions";
import { connect } from "react-redux";
import PermittedHeader from "../../services/PermittedHeader";
import Util from "../../util/Util";
import "./stil.css";
import EkoModal from "../elements/EkoModal";
import Api from "../../util/Api";
class HeaderMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      menu1Css: "none",
      menu2Css: "none",
      menu3Css: "none",
      collections: [],
      isOpen: false,
      generalIframeModal: false,
      modal: false,
      modalButtonVisible: false,
    };

    this.toggleDiv = this.toggleDiv.bind(this);
  }
  componentWillMount() {
    this.getDealer();
  }
  modal(value) {
    this.setState({ modal: value });
  }
  getDealer = () => {
    Api.get("/company/getDealer", (response, err) => {
      if (response && response.final === "ok") {
        this.setState({
          modalButtonVisible: true
        });
      }
    });
  }
  openInsert(menuItem) {
    localStorage.removeItem("new_customer_id_take_collection");
    localStorage.setItem("new_collection_from_who_type", menuItem);
    localStorage.removeItem("new_collection_amount_fc");
    localStorage.removeItem("selected_list_sales_invoice_id");
    localStorage.removeItem("selected_list_purchases_invoice_id");
    localStorage.removeItem("selected_list_receivable_amount_fc");
    localStorage.removeItem("selected_list_payable_amount_fc");
    //localStorage.removeItem("new_collection_from_who_type", 1);
  }

  openInsertPayment(menuItem) {
    localStorage.removeItem("new_customer_id_take_collection");
    localStorage.removeItem("new_collection_amount_fc");
    localStorage.removeItem("selected_list_sales_invoice_id");
    localStorage.removeItem("selected_list_purchases_invoice_id");
    localStorage.removeItem("selected_list_receivable_amount_fc");
    localStorage.removeItem("selected_list_payable_amount_fc");
    localStorage.removeItem("new_collection_from_who_type", 1);
    localStorage.setItem("new_payment_from_who_type", menuItem);
  }

  isPermitted(pageId, html) {
    return PermittedHeader.isPermitted(pageId, html);
  }

  showHelpIcon(key, html) {
    if (this.props.videos && this.props.videos.hasOwnProperty(key)) return html;
    else return null;
  }

  changeVideoConfig(value) {
    let config = this.props.config;
    config[this.props.path] = !value;
    localStorage.setItem("videos_config", JSON.stringify(config));
    this.props.updateFetchedData(
        FETCH_VIDEO_CONFIGS,
        JSON.parse(localStorage.getItem("videos_config"))
    );
  }
  openGeneralIframeModal = () => {
    this.setState({ general_iframe_modal: true });
  };
  getGroups(name) {
    if (name === "Dashboard") {
      return ["/dashboard"];
    }

    if (name === "İşlemler") {
      return [
        "/dashboard/purchases",
        "/dashboard/salesInvoice",
        "/dashboard/collections",
        "/dashboard/payments",
        "/dashboard/soleprofSalesInvoice",
        "/dashboard/transfers"
      ];
    }

    if (name === "Hesaplar") {
      return [
        "/dashboard/customers",
        "/dashboard/suppliers",
        "/dashboard/banks",
        "/dashboard/cheques",
        "/dashboard/employee",
        "/dashboard/assets",
        "/dashboard/product-service"
      ];
    }

    if (name === "Raporlar") {
      return ["/dashboard/reports", "/reports/company"];
    }
  }

  getClassName = group => {
    let classNames = ["menu-dropdown", "classic-menu-dropdown"];

    if (group === "Dashboard") {
      classNames = [];
    }

    return classNames.join(" ");
  };

  getStyle(group) {
    const path = this.props.path;
    let urlGroup = this.getGroups(group).filter(url => path.includes(url));

    if (urlGroup.length > 0) {
      return {
        backgroundColor: "#2e76b3"
      };
    }

    return {};
  }

  toggleDiv = () => {
    const { addClass } = this.state;
    this.setState({
      addClass: !addClass
    });
  };

  showHideMenu = (id) => {
    this.setState({
      menu1Css: "none",
      menu2Css: "none",
      menu3Css: "none",
    });
    if(id=='1'){
      if(this.state.menu1Css=='block'){this.setState({menu1Css: "none"});}
      else if(this.state.menu1Css=='none'){this.setState({menu1Css: "block"});}
    }
    else if(id=='2'){
      if(this.state.menu2Css=='block'){this.setState({menu2Css: "none"});}
      else if(this.state.menu2Css=='none'){this.setState({menu2Css: "block"});}
    }
    else if(id=='3'){
      if(this.state.menu3Css=='block'){this.setState({menu3Css: "none"});}
      else if(this.state.menu3Css=='none'){this.setState({menu3Css: "block"});}
    }
  };

  closeMenu = () => {
    this.setState({
      menu1Css: "none",
      menu2Css: "none",
      menu3Css: "none",
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }
  render() {
      const { isOpen } = this.state;
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
      let isVideoShow =
        this.props.config &&
        typeof this.props.config[this.props.path] !== "undefined"
            ? this.props.config[this.props.path]
            : true;
    const user = JSON.parse(localStorage.getItem('user'));
    let boxClass = ["dropdown-menu pull-left fastStartMenu"];

    if (this.state.addClass) {
      boxClass.push("active");
    }
    document.addEventListener("mousedown", (e) => {
      const path = e.composedPath && e.composedPath();
      if (path && (path.find((el) => el.className === "nav navbar-nav") === undefined)) {
        setTimeout(() => {
          this.setState({
            menu1Css: "none",
            menu2Css: "none",
            menu3Css: "none",
          });
        }, 500);
      }
      if (path && path[0].className.includes("bottom_click")==false) {
        setTimeout(() => {
          this.setState({
            isOpen: false,
          });
        }, 500);
      }
    });
    const isSuper = this.props.companies.filter(c => c.company_type === 9).length > 0;
    const time = new Date();
    return (
        <div className="page-header-menu" style={{ display: this.props.visibleMobileMenu === "opened" ? "block" : (this.props.visibleMobileMenu === null ? "" : "none")}}>
          <div className="container">
            <div className="hor-menu hor-menu-light">
              <ul className="nav navbar-nav">
                <li
                    className={this.getClassName("Dashboard")}
                    style={
                      this.props.path === "/dashboard"
                          ? {
                            backgroundColor: "#2e76b3"
                          }
                          : null
                    }
                    onClick={() => window.location.reload(true)}
                >
                  <Link to="/dashboard">
                    <i className="icon-home" />
                  </Link>
                </li>

                <li
                    aria-haspopup="true"
                    style={this.getStyle("İşlemler")}
                    className={this.getClassName("İşlemler")}
                >
                  <Link onClick={() => this.showHideMenu("1")}>İşlemler</Link>
                  <ul className="dropdown-menu pull-left" style={{display:this.state.menu1Css}}>
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/purchases"
                      >
                        Alışlar
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to={ user.company_type == 9 || user.company_type == 6 ?  "/dashboard/salesInvoice/soleprofv2s"  : "/dashboard/salesInvoice" }
                      >
                        {" "}
                        Satışlar{" "}
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/collections"
                      >
                        Tahsilat
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/payments"
                      >
                        Ödeme
                      </Link>
                    </li>
                    {/* <li aria-haspopup="true" className=" ">
                                        <Link activeClassName={'header-active'} to="/dashboard/soleprofSalesInvoice">SM
                                            Makbuz</Link>
                                    </li> */}
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/transfers"
                      >
                        Virman
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                    aria-haspopup="true"
                    style={this.getStyle("Hesaplar")}
                    className={this.getClassName("Hesaplar")}
                >
                  <Link onClick={() => this.showHideMenu("2")}>Hesaplar</Link>
                  <ul className="dropdown-menu pull-left" style={{display:this.state.menu2Css}}>
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/customers"
                      >
                        Müşteri
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/suppliers"
                      >
                        Tedarikçi
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/banks"
                      >
                        Kasa/Banka
                      </Link>
                    </li>
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/loans"
                      >
                        Kredi
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/cheques"
                      >
                        {" "}
                        Çek/Senet{" "}
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/employee"
                      >
                        Çalışan
                      </Link>
                    </li>

                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/product-service">
                        Ürün/Hizmet
                      </Link>
                    </li>
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/assets">
                        Demirbaş
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                    aria-haspopup="true"
                    style={this.getStyle("Raporlar")}
                    className={this.getClassName("Raporlar")}
                >
                  <Link onClick={() => this.showHideMenu("3")} className={"nav-link"}>
                    Raporlar{" "}
                  </Link>
                  <ul className="dropdown-menu pull-left" style={{display:this.state.menu3Css}}>
                    <li aria-haspopup="true" className=" ">
                      <Link
                          onClick={this.closeMenu}
                          activeClassName={"header-active"}
                          to="/dashboard/reports"
                          className="nav-link  "
                      >
                        İşletme Raporları
                      </Link>
                    </li>
                    {
                      isSuper || Util.companyModules(4)===true?
                          <li aria-haspopup="true" className=" ">
                            <a onClick={() => {
                              const windowFeatures = `width=${window.screen.width},height=${window.screen.height},toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,left=10,top=10`;
                              window.open(apiUrl + "panel/accounting-plan.php?cid="+JSON.parse(localStorage.getItem("user")).company_id+"&t=" + localStorage.getItem('token'), 'ekoGenelMuhasebe', windowFeatures);
                            }}>Genel Muhasebe</a>
                          </li> : null
                    }
                    {
                      isSuper ?
                        <li aria-haspopup="true" className="menu-dropdown classic-menu-dropdown declarations-right-menu">
                        <Link
                            onClick={this.closeMenu}
                            activeClassName={"header-active"}
                            to="/dashboard/declarations"
                            className="nav-link  "
                        >
                          Vergi Beyanları
                        </Link>
                        <ul className="dropdown-menu pull-left declarations-hiddenx">
                          <li aria-haspopup="true" className=" ">
                            <Link
                                onClick={this.closeMenu}
                                activeClassName={"header-active"}
                                to="/dashboard/declarations"
                                className="nav-link  "
                            >
                              Yeni Beyan
                            </Link>
                          </li>
                          <li aria-haspopup="true" className=" ">
                            <Link
                                onClick={this.closeMenu}
                                activeClassName={"header-active"}
                                to="/dashboard/declarations-list"
                                className="nav-link  "
                            >
                              Beyanlar Listesi
                            </Link>
                          </li>
                        </ul>
                      </li> : null
                    }
                    {
                      isSuper ?
                        <li aria-haspopup="true" className="menu-dropdown classic-menu-dropdown dbs-right-menu">
                        <Link
                            onClick={this.closeMenu}
                            activeClassName={"header-active"}
                            to="/DBS/sales"
                            className="nav-link  "
                        >
                          DBS
                        </Link>
                        <ul className="dropdown-menu pull-left hiddenx">
                          <li aria-haspopup="true" className=" ">
                            <Link
                                onClick={this.closeMenu}
                                activeClassName={"header-active"}
                                to="/DBS/purchases"
                                className="nav-link  "
                            >
                              Alışlar
                            </Link>
                          </li>
                          <li aria-haspopup="true" className=" ">
                            <Link
                                onClick={this.closeMenu}
                                activeClassName={"header-active"}
                                to="/DBS/sales"
                                className="nav-link  "
                            >
                              Satışlar
                            </Link>
                          </li>
                        </ul>
                      </li> : null
                    }
                  </ul>
                </li>

              </ul>

              <ul className="nav navbar-nav navbar-right ">
                <li
                    aria-haspopup="true"
                    style={{
                      position: "absolute",
                      right: "11%",
                      top: "0",
                      listStyleType: "none",
                      display: user.role_id == 1 ? "" : "none"
                    }}
                >
                  <Link style={{"padding": "0px"}} to="/dashboard/IVDScreen">
                    <button
                        className="btn blue btn-sm btn btn-default mr-3 pull-right fastOpenButton">
                      <span style={{"color": "white"}}>İVD</span>
                    </button>
                  </Link>
                </li>
                {this.state.modalButtonVisible?<li
                    aria-haspopup="true"
                    style={{
                      position: "absolute",
                      right: "16%",
                      top: "0",
                      listStyleType: "none",
                      display: user.role_id == 1 ? "" : "none"
                    }}
                >
                  <button
                      onClick={this.modal.bind(this, true)}
                      className="btn blue btn-sm btn btn-default mr-3 pull-right fastOpenButton">
                    <span style={{"color": "white"}}>Bayi Paneli</span>
                  </button>
                </li>:""}
                <li
                    aria-haspopup="true"
                    style={{
                      position: "absolute",
                      right: "6%",
                      top: "0",
                      listStyleType: "none"
                    }}
                >
                  <button
                      className="btn blue btn-sm btn btn-default mr-3 pull-right fastOpenButton menu-dropdown"
                      onMouseOver={this.toggleDiv}
                      onMouseOut={this.toggleDiv}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  <ul
                      className={boxClass.join(" ")}
                      onMouseOver={this.toggleDiv}
                      onMouseOut={this.toggleDiv}
                  >
                    <div className="row">
                      <div className="fastAdd">Hızlı Ekle</div>

                      <div className="col-md-3">
                        <div className="text-center">
                          <strong>Satışlar</strong>
                        </div>
                        <hr/>
                        <div style={{color: "#fff"}}>
                          <Link
                              to={user.company_type == 9 || user.company_type == 6 ? "/dashboard/salesInvoice/soleprofv2/add" : "/dashboard/salesInvoice/add/toCustomer"}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            {user.company_type == 9 || user.company_type == 6 ? "Serbest Meslek Makbuzu" : "Satış Faturası"}
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/salesInvoice/add/toSupplier"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Tedarikçiye Fatura
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/salesInvoice/zreports/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Z Raporu
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/salesInvoice/proforma/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Teklif
                          </Link>

                          <br/>
                          <br/>

                          {Util.companyModules(2) === true && (<Link
                              to="/dashboard/salesInvoice/salesShipment/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Sevk İrsaliyesi
                          </Link>)}

                          <br/>
                          <br/>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="text-center">
                          <strong>Alışlar</strong>
                        </div>
                        <hr/>
                        <div style={{color: "#fff"}}>
                          <Link
                              to="/dashboard/purchases/purchaseInvoice/add/productServiceInvoice"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Mal/Hizmet Faturası
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/purchases/purchaseInvoice/add/expenseInvoice"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Gider Faturası
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/purchases/purchaseInvoice/add/customerReturnInvoice"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Müşteriden Fatura
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/purchases/receipt/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Fiş
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/purchases/otherReceipt/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Diğer Giderler
                          </Link>
                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/purchases/insurancePolicy/add"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Sigorta Poliçesi
                          </Link>
                          <br/>
                          <br/>
                          {Util.companyModules(2) === true && (<Link
                              to="/dashboard/purchases/purchaseReceipt"
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Alış İrsaliyesi
                          </Link>)}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="text-center">
                          <strong>Tahsilat</strong>
                        </div>
                        <hr/>
                        <div style={{color: "red"}}>
                          <Link
                              to="/dashboard/collections/add"
                              onClick={this.openInsert.bind(this, 1)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Müşteriden Yapılmış Tahsilat
                          </Link>

                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/collections/add"
                              onClick={this.openInsert.bind(this, 3)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Müşteriden Sipariş Avansı
                          </Link>
                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/collections/add"
                              onClick={this.openInsert.bind(this, 2)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Personelden İş Avansı İadesi
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/collections/add"
                              onClick={this.openInsert.bind(this, 4)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Tedarikçiden Avans İadesi
                          </Link>

                          <br/>
                          <br/>
                          <Link
                              to="/dashboard/collections/add"
                              onClick={this.openInsert.bind(this, 5)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Tedarikçiden Tahsilat
                          </Link>
                          <br/>
                          <br/>

                          {/* <EkoDataTable
                                                            data={this.props.payments}
                                                        >

                                                        </EkoDataTable>*/}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="text-center">
                          <strong>Ödeme</strong>
                        </div>
                        <hr/>
                        <div style={{color: "red"}}>
                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 1)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Tedarikçiye Yapılmış Ödeme
                          </Link>
                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 2)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Tedarikçiye Sipariş Avansı
                          </Link>
                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 3)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Personele İş Avansı
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 5)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Maaş Ödemesi
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 6)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Müşteri Avansının İadesi
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 7)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Maaş Avansı
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 8)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Müşteriye Ödeme
                          </Link>

                          <br/>
                          <br/>

                          <Link
                              to="/dashboard/payments/add"
                              onClick={this.openInsertPayment.bind(this, 9)}
                              style={{color: "#fff", padding: "10px"}}
                          >
                            Personele Masraf Ödemesi
                          </Link>

                          <br/>
                          <br/>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
              </ul>

              {this.props.visibleMenu && this.props.path
                  ? ""
                  : this.showHelpIcon(
                      this.props.path,
                      <ul
                          className="nav navbar-nav navbar-right"
                          style={{marginRight: 0}}
                      >
                        <li className="menu-dropdown classic-menu-dropdown">
                          <Link>
                            <i className="icon-question large"/>
                          </Link>
                          <ul className="dropdown-menu pull-left">
                            <li aria-haspopup="true">
                            <Link
                                  onClick={this.changeVideoConfig.bind(
                                      this,
                                      isVideoShow
                                  )}
                                  className="nav-link"
                              >
                                Başlangıç Videosunu{" "}
                                {isVideoShow ? "Gizle" : "Göster"}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                  )}
            </div>
          </div>
          <section>
            <container>
              <div className='nav'>
                <div className='button bottom_click' id='button' onClick={this.toggle}>
                  <i style={{"fontSize": "20px"}} className={"fa fa-plus bottom_click"}></i>
                </div>
                <div id='wrapperx' className={isOpen ? "wrapperx open" : "wrapperx"}>
                  <ul>
                    <li>
                      <Link to="/dashboard/salesInvoice/add/toCustomer"><i style={{"fontSize": "20px"}}
                                                                           className={"fa fa-plus-circle"}
                                                                           title={"Satış Faturası Ekle"}></i></Link>
                    </li>
                    <li>
                      <Link to="/dashboard/purchases/purchaseInvoice/add/expenseInvoice"><i
                          style={{"fontSize": "20px", "paddingLeft": "6px"}} className={"fa fa-minus-circle"}
                          title={"Gider Faturası Ekle"}></i></Link>
                    </li>
                    <li>
                      <Link to="/dashboard/purchases/receipt/add"><i style={{"fontSize": "20px", "paddingLeft": "7px"}}
                                                                     className={"fa fa-receipt"} title={"Fiş Ekle"}></i></Link>
                    </li>
                    <li>
                      <Link to="/dashboard/collections/add" onClick={this.openInsert.bind(this, 1)}><i
                          style={{"fontSize": "20px", "paddingLeft": "3px"}} className={"fa fa-money-check"}
                          title={"Tahsilat Ekle"}></i></Link>
                    </li>
                    <li>
                      <Link to="/dashboard/payments/add" onClick={this.openInsertPayment.bind(this, 1)}><i
                          style={{"fontSize": "20px", "paddingLeft": "6px"}} className={"fa fa-list"}
                          title={"Ödeme Ekle"}></i></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </container>
          </section>
          <EkoModal
              showModal={this.state.modal}
              title="Bayi Paneli"
              bsSize="xlg" // xlg
              onHide={() => {
                this.setState({
                  modal: false
                });
                //this.onCancel();
              }}
              spinner={this.state.saving}
          >
            <iframe
                style={{overflow: 'visible'}}
                ref="iframe"
                src={apiUrl + "bayi/index.php?time=" + time.getTime() + "&t=" + localStorage.getItem('token')}
                scrolling="auto"
                frameBorder="0"
                width="100%"
                height={800}
            />
          </EkoModal>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.data.videoConfigs,
    videos: state.data.videos,
    companies: state.data.companies || []
  };
}

export default connect(mapStateToProps, actions)(HeaderMenu);
