import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import moment from "moment";
class ModalTrailer extends Component {
    get row() {
        return [
            {
                label: "",
                placeholder: "Plaka",
                type: "text",
                colMd: 12,
                id: "TransportEquipment"
            },
        ];
    }

    get defaultData() {
        return {
            rows: [this.row],
            errors: {
                rows: []
            },
            saving: false
        };
    }

    constructor(props) {
        super(props);

        this.state = this.defaultData;
    }

    checkValid = data => {
        return !!(data && data !== "");
    };

    onSave = () => {
        this.props.closeModal();
    };


    closeModal = () => {
        if (this.props.closeModal) {
            this.props.closeModal();
        }
    };

    onChange = data => {
        this.props.saveTrailer(data);
    };

    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showModal}
                title={"Dorse Bilgileri Ekle"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <EkoInputTable
                    onSave={this.onSave}
                    className={"modalTableFix"}
                    onCancel={this.closeModal}
                    onChange={this.onChange}
                    errors={this.state.errors}
                    formData={this.props.trailerRows}
                    data={{
                        headers: [{ label: "Plaka" }],
                        rows: this.props.trailerRows.rows.map(() => this.row)
                    }}
                />
            </EkoModal>
        );
    }
}

export default ModalTrailer;
