import Util from "../util/Util";

class DataHelper {
    static getOutstandingData(outstanding) {
        if (typeof outstanding === "object") {
            outstanding = Object.values(outstanding)
        }

        if (!outstanding) {
            return false
        }


        const filters = outstanding.filter(item => !!item).map((item, index) => {
            item.dueDaysString = item.dueDays < 0 ? `${Math.abs(item.dueDays)} gün gecikti` : `${item.dueDays} gün sonra`
            if (item.tel_number && (!item.tel_number.startsWith('0') || !item.tel_number.startsWith('90'))) {
                item.tel_number = '0' + item.tel_number
            }

            item.amount = Util.convertDecimalToMoney(item.amount) + "₺"

            item.id = index
            return item;
        })


        const sorted =  filters.sort(function (a, b) {

            return a.dueDays == b.dueDays ? 0 : (a.dueDays > b.dueDays ? -1 : 1);
        });

        return [...sorted]

    }

}

export default DataHelper