import React, { Component } from "react";
import Layout from "../../components/layouts/Dashboard";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { Link } from "react-router";

class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorPage: true, // page 0 ise error sayfası 1 ise success
      show: false, // page 0 ise error sayfası 1 ise success
      message: null
    };
  }

  componentWillMount() {
    const self = this;
    let token = this.props.params.token;
    let message = null;
    Api.get("/subscriberPayment/" + token, (response, postErrors) => {
      if (response) {
        let page = true;
        self.props.checkUser();
        if (parseInt(response.subscriberPayment.status, 10) === 1) {
          window.fbq("track", "Purchase", {
            id: response.subscriberPayment.user_id,
            value: response.subscriberPayment.paidPrice,
            currency: "TRY"
          });
          window.gtag("event", "purchase", {
            transaction_id: response.subscriberPayment.user_id,
            value: response.subscriberPayment.paidPrice
          });
          self.props.checkUser();
          page = false;
        } else {
          let err = JSON.parse(response.subscriberPayment.rawResult);
          message = err.errorMessage ? err.errorMessage : null;
        }

        self.setState({ errorPage: page, show: true, message: message });
      } else self.props.router.push("/dashboard");
    });
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    if (!this.state.show) return <EkoSpinner />;
    return (
      <Layout visibleMenu={true}>
        <div className="row flex">
          <div className="col-md-12 subscription-center">
            {this.state.errorPage ? (
              <div className="portlet light">
                <br />
                <br />
                <i
                  className="fa fa-times-circle fa-payment fa-payment-cancel"
                  aria-hidden="true"
                />
                <br />
                <br />
                <h4 className="alert-heading">
                  <b>Ödemeniz gerçekleştirilemedi.</b>
                </h4>
                <div className="clearfix"></div>
                Hata Mesajı :{" "}
                <span style={{ color: "red" }}>
                  {this.state.message ? this.state.message : "Yok"}
                </span>
                <br />
                {localStorage.getItem("pay_plan_id") ? (
                  <span>
                    Tekrar denemek için{" "}
                    <Link
                      to={
                        "/subscriberPayment/" +
                        localStorage.getItem("pay_plan_id")
                      }
                    >
                      tıklayınız
                    </Link>
                  </span>
                ) : (
                  ""
                )}
                <div className="clearfix"></div>
              </div>
            ) : (
              <div className="portlet light">
                <br />
                <br />
                <i
                  className="fa fa-check-circle fa-payment fa-payment-ok"
                  aria-hidden="true"
                />
                <br />
                <br />
                <h4 className="alert-heading">
                  <b>Ödemeniz başarı ile gerçekleşti.</b>
                </h4>
                <br />
                <br />
                Uygulamaya geri dönmek için{" "}
                <Link to="/dashboard">tıklayınız.</Link>
                <div className="clearfix"></div>
                <div className="clearfix"></div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageLoading: state.page.pageLoading,
    pageTitle: state.page.title
  };
}

export default connect(mapStateToProps, actions)(PaymentStatus);
