import React, {PureComponent} from 'react';

type Props = {
    onClick: Function,
    label: string,
    icon?: string,
    buttonProps?: Object
}

class EkoButtonOutline extends PureComponent<Props> {
    props: Props;

    render() {
        return (
            <button className="btn blue tablebuttons btn-sm" onClick={this.props.onClick} {...this.props.buttonProps}>
                {this.props.icon ? <span>
                <i className={"fa " + this.props.icon}/>
              </span> : null} {this.props.label}
            </button>
        );
    }
}

EkoButtonOutline.defaultProps = {
    buttonProps: {}
}

export default EkoButtonOutline;