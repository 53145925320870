import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {EkoForm, EkoFormButton, EkoFormInputText, EkoFormSelect} from "../elements/EkoForm";
import { toastr } from "react-redux-toastr";
import EkoModal from "../elements/EkoModal";
import Api from "../../util/Api";

class zreportIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            saving: false,
            formData: {
                api_username: "",
                api_password: "",
                device_type: "",
                hugin_key: "",
                ingenico_username: "",
                ingenico_password: "",
            },
        };
        //console.log(this.props, "props");
    }

    get defaultFormData() {
        return {
            api_username: "",
            api_password: "",
            device_type: "",
            hugin_key: "",
            ingenico_username: "",
            ingenico_password: "",
        };
    }

    componentWillMount() {}

    /**
     *
     * @param string field
     * @param string value
     */
    fieldOnChange(field, value) {
        let formData = { ...this.state.formData };
        formData[field] = value;
        this.setState({ formData });
    }
    fieldOnMount(field, value) {}
    onSave() {
        this.setState({ saving: true });

        Api.post(this.state.formData.id?"/company/zReportServiceUpdate":"/company/zReportServiceAdd", this.state.formData, (res, err) => {
            if (err) {
                toastr.error("Hata", err.error ?? "Beklenmeyen bir hata oluştu!");
                this.setState({ saving: false });
                return;
            }
            if(res.final==='error'){
                toastr.error("Hata", res.message);
                return;
            }else{
                toastr.success("Başarılı", "Kayıt başarıyla eklendi");
            }
            this.setState({ saving: false });
            this.props.onHide(true);
        });
    }

    /**
     * Cancel button handler
     */
    cancel(){
        this.setState({formData:this.defaultFormData})
        this.props.onHide();
    }

    render() {
        const fieldProps = {
            onChange: this.fieldOnChange.bind(this),
            onMount: this.fieldOnMount.bind(this),
            formData: this.state.formData,
        };
        return (
            <>
                <EkoModal
                    showModal={this.props.showModal}
                    onHide={this.props.onHide}
                    title={this.props.title}
                >
                    <EkoForm>
                        <div className="row">
                            <EkoFormSelect
                                {...fieldProps}
                                label={"Cihaz Tipi"}
                                name={"device_type"}
                                id={"device_type"}
                                options={[
                                    { id: "hugin", name: "Hugin" },
                                    { id: "ingenico", name: "Ingenico" },
                                ]}
                            />
                            {this.state.formData.device_type==='hugin'?<EkoFormInputText
                                {...fieldProps}
                                label={"Hugin Key"}
                                id={"hugin_key"}
                                name={"hugin_key"}
                            />:""}
                            {this.state.formData.device_type==='ingenico'?<EkoFormInputText
                                {...fieldProps}
                                label={"Ingenico Kullanıcı Adı"}
                                id={"ingenico_username"}
                                name={"ingenico_username"}
                            />:""}
                            {this.state.formData.device_type==='ingenico'?<EkoFormInputText
                                {...fieldProps}
                                label={"Ingenico Parola"}
                                id={"ingenico_password"}
                                name={"ingenico_password"}
                            />:""}
                            {this.props.formElements(fieldProps)}
                        </div>
                        <Modal.Footer>
                            <div className="row buttonProccess2">
                                <EkoFormButton
                                    label={this.state.formData.id ? "Güncelle" : "Kaydet & Ekle"}
                                    faClass="fa fa-plus"
                                    isLoading={this.state.saving}
                                    onClick={this.onSave.bind(this)}
                                />
                                <EkoFormButton
                                    label="İptal"
                                    className="btn"
                                    onClick={this.cancel.bind(this)}
                                />
                            </div>
                        </Modal.Footer>
                    </EkoForm>
                </EkoModal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(zreportIntegration);
