export const CSS = `.portlet-dashboard{
  margin-bottom:10px!important;
}
.box:hover .effect1:before{
    background: #337ab7;
}


.box:hover .effect2:before{
    background: #ca4741;
}
.box:hover .effect3:before{
    background: #337ab7;
}
.box:hover .effect4:before{
    background: #ca4741;
}
.i-color1,.effect1 .detay a:hover{
  color:#4a8bc2;
}
.i-color2,.effect2 .detay a:hover{
  color:#ca4741;
}
.i-color3{
  color:#4a8bc2;
}
.i-color4{
  color:#ca4741;
}`;
