import React, {Component} from 'react'
import Util from '../../util/Util';
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoDataTable from '../../components/elements/EkoDataTable'
import {toastr} from 'react-redux-toastr'
import AddProject from './AddProject'

import HelperContainer from '../../util/HelperContainer'


import Api from '../../util/Api'
//import EkoToastrError from '../../components/elements/EkoToastrError'
import {FETCH_PROJECTS} from '../../actions/types'

class Project extends Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedRow: {},
            showUpdateModal: false,
            showInsertModal: false,
            errors: {},
            validationFields: [],
            projects: [] //table Item
        }

    }


    fetchProjects() {
        this.props.fetchData("/project/list", FETCH_PROJECTS);
    }

    componentWillMount() {
        this.fetchProjects();

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.projects) {
            this.setState({projects: nextProps.projects});
        }

    }


    openUpdate(selectedRow) {
        var self = this;
        const id = selectedRow.id;
        Api.paralel(['/project/' + id
            ],
            function (responses) {
                console.log(responses);
                let formData = {}
                formData.project = HelperContainer.filterObject(responses[0].project);
                formData.project.start_date = Util.convertFromDatabaseDate(formData.project.start_date);
                formData.project.end_date = Util.convertFromDatabaseDate(formData.project.end_date);
                formData.project.unallocated_sga_rate = '%' + Util.convertDecimalToMoney((formData.project.unallocated_sga_rate || 0));
                self.setState({
                    showInsertModal: true,
                    errors: {},
                    formData: formData
                });
            })


    }

    onDelete(selectedRow) {
        const self = this;
        Api.delete("/project/" + selectedRow.id, function (response, err) {
            if (response) {
                self.fetchProjects();
                toastr.success('Başarılı', 'Başarıyla Silindi');
            }
            else toastr.error('Hata', err.message);
        })
        /*  let newData = Sugar.Array.filter(this.props.projects,function(object){
              return (object.id!==selectedRow.id);
          });
          this.props.updateFetchedData(FETCH_SUPPLIERS,{projects:newData});*/
    }


    onSave(formData, formFields) {
        console.log("parent project save called");
        this.fetchProjects();
        this.setState({showInsertModal: false, formData: false});
    }

    render() {
        return (
            <div>

                <AddProject
                    showModal={this.state.showInsertModal}
                    formData={this.state.formData}
                    onSave={this.onSave.bind(this)}
                    onCancel={() => {
                        this.setState({showInsertModal: false})
                    }}
                    errors={this.state.errors}
                />

                <EkoDataTable
                    openInsert={() => {
                        this.setState({showInsertModal: true, formData: false})
                    }}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.props.projects}
                    name={"project"}>
                </EkoDataTable>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.data.projects

    };
}

export default connect(mapStateToProps, actions)(Project);
