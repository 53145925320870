import React from "react"

export default class EkoPercentText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static defaultProps = {
        labelClass: "control-label control-line",
        disabled: false,
        thisClass: "form-control",
        placeholder: "",
        mask_money: false
    };

    handleBlur = e => {
        if (e.target.value === '%' || e.target.value === '') {
            e.target.value = '%0,00';
        }
        else if (!e.target.value.includes(",")) {
            e.target.value += ",00";
        }
        this.props.onChange(e.target.value, this.props.pair);
    };

    handleKeyUp = e => {
        var clean = e.target.value.replace(/[^0-9,.]/g, "")
            .replace(/(,.*?),(.*,)?/, "$1");
        if (clean !== e.target.value) e.target.value = clean;
        e.target.value = this.format(e.target.value);
        this.props.onChange(e.target.value, this.props.pair);
    };

    handleFocus = e => {
        if (e.target.value === '%0,00') e.target.value = '%';
        this.props.onChange(e.target.value, this.props.pair);
    };

    format(num) {
        var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
        if (str.indexOf(",") > 0) {
            parts = str.split(",");
            str = parts[0];
        }
        str = str.split("").reverse();
        for (var j = 0, len = str.length; j < len; j++) {
            if (str[j] !== ".") {
                output.push(str[j]);
                if (i % 3 === 0 && j < (len - 1)) {
                    output.push(".");
                }
                i++;
            }
        }
        formatted = output.reverse().join("");
        return ('%' + formatted + ((parts) ? "," + parts[1].substr(0, 4) : ""));
    }

    render() {
        var className = this.props.className;
        var thisClass = this.props.thisClass;
        var val = (this.props.value === null) ? "0,00" : this.props.value;
        var input = <input disabled={this.props.disabled} id={this.props.id} name={this.props.name} value={val}
                           onFocus={this.handleFocus} onChange={this.handleKeyUp}
                           className={thisClass} type="text" placeholder={this.props.place} onBlur={this.handleBlur}/>;
        var input2 = input;
        if (this.props.justInput) return input2
        if (this.props.className2)
            input2 = <div className={this.props.className2}>{input}</div>;
        if (this.props.className2 && this.props.help)
            input2 = <div className={this.props.className2}>{input}
                <span className="help-block"> {this.props.help} </span>
            </div>;
        if (this.props.hasError)
            className = this.props.className + " has-error";
        var label = (this.props.label) ?
            <label className={this.props.labelClass}>{this.props.label}</label> : "";
        label = (this.props.span) ?
            <label className={this.props.labelClass}>
                {this.props.label}
                <span className="required"> {this.props.span} </span></label> : label;
        return (
            <div className={className}>
                {label}
                {input2}
            </div>


        );
    }
}
