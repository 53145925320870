/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormCheckbox,
  EkoFormInputText,
  EkoFormRadioButton,
  EkoFormSelect,
} from "./EkoForm";
import EkoModal from "./EkoModal";
import { Modal } from "react-bootstrap";
import Api from "../../util/Api";
import Util from "../../util/Util";
import UtilHelper from "../../util/UtilHelper";

let codes = [];
function SelectItemModal({
  showModal,
  onClose,
  items,
  isSales,
  onSave,
  onChangeLines,
  passModal,
}) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([{}]);
  const [errors, setErrors] = useState({});
  const itemsJson = JSON.stringify(items);

  const product_accounting_type = [
    { id: 0, name: "Ticari Mal" },
    { id: 1, name: "Mamül" },
    { id: 2, name: "Yarı Mamül" },
    { id: 3, name: "Hammadde" },
  ];
  const isUpdates = [
    { id: 0, name: "Güncellenmesin" },
    { id: 1, name: "Güncellensin" },
  ];

  const radioButtons = [
    {
      label: "Ürün",
      id: "type",
      radio_id: "urun",
      value: 1,
    },
    {
      label: "Hizmet",
      id: "type",
      radio_id: "hizmet",
      value: 2,
    },
  ];

  const setFieldChange = useCallback((index, field, value) => {
    console.log("change ---->>>>>", index, field, value);
    setFormData((d) => {
      const dn = [...d];
      const dni = dn[index];
      if (!dni) {
        return dn;
      }
      if (field === "status") {
        if (value === "is_stock") {
          dni.is_stock = 1;
          dni.is_sales = 1;
          dni.is_purchase = 1;
        } else if (value === "is_sales") {
          dni.is_stock = 0;
          dni.is_sales = 1;
          dni.is_purchase = 0;
        } else {
          dni.is_stock = 0;
          dni.is_purchase = 1;
          dni.is_sales = 0;
        }
      } else if (field === "typeStr") {
        if (value === "urun") {
          dni.type = 1;
        } else {
          dni.type = 2;
        }
      }
      dn[index] = {
        ...(dni || {}),
        [field]: value,
      };

      return dn;
    });
  }, []);

  const fetchCode = useCallback(async () => {
    if (showModal) {
      // const codeData = await Api.getAsync("/item/code");
      // const code = codeData?.data?.code || "";
      setFormData(items.map((t) => ({})));

      items.forEach((item, i) => {
        setFieldChange(i, "status", "is_stock");
        setFieldChange(i, "typeStr", "urun");
        setFieldChange(i, "typeStr", "urun");
        if (item.item_id) {
          setFieldChange(i, "item_id", item.item_id);
          setFieldChange(i, "item_code", item.item_code);
          setFieldChange(i, "item_name", item.item_name);
        } else {
          // const ex = code.split("U");
          // const num = +(ex?.[1] || 0);
          // const c = "U" + (num + i);
          setFieldChange(i, "item_id", null);
          setFieldChange(i, "product_accounting_type", 0);
          setFieldChange(i, "unSelected", !!item.unSelected);
          // setFieldChange(i, "item_code", c);
          setFieldChange(i, "item_name", item.description);
        }
      });
    }
  }, [showModal, setFieldChange]);

  useEffect(() => {
    if (showModal) {
      const fetchData = async () => {
        await fetchCode();
      };
      fetchData();
    }
  }, [fetchCode, showModal]);

  const closeModal = () => {
    onClose();
  };

  const onFalse = () => {};

  const getCode = async () => {
    let codeData = await Api.getAsync("/item/code");
    let code = codeData?.data?.code || "";
    if(codes.includes(code)){
      return await getCode();
    } else {
      codes.push(code);
      return code;
    }
  }
  const onSaveT2 = () => {
    passModal();
    setTimeout(() => {
      onSave();
      setLoading(false);
      closeModal();
    }, 1000);
  }
  const onSaveT = async (x=false) => {
    setLoading(true);
    const d = formData;
    const promises = await d.map(async (item, i) => {
      if (item.item_id || item.unSelected) {
        return {
          ...items[i],
          product_accounting_type:
            items[i].type === 1 ? item.product_accounting_type || 0 : undefined,
        };
      } else {
        const values = { ...items[i], ...item };
        const postP = { ...items[i], ...item };
        postP.net_amount_fc = UtilHelper.money.convertMoneyToDecimal(
          postP.net_amount_fc
        );
        postP.discount_amount_fc = UtilHelper.money.convertMoneyToDecimal(
          postP.discount_amount_fc
        );
        postP.unit_price_fc = UtilHelper.money.convertMoneyToDecimal4(
          postP.unit_price_fc
        );
        postP.quantity = Util.convertMoneyToDecimal(postP.quantity);
        postP.vat_amount_fc = UtilHelper.money.convertMoneyToDecimal(
          postP.vat_amount_fc
        );

        if (isSales) {
          postP.sales_description = postP.description;
        } else {
          postP.purchase_description = postP.description;
        }
        if (item.is_purchase === 1) {
          postP.purchase_unit_price = !isSales ? postP.unit_price_fc : 0;
          postP.purchase_vat_rate_type_id = !isSales
            ? postP.vat_rate_type_id
            : 6;
        }
        if (item.is_sales === 1) {
          postP.sales_unit_price = isSales ? postP.unit_price_fc : 0;
          postP.sales_vat_rate_type_id = isSales ? postP.vat_rate_type_id : 6;
        }
        postP.is_purchase = item.is_purchase === 1;
        postP.is_sales = item.is_sales === 1;
        postP.is_stock = item.is_stock === 1;
        delete postP.unit_price_fc;
        delete postP.unit_price;
        delete postP.net_amount_fc;
        delete postP.discount_amount_fc;

        postP.opening_quantity = 0;
        postP.quantity = 0;
        postP.product_accounting_type =
          item.type === 1 ? item.product_accounting_type || 0 : undefined;

        let code = await getCode();
        postP.item_code = code;
        const data = await Api.asyncPost("/item", postP);
        const returnItem = {
          ...values,
          item_id: data?.item?.id,
          item_code: code,
        };
        return returnItem;
      }
    });
    let newDatas = await Promise.all(promises);
    codes = [];
    newDatas = [...newDatas];
    onChangeLines(newDatas, () => {
      setTimeout(() => {
        onSave();
        setLoading(false);
        closeModal();
      }, 1000);
    });
  };

  const itemProps = (index) => ({
    onChange: (field, value) => setFieldChange(index, field, value),
    onMount: () => null,
    formData: formData[index] || {},
    errors,
    buttons: radioButtons,
  });

  const onSelectItem = (key, val) => {
    if (key === "all") {
      let newDatas = items.map((it, i) => {
        const val =
          items.filter((it) => !it.unSelected).length >=
          items.filter((it) => !it.item_id).length;
        setFieldChange(i, "unSelected", val);

        return {
          ...it,
          unSelected: val,
        };
      });
      onChangeLines(newDatas);
    } else {
      let newDatas = items.map((it, i) => {
        const nItem = { ...it };
        if (i === +key.replaceAll("item", "")) {
          nItem.unSelected = !nItem.unSelected;
          setFieldChange(i, "unSelected", nItem.unSelected);
        }
        return nItem;
      });
      onChangeLines(newDatas);
    }
  };

  return (
    <EkoModal
      showModal={showModal}
      title={"Ürün / Hizmet Ekle"}
      onHideDisable={loading}
      onHide={closeModal}
      spinner={loading}
      bsSize={"large"}
    >
      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>
          Ürün / Hizmet kartınız aşağıdaki seçeneklere göre hesabınıza
          eklenecektir
        </h4>
        <h5 style={{ textAlign: "center" }}>
          Standart seçenekleri ihtiyacınıza göre değiştirebilirsiniz
        </h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ width: 30 }}>
                {" "}
                <EkoFormCheckbox
                  containerStyle={{ paddingBottom: 10 }}
                  onMount={(x, y) => console.log("onMount", x, y)}
                  onChange={onSelectItem}
                  className="mt-checkbox mt-padding"
                  id="all"
                  value={
                    items.filter((it) => !it.unSelected).length >=
                    items.filter((it) => !it.item_id).length
                  }
                  onFalse={() => console.log("onFalse")}
                />
              </th>
              <th>Ürün Adı</th>
              <th style={{ width: 185 }}>Ürün / Hizmet</th>
              <th>Durumu</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => {
              const type = formData?.[i]?.type;
              const statuses = [
                {
                  id: "is_stock",
                  name: "Hem alış hemde satış faturamda çıksın",
                },
              ];
              if (isSales) {
                statuses.push({
                  id: "is_sales",
                  name: "Sadece satış faturamda çıksın",
                  // type === 1 ? "Sadece satış faturamda çıksın" : "Bu hizmeti satıyorum",
                });
              } else {
                statuses.push({
                  id: "is_purchase",
                  name: "Sadece alış faturamda çıksın",
                  // type === 1 ? "Sadece alış faturamda çıksın" : "Bu hizmeti alıyorum",
                });
              }
              if (item.item_id) return null;
              return (
                <tr key={i}>
                  <td style={{ width: 30 }}>
                    <EkoFormCheckbox
                      value={!item.unSelected}
                      onMount={(x, y) => console.log("onMount", x, y)}
                      onChange={onSelectItem}
                      className="mt-checkbox mt-padding"
                      id={"item" + i}
                      onFalse={() => console.log("onFalse")}
                    />
                  </td>
                  <td>
                    <div style={{ paddingTop: 10 }}>{item.description}</div>
                  </td>
                  <td style={{ width: 185 }}>
                    <EkoFormRadioButton
                      {...itemProps(i)}
                      id={"typeStr"}
                      onFalse={onFalse}
                    />
                  </td>
                  <td colSpan={3}>
                    <div style={{ paddingTop: 8 }}>
                      <EkoFormSelect
                        {...itemProps(i)}
                        formStyle={{ margin: 0 }}
                        searchable={false}
                        id="status"
                        isVertical
                        defaultText="SEÇİNİZ"
                        optionId="id"
                        options={statuses}
                        optionValue="name"
                        disabled={items.filter((it) => !it.unSelected).length < items.filter((it) => !it.item_id).length}
                      />
                      <div className="row">
                        {type === 1 ? (
                          <div className="col-md-5">
                            <EkoFormSelect
                              {...itemProps(i)}
                              formStyle={{ margin: 0 }}
                              id="product_accounting_type"
                              isVertical
                              defaultText="Ticari Mal"
                              optionId="id"
                              options={product_accounting_type}
                              optionValue="name"
                              label="Ürün Tipi"
                              disabled={items.filter((it) => !it.unSelected).length < items.filter((it) => !it.item_id).length}
                            />
                          </div>
                        ) : null}
                        <div className={type === 1 ? "col-md-7" : "col-md-12"}>
                          <EkoFormSelect
                            {...itemProps(i)}
                            formStyle={{ margin: 0 }}
                            id={
                              isSales
                                ? "is_update_sales_unit_price"
                                : "is_update_purchase_unit_price"
                            }
                            isVertical
                            defaultText="Güncellenmesin"
                            optionId="id"
                            options={isUpdates}
                            optionValue="name"
                            label="Son Faturadan Birim Fiyat"
                            disabled={items.filter((it) => !it.unSelected).length < items.filter((it) => !it.item_id).length}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <EkoFormButton
          label={"Seçili Ürün/Hizmetlerin Kartını Açarak Devam Et"}
          faClass="fa fa-plus"
          onClick={onSaveT}
          isLoading={loading}
        />
        <EkoFormButton
            label={"Ürün/Hizmet Kartı Açmadan Devam Et"}
            faClass="fa fa-minus"
            onClick={onSaveT2}
            isLoading={loading}
            className="btn btn-danger"
        />
        <EkoFormButton label="İptal" className="btn" onClick={closeModal} />
      </Modal.Footer>
    </EkoModal>
  );
}

export default SelectItemModal;
