import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import {CSS} from "../../css/tab_datatable";
import Style from "style-it";
import EkoDataTable from '../../components/elements/EkoDataTable'
import {FETCH_OTHER_SALES_LIST} from "../../actions/types";
import Api from "../../util/Api";
import {toastr} from 'react-redux-toastr'
import UtilHelper from "../../util/UtilHelper";
import Util from "../../util/Util";

/**
 *
 */
class OtherSalesList extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            othersales: []
        };
    }


    componentWillMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.fetchData('/otherSales/list', FETCH_OTHER_SALES_LIST);
    }

    openInsert(menuItem) {
        //localStorage.setItem("new_collection_from_who_type", menuItem);

        if (menuItem === 1) {
            this.props.router.push("/dashboard/salesInvoice/add/toCustomer");
        } else if (menuItem === 2) {
            this.props.router.push("/dashboard/salesInvoice/add/toSupplier");
        } else if (menuItem === 3) {
            this.props.router.push("/dashboard/salesInvoice/zreports/add");
        } else if (menuItem === 4) {
            this.props.router.push("/dashboard/salesInvoice/proforma/add");
        }else if (menuItem === 5) {
            this.props.router.push('/dashboard/salesInvoice/otherSales/add')
        }else if (menuItem === 6) {
            this.props.router.push('/dashboard/salesInvoice/soleprofv2/add')
        } else if (menuItem === 7) {
            this.props.router.push('/dashboard/salesInvoice/salesShipment/add')
        }
    }

    openUpdate(row) {
        this.props.router.push('/dashboard/salesInvoice/otherSales/edit/' + row.id);
    }

    get moneyFields() {
        return [
            'amount_cash',
            'amount_creditcard',
            'amount_vat1',
            'amount_vat8',
            'amount_vat18',
            'amount_without_vat'
        ];
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.othersales) {

            const othersales = nextProps.othersales.map(report => {
                const moneyFields = UtilHelper.money.mapDecimalToMoney(this.moneyFields, report);
                const dateFields = {record_date: Util.convertFromDatabaseDate(report.record_date)};

                return {
                    ...report,
                    ...moneyFields,
                    ...dateFields
                }
            });

            this.setState({
                othersales
            });
        }
    }


    onDelete(row) {
        const self = this;
        Api.get('/otherSales/delete/' + row.id, function(response, err) {
            if (response.status === 'ok') {
                toastr.success('Başarıyla Silindi');
                self.fetchData();
            } else {
                toastr.error('Yasal defterlerinize aktarılan kayıtlarınızı düzenlemek yada silmek için lütfen mali müşavirinize danışınız.');
            }
        });
    }

    get items() {
        var user_json = JSON.parse(localStorage.getItem("user"));
        var list = [];
        if(user_json.company_type == 9 || user_json.company_type == 6){
            list = [
                {
                    id: 5,
                    link: "/dashboard/salesInvoice/soleprofv2s",
                    title: "Serbest Meslek Makbuzu"
                },
                {
                    id: 4,
                    link: "/dashboard/salesInvoice/otherSales",
                    title: "Diğer Gelirler"
                }
            ];
        }else{
            list = [
                {
                    id: 1,
                    link: '/dashboard/salesInvoice',
                    title: 'Satışlar'
                },

                {
                    id: 2,
                    link: '/dashboard/salesInvoice/zreports',
                    title: 'Z Raporları'
                },
                {
                    id: 3,
                    link: '/dashboard/salesInvoice/proformas',
                    title: 'Teklifler/Siparişler'
                },
                {
                    id: 4,
                    link: "/dashboard/salesInvoice/otherSales",
                    title: "Diğer Gelirler"
                }
            ];
            if(Util.companyModules(2)===true){
                //@oguz stok modulu satın alındıysa
                list.push({
                    id: 7,
                    link: "/dashboard/salesInvoice/salesShipment",
                    title: "Sevk İrsaliyesi"
                },);
            }
        }
        return list;
    }


    render() {
        const insertMenux = this.props.viewOptions
            ? this.props.viewOptions.sales.addRecordMenu
            : false;
        if(insertMenux!=false){
            var user_json = JSON.parse(localStorage.getItem("user"));
            if(user_json.company_type == 9 || user_json.company_type == 6){
                var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 2 && obj.id !== 3 && obj.id !== 4 && obj.id !== 7);
                insertMenu.unshift({id: 6, name: 'Serbest Meslek Makbuzu'});
            }else{
                insertMenu = insertMenux;
            }
        }

        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                {
                    Style.it(
                        CSS,
                        <div>
                            <EkoDataTable
                                title="Diğer Gelirler"
                                tabMenu={this.items}
                                selectedTab={4}
                                openInsert={this.openInsert.bind(this)}
                                openUpdate={this.openUpdate.bind(this)}
                                onDelete={this.onDelete.bind(this)}
                                data={this.state.othersales}
                                insertMenu={insertMenu}
                                name={"othersales"}
                            />
                        </div>
                    )
                }
            </div>
        );


    }
}

const mapStateToProps = (state) => {
    return {
        othersales: state.data.othersales,
        viewOptions: state.data.viewOptions

    }
}

export default connect(mapStateToProps, actions)(OtherSalesList);