import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import {toastr} from "react-redux-toastr";
import {FiInfo} from "react-icons/fi";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class PurchaseExpenseCategory extends Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.state = {
            saving: false,
            categoryList: [],
            rows: [],
            recordType: [],
            getIncomeStatement: [],
            addCategoryListSelect:248,
            addDBSCodeListSelect:1,
            addIncomeStatementListSelect:1,
            addCategoryText:"",
            addCategoryTaxRate:"",
            addCategoryNotes:"",
            loading:false
        };
    }

    focus() {
        this.textInput.current.focus();
    }

    setDefaultStorage() {
        axios.get(ROOT_URL + "/DBS/purchaseExpenseCategoriesSelect/list/0", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(response => {
            var data = JSON.stringify(response.data.purchaseExpenseCategories, null, 2);
            localStorage.setItem("purchase_expense_categories", '');
            localStorage.setItem("purchase_expense_categories", data);
        });
    }

    categoryList() {
        Api.get("/DBS/purchaseExpenseCategories/list", (data, errors) => {
            if (data) {
                this.setState({categoryList: data.purchaseExpenseCategories});
                this.setState({rows: data.rows});
            }
        });
    }

    getIncomeStatementList() {
        Api.get("/DBS/getIncomeStatement/list", (data, errors) => {
            if (data) {
                this.setState({getIncomeStatement: data.getIncomeStatement});
            }
        });
    }

    onDelete(id) {
        Api.get("/DBS/purchaseExpenseCategories/delete/"+id, (data, errors) => {
            if (data) {
                this.categoryList();
                toastr.success("Başarılı", "Kategori Başarıyla Silindi");
            }
        });
    }

    getDBSRecordType() {
        Api.get("/DBS/DBSRecordType/list", (data, errors) => {
            if (data) {
                this.setState({recordType: data.recordType});
            }
        });
    }

    componentWillMount() {
        this.categoryList();
        this.getDBSRecordType();
        this.getIncomeStatementList();
    }

    setAddSelected(categoryId){
        this.setState({addCategoryListSelect: categoryId});
        window.scrollTo(0, 0);
        this.focus();
    }

    add(){
        if(this.state.addCategoryText ==''){
            toastr.error("Hata!", "Kategori adı girmeden kayıt ekleyemezsiniz.");
        }else if(this.state.addCategoryListSelect ==0){
            toastr.error("Hata!", "Ana kategori seçmeden kayıt ekleyemezsiniz.");
        }else if(this.state.addDBSCodeListSelect ==0){
            toastr.error("Hata!", "DBS kodu seçmeden kayıt ekleyemezsiniz.");
        }else{
            var params = {
                top_category_id: this.state.addCategoryListSelect,
                name: this.state.addCategoryText,
                rate: this.state.addCategoryTaxRate,
                notes: this.state.addCategoryNotes,
                dbs_code_id: this.state.addDBSCodeListSelect,
                income_statement: this.state.addIncomeStatementListSelect,
            };
            Api.post("/DBS/addPurchaseExpenseCategory", params, (response, err) => {
                this.categoryList();
                this.setDefaultStorage();
                toastr.success("Başarılı", "Kategori Başarıyla Eklendi");
            });
        }

        this.setState({
            addCategoryListSelect: 248,
            addDBSCodeListSelect: 1,
            addCategoryText: "",
            addCategoryTaxRate: "",
            addCategoryNotes: "",
        });
    }

    rowChangeUpdate = (val, obj, box) => {
        this.setState({loading: true});
        if(box=='dbs_code_id'){
            this.state.rows[obj].dbs_code_id = val;
        }else if(box=='income_statement_id'){
            this.state.rows[obj].income_statement_id = val;
        }else if(box=='tax_rate'){
            if (val>100){val=100;}
            this.state.rows[obj].tax_rate = val;
        }else if(box=='notes'){
            this.state.rows[obj].notes = val;
        }else if(box=='view'){
            if(val==true){val=0;}else{val=1;}
            this.state.rows[obj].view = val;
        }
        var params = {
            id: this.state.rows[obj].id,
            column: box,
            val: val
        };
        Api.post("/DBS/purchaseExpenseCategoryLinesUpdate", params, (response, err) => {
            //toastr.success("Başarılı", "Başarıyla Güncellendi");
            this.setDefaultStorage();
        });
        this.setState({loading: false});
        toast.success("Başarıyla Güncellendi", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false
        });
    };

    render() {
        return (
            <div><ToastContainer  limit={1} />
                <div style={{height:"25px"}}></div>
                <div className="row">
                    <div className="col-md-2">
                        <p>Ana Kat.</p>
                        <select
                            className="form-control"
                            onChange={e => { this.setState({ addCategoryListSelect: e.target.value }); }}
                            value={this.state.addCategoryListSelect}>
                            {this.state.categoryList.map((e, key) => {
                                return <option
                                    key={e.id}
                                    value={e.id}>{e.name}</option>;
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <p>Kategori Adı</p>
                        <input value={this.state.addCategoryText} placeholder="Kategori Adı" className="form-control" type="text" ref={this.textInput} onChange={e => { this.setState({ addCategoryText: e.target.value }); }}/>
                    </div>
                    <div className="col-md-2">
                        <p>DBS Kodu</p>
                        <select
                            className="form-control"
                            value={this.state.addDBSCodeListSelect}
                            onChange={e => { this.setState({ addDBSCodeListSelect: e.target.value }); }}
                        >
                            {this.state.recordType.map((e, key) => {
                                return <option
                                    key={e.id}
                                    value={e.id}>{e.code + " - " + e.description}</option>;
                            })}
                        </select>
                    </div>
                    {/*<div className="col-md-2">
                        <p>Muhasebe Kodu</p>
                        <select
                            className="form-control"
                            value={this.state.addIncomeStatementListSelect}
                            onChange={e => { this.setState({ addIncomeStatementListSelect: e.target.value }); }}
                        >
                            {this.state.getIncomeStatement.map((e, key) => {
                                return <option
                                    key={e.id}
                                    value={e.id}>{e.account_code + " " + e.description}</option>;
                            })}
                        </select>
                    </div>*/}
                    <div className="col-md-2">
                        <p>Vergi Çarpanı</p>
                        <div className="input-group">
                            <span className="input-group-addon">%</span>
                            <input value={this.state.addCategoryTaxRate} placeholder="Vergi Çarpanı"
                                   className="form-control" type="number" min="1" max="100" onChange={e => {
                                this.setState({addCategoryTaxRate: e.target.value > 100 ? 100 : e.target.value});
                            }}/>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <p>Not</p>
                        <input value={this.state.addCategoryNotes} placeholder="Not" className="form-control" type="text"  onChange={e => { this.setState({ addCategoryNotes: e.target.value }); }} />
                    </div>
                    <div className="col-md-1">
                        <p>&nbsp;</p>
                        <button className="btn blue btn-sm" onClick={this.add.bind(this)}>
                            <i className="fa fa-plus"/>&nbsp; Yeni Ekle
                        </button>
                    </div>
                </div>
                <div style={{height:"25px"}}></div>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr className="fcolumn">
                        <th style={{width:"20%"}} className="fcolumn">Kategori Adı</th>
                        <th style={{width:"20%"}} className="fcolumn">DBS Kodu</th>
                        {/*<th style={{width:"20%"}} className="fcolumn">Muhasebe Kodu</th>*/}
                        <th style={{width:"20%"}} className="fcolumn">Vergi Çarpanı</th>
                        <th style={{width:"20%"}} className="fcolumn">Not</th>
                        <th style={{width:"12%"}} className="fcolumn"><FiInfo color={"black"} size={15} title={"Kayıtların Listede Gösterilip Gösterilmeyeceğini Belirler"}/> <span>İşlem</span></th>
                    </tr>
                    </thead>
                </table>
                {
                    this.state.categoryList.map((item, index) => {
                        return <table className="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr key={index}>
                                <td style={{fontWeight: "bold",width:"20%"}}>{item.name} <i onClick={(e) => this.setAddSelected(item.id)} style={{float: "right",width:"15%",fontSize:"20px","cursor":"pointer"}} className="fa fa-plus-circle"/></td>
                                <td style={{width:"20%"}}></td>
                                {/*<td style={{width:"20%"}}></td>*/}
                                <td style={{width:"20%"}}></td>
                                <td style={{width:"20%"}}></td>
                                <td style={{width:"12%"}}></td>
                            </tr>
                            {
                                item.sub_category.map((item, index) => {
                                    var obj = this.state.rows.findIndex(o => o.purchase_expense_categories_id == item.id);
                                    return <tr key={index}>
                                            <td style={{width:"20%"}}>
                                                {item.default!=0?<span onClick={(e) => this.onDelete(item.id)} className="btn btn-primary" style={{marginLeft: -24 + 'px'}}>X</span>:<span  style={{width: 24 + 'px'}}>&nbsp;</span>}
                                                {item.name}
                                            </td>
                                            <td style={{width:"20%"}}>
                                                <select
                                                    className="form-control"
                                                    value={typeof this.state.rows[obj] === 'undefined'?1:this.state.rows[obj].dbs_code_id}
                                                    onChange={(e) => this.rowChangeUpdate(e.target.value, obj,'dbs_code_id')}
                                                >
                                                    {this.state.recordType.map((e, key) => {
                                                        return <option
                                                            key={e.id}
                                                            value={e.id}>{e.code + " - " + e.description}</option>;
                                                    })}
                                                </select>
                                            </td>
                                        {/*<td style={{width:"20%"}}>
                                            <select
                                                className="form-control"
                                                value={typeof this.state.rows[obj] === 'undefined'?1:this.state.rows[obj].income_statement_id}
                                                onChange={(e) => this.rowChangeUpdate(e.target.value, obj,'income_statement_id')}
                                            >
                                                {this.state.getIncomeStatement.map((e, key) => {
                                                    return <option
                                                        key={e.id}
                                                        value={e.id}>{e.account_code + " - " + e.description}</option>;
                                                })}
                                            </select>
                                        </td>*/}
                                            <td style={{width:"20%"}}>
                                                <div className="input-group">
                                                    <span className="input-group-addon">%</span>
                                                    <input
                                                        value={typeof this.state.rows[obj] === 'undefined' ? 1 : this.state.rows[obj].tax_rate}
                                                        className="form-control" type="number" min="1" max="100"
                                                        onChange={(e) => this.rowChangeUpdate(e.target.value, obj, 'tax_rate')}/>
                                                </div></td>
                                            <td style={{width:"12%"}}><input value={typeof this.state.rows[obj] === 'undefined'?"":this.state.rows[obj].notes} className="form-control" type="text" onChange={(e) => this.rowChangeUpdate(e.target.value, obj,'notes')} /></td>
                                        <td>Göster <input onChange={(e) => this.rowChangeUpdate(e.target.checked, obj, 'view')} type="checkbox" checked={typeof this.state.rows[obj] === 'undefined'?false:this.state.rows[obj].view==0?true:false} /></td>
                                        </tr>;
                                })
                            }
                            </tbody>
                        </table>;
                    })
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices,
        cities: state.data.cities,
        counties: state.data.counties,
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(PurchaseExpenseCategory);
