import React, {Component} from 'react'
import Sugar from 'sugar'
import {toastr} from 'react-redux-toastr'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import Api from '../../util/Api'
import EkoHeaderForm from '../../components/elements/EkoHeaderForm'
import Util from '../../util/Util';
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import {EkoFormInputText} from "../../components/elements/EkoForm";

class AddExpenseCategory extends Component {

    constructor(props) {
        super(props);
        this.permitted = [];
        this.setReset = null;

        this.state = {
            expenseName: "",
            formData: {},
            saving: false,
            errors: this.defaultErrors(),
            deleteRowIds: [],

            headerFields: [
                {title: 'Kategori Adı', type: 'text', id: 'description', clearable: true},
            ],
            tableData: {
                headers: [
                    {label: 'Gider Kategorisi', width: '10%'},
                    {label: 'Açıklama', width: '10%'},
                    {label: "Brüt Tutar", width: '10%'},
                    {label: 'KDV Oranı', width: '10%'},
                    {label: 'KDV Tutarı', width: '10%'},
                    {label: 'Stopaj Oranı', width: '10%'},
                    {label: 'Stopaj Kodu', width: '10%'},
                    {label: 'Stopaj Tutarı', width: '10%'},
                    {label: 'Net Ödenen', width: '10%'},
                ],
                customFooter: null,
                rows: this.defaultRows()
            },
            vatRates: []
        }
    }


    isPermitted(fieldName) {
        let fieldCompanyTypes;

        if (this.props.viewOptions && this.props.viewOptions.receipts.fieldCompanyTypes) {
            fieldCompanyTypes = this.props.viewOptions.receipts.fieldCompanyTypes;
        }

        if (fieldCompanyTypes === undefined) {
            return false;
        }


        let finded = fieldCompanyTypes.filter((type) => type.id === fieldName);

        if (this.permitted[fieldName] !== undefined) {
            return this.permitted[fieldName];
        }

        if (finded.length === 0) {
            this.permitted[fieldName] = true;
            return true;
        }

        finded = finded[0].name;

        const returnValue = CompanyTypePermission.isPermitted(finded);

        this.permitted[fieldName] = returnValue;

        return returnValue;
    }
    defaultFormData() {
        return {
            expense: {
                description:""
            },
            items: {
                date: null,
                payment_method: 0,
                bank_account_id: 0,
                description: null
            },
            rows: [{
                id: 0,
                expense_category_id_select: '',
                description: '',
                vat_exc: null,
                vat_rate: 0,
                vat_amount: null,
                stoppage_ratio:null,
                stoppage_code:null,
                stoppage_amount:0,
                amount: null,
            }],
            footer: {}
        }
    }
    setFieldChange(type, field, value) {
        this.setState({
            expenseName : field
        });
    }
    onSave() {
        var self = this;
        let formData = this.state.formData;
        self.setState({saving: true});
        if (this.props && this.props.expenseId) {
            var params = {id: this.props.expenseId,description:this.state.expenseName,rows:formData.rows};
            Api.post("/otherReceipt/editExpenseCategories", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu. Kaydedilemedi");
                    this.setState({saving:false});
                } else {
                    toastr.success("Başarılı", "Başarıyla Güncellendi");
                    //this.redirectToBase();
                    this.setState({saving:false});
                }
            });
        }else{
            var params = {description:this.state.expenseName,rows:formData.rows};
            Api.post("/otherReceipt/addExpenseCategories", params, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu. Kaydedilemedi");
                    this.setState({saving:false});
                } else {
                    toastr.success("Başarılı", "Başarıyla Eklendi");
                    this.redirectToBase();
                    this.setState({expenseName:""});
                }
            });
        }

    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        if (this.props && this.props.expenseId) {
            Api.get("/otherReceipt/getExpenseCategories/" + this.props.expenseId, (response, error) => {
                this.setState({expenseName:response.expenseCategories.description});
                formData.rows=[];
                response.rows.map((r, i) => {
                    formData.rows = [...formData.rows, {
                        id: r['id'],
                        expense_category_id_select: r['expense_category_id_select'],
                        description: r['description'],
                        vat_exc: r['vat_exc'],
                        vat_rate: Number(r['vat_rate']),
                        vat_amount: r['vat_amount'],
                        stoppage_ratio:r['stoppage_ratio'],
                        stoppage_code:r['stoppage_code'],
                        stoppage_amount:r['stoppage_amount'],
                        amount: r['amount'],
                    }];
                    this.setState({formData: formData});
                })
            });
        }

        let errors = this.defaultErrors();
        if (formData.rows.length > errors.rows.length) {
            const defaultErrors = this.defaultErrors();
            while (errors.rows.length < formData.rows.length)
                errors.rows.push(defaultErrors.rows[0])
        }
        this.setState({formData: formData, errors: errors});
        this.assignExpenseAndVat();

    }
    componentWillUpdate(nextProps, nextState) {
        let tableData = nextState.tableData;
        let formData = nextState.formData;
        let errors = nextState.errors
        if (formData && formData.rows && (formData.rows.length > tableData.rows.length || formData.rows.length > errors.rows.length)) {
            while (formData.rows.length > tableData.rows.length) {
                tableData.rows.push(tableData.rows[0]); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
            }

            const defaultErrors = this.defaultErrors();
            while (errors.rows.length < formData.rows.length)
                errors.rows.push(defaultErrors.rows[0])

            this.setState({tableData: tableData, errors: errors})
        } else if (formData && formData.rows && formData.rows.length === 1 && (tableData.rows.length > 1 || tableData.rows.length > 1)) {
            //edit den add safasina geclince
            let tableData = this.state.tableData;
            tableData.rows = this.defaultRows();
            this.setState({errors: this.defaultErrors(), tableData: tableData});
        }
    }


    redirectToBase() {
        window.location.replace("/dashboard/purchases/expensecategory");
    }
    defaultErrors() {
        return {
            rows: [{
                expense_category_id_select: false,
                description: false,
                vat_exc: false,
                vat_rate: false,
                vat_amount: false,
                stoppage_ratio: false,
                stoppage_code: false,
                stoppage_amount: false,
                amount: false,
            }],
            footer: {}
        }
    }
    defaultRows() {
        return [
            [{
                type: 'select',
                id: 'expense_category_id_select',
                optionValue: 'description',
                defaultKey: 'diğer',
                searchFilter: 'key_words',
                optionId: 'id',
                defaultText: 'SEÇ',
                searchable: true,
                data: []
            },
                {type: 'text', id: 'description', vertical: true},
                {type: 'money', id: 'vat_exc', vertical: true},
                {type: 'select', id: 'vat_rate', defaultText: 'SEÇ', optionValue: 'name', optionId: 'id', data: []},
                {type: 'money', id: 'vat_amount', vertical: true},
                {type: 'money', id: 'stoppage_ratio', vertical: true},
                {
                    type: 'select',
                    id: 'stoppage_code',
                    optionValue: 'name',
                    defaultKey: 'diğer',
                    searchFilter: 'name',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    searchable: true,
                    data: []
                },
                {type: 'money', id: 'stoppage_amount', vertical: true},
                {type: 'money', id: 'amount', vertical: true},
            ]
        ]
    }
    assignExpenseAndVat = (next) => {
        let vatRates = {}
        Api.paralel(['/data/vatRateTypes'], resp => {
            let tableData = this.state.tableData
            if (resp[0]) {
                tableData.rows[0][3].data = resp[0].vatRateTypes
                resp[0].vatRateTypes.forEach(function (vr) {
                    vatRates[vr.id] = vr;
                })
            }
            this.setState({tableData: tableData, vatRates: vatRates}, () => next ? next() : null)
        });

        let tableData = this.state.tableData;
        tableData.rows[0][0].data = JSON.parse(localStorage.getItem("purchase_expense_categories"));
        var jsonParse = JSON.parse(localStorage.getItem("stoppage_code"));
        jsonParse.unshift({name: 'YOK', id: 0, code: '0', description: 'YOK', vat_reduction_type_id: 0,});
        tableData.rows[0][6].data = jsonParse;
        this.setState({tableData: tableData});
    }
    onCancel() {

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    onRowDelete(index, row) {
        let formData = this.state.formData
        let errors = this.state.errors
        let deleteRowIds = this.state.deleteRowIds
        errors.rows = Sugar.Array.removeAt(errors.rows, index)
        this.setState({errors: errors})
        if (formData.receipt.id) {
            deleteRowIds.push(row.id)
            /*  Api.delete('/receiptLine/'+row.id,function(response,err){
             toastr.success('Fiş satırı silindi');
             });*/
        }
    }
    onReceiptChange(formData,type, field, value) {

        if (this.state.saving) return;
        let headerFields = this.state.headerFields;
        let stateFormData = {...this.state.formData};
        let errors = this.state.errors;
        if (formData.items) {
            stateFormData.receipt = Object.assign(stateFormData.receipt, formData.items)
        }

        if (formData.rows) {
            stateFormData.rows = formData.rows
            //stateFormData = this.calculateTotal(stateFormData);
            if (formData.rows.length > errors.rows.length) {
                const defaultErrors = this.defaultErrors();
                while (errors.rows.length < formData.rows.length)
                    errors.rows.push(defaultErrors.rows[0])
            }
        }

        const paymentMethod = stateFormData.receipt.payment_method;
        if (this.props.bankAccounts && paymentMethod) {
            let bankAccounts = this.props.bankAccounts.filter(b => (b.bank_account_type === paymentMethod && b.is_active === 1 && b.currency === 'TRY'))
            if (paymentMethod === 4) {
                if (stateFormData.receipt.employee_id) {
                    stateFormData.receipt.bank_account_id = stateFormData.receipt.employee_id
                }
                bankAccounts = this.props.employees.filter(b => b.termination_date === null)
                bankAccounts = bankAccounts.map(function (employee) {
                    return {name: employee.firstname + " " + employee.lastname, id: employee.id};
                });
            }
            headerFields[3]['data'] = bankAccounts
            this.setState({headerFields: headerFields, formData: stateFormData, errors: errors});
        }
        else {
            this.setState({formData: stateFormData, errors: errors});
        }

    }
    onChangeField(field, value) {
        let formData = this.state.formData;
        if (field === "payment_method" && !this.state.saving) {
            delete formData.receipt.bank_account_id
        }
        let errors = this.state.errors
        errors.receipt[field] = false
        this.setState({errors: errors, formData: formData})
    }

    addRow = () => {
        // do nothing
    }
    onReceptLineChange(index, formData, field) {
        let stateFormData = this.state.formData;
        stateFormData.rows[index] = formData;
        const vatId = stateFormData.rows[index].vat_rate;
        let amount = stateFormData.rows[index].amount;
        let vat_amount = stateFormData.rows[index].vat_amount;
        let stoppage_amount = stateFormData.rows[index].stoppage_amount;
        let vat_exc = stateFormData.rows[index].vat_exc;
        let stoppage_ratio = stateFormData.rows[index].stoppage_ratio;
        let errors = this.state.errors

        if (errors.rows[index]) {
            errors.rows[index][field] = false
        }

        if ((field === 'vat_exc' || field === 'vat_rate') && vat_exc && vatId) {
            vat_exc = Util.convertMoneyToDecimal(vat_exc);
            stateFormData.rows[index].vat_amount = Util.convertDecimalToMoney((vat_exc * this.state.vatRates[vatId].rate));
            errors.rows[index].vat_amount = false
        }

        if ((field === 'vat_exc' || field === 'stoppage_ratio') && vat_exc && stoppage_ratio) {
            var stoppage_ratiox = stoppage_ratio.replace("%",'');
            stoppage_ratiox = parseInt(stoppage_ratiox);
            vat_exc = Util.convertMoneyToDecimal(vat_exc);
            if(stoppage_ratio!='%'){
                stateFormData.rows[index].stoppage_amount = Util.convertDecimalToMoney((vat_exc * stoppage_ratiox)/100);
            }else{
                stateFormData.rows[index].stoppage_amount = Util.convertDecimalToMoney(0);
            }
            errors.rows[index].stoppage_amount = false
        }

        if (vat_exc && vat_amount && stoppage_amount) {
            stateFormData.rows[index].amount = Util.convertDecimalToMoney((Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_exc)+Util.convertMoneyToDecimal(this.state.formData.rows[index].vat_amount))-Util.convertMoneyToDecimal(this.state.formData.rows[index].stoppage_amount));
            errors.rows[index].amount = false
        }



        this.setState({formData: stateFormData, errors: errors});
    }
    filterOnlyPermittedFields() {

        const filtered = this.state.headerFields.filter((field) => {
            return this.isPermitted(field.id);
        });

        if (CompanyTypePermission.isSelfEmployment()) {
            filtered.push({});
        }

        return filtered;
    }


    render() {
        return <div>
            <div className="portlet-body">
                <div className="row">
                    <div className="col-md-12">
                        <EkoFormInputText
                            onChange={this.setFieldChange.bind(this)}
                            value={this.state.expenseName}
                            id="expenseName"
                            label="Şablon Adı"
                            isVertical>
                        </EkoFormInputText>
                    </div>
                </div>
            </div>
            <EkoHeaderForm
                setReset={click => this.setReset = click}
                fields={this.filterOnlyPermittedFields()}
                formData={this.state.formData.expensecategory}
                errors={this.state.errors.expensecategory}
                onChangeField={this.onChangeField.bind(this)}
                onChangeFields={this.onReceiptChange.bind(this)}
                title="ŞABLON SATIRLARI">
                <EkoInputTableButtonGroup data={this.state.tableData}
                                          formData={this.state.formData}
                                          errors={this.state.errors}
                                          onSave={this.onSave.bind(this, true)}
                                          saving={this.state.saving}
                                          formId={"add_expense_category"}
                                          onRowAdd={this.addRow}
                                          onCancel={this.onCancel.bind(this)}
                                          onChangeRow={this.onReceptLineChange.bind(this)}
                                          onChange={() => null}
                                          onRowDelete={this.onRowDelete.bind(this)}
                />
            </EkoHeaderForm>
        </div>
    }

}

function mapStateToProps(state) {
    return {
        expenseCategories: state.data.expenseCategories
    };
}

export default connect(mapStateToProps, actions)(AddExpenseCategory);
