import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { FETCH_COLLECTIONS } from "../../actions/types";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import Style from "style-it";
import { CSS } from "../../css/tab_datatable";
import {Link} from "react-router";
import IncomingStatementsModal from "./IncomingStatementsModal";
import Util from "../../util/Util";

class Collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      showStatementModal: false // cb 03/05/2023
    };
  }

  fetchCollections() {
    this.props.fetchData("/collection/list", FETCH_COLLECTIONS);
  }

  componentWillMount() {
    localStorage.removeItem("new_customer_id_take_collection");
    localStorage.removeItem("new_collection_amount_fc");
    localStorage.removeItem("selected_list_sales_invoice_id");
    localStorage.removeItem("selected_list_purchases_invoice_id");
    localStorage.removeItem("selected_list_receivable_amount_fc");
    localStorage.removeItem("selected_list_payable_amount_fc");
    localStorage.removeItem("new_collection_from_who_type", 1);
    this.fetchCollections();
  }

  openUpdate(selectedRow) {
    this.props.router.push("/dashboard/collections/edit/" + selectedRow.id);
  }

  openInsert(menuItem) {
    localStorage.setItem("new_collection_from_who_type", menuItem);
    this.props.router.push("/dashboard/collections/add");
  }

  openReceipt(selectedRow) {
    this.printShow(selectedRow);
  }
  transformCollectionInvoice(selectedRow) {
    this.props.router.push("/dashboard/salesInvoice/add/toCustomer?collection_id=" + selectedRow.id);
  }
  printShow = row => {
    window.open(
      process.env.REACT_APP_API_URL +
        "/collection/print/" +
        row.id +
        "?token=" +
        localStorage.getItem("token"),
      "_blank"
    );
  };

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/collection/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchCollections();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  get items() {
    return [
      {
        id: 1,
        link: "/dashboard/collections",
        title: "Tahsil Ettiklerim"
      },

      {
        id: 2,
        link: "/dashboard/collections/outstanding",
        title: "Alacaklarım"
      }
    ];
  }
  /* cb 03/05/2023 */
  sideLink() {
    return (
        <Link className="btn btn-default blue" onClick={this.openIncomingStatements.bind(this)}><i className="fa fa-reply"></i> Banka Hesap Hareketleri (Gelen Para)</Link>
    );
  }
  openIncomingStatements() {
    let self = this;
    self.setState({showStatementModal: true});
  }
  /**/
  render() {
    const insertMenu = this.props.viewOptions
      ? this.props.viewOptions.collections.type
      : false;
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <IncomingStatementsModal
              showModal={this.state.showStatementModal}
              formData={{"token": localStorage.getItem("token")}}
              onCancel={() => {
                this.setState({showStatementModal: false});
              }}
              errors={this.state.errors}
          />
          {Style.it(
              CSS,
              <div>
                <EkoDataTable
                    tabMenu={this.items}
                    selectedTab={1}
                    title={"Tahsilatlar"}
                    openInsert={this.openInsert.bind(this)}
                    transformCollectionInvoice={this.transformCollectionInvoice.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.props.collections}
                    openReceipt={this.openReceipt.bind(this)}
                    insertMenu={insertMenu}
                    name={"collection"}
                    sideLink={this.sideLink()}
                ></EkoDataTable>
              </div>
          )}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collections: state.data.collection,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(Collections);
