const array_fill = function (startIndex, num, mixedVal) {
    let key;
    let tmpArr = {};
    if (!isNaN(startIndex) && !isNaN(num)) {
        for (key = 0; key < num; key++) {
            tmpArr[(key + startIndex)] = mixedVal
        }
    }
    return tmpArr
};


export {
    array_fill
};